import './style.scss'
import { useContext } from 'react'
import AppInfoContext from 'src/provider/state-manager/appInfoProvider'
import VisibilityContext from 'src/provider/state-manager/visibilityProvider'
import { 
    ResponderContentWrapper, 
    ResponderIconCircle, 
    ResponderIconHolder, 
    ResponderTitle, 
    ResponderMessage, 
    ResponderContentPlus,
    MessageIcon } from './style'
import { CenterPopupContainer, CenterPopup, Button, Icon } from 'src/styles'
import access from 'src/utils/localAccess'
import utility from 'src/utils/utility'


export const Responder = () => {
    const {info} = useContext(AppInfoContext)
    const { visibility: {response}, responder } = useContext(VisibilityContext)

    const responderIcon = utility.getResponderIcon(response.type)

    function closeResponder () {
        responder.hide()
    }

    function handleNav (path: string) {
        responder.hide()
        if (path != 'close') access.getNavigationAccess()(path)
    }

    return (
        <>
            { response.status && 
                <CenterPopupContainer>
                    <CenterPopup data-aos="slide-down">
                        <i className="fa fa-times responder-cancel" onClick={closeResponder}></i>
                        { info.token ?
                            <ResponderContentWrapper>
                                <ResponderIconCircle>
                                    <Icon src={responderIcon} />
                                </ResponderIconCircle>
                                <ResponderIconHolder></ResponderIconHolder>
                                <ResponderTitle>{response.title}</ResponderTitle>
                                <ResponderMessage>{response.message}</ResponderMessage>
                                { (response.btnText && response.btnPath) &&
                                    <Button
                                        width="25"
                                        height="5"
                                        topMargin="5.5"
                                        bottomMargin="1.5"
                                        hoverBgColor="#FED0B7"
                                        onClick={() => handleNav(response.btnPath)}
                                    >
                                        {response.btnText}
                                    </Button>
                                }
                            </ResponderContentWrapper>
                            :
                            <ResponderContentPlus>
                                <MessageIcon src={responderIcon} topMargin="10" bottomMargin="5" />
                                <ResponderMessage fontWeight="700">
                                    {response.message || 'Hello. We see you are trying to get a loan. why dont you signup to complete your loan process'}
                                </ResponderMessage>
                                <Button
                                    width="28"
                                    height="5"
                                    topMargin="7"
                                    bottomMargin="1.5"
                                    hoverBgColor="#FED0B7"
                                    onClick={() => handleNav('/onboarding/type')}
                                >
                                    {response.btnText || 'Sign up'}
                                </Button>
                            </ResponderContentPlus>
                        }
                    </CenterPopup >
                </CenterPopupContainer>
            }
        </>
    )
}
