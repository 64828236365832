import { useState, useEffect, useContext } from 'react'
import utility from 'src/utils/utility'
import { useNavigate } from 'react-router-dom'
import AppInfoContext from 'src/provider/state-manager/appInfoProvider'
import ApiContext from 'src/provider/API/call-service'
import { Container } from 'src/component'
import { AppSpan, AppText, Button, FlexRow } from 'src/styles'
import helpers from 'src/utils/helpers'

const OtpVerification = () => {
    const navigate = useNavigate()
    const {info, setInfoProperty} = useContext(AppInfoContext)
    const {API} = useContext(ApiContext)
    const [otp, setOtp] = useState('')
    var [time, setTime] = useState<any>(null)
    var [count, setCount] = useState<any>(null)

    useEffect(() => {
        if (count === 30) handleResendOtp()

        return () => { clearInterval(time) }
    }, [count])

    useEffect(() => {
        return () => { clearInterval(time) }
    }, [])

    function handleResendCounter () {
        time =  setInterval(async () => {
            setCount((count: number) => {
                if (count === 30) {
                    clearInterval(time)
                    return 0
                }
                else return count + 1
            });
        }, 1000)
    }

    const handleOtp = (e: React.ChangeEvent<HTMLInputElement|HTMLSelectElement>) => {
        if (e.target.value.length < 7) {
            setOtp(e.target.value)
        }
    }

    async function handleResendOtp () {
        API.passwordResetResendOtp({username: info.onBoardingData.email})
    }

    async function handleNext (e: React.FormEvent<HTMLButtonElement|HTMLFormElement>) {
        e.preventDefault()
        await setInfoProperty('onBoardingData', {...info.onBoardingData, otp})
        navigate('/forget-password/new-passcode')
    }

    return (
        <Container>
            <p className="right-align-text">Don't have an account? 
                <a className="registration-link" onClick={() => navigate('/onboarding/type')}> Sign up</a>
            </p>
            <div className="main-content-wrapper">
                <h3>Reset Passcode OTP</h3>
                <p>Just hit the button below to reset your passcode. We will email you a code to reset your passcode</p>
                <form className="login-form">
                    <div className="form-group-wrapper">
                        <div className="passcode-input-wrapper">
                            <input 
                                className='display-hidden' 
                                id='otp' name="otp" 
                                value={otp} autoFocus={true} 
                                onChange={handleOtp} autoComplete="off"
                            />
                            <label className={`passcode-input ${otp.length === 0 && 'active-pin-box'}`} htmlFor='otp' >{otp[0] || ''}</label>
                            <label className={`passcode-input ${otp.length === 1 && 'active-pin-box'}`} htmlFor='otp' >{otp[1] || ''}</label>
                            <label className={`passcode-input ${otp.length === 2 && 'active-pin-box'}`} htmlFor='otp' >{otp[2] || ''}</label>
                            <label className={`passcode-input ${otp.length === 3 && 'active-pin-box'}`} htmlFor='otp' >{otp[3] || ''}</label>
                            <label className={`passcode-input ${otp.length === 4 && 'active-pin-box'}`} htmlFor='otp' >{otp[4] || ''}</label>
                            <label className={`passcode-input ${otp.length === 5 && 'active-pin-box'}`} htmlFor='otp' >{otp[5] || ''}</label>
                        </div>
                    </div>
                    <FlexRow justifyContent="space-between" topMargin="-1">
                        <AppText textSize="1.4" color="#D5D5D5" fontWeight="400">
                            Resend Code in 
                            <AppSpan color="#6DE7B4" leftPadding="1">{helpers.formatSeconds(30-count)}</AppSpan>
                        </AppText>
                        <a onClick={handleResendCounter} className="resend-otp">Resend Code</a>
                    </FlexRow>
                    <Button 
                        width="18" 
                        height="4.5" 
                        topMargin="10"
                        hoverBgColor="#FFC49D" 
                        className="loan-action-btn"
                        disabled={otp.length != 6 ? true : false}
                        onClick={handleNext}
                    >
                        Continue
                    </Button>
                </form>
            </div>

        </Container>
    )
}

export default utility.routeData('forget-password/otp-verification', 'Otp Verification', <OtpVerification/>)