import { Chart as ChartJS, registerables } from 'chart.js';
import { Line } from 'react-chartjs-2'
ChartJS.register(...registerables);

export const InterestChart = () => {
    return (
        <Line 
            data={{
                labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'],
                datasets: [
                    {
                        label: 'Total Interest Earned',
                        //data: [5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60],
                        data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
                        borderColor: '#FED0B7',
                        pointRadius: 0,
                    }
                ]
            }}
            height="90%"
            width="100%"
            options={{
                maintainAspectRatio: false, 
                plugins: {
                    legend: {
                        align: 'end',
                        labels: {
                            boxWidth: 10,
                            boxHeight: 10,
                            color: '#fff'
                        }
                    }
                },
                scales: {
                    y: {  
                        display: false
                    },
                    x: {  
                        ticks: {
                            color: "#fff", 
                        }
                    }
                }
            }}
        />
    )
}
