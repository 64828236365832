import { useState, useContext, useEffect } from 'react'
import ApiContext from 'src/provider/API/call-service'
import LoanContext from 'src/provider/state-manager/loanProvider'
import { SidePopupContainer, SidePopup, Button, FlexRow, AppLink, BorderlessButton, CustomContainer, AppLabel, OutlineButton, AppSpan  } from "src/styles"
import utility from 'src/utils/utility'
import { LoanNegotiation, NegotiationRejectionReason } from 'src/popup'


export const NegotiationRequest: React.FC<any> = ({data, close}) => {
  const {API} = useContext(ApiContext)
  const {setLoanProperty} = useContext(LoanContext)
  const [showNegotiation, setShowNegotiation] = useState(false)
  const [showRejectNegotiation, setShowRejectNegotiation] = useState(false)

  async function handleAcceptNegotiation () {
    const acceptance = await API.acceptNegotiation(data?.loanNegotiationId)
    if (acceptance) {
        let negotiationInfo = {...data}
        delete negotiationInfo?.loanOffer
        delete negotiationInfo?.loanRequestId
        delete negotiationInfo?.loanOfferId
        delete negotiationInfo?.rejected
        delete negotiationInfo?.loanNegotiationId
        delete negotiationInfo?.accepted
        const payload = {
            ...data.loanOffer,
            ...negotiationInfo
        }

        if (data?.loanOffer?.amountPerPerson) payload.amount = data?.loanOffer?.amountPerPerson
        else if (data?.loanOffer?.numberOfPerson) payload.amount = (data?.loanOffer?.amount / data?.loanOffer?.numberOfPerson)

        payload.numberOfPerson = 1
        await setLoanProperty('proposedLoanRequest', data?.loanOffer)
        const response = await API.publishLoanPortfolio(payload, true)
        if (response) {
            await API.requestLoan([response?.loanOfferId], true, data?.loanRequestId)
            //API.updateNotificationStatus()
        }
    }
    close()
  }

//   async function handleRejectNegotiation () {
//     await API.rejectNegotiation(data?.loanNegotiationId)
//     close()
//   }
  
  return (
    <>
      { !showNegotiation &&
        <SidePopupContainer >
          <SidePopup data-aos="slide-left">
            <div className="popup-title-wrapper">
              <h4>Loan negotiation request</h4>
              <i className="fa fa-times" onClick={close}></i>
            </div>
            <span className="popup-title-summary">
                Loan negotiation request from borrower on portfolio.
            </span>
            <CustomContainer>
                <AppLabel color='#fff' textSize='1.4'>Loan Terms</AppLabel>
                <div className="side-bar-summary">
                    <div className="bar-summary-data">
                        <p className="bar-summary-data-text">Loan type</p>
                        <p className="bar-summary-data-text">{data?.loanOffer?.loanTypeName}</p>
                    </div>
                    <div className="bar-summary-data">
                        <p className="bar-summary-data-text">Loan amount</p>
                        <p className="bar-summary-data-text">&#8358;{utility.moneyFormat(data?.loanOffer?.amountPerPerson || data?.loanOffer?.amount || data?.loanOffer?.loanAmount)}</p>
                    </div>
                    <div className="bar-summary-data">
                        <p className="bar-summary-data-text">Interest rate</p>
                        <p className="bar-summary-data-text">{(data?.loanOffer?.interest || data?.loanOffer?.interest === 0) ? data?.loanOffer?.interest : data?.loanOffer?.interestRate}%</p>
                    </div>
                    <div className="bar-summary-data">
                        <p className="bar-summary-data-text">Loan tenor</p>
                        <p className="bar-summary-data-text">{data?.loanOffer?.tenor} {data?.loanOffer?.tenorType?.toLowerCase()}(s)</p>
                    </div>
                    <div className="bar-summary-data">
                        <p className="bar-summary-data-text">{data?.loanOffer?.repaymentTermName || data?.repaymentTerm } payment</p>
                        <p className="bar-summary-data-text">&#8358;{utility.moneyFormat(data?.loanOffer?.totalAmountPerTenorType || data?.loanOffer?.amountPlusInterestPerTenorType || data?.loanOffer?.amountPerTenorType)}</p>
                    </div>
                    <div className="bar-summary-data">
                        <p className="bar-summary-data-text">Total interest amount</p>
                        <p className="bar-summary-data-text">&#8358;{utility.moneyFormat(data?.loanOffer?.interestAmount)}</p>
                    </div>
                </div>
            </CustomContainer>
            <CustomContainer topMargin='2'>
                <AppLabel color='#fff' textSize='1.4'>Negotiation Terms</AppLabel>
                <CustomContainer 
                    className='side-bar-summary'
                    borderColor='#2C3D73'
                    bgColor='rgba(44, 61, 118, 0.2)'
                >
                    { (!!data?.interest || !!data?.interestRate) &&
                        <div className="bar-summary-data">
                            <p className="bar-summary-data-text">Interest rate</p>
                            <p className="bar-summary-data-text">{(data?.interest || data?.interest === 0) ? data?.interest : data?.interestRate}%</p>
                        </div>
                    }
                    { !!data?.tenor &&
                        <div className="bar-summary-data">
                            <p className="bar-summary-data-text">Loan tenor</p>
                            <p className="bar-summary-data-text">{data?.tenor} {data?.tenorType?.toLowerCase()}(s)</p>
                        </div>
                    }
                    { (!!data?.amountPerPerson || !!data?.amount || !!data?.loanAmount) &&
                        <div className="bar-summary-data">
                            <p className="bar-summary-data-text">Loan payment</p>
                            <p className="bar-summary-data-text">&#8358;{utility.moneyFormat(data?.amountPerPerson || data?.amount || data?.loanAmount)}</p>
                        </div>
                    }
                    { (!!data?.repaymentTermName || !!data?.repaymentTerm) &&
                        <div className="bar-summary-data">
                            <p className="bar-summary-data-text">{data?.repaymentTermName || data?.repaymentTerm} payment</p>
                            <p className="bar-summary-data-text">&#8358;{utility.moneyFormat(data?.totalAmountPerTenorType || data?.amountPlusInterestPerTenorType || data?.amountPerTenorType)}</p>
                        </div>
                    }
                    { !!data?.interestAmount &&
                        <div className="bar-summary-data">
                            <p className="bar-summary-data-text">Total interest amount</p>
                            <p className="bar-summary-data-text">&#8358;{utility.moneyFormat(data?.interestAmount)}</p>
                        </div>
                    }
                    
                </CustomContainer>
            </CustomContainer>
            <FlexRow topMargin="3" className="loan-acceptance-btns">
                <a className="underlined-link-btn" style={{color: '#FF3A5A'}} onClick={() => setShowRejectNegotiation(true)}>Reject Negotiation</a>
                <OutlineButton 
                    color="#6DE7B4" 
                    width="13" 
                    height="3.8"  
                    hoverBgColor="#FED0B7"
                    leftMargin="3"
                    titleSize='1.3' 
                    onClick={() => setShowNegotiation(true)}
                    className="loan-action-outline-btn"
                    disabled={true}
                >
                    <AppSpan textSize="1.4" color="auto">Renegotiate</AppSpan>
                </OutlineButton>
                <Button 
                    width="15" 
                    height="3.8"
                    hoverBgColor="#FED0B7"
                    leftMargin="1"
                    titleSize='1.3'
                    onClick={handleAcceptNegotiation}
                >
                    Accept Negotiation
                </Button>
            </FlexRow>
          </SidePopup>
        </SidePopupContainer>
    }
    { showNegotiation &&
        <LoanNegotiation 
            close={() => setShowNegotiation(false)}
            data={data}
            type="lender"
        />
    }
    { showRejectNegotiation &&
        <NegotiationRejectionReason 
            data={data}
            close={() => setShowRejectNegotiation(false)}
            closeNegotiation={close}
        />
    }
    </>
  )
}
