import { useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import ApiContext from 'src/provider/API/call-service'
import { 
    ResponderContentWrapper, 
    ResponderIconCircle, 
    ResponderIconHolder, 
} from 'src/component/Responder/style'
import { CenterPopupContainer, CenterPopup, Button, Icon, FlexRow, AppText, AppSpan } from 'src/styles'
import { images } from 'src/provider/config/constant'


export const PostponeQuestionaire: React.FC<any> = ({close, data, tabType}) => {
    const navigate = useNavigate()
    const {API} = useContext(ApiContext)

    async function handleSave () {
        const response = tabType === 'trust' ? await API.saveTrustAnswers(data) : await API.savePersonalityAnswers(data)
        if (response) navigate('/dashboard/home')
        else close()
    }

    return (
        <CenterPopupContainer zIndex="8">
            <CenterPopup data-aos="slide-down">
                <i className="fa fa-times responder-cancel" onClick={close}></i>
                    <ResponderContentWrapper>
                        <ResponderIconCircle>
                            <Icon src={images.WARNING_RESPONSE} />
                        </ResponderIconCircle>
                        <ResponderIconHolder></ResponderIconHolder>
                        <AppText topMargin="3" fontWeight="400" align="center" textSize='1.4'>
                            You will not be able to take a loan at this moment if you choose to continue this process later.   
                        </AppText>
                        <AppSpan topMargin="5" textSize='1.4'>Do you still want to continue later?</AppSpan>
                        <FlexRow justifyContent="flex-end" className="loan-acceptance-btns" topMargin="7">
                            <a className="underlined-link-btn" style={{color: '#FF3A5A'}} onClick={handleSave}>Yes, Do it later</a>
                            <Button 
                                width="17" 
                                height="4"
                                hoverBgColor="#FED0B7"
                                leftMargin="3.5"
                                onClick={close}
                            >
                                No, Keep going
                            </Button>
                        </FlexRow>
                    </ResponderContentWrapper>
            </CenterPopup >
        </CenterPopupContainer>
    )
}
