import './style.scss'
import { useEffect, useState, useContext } from 'react'
import utility from 'src/utils/utility'
import helpers from 'src/utils/helpers'
import { useNavigate } from 'react-router-dom'
import ApiContext from 'src/provider/API/call-service'
import { Container } from 'src/component'
import { BorderlessButton, Button, FlexRow } from 'src/styles'
import { MdRefresh } from 'react-icons/md'



const SignIn: React.FC = () => {
    const navigate = useNavigate()
    const {API} = useContext(ApiContext)
    const [isAuth, setIsAuth] = useState<any>(null)
    const [padNumbers, setPadNumbers] = useState<Array<number>>([])
    const [input, setInput] = useState('')
    const [username, setUsername] = useState('')

    useEffect(() => {
        let isAuthenticated = localStorage.getItem('token') && localStorage.getItem('hasLogin') ? true : false
        setIsAuth(isAuthenticated)
        if(isAuthenticated) navigate('/dashboard')
    }, [])

    useEffect(() => {
        handlePadRefresh()
    }, [])

    function handlePadRefresh () {
        setPadNumbers(helpers.generatePadNumbers())
    }

    function handleButtonClick (val: number) {
        if (input.length < 6) {
            setInput(input + String(val))
        }
    }

    function handleDeleteInput () {
        if (input.length > 0) {
            setInput(input.substring(0, input.length - 1))
        }
    }

    function handleClearInput () {
        setInput('')
    }

    async function handleLogin () {
        const response = await API.signIn({email: username, password: input})
        if (response) navigate('/dashboard/home')
    }

    return (
        <>
            { !isAuth ?
                <Container>
                    <p className="right-align-text">Don't have an account? 
                        <a className="registration-link" onClick={() => navigate('/onboarding/type')}> Sign up</a>
                    </p>
                    <div className="main-content-wrapper">
                        <h3>Sign in to your account</h3>
                        <p>Sign in to see what's happening on your account. Feel free to look around, it's yours</p>
                        <form className="login-form">
                            <div className="form-group-wrapper">
                                <label className="form-label">Username</label>
                                <input 
                                    className="form-input-field" 
                                    placeholder="Email or Phone number"
                                    value={username}
                                    onChange={(e) => setUsername(e.target.value)}
                                />
                            </div>
                            <div className="form-group-wrapper">
                                <label className="form-label">Passcode</label>
                                <div className="passcode-input-wrapper">
                                    <input disabled className="passcode-input" value={(input[0] && '*') || ''} />
                                    <input disabled className="passcode-input" value={(input[1] && '*') || ''} />
                                    <input disabled className="passcode-input" value={(input[2] && '*') || ''} />
                                    <input disabled className="passcode-input" value={(input[3] && '*') || ''} />
                                    <input disabled className="passcode-input" value={(input[4] && '*') || ''} />
                                    <input disabled className="passcode-input" value={(input[5] && '*') || ''} />
                                </div>
                            </div>
                            <FlexRow justifyContent="flex-start">
                                <BorderlessButton 
                                    titleSize="1.4" color="#D5D5D5" 
                                    fontWeight="400" hoverColor="#6DE7B4"
                                    onClick={() => navigate('/forget-password/identification')}
                                >
                                    Forget Passcode?
                                </BorderlessButton>
                            </FlexRow>
                        </form>
                    </div>
                    <div className="form-btn-wrapper">
                        <Button 
                            width="18" 
                            height="4.5" 
                            hoverBgColor="#FFC49D" 
                            rightMargin="35"
                            className='signin-btn loan-action-btn'
                            disabled={(input.length != 6 || !username)}
                            onClick={handleLogin}
                        >
                            Login
                        </Button>
                        <div className="keypad-container">
                            <FlexRow justifyContent="flex-start">
                                <button className="keypad" onClick={() => handleButtonClick(padNumbers[0])}>{padNumbers[0]}</button>
                                <button className="keypad" onClick={() => handleButtonClick(padNumbers[1])}>{padNumbers[1]}</button>
                                <button className="keypad" onClick={() => handleButtonClick(padNumbers[2])}>{padNumbers[2]}</button>
                                <button className="keypad" onClick={() => handleButtonClick(padNumbers[3])}>{padNumbers[3]}</button>
                            </FlexRow>
                            <FlexRow justifyContent="flex-start">
                                <button className="keypad" onClick={() => handleButtonClick(padNumbers[4])}>{padNumbers[4]}</button>
                                <button className="keypad" onClick={() => handleButtonClick(padNumbers[5])}>{padNumbers[5]}</button>
                                <button className="keypad" onClick={() => handleButtonClick(padNumbers[6])}>{padNumbers[6]}</button>
                                <button className="keypad" onClick={() => handleButtonClick(padNumbers[7])}>{padNumbers[7]}</button>
                            </FlexRow>
                            <FlexRow justifyContent="flex-start">
                                <button className="keypad" onClick={() => handleButtonClick(padNumbers[8])}>{padNumbers[8]}</button>
                                <button className="keypad" onClick={() => handleButtonClick(padNumbers[9])}>{padNumbers[9]}</button>
                                <button className="refresh-btn" onClick={handlePadRefresh}>
                                    <MdRefresh style={{fontSize: '3rem'}} />
                                </button>
                            </FlexRow>
                            <FlexRow justifyContent="flex-start" topMargin="1">
                                <button className="delete-btn" onClick={handleDeleteInput}>Delete</button>
                                <button className="clear-btn" onClick={handleClearInput} >Clear</button>
                            </FlexRow>
                        </div>
                    </div>
                </Container>
                : null
            }
        </>
    )
}

export default utility.routeData('/login', 'SignIn', <SignIn/>)