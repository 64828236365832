import { useEffect, useContext, useState } from 'react'
import { Outlet, useNavigate } from 'react-router-dom'
import { useIdleTimer } from 'react-idle-timer'
import { appSettings } from 'src/provider/config/constant'
import AppInfoContext from 'src/provider/state-manager/appInfoProvider'
import ApiContext from 'src/provider/API/call-service'
import utility from 'src/utils/utility'
import access from 'src/utils/localAccess'
import ReactTooltip from 'react-tooltip'
import { useGAEventTracker } from 'src/hooks'
import helpers from 'src/utils/helpers'

export const OnboardingRoutes = () => {
    const navigate = useNavigate()
    const {API} = useContext(ApiContext)
    const [isAuth, setIsAuth] = useState<any>(null)
    const {recoverStateData, logout, info} = useContext(AppInfoContext)
    const GAEventTracker = useGAEventTracker('Onboarding')

    useEffect(() => {
        let isAuthenticated = localStorage.getItem('token') && localStorage.getItem('hasLogin') ? true : false
        setIsAuth(isAuthenticated)
        if(isAuthenticated) navigate('/dashboard')
    }, [])

    useEffect(() => {
        recoverStateData()
        access.setLogoutHandler(handleLogout)
        access.setNavigationAccess(navigate)
        handleAuth()
    }, [])

    async function handleAuth() {
        const sessionExpiryTime = await localStorage.getItem('onboarding-session-expiry-time')

        let isSessionExpire = false
        const currentDate = new Date()

        if (sessionExpiryTime) {
            if (currentDate > new Date(sessionExpiryTime)) isSessionExpire = true
        }

        if (isSessionExpire) handleLogout()
    }

    // =========================== HANDLE ONBOARDING INACTIVITY ======================
    const onAction = (e: any) => helpers.setSessionExpiryTime('onboarding')
    const onActive = (e: any) => {}
    const onIdle = async() => {
        const route = window.location.href.split('/').pop()
        console.log({route})
        if (route != 'bvn-details') {
            const response = await API.updateLastPageVisited(route)
            if (response) handleLogout()
        }
    }

    const idleTimer = useIdleTimer({
        timeout: appSettings.INACTIVITY_TIME,
        onIdle,
        onActive,
        onAction,
        debounce: 500
    })

    async function handleLogout() {
        GAEventTracker('Inactivity Logout', `User: ${info.onBoardingData.email || ''}`)
        await logout()
    }

    return (
        <>
            { 
               !isAuth ? 
                <>
                    <Outlet/> 
                    <ReactTooltip className='tooltip-style' arrowColor='#081952' multiline={true} />
                </>
               : null
            }
        </>
    )
}

export default utility.routeData('onboarding', 'Onboarding', <OnboardingRoutes/>)