import { images } from 'src/provider/config/constant'
import { useState, useEffect, useContext } from 'react'
import ApiContext from 'src/provider/API/call-service'
import { CircularProgressbar } from 'react-circular-progressbar'
import { 
    SidePopupContainer, 
    SidePopup, 
    Button, 
    AppSpan,  
    FlexRow,
    ScreenTitle,
    CustomContainer,
    AppText,
    OutlineButton,
    Icon,
    RoundImageContainer,
    FlexColumn,
    AppLink,
    Circle
} from "src/styles"
import utility, { getLoanStatusColor } from 'src/utils/utility'
import { BorrowerLoanRequest } from 'src/popup'

export const Portfolio: React.FC<any> = ({close, viewDetails, data}) => {
    const {API} = useContext(ApiContext)
    const [showBorrowerRequest, setShowBorrowerRequest] = useState(false)
    const [allBorrowers, setAllBorrowers] = useState<any>(null)
    const [borrowerRequest, setBorrowerRequest] = useState({})

    useEffect(() => {
        fetchBorrowers()
    }, [data])

    async function fetchBorrowers () {
        const response = await API.fetchOfferBorrowers(data?.loanOfferId)
        if (response) setAllBorrowers(response)
    }

    function handlePortfolioDetails () {
        viewDetails()
        close()
    }

    function handleBorrowerRequest (item: any) {
        setBorrowerRequest(item)
        setShowBorrowerRequest(true)
    }

    return (
        <>
            { !showBorrowerRequest &&
                <SidePopupContainer >
                    <SidePopup data-aos="slide-left">
                        <FlexRow justifyContent="space-between" topMargin="3" bottomMargin="4">
                            <ScreenTitle textSize="2">Portfolio</ScreenTitle>
                            <AppSpan className="fa fa-times" color="#FED0B7" textSize="1.7" onClick={close}></AppSpan>
                        </FlexRow>

                        <CustomContainer
                            topPadding="1.5" 
                            leftPadding="1.5"
                            rightPadding="1.5"
                            bottomPadding="2"
                            bgColor="#4700E0"
                            radius="0.8"
                        >
                            <FlexRow justifyContent="space-between" topMargin="1" wrap="wrap">
                                <CustomContainer width="auto" sizeUnit="" topMargin="1">
                                    <AppSpan textSize="1.3" fontWeight="400">Portfolio Amount</AppSpan>
                                    <AppText textSize="2">₦{utility.moneyFormat(data?.amount)}</AppText>
                                </CustomContainer>
                                <FlexRow width="auto" sizeUnit=""  topMargin="1">
                                    <AppSpan textSize="1.4" rightMargin="1.5">Portfolio Status</AppSpan>
                                    <OutlineButton 
                                        hoverBgColor="transparent"  
                                        width="9" 
                                        height="3" 
                                        titleSize="1.3" 
                                        fontWeight="700" 
                                        color={data?.completed ? '#6DE7B4' : '#F5B070'}
                                        hoverColor={data?.completed ? '#6DE7B4' : '#F5B070'}
                                        radius="2"
                                    >
                                        {data?.completed ? 'Completed' : !data?.completed && data?.taken ? 'Running'  : 'Open'}
                                    </OutlineButton>
                                </FlexRow>
                            </FlexRow>
                            <FlexRow justifyContent="space-between" topMargin="3" wrap="wrap">
                                <CustomContainer width="auto" sizeUnit="" rightMargin="2" topMargin="1">
                                    <AppSpan fontWeight="400" textSize="1.3">Loan type</AppSpan>
                                    <AppText>{data?.loanTypeName}</AppText>
                                </CustomContainer>
                                {/* <CustomContainer width="auto" sizeUnit="" rightMargin="2" topMargin="1">
                                    <AppSpan fontWeight="400" textSize="1.3">Loan amount</AppSpan>
                                    <AppText>₦150,000</AppText>
                                </CustomContainer> */}
                                <CustomContainer width="auto" sizeUnit=""  topMargin="1" rightMargin="2">
                                    <AppSpan fontWeight="400" textSize="1.3">Loan per/person</AppSpan>
                                    <AppText>₦{utility.moneyFormat(data?.amountPerPerson)}</AppText>
                                </CustomContainer>
                                <CustomContainer width="auto" sizeUnit=""  topMargin="1">
                                    <AppSpan fontWeight="400" textSize="1.3">No of Loanees</AppSpan>
                                    <AppText>{allBorrowers?.length || '0'}</AppText>
                                </CustomContainer>
                            </FlexRow>
                            <FlexRow justifyContent="space-between" topMargin="3" wrap="wrap">
                                <CustomContainer width="auto" sizeUnit="" rightMargin="2" topMargin="1">
                                    <AppSpan fontWeight="400" textSize="1.3">Duration</AppSpan>
                                    <AppText>{data?.tenor} {data?.tenorType.toLowerCase()}(s)</AppText>
                                </CustomContainer>
                                <CustomContainer width="auto" sizeUnit="" rightMargin="2" topMargin="1">
                                    <AppSpan fontWeight="400" textSize="1.3">interest rate</AppSpan>
                                    <AppText>{data?.interest}%</AppText>
                                </CustomContainer>
                                <CustomContainer width="auto" sizeUnit="" topMargin="1">
                                    <AppSpan fontWeight="400" textSize="1.3">Total Repayment</AppSpan>
                                    <AppText>₦{utility.moneyFormat(data?.totalAmountToBeRepaid)}</AppText>
                                </CustomContainer>
                            </FlexRow>
                        </CustomContainer>

                        <CustomContainer topMargin="2">
                            { allBorrowers?.length > 0 ?
                                allBorrowers?.map((item: any, index: number) => {
                                    return (
                                        <FlexRow 
                                            bgColor="#000926" 
                                            justifyContent="space-between"
                                            topPadding="1" 
                                            leftPadding="1"
                                            rightPadding="1"
                                            bottomPadding="1"
                                            topMargin="0.7"
                                            radius="0.8"
                                            gap='1'
                                            key={index}
                                            onClick={() => handleBorrowerRequest(item)}
                                        >
                                            <FlexRow 
                                                width="auto" sizeUnit="" 
                                                alignItems="center" // flex-start
                                            >
                                                {/* <RoundImageContainer size="3" bgColor="#fff" isMaxWidth={false}>
                                                    <Icon src={images.USER} style={{width: '2rem'}} />
                                                </RoundImageContainer> */}
                                                <Circle 
                                                    size='4.2' rightMargin='1' bgColor='#ffffff'
                                                >
                                                    <Circle
                                                        size='3.9'
                                                        bgColor={getLoanStatusColor(item?.userTag)}
                                                    >
                                                        <RoundImageContainer 
                                                            size="3.3" borderColor='#ffffff'
                                                            style={{borderWidth: '1.5px'}}
                                                        >
                                                            <Icon 
                                                                src={item.profilePictureUrl || images.USER}
                                                                width='3.1' radius='50' height='3.1' noResize
                                                            />
                                                        </RoundImageContainer>
                                                    </Circle>
                                                </Circle>
                                                <CustomContainer width="auto" sizeUnit=""  leftMargin="1" topMargin="-0.5">
                                                    <AppSpan textSize="1.3" fontWeight="700">{item.firstName} {item.lastName}</AppSpan>
                                                    <AppText textSize="0.9" fontWeight="400" color="#E2E2E2">{item?.loanTypeName}</AppText>
                                                </CustomContainer>
                                            </FlexRow>
                                            <AppSpan textSize='1.3' fontWeight='500' color='#FED0B7' hoverColor='#6DE7B4' cursor='pointer'>View offer</AppSpan>
                                            <CustomContainer width="auto" sizeUnit=""  leftMargin="1">
                                                <AppSpan fontWeight="700" textSize="1.4" color="#F2EAE0" align="right">N{utility.moneyFormat(item?.loanOffer?.amountPerPerson || item?.amount)}</AppSpan>
                                                <AppText textSize="0.9" align="right">{(data?.loanOffer?.tenor || data?.loanOffer?.tenor === 0) ? data?.loanOffer?.tenor : data?.tenor} {data?.loanOffer?.tenorType.toLowerCase() || data?.tenorType.toLowerCase()}(s)</AppText>
                                            </CustomContainer>
                                        </FlexRow>
                                    )
                                })
                                : 
                                <>
                                    <AppText textSize="1.65">Loanees</AppText>
                                    <FlexColumn topMargin='1'>
                                        <AppText textSize="1.8" width="25" topMargin="3" align="center">No Borrowers Yet</AppText>
                                        <AppSpan color="#E2E2E2" textSize="1.3" fontWeight="400" topMargin="1" align="center" width="30">You did not have borrowers for this loan yet.</AppSpan>
                                    </FlexColumn>
                                </>
                            }
                        </CustomContainer>
                        <OutlineButton 
                            hoverBgColor="#FED0B7"  
                            width="100" 
                            height="5" 
                            sizeUnit="%"
                            color="#6DE7B4"
                            topMargin="4"
                            onClick={handlePortfolioDetails}
                        >
                            View Portfolio Details
                        </OutlineButton>
                    </SidePopup>
                </SidePopupContainer>
            }

            { showBorrowerRequest &&
                <BorrowerLoanRequest 
                    close={() => setShowBorrowerRequest(false)}
                    data={borrowerRequest}
                />
            }
        </>
    )
}
