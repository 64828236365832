import { useEffect, useContext } from 'react'
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom'
import AOS from 'aos'
import 'aos/dist/aos.css'
import modules from 'src/app/app-module'
import dashboardModules from 'src/app/dashboard/dashboard-module'
import exploreModules from 'src/app/explore/explore-module'
import onboardingModules from 'src/app/onboarding/onboarding-module'
import VisibilityContext from 'src/provider/state-manager/visibilityProvider'
import ApiContext from 'src/provider/API/call-service'
import ReactGA from 'react-ga'
import { credentials } from 'src/provider/config/constant'
import Home from 'src/app/dashboard/home'
import ExploreHome from 'src/app/explore/home'
import OnboardType from 'src/app/onboarding/onboardType'
import access from 'src/utils/localAccess'
import { Complaint, PopUps } from 'src/component'

ReactGA.initialize(credentials.GA_MEASUREMENT_ID);

export const AppRoutes = () => {
    const {notifier, isConnected} = useContext(VisibilityContext)
    const {getDeviceInfo} = useContext(ApiContext)

    useEffect(() => {
        AOS.init({
            duration: 300,
            delay: 20,
            easing: 'linear'
        })
    }, [])

    useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);
      }, [window.location.pathname]);

    useEffect(() => {
        let isOnline = window.navigator.onLine
        access.setInternetStatus(isOnline)
        isConnected(isOnline)
        getDeviceInfo()
    }, [])

    useEffect(() => {
        window.addEventListener("offline", 
        () => handleInternetConnection(false)
      );
        window.addEventListener("online", 
        () => handleInternetConnection(true)
      );

      return () => {
        window.removeEventListener("offline", () => handleInternetConnection(false)
        );
        window.removeEventListener("online", () => handleInternetConnection(true));
      };
    }, [])

    function handleInternetConnection (isOnline: boolean) {
        if(!isOnline)  notifier.show('Oops...You are offline', 'warning')
        else notifier.show('Network has been restored', 'success')
        access.setInternetStatus(isOnline)
        isConnected(isOnline)
    }

    return (
        <>
            <PopUps />
            <Complaint />
            <Router>
                <Routes>
                    {
                        modules.map(module => (
                            module.name === 'Dashboard' ? 
                                <Route {...module.routeProps} key={module.name} >
                                    {
                                        dashboardModules.map(dashboardModule => (
                                            <Route {...dashboardModule.routeProps} key={dashboardModule.name}  />
                                        ))
                                    } 
                                      <Route index element={Home.routeProps.element} />
                                </Route> 
                            :
                            module.name === 'Explore' ?
                                <Route {...module.routeProps} key={module.name} >
                                    {
                                        exploreModules.map(exploreModule => (
                                            <Route {...exploreModule.routeProps} key={exploreModule.name}  />
                                        ))
                                    } 
                                      <Route index element={ExploreHome.routeProps.element} />
                                </Route> 
                            :
                            module.name === 'Onboarding' ?
                                <Route {...module.routeProps} key={module.name} >
                                    {
                                        onboardingModules.map(onboardingModule => (
                                            <Route {...onboardingModule.routeProps} key={onboardingModule.name}  />
                                        ))
                                    } 
                                      <Route index element={OnboardType.routeProps.element} />
                                </Route> 
                            :
                                <Route {...module.routeProps} key={module.name}  />
                        ))
                    }
                    <Route path="*" element={<Navigate to="/" />}/>
                </Routes>
            </Router>
        </>
    )
}