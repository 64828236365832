import { useContext } from 'react'
import ApiContext from 'src/provider/API/call-service'
import { 
    ResponderContentWrapper
} from 'src/component/Responder/style'
import { CenterPopupContainer, CenterPopup, Button, Icon, FlexRow, AppText, AppSpan } from 'src/styles'


export const DeletePaymentData: React.FC<any> = ({close, type}) => {
    const {API} = useContext(ApiContext)

    async function handleDeletePaymentData () {
        if (type === 'card') {
            const response = await API.deleteCardData()
        }
        else {
            const response = await API.deleteBankData()
        }

        close()
    }

    return (
        <CenterPopupContainer zIndex="8">
            <CenterPopup data-aos="slide-down">
                <i className="fa fa-times responder-cancel" onClick={close}></i>
                    <ResponderContentWrapper>
                        <AppText topMargin="12" fontWeight="700" align="center" textSize='3'>
                            {type === 'card' ? 'Delete Card' : 'Delete Bank Account'}
                        </AppText>
                        <AppSpan topMargin="1.5" textSize='1.4' fontWeight="400">
                            {type === 'card' ? 'Are you sure you want to delete this Card?' : 'Are you sure you want to delete this bank account?'}
                        </AppSpan>
                        <FlexRow justifyContent="flex-end" className="loan-acceptance-btns" topMargin="9">
                            <a className="underlined-link-btn" style={{color: '#FF3A5A'}} onClick={handleDeletePaymentData}>
                                {type==="card" ? 'Yes, Delete Card' : 'Yes, Delete Account'}
                            </a>
                            <Button 
                                width="17" 
                                height="4"
                                hoverBgColor="#FED0B7"
                                leftMargin="3.5"
                                onClick={close}
                            >
                                No, Keep Data
                            </Button>
                        </FlexRow>
                    </ResponderContentWrapper>
            </CenterPopup >
        </CenterPopupContainer>
    )
}
