import { useState, useContext } from 'react'
import ApiContext from 'src/provider/API/call-service'
import { AiFillStar } from 'react-icons/ai'
import { 
    CenterPopupContainer, FlexColumn, Button, 
    ScreenTitle, FlexRow, AppSpan 
} from 'src/styles'


export const Rating: React.FC<any> = ({close}) => {
    const {API} = useContext(ApiContext)
    const [rating, setRating] = useState(0)
    const [comment, setComment] = useState('')

    async function handleSubmitFeedback () {
        await API.submitFeedback(rating, comment)
        close()
    }

    return (
        <CenterPopupContainer zIndex="7">
            <div className="rating-popup-container" data-aos="slide-down">
                <i className="fa fa-times responder-cancel" onClick={close}></i>
                    <FlexColumn rightPadding="2.5" leftPadding="2.5" topMargin="4">
                        <ScreenTitle textSize="2" width="35" align="center">
                            Rate your experience with the crendly app.
                        </ScreenTitle>
                        <FlexRow width="auto" sizeUnit="" justifyContent='flex-start' topMargin="6">
                            {
                                Array.from(Array(5).keys()).map((item: any, index: number) => {
                                    let color = index+1 <= rating ? '#FED0B7' : '#2E4DBD'
                                    return (
                                        <AiFillStar 
                                            className="rating-icon" 
                                            style={{color: color}} 
                                            key={index}
                                            onClick={() => setRating(index+1)}
                                        />
                                    )
                                })
                            }
                        </FlexRow>
                        <AppSpan textSize="1.4" topMargin="6">Write a comment</AppSpan>
                        <textarea 
                            className="comment-input" 
                            value={comment} 
                            onChange={(e) => setComment(e.target.value)}
                        />
                        <FlexRow justifyContent="flex-end" className="loan-acceptance-btns" topMargin="5">
                            <a className="underlined-link-btn" onClick={close} >
                                Not Now
                            </a>
                            <Button 
                                width="20" 
                                height="4"
                                hoverBgColor="#FED0B7"
                                leftMargin="3.5"
                                titleSize="1.4"
                                onClick={handleSubmitFeedback}
                            >
                                Submit your feedback
                            </Button>
                        </FlexRow>
                    </FlexColumn>
            </div >
        </CenterPopupContainer>
    )
}
