import { useState, useContext, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import ApiContext from 'src/provider/API/call-service'
import LoanContext from 'src/provider/state-manager/loanProvider'
import { images, loanConfig } from 'src/provider/config/constant'
import { SidePopupContainer, SidePopup, Divider, OutlineButton, FlexRow  } from "src/styles"
import utility from 'src/utils/utility'
import { DecisionBox } from 'src/popup'

export const PortfolioSummary: React.FC<any> = ({data, close}) => {
  const navigate = useNavigate()
  const {API} = useContext(ApiContext)
  const {setLoanProperty} = useContext(LoanContext)
  const [showConfirmCancel, setShowConfirmCancel] = useState(false)

  async function handleDeletePortfolio () {
    const response = await API.cancelPortfolio(data?.loanOfferId)
    if (response) {
      navigate('/dashboard/loan')
      close()
    }
  }

  async function handleEditPortfolio () {
    const {
      loanTypeId, tenor, tenorType, interest, 
      amount, repaymentTermId, rateId, isNegotiable, 
      isExtensionAllowed, canBeExtendedBy, loanOfferId,
      numberOfPerson
    } = data

    await setLoanProperty(
      'loanCreation', 
      {
        loanTypeId, tenor, tenorType, isInterest: interest ? 'Yes' : 'No', 
        interest, amount: data?.loanOffer?.amount || amount, repaymentTermId, rateId, isNegotiable, 
        isExtensionAllowed, canBeExtendedBy, editType: 'existing-portfolio',
        loanOfferId, numberOfPerson
      }
  )
    navigate('/dashboard/loan-creation/loan-type')
  }

  return (
    <>
      <SidePopupContainer >
        <SidePopup data-aos="slide-left">
          <div className="popup-title-wrapper">
            <h4>Loan Portfolio Details</h4>
            <div className="side-popup-nav-icons">
              <p onClick={handleEditPortfolio}>
                <span className="fa fa-pencil">
                  <span>Edit</span>
                </span>
              </p>
              <i className="fa fa-times" onClick={close}></i>
            </div>
          </div>

          <div className="side-bar-summary">
            <div className="bar-summary-data">
              <p className="bar-summary-data-text">Loan type</p>
              <p className="bar-summary-data-text">{data?.loanTypeName || loanConfig.loanType[data?.loanTypeId]}</p>
            </div>
            <div className="bar-summary-data">
              <p className="bar-summary-data-text">Portfolio amount</p>
              <p className="bar-summary-data-text">&#8358;{utility.moneyFormat(data?.loanOffer?.amount || data?.amount)}</p>
            </div>
            <div className="bar-summary-data">
              <p className="bar-summary-data-text">Loan amount / borrower</p>
              <p className="bar-summary-data-text">&#8358;{utility.moneyFormat(data?.loanOffer?.amountPerPerson || data?.amountPerPerson)}</p>
            </div>
            <div className="bar-summary-data">
              <p className="bar-summary-data-text">Amount Funded</p>
              <p className="bar-summary-data-text">&#8358;{utility.moneyFormat(data?.loanOffer?.amountLocked || data?.amountLocked)}</p>
            </div>
            <div className="bar-summary-data">
              <p className="bar-summary-data-text">Interest rate</p>
              <p className="bar-summary-data-text">{data?.interest}%</p>
            </div>
            {/* <div className="bar-summary-data">
              <p className="bar-summary-data-text">Interest type</p>
              <p className="bar-summary-data-text">Monthly</p>
            </div> */}
            <div className="bar-summary-data">
              <p className="bar-summary-data-text">Loan tenor</p>
              <p className="bar-summary-data-text">{data?.tenor} {data?.tenorType?.toLowerCase()}(s)</p>
            </div>
            <div className="bar-summary-data">
              <p className="bar-summary-data-text">{data?.repaymentTermName} payment / borrower</p>
              <p className="bar-summary-data-text">&#8358;{utility.moneyFormat(data?.repaymentPerTenorBorrower)}</p>
            </div>
            <div className="bar-summary-data">
              <p className="bar-summary-data-text">Total interest amount</p>
              <p className="bar-summary-data-text">&#8358;{utility.moneyFormat(data?.interestAmount * data?.numberOfPerson)}</p>
            </div>
            <div className="bar-summary-data">
              <p className="bar-summary-data-text">Total repayment amount</p>
              <p className="bar-summary-data-text">&#8358;{utility.moneyFormat(data?.totalAmountToBeRepaid)}</p>
            </div>
            <Divider margin="1" />
            <div className="bar-summary-data">
              <p className="bar-summary-data-text">Repayment type</p>
              <p className="bar-summary-data-text">{data?.rateId == 1 ? 'Installment' : 'One-off'}</p>
            </div>
            <div className="bar-summary-data">
              <p className="bar-summary-data-text">Repayment occurrence</p>
              <p className="bar-summary-data-text">{data?.repaymentTermName}</p>
            </div>
            {/* <div className="bar-summary-data">
              <p className="bar-summary-data-text">Repayment start date</p>
              <p className="bar-summary-data-text">21 Mar 2021</p>
            </div>
            <div className="bar-summary-data">
              <p className="bar-summary-data-text">Repayment end date</p>
              <p className="bar-summary-data-text">21 Jun 2021</p>
            </div> */}
            <Divider margin="1" />
            <div className="bar-summary-data">
              <p className="bar-summary-data-text">Negotiation</p>
              <p className="bar-summary-data-text" style={{color: '#6DE7B4'}}>{data?.isNegotiable ? 'YES' : 'NO'}</p>
            </div>
            <div className="bar-summary-data">
              <p className="bar-summary-data-text">Loan extension</p>
              <p className="bar-summary-data-text" style={{color: '#6DE7B4'}}>{data?.isExtensionAllowed ? 'YES' : 'NO'}</p>
            </div>
            <div className="bar-summary-data">
              <p className="bar-summary-data-text">Loan extension time</p>
              <p className="bar-summary-data-text">{data?.canBeExtendedBy} days</p>
            </div>
          </div>
          <FlexRow justifyContent='flex-end' topMargin="8">
            <OutlineButton
              width="18"
              color="#6DE7B4" 
              hoverBgColor="#FED0B7"
              onClick={() => setShowConfirmCancel(true)}
            >
              Cancel Portfolio
            </OutlineButton>
          </FlexRow>
        </SidePopup>
      </SidePopupContainer>
      { showConfirmCancel &&
        <DecisionBox
          close={() => setShowConfirmCancel(false)}
          title='Cancelling  this portfolio will automatically delete it'
          subheading='Do you still want to cancel this portfolio?'
          noText="Yes, Cancel Portfolio"
          yesText="No, Don't"
          noColor='#FF0062'
          icon={images.WARNING_RESPONSE}
          noMethod={handleDeletePortfolio}
        />
      }
    </>
  )
}
