import { useState, useContext, useEffect } from 'react'
import ApiContext from 'src/provider/API/call-service'
import { SidePopupContainer, SidePopup, Divider, Button, AppSpan, CustomContainer, AppText, FlexRow, FlexColumn, Icon  } from "src/styles"
import { ImNotification } from 'react-icons/im'
import { LoanRequestDetails, OfferSummary } from 'src/popup'
import { images } from 'src/provider/config/constant'
import helpers from 'src/utils/helpers'
import { explain } from 'src/utils/utility'
import ReactTooltip from 'react-tooltip'

export const RequestList: React.FC<any> = ({close}) => {
    const {API} = useContext(ApiContext)
    const [isLoan, setIsLoan] = useState(false)
    const [usersRequests, setUsersRequests] = useState<any>(null)
    const [myRequests, setMyRequests] = useState<any>(null)
    const [showLoanDetails, setShowLoanDetails] = useState(false)
    const [showOfferDetails, setShowOfferDetails] = useState(false)
    const [details, setDetails] = useState({})
    var [time, setTime] = useState<any>(null)
    var [count, setCount] = useState<any>(null)

    useEffect(() => {
        ReactTooltip.rebuild();
        if (!usersRequests) handleFetchRequests()
        if(usersRequests?.length) {
            time =  setInterval(() => {
              setCount((count: number) => count + 1);
            }, 1000)
        }
        return () => { clearInterval(time) }
    }, [usersRequests])

    async function handleViewRequest (type: string, data: any) {
        setDetails(data)
        if (type === 'loan') setShowLoanDetails(true)
        else setShowOfferDetails(true)
    }

    async function handleFetchRequests () {
        const response1 = await API.getLoanRequestsOnUserOffers()
        if (response1) {
            console.log({response1})
            setUsersRequests(response1)
            const response2 = await API.getStatusOfLoanOffersApplied()
            if (response2) setMyRequests(response2)
        }
    }

    return (
        <>
            { (!showLoanDetails && !showOfferDetails) &&
                <SidePopupContainer >
                    <SidePopup width="65" data-aos="slide-left">
                        <div className="popup-title-wrapper">
                            <h4>My Requests</h4>
                            <i className="fa fa-times" onClick={close}></i>
                        </div>
                        <span className="popup-title-summary">
                            See  all Loan requests and all offer requests.
                        </span>

                        <div className="calc-type-toggler">
                            <input type="checkbox" className="calc-toggler-checkbox" defaultChecked={isLoan} onChange={(e) => setIsLoan(e.target.checked)} />
                            <div className="calc-type-slider"></div>
                            <div className="calc-type-text">
                                <span className="calc-type-1">Loan Requests Received</span>
                                <span className="calc-type-2">Your Loan Application</span>
                            </div>
                        </div>
                        { (!isLoan && usersRequests?.length > 0) &&
                            <>
                                {
                                    usersRequests?.map((item: any, index: number) => {

                                        //let timeLeft = helpers.convertSecondsToHMS(helpers.convertHMStoSeconds(item.timeLeft) - count)
                                        let timeDiffCheck = helpers.getTimeDifference(item?.dateSubmitted)
                                        let timeLeft = helpers.convertSecondsToHMS(timeDiffCheck.seconds)
                                        let hr = helpers.formatTimeInfo(timeLeft.split(':')[0])
                                        let min = helpers.formatTimeInfo(timeLeft.split(':')[1])
                                        let sec = helpers.formatTimeInfo(timeLeft.split(':')[2])
                                        let color = (Number(hr) <= 0 && Number(min) <= 10) ? '#FF3A5A' : '#6DE7B4'
                                        let dateTime = helpers.splitDateTime(item?.dateSubmitted)
                                        return (
                                            // (helpers.convertHMStoSeconds(item.timeLeft) > count) &&
                                            timeDiffCheck.hasNotExceededTime &&
                                            <div key={index}>
                                                <CustomContainer topMargin='3.5'>
                                                    <AppText textSize='1.7' color='#AAAAAA' fontWeight='400'>{dateTime?.slashedDate} {dateTime?.completeTime}</AppText>
                                                    <FlexRow justifyContent="space-between" topMargin='0.3'>
                                                        <AppText textSize="1.7" rightPadding='2'>
                                                            Loan request From <AppSpan color='#6DE7B4' textSize="1.7">{item.firstName} {item.lastName}</AppSpan>
                                                        </AppText>
                                                        <FlexRow width="auto" sizeUnit="">
                                                            <AppText color={color} textSize="2.5" rightMargin='2'>{timeLeft}</AppText>
                                                            {/* <ImNotification style={{fontSize: '1.7rem'}} /> */}
                                                            {explain(
                                                                `This timer shows how long you have to attend to this loan request
                                                                before it expires.`, true, '0', '1.7'
                                                            )}
                                                        </FlexRow>
                                                    </FlexRow>
                                                    <AppText fontWeight="400" width='40' textSize="1.6" className='notif-req-text'>
                                                        You have {hr} hour(s), {min} minute(s) and {sec} seconds to attend to this loan request. Please try to attend to this request as soon as possible.
                                                    </AppText>
                                                    <Button
                                                        width="13"
                                                        height="3.5"
                                                        hoverBgColor="#FED0B7" 
                                                        topMargin="2"
                                                        radius="0.4"
                                                        titleSize="1.4"
                                                        onClick={() => handleViewRequest('loan', item)}
                                                    >
                                                        View Request
                                                    </Button>
                                                </CustomContainer>
                                                <Divider/>
                                            </div>
                                        )
                                    })
                                }
                            </>
                        }

                        { (!isLoan && !usersRequests?.length) &&
                            <FlexColumn topMargin="10" >
                                <Icon src={images.NO_LOAN} style={{width: '8rem'}} />
                                <AppText textSize="1.8" width="25" topMargin="3" align="center">No Loan Request Received</AppText>
                                <AppSpan color="#E2E2E2" textSize="1.3" fontWeight="400" topMargin="1" align="center" width="26">You haven’t received any loan request yet.</AppSpan>
                            </FlexColumn>
                        }

                        { (isLoan && myRequests?.length > 0) &&
                            <>
                                {
                                    myRequests?.map((item: any, index: number) => {
                                        let dateTime = helpers.splitDateTime(item?.dateSubmitted)
                                        return (
                                            <div key={index}> 
                                                <CustomContainer topMargin='3.5'>
                                                    <AppText textSize='1.7' color='#AAAAAA' fontWeight='400'>{dateTime?.slashedDate} {dateTime?.completeTime}</AppText>
                                                    <FlexRow justifyContent="space-between" topMargin='0.3' className='notif-offer-container'>
                                                        <AppText textSize="1.6" rightPadding='2' color='#6DE7B4'>
                                                            You showed interest in a Loan offer
                                                        </AppText>
                                                        <FlexRow width="auto" sizeUnit="">
                                                            <Button
                                                                width="16" height="3.4" rightMargin='2'
                                                                bgColor='#525252' radius="0.4"
                                                                titleSize="1.4" color='#FED0B7'
                                                            >
                                                                {item?.rejectedLender ? 'Request Rejected' : item?.agreedLender ? 'Request Accepted' : 'Pending Acceptance'}
                                                            </Button>
                                                            <ImNotification style={{fontSize: '2rem'}} />
                                                        </FlexRow>
                                                    </FlexRow>
                                                    <AppText fontWeight="400" width='38' textSize="1.6" className='notif-req-text'>
                                                        You showed interest in this loan offer. Offer will expire if no action is taken on your request.
                                                    </AppText>
                                                    <Button
                                                        width="13"
                                                        height="3.5"
                                                        hoverBgColor="#FED0B7" 
                                                        topMargin="2"
                                                        radius="0.4"
                                                        titleSize="1.4"
                                                        onClick={() => handleViewRequest('offer', item)}
                                                    >
                                                        View Loan
                                                    </Button>
                                                </CustomContainer>
                                                <Divider/>
                                            </div>

                                        )
                                    })
                                }
                            </>
                        }
                        { (isLoan && !myRequests?.length) &&
                            <FlexColumn topMargin="10" >
                                <Icon src={images.NO_LOAN} style={{width: '8rem'}} />
                                <AppText textSize="1.8" width="25" topMargin="3" align="center">No Loan Request Made</AppText>
                                <AppSpan color="#E2E2E2" textSize="1.3" fontWeight="400" topMargin="1" align="center" width="26">You haven’t made any loan request yet.</AppSpan>
                            </FlexColumn>
                        }
                    </SidePopup>
                </SidePopupContainer>
            }
            { showLoanDetails &&
                <LoanRequestDetails
                    close={() => setShowLoanDetails (false)}
                    closeNotif={() => close()}
                    data={details}
                />
            }
            { showOfferDetails &&
                <OfferSummary
                    close={() => setShowOfferDetails (false)}
                    closeNotif={() => close()}
                    data={details}
                />
            }
        </>
    )
}
