import { useState, useContext, useEffect } from 'react'
import utility from 'src/utils/utility'
import { useNavigate } from 'react-router-dom'
import { Container } from 'src/component'
import UserContext from 'src/provider/state-manager/userProvider'
import { AppText, Button, FormGroup, ScreenTitle } from 'src/styles'
import { NoBVNPopup } from 'src/popup'
import helpers from 'src/utils/helpers'

const WithoutBVN = () => {
    const navigate = useNavigate()
    const {setUserProperty} = useContext(UserContext)
    const [input, setInput] = useState({phoneNumber: ''})
    const [showNoBvnPopup, setShowNoBvnPopup] = useState(false)
    const [isValidated, setIsValidated] = useState(false)

    useEffect(() => {
        setIsValidated(helpers.validatePhoneNo(input.phoneNumber))
    }, [input])

    const handleInput = (e: React.ChangeEvent<HTMLInputElement|HTMLSelectElement>) => setInput({...input, [e.target.name]: e.target.value})

    async function handleNext () {
        await setUserProperty('bvnData', input.phoneNumber)
        //navigate('/onboarding/phoneNo-verification')  // to be uncommented when no bvn feature is available
        setShowNoBvnPopup(true)  // to be removed when no bvn feature is available
    }

    return (
        <>
            <Container type="onboarding">
                <p className="right-align-text">Already have an account? 
                    <a className="registration-link" onClick={() => navigate('/login')}> Sign in</a>
                </p>
                <div className="onboarding-container">
                    <ScreenTitle textSize="3" bottomMargin="1.5" topMargin="3" >
                        Continue without BVN
                    </ScreenTitle>
                    <AppText color="#D5D5D5" textSize="1.4" fontWeight="400" bottomMargin="2">
                        Set up your account without your BVN and add it when you want to make a transaction
                    </AppText>
                    <FormGroup topMargin="5">
                        <label>Phone Number</label>
                        <input 
                            type="number" 
                            placeholder="Phone number" 
                            name="phoneNumber" 
                            onChange={handleInput}
                        />
                    </FormGroup>
                    
                    <Button 
                        width="15" 
                        height="4.5" 
                        hoverBgColor="#FFC49D" 
                        topMargin="8"
                        bottomMargin="3"
                        className="loan-action-btn"
                        disabled={!isValidated}
                        onClick={handleNext}
                    >
                        Next
                    </Button>
                </div>
            </Container>
            { showNoBvnPopup &&
                <NoBVNPopup close={() => setShowNoBvnPopup(false)} />
            }
        </>
    )
}

export default utility.routeData('without-bvn', 'No BVN', <WithoutBVN/>)