import './style.scss'
import utility from 'src/utils/utility'
import { useNavigate } from 'react-router-dom'
import { BsChevronLeft } from 'react-icons/bs'
import { FormGroup, SelectButton, Button, ScreenContainer } from "src/styles"

const GetLoanType = () => {
    const navigate = useNavigate()

    return (
        <ScreenContainer>
            <div className="loan-type-container">
                <div className="loan-type-title">
                    <BsChevronLeft className="loan-back-icon" onClick={() => navigate(-1)} />
                    <div className="loan-type-title-content-plus">
                        <p >Get a Loan</p>
                        <span>
                            Viverra magna pretium odio in mollis augue at. Urna commodo sed platea 
                            nullam eu. Tincidunt et ipsum pellentesque velit rhoncus tortor.
                        </span>
                    </div>
                </div>
                <div className="loan-type-content">
                    <div className="loan-horizontal-bar"></div>
                    <FormGroup>
                        <label>What type of loan are you applying for?</label>
                        <div className="loan-tenor-types">
                            <SelectButton width="10" height="4" size="1.35">
                                <input type="radio" name="get-loan-type" />
                                <span></span>
                                <label>Payday</label>
                            </SelectButton>
                            <SelectButton width="10" height="4" size="1.35">
                                <input type="radio" name="get-loan-type" />
                                <span></span>
                                <label>Personal</label>
                            </SelectButton>
                            <SelectButton width="10" height="4" size="1.35">
                                <input type="radio" name="get-loan-type" />
                                <span></span>
                                <label>Gadget</label>
                            </SelectButton>
                            <SelectButton width="10" height="4" size="1.35">
                                <input type="radio" name="get-loan-type" />
                                <span></span>
                                <label>Rent</label>
                            </SelectButton>
                            <SelectButton width="10" height="4" size="1.35">
                                <input type="radio" name="get-loan-type" />
                                <span></span>
                                <label>Travel</label>
                            </SelectButton>
                            <SelectButton width="10" height="4" size="1.35">
                                <input type="radio" name="get-loan-type" />
                                <span></span>
                                <label>School fees</label>
                            </SelectButton>
                            <SelectButton width="10" height="4" size="1.35">
                                <input type="radio" name="get-loan-type" />
                                <span></span>
                                <label>Benevolent</label>
                            </SelectButton>
                            <SelectButton width="10" height="4" size="1.35">
                                <input type="radio" name="get-loan-type" />
                                <span></span>
                                <label>Medical fees</label>
                            </SelectButton>
                        </div>
                    </FormGroup>
                    <FormGroup>
                        <label>How much are you applying for?</label>
                        <input type="number" placeholder="Enter amount"/>
                    </FormGroup>
                    <div className="loan-creation-wrapper-plus">
                        <span>Back</span>
                        <Button
                            width="13"
                            hoverBgColor="#FED0B7"
                            onClick={() => navigate('/explore/get-loan/loan-duration')}
                        >
                            Next
                        </Button>
                    </div>
                </div>
            </div>
        </ScreenContainer>
    )
}

export default utility.routeData('get-loan/loan-type', 'Explore Get Loan Type', <GetLoanType/>)