import { useState, useContext, useEffect, useRef } from 'react'
import utility from 'src/utils/utility'
import ApiContext from 'src/provider/API/call-service'
import UserContext from 'src/provider/state-manager/userProvider'
import AppInfoContext from 'src/provider/state-manager/appInfoProvider'
import { useNavigate } from 'react-router-dom'
import { Container } from 'src/component'
import { BsChevronLeft } from 'react-icons/bs'
import { AppLink, AppSpan, AppText, Button, FlexRow, GridContainer, ScreenTitle } from 'src/styles'
import { NoAuthPopup, NoBVNPopup } from 'src/popup'
import { images } from 'src/provider/config/constant'
import helpers from 'src/utils/helpers'
import {LiaSmsSolid} from 'react-icons/lia'
import { HiOutlineMail } from 'react-icons/hi'

const PhoneNoVerification = () => {
    const navigate = useNavigate()
    const {API} = useContext(ApiContext)
    const {info} = useContext(AppInfoContext)
    const {user: {bvnData}} = useContext(UserContext)
    const [otp, setOtp] = useState('')
    const [showOtpVerified, setShowOtpVerified] = useState(false)
    const [showNoBvnPopup, setShowNoBvnPopup] = useState(false)
    const [isPhoneNo, setIsPhoneNo] = useState(true)
    const [resendType, setResendType] = useState('phoneNo')
    let [time, setTime] = useState<any>(null)
    let [count, setCount] = useState<number>(0)

    useEffect(() => {
        if (count === 30) handleResendOtp()
    }, [count])

    useEffect(() => {
        return () => { clearInterval(time) }
    }, [])

    useEffect(() => {
        setIsPhoneNo(info.onBoardingData?.resendOtpType != 'email')
    }, [info.onBoardingData?.resendOtpType])

    function handleResendCounter (resendType: string) {
        if (!count) {
            setResendType(resendType)
            time =  setInterval(async () => {
                setCount((count: number) => {
                    if (count === 30) {
                        clearInterval(time)
                        return 0
                    }
                    else return count + 1
                });
            }, 1000)
        }
    }

    const handleOtp = (e: React.ChangeEvent<HTMLInputElement|HTMLSelectElement>) => {
        if (e.target.value.length < 7) {
            setOtp(e.target.value)
        }
    }

    async function handleVerify () {
        if (info.onBoardingData.bvn) {
            const response = await API.verifyOtp({username: info.onBoardingData.phoneNumber, otp: otp})
            if (response) setShowOtpVerified(true)
        }
        else setShowNoBvnPopup(true)
    }

    async function handleResendOtp () {
        const response = resendType === 'phoneNo' ? await API.resendOtp({username: info.onBoardingData.phoneNumber})
                        : await API.resendOtpViaEmail(bvnData?.idNumber)
        
        if (response) setIsPhoneNo(resendType === 'phoneNo')
    }

    return (
        <>
            <Container type="onboarding" progressBar='7.69'>
                <p className="right-align-text">Already have an account? 
                    <a className="registration-link" onClick={() => navigate('/login')}> Sign in</a>
                </p>
                <div className="onboarding-container">
                    <BsChevronLeft className="loan-back-icon" onClick={() => navigate(-1)} />
                    <ScreenTitle textSize="3" bottomMargin="1.5" topMargin="3" >
                        BVN Verification
                    </ScreenTitle>
                    <AppText textSize="1.4" fontWeight="400" bottomMargin="7">
                        Enter verification code sent to your {isPhoneNo ? 'phone number' : 'email address'}
                        <AppSpan textSize="1.4" color="#FED0B7" > {utility.maskContactInfo(isPhoneNo ? bvnData?.mobile : bvnData.email, isPhoneNo ? 'phone' : 'email')} </AppSpan>
                    </AppText>
                    <div className="form-group-wrapper">
                        <div className="passcode-input-wrapper">
                            <input 
                                className='display-hidden' 
                                id='pass' name="pass" 
                                value={otp} autoFocus={true} 
                                onChange={handleOtp} autoComplete="off"
                            />
                            <label className={`passcode-input ${otp.length === 0 && 'active-pin-box'}`} htmlFor='pass' >{otp[0] || ''}</label>
                            <label className={`passcode-input ${otp.length === 1 && 'active-pin-box'}`} htmlFor='pass' >{otp[1] || ''}</label>
                            <label className={`passcode-input ${otp.length === 2 && 'active-pin-box'}`} htmlFor='pass' >{otp[2] || ''}</label>
                            <label className={`passcode-input ${otp.length === 3 && 'active-pin-box'}`} htmlFor='pass' >{otp[3] || ''}</label>
                            <label className={`passcode-input ${otp.length === 4 && 'active-pin-box'}`} htmlFor='pass' >{otp[4] || ''}</label>
                            <label className={`passcode-input ${otp.length === 5 && 'active-pin-box'}`} htmlFor='pass' >{otp[5] || ''}</label>
                        </div>
                    </div>
                    <AppText textSize="1.4" fontWeight="400" topMargin="4">Didn't get the code?</AppText>
                    <AppText textSize="1.4" color="#D5D5D5" fontWeight="400" topMargin='2'>
                            Code will resend in 
                            <AppSpan color="#FED0B7" leftPadding="0.5">{helpers.formatSeconds(30-count)}</AppSpan>
                        </AppText>
                    <FlexRow 
                        width='auto' sizeUnit='' topMargin="3" wrap='wrap'
                        justifyContent='flex-start' alignItems='flex-start'
                    >
                        <GridContainer
                            width='22'  minHeight='5.5' topPadding='0' bottomPadding='0'
                            radius='0.8' padding='1.5' bgColor='#081952' 
                            rightMargin='1' topMargin='1.5'
                            className='otp-resend-btn'
                            onClick={() => handleResendCounter('phoneNo')}
                        >
                            <FlexRow>
                                <AppSpan color='#6DE7B4' textSize='1.9' rightMargin='0.6'>
                                    <LiaSmsSolid />
                                </AppSpan>
                                <AppLink textSize="1.4">
                                    Resend Code via SMS
                                </AppLink>
                            </FlexRow>
                        </GridContainer>
                        { bvnData?.email &&
                            <GridContainer
                                width='22' minHeight='5.5' topPadding='0' bottomPadding='0'
                                radius='0.8' padding='1.5' bgColor='#081952' topMargin='1.5'
                                className='otp-resend-btn'
                                onClick={() => handleResendCounter('email')}
                            >
                                <FlexRow>
                                    <AppSpan color='#6DE7B4' textSize='1.8' rightMargin='0.6'>
                                        <HiOutlineMail />
                                    </AppSpan>
                                    <AppLink textSize="1.4">
                                        Resend Code via Email
                                    </AppLink>
                                </FlexRow>
                            </GridContainer>
                        }
                    </FlexRow>
                    <Button 
                        width="15" 
                        height="4.5" 
                        hoverBgColor="#FFC49D" 
                        topMargin="12"
                        bottomMargin="3"
                        className="loan-action-btn"
                        disabled={otp.length != 6 ? true : false}
                        onClick={handleVerify}
                    >
                        Next
                    </Button>
                </div>
            </Container>

            { showOtpVerified &&
                <NoAuthPopup 
                    close={() => setShowOtpVerified(false)} 
                    text="OTP Verification Successful"
                    route="/onboarding/personal-info"
                    responseIcon={images.JOIN_RESPONSE}
                />
            }
            { showNoBvnPopup &&
                <NoBVNPopup close={() => setShowNoBvnPopup(false)} />
            }
        </>
    )
}

export default utility.routeData('phoneNo-verification', 'Phone Number Verification', <PhoneNoVerification/>)