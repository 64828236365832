import './style.scss'
import { useState, useContext, useEffect } from 'react'
import utility, { getLoanStatusColor } from 'src/utils/utility'
import { useNavigate } from 'react-router-dom'
import ApiContext from 'src/provider/API/call-service'
import LoanContext from 'src/provider/state-manager/loanProvider'
import { BsChevronLeft } from 'react-icons/bs'
import { BiGitCompare } from 'react-icons/bi'
import { ScreenContainer, Divider, OutlineButton, Button, AppSpan, FlexRow, Icon, AppText, CustomContainer, RoundImageContainer, Circle } from "src/styles"
import { images } from 'src/provider/config/constant'
import { LoansToCompare, CompareLoan, LoanNegotiation, AddOffers, ProposeOffer } from 'src/popup'

const LoanOfferDetails = () => {
    const navigate= useNavigate()
    const {API} = useContext(ApiContext)
    const {loan: {selectedLoanOffer, requestLoanOffers, currentProcessType}} = useContext(LoanContext)
    const [loanDetails, setLoanDetails] = useState<any>(null)
    const [similarLoan, setSimilarLoan] = useState<any>(null)
    const [selectedCompareLoan, setSelectedCompareLoan] = useState({})
    const [currentProcessUser, setCurrentProcessUser] = useState('')
    const [showLoansToCompare, setShowLoansToCompare] = useState(false)
    const [showCompareLoan, setShowCompareLoan] = useState(false)
    const [showLoanNegotiation, setShowLoanNegotiation] = useState(false)
    const [showAddOffers, setShowAddOffers] = useState(false)
    const [showProposeOffer, setShowProposeOffer] = useState(false)

    useEffect(() => {
        console.log({currentProcessType})
        setCurrentProcessUser(currentProcessType)
        setLoanDetails(selectedLoanOffer) // details of the selected loan request or oofer
    }, [selectedLoanOffer, currentProcessType])

    useEffect(() => {
        if(showLoansToCompare || showCompareLoan || showLoanNegotiation || showAddOffers || showProposeOffer) {
          document.body.style.overflow = 'hidden'
        }
        else document.body.style.overflow = 'auto'
    }, [showLoansToCompare, showCompareLoan, showLoanNegotiation, showAddOffers, showProposeOffer])

    useEffect(() => {
        let offers_requests = []
    
        for (let item of requestLoanOffers) {
            if (offers_requests.length >= 2) break;
            if (currentProcessUser === 'borrower') {
                if(item.loanOfferId != loanDetails?.loanOfferId) offers_requests.push(item)
            }
            else {
                if(item.loanRequestId != loanDetails?.loanRequestId) offers_requests.push(item)
            }
        }
        setSimilarLoan(offers_requests)
    }, [loanDetails])

    const loansToCompareShow = (val: boolean) => setShowLoansToCompare(val)

    return (
        <>
            <ScreenContainer>
                <div className="loan-offers-container">
                    <div className="loan-details-content">
                        <div className="loan-details-content-1">
                            <div className="loan-details-title">
                                <BsChevronLeft className="back-btn-icon" onClick={() => navigate(-1)} />
                                <div className="loan-type-title-content">
                                    <p>Loan {currentProcessUser === 'borrower' ? 'offer' : 'request'} Details</p>
                                </div>
                            </div>
                            <div className="loan-details-summary">
                                <div className="loan-offer-header">
                                    <div className="loan-offer-label">
                                        <img src={images.LOAN_OFFER_ICON} className="loan-offer-header-icon" />
                                        <div className="loan-offer-label-text">
                                            {/* <label>LoanType</label> */}
                                            <p className='loan-offer-type-text'>{loanDetails?.loanTypeName}</p>
                                        </div>
                                    </div>
                                    <span className="loan-offer-percentage" style={{color: '#6DE7B4'}}>{loanDetails?.interest}%p.m</span>
                                </div>
                                <Divider margin="1.5" />
                                <div className="details-data-container">
                                    <div className="loan-offer-label-text">
                                        <label>Amount</label>
                                        <p>N{utility.moneyFormat(loanDetails?.amountPerPerson || loanDetails?.amount)}</p>
                                    </div>
                                    <div className="loan-offer-label-text">
                                        <label>Duration</label>
                                        <p>{loanDetails?.tenor} {loanDetails?.tenorType?.toLowerCase()}(s)</p>
                                    </div>
                                    <div className="loan-offer-label-text">
                                        <label>{loanDetails?.repaymentTermName} Repayment</label>
                                        <p>N{utility.moneyFormat(currentProcessUser === 'borrower' ? loanDetails?.repaymentPerTenorBorrower : (loanDetails?.totalAmountPerTenorType || loanDetails?.amountPerTenorType))}</p>
                                    </div>
                                    <div className="loan-offer-label-text">
                                        <label>Interest on loan</label>
                                        {/* <p>{loanDetails?.interest}% ({loanDetails?.repaymentTermName})</p> */}
                                        <p>{loanDetails?.interest}% (monthly)</p>
                                    </div>
                                    <div className="loan-offer-label-text">
                                        <label>Payment</label>
                                        <p>{loanDetails?.rateId === 1 ? 'Installment' : 'One-off'}</p>
                                    </div>
                                    <div className="loan-offer-label-text">
                                        <label>Interest Amount</label>
                                        <p>N{utility.moneyFormat(loanDetails?.interestAmount)}</p>
                                    </div>
                                    <div className="loan-offer-label-text">
                                        <label>Total Repayment</label>
                                        <p>N{utility.moneyFormat(currentProcessUser === 'borrower' ? loanDetails?.totalRepaymentBorrower : loanDetails?.totalAmountToBeRepaid)}</p>
                                    </div>
                                </div>
                                <div className="down-line-abs"></div>
                            </div>

                            { currentProcessUser === 'borrower' &&
                                <CustomContainer topMargin='2.5'>
                                    <AppText textSize='1.7'>Portfolio Owner</AppText>
                                    <FlexRow justifyContent='space-between' alignItems='flex-start' wrap='wrap'>
                                        <FlexRow justifyContent='flex-start' width='auto' size='' topMargin='1.5'>
                                            {/* <RoundImageContainer bgColor='#fff' size='4' rightMargin='1'>
                                                <Icon src={images.USER} />
                                            </RoundImageContainer> */}
                                            <Circle 
                                                    size='4.2' rightMargin='1' bgColor='#ffffff'
                                                >
                                                    <Circle
                                                        size='3.9'
                                                        bgColor={getLoanStatusColor(loanDetails?.userTag)}
                                                    >
                                                        <RoundImageContainer 
                                                            size="3.3" borderColor='#ffffff'
                                                            style={{borderWidth: '1.5px'}}
                                                        >
                                                            <Icon 
                                                                src={loanDetails.profilePictureUrl || images.USER}
                                                                width='3.1' radius='50' height='3.1' noResize
                                                            />
                                                        </RoundImageContainer>
                                                    </Circle>
                                                </Circle>
                                            {/* <CustomContainer width='auto' size='' rightMargin='2'>
                                                <AppSpan fontWeight='400' textSize='1.2'>Reputation Score</AppSpan>
                                                <AppText textSize='1.3' color='#6DE7B4' topMargin='0.4'>65%</AppText>
                                            </CustomContainer> */}
                                            {/* <CustomContainer width='auto' size='' leftPadding='2' style={{borderLeft: '1px solid rgba(255, 255, 255, 0.1)'}}>
                                                <AppSpan fontWeight='400' textSize='1.2'>Loan Disbursed</AppSpan>
                                                <AppText textSize='1.3' topMargin='0.4'>-</AppText>
                                            </CustomContainer> */}
                                        </FlexRow>
                                        <CustomContainer width='auto' size='' leftMargin='1.5' topMargin='1.5'>
                                            <AppText fontWeight='400' textSize='1.2' align='right'>Viewed By</AppText>
                                            <FlexRow width='auto' size=' ' topMargin='0.4'>
                                                <Icon src={images.FRUIT_HEADS2X} width='5'/>
                                                <AppText textSize="1.2" leftMargin="0.7">
                                                    {loanDetails?.numberOfInterestedBorrowers || 0} person(s)
                                                </AppText>
                                            </FlexRow>
                                        </CustomContainer>
                                    </FlexRow>
                                </CustomContainer>
                            }

                            { currentProcessUser === 'lender' &&
                                <CustomContainer topMargin='2.5'>
                                    <AppText textSize='1.7'>Borrower Info</AppText>
                                    <FlexRow justifyContent='flex-start' alignItems='flex-start' wrap='wrap'>
                                        <FlexRow justifyContent='flex-start' width='auto' size='' topMargin='1.5'>
                                            <RoundImageContainer bgColor='#fff' size='4' rightMargin='1'>
                                                <Icon src={images.USER} />
                                            </RoundImageContainer>
                                            <CustomContainer width='auto' size='' rightMargin='2'>
                                                <AppSpan fontWeight='400' textSize='1.2'>{loanDetails?.firstName} {loanDetails?.lastName}</AppSpan>
                                                <AppText textSize='1.3' color='#6DE7B4' topMargin='0.4'>-</AppText>
                                            </CustomContainer>
                                            <CustomContainer width='auto' size='' leftPadding='2' style={{borderLeft: '1px solid rgba(255, 255, 255, 0.1)'}}>
                                                <AppSpan fontWeight='400' textSize='1.2'>Loans Default</AppSpan>
                                                <AppText textSize='1.3' topMargin='0.4'>0</AppText>
                                            </CustomContainer>
                                        </FlexRow>
                                    </FlexRow>
                                </CustomContainer>
                            }
                            
                            <div className="about-loan">
                               { currentProcessUser === 'borrower' &&
                                <>
                                     <h4 className="about-loan-title">About this Loan</h4>
                                    <span className="about-loan-text">
                                        {loanDetails?.about}
                                    </span>
                                </>
                               }
                                <div className="loan-decision-btns">
                                    { currentProcessUser === 'borrower' &&
                                        <a 
                                            className={loanDetails?.isNegotiable ? "negotiate-loan-btn" : 'negotiate-loan-btn disable-a-tag'} 
                                            onClick={() => loanDetails?.isNegotiable ? setShowLoanNegotiation(true) : null}
                                        >
                                            Negotiate Loan
                                        </a>
                                    }
                                    <Button 
                                        width="20" 
                                        hoverBgColor="#FED0B7"
                                        onClick={currentProcessUser === 'borrower' ? () => setShowAddOffers(true) : () => setShowProposeOffer(true)}
                                    >
                                        {currentProcessUser === 'borrower' ? 'Show Interest' : 'Propose Offer'}
                                    </Button>
                                </div>
                            </div>
                        </div>
                        <div className="loan-details-content-2">
                            <div className="compare-btn-wrapper">
                                <OutlineButton 
                                    color="#6DE7B4" 
                                    width="18" 
                                    hoverBgColor="#FED0B7" 
                                    className="loan-action-outline-btn"
                                    disabled={(!similarLoan || similarLoan?.length < 1) ? true : false}
                                    onClick={() => loansToCompareShow(true)}
                                >
                                    <BiGitCompare className="compare-btn-icon" />
                                    <a className="compare-link-text">Compare</a>
                                </OutlineButton>
                            </div>
                            <div className="similar-loan">
                                <h4 className="similar-loan-label">Similar Loan</h4>
                                {
                                    similarLoan?.map((item: any, index: number) => {
                                        return (
                                            <div className="loan-details-summary-similar" key={index} style={{paddingBottom: "0.5rem"}}>
                                                <div className="loan-offer-header">
                                                    <div className="loan-offer-label">
                                                        <img src={images.LOAN_OFFER_ICON} className="loan-offer-header-icon" />
                                                        <div className="loan-offer-label-text">
                                                            {/* <label>LoanType</label> */}
                                                            <p className='loan-offer-type-text'>{item.loanTypeName}</p>
                                                        </div>
                                                    </div>
                                                    <span className="loan-offer-percentage" style={{color: '#6DE7B4'}}>{item.interest}%p.m</span>
                                                </div>
                                                <Divider margin="1.5" />
                                                <div className="flex-row-space-between margin-vertical-1-5">
                                                    <div className="loan-offer-label-text">
                                                        <label>Amount</label>
                                                        <p>N{utility.moneyFormat(item?.amountPerPerson ||item?.amount)}</p>
                                                    </div>
                                                    <div className="loan-offer-label-text width-10-rem">
                                                        <label>Duration</label>
                                                        <p>{item.tenor} {item?.tenorType?.toLowerCase()}(s)</p>
                                                    </div>
                                                </div>
                                                <div className="flex-row-space-between margin-vertical-1-5">
                                                    <div className="loan-offer-label-text">
                                                        <label>Payment</label>
                                                        <p>{item.rateId === 1 ? 'Installment' : 'One-off'}</p>
                                                    </div>
                                                    { currentProcessUser === 'borrower' ?
                                                        // <div className="loan-offer-label-text width-10-rem">
                                                        //     <label>Lender's rep</label>
                                                        //     <p style={{color: '#6DE7B4'}}>{item?.lenderRep || 89.2}%</p>
                                                        // </div>
                                                        null
                                                        :
                                                        <div className="loan-offer-label-text width-10-rem">
                                                            <label>Crendly score</label>
                                                            <p style={{color: '#6DE7B4'}}>{item?.creditScoreInfo?.grandTotal || 0}%</p>
                                                        </div>
                                                    }
                                                    
                                                </div>
                                                <FlexRow justifyContent="flex-start" wrap="wrap">
                                                    <Icon src={images.FRUIT_HEADS2X} style={{marginLeft: '-0.5rem'}} width='6'/>
                                                    <AppText textSize="1.2" leftMargin="1">
                                                        {item?.numberOfInterestedBorrowers || item?.numberOfPerson - item?.numberOfSlotLeft || 0} person(s) made request
                                                    </AppText>
                                                </FlexRow>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </ScreenContainer>
            { showLoansToCompare &&
                <LoansToCompare
                    close={loansToCompareShow}
                    showCompareLoan={setShowCompareLoan}
                    setCompareWithLoan={setSelectedCompareLoan}
                />
            }
            { showCompareLoan &&
                <CompareLoan 
                    close={() => setShowCompareLoan(false)}
                    selectedLoan={loanDetails}
                    CompareWithLoan={selectedCompareLoan}
                    setChosenOffer={setLoanDetails}
                />
            }
            { showLoanNegotiation &&
                <LoanNegotiation 
                    close={() => setShowLoanNegotiation(false)}
                    data={loanDetails}
                    type="borrower"
                />
            }
            { showAddOffers &&
                <AddOffers
                    close={() => setShowAddOffers(false)}
                    data={loanDetails}
                />
            }
            { showProposeOffer &&
                <ProposeOffer
                    close={() => setShowProposeOffer(false)}
                    data={loanDetails}
                />
            }
        </>
    )
}

export default utility.routeData('loan-board/loan-offer-details', 'Loan Offer Details', <LoanOfferDetails/>)