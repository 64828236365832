import { useState, useContext } from 'react'
import ApiContext from 'src/provider/API/call-service'
import { 
    SidePopupContainer, 
    SidePopup, 
    FlexRow, 
    Button,
    FormGroup,
} from "src/styles"

export const NegotiationRejectionReason: React.FC<any> = ({close, closeNegotiation, data}) => {
    const {API} = useContext(ApiContext)
    const [reason, setReason] = useState('')


    async function handleRejectNegotiation () {
        await API.rejectNegotiation(data?.loanNegotiationId, reason)
        close()
        closeNegotiation()
      }

    return (
        <SidePopupContainer >
            <SidePopup data-aos="slide-left">
                <div className="popup-title-wrapper">
                    <h4>Reason for Rejecting Negotiation</h4>
                    <i className="fa fa-times" onClick={close}></i>
                </div>
                <span className="popup-title-summary">
                    State your reason for rejecting this negotiation request.
                </span>
                <FormGroup>
                    <textarea 
                        placeholder="Your reason" 
                        name="about"
                        value={reason} 
                        onChange={(e) => setReason(e.target.value)}
                    >
                    </textarea>
                </FormGroup>
                <FlexRow justifyContent="flex-end" topMargin="4" className="loan-acceptance-btns">
                    <Button 
                        width="15" 
                        height="4"
                        hoverBgColor="#FED0B7"
                        leftMargin="3.5"
                        className='loan-action-btn'
                        disabled={!reason ? true : false}
                        onClick={handleRejectNegotiation}
                    >
                        Submit
                    </Button>
                </FlexRow>
            </SidePopup>
        </SidePopupContainer>
    )
}
