import './style.scss'
import { useState, useEffect } from 'react'
import utility from 'src/utils/utility'
import { useNavigate } from 'react-router-dom'
import { BsChevronLeft } from 'react-icons/bs'
import { FormGroup, SelectButton, Button, ScreenContainer, LevelBar, Divider, FormGroupWithIcon } from "src/styles"
import { ExploreLoanSummary } from 'src/popup'

const LoanDuration = () => {
    const navigate = useNavigate()
    const [showPaymentOccurence, setShowPaymentOccurence] = useState(false)
    const [interestRate, setInterestRate] = useState(50)
    const [loanSummaryShow, setLoanSummaryShow] = useState(false)

    useEffect(() => {
        if(loanSummaryShow) {
          document.body.style.overflow = 'hidden'
        }
        else document.body.style.overflow = 'auto'
    }, [loanSummaryShow])


    const handleSlider = (e: React.ChangeEvent<HTMLInputElement>) => setInterestRate(Number(e.target.value))

    return (
        <>
            <ScreenContainer>
                <div className="loan-type-container">
                    <div className="loan-type-title">
                        <BsChevronLeft className="loan-back-icon" onClick={() => navigate(-1)} />
                        <div className="loan-type-title-content-plus">
                            <p >Get a Loan</p>
                            <span>
                                Viverra magna pretium odio in mollis augue at. Urna commodo sed platea 
                                nullam eu. Tincidunt et ipsum pellentesque velit rhoncus tortor.
                            </span>
                        </div>
                    </div>
                    <div className="loan-type-content">
                        <div className="loan-horizontal-bar"></div>
                        <LevelBar strokeWidth="50"><span></span></LevelBar>
                        <FormGroup topMargin="3">
                            <label>How long is this loan going to run?</label>
                            <div className="loan-tenor-types">
                                <SelectButton width="10" height="4">
                                    <input type="radio" name="loan-duration" />
                                    <span></span>
                                    <label>7 Days</label>
                                </SelectButton>
                                <SelectButton width="10" height="4">
                                    <input type="radio" name="loan-duration" />
                                    <span></span>
                                    <label>15 Days</label>
                                </SelectButton>
                                <SelectButton width="10" height="4">
                                    <input type="radio" name="loan-duration" />
                                    <span></span>
                                    <label>1 Month</label>
                                </SelectButton>
                                <SelectButton width="10" height="4">
                                    <input type="radio" name="loan-duration" />
                                    <span></span>
                                    <label>3 Months</label>
                                </SelectButton>
                                <SelectButton width="10" height="4">
                                    <input type="radio" name="loan-duration" />
                                    <span></span>
                                    <label>6 Months</label>
                                </SelectButton>
                                <SelectButton width="10" height="4">
                                    <input type="radio" name="loan-duration" />
                                    <span></span>
                                    <label>9 Months</label>
                                </SelectButton>
                                <SelectButton width="10" height="4">
                                    <input type="radio" name="loan-duration" />
                                    <span></span>
                                    <label>12 Months</label>
                                </SelectButton>
                            </div>
                        </FormGroup>
                        <FormGroup>
                            <label>How do you want to make payment?</label>
                            <div className="loan-tenor-types">
                                <SelectButton width="16" height="4">
                                    <input type="radio" name="payment-type" onChange={() => setShowPaymentOccurence(true)} />
                                    <span></span>
                                    <label>Installment</label>
                                </SelectButton>
                                <SelectButton width="16" height="4">
                                    <input type="radio" name="payment-type" onChange={() => setShowPaymentOccurence(false)} />
                                    <span></span>
                                    <label>One-Off</label>
                                </SelectButton>
                            </div>
                        </FormGroup>
                        { showPaymentOccurence &&
                            <>
                                <Divider bgColor="#1E3177" />
                                <FormGroup topMargin="4">
                                    <label>How often you want to make payment?</label>
                                    <div className="loan-tenor-types">
                                        <SelectButton width="10" height="4">
                                            <input type="radio" name="loan-duration" />
                                            <span></span>
                                            <label>Daily</label>
                                        </SelectButton>
                                        <SelectButton width="10" height="4">
                                            <input type="radio" name="loan-duration" />
                                            <span></span>
                                            <label>Yearly</label>
                                        </SelectButton>
                                        <SelectButton width="10" height="4">
                                            <input type="radio" name="loan-duration" />
                                            <span></span>
                                            <label>Monthly</label>
                                        </SelectButton>
                                    </div>
                                </FormGroup>
                            </>
                        }
                        <FormGroupWithIcon>
                            <label>Interest Rate</label>
                            <p className="selected-interest-rate">{interestRate}%</p>
                            <div className="range-input-container">
                                <input type="range" className="range-input" onChange={handleSlider} />
                                <span className="range-progress-shadow" style={{width: `${(interestRate * 48.2) /50}%`}}></span>
                            </div>
                        </FormGroupWithIcon>
                        <div className="loan-creation-wrapper-plus">
                            <span onClick={() => navigate(-1)}>Back</span>
                            <Button
                                width="13"
                                hoverBgColor="#FED0B7"
                                onClick={() => setLoanSummaryShow(true)}
                            >
                                Next
                            </Button>
                        </div>
                    </div>
                </div>
            </ScreenContainer>
            { loanSummaryShow &&
                <ExploreLoanSummary
                    close={() => setLoanSummaryShow(false)}
                />
            }
        </>
    )
}

export default utility.routeData('get-loan/loan-duration', 'Explore Get Loan Duration', <LoanDuration/>)