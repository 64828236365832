import './style.scss'
import { AbsoluteContainer, AppSpan, AppText, Button, CustomContainer, FlexRow, Icon, ScreenContainer } from 'src/styles'
import utility from 'src/utils/utility'
import { images } from 'src/provider/config/constant'

const Explore = () => {
    return (
        <ScreenContainer>
                <CustomContainer topMargin="2" rightPadding="2" leftPadding="2" className="trans-screen-container">
                    <CustomContainer>
                        <AppText textSize="1.7" bottomMargin="1.5">Latest in Crendly's Community</AppText>
                        <div className="community-latest-full">
                            <div className="community_text-wrapper community_text-wrapper-full">
                                <p>Looking for ways to cut your monthly spend? </p>
                                <button className="btn-expand-width">Stories</button>
                            </div>
                        </div>
                    </CustomContainer>
                    <CustomContainer topMargin="6">
                        <AppText textSize="1.7" bottomMargin="1.5">Inside Crendly's Community</AppText>
                        <FlexRow justifyContent="flex-start" wrap="wrap">
                            <div className="explore-img-card explore-community2">
                                <AbsoluteContainer top="2" left="1.5">
                                    <AppText textSize="2" bottomMargin="4" width="20">
                                        Looking for ways to cut your monthly spend? 
                                    </AppText>
                                    <Button
                                        width="14"
                                        height="4"
                                        titleSize='1.4'
                                        color="#00071F"
                                        bgColor="#fff"
                                        hoverBgColor='#6DE7B4'
                                        hoverColor="#fff"
                                    >
                                        Stories
                                    </Button>
                                </AbsoluteContainer>
                            </div>
                            <div className="explore-img-card explore-community1">
                                <AbsoluteContainer top="2" left="1.5">
                                    <AppText textSize="2" bottomMargin="4" width="20">
                                        Not sure how to travel for summer?
                                    </AppText>
                                    <Button
                                        width="14"
                                        height="4"
                                        titleSize='1.4'
                                        color="#00071F"
                                        bgColor="#fff"
                                        hoverBgColor='#6DE7B4'
                                        hoverColor="#fff"
                                    >
                                        Guide
                                    </Button>
                                </AbsoluteContainer>
                            </div>
                            <div className="explore-img-card explore-community3">
                                <AbsoluteContainer top="2" left="1.5">
                                    <AppText textSize="2" bottomMargin="4" width="20">
                                        Proofing her business idea with Crendly 
                                    </AppText>
                                    <Button
                                        width="14"
                                        height="4"
                                        titleSize='1.4'
                                        color="#00071F"
                                        bgColor="#fff"
                                        hoverBgColor='#6DE7B4'
                                        hoverColor="#fff"
                                    >
                                        Interview
                                    </Button>
                                </AbsoluteContainer>
                            </div>
                            <div className="explore-img-card explore-community4">
                                <AbsoluteContainer top="2" left="1.5">
                                    <AppText textSize="2" bottomMargin="4" width="20">
                                        Looking for ways to cut your monthly spend? 
                                    </AppText>
                                    <Button
                                        width="14"
                                        height="4"
                                        titleSize='1.4'
                                        color="#00071F"
                                        bgColor="#fff"
                                        hoverBgColor='#6DE7B4'
                                        hoverColor="#fff"
                                    >
                                        Stories
                                    </Button>
                                </AbsoluteContainer>
                            </div>
                            <div className="explore-img-card explore-community5">
                                <AbsoluteContainer top="2" left="1.5">
                                    <AppText textSize="2" bottomMargin="4" width="20">
                                        Not sure how to travel for summer?
                                    </AppText>
                                    <Button
                                        width="14"
                                        height="4"
                                        titleSize='1.4'
                                        color="#00071F"
                                        bgColor="#fff"
                                        hoverBgColor='#6DE7B4'
                                        hoverColor="#fff"
                                    >
                                        Guide
                                    </Button>
                                </AbsoluteContainer>
                            </div>
                            <div className="explore-img-card explore-community6">
                                <AbsoluteContainer top="2" left="1.5">
                                    <AppText textSize="2" bottomMargin="4" width="20">
                                        Proofing her business idea with Crendly 
                                    </AppText>
                                    <Button
                                        width="14"
                                        height="4"
                                        titleSize='1.4'
                                        color="#00071F"
                                        bgColor="#fff"
                                        hoverBgColor='#6DE7B4'
                                        hoverColor="#fff"
                                    >
                                        Interview
                                    </Button>
                                </AbsoluteContainer>
                            </div>
                        </FlexRow>
                    </CustomContainer>
                    <CustomContainer 
                        bgColor="#fff"
                        topPadding="3"
                        leftPadding="3"
                        rightPadding="5"
                        bottomPadding="7"
                        topMargin="1.5"
                        bottomMargin="3"
                        radius="0.8"
                    >
                        <CustomContainer style={{zIndex: 2}}>
                            <AppText color="#00071F" textSize="2" bottomMargin="2">Advert board</AppText>
                            <AppSpan color="#000" textSize="1.4" fontWeight="400">
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Egestas etiam 
                                tristique bibendum quam suspendisse. Euismod morbi arcu, pretium enim 
                                eu sollicitudin pellentesque 
                            </AppSpan>
                        </CustomContainer>
                        <AbsoluteContainer top="0" right="0">
                            <Icon src={images.C_LOGO_GREEN} />
                        </AbsoluteContainer>
                    </CustomContainer>

                    <CustomContainer>
                        <FlexRow justifyContent="flex-start" wrap="wrap">
                            <div className="explore-img-card explore-community2">
                                <AbsoluteContainer top="2" left="1.5">
                                    <AppText textSize="2" bottomMargin="4" width="20">
                                        Looking for ways to cut your monthly spend? 
                                    </AppText>
                                    <Button
                                        width="14"
                                        height="4"
                                        titleSize='1.4'
                                        color="#00071F"
                                        bgColor="#fff"
                                        hoverBgColor='#6DE7B4'
                                        hoverColor="#fff"
                                    >
                                        Stories
                                    </Button>
                                </AbsoluteContainer>
                            </div>
                            <div className="explore-img-card explore-community1">
                                <AbsoluteContainer top="2" left="1.5">
                                    <AppText textSize="2" bottomMargin="4" width="20">
                                        Not sure how to travel for summer?
                                    </AppText>
                                    <Button
                                        width="14"
                                        height="4"
                                        titleSize='1.4'
                                        color="#00071F"
                                        bgColor="#fff"
                                        hoverBgColor='#6DE7B4'
                                        hoverColor="#fff"
                                    >
                                        Guide
                                    </Button>
                                </AbsoluteContainer>
                            </div>
                            <div className="explore-img-card explore-community3">
                                <AbsoluteContainer top="2" left="1.5">
                                    <AppText textSize="2" bottomMargin="4" width="20">
                                        Proofing her business idea with Crendly 
                                    </AppText>
                                    <Button
                                        width="14"
                                        height="4"
                                        titleSize='1.4'
                                        color="#00071F"
                                        bgColor="#fff"
                                        hoverBgColor='#6DE7B4'
                                        hoverColor="#fff"
                                    >
                                        Interview
                                    </Button>
                                </AbsoluteContainer>
                            </div>
                            <div className="explore-img-card explore-community4">
                                <AbsoluteContainer top="2" left="1.5">
                                    <AppText textSize="2" bottomMargin="4" width="20">
                                        Looking for ways to cut your monthly spend? 
                                    </AppText>
                                    <Button
                                        width="14"
                                        height="4"
                                        titleSize='1.4'
                                        color="#00071F"
                                        bgColor="#fff"
                                        hoverBgColor='#6DE7B4'
                                        hoverColor="#fff"
                                    >
                                        Stories
                                    </Button>
                                </AbsoluteContainer>
                            </div>
                            <div className="explore-img-card explore-community5">
                                <AbsoluteContainer top="2" left="1.5">
                                    <AppText textSize="2" bottomMargin="4" width="20">
                                        Not sure how to travel for summer?
                                    </AppText>
                                    <Button
                                        width="14"
                                        height="4"
                                        titleSize='1.4'
                                        color="#00071F"
                                        bgColor="#fff"
                                        hoverBgColor='#6DE7B4'
                                        hoverColor="#fff"
                                    >
                                        Guide
                                    </Button>
                                </AbsoluteContainer>
                            </div>
                            <div className="explore-img-card explore-community6">
                                <AbsoluteContainer top="2" left="1.5">
                                    <AppText textSize="2" bottomMargin="4" width="20">
                                        Proofing her business idea with Crendly 
                                    </AppText>
                                    <Button
                                        width="14"
                                        height="4"
                                        titleSize='1.4'
                                        color="#00071F"
                                        bgColor="#fff"
                                        hoverBgColor='#6DE7B4'
                                        hoverColor="#fff"
                                    >
                                        Interview
                                    </Button>
                                </AbsoluteContainer>
                            </div>
                        </FlexRow>
                    </CustomContainer>
                </CustomContainer>
        </ScreenContainer>
    )
}

export default utility.routeData('explore', 'Explore', <Explore/>)