import { useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import ApiContext from 'src/provider/API/call-service'
import { 
    ResponderContentWrapper, 
    ResponderIconCircle, 
    ResponderIconHolder,
    ResponderTitle, 
} from 'src/component/Responder/style'
import { CenterPopupContainer, CenterPopup, Button, Icon, FlexRow, AppText, AppSpan, BorderlessButton } from 'src/styles'
import { images } from 'src/provider/config/constant'


export const AddOffers: React.FC<any> = ({close, data}) => {
    const navigate = useNavigate()
    const {API} = useContext(ApiContext)

    async function handleAddOffer () {
        navigate('/dashboard/loan-board/loan-offer-selection')
        close()
    }

    return (
        <CenterPopupContainer zIndex="8">
            <CenterPopup data-aos="slide-down">
                <i className="fa fa-times responder-cancel" onClick={close}></i>
                    <ResponderContentWrapper>
                        <ResponderIconCircle>
                            <Icon src={images.STATEMENT_RESPONSE} />
                        </ResponderIconCircle>
                        <ResponderIconHolder></ResponderIconHolder>
                        <ResponderTitle>Your loan request has been noted.</ResponderTitle>
                        <AppText topMargin="4" fontWeight="400" align="center" textSize='1.4'>
                            Submit two (2) more loan interests to increase your chances.
                        </AppText>
                        <FlexRow justifyContent="flex-end" className="loan-acceptance-btns" topMargin="5">
                            <BorderlessButton hoverColor='#FED0B7' titleSize='1.4' onClick={() => API.requestLoan([data?.loanOfferId])}>
                                Not Now
                            </BorderlessButton>
                            <Button 
                                width="17" 
                                height="4"
                                hoverBgColor="#FED0B7"
                                leftMargin="3.5"
                                onClick={handleAddOffer}
                            >
                                Continue
                            </Button>
                        </FlexRow>
                    </ResponderContentWrapper>
            </CenterPopup >
        </CenterPopupContainer>
    )
}
