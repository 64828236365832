import React, { useState, useContext, useEffect } from 'react'
import { GoChevronDown } from 'react-icons/go'
import ApiContext from 'src/provider/API/call-service'
import { images, credentials } from 'src/provider/config/constant'
import { 
    SidePopupContainer, 
    SidePopup, 
    FormGroup, 
    Form, 
    Button, 
    CustomContainer, 
    FlexColumn, 
    DataWithLabel, 
    FormGroupWithIcon,
    Divider,
    AppText,
    AppSpan,
    GridContainer
} from "src/styles"
import Okra from "okra-js"
import MonoConnect from '@mono.co/connect.js';


export const AddBank: React.FC<any> = ({close, hasBank}) => {
    const {API} = useContext(ApiContext)
    const [isAccount, setIsAccount] = useState(hasBank)
    const [input, setInput] = useState({bankName: '', accountNumber: ''})

    const monoConnect = React.useMemo(() => {
        const monoInstance = new MonoConnect({
          onClose: () => console.log('Mono widget closed'),
          onLoad: () => console.log('Mono widget loaded successfully'),
          onSuccess: ({ code }: any) => linkAccountWithMono(code),
          key: credentials.MONO_PUBLIC_KEY
        })
    
        monoInstance.setup()
        
        return monoInstance;
      }, [])

    function handleOkraWidget () {
        Okra.buildWithOptions({
            name: credentials.OKRA_NAME,
            env: credentials.OKRA_ENV,
            app_id: credentials.OKRA_APP_ID,
            key: credentials.OKRA_KEY, 
            token: credentials.OKRA_TOKEN,
            currency: 'NGN',
            color:  '#160e2e',
            limit: '24',
            logo: credentials.CRENDLY_LOGO,
            products: ['auth','identity','balance','transactions', 'income'],
            onSuccess: function(data: any){
                console.log('okra success', data)
                linkAccountWithOkra(data)
            },
            onError: function (data: any) {
                console.log('okra error', data)
                API.linkBankAccount('error')
            },
            onClose: function(){
                console.log('okra close')
                close()
            }
        })
    }

    async function linkAccountWithOkra (data: any) {
        const payload = {
            bank: data.auth.bank_details?.name,
            accountNumber: data.accounts?.length ? String(data.accounts[0]?.nuban) : String(data.accounts?.nuban),
            accountName: data.accounts?.length ? data.accounts[0]?.name : data.accounts?.name,
            okraCustomerId: data.customer_id,
            incomeServiceProvider: 2
        }

        handleAccountLinking(payload)
    }

    async function linkAccountWithMono (code: string) {
        const payload = {
            okraCustomerId: code,
            incomeServiceProvider: 1
        }

        handleAccountLinking(payload)
    }

    async function handleAccountLinking (payload: any) {
        const response = await API.linkBank(payload, true)
        if (response) API.linkBankAccount('success')
        else API.linkBankAccount('error')
    }

    return (
        <SidePopupContainer >
            <SidePopup data-aos="slide-left">
                <CustomContainer>
                    <div className="popup-title-wrapper">
                        <h4>Bank Account</h4>
                        <i className="fa fa-times" onClick={() => close(false)}></i>
                    </div>
                    <FlexColumn topMargin="12">
                        <img src={images.BANK_ICON} className="card-center-icon" />
                        <DataWithLabel 
                            titleSize="1.8" 
                            textSize="1.4" 
                            titleWeight="700" 
                            color="#E2E2E2"
                            titleColor="#fff"
                            className="data-with-label"
                            fontWeight="400"
                        >
                            <label style={{marginBottom: '0.5rem'}} >Link a Bank Account</label>
                            {!isAccount &&
                                <span>You do not have any Bank account added yet.</span>
                            }
                        </DataWithLabel>

                        <GridContainer width='auto' sizeUnit=''>
                            {/* <Button
                                width="25"
                                height="4"
                                hoverBgColor="#FED0B7" 
                                topMargin="10"
                                onClick={handleOkraWidget}
                            >
                                Link Account Via Okra
                            </Button> */}

                            <Button
                                width="25"
                                height="4"
                                hoverBgColor="#FED0B7" 
                                topMargin="1.5"
                                onClick={() => monoConnect.open()}
                            >
                                Link Account Via Mono
                            </Button>
                        </GridContainer>

                        {/* <FlexColumn topMargin='7' width='90' sizeUnit='%'>
                            <AppText textSize='1.3' color='#E2E2E2' fontWeight='400' align='center'>
                                <AppSpan textSize='1.3' color='#E2E2E2'>NB</AppSpan>
                                : When linking via Okra, Check under "<AppSpan textSize='1.3' color='#E2E2E2'>internet banking</AppSpan>"
                                or "<AppSpan textSize='1.3' color='#E2E2E2'>mobile banking</AppSpan>"
                                if you did not see your bank in either section in the popup widget.
                            </AppText>
                        </FlexColumn> */}
                    </FlexColumn>
                </CustomContainer>
            </SidePopup>
        </SidePopupContainer>
    )
}
