import styled from 'styled-components'
import { StyleProps } from 'src/model'

export const CircleProgressBar = styled.div<StyleProps>`
    width: ${({size}) => size || 5.5}rem;
    height: ${({size}) => size || 5.5}rem;
    position: relative;
`
export const BarCircle = styled.div<StyleProps>`
    width: ${({size}) => size || 5.5}rem;
    height: ${({size}) => size || 5.5}rem;
    border: ${({strokeWidth}) => strokeWidth || 0.4}rem solid ${({bgColor}) => bgColor};
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    font-size: ${({barFontSize}) => barFontSize}rem;
    font-weight: 700;
`
export const BarSvg = styled.svg.attrs((props: StyleProps) => ({
    width: `${props.size}rem`,
    height: `${props.size}rem`,
    xmlns: "http://www.w3.org/2000/svg",
    version: "1.1"
}))<StyleProps>`
    position: absolute;
    top: 0;
    left: 0
`

export const SvgCircle = styled.circle<StyleProps>`
    fill: none;
    stroke: ${({barBgColor}) => barBgColor};
    stroke-width: ${({strokeWidth}) => Number(strokeWidth) + 0.05}rem;
    stroke-dasharray: ${({size}) => (472 * (Number(size) * 10)) / 160};
    stroke-dashoffset: ${({barOffset}) => barOffset};
`