import './style.scss'
import { useContext, useEffect, useRef, useState } from "react";
import { images, funFacts } from "src/provider/config/constant";
import { AppText, CenterPopup, CenterPopupContainer, Circle, CustomContainer, FlexColumn, FlexRow, Icon } from "src/styles";
import ApiContext from "src/provider/API/call-service";


export const DecisionCheckWaitScreen: React.FC = () => {
    const {API} = useContext(ApiContext)
    const [facts, setFacts] = useState<Array<string>>([])
    const [currentPoint, setCurrentPoint] = useState(0)
    const [factBatch, setFactBatch] = useState(1)
    var [time] = useState<any>(null)
    var [count, setCount] = useState<any>(0)

    const isMounted = useRef(true)
    
    useEffect(() => {
        time =  setInterval(async () => {
            setCount((count: number) => {
                if (count === 30) {
                    clearInterval(time)
                    return 0 
                }
                else return count + 1
            });
        }, 1000)

        return () => { 
            isMounted.current = false
            clearInterval(time) 
        }
    }, [])

    useEffect(() => {
        if (count === 30) API.getRequestLoanOffers()
    }, [count])


    useEffect(() => {
        let timer: any = ''
        if (facts?.length) {
            timer = window.setTimeout(async () => {
                if (currentPoint === (facts.length - 1)) {
                    const factIndex =  3 * factBatch
                    if ((factIndex) < funFacts.length) {
                        setFacts(funFacts.slice((factIndex), (factIndex) + 3))
                        setFactBatch(factBatch + 1)
                    }
                    else {
                        setFactBatch(0)
                        setFacts(funFacts.slice(0, 3))
                    }
                    
                }
                setCurrentPoint(currentPoint != (facts.length - 1) ? currentPoint + 1 : 0)
            }, 10000)
        }
 
        return () => { clearTimeout(timer) }
     }, [currentPoint, facts])
     
    useEffect(() => {
        if (funFacts?.length) setFacts(funFacts.slice(0, 3))
    }, [funFacts])


    return (
        <>
            <CenterPopupContainer zIndex="8">
                <CenterPopup data-aos="slide-down">
                    {/* <i className="fa fa-times responder-cancel" onClick={() => API.getRequestLoanOffers()}></i> */}
                    <Icon src={images.CRENDLY_LOADER} topMargin='-20' className='wait-loader-gif' />
                    <CustomContainer 
                        width="30" bgColor="#00071F" radius="2" height="0.4" 
                        topMargin="-12" className='progress-waiting-bar'
                    >
                        <CustomContainer width={String((count/30) * 100)} sizeUnit="%" bgColor="#6DE7B4" radius="2" height="0.4" />
                    </CustomContainer>
                    <AppText width='28' textSize='1.5' fontWeight="400" align="center" topMargin="3">
                        We are looking for the best offers for you. This might take a while.  
                    </AppText>

                    <FlexColumn 
                        width='70' sizeUnit="%" topMargin="15" bottomMargin="4"
                        className="fun-fact-container" 
                    >
                        <AppText align="center" textSize="2" fontWeight="700" color='#FED0B7'>
                            Fun Facts
                        </AppText>
                        <AppText textSize='1.5' fontWeight="400" align="center" topMargin="2">
                            {facts[currentPoint]}
                        </AppText>
                        <FlexRow justifyContent='flex-start' width='auto' topMargin='2.5'>
                            {
                                Array.from(Array(3).keys()).map((digit, index) => {
                                    return (
                                        <Circle 
                                            size='0.8' 
                                            bgColor={currentPoint === digit ? '#6DE7B4' : '#3A3A3A'} 
                                            rightMargin='0.8'
                                            key={index}
                                            onClick={() => setCurrentPoint(digit)}
                                        />
                                    )
                                })
                            }
                        </FlexRow>
                    </FlexColumn>
                </CenterPopup>
            </CenterPopupContainer>
        </>
    )
}