import Home from './home'
import LoanType from './loanCreation/loanType'
import LoanTenor from './loanCreation/loanTenor'
import RepaymentOption from './loanCreation/repaymentOption'
import Loan from './loan'
import TakeLoanType from './takeLoan/loanType'
import LoanDuration from './takeLoan/loanDuration'
import LoanOffers from './loanBoard/loanOffers'
import LoanOfferDetails from 'src/app/dashboard/loanBoard/loanOfferDetails'
import loanOfferSelection from './loanBoard/loanOfferSelection'
import Transactions from './transactions'
import Payment from './payment'
import Explore from './explore'
import SettingsHome from './settings/home'
import Support from './settings/support'
import Account from './settings/account'
import Questionaire from './questionaire'
import LoanTypesQuick from './loanCategory/loanTypeList'
import AllBorrowers from './loan/interestedBorrowers'
import CompleteFaceScan from './settings/completeFaceScan'
import PaymentConfirmation from './paymentConfirmation'
import MaritalStatus_EducationBgUpdate from './settings/maritalAndEduStatus'
import EmploymentStatusUpdate from './settings/employmentStatus'
import AddressVerificationUpdate from './settings/addressInfo'
import MeansOfIdentificationUpdate from './settings/meansOfIdentification'
import CompleteSignatureUpload from './settings/signatureUpload'

export default [
    Home,
    LoanType,
    LoanTenor,
    RepaymentOption,
    Loan,
    TakeLoanType,
    LoanDuration,
    LoanOffers,
    LoanOfferDetails,
    loanOfferSelection,
    Transactions,
    Payment,
    Explore,
    SettingsHome,
    Support,
    Account,
    Questionaire,
    LoanTypesQuick,
    AllBorrowers,
    CompleteFaceScan,
    PaymentConfirmation,
    MaritalStatus_EducationBgUpdate,
    EmploymentStatusUpdate,
    AddressVerificationUpdate,
    MeansOfIdentificationUpdate,
    CompleteSignatureUpload
]