import './style.scss'
import { useState, useEffect, useContext } from 'react'
import AppInfoContext from 'src/provider/state-manager/appInfoProvider'
import ApiContext from 'src/provider/API/call-service'
import UserContext from 'src/provider/state-manager/userProvider'
import LoanContext from 'src/provider/state-manager/loanProvider'
import VisibilityContext from 'src/provider/state-manager/visibilityProvider'
import { useNavigate } from 'react-router-dom'
import utility, { getLoanStatusColor } from 'src/utils/utility'
import { ImUser } from 'react-icons/im'
import { AiTwotoneStar } from 'react-icons/ai'
import { BsHeadset, BsFillEyeFill } from 'react-icons/bs'
import { 
    AbsoluteContainer,
    AppSpan, AppText, Button, Circle, CustomContainer, 
    Divider, FlexColumn, FlexRow, 
    GridContainer, 
    Icon, OutlineButton, 
    RoundImageContainer, 
    ScreenContainer, 
} from 'src/styles'
import { images } from 'src/provider/config/constant'
import { AccountDeletion, Rating } from 'src/popup'
import { IoCopyOutline } from 'react-icons/io5'
import { CopyToClipboard } from 'react-copy-to-clipboard'


const SettingsHome = () => {
    const navigate = useNavigate()
    const {API} = useContext(ApiContext)
    const {user} = useContext(UserContext)
    const {notifier} = useContext(VisibilityContext)
    const {loan: {currentLoans}} = useContext(LoanContext)
    const {logout} = useContext(AppInfoContext)
    const [referral, setReferral] = useState({link: '', code: ''})
    const [view, setView] = useState('')

    useEffect(() => {
        if (user.userData?.referralLink) {
            const link = user.userData.referralLink
            const splittedLink = link.split('=')
            const code = splittedLink[splittedLink.length - 1]
            setReferral({link, code})
        }
    }, [user.userData])

    useEffect(() => {
        if(view) {
          document.body.style.overflow = 'hidden'
        }
        else document.body.style.overflow = 'auto'
    }, [view])

    async function handleLogout () {
        API.updateLoginHistory()
        await logout()
    }

    return (
        <>
            <ScreenContainer>
                <FlexRow justifyContent='flex-start' className='settings_personal-details-wrapper'>
                    <FlexRow 
                        bgColor="#4700E0" radius="0.5"
                        topPadding="0" bottomPadding="1.5"
                        leftPadding="2" rightPadding="2"
                        justifyContent="flex-start"
                        wrap="wrap" width='75'
                        className='settings-details-header'
                    >
                        <FlexRow width="33.75" justifyContent="flex-start" topMargin="1.5">
                            <Circle 
                                size='6.9' rightMargin='2' bgColor='#ffffff'
                            >
                                <Circle
                                    size='6.7'
                                    bgColor={getLoanStatusColor(user.formattedUserData?.loanStatusColorCode)}
                                >
                                    <RoundImageContainer 
                                        size="6" borderColor='#ffffff'
                                        style={{borderWidth: '1.5px'}}
                                        //className='setting-user-img'
                                    >
                                        <Icon 
                                            src={user.formattedUserData?.bioDetail?.profilePicture || images.USER2X}
                                            width='5.8' radius='50' height='5.8' noResize
                                        />
                                    </RoundImageContainer>
                                </Circle>
                            </Circle>
                            <CustomContainer width="auto" sizeUnit="">
                                <AppText textSize="1.4" >{user.formattedUserData?.bioDetail?.firstName} {user.formattedUserData?.bioDetail?.lastName}</AppText>
                                <AppSpan color="#E2E2E2" textSize="1.3" fontWeight="400">{user.formattedUserData?.primaryBankAccount?.bank} - {user.formattedUserData?.primaryBankAccount?.accountNumber}</AppSpan>
                            </CustomContainer>
                        </FlexRow>
                        <FlexRow width="35" justifyContent="space-between" topMargin="1.5" className="add-left-border">
                            <CustomContainer width="auto" sizeUnit=""  leftMargin="3">
                                <AppSpan color="#E2E2E2" textSize="1" fontWeight="400">TOTAL LOAN REQUESTED</AppSpan>
                                <AppText textSize="1.3" >{currentLoans?.userLoanInformation?.numberOfLoanRequestCreated || 0} (₦{utility.moneyFormat(currentLoans?.userLoanInformation?.totalLoanAmountRequested || 0)})</AppText>
                            </CustomContainer>
                            <CustomContainer width="auto" sizeUnit="" leftMargin="2">
                                <AppSpan color="#E2E2E2" textSize="1" fontWeight="400">TOTAL LOAN GRANTED</AppSpan>
                                <AppText textSize="1.3" >{currentLoans?.userLoanInformation?.numberOfLoanRequestsGranted || 0} (₦{utility.moneyFormat(currentLoans?.userLoanInformation?.totalLoanAmountGranted || 0)})</AppText>
                            </CustomContainer>
                        </FlexRow>
                    </FlexRow>

                    <CustomContainer width='34.2' topMargin='2' className='score-container'>
                        <CustomContainer>
                            <FlexRow justifyContent='space-between'>
                                <AppSpan fontWeight='400' textSize='1.3'>Your Crendly Score</AppSpan>
                                <AppSpan >{user.overallScore?.grandTotal || 0}</AppSpan>
                            </FlexRow>
                            <FlexColumn height='2' radius='0.2'>
                                <FlexRow height='0.4' justifyContent='flex-start' alignItems='flex-start'>
                                    <CustomContainer width='30.4' sizeUnit='%' height='0.4' bgColor='#FF5050'/>
                                    <CustomContainer width='20.5' sizeUnit='%' height='0.4' bgColor='#FF7650' />
                                    <CustomContainer width='20.76' sizeUnit='%' height='0.4' bgColor='#FFB750' />
                                    <CustomContainer width='14.3' sizeUnit='%' height='0.4' bgColor='#4DDBA4' />
                                    <CustomContainer width='14' sizeUnit='%' height='0.4' bgColor='#50A379' />
                                </FlexRow>
                                <CustomContainer 
                                    className='score-pointer'
                                    style={{left: `${user.overallScore?.grandTotal || 0}%`}}
                                    width='0.3' bgColor='#E0E0E0'
                                />
                            </FlexColumn>
                        </CustomContainer>
                        <CustomContainer topMargin='1.5'>
                            <FlexRow justifyContent='space-between'>
                                <AppSpan fontWeight='400' textSize='1.3'>Your Reputation Score</AppSpan>
                                <AppSpan >{user.overallScore?.totalReputationScore || 0}</AppSpan>
                            </FlexRow>
                            <FlexColumn height='2' radius='0.2'>
                                <FlexRow height='0.4' justifyContent='flex-start' alignItems='flex-start'>
                                <CustomContainer width='30.4' sizeUnit='%' height='0.4' bgColor='#FF5050'/>
                                    <CustomContainer width='20.5' sizeUnit='%' height='0.4' bgColor='#FF7650' />
                                    <CustomContainer width='20.76' sizeUnit='%' height='0.4' bgColor='#FFB750' />
                                    <CustomContainer width='14.3' sizeUnit='%' height='0.4' bgColor='#4DDBA4' />
                                    <CustomContainer width='14' sizeUnit='%' height='0.4' bgColor='#50A379' />
                                </FlexRow>
                                <CustomContainer 
                                    className='score-pointer'
                                    style={{left: `${user.overallScore?.totalReputationScore || 0}%`}}
                                    width='0.3' bgColor='#E0E0E0'
                                />
                            </FlexColumn>
                        </CustomContainer>
                    </CustomContainer>
                </FlexRow>
                <div className="settings_home-content-wrapper">
                    <FlexRow topMargin="8" wrap="wrap" justifyContent="flex-start" className="setting-card-wrapper" >
                        <div className="settings-card" onClick={() => navigate('/dashboard/settings/account')}>
                            <ImUser className="settings-icon" />
                            <CustomContainer leftMargin="1.5">
                                <AppText textSize="1.3" bottomMargin="0.6" >My Account</AppText>
                                <AppSpan textSize="1.3" fontWeight="400">Make changes to your account</AppSpan>
                            </CustomContainer>
                        </div>
                        <div className="settings-card" onClick={() => setView('rating')}>
                            <AiTwotoneStar className="settings-icon" />
                            <CustomContainer leftMargin="1.5">
                                <AppText textSize="1.3" bottomMargin="0.6" >Rate Crendly</AppText>
                                <AppSpan textSize="1.3" fontWeight="400">Rate your experience using crendly</AppSpan>
                            </CustomContainer>
                        </div>
                        <div className="settings-card" onClick={() => navigate('/dashboard/settings/support')}>
                            <BsHeadset className="settings-icon" />
                            <CustomContainer leftMargin="1.5">
                                <AppText textSize="1.3" bottomMargin="0.6" >Support</AppText>
                                <AppSpan textSize="1.3" fontWeight="400">Get in touch with us</AppSpan>
                            </CustomContainer>
                        </div>
                    </FlexRow>
                    <Divider bgColor="#081952"  margin="2" />

                    <FlexRow 
                        justifyContent="flex-start" 
                        width="auto" sizeUnit=""
                        topMargin="3"
                        wrap="wrap"
                    >
                        <FlexRow justifyContent="flex-start" 
                            alignItems="flex-start" width="auto" sizeUnit=""
                        >
                            <BsFillEyeFill className="settings-icon" />
                            <CustomContainer leftMargin="1.5" width="auto" sizeUnit="" rightMargin="5">
                                <AppText textSize="1.3" bottomMargin="0.5" >Hide my visibility</AppText>
                                <AppSpan textSize="1.3" fontWeight="400">Hide or show visibility on Crendly</AppSpan>
                            </CustomContainer>
                        </FlexRow>
                        <div className="visibility-slider-container">
                            <input type="checkbox" className="visibility-checkbox" />
                            <div className="visibility-slider-shadow"></div>
                            <span className="visibility-slider"></span>
                        </div>
                    </FlexRow>
                    { referral.link &&
                        <CustomContainer topMargin='3'>
                            <FlexRow gap='2' wrap='wrap' justifyContent='flex-start'>
                                <GridContainer 
                                    width='35' radius='0.6' bgColor='#081952'
                                    minHeight='5.5' padding='1.5'
                                >
                                    <FlexRow justifyContent='space-between'>
                                        <AppSpan color='#6DE7B4' textSize='1.5' fontWeight='400' rightMargin='0.6'>
                                            Referral Code
                                        </AppSpan>
                                        <FlexRow width='auto' sizeUnit='' gap='1.5'>
                                            <AppSpan textSize="1.5" fontWeight='500'>
                                                {referral.code.substring(0, 20)}
                                            </AppSpan>
                                            <CopyToClipboard
                                                text={referral.code}
                                                onCopy={() => notifier.show('Referral Code Copied Successfully', 'success')}
                                            >
                                                <AppSpan 
                                                    color='#6DE7B4' hoverColor='#FED0B7' 
                                                    textSize='1.7' cursor='pointer'
                                                >
                                                    <IoCopyOutline />
                                                </AppSpan>
                                            </CopyToClipboard>
                                        </FlexRow>
                                    </FlexRow>
                                </GridContainer>
                                <GridContainer 
                                    width='35' radius='0.6' bgColor='#081952'
                                    minHeight='5.5' padding='1.5'
                                >
                                    <FlexRow justifyContent='space-between'>
                                        <AppSpan color='#6DE7B4' textSize='1.5' fontWeight='400' rightMargin='0.6'>
                                            Referral Link
                                        </AppSpan>
                                        <FlexRow width='auto' sizeUnit='' gap='1.5'>
                                            <AppSpan textSize="1.5" fontWeight='500'>
                                                {referral.link.substring(0, 20)}...
                                            </AppSpan>
                                            <CopyToClipboard
                                                text={referral.link}
                                                onCopy={() => notifier.show('Referral Link Copied Successfully', 'success')}
                                            >
                                                <AppSpan 
                                                    color='#6DE7B4' hoverColor='#FED0B7' 
                                                    textSize='1.7' cursor='pointer'
                                                >
                                                    <IoCopyOutline />
                                                </AppSpan>
                                            </CopyToClipboard>
                                        </FlexRow>
                                    </FlexRow>
                                </GridContainer>
                            </FlexRow>
                        </CustomContainer>
                    }
                    <FlexRow 
                        topMargin='6' gap='2'
                        justifyContent='flex-start'
                        wrap='wrap'
                        className='account-strict-action'
                    >
                        <OutlineButton
                            color="#E54B4D"
                            width="19"
                            hoverBgColor="#E54B4D"
                            hoverColor="#fff"
                            onClick={handleLogout}
                        >
                            Logout
                        </OutlineButton>
                        <Button
                            width="19"
                            color="#E54B4D"
                            bgColor="transparent"
                            hoverBgColor='#E54B4D'
                            hoverColor="#fff"
                            onClick={() => setView('account-deletion')}
                        >
                            Delete My Account
                        </Button>
                    </FlexRow>
                </div>
            </ScreenContainer>
            { view === 'rating' &&
                <Rating close={() => setView('view')} />
            }
            { view === 'account-deletion' &&
                <AccountDeletion 
                    close={() => setView('view')}
                />
            }
        </>
    )
}

export default utility.routeData('settings/home', 'Settings home', <SettingsHome/>)
