import { useState, useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import ApiContext from 'src/provider/API/call-service'
import LoanContext from 'src/provider/state-manager/loanProvider'
import { images } from 'src/provider/config/constant'
import { CgCheck } from 'react-icons/cg'
import { IoMdStar } from 'react-icons/io'
import { 
    SidePopupContainer, 
    SidePopup, 
    FlexRow, 
    AppText,
    CustomContainer,
    Button,
    AppSpan,
    SideBarSummary,
    FlexColumn,
    Divider,
    Circle,
    Icon,
    RoundImageContainer
} from "src/styles"
import { RejectionReason, Agreement } from 'src/popup'
import utility, { getLoanStatusColor } from 'src/utils/utility'
import helpers from 'src/utils/helpers'

export const LoanRequestDetails: React.FC<any> = ({data, close, closeNotif}) => {
    const navigate = useNavigate()
    const {API} = useContext(ApiContext)
    const {setLoanProperty} = useContext(LoanContext)
    const [showRejectionReason, setShowRejectionReason] = useState(false)
    const [showAgreement, setShowAgreement] = useState(false)

    let dateTime = helpers.splitDateTime(data?.createdAt || data?.CreatedAt)
    let numberOfBorrowers = data?.numberOfPerson - data?.numberOfSlotLeft
    async function handleIgnoreRequest () {
        setShowRejectionReason(true)
    }

    async function handleAcceptRequest () {
        setShowAgreement(true)
    }

    async function handleBorrowers () {
        await setLoanProperty('selectedOfferRequest', {...data})
        if (closeNotif) closeNotif()
        navigate('/dashboard/loan/borrowers')
        close()
    }

    function handleCloseAll () {
        if (closeNotif) closeNotif()
        else close()
    }

    return (
        <>
        { !showRejectionReason &&
            <SidePopupContainer >
                <SidePopup data-aos="slide-left">
                    <div className="popup-title-wrapper">
                        <h4>Loan Request Details</h4>
                        <i className="fa fa-times" onClick={close}></i>
                    </div>

                    <SideBarSummary bgColor="#4700e0" topPadding="2" bottomPadding="2">
                        <FlexColumn>
                            {/* <div className="img-circle-wrapper">
                                <img src={images.USER_IMG} />
                                <div className="img-circle-icon">
                                    <CgCheck className="check-circle-icon" />
                                </div>
                            </div> */}
                            <Circle 
                                size='7.2' rightMargin='1' bgColor='#ffffff'
                            >
                                <Circle
                                    size='6.9'
                                    bgColor={getLoanStatusColor(data?.userTag)}
                                >
                                    <RoundImageContainer 
                                        size="6.1" borderColor='#ffffff'
                                        style={{borderWidth: '1.5px'}}
                                    >
                                        <Icon 
                                            src={data.profilePictureUrl || images.USER_IMG}
                                            width='5.8' radius='50' height='5.8' noResize
                                        />
                                    </RoundImageContainer>
                                </Circle>
                            </Circle>
                            <AppSpan topMargin="1.5" bottomPadding="0.5">{data?.firstName} {data?.lastName}</AppSpan>
                            <AppSpan fontWeight="400" textSize="1.4">Loan requested on {dateTime?.date}</AppSpan>
                            {/* <FlexRow topMargin="0.7">
                                { 
                                    Array.from(Array(5).keys()).map((item: any, index: number) => {
                                        let color = index <= 3 ? '#FFBB2E' : '#C4C4C4'
                                        return (
                                            <IoMdStar style={{fontSize: '2rem', color}} key={index} />
                                        )
                                    })
                                }
                            </FlexRow> */}
                        </FlexColumn>
                        <CustomContainer topMargin="3" leftPadding="1.5" rightPadding="1.5" bottomMargin="1">
                            <FlexRow justifyContent="space-between">
                                <AppSpan fontWeight="400" textSize="1.4">Crendly Score</AppSpan>
                                <AppSpan color="#6DE7B4" leftPadding="1" textSize="1.4">{(data?.creditScoreInfo?.grandTotal || data?.loanOffer?.creditScoreInfo?.grandTotal || 0).toFixed(2)}%</AppSpan>
                            </FlexRow>
                            <Divider margin="0.6" />
                            <FlexRow justifyContent="space-between" topMargin='1.2'>
                                <AppSpan fontWeight="400" textSize="1.4">Loan amount</AppSpan>
                                <AppSpan fontWeight="400" textSize="1.4">&#8358;{utility.moneyFormat(data?.loanOffer?.amountPerPerson || data?.amount)}</AppSpan>
                            </FlexRow>
                            <FlexRow justifyContent="space-between" topMargin='1'>
                                <AppSpan fontWeight="400" textSize="1.4">Interest rate</AppSpan>
                                <AppSpan fontWeight="400" leftPadding="1" textSize="1.4">{(data?.loanOffer?.interest || data?.loanOffer?.interest === 0) ? data?.loanOffer?.interest : data?.interest}%</AppSpan>
                            </FlexRow>
                            <FlexRow justifyContent="space-between" topMargin='1'>
                                <AppSpan fontWeight="400" textSize="1.4">Interest type</AppSpan>
                                <AppSpan fontWeight="400" leftPadding="1" textSize="1.4">{data?.loanOffer?.repaymentTermName || data?.repaymentTermName}</AppSpan>
                            </FlexRow>
                            <FlexRow justifyContent="space-between" topMargin='1'>
                                <AppSpan fontWeight="400" textSize="1.4">Repayment amount</AppSpan>
                                <AppSpan fontWeight="400" leftPadding="1" textSize="1.4">&#8358;{utility.moneyFormat((data?.loanOffer?.totalRepaymentBorrower || data?.loanOffer?.totalRepaymentBorrower === 0) ? data?.loanOffer?.totalRepaymentBorrower : data?.totalAmountToBeRepaid)}</AppSpan>
                            </FlexRow>
                            <Divider margin="0.6" />
                            <FlexRow justifyContent="space-between" topMargin='1.2'>
                                <AppSpan fontWeight="400" textSize="1.4">Request date</AppSpan>
                                <AppSpan fontWeight="400" leftPadding="1" textSize="1.4">{data?.createdAt?.split('T')[0]}</AppSpan>
                            </FlexRow>
                            <FlexRow justifyContent="space-between" topMargin='1'>
                                <AppSpan fontWeight="400" textSize="1.4">Repayment type</AppSpan>
                                <AppSpan fontWeight="400" leftPadding="1" textSize="1.4">{(data?.loanOffer?.rateId == 1 || data?.rateId == 1) ? 'Installment' : 'One-off'}</AppSpan>
                            </FlexRow>
                            <FlexRow justifyContent="space-between" topMargin='1'>
                                <AppSpan fontWeight="400" textSize="1.4">Duration</AppSpan>
                                <AppSpan fontWeight="400" leftPadding="1" textSize="1.4">{(data?.loanOffer?.tenor || data?.loanOffer?.tenor === 0) ? data?.loanOffer?.tenor : data?.tenor} {data?.loanOffer?.tenorType?.toLowerCase() || data?.tenorType?.toLowerCase()}(s)</AppSpan>
                            </FlexRow>
                        </CustomContainer>
                    </SideBarSummary>
                    <AppText topMargin='1.5' textSize="1.7">Loan status</AppText>
                    <FlexRow justifyContent="flex-start">
                        <Circle 
                            bgColor="linear-gradient(0deg, #FF3A5A, #FF3A5A), #F65654"
                            size="1.1"
                            rightMargin="1"
                        />
                        <AppSpan textSize="1.4">
                            Request submitted by
                            <AppSpan textSize="1.4" color="#FED0B7" leftPadding="0.5">{data?.firstName} {data?.lastName}</AppSpan>
                        </AppSpan>
                    </FlexRow>
                    <AppText color="#AAAAAA" fontWeight="400" leftPadding="2.5" topPadding="0.6">{dateTime?.slashedDate} {dateTime?.completeTime}</AppText>
                    { ((!data?.isInitiatedByLender || (data?.isInitiatedByLender && data?.agreedBorrower)) && (!data?.agreedLender && !data?.rejectedLender)) &&
                        <FlexRow justifyContent="flex-end" topMargin="7" className="loan-acceptance-btns">
                            <a className="underlined-link-btn" onClick={handleIgnoreRequest}>Reject Request</a>
                            <Button 
                                width="20" 
                                height="4"
                                hoverBgColor="#FED0B7"
                                leftMargin="3.5"
                                onClick={handleAcceptRequest}
                            >
                                Accept request
                            </Button>
                        </FlexRow>
                    }
                    { ((data?.isInitiatedByLender && !data?.agreedBorrower) || (data?.rejectedLender || data?.agreedLender)) &&
                        <AppText topMargin='6' fontWeight='400' color='#FED0B7' >Awaiting Borrower acceptance</AppText>
                    }
                    
                    <Divider/>
                    <CustomContainer>
                        <AppText>Portfolio: {data?.loanTypeName}</AppText>
                        <FlexRow justifyContent='space-between' wrap='wrap'>
                            <FlexRow width='auto' size='' topMargin='0.4'>
                                <Icon src={images.FRUIT_HEADS2X} width='6'/>
                                <AppSpan fontWeight='400' textSize="1.4" leftMargin="0.5">
                                    {data?.loanOffer?.numberOfPerson - 1 || 0} other person(s) are interested in this offer
                                </AppSpan>
                            </FlexRow>
                            <a className="underlined-link-btn" onClick={handleBorrowers}>View all</a>
                        </FlexRow>
                    </CustomContainer>
                </SidePopup>
            </SidePopupContainer>
        }
        { showAgreement &&
            <Agreement 
                close={() => setShowAgreement(false)}
                closeDetails={handleCloseAll}
                data={data}
            />
        }
        { showRejectionReason &&
            <RejectionReason
                close={() => setShowRejectionReason(false)}
                closeDetails={handleCloseAll}
                data={data}
            />
        }
        </>
    )
}
