import { useContext } from 'react'
import ApiContext from 'src/provider/API/call-service'
import { 
    ResponderContentWrapper, 
    ResponderIconCircle, 
    ResponderIconHolder, 
} from 'src/component/Responder/style'
import { CenterPopupContainer, CenterPopup, Button, Icon, FlexRow, AppText, AppSpan } from 'src/styles'
import { images } from 'src/provider/config/constant'


export const EndPortfolio: React.FC<any> = ({close, closePortfolio, data, refreshMethod}) => {
    const {API} = useContext(ApiContext)

    async function handleEndPortfolio () {
        await API.endPortfolio(data?.loanOfferId)
        closePortfolio()
        close()
        refreshMethod()
    }

    return (
        <CenterPopupContainer zIndex="8">
            <CenterPopup data-aos="slide-down">
                <i className="fa fa-times responder-cancel" onClick={close}></i>
                    <ResponderContentWrapper>
                        <ResponderIconCircle>
                            <Icon src={images.WARNING_RESPONSE} />
                        </ResponderIconCircle>
                        <ResponderIconHolder></ResponderIconHolder>
                        <AppText topMargin="4" fontWeight="400" align="center" textSize='1.4'>
                            Are you sure you want to end this portfolio? There are still Loanees on the portfolio.
                        </AppText>
                        <AppSpan topMargin="3" textSize='1.4'>Do you still want to end portfolio?</AppSpan>
                        <FlexRow justifyContent="flex-end" className="loan-acceptance-btns" topMargin="7">
                            <a className="underlined-link-btn" style={{color: '#FF3A5A'}} onClick={handleEndPortfolio}>Yes, End Portfolio</a>
                            <Button 
                                width="17" 
                                height="4"
                                hoverBgColor="#FED0B7"
                                leftMargin="3.5"
                                onClick={close}
                            >
                                No, Don't
                            </Button>
                        </FlexRow>
                    </ResponderContentWrapper>
            </CenterPopup >
        </CenterPopupContainer>
    )
}
