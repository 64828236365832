import styled from 'styled-components'
import { StyleProps } from 'src/model'
import { FaBars } from 'react-icons/fa'

export const NavBarContainer = styled.nav<StyleProps>`
    width: 100%;
    height: 8rem;
    background-color: #00071F;
    position: fixed;
    top: 0;
    left: 0;
    overflow: hidden;
    z-index: 6;
    transition: all .2s;

`
export const NavBarLogo = styled.img`
    position: absolute;
    top: 1rem;
    left: -1rem;
    transform: scale(.65);

    @media (max-width: 900px) {
        transform: scale(.55);
        top: .5rem;
        left: -3rem;
    }
    @media (max-width: 600px) {
        transform: scale(.45);
        top: .2rem;
        left: -6rem;
    }   
`
export const MenuIcon = styled(FaBars)`
    font-size: 2.5rem;
    position: absolute;
    color: rgba(255, 255, 255, 0.7);
    top: 50%;
    left: 3rem;
    transform: translateY(-40%);
    transition: all .2s;
    display: none;

    &:hover {
        color: #FED0B7
    }

    @media (max-width: 770px) {
        display: inline-block;
    }

    @media (max-width: 390px) {
        left: 1.5rem
    }
`