import { useState } from 'react'
import { handleTypeNumberControl } from 'src/utils/utility'
import { FlexRow } from "src/styles"

export const TransPin: React.FC<any> = ({onChange}) => {
    const [transPin, setTransPin] = useState('')

    const handlePin = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.value.length < 5) {
            setTransPin(e.target.value)
            onChange(e.target.value)
        }
    }
    
    return (
        <FlexRow justifyContent="flex-start" width="auto" sizeUnit="" topMargin='0.5'>
            <input 
                className='display-hidden' id='pin' 
                name="pin" type='number' 
                value={transPin} 
                onChange={handlePin}
                onKeyUp={handleTypeNumberControl}
                onKeyDown={handleTypeNumberControl} 
                autoFocus={true} autoComplete="off"
            />
            <label className={`withdraw-passCode ${transPin.length === 0 && 'active-pin-box'}`} htmlFor='pin' >{transPin[0] ? '*' : ''}</label>
            <label className={`withdraw-passCode ${transPin.length === 1 && 'active-pin-box'}`} htmlFor='pin' >{transPin[1] ? '*' : ''}</label>
            <label className={`withdraw-passCode ${transPin.length === 2 && 'active-pin-box'}`} htmlFor='pin' >{transPin[2] ? '*' : ''}</label>
            <label className={`withdraw-passCode ${transPin.length === 3 && 'active-pin-box'}`} htmlFor='pin' >{transPin[3] ? '*' : ''}</label>
        </FlexRow>
    )
}