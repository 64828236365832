import { useState, useRef, useContext } from 'react'
import utility, { handleTypeNumberControl } from 'src/utils/utility'
import { useNavigate } from 'react-router-dom'
import ApiContext from 'src/provider/API/call-service'
import { Container } from 'src/component'
import { Button, FlexRow } from 'src/styles'
import { BsFillEyeSlashFill, BsFillEyeFill } from 'react-icons/bs'

const NewPasscode = () => {
    const navigate = useNavigate()
    const {API} = useContext(ApiContext)
    const [password, setPassword] = useState({pass: '', cPass: ''})
    const [showCode, setShowCode] = useState({passcode: false, confirmCode: false})

    const handlePassword = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.value.length < 7) {
            setPassword({...password, [e.target.name]: e.target.value})
        }
    }


    async function handleNext (e: React.FormEvent<HTMLButtonElement|HTMLFormElement>) {
        e.preventDefault()
        const response = await API.resetPassword(password.pass, password.cPass)
        if (response) navigate('/login')
    }

    return (
        <Container>
            <p className="right-align-text">Don't have an account? 
                <a className="registration-link" onClick={() => navigate('/onboarding/type')}> Sign up</a>
            </p>
            <div className="main-content-wrapper">
                <h3>New Passcode</h3>
                <p>Enter your new passcode and you are all set. </p>
                <form className="login-form">
                    <div className="form-group-wrapper">
                        <FlexRow bottomPadding='1' justifyContent='flex-start'>
                            <label className="">Passcode</label>  
                            { !showCode.passcode ?
                                <BsFillEyeSlashFill 
                                    style={{marginLeft: '1.5rem', marginTop: '-0.5rem'}}
                                    onClick={() => setShowCode({...showCode, passcode: true})} 
                                />
                                : <BsFillEyeFill 
                                    style={{marginLeft: '1.5rem', marginTop: '-0.5rem'}}
                                    onClick={() => setShowCode({...showCode, passcode: false})} 
                                />
                            }
                        </FlexRow>
                        <div className="passcode-input-wrapper">
                            <input 
                                className='display-hidden' 
                                id='pass' name="pass" type='number'
                                value={password.pass} autoFocus={true} 
                                onChange={handlePassword} 
                                onKeyUp={handleTypeNumberControl}
                                onKeyDown={handleTypeNumberControl} 
                                autoComplete="off"
                            />
                            <label className={`passcode-input ${password.pass.length === 0 && 'active-pin-box'}`} htmlFor='pass' >{password.pass[0] && showCode.passcode ? password.pass[0] : password.pass[0] ? '*' : ''}</label>
                            <label className={`passcode-input ${password.pass.length === 1 && 'active-pin-box'}`} htmlFor='pass' >{password.pass[1] && showCode.passcode ? password.pass[1] : password.pass[1] ? '*' : ''}</label>
                            <label className={`passcode-input ${password.pass.length === 2 && 'active-pin-box'}`} htmlFor='pass' >{password.pass[2] && showCode.passcode ? password.pass[2] : password.pass[2] ? '*' : ''}</label>
                            <label className={`passcode-input ${password.pass.length === 3 && 'active-pin-box'}`} htmlFor='pass' >{password.pass[3] && showCode.passcode ? password.pass[3] : password.pass[3] ? '*' : ''}</label>
                            <label className={`passcode-input ${password.pass.length === 4 && 'active-pin-box'}`} htmlFor='pass' >{password.pass[4] && showCode.passcode ? password.pass[4] : password.pass[4] ? '*' : ''}</label>
                            <label className={`passcode-input ${password.pass.length === 5 && 'active-pin-box'}`} htmlFor='pass' >{password.pass[5] && showCode.passcode ? password.pass[5] : password.pass[5] ? '*' : ''}</label>
                        </div>
                    </div>
                    <div className="form-group-wrapper">
                        <FlexRow bottomPadding='1' justifyContent='flex-start'>
                            <label className="">Confirm Passcode</label>  
                            { !showCode.confirmCode ?
                                <BsFillEyeSlashFill 
                                    style={{marginLeft: '1.5rem', marginTop: '-0.5rem'}}
                                    onClick={() => setShowCode({...showCode, confirmCode: true})} 
                                />
                                : <BsFillEyeFill 
                                    style={{marginLeft: '1.5rem', marginTop: '-0.5rem'}}
                                    onClick={() => setShowCode({...showCode, confirmCode: false})} 
                                />
                            }
                        </FlexRow>
                        <div className="passcode-input-wrapper">
                            <input 
                                className='display-hidden' 
                                id='cPass' type='number'
                                name="cPass" value={password.cPass} 
                                onChange={handlePassword}
                                onKeyUp={handleTypeNumberControl}
                                onKeyDown={handleTypeNumberControl} 
                                autoComplete="off"
                            />
                            <label className={`passcode-input ${password.cPass.length === 0 && 'active-pin-box'}`} htmlFor='cPass' >{password.cPass[0] && showCode.confirmCode ? password.cPass[0] : password.cPass[0] ? '*' : ''}</label>
                            <label className={`passcode-input ${password.cPass.length === 1 && 'active-pin-box'}`} htmlFor='cPass' >{password.cPass[1] && showCode.confirmCode ? password.cPass[1] : password.cPass[1] ? '*' : ''}</label>
                            <label className={`passcode-input ${password.cPass.length === 2 && 'active-pin-box'}`} htmlFor='cPass' >{password.cPass[2] && showCode.confirmCode ? password.cPass[2] : password.cPass[2] ? '*' : ''}</label>
                            <label className={`passcode-input ${password.cPass.length === 3 && 'active-pin-box'}`} htmlFor='cPass' >{password.cPass[3] && showCode.confirmCode ? password.cPass[3] : password.cPass[3] ? '*' : ''}</label>
                            <label className={`passcode-input ${password.cPass.length === 4 && 'active-pin-box'}`} htmlFor='cPass' >{password.cPass[4] && showCode.confirmCode ? password.cPass[4] : password.cPass[4] ? '*' : ''}</label>
                            <label className={`passcode-input ${password.cPass.length === 5 && 'active-pin-box'}`} htmlFor='cPass' >{password.cPass[5] && showCode.confirmCode ? password.cPass[5] : password.cPass[5] ? '*' : ''}</label>
                        </div>
                    </div>
                    <Button 
                        width="19" 
                        height="4.5" 
                        topMargin="10"
                        hoverBgColor="#FFC49D" 
                        className="loan-action-btn"
                        disabled={password.pass.length != 6 || password.cPass.length != 6 ? true : false}
                        onClick={handleNext}
                    >
                        Login
                    </Button>
                </form>
            </div>

        </Container>
    )
}

export default utility.routeData('forget-password/new-passcode', 'New Passcode', <NewPasscode/>)