import { useState, useRef, useContext, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import ApiContext from 'src/provider/API/call-service'
import UserContext from 'src/provider/state-manager/userProvider'
import VisibilityContext from 'src/provider/state-manager/visibilityProvider'
import utility, { handleTypeNumberControl } from 'src/utils/utility'
import { ImUser } from 'react-icons/im'
import { BsFillEyeFill, BsFillEyeSlashFill, BsChevronLeft } from 'react-icons/bs'
import { IoLockClosed } from 'react-icons/io5'
import { GoChevronDown } from 'react-icons/go'
import { 
    AppSpan, AppText, CustomContainer, 
    FlexRow, ScreenContainer, FormGroupsWrapper,
    ScreenTitle, FormGroup, FormGroupWithIcon, 
    Button, BorderlessButton, RoundImageContainer, Icon, AbsoluteContainer, FlexColumn, AppLink, AppLabel
} from 'src/styles'
import states from 'src/utils/states'
import helpers from 'src/utils/helpers'
import { images, cloudinaryData } from 'src/provider/config/constant'
import { BiImageAdd } from 'react-icons/bi'


const Account = () => {
    const navigate = useNavigate()
    const {API} = useContext(ApiContext)
    const {user: {formattedUserData, userData}} = useContext(UserContext)
    const {notifier} = useContext(VisibilityContext)
    const [tab, setTab] = useState('account')
    const [pinChangeType, setPinChangeType] = useState('')
    const [isHidden, setIsHidden] = useState({oPass: true, nPass: true, cPass: true, oPin: true, nPin: true, cPin: true})
    const [isValidated, setIsValidated] = useState(false)
    const [input, setInput] = useState({
        firstName: '', lastName: '', gender: '', dateOfBirth: '',
        email: '', phoneNumber: '', residenceNo: '', apartmentNo: '',
        landMark: '', streetName: '', lga: '', state: '', relationship: '',
    })
    const [password, setPassword] = useState({pass: '', nPass: '', cPass: ''})
    const [pin, setPin] = useState({pin: '', nPin: '', cPin: ''})
    const [nextOfKin, setNextOfKin] = useState<any>({})
    const [residenceCategories, setResidenceCategories] = useState<any>([])
    const [lgaList, setLgaList] = useState<any>([])
    const [signature, setSignature] = useState('')
    const [profilePhoto, setProfilePhoto] = useState('')

    useEffect(() => {
        if (!nextOfKin.length) fetchNextOfKin()
        if (!residenceCategories.length) fetchResidenceCategories()
    }, [userData.userId, input.state])

    useEffect(() => {
        if (input.state && !lgaList.length) {
            const stateId = helpers.getStateId(input.state)
            const stateData = states[stateId]
            setLgaList(stateData?.lgas)
        }
    }, [])

    useEffect(() => {
        const auth = helpers.inputValidator(input, ['apartmentNo'])
        setIsValidated(auth.isValidated && helpers.validatePhoneNo(input.phoneNumber) && /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(input.email))
    }, [input])

    async function fetchNextOfKin () {
        const response = await API.getNextOfKinDetails()
        if (response) {
            setNextOfKin(response)
            const {firstName, lastName, phoneNumber, email, gender, dateOfBirth, relationship} = response.personalDetail
            const {residenceNo, apartmentNo, streetName, landMark, state, lga} = response.contactAddress
            setInput({
                ...input, firstName, lastName, phoneNumber, 
                email, gender, dateOfBirth, relationship: relationship || '',
                residenceNo, apartmentNo, streetName, landMark, state, lga
            })
        }
    }

    async function fetchResidenceCategories () {
        const response = await API.getResidenceCategories()
        if (response) setResidenceCategories(response)
    }

    // useEffect(() => {
    //     document.addEventListener('keydown', checkKey, false)

    //     return () => document.removeEventListener('keydown', checkKey, false)
    // }, [])

    // function checkKey (e: any) {
    //     if(e.key === 'Delete' || e.key === 'Backspace') {
    //         //e.preventDefault()
    //     }
    // }

    const handleInput = (e: React.ChangeEvent<HTMLInputElement|HTMLSelectElement>) => {
        if (e.target.name === 'state') {
            if (e.target.value) {
                const stateData = states[Number(e.target.value)]
                setInput({...input, state: stateData.state, lga: ''})
                setLgaList(stateData?.lgas)
            }
        }

        else {
            if (e.target.name === 'phoneNumber') {
                if (e.target.value.length > 14) return false
            }
            setInput({...input, [e.target.name]: e.target.value})
        }
    }

    function handlePassword (e: React.ChangeEvent<HTMLInputElement>) {
        // if(e.target.value.length < 2) {
        //     setPassword({...password, [e.target.name]: e.target.value})
        //     if (e.target.nextSibling) {
        //         (e.target.nextSibling as HTMLInputElement).focus()
        //     }
        // }
        if (e.target.value.length < 7) {
            setPassword({...password, [e.target.name]: e.target.value})
        }
    }

    function handlePin (e: React.ChangeEvent<HTMLInputElement>) {
        if (e.target.value.length < 5) {
            setPin({...pin, [e.target.name]: e.target.value})
        }
    }

    async function handlePasscodeChange () {
        const payload = {
            currentPassword: password.pass,
            newPassword: password.nPass,
            confirmPassword: password.cPass
        }
        const response = await API.changePasscode(payload)
    }

    async function handlePinChange () {
        const payload = {
            currentPin: pin.pin,
            newPin: pin.nPin,
            confirmPin: pin.cPin
        }
        const response = await API.changeTransactionPin(payload)
    }

    async function handleNextOfKinUpdate () {
        await API.updateNextOfKinDetails(input)
    }

    async function handleUpload(e: React.ChangeEvent<HTMLInputElement>, type: string) {
        if (e.target.files instanceof FileList) {
            if(!helpers.isValidFileType(e.target.value)) return notifier.show('Only JPG, PNG, and JPEG files are allowed')

            const data = new FormData()
            data.append('file', e.target.files[0])
            data.append('upload_preset', cloudinaryData.UPLOAD_PRESET)
            data.append('cloud_name', cloudinaryData.CLOUND_NAME)
            data.append('public_id', `CRENDLY_PROFILE_PICS-${userData?.userId}-${Date.now()}`)

            const url = await API.uploadToCloudinary(data, cloudinaryData.CLOUND_NAME)
            if (url) {
                if (type === 'photo') {
                    setProfilePhoto(url)
                    await API.updateSignature(formattedUserData?.bioDetail?.signature || '', url)
                }
                else {
                    setSignature(url)
                    await API.updateSignature(url, formattedUserData?.bioDetail?.profilePicture || '')
                }
                API.getUserDetails()
            }
        }
    }

    return (
        <>
            <ScreenContainer>
                <div className="account-settings-container">
                    <div className="account-setting-tabs">
                        <FlexRow width="auto" sizeUnit="" justifyContent="flex-start" alignItems="flex-start">
                            <BsChevronLeft className="loan-back-icon" onClick={() => navigate(-1)} />
                            <CustomContainer width="auto" sizeUnit="" className="acct-setting-title">
                                <AppText textSize="2" bottomMargin="1" >My Account</AppText>
                                <AppSpan textSize="1.3" fontWeight="400">
                                    Make changes to account information and settings
                                </AppSpan>
                            </CustomContainer>
                        </FlexRow>
                        <div className="tabs-wrapper">
                            <div 
                                className={tab === 'account' ? 'tab-content selected-tab' : 'tab-content'} 
                                onClick={() => setTab('account')}
                            >
                                <ImUser className={tab === 'account' ? 'settings-icon tab-icon' : 'settings-icon'} />
                                <CustomContainer leftMargin="1.5">
                                    <AppText textSize="1.5" className="text-cursor" >Personal Information</AppText>
                                    <AppSpan textSize="1.3" fontWeight="400" className="text-cursor">Make changes to your information </AppSpan>
                                </CustomContainer>
                            </div>
                            <div 
                                className={tab === 'next-of-kin' ? 'tab-content selected-tab' : 'tab-content'} 
                                onClick={() => setTab('next-of-kin')}
                            >
                                <ImUser className={tab === 'next-of-kin' ? 'settings-icon tab-icon' : 'settings-icon'} />
                                <CustomContainer leftMargin="1.5">
                                    <AppText textSize="1.5" className="text-cursor">Next of Kin</AppText>
                                    <AppSpan textSize="1.3" fontWeight="400" className="text-cursor">Add or view next of kin information </AppSpan>
                                </CustomContainer>
                            </div>
                            <div 
                                className={tab === 'security' ? 'tab-content selected-tab' : 'tab-content'} 
                                onClick={() => setTab('security')}
                            >
                                <IoLockClosed className={tab === 'security' ? 'settings-icon tab-icon' : 'settings-icon'} />
                                <CustomContainer leftMargin="1.5">
                                    <AppText textSize="1.5" className="text-cursor">Security</AppText>
                                    <AppSpan textSize="1.3" fontWeight="400" className="text-cursor">Reset your passcode and keep your account safe </AppSpan>
                                </CustomContainer>
                            </div>
                        </div>
                    </div>

                    <div className="account-setting-info">
                        <div className="vertical-divider"></div>
                        { tab === "account" &&
                            <CustomContainer>
                                <CustomContainer bottomMargin="4">
                                    <ScreenTitle textSize="1.8" bottomMargin="1">Profile Photo</ScreenTitle>
                                    <RoundImageContainer bgColor='#fff' size='7' bottomMargin='1' topMargin='2'>
                                        <Icon 
                                            src={profilePhoto ||formattedUserData?.bioDetail?.profilePicture || images.USER2X} 
                                            width='7' height='7' radius='50'
                                        />
                                        <AbsoluteContainer right='-0.5' bottom='-0.4'>
                                            <AppLabel color='#fff' htmlFor='profilePhoto'>
                                                <RoundImageContainer bgColor='#2E4DBD' size='2.5'>
                                                    <input type="file" onChange={(e) => handleUpload(e, 'photo')} className="idcard-input" id="profilePhoto" />
                                                    <BiImageAdd style={{fontSize: '1.8rem'}} />
                                                </RoundImageContainer>
                                            </AppLabel>
                                        </AbsoluteContainer>
                                    </RoundImageContainer>
                                </CustomContainer>
                                <CustomContainer bottomMargin="6">
                                    <ScreenTitle textSize="1.8" bottomMargin="1">Bio Details</ScreenTitle>
                                    <FormGroupsWrapper className="full-column-flex">
                                        <FormGroup width="48.5" rightMargin="3" bottomMargin="1" topMargin="2" className="full-width-flex">
                                            <label>First Name</label>
                                            <input 
                                                type="text" 
                                                name="firstName" 
                                                value={formattedUserData?.bioDetail?.firstName || 'nill'}
                                                disabled
                                            />
                                        </FormGroup>
                                        <FormGroup width="48.5" bottomMargin="1" topMargin="2" className="full-width-flex">
                                            <label>Last Name</label>
                                            <input 
                                                type="text"  
                                                name="lastName" 
                                                value={formattedUserData?.bioDetail?.lastName || 'nill'}
                                                disabled
                                            />
                                        </FormGroup>
                                    </FormGroupsWrapper>
                                    <FormGroupsWrapper className="full-column-flex">
                                        <FormGroup width="48.5" rightMargin="3" bottomMargin="1" topMargin="2" className="full-width-flex">
                                            <label>Gender</label>
                                            <input 
                                                type="text"  
                                                name="gender" 
                                                value={formattedUserData?.bioDetail?.gender || 'nill'}
                                                disabled
                                            />
                                        </FormGroup>
                                        <FormGroup width="48.5" bottomMargin="1" topMargin="2" className="full-width-flex">
                                            <label>Date of Birth</label>
                                            <input 
                                                type="text" 
                                                name="dateOfBirth" 
                                                value={formattedUserData?.bioDetail?.dateOfBirth || 'nill'} 
                                                disabled
                                            />
                                        </FormGroup>
                                    </FormGroupsWrapper>
                                    <FormGroupsWrapper className="full-column-flex">
                                        <FormGroup width="48.5" rightMargin="3" bottomMargin="1" topMargin="2" className="full-width-flex">
                                            <label>Phone Number</label>
                                            <input 
                                                type="text" 
                                                name="phoneNumber" 
                                                value={formattedUserData?.bioDetail?.phoneNumber || 'nill'}
                                                disabled
                                            />
                                        </FormGroup>
                                        <FormGroup width="48.5" bottomMargin="1" topMargin="2" className="full-width-flex">
                                            <label>Email Address</label>
                                            <input 
                                                type="email" 
                                                name="email" 
                                                value={formattedUserData?.bioDetail?.email || 'nill'}
                                                disabled
                                            />
                                        </FormGroup>
                                    </FormGroupsWrapper>
                                </CustomContainer>
                                <CustomContainer bottomMargin="6">
                                    <ScreenTitle textSize="1.8" bottomMargin="1">Contact Address</ScreenTitle>
                                    <FormGroupsWrapper className="full-column-flex">
                                        <FormGroup width="48.5" rightMargin="3" bottomMargin="1" topMargin="2" className="full-width-flex">
                                            <label>Residence No</label>
                                            <input 
                                                type="text" 
                                                name="residenceNo" 
                                                value={formattedUserData?.contactAddress?.residenceNo || 'nill'}
                                                disabled 
                                            />
                                        </FormGroup>
                                        <FormGroup width="48.5" bottomMargin="1" topMargin="2" className="full-width-flex">
                                            <label>Apartment No</label>
                                            <input 
                                                type="text" 
                                                name="apartmentNo" 
                                                value={formattedUserData?.contactAddress?.apartmentNo || 'nill'}
                                                disabled
                                            />
                                        </FormGroup>
                                    </FormGroupsWrapper>
                                    <FormGroup bottomMargin="1" topMargin="2">
                                        <label>Area or Road Name</label>
                                        <input 
                                            type="text" 
                                            name="streetName" 
                                            value={formattedUserData?.contactAddress?.streetName || 'nill'}
                                            disabled 
                                        />
                                    </FormGroup>
                                    <FormGroup bottomMargin="1" topMargin="2">
                                        <input 
                                            type="text" 
                                            name="busStop" 
                                            value={formattedUserData?.contactAddress?.landMark || 'nill'}
                                            disabled
                                        />
                                    </FormGroup>
                                    <FormGroupsWrapper className="full-column-flex">
                                        <FormGroup width="48.5" rightMargin="3" bottomMargin="1" topMargin="2" className="full-width-flex">
                                            <label>State</label>
                                            <input 
                                                type="text" 
                                                name="state" 
                                                value={formattedUserData?.contactAddress?.state || 'nill'}
                                                disabled
                                            />
                                        </FormGroup>
                                        <FormGroup width="48.5" bottomMargin="1" topMargin="2" className="full-width-flex">
                                            <label>L.G.A</label>
                                            <input 
                                                type="text" 
                                                name="lga" 
                                                value={formattedUserData?.contactAddress?.lga || 'nill'}
                                                disabled
                                            />
                                        </FormGroup>
                                    </FormGroupsWrapper>
                                </CustomContainer>
                                <CustomContainer bottomMargin='4'>
                                    <ScreenTitle textSize="1.8" bottomMargin="1">Identification</ScreenTitle>
                                    <FlexRow justifyContent='flex-start' wrap='wrap'>
                                        <CustomContainer rightMargin='2.5' bottomMargin='1' radius='0.5' className='setting-id-card'>
                                            <img 
                                                src={formattedUserData?.bioDetail?.idCard} 
                                                style={{width: '100%', height: '100%'}}
                                            />
                                        </CustomContainer>
                                        <CustomContainer width='32'>
                                            <FormGroup bottomMargin="1">
                                                <label>ID Number</label>
                                                <input 
                                                    type="text" 
                                                    name="idNumber" 
                                                    value={formattedUserData?.identification?.number || 'nill'}
                                                    disabled
                                                />
                                            </FormGroup>
                                            <FormGroupsWrapper className="full-column-flex">
                                                <FormGroup width="48.5" rightMargin="3" bottomMargin="1" topMargin="1" className="full-width-flex">
                                                    <label>Issue Date</label>
                                                    <input 
                                                        type="text" 
                                                        name="issueDate" 
                                                        value={formattedUserData?.identification?.issueDate || 'nill'} 
                                                        disabled
                                                    />
                                                </FormGroup>
                                                <FormGroup width="48.5" bottomMargin="1" topMargin="1" className="full-width-flex">
                                                    <label>Expiry Date</label>
                                                    <input 
                                                        type="text" 
                                                        name="expiryDate" 
                                                        value={formattedUserData?.identification?.expiryDate || 'nill'} 
                                                        disabled 
                                                    />
                                                </FormGroup>
                                            </FormGroupsWrapper>
                                        </CustomContainer>
                                    </FlexRow>
                                </CustomContainer>
                                <CustomContainer bottomMargin='2'>
                                    <ScreenTitle textSize="1.8" bottomMargin="1">
                                        Signature
                                    </ScreenTitle>
                                    <FlexColumn 
                                        rightMargin='2.5' bottomMargin='1' bgColor='#ffffff'
                                        className='setting-id-card setting-signature'
                                    >
                                        <img 
                                            src={signature || formattedUserData?.bioDetail?.signature} 
                                            style={{maxWidth: '100%', maxHeight: '100%'}}
                                        />
                                    </FlexColumn>
                                    {/* { !formattedUserData?.bioDetail?.signature &&
                                        <AppLabel 
                                            textSize='1.4' hoverColor='#FED0B7' 
                                            htmlFor='signature' 
                                            className='account-new-sigature'
                                        >
                                            Upload signature
                                            <input type="file" onChange={(e) => handleUpload(e, 'signature')} className="idcard-input" id="signature" />
                                        </AppLabel>
                                    } */}
                                </CustomContainer>
                            </CustomContainer>
                        }

                        { tab === "next-of-kin" &&
                            <CustomContainer>
                                <CustomContainer bottomMargin="6">
                                    <ScreenTitle textSize="1.8" bottomMargin="1">Next Of Kin Details</ScreenTitle>
                                    <FormGroupsWrapper className="full-column-flex">
                                        <FormGroup width="48.5" rightMargin="3" bottomMargin="1" topMargin="2" className="full-width-flex">
                                            <label>First Name</label>
                                            <input 
                                                type="text" 
                                                name="firstName" 
                                                value={input.firstName}
                                                onChange={handleInput} 
                                                placeholder="Enter first name" 
                                            />
                                        </FormGroup>
                                        <FormGroup width="48.5" bottomMargin="1" topMargin="2" className="full-width-flex">
                                            <label>Last Name</label>
                                            <input 
                                                type="text" 
                                                placeholder="Enter last name" 
                                                name="lastName" 
                                                value={input.lastName}
                                                onChange={handleInput} 
                                            />
                                        </FormGroup>
                                    </FormGroupsWrapper>
                                    <FormGroupsWrapper className="full-column-flex">
                                        <FormGroupWithIcon width="48.5" rightMargin="3" bottomMargin="1" topMargin="2" className="full-width-flex">
                                            <label>Gender</label>
                                            <div className="input-with-icon">
                                                <select 
                                                    name="gender" value={input.gender} 
                                                    onChange={handleInput}
                                                    style={{color: input.gender ? '#fff' : '#999'}}
                                                >
                                                <option value="">Choose gender</option>
                                                    <option value="Male">Male</option>
                                                    <option value="Female">Female</option>
                                                </select>
                                                <GoChevronDown className="input-with-icon-tag" />
                                            </div>
                                        </FormGroupWithIcon>
                                        <FormGroup width="48.5" bottomMargin="1" topMargin="2" className="full-width-flex">
                                            <label>Date of Birth</label>
                                            <input 
                                                type="date" 
                                                name="dateOfBirth" 
                                                value={input.dateOfBirth} 
                                                style={{color: input.dateOfBirth ? '#fff' : '#999'}}
                                                onChange={handleInput} 
                                            />
                                        </FormGroup>
                                    </FormGroupsWrapper>
                                    <FormGroupsWrapper className="full-column-flex">
                                        <FormGroup width="48.5" rightMargin="3" bottomMargin="1" topMargin="2" className="full-width-flex">
                                            <label>Phone Number</label>
                                            <input 
                                                type="number" 
                                                name="phoneNumber" 
                                                value={input.phoneNumber}
                                                onChange={handleInput} 
                                                placeholder="Enter Phone no" 
                                            />
                                        </FormGroup>
                                        <FormGroup width="48.5" bottomMargin="1" topMargin="2" className="full-width-flex">
                                            <label>Email Address</label>
                                            <input 
                                                type="email" 
                                                placeholder="Enter email" 
                                                name="email" 
                                                value={input.email}
                                                onChange={handleInput} 
                                            />
                                        </FormGroup>
                                    </FormGroupsWrapper>
                                    <FormGroupsWrapper className="full-column-flex">
                                        <FormGroupWithIcon width="48.5" rightMargin="3" bottomMargin="1" topMargin="2" className="full-width-flex">
                                            <label>Relationship</label>
                                            <div className="input-with-icon">
                                                <select 
                                                    name="relationship" 
                                                    value={input.relationship} 
                                                    onChange={handleInput}
                                                    style={{color: input.relationship ? '#fff' : '#999'}}
                                                >
                                                    <option value="">Select relationship</option>
                                                    <option value="Mother">Mother</option>
                                                    <option value="Father">Father</option>
                                                    <option value="Sibling">Sibling</option>
                                                    <option value="Wife">Wife</option>
                                                    <option value="Husband">Husband</option>
                                                    <option value="Others">Others</option>
                                                </select>
                                                <GoChevronDown className="input-with-icon-tag" />
                                            </div>
                                        </FormGroupWithIcon>
                                    </FormGroupsWrapper>
                                </CustomContainer>
                                <CustomContainer>
                                    <ScreenTitle textSize="1.8" bottomMargin="1">Contact Address</ScreenTitle>
                                    <FormGroupsWrapper className="full-column-flex">
                                        <FormGroup width="48.5" rightMargin="3" bottomMargin="1" topMargin="2" className="full-width-flex">
                                            <label>Residence No</label>
                                            <input 
                                                type="text" 
                                                name="residenceNo" 
                                                value={input.residenceNo}
                                                onChange={handleInput} 
                                                placeholder="Enter residence no." 
                                            />
                                        </FormGroup>
                                        <FormGroup width="48.5" bottomMargin="1" topMargin="2" className="full-width-flex">
                                            <label>Apartment No</label>
                                            <input 
                                                type="text" 
                                                placeholder="Enter apartment no." 
                                                name="apartmentNo" 
                                                value={input.apartmentNo}
                                                onChange={handleInput} 
                                            />
                                        </FormGroup>
                                    </FormGroupsWrapper>
                                    <FormGroup bottomMargin="1" topMargin="2">
                                        <label>Area or Road Name</label>
                                        <input 
                                            type="text" 
                                            name="streetName" 
                                            value={input.streetName}
                                            onChange={handleInput} 
                                            placeholder="Street name" 
                                        />
                                    </FormGroup>
                                    <FormGroupWithIcon bottomMargin="1" topMargin="2">
                                        <div className="input-with-icon">
                                            <select 
                                                name="landMark" 
                                                value={input.landMark} 
                                                style={{color: input.landMark ? '#fff' : '#999'}}
                                                onChange={handleInput}
                                            >
                                                <option value="">Nearest bus stop/landmark</option>
                                                {
                                                    residenceCategories?.map((item: any, index: number) => {
                                                        return (
                                                            <option selected={input.landMark === item.area} value={item.area} key={index}>{item.area}</option>
                                                        )
                                                    })
                                                }
                                            </select>
                                            <GoChevronDown className="input-with-icon-tag" />
                                        </div>
                                    </FormGroupWithIcon>
                                    <FormGroupsWrapper className="full-column-flex">
                                        <FormGroupWithIcon width="48.5" rightMargin="3" bottomMargin="1" topMargin="2" className="full-width-flex">
                                            <label>State</label>
                                            <div className="input-with-icon">
                                                <select 
                                                    name="state" onChange={handleInput}
                                                    style={{color: input.state ? '#fff' : '#999'}}
                                                >
                                                    <option value="">Select state</option>
                                                    {
                                                        states?.map((item: any, index: number) => {
                                                            return (
                                                                <option selected={input.state === item.state} value={index} key={index}>{item.state}</option>
                                                            )
                                                        })
                                                    }
                                                </select>
                                                <GoChevronDown className="input-with-icon-tag" />
                                            </div>
                                        </FormGroupWithIcon>
                                        <FormGroupWithIcon width="48.5" bottomMargin="1" topMargin="2" className="full-width-flex">
                                            <label>L.G.A</label>
                                            <div className="input-with-icon">
                                                <select 
                                                    name="lga" value={input.lga} 
                                                    onChange={handleInput}
                                                    style={{color: input.lga ? '#fff' : '#999'}}
                                                >
                                                    <option value="">Select LGA</option>
                                                    {
                                                        lgaList.map((item: any, index: number) => {
                                                            return (
                                                                <option selected={input.lga === item} value={item} key={index}>{item}</option>
                                                            )
                                                        })
                                                    }
                                                </select>
                                                <GoChevronDown className="input-with-icon-tag" />
                                            </div>
                                        </FormGroupWithIcon>
                                    </FormGroupsWrapper>
                                </CustomContainer>
                                <Button
                                    width="20"
                                    height="4.5"
                                    titleSize="1.4"
                                    topMargin='3'
                                    hoverBgColor="#FED0B7"
                                    className="loan-action-btn"
                                    disabled={!isValidated ? true : false}
                                    onClick={handleNextOfKinUpdate}
                                >
                                    Update Details
                                </Button>
                            </CustomContainer>
                        }

                        { tab === "security" &&
                            <CustomContainer >
                                { !pinChangeType &&
                                    <>
                                        <CustomContainer width="auto" sizeUnit="" bottomMargin="7">
                                            <ScreenTitle textSize="1.8" bottomMargin="2">Passcode</ScreenTitle>
                                            <FlexRow justifyContent="flex-start" bottomMargin="5">
                                                <FlexRow 
                                                    justifyContent="flex-start" width="auto" sizeUnit="" rightMargin="1.7" 
                                                    className="acct-passcode-wrapper" onClick={() => setPinChangeType('passcode')}
                                                >
                                                    <label className="app-passCode" >*</label>
                                                    <label className="app-passCode" >*</label>
                                                    <label className="app-passCode" >*</label>
                                                    <label className="app-passCode" >*</label>
                                                    <label className="app-passCode" >*</label>
                                                    <label className="app-passCode" >*</label>
                                                </FlexRow>
                                                {/* <BsFillEyeFill className="settings-icon" /> */}
                                            </FlexRow>
                                            <a className="change-code" onClick={() => setPinChangeType('passcode')}>Change Passcode</a>
                                        </CustomContainer>
                                        <CustomContainer width="auto" sizeUnit="">
                                            <ScreenTitle textSize="1.8" bottomMargin="2">Transaction PIN</ScreenTitle>
                                            <FlexRow justifyContent="flex-start" bottomMargin="5">
                                                <FlexRow 
                                                    justifyContent="flex-start" width="auto" sizeUnit="" rightMargin="1.7"
                                                    className="acct-passcode-wrapper" onClick={() => setPinChangeType('trans-pin')}
                                                >
                                                    <label className="app-passCode" >*</label>
                                                    <label className="app-passCode" >*</label>
                                                    <label className="app-passCode" >*</label>
                                                    <label className="app-passCode" >*</label>
                                                </FlexRow>
                                                {/* <BsFillEyeFill className="settings-icon" /> */}
                                            </FlexRow>
                                            <a className="change-code" onClick={() => setPinChangeType('trans-pin')}>Change PIN</a>
                                        </CustomContainer>
                                    </>
                                }
                                { pinChangeType === 'passcode' &&
                                    <CustomContainer width="auto" sizeUnit="">
                                        <ScreenTitle textSize="1.8" bottomMargin="3">Passcode</ScreenTitle>
                                        <CustomContainer bottomMargin="5">
                                            <AppText fontWeight="500" textSize="1.4" bottomMargin="1">Old Passcode</AppText>
                                            <FlexRow justifyContent="flex-start">
                                                <FlexRow 
                                                    justifyContent="flex-start" width="auto" sizeUnit="" rightMargin="1.7"
                                                    className="acct-passcode-wrapper"
                                                >   
                                                    <input 
                                                        className='display-hidden' 
                                                        id='pass' name="pass" type='number'
                                                        value={password.pass} autoFocus={true}
                                                        onKeyUp={handleTypeNumberControl}
                                                        onKeyDown={handleTypeNumberControl} 
                                                        onChange={handlePassword} autoComplete="off"
                                                    />
                                                    <label className={`app-passCode ${password.pass.length === 0 && 'active-pin-box'}`} htmlFor='pass' >{(password.pass[0] && isHidden.oPass) ? '*' : password.pass[0] ? password.pass[0] : ''}</label>
                                                    <label className={`app-passCode ${password.pass.length === 1 && 'active-pin-box'}`} htmlFor='pass' >{(password.pass[1] && isHidden.oPass) ? '*' : password.pass[1] ? password.pass[1] : ''}</label>
                                                    <label className={`app-passCode ${password.pass.length === 2 && 'active-pin-box'}`} htmlFor='pass' >{(password.pass[2] && isHidden.oPass) ? '*' : password.pass[2] ? password.pass[2] : ''}</label>
                                                    <label className={`app-passCode ${password.pass.length === 3 && 'active-pin-box'}`} htmlFor='pass' >{(password.pass[3] && isHidden.oPass) ? '*' : password.pass[3] ? password.pass[3] : ''}</label>
                                                    <label className={`app-passCode ${password.pass.length === 4 && 'active-pin-box'}`} htmlFor='pass' >{(password.pass[4] && isHidden.oPass) ? '*' : password.pass[4] ? password.pass[4] : ''}</label>
                                                    <label className={`app-passCode ${password.pass.length === 5 && 'active-pin-box'}`} htmlFor='pass' >{(password.pass[5] && isHidden.oPass) ? '*' : password.pass[5] ? password.pass[5] : ''}</label>
                                                </FlexRow>
                                                { isHidden.oPass ?
                                                    <BsFillEyeSlashFill className="settings-icon" onClick={() => setIsHidden({...isHidden, oPass: false})} />
                                                    : <BsFillEyeFill className="settings-icon" onClick={() => setIsHidden({...isHidden, oPass: true})} />
                                                }
                                            </FlexRow>
                                        </CustomContainer>
                                        <CustomContainer bottomMargin="5">
                                            <AppText fontWeight="500" textSize="1.4" bottomMargin="1">New Passcode</AppText>
                                            <FlexRow justifyContent="flex-start">
                                                <FlexRow 
                                                    justifyContent="flex-start" width="auto" sizeUnit="" rightMargin="1.7"
                                                    className="acct-passcode-wrapper"
                                                >
                                                    <input 
                                                        className='display-hidden' id='nPass' 
                                                        value={password.nPass} name="nPass" 
                                                        onChange={handlePassword}
                                                        onKeyUp={handleTypeNumberControl}
                                                        onKeyDown={handleTypeNumberControl} 
                                                        autoComplete="off"
                                                    />
                                                    <label className={`app-passCode ${password.nPass.length === 0 && 'active-pin-box'}`} htmlFor='nPass' >{(password.nPass[0] && isHidden.nPass) ? '*' : password.nPass[0] ? password.nPass[0] : ''}</label>
                                                    <label className={`app-passCode ${password.nPass.length === 1 && 'active-pin-box'}`} htmlFor='nPass' >{(password.nPass[1] && isHidden.nPass) ? '*' : password.nPass[1] ? password.nPass[1] : ''}</label>
                                                    <label className={`app-passCode ${password.nPass.length === 2 && 'active-pin-box'}`} htmlFor='nPass' >{(password.nPass[2] && isHidden.nPass) ? '*' : password.nPass[2] ? password.nPass[2] : ''}</label>
                                                    <label className={`app-passCode ${password.nPass.length === 3 && 'active-pin-box'}`} htmlFor='nPass' >{(password.nPass[3] && isHidden.nPass) ? '*' : password.nPass[3] ? password.nPass[3] : ''}</label>
                                                    <label className={`app-passCode ${password.nPass.length === 4 && 'active-pin-box'}`} htmlFor='nPass' >{(password.nPass[4] && isHidden.nPass) ? '*' : password.nPass[4] ? password.nPass[4] : ''}</label>
                                                    <label className={`app-passCode ${password.nPass.length === 5 && 'active-pin-box'}`} htmlFor='nPass' >{(password.nPass[5] && isHidden.nPass) ? '*' : password.nPass[5] ? password.nPass[5] : ''}</label>
                                                </FlexRow>
                                                { isHidden.nPass ?
                                                    <BsFillEyeSlashFill className="settings-icon" onClick={() => setIsHidden({...isHidden, nPass: false})} />
                                                    : <BsFillEyeFill className="settings-icon" onClick={() => setIsHidden({...isHidden, nPass: true})} />
                                                }
                                            </FlexRow>
                                        </CustomContainer>
                                        <CustomContainer bottomMargin="5">
                                            <AppText fontWeight="500" textSize="1.4" bottomMargin="1">Confirm New Passcode</AppText>
                                            <FlexRow justifyContent="flex-start">
                                                <FlexRow 
                                                    justifyContent="flex-start" width="auto" sizeUnit="" rightMargin="1.7"
                                                    className="acct-passcode-wrapper"
                                                >
                                                    <input 
                                                        className='display-hidden' id='cPass' 
                                                        name="cPass" type='number'
                                                        value={password.cPass} 
                                                        onChange={handlePassword}
                                                        onKeyUp={handleTypeNumberControl}
                                                        onKeyDown={handleTypeNumberControl}  
                                                        autoComplete="off"
                                                    />
                                                    <label className={`app-passCode ${password.cPass.length === 0 && 'active-pin-box'}`} htmlFor='cPass' >{(password.cPass[0] && isHidden.cPass) ? '*' : password.cPass[0] ? password.cPass[0] : ''}</label>
                                                    <label className={`app-passCode ${password.cPass.length === 1 && 'active-pin-box'}`} htmlFor='cPass' >{(password.cPass[1] && isHidden.cPass) ? '*' : password.cPass[1] ? password.cPass[1] : ''}</label>
                                                    <label className={`app-passCode ${password.cPass.length === 2 && 'active-pin-box'}`} htmlFor='cPass' >{(password.cPass[2] && isHidden.cPass) ? '*' : password.cPass[2] ? password.cPass[2] : ''}</label>
                                                    <label className={`app-passCode ${password.cPass.length === 3 && 'active-pin-box'}`} htmlFor='cPass' >{(password.cPass[3] && isHidden.cPass) ? '*' : password.cPass[3] ? password.cPass[3] : ''}</label>
                                                    <label className={`app-passCode ${password.cPass.length === 4 && 'active-pin-box'}`} htmlFor='cPass' >{(password.cPass[4] && isHidden.cPass) ? '*' : password.cPass[4] ? password.cPass[4] : ''}</label>
                                                    <label className={`app-passCode ${password.cPass.length === 5 && 'active-pin-box'}`} htmlFor='cPass' >{(password.cPass[5] && isHidden.cPass) ? '*' : password.cPass[5] ? password.cPass[5] : ''}</label>
                                                </FlexRow>
                                                { isHidden.cPass ?
                                                    <BsFillEyeSlashFill className="settings-icon" onClick={() => setIsHidden({...isHidden, cPass: false})} />
                                                    : <BsFillEyeFill className="settings-icon" onClick={() => setIsHidden({...isHidden, cPass: true})} />
                                                }
                                            </FlexRow>
                                        </CustomContainer>
                                        <FlexRow topMargin="3" justifyContent="flex-start" leftMargin="4">
                                            <BorderlessButton 
                                                titleSize="1.3" color="#fff" 
                                                hoverColor="#FED0B7"
                                                onClick={() => setPinChangeType('')}
                                            >
                                                Back
                                            </BorderlessButton>
                                            <Button
                                                width="20"
                                                height="4.5"
                                                titleSize="1.4"
                                                leftMargin="5"
                                                hoverBgColor="#FED0B7"
                                                className="loan-action-btn"
                                                disabled={(password.pass.length != 6 || password.nPass.length != 6 || password.cPass.length != 6) ? true : false}
                                                onClick={handlePasscodeChange}
                                            >
                                                Save Changes
                                            </Button>
                                        </FlexRow>
                                    </CustomContainer>
                                }

                                { pinChangeType === 'trans-pin' &&
                                    <CustomContainer width="auto" sizeUnit="">
                                        <ScreenTitle textSize="1.8" bottomMargin="3">Transaction PIN</ScreenTitle>
                                        <CustomContainer bottomMargin="5">
                                            <AppText fontWeight="500" textSize="1.4" bottomMargin="1">Old PIN</AppText>
                                            <FlexRow justifyContent="flex-start">
                                                <FlexRow 
                                                    justifyContent="flex-start" width="auto" sizeUnit="" rightMargin="1.7"
                                                    className="acct-passcode-wrapper"
                                                >
                                                    <input 
                                                        className='display-hidden' id='pin' 
                                                        name="pin" type='number'
                                                        value={pin.pin} 
                                                        onChange={handlePin}
                                                        onKeyUp={handleTypeNumberControl}
                                                        onKeyDown={handleTypeNumberControl}  
                                                        autoFocus={true} autoComplete="off"
                                                    />
                                                    <label className={`app-passCode ${pin.pin.length === 0 && 'active-pin-box'}`} htmlFor='pin' >{(pin.pin[0] && isHidden.oPin) ? '*' : pin.pin[0] ? pin.pin[0] : ''}</label>
                                                    <label className={`app-passCode ${pin.pin.length === 1 && 'active-pin-box'}`} htmlFor='pin' >{(pin.pin[1] && isHidden.oPin) ? '*' : pin.pin[1] ? pin.pin[1] : ''}</label>
                                                    <label className={`app-passCode ${pin.pin.length === 2 && 'active-pin-box'}`} htmlFor='pin' >{(pin.pin[2] && isHidden.oPin) ? '*' : pin.pin[2] ? pin.pin[2] : ''}</label>
                                                    <label className={`app-passCode ${pin.pin.length === 3 && 'active-pin-box'}`} htmlFor='pin' >{(pin.pin[3] && isHidden.oPin) ? '*' : pin.pin[3] ? pin.pin[3] : ''}</label>
                                                </FlexRow>
                                                { isHidden.oPin ?
                                                    <BsFillEyeSlashFill className="settings-icon" onClick={() => setIsHidden({...isHidden, oPin: false})} />
                                                    : <BsFillEyeFill className="settings-icon" onClick={() => setIsHidden({...isHidden, oPin: true})} />
                                                }
                                            </FlexRow>
                                        </CustomContainer>
                                        <CustomContainer bottomMargin="5">
                                            <AppText fontWeight="500" textSize="1.4" bottomMargin="1">New PIN</AppText>
                                            <FlexRow justifyContent="flex-start">
                                                <FlexRow 
                                                    justifyContent="flex-start" width="auto" sizeUnit="" rightMargin="1.7"
                                                    className="acct-passcode-wrapper"
                                                >
                                                    <input 
                                                        className='display-hidden' id='nPin' 
                                                        name="nPin" type='number'
                                                        value={pin.nPin} 
                                                        onChange={handlePin}
                                                        onKeyUp={handleTypeNumberControl}
                                                        onKeyDown={handleTypeNumberControl}  
                                                        autoComplete="off"
                                                    />
                                                    <label className={`app-passCode ${pin.nPin.length === 0 && 'active-pin-box'}`} htmlFor='nPin' >{(pin.nPin[0] && isHidden.nPin) ? '*' : pin.nPin[0] ? pin.nPin[0] : ''}</label>
                                                    <label className={`app-passCode ${pin.nPin.length === 1 && 'active-pin-box'}`} htmlFor='nPin' >{(pin.nPin[1] && isHidden.nPin) ? '*' : pin.nPin[1] ? pin.nPin[1] : ''}</label>
                                                    <label className={`app-passCode ${pin.nPin.length === 2 && 'active-pin-box'}`} htmlFor='nPin' >{(pin.nPin[2] && isHidden.nPin) ? '*' : pin.nPin[2] ? pin.nPin[2] : ''}</label>
                                                    <label className={`app-passCode ${pin.nPin.length === 3 && 'active-pin-box'}`} htmlFor='nPin' >{(pin.nPin[3] && isHidden.nPin) ? '*' : pin.nPin[3] ? pin.nPin[3] : ''}</label>
                                                </FlexRow>
                                                { isHidden.nPin ?
                                                    <BsFillEyeSlashFill className="settings-icon" onClick={() => setIsHidden({...isHidden, nPin: false})} />
                                                    : <BsFillEyeFill className="settings-icon" onClick={() => setIsHidden({...isHidden, nPin: true})} />
                                                }
                                            </FlexRow>
                                        </CustomContainer>
                                        <CustomContainer bottomMargin="5">
                                            <AppText fontWeight="500" textSize="1.4" bottomMargin="1">Confirm New PIN</AppText>
                                            <FlexRow justifyContent="flex-start">
                                                <FlexRow 
                                                    justifyContent="flex-start" width="auto" sizeUnit="" rightMargin="1.7"
                                                    className="acct-passcode-wrapper"
                                                >
                                                    <input 
                                                        className='display-hidden' id='cPin' 
                                                        name="cPin" type='number'
                                                        value={pin.cPin} 
                                                        onChange={handlePin}
                                                        onKeyUp={handleTypeNumberControl}
                                                        onKeyDown={handleTypeNumberControl}  
                                                        autoComplete="off"
                                                    />
                                                    <label className={`app-passCode ${pin.cPin.length === 0 && 'active-pin-box'}`} htmlFor='cPin' >{(pin.cPin[0] && isHidden.cPin) ? '*' : pin.cPin[0] ? pin.cPin[0] : ''}</label>
                                                    <label className={`app-passCode ${pin.cPin.length === 1 && 'active-pin-box'}`} htmlFor='cPin' >{(pin.cPin[1] && isHidden.cPin) ? '*' : pin.cPin[1] ? pin.cPin[1] : ''}</label>
                                                    <label className={`app-passCode ${pin.cPin.length === 2 && 'active-pin-box'}`} htmlFor='cPin' >{(pin.cPin[2] && isHidden.cPin) ? '*' : pin.cPin[2] ? pin.cPin[2] : ''}</label>
                                                    <label className={`app-passCode ${pin.cPin.length === 3 && 'active-pin-box'}`} htmlFor='cPin' >{(pin.cPin[3] && isHidden.cPin) ? '*' : pin.cPin[3] ? pin.cPin[3] : ''}</label>
                                                </FlexRow>
                                                { isHidden.cPin ?
                                                    <BsFillEyeSlashFill className="settings-icon" onClick={() => setIsHidden({...isHidden, cPin: false})} />
                                                    : <BsFillEyeFill className="settings-icon" onClick={() => setIsHidden({...isHidden, cPin: true})} />
                                                }
                                            </FlexRow>
                                        </CustomContainer>
                                        <FlexRow topMargin="3" justifyContent="flex-start" leftMargin="4">
                                            <BorderlessButton 
                                                titleSize="1.3" color="#fff" 
                                                hoverColor="#FED0B7"
                                                onClick={() => setPinChangeType('')}
                                            >
                                                Back
                                            </BorderlessButton>
                                            <Button
                                                width="20"
                                                height="4.5"
                                                titleSize="1.4"
                                                leftMargin="5"
                                                hoverBgColor="#FED0B7"
                                                className="loan-action-btn"
                                                disabled={(pin.pin.length != 4 || pin.nPin.length != 4 || pin.cPin.length != 4) ? true : false}
                                                onClick={handlePinChange}
                                            >
                                                Save Changes
                                            </Button>
                                        </FlexRow>
                                    </CustomContainer>
                                }
                            </CustomContainer>
                        }
                    </div>
                </div>
            </ScreenContainer>
        </>
    )
}

export default utility.routeData('settings/account', 'Account Settings', <Account/>)