import styled from 'styled-components'
import { StyleProps } from 'src/model'

export const ResponderContentWrapper = styled.div<StyleProps>`
    width: 36rem;
    display: flex;
    flex-direction: column;
    align-items: center;

    @media (max-width: 370px) {
        width: 27rem
    }

    @media (max-width: 330px) {
        width: 95%
    }
`
export const ResponderIconCircle = styled.div`
    display: grid;
    place-items: center;
    width: 9rem;
    height: 9rem;
    border-radius: 50%;
    border: 2px solid #6DE7B4;

    i {
        font-size: 3rem;
        color: #FED0B7;
    }
`
export const ResponderIconHolder = styled.span`
    border: 1px solid #6DE7B4;
    height: 5.7rem
`
export const ResponderTitle = styled.p`
    font-weight: 700;
    font-size: 2.8rem;
    color: #FED0B7;
    margin-top: 4rem;
    text-align: center;
`
export const ResponderMessage = styled.span<StyleProps>`
    font-size: 1.4rem;
    font-weight: ${({fontWeight}) => fontWeight || 400};
    text-align: center
`

export const MessageIcon = styled.img<StyleProps>`
    margin-top: ${({topMargin}) => topMargin || 7}rem;
    margin-bottom: ${({bottomMargin}) => bottomMargin || 5}rem;
`

export const ResponderContentPlus = styled.div<StyleProps>`
    width: 50rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content-center;

    @media (max-width: 490px) {
        width: 40rem
    }
    @media (max-width: 370px) {
        width: 30rem
    }

    @media (max-width: 330px) {
        width: 95%
    }
`