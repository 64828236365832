import { useState, useContext, useEffect } from 'react'
import ApiContext from 'src/provider/API/call-service'
import { BiReceipt } from 'react-icons/bi'
import UserContext from 'src/provider/state-manager/userProvider'
import { 
    FlexRow, 
    AppText,
    CustomContainer,
    Button,
    CenterPopupContainer,
    CenterAlignedPopup,
    ScreenTitle,
    AppSpan,
    SideBarSummary,
    DataWithLabel,
    Divider
} from "src/styles"
import utility from 'src/utils/utility'
import { FundPortfolio, SignOffer } from 'src/popup'
import helpers from 'src/utils/helpers'
import { offerLetterBaseUrl } from 'src/provider/config/constant'


export const Agreement: React.FC<any> = ({close, closeDetails, data, isLenderShowInterest}) => {
    const {API} = useContext(ApiContext)
    const {user: {userData}} = useContext(UserContext)
    const [charge, setCharge] = useState(0)
    const [showFundPortfolio, setShowFundPortfolio] = useState(false)
    const [showSignature, setShowSignature] = useState(false)

    useEffect(() => {
        handleGetCharge()
    }, [])

    let startDateTime = data?.loanOffer?.startDate ? 
        helpers.splitDateTime(data?.loanOffer?.startDate)
        : data?.startDate ? helpers.splitDateTime(data?.startDate)
        : null

    let endDateTime = data?.loanOffer?.endDate ? 
    helpers.splitDateTime(data?.loanOffer?.endDate)
    : data?.endDate ? helpers.splitDateTime(data?.endDate)
    : null

    async function handleGetCharge () {
        if (data) {
            const response = await API.getCharges(
                data?.loanOffer?.amountPerPerson || data?.amount,
                (data?.loanOffer?.interest || data?.loanOffer?.interest === 0) ? data?.loanOffer?.interest : data?.interest,
                'REPAYMENT',
                (data?.loanOffer?.interestAmount || data?.loanOffer?.interestAmount === 0) ? data?.loanOffer?.interestAmount : data?.interestAmount
            )
            if (response) setCharge(response)
        }
    }

    const downloadFile = () => {
        const downloadLink = document.createElement('a');
        document.body.appendChild(downloadLink);
        downloadLink.download = 'Offer-letter';
        downloadLink.href = `${offerLetterBaseUrl}/LoanOffer/DownloadLoanAgreementLetter/${data?.loanOfferId}/${data?.loanRequestId}?customerId=${userData.customerId}`;
        console.log({downloadLink: downloadLink.href})
        downloadLink.click();
    }

    async function handleIssueOffer () {
        setShowSignature(false)
        if (!isLenderShowInterest) {
            const response = await API.acceptLoanRequest(data?.loanOfferId, data?.loanRequestId) // TODO: remove getCurrenLoans method called inside acceptLoanRequest function
            if (response === 'fund portfolio') setShowFundPortfolio(true)
            else handleClose()
        }
        else handleLenderShowInterest()
    }

    // FOR LENDER SHOWING INTEREST IN LOAN REQUEST
    async function handleLenderShowInterest () {
        const response = await API.submitInterestInLoanRequest(data?.loanRequestId, data?.loanOfferId || data?.loanOffer?.loanOfferId)
        if (response === 'fund portfolio') setShowFundPortfolio(true)
        else handleClose()
    }

    //=================================================

    function handleClose () {
        API.runBackgroundService(['current-loan-update', 'charts-info-update'])
        close()
        if(closeDetails) closeDetails()
    }

    return (
        <>
            { !showFundPortfolio &&
                <CenterPopupContainer zIndex="8">
                    <CenterAlignedPopup data-aos="slide-right">
                        <CustomContainer bottomMargin="3.5">
                            <ScreenTitle align="center" textSize="2" className='agreement-title'>
                                Issue Loan Offer to Borrower
                            </ScreenTitle>
                            <i className="fa fa-times abs-right-center" onClick={close}></i>
                        </CustomContainer>
                        <FlexRow justifyContent="space-between" alignItems="flex-start" className="agreement-address-wrapper"> 
                            <AppText color="#E2E2E2" textSize="1.4" fontWeight="400">
                                Loan Offer Issued to: 
                                <AppSpan color="#fff" textSize="1.4" fontWeight="700" leftPadding="1"> {data?.firstName} {data?.lastName}</AppSpan>
                            </AppText>
                        </FlexRow>
                        <CustomContainer className='agreement-info-wrapper'>
                            <CustomContainer topMargin="2" bottomMargin="2">
                                <AppText textSize="1.4" color="#E2E2E2" bottomMargin='1'>Hi {data?.firstName},</AppText>
                                <AppText fontWeight="400" textSize="1.4" color="#E2E2E2">
                                    I refer to our prior correspondence on the subject and I am pleased
                                    to offer the below-mentioned loan offer to you under the terms 
                                    and conditions stated below.
                                </AppText>
                            </CustomContainer>
                            <SideBarSummary bgColor="#4700e0" topPadding="2" bottomPadding="2">
                                <FlexRow justifyContent="space-between" alignItems="flex-start" bottomMargin="2"> 
                                    <DataWithLabel 
                                        titleSize="1.25" 
                                        textSize="1.5" 
                                        fontWeight="700"
                                        align="left"
                                    >
                                        <label>Loan Amount</label>
                                        <span>&#8358;{utility.moneyFormat(data?.loanOffer?.amountPerPerson || data?.amount)}</span>
                                    </DataWithLabel>
                                    <DataWithLabel 
                                        titleSize="1.25" 
                                        textSize="1.5" 
                                        fontWeight="700"
                                        align="right"
                                    >
                                        <label>Tenor</label>
                                        <span>{(data?.loanOffer?.tenor || data?.loanOffer?.tenor === 0) ? data?.loanOffer?.tenor : data?.tenor} {data?.loanOffer?.tenorType?.toLowerCase() || data?.tenorType?.toLowerCase()}(s)</span>
                                    </DataWithLabel>
                                </FlexRow>
                                <FlexRow justifyContent="space-between" alignItems="flex-start" bottomMargin="2"> 
                                    <DataWithLabel 
                                        titleSize="1.25" 
                                        textSize="1.5" 
                                        className="data-with-label"
                                        fontWeight="700"
                                        align="left"
                                    >
                                        <label>Total repayment</label>
                                        <span>&#8358;{utility.moneyFormat((data?.loanOffer?.totalRepaymentBorrower || data?.loanOffer?.totalRepaymentBorrower === 0) ? data?.loanOffer?.totalRepaymentBorrower : data?.totalAmountToBeRepaid)}</span>
                                    </DataWithLabel>
                                    <DataWithLabel 
                                        titleSize="1.25" 
                                        textSize="1.5" 
                                        className="data-with-label"
                                        fontWeight="700"
                                        align="right"
                                    >
                                        <label>Interest rate</label>
                                        {/* <span>{(data?.loanOffer?.interest || data?.loanOffer?.interest === 0) ? data?.loanOffer?.interest : data?.interest}% {data?.loanOffer?.repaymentTermName || data?.repaymentTermName}</span> */}
                                        <span>{(data?.loanOffer?.interest || data?.loanOffer?.interest === 0) ? data?.loanOffer?.interest : data?.interest}% Monthly</span>
                                    </DataWithLabel>
                                </FlexRow>
                                <FlexRow justifyContent="space-between" alignItems="flex-start"> 
                                    <DataWithLabel 
                                        titleSize="1.25" 
                                        textSize="1.5" 
                                        titleColor="#E2E2E2"
                                        className="data-with-label"
                                        fontWeight="700"
                                        align="left"
                                    >
                                        <label>{data?.loanOffer?.repaymentTermName || data?.repaymentTermName} Repayment</label>
                                        <span>&#8358;{utility.moneyFormat((data?.loanOffer?.repaymentPerTenorBorrower || data?.loanOffer?.repaymentPerTenorBorrower === 0) ? data?.loanOffer?.repaymentPerTenorBorrower : data?.totalAmountPerTenorType)}</span>
                                    </DataWithLabel>
                                    <DataWithLabel 
                                        titleSize="1.25" 
                                        textSize="1.5" 
                                        color="#fff"
                                        className="data-with-label"
                                        fontWeight="700"
                                        align="right"
                                    >
                                        <label>Repayment</label>
                                        <span>{(data?.loanOffer?.rateId || data?.rateId) == 1 ? 'Installment' : 'One-off'} ({data?.loanOffer?.repaymentTermName || data?.repaymentTermName})</span>
                                    </DataWithLabel>
                                </FlexRow>
                                { (data?.loanOffer?.startDate || data?.startDate || data?.loanOffer?.endDate || data?.endDate) &&
                                    <FlexRow justifyContent="space-between" alignItems="flex-start"> 
                                        <DataWithLabel 
                                            titleSize="1.25" 
                                            textSize="1.5" 
                                            titleColor="#E2E2E2"
                                            className="data-with-label"
                                            fontWeight="700"
                                            align="left"
                                        >
                                            <label>Start Date</label>
                                            <span>{startDateTime?.date} {startDateTime?.completeTime}</span>
                                        </DataWithLabel>
                                        <DataWithLabel 
                                            titleSize="1.25" 
                                            textSize="1.5" 
                                            color="#fff"
                                            className="data-with-label"
                                            fontWeight="700"
                                            align="right"
                                        >
                                            <label>End Date</label>
                                            <span>{endDateTime?.date} {endDateTime?.completeTime}</span>
                                        </DataWithLabel>
                                    </FlexRow>
                                }
                                <Divider margin='1' bgColor='rgba(255,255,255,0.2)' />
                                <AppText color='#E2E2E2' textSize='1.25' fontWeight='400'>
                                    Crendly Charges:
                                    <AppSpan textSize='1.5'> &#8358;{charge}</AppSpan>
                                </AppText>
                            </SideBarSummary>

                            <CustomContainer>
                                <AppText textSize="1.4" fontWeight="700" topMargin="2">
                                    Conditions Precedent to Drawdown
                                </AppText>
                                <ol className="condition-list-wrapper">
                                    <li className="condition-list">
                                        <AppText color="#E2E2E2" leftMargin="1.2" textSize="1.4" fontWeight="400">
                                            Acceptance of loan offer from the Lender
                                        </AppText>
                                    </li>
                                    <li className="condition-list">
                                        <AppText color="#E2E2E2" leftMargin="1.2" textSize="1.4" fontWeight="400">
                                            Receipt of the Offer Letter duly signed by the Borrower
                                        </AppText>
                                    </li>
                                    <li className="condition-list">
                                        <AppText color="#E2E2E2" leftMargin="1.2" textSize="1.4" fontWeight="400">
                                            Receipt of the Loan Agreement duly signed by the Borrower
                                        </AppText>
                                    </li>
                                    <li className="condition-list">
                                        <AppText color="#E2E2E2" leftMargin="1.2" textSize="1.4" fontWeight="400">
                                            Payment of the upfront fee and any other applicable fee
                                        </AppText>
                                    </li>
                                </ol>
                            </CustomContainer>
                            <AppText fontWeight="400" textSize="1.4" color="#E2E2E2">
                                The above summary terms and conditions are preliminary and are subject to change
                                and do not purport to summarize all of the terms and conditions contained in definitive legal documentation
                                for this loan facility contemplated hereby. <br/>
                                Please indicate your acceptance of the above facility and the terms and conditions by signing this letter
                                within 3 (three) days from the date of this letter, failing which the offer shall lapse
                                without any liability or commitment on the part of the Lender.
                            </AppText>
                        </CustomContainer>
                        
                        <FlexRow 
                            topMargin="3" 
                            justifyContent={isLenderShowInterest ? "flex-end" : "space-between"} 
                            className="offer-acceptance-action">
                            { !isLenderShowInterest &&
                                <button className="borderless-button-plus agreement-terms-btn" onClick={downloadFile}>
                                    <BiReceipt className="borderless-button-icon" />
                                    <span>View Full Contract</span>
                                </button>
                            }
                            <FlexRow justifyContent="flex-end" width="auto" sizeUnit="" className="loan-acceptance-btns agreement-issue-offer-btn">
                                <Button 
                                    width="17" 
                                    height="4"
                                    hoverBgColor="#FED0B7"
                                    leftMargin="3.5"
                                    onClick={() => setShowSignature(true)}
                                >
                                    Issue Offer
                                </Button>
                            </FlexRow>
                        </FlexRow>
                    </CenterAlignedPopup>
                </CenterPopupContainer> 
            }
            
            { showFundPortfolio &&
                <FundPortfolio 
                    close={() => setShowFundPortfolio(false)}
                    portfolioId={data?.loanOfferId}
                    portfolioAmount={data?.loanOffer?.amount || data.amount}
                    //onCompleteMethod={isLenderShowInterest ? handleLenderShowInterest : null}
                />
            }

            { showSignature &&
                <SignOffer 
                    isLender={true}
                    actionMethod={handleIssueOffer}
                    close={() => setShowSignature(false)}
                />
            }
        </>
    )
}
