import React, { useContext } from 'react'
import utility, { catchErrMsg, erroMessage, loglite, requestMessage } from 'src/utils/utility'
import identityCaller from '../interceptor/identity'
import axios from 'axios'
import authCaller from '../interceptor/auth'
import profileCaller from '../interceptor/profile'
import loanCaller from '../interceptor/loan'
import loanRepaymentCaller from '../interceptor/loanRepayment'
import recommendationCaller from '../interceptor/recommendation'
import decisionCaller from '../interceptor/decision'
import walletCaller from '../interceptor/wallet'
import notificationCaller from '../interceptor/notification'
import ratingCaller from '../interceptor/rating'
import chargesCaller from '../interceptor/charges'
import authAdminCaller from '../interceptor/authAdmin'
import VisibilityContext from 'src/provider/state-manager/visibilityProvider'
import AppInfoContext from 'src/provider/state-manager/appInfoProvider'
import UserContext from 'src/provider/state-manager/userProvider'
import { useGAEventTracker } from 'src/hooks'
import LoanContext from 'src/provider/state-manager/loanProvider'
import access from 'src/utils/localAccess'
import helpers from 'src/utils/helpers'
import * as device from 'react-device-detect';
import { appSettings } from 'src/provider/config/constant'
import { IBankAccounts } from '@src/model'


const ApiContext = React.createContext<any>(null)

export const ApiProvider = (props: any) => {
    const {responder, loader, notifier, waitingScreen, visibility: {processingScreen}} = useContext(VisibilityContext)
    const {setInfoProperty, info, logout} = useContext(AppInfoContext)
    const {setUserProperty, user} = useContext(UserContext)
    const {loan, setLoanProperty} = useContext(LoanContext)
    const GAEventTracker = useGAEventTracker()

    const channelId = 'Crendly'
    const channelType = 'Web'

    async function exploreResponse (message?: string, icon?: string) {
        responder.show(
            '', 
            message || 'Hello. We see you are trying to get a loan. Why don’t you sign up to complete your loan process.',
            icon || 'explore',
            'Sign up',
            '/onboarding/type'
        )
        return null
    }

    async function getDeviceInfo () {
        try {
            const response = await axios.get('https://geolocation-db.com/json/')
            if (!response.data.country_code) notifier.show('location info could not be retrieved.', 'warning')
            const details = {
                browser: device.browserName,
                browserVersion: device.fullBrowserVersion,
                engineName: device.engineName,
                deviceType: device.isDesktop ? 'Desktop' : 'Mobile',
                getUA: device.getUA,
                deviceModel: device.isMobile ? (utility.getPhoneModel(device.mobileModel) || device.mobileModel) : 'Desktop/Laptop',
                deviceVendor: device.mobileVendor,
                OS: device.osName,
                osVersion: device.osVersion,
                deviceCountry: response.data?.country_name,
                deviceState: response.data?.state,
                latitude: Number(response.data?.latitude),
                longitude: Number(response.data?.longitude),
                ipAddress: response.data?.IPv4
            }
            console.log({details})

            // alternative to get location cords
            if (navigator?.geolocation) {
                navigator.geolocation?.getCurrentPosition((pos) => {
                    console.log({pos})
                    details.latitude = Number(pos?.coords?.latitude)
                    details.longitude = Number(pos?.coords?.longitude)
                }, (error: any) => {
                    //notifier.show('Location is not enabled', 'warning')
                }, 
                {enableHighAccuracy: true, timeout: 5000, maximumAge: 0}
                )
            }
            //==========================================

            await setInfoProperty('deviceInfo', details)
        }
        catch (err) {
            //notifier.show(erroMessage(catchErrMsg(err)), 'warning')
        }
    }

    async function signUp (payload: any) {
        try {
            loader(true)
            await getDeviceInfo()
            const response = await authCaller.post('platform/signupv2', {...payload, channelId, channelType})
            loader(false)

            console.log({response: response.data})
            if (!response.data.status) {
                notifier.show(requestMessage(response.data))
                GAEventTracker('Failed BVN Verification', `User: ${payload.email}`, 'Onboarding')
                return null
            }
            else {
                setInfoProperty('token', response.data.data.accessToken)
                setUserProperty('bvnData', response.data.data.bvnData)
                await setUserProperty('userData', {userId: response.data?.data.userId, isBvnVerified: response.data.data.isBvnVerified, customerId: response.data.data.customerId})
                setInfoProperty('onBoardingData', {...info.onBoardingData, ...payload})
                GAEventTracker('Successfull BVN Verification', `User: ${payload.email}`, 'Onboarding')
                return response.data.data
            }
        }
        catch (err: any) {
            loader(false) 
            console.log({err})
            if (err.response?.status === 400 && err.response?.data?.data?.hasNotCompletedOnboarding) {
                setUserProperty('userData', {userId: err.response?.data?.data.userId, id: err.response?.data?.data.userId})
                setInfoProperty('token', err?.response?.data?.data?.accessToken)
                setUserProperty('bvnData', err.response?.data?.data?.bvnData)
                setInfoProperty('onBoardingData', {...info.onBoardingData, ...payload})
                GAEventTracker('Continue Signup as Existing User', `User: ${payload.email}`, 'Onboarding')
                let resp = err.response?.data?.data
                resp.exist = true
                return resp
            }
            else notifier.show(erroMessage(catchErrMsg(err)))
            return null
        }
    }

    async function signIn (payload: any) {
        try {
            loader(true)
            const response = await authCaller.post('login', {...payload, channelId})
            loader(false)

            console.log({response: response.data})
            if (!response.data.status) {
                notifier.show(requestMessage(response.data))
                GAEventTracker('Failed Login', `User: ${payload.email}`, 'Login')
                return null
            }
            else {
                GAEventTracker('Successful Login', `User: ${response.data?.data.user.id}`, 'Login')
                getDeviceInfo()
                await localStorage.setItem('hasLogin', response.data.data.access_token)
                await setInfoProperty('token', response.data.data.access_token)
                await setInfoProperty('refreshToken', response.data.data.refresh_token)

                await setUserProperty('userData', { userId: response.data?.data.user.id, lastLoginDate: response.data.data?.lastLoginDate, ...response.data.data.user})
                return response.data.status
            }
        }
        catch (err: any) {
            loader(false) 
            console.log({err})
            notifier.show(erroMessage(catchErrMsg(err)))
            GAEventTracker('Failed Login', `User: ${payload.email}`, 'Login')
            return null
        }
    }
    // axios(url, body, queryParams)
    async function updateLastPageVisited (route: any) {
        const userId = user.userData?.userId || user.userData?.id
        if (!userId) return false

        try {
            loader(true)
            const response = await authCaller.post(`Update-LastPageVisited/${userId}`, {url: route})
            loader(false)

            console.log({response: response.data})
            if (response.status != 200 && response.status != 201) {
                notifier.show(requestMessage(response.data))
                return null
            }
            else {
                return response.data
            }
        }
        catch (err: any) {
            loader(false)
            notifier.show(erroMessage(catchErrMsg(err)))
            return null
        }
    }

    async function sendOtp_Inactivity (otpOption: string) {
        try {
            loader(true)
            const response = await authCaller.get(`Initiate-Verification/${user.userData?.userId || user.userData?.id}/${otpOption}`)
            loader(false)

            console.log({response: response.data})
            if (response.status != 200 && response.status != 201) {
                notifier.show(requestMessage(response.data))
                return null
            }
            else {
                notifier.show(requestMessage(response.data, 'Otp was successfully sent'), 'success')
                return response.data
            }
        }
        catch (err: any) {
            loader(false)
            notifier.show(erroMessage(catchErrMsg(err)))
            return null
        }
    }

    async function completeVerification_Inactivity (otp: string) {
        try {
            loader(true)
            const response = await authCaller.get(`complete-Verification/${user.userData?.userId || user.userData?.id}/${otp}`)
            loader(false)

            console.log({response: response.data})
            if (response.status != 200 && response.status != 201) {
                notifier.show(requestMessage(response.data))
                return null
            }
            else {
                const info = response.data.data
                const {firstName, lastName, phoneNumber, email, lastPageVisted, gender, customerId} = info
                setInfoProperty('token', info.accessToken)
                setUserProperty('userData', { ...user.userData, firstName, lastName, phoneNumber, email, lastPageVisted, gender, customerId})
                return info
            }
        }
        catch (err: any) {
            loader(false)
            notifier.show(erroMessage(catchErrMsg(err)))
            return null
        }
    }

    async function resendOtp (payload: any) {
        try {
            loader(true)
            const response = await authCaller.post('ResendVerificationOtp', {...payload, channelId})
            loader(false)
            notifier.show(requestMessage(response.data), 'success')
            setInfoProperty('onBoardingData', {...info.onBoardingData, resendOtpType: 'phoneNo'})
            return true
        }
        catch (err: any) {
            loader(false)
            notifier.show(erroMessage(catchErrMsg(err)))
            return null
        }
    }

    async function resendOtpViaEmail (bvn: string) {
        try {
            loader(true)
            const response = await authCaller.get(`ResendVerificationOtpToEmailLinkedToBvn/${bvn}`)
            loader(false)
            notifier.show(requestMessage(response.data), 'success')
            setInfoProperty('onBoardingData', {...info.onBoardingData, resendOtpType: 'email'})
            return true
        }
        catch (err: any) {
            loader(false)
            notifier.show(erroMessage(catchErrMsg(err)))
            return null
        }
    }

    async function passwordResetResendOtp (payload: any) {
        try {
            loader(true)
            const response = await authCaller.post('PasswordReset-ResendOtp', {...payload, channelId})
            loader(false)
            notifier.show(requestMessage(response.data), 'success')
        }
        catch (err: any) {
            loader(false)
            notifier.show(erroMessage(catchErrMsg(err)))
            return null
        }
    }

    async function verifyOtp (payload: any) {
        try {
            loader(true)
            const response = await authCaller.post('verify_emailOrPhone', {...payload, channelId})
            loader(false)

            console.log({response: response.data})
            if (!response.data.status) {
                notifier.show(requestMessage(response.data))
                return null
            }
            else {
                setInfoProperty('token', response.data.data.access_token)
                setInfoProperty('refreshToken', response.data.data.refresh_token)
                setUserProperty('userData', {...user.userData, ...response.data.data.user})
                return response.data.data
            }
        }
        catch (err: any) {
            loader(false)
            notifier.show(erroMessage(catchErrMsg(err)))
            return null
        }
    }

    async function verifyEmail (userId: string) {
        try {
            loader(true)
            const response = await authCaller.get(`verify-email/${userId}`)
            loader(false)

            console.log({response: response.data})
            if (!response.data.status) {
                notifier.show(requestMessage(response.data))
                return null
            }
            else {
                return true
            }
        }
        catch (err: any) {
            loader(false)
            notifier.show(erroMessage(catchErrMsg(err)))
            return null
        }
    }

    async function updateEmail (payload: any) {
        try {
            loader(true)
            const response = await authCaller.post('update_email', {...payload, channelId})
            loader(false)

            console.log({response: response.data})
            if (!response.data.status) {
                notifier.show(requestMessage(response.data))
                return null
            }
            else {
                setInfoProperty('onBoardingData', {...info.onBoardingData, email: payload.email})
                return response.data.data
            }
        }
        catch (err: any) {
            loader(false)
            notifier.show(erroMessage(catchErrMsg(err)))
            return null
        }
    }

    async function updateMarital_EducationalStatus (education: string) {
        if (!info.deviceInfo?.deviceModel) await getDeviceInfo()

        var payload = {
            maritalStatus: info.onBoardingData.maritalStatus,
            dependents: info.onBoardingData.dependents,
            educationalQualification: education,
            email: info.onBoardingData.email,
            deviceType: info.deviceInfo?.deviceModel
        }

        try {
            loader(true)
            const response = await profileCaller.post(`update_profile/${user.userData.id}`, payload)
            loader(false)

            console.log({response: response.data})
            if (response.data.code != 200) {
                notifier.show(requestMessage(response.data))
                return null
            }
            else {
                const userInfo = {...response.data.data}
                Object.keys(userInfo).forEach(key => {
                    if (userInfo[key] === null) {
                      delete userInfo[key];
                    }
                });
                setInfoProperty('onBoardingData', {...info.onBoardingData, educationalQualification: education})
                setUserProperty('userData', {...user.userData, ...userInfo})
                return response.data.data
            }
        }
        catch (err: any) {
            loader(false)
            notifier.show(erroMessage(catchErrMsg(err)))
            return null
        }
    }

    async function updateEmploymentDetails (payload: any, isUpdate?: boolean) {
        try {
            loader(true)
            const response = await identityCaller.post('update-employment_details', {...payload, userId: user.userData?.userId || user.userData.id})
            loader(false)

            console.log({response: response.data})
            if (!response.data.status) {
                notifier.show(requestMessage(response.data))
                return null
            }
            else {
                const {id, ...rest} = response.data.data
                if (!isUpdate) setInfoProperty('onBoardingData', {...info.onBoardingData, ...payload})
                await setUserProperty('userData', {...user.userData, ...rest})
                console.log({user: user.userData})
                return response.data.data
            }
        }
        catch (err: any) {
            loader(false)
            notifier.show(erroMessage(catchErrMsg(err)))
            return null
        }
    }

    async function Marital_EducationalStatusUpdate (data: any) {
        if (!info.deviceInfo?.deviceModel) await getDeviceInfo()

        var payload = {
            ...data,
            deviceType: info.deviceInfo?.deviceModel
        }

        try {
            loader(true)
            const response = await profileCaller.post(`update_profile/${user.userData?.userId || user.userData.id}`, payload)
            loader(false)

            console.log({response: response.data})
            if (response.data.code != 200) {
                notifier.show(requestMessage(response.data))
                return null
            }
            else {
                const userInfo = {...response.data.data}
                Object.keys(userInfo).forEach(key => {
                    if (userInfo[key] === null) {
                      delete userInfo[key];
                    }
                });
                setUserProperty('userData', {...user.userData, ...userInfo})
                return response.data.data
            }
        }
        catch (err: any) {
            loader(false)
            notifier.show(erroMessage(catchErrMsg(err)))
            return null
        }
    }

    async function getResidenceCategories () {
        try {
            loader(true)
            const response = await identityCaller.get('GetResidenceCategories')
            loader(false)

            console.log({response: response.data})
            if (!response.data?.length) {
                notifier.show('Residence categories could not be fetched')
                return null
            }
            else {
                return response.data
            }
        }
        catch (err: any) {
            loader(false)
            notifier.show(erroMessage(catchErrMsg(err)))
            return null
        }
    }

    async function updateAddress (payload: any, isUpdate?: boolean) {
        const stateId = helpers.getStateId(payload.state)
        if (!info.deviceInfo?.latitude) await getDeviceInfo()

        try {
            loader(true)
            const response = await identityCaller.post('update-address', {
                ...payload, 
                userId: user.userData.userId,
                channel: 'Web', 
                locationInfo: {
                    latitude: info.deviceInfo?.latitude || 0,
                    longitude: info.deviceInfo?.longitude || 0,
                    ipAddress: info.deviceInfo?.ipAddress,
                    // streetName: payload?.street,
                    // directions: payload?.area
                }
            })
            loader(false)

            if (!response.data?.status) {
                notifier.show(requestMessage(response.data))
                return null
            }
            else {
                if (!isUpdate) await setInfoProperty('onBoardingData', {...info.onBoardingData, ...payload, stateId})
                await setUserProperty('userData', {...user.userData, ...response.data.data})
                return response.data?.data
            }
        }
        catch (err: any) {
            loader(false)
            notifier.show(erroMessage(catchErrMsg(err)))
            return null
        }
    }

    async function updateIdCard (payload: any, isUpdate?: boolean) {
        const cardDateValidation = helpers.validateIdCardDate(payload.issuanceDate, payload.expiryDate)
        if (!cardDateValidation?.isValid) return notifier.show(cardDateValidation?.message)

        try {
            loader(true)
            const response = await identityCaller.post('update-regulatory_Id', {...payload, userId: user.userData?.userId})
            loader(false)

            console.log({response: response.data})
            if (!response.data.status) {
                notifier.show(requestMessage(response.data))
                return null
            }
            else {
                if (!isUpdate) setInfoProperty('onBoardingData', {...info.onBoardingData, ...payload})
                if (response.data.data) {
                    const {userId, ...rest} = response.data.data
                    await setUserProperty('userData', {...user.userData, ...rest})
                }
                return true //response.data.status
            }
        }
        catch (err: any) {
            loader(false)
            notifier.show(erroMessage(catchErrMsg(err)))
            
            let resp = null
            if (err.response?.status === 400 && err.response?.data?.responseCode === 409) {
                resp = {useVnin: true}
            }

            return resp
        }
    }

    async function updateSignature (signaturePath: string, faceScan = null) {
        try {
            loader(true)
            const response = await identityCaller.post('update-facescan', {signaturePath, imagePath: faceScan || info.onBoardingData?.faceScanImage || '', userId: user.userData?.userId})
            loader(false)

            console.log({response: response.data})
            if (!response.data.status) {
                notifier.show(requestMessage(response.data))
                return null
            }
            else {
                setInfoProperty('onBoardingData', {...info.onBoardingData, signaturePath})
                if (response.data.data) {
                    const {userId, ...rest} = response.data.data
                    await setUserProperty('userData', {...user.userData, ...rest})
                }
                return response.data.status
            }
        }
        catch (err: any) {
            loader(false)
            console.log({err})
            notifier.show(erroMessage(catchErrMsg(err)))
            return null
        }
    }

    async function getBankList () {
        try {
            loader(true)
            const response = await identityCaller.get('bank-list')
            loader(false)

            console.log({response: response.data})
            if (!response.data.length) {
                notifier.show('Bank list could not be fetched')
                return null
            }
            else {
                return response.data
            }
        }
        catch (err: any) {
            loader(false)
            notifier.show(erroMessage(catchErrMsg(err)))
            return null
        }
    }

    async function validateAccountNo (payload: any) {
        try {
            loader(true)
            const response = await identityCaller.post('validate-account-number', payload)
            loader(false)

            console.log({response: response.data})
            if (!response.data.status) {
                notifier.show(requestMessage(response.data))
                return null
            }
            else {
                return response.data.data
            }
        }
        catch (err: any) {
            loader(false)
            notifier.show(erroMessage(catchErrMsg(err)))
            return null
        }
    }

    async function linkBank (payload: any, isLoggedIn?: boolean) {
        try {
            loader(true)
            const response = await identityCaller.post('link-bank', {...payload, userId: user.userData.userId})
            loader(false)

            if (!response.data.status) {
                notifier.show(requestMessage(response.data))
                return null
            }
            else {
                await setInfoProperty('onBoardingData', {...info.onBoardingData, ...payload})
                if (isLoggedIn) {
                    await getUserDetails()
                    await getWalletAccount()
                } 
                return response.data.status
            }
        }
        catch (err: any) {
            loader(false)
            notifier.show(erroMessage(catchErrMsg(err)))
            return null
        }
    }

    async function createWalletAccount (data: any) {
        const payload = {
            ...data,
            phoneNumber: user.userData.mobileNo || user.userData.phoneNumber
        }
        try {
            loader(true)
            const response = await walletCaller.post('account/addAccount', payload)
            loader(false)

            console.log({response: response.data})
            if (response.status != 200 && response.status != 201) {
                notifier.show(requestMessage(response.data))
                return null
            }
            else {
                return response.data.data
            }
        }
        catch (err: any) {
            loader(false)
            if (err.response.status === 400 && err.response.data.message === 'Phone Number already exists!') return true
            else{
                notifier.show(erroMessage(catchErrMsg(err)))
                return null
            }
        }
    }

    async function createPasscode (password: string, confirmPassword: string) {
        if (password != confirmPassword) {
            notifier.show('Password confirmation is invalid')
            return false
        }

        try {
            loader(true)
            const response = await authCaller.post('create_password', {password, userId: user.userData.userId})
            loader(false)

            if (!response.data.status) {
                notifier.show(response.data.message)
                return null
            }
            else {
                await setInfoProperty('onBoardingData', {...info.onBoardingData, passcode: password})
                return response.data.status
            }
        }
        catch (err: any) {
            loader(false)
            notifier.show(erroMessage(catchErrMsg(err)))
            return null
        }
    }

    async function createTransactionPin (pin: string, confirmPin: string) {
        if (pin != confirmPin) {
            notifier.show('Transaction Pin confirmation is invalid')
            return false
        }
        try {
            loader(true)
            const response = await identityCaller.post('Set_Transaction_Pin', {userId: user.userData?.userId, pin})
            loader(false)

            console.log({response: response.data})
            if (!response.data.status) {
                notifier.show(requestMessage(response.data))
                return null
            }
            else {
                GAEventTracker('Successful Onboarding Completion', `UserId: ${user.userData?.userId}`, 'Onboarding')
                return response.data.status
            }
        }
        catch (err: any) {
            loader(false)
            notifier.show(erroMessage(catchErrMsg(err)))
            return null
        }
    }

    async function changePasscode (payload: any) {
        try {
            loader(true)
            const response = await authCaller.post('change_password', payload)
            loader(false)

            console.log({response: response.data})
            if (response.status != 200 && response.status != 201) {
                notifier.show(requestMessage(response.data, 'Passowrd change failed'))
                return null
            }
            else {
                responder.show(
                    'Passcode changed successfully', 
                    'Your passcode was changed succesfully.',
                    'card',
                    'continue',
                    '/dashboard/settings/home'
                )
                return response.data
            }
        }
        catch (err: any) {
            loader(false)
            console.log({err})
            notifier.show(erroMessage(catchErrMsg(err)))
            return null
        }
    }

    async function changeTransactionPin (payload: any) {
        try {
            loader(true)
            const response = await identityCaller.put(`Change_Transaction_Pin/${user.userData?.userId}`, payload)
            loader(false)

            console.log({response: response.data})
            if (response.status != 200 && response.status != 201) {
                notifier.show(response.data.message || 'Transaction pin could not be changed')
                return null
            }
            else {
                responder.show(
                    'PIN changed successfully', 
                    'Your transaction PIN was changed succesfully.',
                    'card',
                    'continue',
                    '/dashboard/settings/home'
                )
                return response.data
            }
        }
        catch (err: any) {
            loader(false)
            notifier.show(erroMessage(catchErrMsg(err)))
            return null
        }
    }

    async function verifyTransactionPin (pin: string) {
        try {
            loader(true)
            const response = await identityCaller.post('Validate_Transaction_Pin', {userId: user.userData?.userId, pin})
            loader(false)

            console.log({response: response.data})
            if (!response.data.status || response.status != 200) {
                notifier.show(requestMessage(response.data))
                return null
            }
            else {
                return response.data.status
            }
        }
        catch (err: any) {
            loader(false)
            notifier.show(erroMessage(catchErrMsg(err)))
            return null
        }
    }

    async function updateLocationHistory () {
        if (!info.deviceInfo?.latitude) await getDeviceInfo()
        if (!info.deviceInfo?.latitude) return null

        try {
            loader(true)
            const response = await identityCaller.post(`update-location-history`, {
                latitude: info.deviceInfo?.latitude || 0,
                longitude: info.deviceInfo?.longitude || 0,
                ipAddress: info.deviceInfo?.ipAddress
            })

            loglite(response.data, 'updateLocationHistory API')
            return true
        }
        catch (err: any) {
            return null
        }
    }

    async function updateLoginHistory () {
        if (!info.deviceInfo?.latitude) await getDeviceInfo()
        if (!info.deviceInfo?.latitude) return null

        try {
            loader(true)
            const response = await authAdminCaller.post(`UpdateLoginHistory`, {
                latitude: info.deviceInfo?.latitude || 0,
                longitude: info.deviceInfo?.longitude || 0,
                ipAddress: info.deviceInfo?.ipAddress,
                lastLoginDate: user.userData?.lastLoginDate,
                customerId: user.userData?.customerId
            })
            loader(false)

            loglite(response.data, 'updateLoginHistory API')
            return true
        }
        catch (err: any) {
            loader(false)
            //notifier.show(erroMessage(catchErrMsg(err)))
            return null
        }
    }

    async function uploadToCloudinary (data: any, cloudName: string) {
        try {
            loader(true)
            const response = await axios.post(`https://api.cloudinary.com/v1_1/${cloudName}/image/upload`, data)
            loader(false)
            console.log({imageurl: response.data})
            return response.data.secure_url
        }
        catch (err: any) {
            loader(false)
            notifier.show(`Document could not be uploaded`)
            return null
        }
    }

    async function forgetPasswordIdentification (email: string) {
        try {
            loader(true)
            const response = await authCaller.post('forgot_password', {email, channelId})
            loader(false)

            console.log({response: response.data})
            if (!response.data.status) {
                notifier.show(requestMessage(response.data))
                return null
            }
            else {
                return response.data.status
            }
        }
        catch (err: any) {
            loader(false)
            notifier.show(erroMessage(catchErrMsg(err)))
            return null
        }
    }

    async function resetPassword (newPassword: string, confirmPassword: string) {
        if (newPassword != confirmPassword) {
            notifier.show('Passcode confirmation is invalid')
            return false
        }
        try {
            loader(true)
            const response = await authCaller.post('reset_password', {otp: info.onBoardingData?.otp, newPassword, confirmPassword, channelId, email: info.onBoardingData?.email})
            loader(false)

            console.log({response: response.data})
            if (!response.data.status) {
                notifier.show(requestMessage(response.data))
                return null
            }
            else {
                notifier.show(requestMessage(response.data), 'success')
                return response.data.status
            }
        }
        catch (err: any) {
            loader(false)
            notifier.show(erroMessage(catchErrMsg(err)))
            return null
        }
    }

    async function getLoanTypes () {
        try {
            loader(true)
            const response = await loanCaller.get('Loan/loanTypes')
            loader(false)

            console.log({response: response.data})
            if (response.status != 200) {
                notifier.show('Loan types could not be fetched')
                return null
            }
            else {
                await setLoanProperty('loanTypes', response.data.data)
                return response.data.data
            }
        }
        catch (err: any) {
            loader(false)
            notifier.show(erroMessage(catchErrMsg(err)))
            return null
        }
    }

    async function calculateLoanRepayment (payload: any) {
        try {
            loader(true)
            const response = await loanRepaymentCaller.post('CalculateLoanRepaymentAmount', payload)
            loader(false)

            console.log({response: response.data})
            if (response.status != 200 && response.status != 201) {
                notifier.show(requestMessage(response.data))
                return null
            }
            else {
                return response.data.data
            }
        }
        catch (err: any) {
            loader(false)
            notifier.show(erroMessage(catchErrMsg(err)))
            return null
        }
    }

    async function publishLoanPortfolio (offerData={}, isQuickAccess = false) {
        let repaymentTermId = loan.loanCreation?.rateId == 2 ? 1 : loan.loanCreation?.repaymentTermId
        const payload = {
            repaymentTermId,
            ...offerData,
            ...loan.loanCreation,
            firstName: user.userData.firstName,
            lastName: user.userData.lastName,
            email: user.userData.email,
            phoneNumber: user.userData.mobileNo,
            customerId: user.userData.customerId,
            userType: user.userData.profileType.toUpperCase(),
            countryCode: '234',
        }
        try {
            loader(true)
            const response = await loanCaller.post('LoanOffer/create-loan-offer', payload)
            loader(false)

            console.log({response: response.data})
            if (response.status != 200 && response.status != 201) {
                notifier.show('Loan offer could not be created')
                return null
            }
            else {
                await setLoanProperty('proposedLoanRequest', response.data.data) // loanRequest or offer created
                await setLoanProperty('currentProcessType', 'lender') // wether loan request or loan offer
                await getCurrentLoans() // To update the dashboard with expected loan card to display
                if (!isQuickAccess) await getOfferLoanRequests (response.data.data?.customerId || user.userData.customerId) // to recommend requests to lenders
                if (!isQuickAccess) {
                    responder.show(
                        'You have successfully published a loan offer', 
                        'Nice, You have published a loan offer. Borrowers will be able to see your offer.',
                        'success',
                        'View available loan request',
                        '/dashboard/loan-board/loan-offers'
                    )
                    access.getNavigationAccess()('/dashboard/loan')
                }
                return response.data.data
            }
        }
        catch (err: any) {
            loader(false)
            notifier.show(erroMessage(catchErrMsg(err)))
            return null
        }
    }

    async function submitLoanRequest (loanData={}, isQuickAccess=false) {
        let repaymentTermId = loan.loanRequest?.rateId == 2 ? 1 : loan.loanRequest?.repaymentTermId
        const payload = {
            repaymentTermId,
            ...loanData,
            ...loan.loanRequest,
            firstName: user.userData.firstName,
            lastName: user.userData.lastName,
            email: user.userData.email,
            phoneNumber: user.userData.mobileNo,
            customerId: user.userData.customerId,
            userType: user.userData.profileType?.toUpperCase(),
            countryCode: '234'
        }
        try {
            loader(true)
            console.log({payload})
            const response = await loanCaller.post('LoanRequest/create-loan-request', payload)
            loader(false)

            console.log({response})
            if (response.status != 200 && response.status != 201) {
                notifier.show('Loan request could not be submitted')
                return null
            }
            else {
                await setLoanProperty('proposedLoanRequest', response.data.data) // loanRequest or offer created
                access.setProposedLoan(response.data.data)
                await setLoanProperty('currentProcessType', 'borrower') // wether loan request or loan offer
                //await getInfoForCharts() // to update the loan charts
                await getCurrentLoans() // To update the dashboard with expected loan card to display
                await getUserOverallScore() // get the updated crendly score
                //let result;
                //if (!isQuickAccess) result = await getRequestLoanOffers (response.data.data?.customerId || user.userData.customerId) // to recommend offers to borrowers
                if (!access.getCallDebounce()) waitingScreen.show(isQuickAccess ? false : true)
                return response.data.data
            }
        }
        catch (err: any) {
            loader(false)
            console.log({err})
            notifier.show(erroMessage(catchErrMsg(err) || err.response?.data?.errors?.Amount))
            return null
        }
    }

    async function getCreditDecisionResponse (decisionData: any, isViewOffers = true) {
        //console.log({decisionData, isViewOffers})
        let userPhoneNo = user.userData.mobileNo || user.userData.phoneNumber
        let proposedLoan = access.getProposedLoan()

        if (processingScreen.isViewOffers != null) isViewOffers = processingScreen.isViewOffers // new implementation, to be monitored and ensure it doesn't affect anything
        try {
            waitingScreen.hide()
            if (!decisionData?.creditDecisionResult || (!decisionData?.loanOffers?.length)) {
                notifier.show(decisionData?.message || 'No available loan offers within your eligible amount')
                cancelOpenLoanRequest(loan.proposedLoanRequest?.loanRequestId || proposedLoan?.loanRequestId)
                access.getNavigationAccess()('/dashboard/home')
                return null
            }
            else {
                decisionData.loanOffers = decisionData?.loanOffers?.filter((item: any) => item.phoneNumber != userPhoneNo)
                await setLoanProperty('requestLoanOffersCreditDecision', decisionData)
                await setLoanProperty('requestLoanOffers', decisionData?.loanOffers) // save the matched loan requests or offers to be used for following processes 
                if (isViewOffers) access.getNavigationAccess()('/dashboard/loan-board/loan-offers') // new implementation, to be monitored and ensure it doesn't affect anything
                else access.getNavigationAccess()('/dashboard/loan-board/loan-offer-details')
                return true
            }
        }
        catch (err: any) {
            loader(false)
            cancelOpenLoanRequest(loan.proposedLoanRequest?.loanRequestId || proposedLoan?.loanRequestId)
            notifier.show(erroMessage(catchErrMsg(err)))
            return null
        }
    }


    async function getRequestLoanOffers (borrowerCustomerId?: string, isViewOffers = true) {
        let customerId = user.userData.customerId || borrowerCustomerId
        let proposedLoan = access.getProposedLoan()

        try {
            loader(true)
            const response = await recommendationCaller.get(`loan-offers/${customerId}`)
            loader(false)
            console.log({response})
            if (response.status != 200 && response.status != 201) {
                waitingScreen.hide()
                cancelOpenLoanRequest(loan.proposedLoanRequest?.loanRequestId || proposedLoan?.loanRequestId)
                notifier.show('Loan offers could not be fetched')
                access.getNavigationAccess()('/dashboard/home')
            }
            else {
                getCreditDecisionResponse(response.data?.data, isViewOffers)
            }
            return true
        }
        catch (err: any) {
            loader(false)
            waitingScreen.hide()
            cancelOpenLoanRequest(loan.proposedLoanRequest?.loanRequestId || proposedLoan?.loanRequestId)
            notifier.show(erroMessage(catchErrMsg(err)))
            access.getNavigationAccess()('/dashboard/home')
            return null
        }
    }

    async function getOfferLoanRequests (lenderCustomerId: string) {
        try {
            loader(true)
            const response = await recommendationCaller.get(`loan-requests/${lenderCustomerId}`)
            loader(false)

            console.log({response})
            if (response.status != 200 && response.status != 201) {
                notifier.show('Loan requests could not be fetched')
            }
            else {
                let userPhoneNo = user.userData.mobileNo || user.userData.phoneNumber
                response.data = response.data?.filter((item: any) => item.phoneNumber != userPhoneNo)
                await setLoanProperty('requestLoanOffers', response.data) // save the matched loan requests or offers to be used for following processes 
            }
            return true
        }
        catch (err: any) {
            loader(false)
            notifier.show(erroMessage(catchErrMsg(err)))
            return null
        }
    }

    async function filterLoanOffer (payload: any) {
        //console.log({loanType: info.quickRequest?.category})
        let loanCategory = info.quickRequest?.category
        let loanType = loanCategory ? `${utility.toTitleCase(loanCategory)}${loanCategory.toLowerCase() != 'others' ? ' Loan' : ''}` : null
        try {
            loader(true)
            const response = await recommendationCaller.get('borrower/loan-offers', {
                params: {
                    "filter.amount.from": payload?.amountFrom,
                    "filter.amount.to": payload?.amountTo,
                    "filter.duration.from": payload?.durationFrom,
                    "filter.duration.to": payload?.durationTo,
                    "filter.interestRate.from": payload?.interestFrom,
                    "filter.interestRate.to": payload?.interestTo,
                    "filter.loanType": payload?.loanType || loanType,
                    "searchText": payload?.searchText,
                }
            })
            loader(false)

            console.log({response})
            if (response.status != 200 && response.status != 201) {
                notifier.show('Loan offers Could not be filtered or searched')
                return null
            }
            else {
                let userPhoneNo = user.userData.mobileNo || user.userData.phoneNumber
                response.data = response.data?.filter((item: any) => (item.phoneNumber != userPhoneNo) && !item.completed)
                await setLoanProperty('requestLoanOffers', response.data?.reverse())
                return response.data
            }
        }
        catch (err: any) {
            loader(false)
            notifier.show(erroMessage(catchErrMsg(err)))
            return null
        }
    }

    async function filterLoanRequest (payload: any) {
        //console.log({loanType: info.quickRequest?.category})
        let loanCategory = info.quickRequest?.category
        let loanType = loanCategory ? `${utility.toTitleCase(loanCategory)}${loanCategory.toLowerCase() != 'others' ? ' Loan' : ''}` : null
        try {
            loader(true)
            const response = await recommendationCaller.get('lender/loan-requests', {
                params: {
                    "filter.amount.from": payload?.amountFrom,
                    "filter.amount.to": payload?.amountTo,
                    "filter.duration.from": payload?.durationFrom,
                    "filter.duration.to": payload?.durationTo,
                    "filter.interestRate.from": payload?.interestFrom,
                    "filter.interestRate.to": payload?.interestTo,
                    "filter.loanType": payload?.loanType || loanType,
                    "searchText": payload?.searchText,
                }
            })
            loader(false)

            console.log({response})
            if (response.status != 200 && response.status != 201) {
                notifier.show('Loan requests could not be filtered or searched')
                return null
            }
            else {
                let userPhoneNo = user.userData.mobileNo || user.userData.phoneNumber
                response.data = response.data?.filter((item: any) => ((item.phoneNumber != userPhoneNo) && !item.completed ))

                await setLoanProperty('requestLoanOffers', response.data?.reverse())
                return response.data
            }
        }
        catch (err: any) {
            loader(false)
            notifier.show(erroMessage(catchErrMsg(err)))
            return null
        }
    }

    async function requestLoan (loanOfferIds: Array<number>, isNegotiationAcceptance = false, loanRequestId = null) {
        const payload = {
            loanRequestId: loan?.proposedLoanRequest?.loanRequestId || loanRequestId,
            loanOfferIds
        }
        try {
            loader(true)
            const response = await loanCaller.post(`LoanRequest/SubmitInterestInLoanOffers`, payload)
            loader(false)

            console.log({response})
            if ((response.status != 200 && response.status != 201) || !response.data.status) {
                notifier.show(response.data.messageString || 'Loan request could not be submitted')
            }
            else {
                if (!isNegotiationAcceptance) {
                    await getCurrentLoans()
                    //await getInfoForCharts()
                    responder.show(
                        'Your loan request has been successfully submitted', 
                        'We will send a notification of your interest in his/her loan offer. You will get notified when he/she responds.',
                        'loan-request',
                        'Ok',
                        '/dashboard/home'
                    )
                }
                else notifier.show('You have successfully accepted the negotiation', 'success')
                
                access.getNavigationAccess()('/dashboard/home')
                return response.data.data
            }
        }
        catch (err: any) {
            loader(false)
            notifier.show(erroMessage(catchErrMsg(err)))
            return null
        }
    }

    async function submitInterestInLoanRequest (loanRequestId: number, offerId = null) {
        const loanOfferId = loan?.proposedLoanRequest?.loanOfferId || offerId
        
        try {
            loader(true)
            const response = await loanCaller.post(`LoanOffer/SubmitInterestInLoanRequest/${loanOfferId}/${loanRequestId}`)
            loader(false)

            console.log({response})
            if (response.status != 200 && response.status != 201) {
                notifier.show(response.data.messageString || 'Proposed Loan Offer could not be submitted')
            }
            else {
                //await getCurrentLoans()
                responder.show(
                    'Your proposed loan offer has been successfully submitted', 
                    'We will send a notification of your offer to the borrower. You will get notified when he/she responds.',
                    'loan-request',
                    'Ok',
                    '/dashboard/home'
                )
                access.getNavigationAccess()('/dashboard/home')
                return response.data.data
            }
        }
        catch (err: any) {
            loader(false)
            notifier.show(erroMessage(catchErrMsg(err)))
            if (err?.response?.data?.messageString.includes('fund your loan portfolio')) return 'fund portfolio'
            return null
        }
    }

    async function handleLoanRejectionNotif (data: any) {
        //Responsible for taking the user to a screen where they can see other
        //loan offers similar to their initial loan request
        const payload = {
            loanType: data?.loanOffer?.loanTypeName || data?.loanTypeName,
            amountFrom: data?.loanOffer?.amountPerPerson || data?.amount,
            durationFrom: data?.tenor,
            interestFrom: data?.interest,
            searchText: data?.loanOffer?.loanTypeName || data?.loanTypeName
        }

        try {
            await setLoanProperty('proposedLoanRequest', data)
            access.setProposedLoan(data)
            await setLoanProperty('currentProcessType', 'borrower')
            await filterLoanOffer(payload)
            updateNotificationStatus()
            access.getNavigationAccess()('/dashboard/loan-board/loan-offers')
            return true
        }
        catch (err) {
            loader(false)
            notifier.show(erroMessage(catchErrMsg(err)))
            return null
        }
    }

    async function sendNegotiation (payload: any) {
        try {
            loader(true)
            const response = await loanCaller.post(`LoanRequest/NegotiateLoanOffer/${payload.loanOfferId}`, {...payload, loanRequestId: loan.proposedLoanRequest.loanRequestId})
            loader(false)

            console.log({response})
            if (response.status != 200 && response.status != 201) {
                notifier.show(response.data.messageString || 'Offer negotiation could not be sent')
            }
            else {
                responder.show(
                    'Negotiation sent to Lender', 
                    'Your negotiation terms have been sent to the Lender. You will get a notification when the Lender accepts or declines.',
                    'negotiation',
                    'Ok',
                    '/dashboard/home'
                )
                access.getNavigationAccess()('/dashboard/home')
                return response.data.data
            }
        }
        catch (err: any) {
            loader(false)
            notifier.show(erroMessage(catchErrMsg(err)))
            return null
        }
    }


    async function calculateRepaymentAmount (payload: any) {
        try {
            loader(true)
            const response = await loanRepaymentCaller.post('LoanCalculator', payload)
            loader(false)

            console.log({response: response.data})
            if (response.status != 200 && response.status != 201) {
                notifier.show(requestMessage(response.data))
                return null
            }
            else {
                return response.data.data
            }
        }
        catch (err: any) {
            loader(false)
            notifier.show(erroMessage(catchErrMsg(err)))
            return null
        }
    }

    async function linkBankAccount (type?: string) {
        responder.show(
            type === 'success' ? 'Bank Account Linked' : 'Bank account Linking Failed', 
            type === 'success' ? 'Your bank account was linked succesfully.' : 'Error occured while linking bank account',
            type === 'success' ? 'bank' : 'cancel',
            'Continue',
            '/dashboard/home'
        )
        return null
    }

    async function topupWallet (payload: any) {
        try {
            loader(true)
            const response = await walletCaller.post('transaction/VerifyWalletFunding', payload)
            loader(false)

            console.log({response: response.data})
            if (response.status != 200 && response.status != 201) {
                notifier.show(response.data.message || "Wallet could not be funded")
                return null
            }
            else {
                await getWalletAccount()
                responder.show(
                    'Topup Successful', 
                    'Your wallet topup was successful.',
                    'topup',
                    'Go to Home',
                    '/dashboard/home'
                )
                return true
            }
        }
        catch (err: any) {
            loader(false)
            notifier.show(erroMessage(catchErrMsg(err)))
            return null
        }
    }

    async function initializeFunding (payload: any) {
        try {
            loader(true)
            const response = await walletCaller.post('transaction/InitializeTransaction', payload)
            loader(false)

            console.log({response: response.data})
            if (response.status != 200 && response.status != 201) {
                notifier.show(response.data.message || "Wallet could not be funded")
                return null
            }
            else return response.data.data
        }
        catch (err: any) {
            loader(false)
            notifier.show(erroMessage(catchErrMsg(err)))
            return null
        }
    }

    async function fetchTrustQuestions () {
        try {
            loader(true)
            const response = await decisionCaller.get('TrustQuestion/GetTrustQuestions')
            loader(false)

            console.log({response: response.data})
            if (response.status != 200 && response.status != 201) {
                notifier.show(requestMessage(response.data))
                return null
            }
            else {
                return response.data.data
            }
        }
        catch (err: any) {
            loader(false)
            notifier.show(erroMessage(catchErrMsg(err)))
            return null
        }
    }

    async function fetchPersonalityQuestions () {
        try {
            loader(true)
            const response = await decisionCaller.get('PersonalityQuestion/GetPersonalityQuestions')
            loader(false)

            console.log({response: response.data})
            if (response.status != 200 && response.status != 201) {
                notifier.show(requestMessage(response.data))
                return null
            }
            else {
                return response.data.data
            }
        }
        catch (err: any) {
            loader(false)
            notifier.show(erroMessage(catchErrMsg(err)))
            return null
        }
    }

    async function submitTrustAnswers (payload: any) {
        //console.log({payload})
        try {
            loader(true)
            const response = await decisionCaller.post('TrustQuestion/SubmitAnswers', payload)
            loader(false)

            console.log({response: response.data})
            if (response.status != 200 && response.status != 201) {
                notifier.show(requestMessage(response.data))
                return null
            }
            else {
                await getUserDetails()
                await getUserOverallScore()
                return response.data.status
            }
        }
        catch (err: any) {
            loader(false)
            notifier.show(erroMessage(catchErrMsg(err)))
            return null
        }
    }

    async function submitPersonalityAnswers (payload: any) {
        //console.log({payload})
        try {
            loader(true)
            const response = await decisionCaller.post('PersonalityQuestion/SubmitAnswers', payload)
            loader(false)

            console.log({response: response.data})
            if (response.status != 200 && response.status != 201) {
                notifier.show(requestMessage(response.data))
                return null
            }
            else {
                await getUserDetails()
                return response.data.status
            }
        }
        catch (err: any) {
            loader(false)
            notifier.show(erroMessage(catchErrMsg(err)))
            return null
        }
    }

    async function saveTrustAnswers (answers: Array<any>) {
        const payload = {
            userPhoneNumber: user.userData.mobileNo || user.userData.phoneNumber,
            customerId: user.userData.customerId,
            answers
        }
        try {
            loader(true)
            const response = await decisionCaller.post('TrustQuestion/Save-to_Continue', payload)
            loader(false)

            console.log({response: response.data})
            if (response.status != 200 && response.status != 201) {
                notifier.show(requestMessage(response.data))
                return null
            }
            else {
                return response.data.status
            }
        }
        catch (err: any) {
            loader(false)
            notifier.show(erroMessage(catchErrMsg(err)))
            return null
        }
    }

    async function savePersonalityAnswers (answers: any) {
        const payload = {
            userPhoneNumber: user.userData.mobileNo || user.userData.phoneNumber,
            customerId: user.userData.customerId,
            answers
        }
        try {
            loader(true)
            const response = await decisionCaller.post('PersonalityQuestion/Save-to_Continue', payload)
            loader(false)

            console.log({response: response.data})
            if (response.status != 200 && response.status != 201) {
                notifier.show(requestMessage(response.data))
                return null
            }
            else {
                return response.data.status
            }
        }
        catch (err: any) {
            loader(false)
            notifier.show(erroMessage(catchErrMsg(err)))
            return null
        }
    }

    async function getSavedTrustAnswers () {
        try {
            loader(true)
            const response = await decisionCaller.get(`TrustQuestion/Get-saved-Answers/${user.userData.customerId}`)
            loader(false)

            console.log({response: response.data})
            if (response.status != 200 && response.status != 201) {
                notifier.show(requestMessage(response.data))
                return null
            }
            else {
                return response.data.data
            }
        }
        catch (err: any) {
            loader(false)
            notifier.show(erroMessage(catchErrMsg(err)))
            return null
        }
    }

    async function getSavedPersonlityAnswers () {
        try {
            loader(true)
            const response = await decisionCaller.get(`PersonalityQuestion/Get-saved-Answers/${user.userData.customerId}`)
            loader(false)

            console.log({response: response.data})
            if (response.status != 200 && response.status != 201) {
                notifier.show(requestMessage(response.data))
                return null
            }
            else {
                return response.data.data
            }
        }
        catch (err: any) {
            loader(false)
            notifier.show(erroMessage(catchErrMsg(err)))
            return null
        }
    }

    async function fetchAllUserOffers () {
        try {
            loader(true)
            const response = await loanCaller.get(`LoanOffer/get-loan-offers/lender/${user.userData.customerId}`)
            loader(false)

            console.log({response: response.data})
            if (response.status != 200 && response.status != 201) {
                notifier.show(requestMessage(response.data))
                return null
            }
            else {
                setLoanProperty('allUserPortfolios', response.data.data)
                return response.data.data
            }
        }
        catch (err: any) {
            loader(false)
            notifier.show(erroMessage(catchErrMsg(err)))
            return null
        }
    }

    async function fetchUserRunningOffers () {
        try {
            loader(true)
            const response = await loanCaller.get(`LoanOffer/get-loan-offers/unpaid/${user.userData.customerId}`)
            loader(false)

            console.log({response: response.data})
            if (response.status != 200 && response.status != 201) {
                notifier.show(requestMessage(response.data))
                return null
            }
            else {
                setLoanProperty('userRunningPortfolios', response.data.data)
                return response.data.data
            }
        }
        catch (err: any) {
            loader(false)
            notifier.show(erroMessage(catchErrMsg(err)))
            return null
        }
    }

    async function fetchUserEndedOffers () {
        try {
            loader(true)
            const response = await loanCaller.get(`LoanOffer/get-loan-offers/paid/${user.userData.customerId}`)
            loader(false)

            console.log({response: response.data})
            if (response.status != 200 && response.status != 201) {
                notifier.show(requestMessage(response.data))
                return null
            }
            else {
                return response.data.data
            }
        }
        catch (err: any) {
            loader(false)
            notifier.show(erroMessage(catchErrMsg(err)))
            return null
        }
    }

    async function fetchAllUserLoans () {
        try {
            loader(true)
            const response = await loanCaller.get(`LoanRequest/get-loan-requests/borrower/${user.userData.customerId}`)
            loader(false)

            console.log({response: response.data})
            if (response.status != 200 && response.status != 201) {
                notifier.show(requestMessage(response.data))
                return null
            }
            else {
                return response.data.data
            }
        }
        catch (err: any) {
            loader(false)
            notifier.show(erroMessage(catchErrMsg(err)))
            return null
        }
    }

    async function fetchUserRunningLoans () {
        try {
            loader(true)
            const response = await loanCaller.get(`LoanRequest/get-loan-requests/unpaid/${user.userData.customerId}`)
            loader(false)

            console.log({response: response.data})
            if (response.status != 200 && response.status != 201) {
                notifier.show(requestMessage(response.data))
                return null
            }
            else {
                return response.data.data
            }
        }
        catch (err: any) {
            loader(false)
            notifier.show(erroMessage(catchErrMsg(err)))
            return null
        }
    }

    async function fetchUserRepaidLoans () {
        try {
            loader(true)
            const response = await loanCaller.get(`LoanRequest/get-loan-requests/paid/${user.userData.customerId}`)
            loader(false)

            console.log({response: response.data})
            if (response.status != 200 && response.status != 201) {
                notifier.show(requestMessage(response.data))
                return null
            }
            else {
                return response.data.data
            }
        }
        catch (err: any) {
            loader(false)
            notifier.show(erroMessage(catchErrMsg(err)))
            return null
        }
    }

    async function fetchOfferBorrowers (offerId: number) {
        try {
            loader(true)
            const response = await loanCaller.get(`LoanOffer/get-borrowers-for-loan-offer/${offerId}`)
            loader(false)

            console.log({response: response.data})
            if (response.status != 200 && response.status != 201) {
                notifier.show(requestMessage(response.data))
                return null
            }
            else {
                return response.data.data
            }
        }
        catch (err: any) {
            loader(false)
            notifier.show(erroMessage(catchErrMsg(err)))
            return null
        }
    }

    async function endPortfolio (offerId: number) {
        try {
            loader(true)
            const response = await loanCaller.delete(`LoanOffer/delete-loan-offer/${offerId}`)
            loader(false)

            console.log({response: response.data})
            if (response.status != 200 && response.status != 201) {
                notifier.show(requestMessage(response.data))
                return null
            }
            else {
                responder.show(
                    'You have ended this portfolio.', 
                    'You have ended this portfolio. No one will be able to see or request this portfolio. However, Loans requested from this portfolio still runs until they are fully cleared.',
                    'success',
                    'continue',
                    '/dashboard/loan'
                )
                return null
            }
        }
        catch (err: any) {
            loader(false)
            notifier.show(erroMessage(catchErrMsg(err)))
            return null
        }
    }

    async function fetchAmountToRepay () {
        try {
            loader(true)
            const response = await loanRepaymentCaller.get(`GetTotalAmountToBePaid/${user.userData.customerId}`)
            loader(false)

            console.log({response: response.data})
            if (response.status != 200 && response.status != 201) {
                notifier.show(requestMessage(response.data))
                return null
            }
            else {
                if (response.data.data) return response.data.data
                else return null
            }
        }
        catch (err: any) {
            loader(false)
            notifier.show(erroMessage(catchErrMsg(err)))
            return null
        }
    }

    async function getWalletAccount () {
        try {
            loader(true)
            const response = await walletCaller.get(`account/GetWalletDetailsByCustomerId/${user.userData.customerId}`)
            
            console.log({response: response.data})
            if (response.status != 200 && response.status != 201) {
                loader(false)
                //notifier.show(requestMessage(response.data))
                return null
            }
            else {
                await setUserProperty('walletData', {...response.data.data})
                loader(false)
                return response.data.data
            }
        }
        catch (err: any) {
            loader(false)
            //notifier.show(erroMessage(catchErrMsg(err)))
            return null
        }
    }

    async function getUserDetails () {
        try {
            loader(true)
            const response = await profileCaller.get(`get-user-details/${user.userData.userId}`)
            loader(false)

            console.log({response: response.data})
            if (response.status != 200 && response.status != 201) {
                notifier.show(requestMessage(response.data))
                return null
            }
            else {
                let userInfo = {...response.data.data}
                userInfo.primaryBankAccount = helpers.getPrimaryAccountDetails(userInfo)
                
                await setUserProperty('formattedUserData', {...userInfo})
                await setUserProperty('onBoardingStatus', utility.getPercCompleted(userInfo))
                return userInfo
            }
        }
        catch (err: any) {
            loader(false)
            notifier.show(erroMessage(catchErrMsg(err)))
            return null
        }
    }

    async function fetchTransactions () {
        try {
            loader(true)
            const response = await walletCaller.get(`transaction/GetTransactionsByCustomerId/${user.userData.customerId}`)
            loader(false)

            console.log({response: response.data})
            if (response.status != 200 && response.status != 201) {
                //notifier.show(response.data.message)
                return null
            }
            else {
                await setInfoProperty('transactions', response.data?.data) // reversed before
                return response.data?.data
            }
        }
        catch (err: any) {
            loader(false)
            //notifier.show(`Something went wrong ${err.response?.data?.message}`)
            return null
        }
    }

    async function fetchTransactionsByDate ({startDate, endDate}: any) {
        let customerId = user.userData.customerId
        try {
            loader(true)
            const response = await walletCaller.post("transaction/GetTransactionsHistoryByDateRange", {customerId, startDate, endDate})
            loader(false)

            console.log({response: response.data})
            if (response.status != 200 && response.status != 201) {
                //notifier.show(response.data.message)
                return null
            }
            else {
                return response.data?.data
            }
        }
        catch (err: any) {
            loader(false)
            //notifier.show(`Something went wrong ${err.response?.data?.message}`)
            return null
        }
    }

    async function repayLoan (amount: number) {
        let referenceId = loan.currentLoans?.loanRequest?.loanReferenceId

        try {
            loader(true)
            const response = await loanRepaymentCaller.get(`PayLoan/${referenceId}/${amount}`)
            loader(false)

            console.log({response: response.data})
            if (response.status != 200 && response.status != 201) {
                notifier.show(requestMessage(response.data))
                return null
            }
            else {
                await getCurrentLoans()
                await getInfoForCharts()
                if (response.data.data) {
                    await getWalletAccount()
                    responder.show(
                        'Loan repaid successfully', 
                        'You have successfully paid your loan.',
                        'success',
                        'Continue',
                        '/dashboard/loan'
                    )
                    return true
                }
                else {
                    notifier.show(requestMessage(response.data))
                    return null
                }
            }
        }
        catch (err: any) {
            loader(false)
            notifier.show(erroMessage(catchErrMsg(err)))
            return null
        }
    }

    async function generateStatement (payload: any) {
        try {
            loader(true)
            const response = await walletCaller.post("transaction/GenerateTransactionsReport", {...payload, customerId: user.userData.customerId})
            loader(false)

            console.log({response: response.data})
            if (response.status != 200 && response.status != 201) {
                notifier.show(requestMessage(response.data))
                return null
            }
            else {
                responder.show(
                    'Statement Generated', 
                    'Your statement has been generated and sent to your email address.',
                    'statement'
                )
                return true
            }
        }
        catch (err: any) {
            loader(false)
            notifier.show(erroMessage(catchErrMsg(err)))
            return null
        }
    }

    async function getNextOfKinDetails () {
        try {
            loader(true)
            const response = await profileCaller.get(`get-nextofkin/${user.userData?.userId}`)
            loader(false)

            console.log({response: response.data})
            if (response.status != 200 && response.status != 201) {
                //notifier.show(response.data.message)
                return null
            }
            else {
                return response.data.data
            }
        }
        catch (err: any) {
            loader(false)
            //notifier.show(`Something went wrong: ${err.response?.data?.message}`)
            return null
        }
    }

    async function updateNextOfKinDetails (payload: any) {
        try {
            loader(true)
            const response = await profileCaller.post(`update-nextOfKin/${user.userData?.userId}`, payload)
            loader(false)

            console.log({response: response.data})
            if (response.status != 200 && response.status != 201) {
                notifier.show(requestMessage(response.data))
                return null
            }
            else {
                responder.show(
                    'Next of Kin details updated successfully', 
                    'Your next of kin details was updated succesfully.',
                    'card',
                    'continue',
                    '/dashboard/settings/home'
                )
                return response.data.data
            }
        }
        catch (err: any) {
            loader(false)
            notifier.show(erroMessage(catchErrMsg(err)))
            return null
        }
    }

    async function withdrawFund (amount: number, charge: number) {
        const payload = {
            name: user.formattedUserData.primaryBankAccount?.accountName,
            customerId: user.userData.customerId,
            bankAccountNumber: user.formattedUserData.primaryBankAccount?.accountNumber,
            bankCode: user.formattedUserData.primaryBankAccount?.bankCode,
            bankName: user.formattedUserData.primaryBankAccount?.bank,
            amount,
            charge
        }
        try {
            loader(true)
            const response = await walletCaller.post("transaction/WithdrawToBankAccount", payload)
            loader(false)

            console.log({response: response.data})
            if (response.status != 200 && response.status != 201) {
                notifier.show(requestMessage(response.data))
                return null
            }
            else {
                await getWalletAccount()
                responder.show(
                    'Withdrawal Successful', 
                    'Your withdrawal was successful',
                    'withdraw',
                    'Go to Home',
                    '/dashboard/home'
                )
                return response.data.data
            }
        }
        catch (err: any) {
            loader(false)
            notifier.show(erroMessage(catchErrMsg(err)))
            return null
        }
    }

    async function getLoanRequestsOnUserOffers () {
        try {
            loader(true)
            const response = await loanCaller.get(`LoanOffer/GetLoanRequestsForUser/${user.userData.customerId}`)
            loader(false)

            console.log({response: response.data})
            if ((response.status != 200 && response.status != 201) || !response.data.status) {
                notifier.show(requestMessage(response.data))
                return null
            }
            else {
                return response.data.data
            }
        }
        catch (err: any) {
            loader(false)
            notifier.show(erroMessage(catchErrMsg(err)))
            return null
        }
    }

    async function getStatusOfLoanOffersApplied () {
        try {
            loader(true)
            const response = await loanCaller.get(`LoanRequest/GetStatusOfLoanOffersApplied/${user.userData.customerId}`)
            loader(false)

            console.log({response: response.data})
            if ((response.status != 200 && response.status != 201) || !response.data.status) {
                notifier.show(requestMessage(response.data))
                return null
            }
            else {
                return response.data.data
            }
        }
        catch (err: any) {
            loader(false)
            notifier.show(erroMessage(catchErrMsg(err)))
            return null
        }
    }

    async function rejectLoanRequest (loanOfferId: number, loanRequestId: number, reason: string) {
        try {
            loader(true)
            const response = await loanCaller.post(`LoanOffer/RejectLoanRequest/${loanOfferId}/${loanRequestId}`, {reason})
            loader(false)

            console.log({response: response.data})
            if ((response.status != 200 && response.status != 201) || !response.data.status) {
                notifier.show(requestMessage(response.data))
                return null
            }
            else {
                await updateNotificationStatus()
                responder.show(
                    'Loan request ignored', 
                    'You have ignored this Loan request. A notification of your rejection will be sent to the Borrower.',
                    'success',
                    'Go to Home',
                    '/dashboard/home'
                )
                return response.data.status
            }
        }
        catch (err: any) {
            loader(false)
            notifier.show(erroMessage(catchErrMsg(err)))
            return null
        }
    }

    async function acceptLoanRequest (loanOfferId: number, loanRequestId: number) {
        try {
            loader(true)
            const response = await loanCaller.get(`LoanOffer/AcceptLoanRequest/${loanOfferId}/${loanRequestId}`)
            loader(false)

            console.log({response: response.data})
            if ((response.status != 200 && response.status != 201) || !response.data.status) {
                notifier.show(requestMessage(response.data))
                return null
            }
            else {
                await getWalletAccount()
                await updateNotificationStatus()
                responder.show(
                    'Offer Has Been Issued to Borrower', 
                    'You have accepted this loan request. A notification of your acceptance will be sent to the borrower. ',
                    'success',
                    'Go to Home',
                    '/dashboard/home'
                )
                return response.data?.status
            }
        }
        catch (err: any) {
            loader(false)
            notifier.show(erroMessage(catchErrMsg(err)))
            if (err?.response?.data?.messageString.includes('Insufficient fund')) return 'fund portfolio'
            else return null
        }
    }

    async function GroupLoanRequestsByLoanType () {
        try {
            loader(true)
            const response = await loanCaller.get(`LoanRequest/GroupLoanRequestsByLoanType`)
            loader(false)

            console.log({response: response.data})
            if ((response.status != 200 && response.status != 201) || !response.data.status) {
                notifier.show(requestMessage(response.data))
                return null
            }
            else {
                await setInfoProperty('quickRequest', {...info.quickRequest, data: response.data?.data})
                return response.data?.status
            }
        }
        catch (err: any) {
            loader(false)
            notifier.show(erroMessage(catchErrMsg(err)))
            return null
        }
    }

    async function GroupLoanRequestsByTenor () {
        try {
            loader(true)
            const response = await loanCaller.get(`LoanRequest/GroupLoanRequestsByTenor`)
            loader(false)

            console.log({response: response.data})
            if ((response.status != 200 && response.status != 201) || !response.data.status) {
                notifier.show(requestMessage(response.data))
                return null
            }
            else {
                await setInfoProperty('quickRequest', {...info.quickRequest, data: response.data?.data})
                return response.data?.status
            }
        }
        catch (err: any) {
            loader(false)
            notifier.show(erroMessage(catchErrMsg(err)))
            return null
        }
    }

    async function GroupLoanRequestsByInterest () {
        try {
            loader(true)
            const response = await loanCaller.get(`LoanRequest/GroupLoanRequestsByInterest`)
            loader(false)

            console.log({response: response.data})
            if ((response.status != 200 && response.status != 201) || !response.data.status) {
                notifier.show(requestMessage(response.data))
                return null
            }
            else {
                await setInfoProperty('quickRequest', {...info.quickRequest, data: response.data?.data})
                return response.data?.status
            }
        }
        catch (err: any) {
            loader(false)
            notifier.show(erroMessage(catchErrMsg(err)))
            return null
        }
    }

    async function GroupLoanRequestsByRateType () {
        try {
            loader(true)
            const response = await loanCaller.get(`LoanRequest/GroupLoanRequestsByRateType`)
            loader(false)

            console.log({response: response.data})
            if ((response.status != 200 && response.status != 201) || !response.data.status) {
                notifier.show(requestMessage(response.data))
                return null
            }
            else {
                await setInfoProperty('quickRequest', {...info.quickRequest, data: response.data?.data})
                return response.data?.status
            }
        }
        catch (err: any) {
            loader(false)
            notifier.show(erroMessage(catchErrMsg(err)))
            return null
        }
    }


    async function GroupLoanOffersByLoanType () {
        try {
            loader(true)
            const response = await loanCaller.get(`LoanOffer/GroupLoanOffersByLoanType`)
            loader(false)

            console.log({response: response.data})
            if ((response.status != 200 && response.status != 201) || !response.data.status) {
                notifier.show(requestMessage(response.data))
                return null
            }
            else {
                await setInfoProperty('quickRequest', {...info.quickRequest, data: response.data?.data})
                return response.data?.status
            }
        }
        catch (err: any) {
            loader(false)
            notifier.show(erroMessage(catchErrMsg(err)))
            return null
        }
    }

    async function GroupLoanOffersByTenor () {
        try {
            loader(true)
            const response = await loanCaller.get(`LoanOffer/GroupLoanOffersByTenor`)
            loader(false)

            console.log({response: response.data})
            if ((response.status != 200 && response.status != 201) || !response.data.status) {
                notifier.show(requestMessage(response.data))
                return null
            }
            else {
                await setInfoProperty('quickRequest', {...info.quickRequest, data: response.data?.data})
                return response.data?.status
            }
        }
        catch (err: any) {
            loader(false)
            notifier.show(erroMessage(catchErrMsg(err)))
            return null
        }
    }

    async function GroupLoanOffersByInterest () {
        try {
            loader(true)
            const response = await loanCaller.get(`LoanOffer/GroupLoanOffersByInterest`)
            loader(false)

            console.log({response: response.data})
            if ((response.status != 200 && response.status != 201) || !response.data.status) {
                notifier.show(requestMessage(response.data))
                return null
            }
            else {
                await setInfoProperty('quickRequest', {...info.quickRequest, data: response.data?.data})
                return response.data?.status
            }
        }
        catch (err: any) {
            loader(false)
            notifier.show(erroMessage(catchErrMsg(err)))
            return null
        }
    }

    async function GroupLoanOffersByRateType () {
        try {
            loader(true)
            const response = await loanCaller.get(`LoanOffer/GroupLoanOffersByRateType`)
            loader(false)

            console.log({response: response.data})
            if ((response.status != 200 && response.status != 201) || !response.data.status) {
                notifier.show(requestMessage(response.data))
                return null
            }
            else {
                await setInfoProperty('quickRequest', {...info.quickRequest, data: response.data?.data})
                return response.data?.status
            }
        }
        catch (err: any) {
            loader(false)
            notifier.show(erroMessage(catchErrMsg(err)))
            return null
        }
    }

    async function getCurrentLoans (canLoad = true) {
        try {
            canLoad && loader(true)
            const response = await loanCaller.get(`LoanRequest/GetLastLoanDetail/${user.userData.customerId}`)
            

            console.log({response: response.data})
            if (response.status != 200 && response.status != 201) {
                //notifier.show(response.data.messageString)
                loader(false)
                console.log({currentloanData: 'failed'})
                return null
            }
            else {
                await setLoanProperty('currentLoans', {...response.data?.data})
                loader(false)
                if (response.data?.data?.running) getLoanRequestInfo(response.data?.data?.loanRequest?.loanRequestId, canLoad) // just added to get all info about the running loan
                return response.data?.data
            }
        }
        catch (err: any) {
            loader(false)
            //notifier.show(erroMessage(catchErrMsg(err)))
            return null
        }
    }

    async function getLoanRequestInfo (loanRequestId: number, canLoad = true) {
        try {
            canLoad && loader(true)
            const response = await loanCaller.get(`LoanRequest/get-loan-request/${loanRequestId}`)

            console.log({response: response.data})
            if ((response.status != 200 && response.status != 201) || !response.data.status) {
                loader(false)
                //notifier.show(requestMessage(response.data))
                return null
            }
            else {
                await setLoanProperty('currentLoanInfo', {...response.data?.data})
                loader(false)
                return response.data?.data
            }
        }
        catch (err: any) {
            loader(false)
            //notifier.show(erroMessage(catchErrMsg(err)))
            return null
        }
    }

    async function cancelOpenLoanRequest (loanRequestId: number) {
        try {
            loader(true)
            const response = await loanCaller.get(`LoanRequest/CancelLoanRequest/${loanRequestId}`)
            loader(false)

            console.log({response: response.data})
            if ((response.status != 200 && response.status != 201) || !response.data.status) {
                notifier.show(requestMessage(response.data))
                return null
            }
            else {
                await setLoanProperty('loanIdB4Cancel', loanRequestId)
                runBackgroundService('current-loan-update', 1000)
                return response.data?.status
            }
        }
        catch (err: any) {
            loader(false)
            //notifier.show(erroMessage(catchErrMsg(err)))
            return null
        }
    }

    async function createLoanRequestReplica (loanRequestId: number) {
        try {
            loader(true)
            const response = await loanCaller.get(`LoanRequest/CreateLoanRequestReplica/${loanRequestId}`)
            loader(false)

            console.log({response: response.data})
            if ((response.status != 200 && response.status != 201) || !response.data.status) {
                notifier.show(requestMessage(response.data))
                return null
            }
            else {
                await setLoanProperty('proposedLoanRequest', response.data.data)
                access.setProposedLoan(response.data.data)
                await setLoanProperty('currentProcessType', 'borrower') 
                await getCurrentLoans()
                await getUserOverallScore() // get updated crendly score
                //await getRequestLoanOffers (response.data.data?.customerId || user.userData.customerId)
                if (!access.getCallDebounce()) waitingScreen.show()
                return response.data?.data
            }
        }
        catch (err: any) {
            loader(false)
            notifier.show(erroMessage(catchErrMsg(err)))
            return null
        }
    }

    async function getOfferProspects (loanOfferId: number) {
        try {
            loader(true)
            const response = await loanCaller.get(`LoanOffer/GetProspectsForLoanOffer/${loanOfferId}`)
            loader(false)

            console.log({response: response.data})
            if ((response.status != 200 && response.status != 201) || !response.data.status) {
                notifier.show(requestMessage(response.data))
                return null
            }
            else {
                await setLoanProperty('offerBorrowers', [...response.data.data])
                return response.data?.data
            }
        }
        catch (err: any) {
            loader(false)
            notifier.show(erroMessage(catchErrMsg(err)))
            return null
        }
    }

    async function fundPortfolio (loanOfferId: number, amount: number) {
        try {
            loader(true)
            const response = await loanCaller.post(`LoanOffer/FundPortfolio/${loanOfferId}/${amount}`)
            loader(false)

            console.log({response: response.data})
            if ((response.status != 200 && response.status != 201) || !response.data.status) {
                notifier.show(requestMessage(response.data))
                if (response.data.messageString === 'Insufficient cash in Balance, Fund Wallet and Try Again.') return 'fund-wallet'
                return null
            }
            else {
                await Promise.all([getWalletAccount(), fetchAllUserOffers(), fetchUserRunningOffers()])
                responder.show(
                    'You have successfully funded your portfolio', 
                    'Nice. You have funded your portfolio',
                    'success',
                    'Continue',
                    'close'
                )
                return response.data?.status
            }
        }
        catch (err: any) {
            loader(false)
            notifier.show(erroMessage(catchErrMsg(err)))
            return null
        }
    }

    async function fetchNotifications () {
        try {
            loader(true)
            const response = await notificationCaller.post(`Notification/GetAllUserNotifications?UserId=${user.userData?.userId}`)
            loader(false)

            console.log({response: response.data})
            if ((response.status != 200 && response.status != 201) || !response.data.status) {
                //notifier.show(requestMessage(response.data))
                return null
            }
            else {
                await setUserProperty('notifications', response.data?.data)
                return response.data?.data
            }
        }
        catch (err: any) {
            loader(false)
            //notifier.show(erroMessage(catchErrMsg(err)))
            return null
        }
    }

    async function updateNotificationStatus () {
        if (user?.notifId) {
            try {
                loader(true)
                const response = await notificationCaller.post(`Notification/UpdateNotification?RefId=${user?.notifId}`)
                loader(false)
    
                console.log({response: response.data})
                if ((response.status != 200 && response.status != 201) || !response.data.status) {
                    //notifier.show(requestMessage(response.data))
                    return null
                }
                else {
                    setUserProperty('notifId', 0)
                    await fetchNotifications()
                    return true
                }
            }
            catch (err: any) {
                loader(false)
                //notifier.show(erroMessage(catchErrMsg(err)))
                return null
            }
        }
        else return false
        
    }

    async function submitFeedback (ratingReceived: number, comment: string) {
        const payload = {
            userId: user.userData.userId,
            ratingReceived,
            comment
        }
        try {
            loader(true)
            const response = await ratingCaller.post(`RatePlatform`, payload)
            loader(false)

            console.log({response: response.data})
            if (response.status != 200 && response.status != 201) {
                notifier.show(requestMessage(response.data))
            }
            else {
                responder.show(
                    'Your feedback and review has been submitted', 
                    '',
                    'success',
                    'continue',
                    '/dashboard/settings/home'
                )
            }
        }
        catch (err: any) {
            loader(false)
            notifier.show(erroMessage(catchErrMsg(err)))
        }
    }

    async function rejectLoanOffer (loanOfferId: number, loanRequestId: number, reason: string) {
        try {
            loader(true)
            const response = await loanCaller.post(`LoanRequest/RejectLoanOffer/${loanOfferId}/${loanRequestId}`, {reason})
            loader(false)

            console.log({response: response.data})
            if (response.status != 200 && response.status != 201) {
                notifier.show(requestMessage(response.data))
                return null
            }
            else {
                await updateNotificationStatus()
                responder.show(
                    'You have rejected this loan offer', 
                    'A notification of your rejection will be sent to the Lender',
                    'cancel',
                    'Go to Home',
                    '/dashboard/home'
                )
                return true
            }
        }
        catch (err: any) {
            loader(false)
            notifier.show(erroMessage(catchErrMsg(err)))
            return null
        }
    }

    async function acceptLoanOffer (loanOfferId: number, loanRequestId: number) {
        try {
            loader(true)
            const response = await loanCaller.get(`LoanRequest/AcceptLoanOffer/${loanOfferId}/${loanRequestId}`)
            loader(false)

            console.log({response: response.data})
            if (response.status != 200 && response.status != 201) {
                notifier.show(requestMessage(response.data))
            }
            else {
                await getCurrentLoans()
                await getWalletAccount()
                await updateNotificationStatus()
                responder.show(
                    'You have accepted this loan offer', 
                    "Let's goooo....",
                    'success',
                    'Go to Home',
                    '/dashboard/home'
                )
                return null
            }
        }
        catch (err: any) {
            loader(false)
            notifier.show(erroMessage(catchErrMsg(err)))
            return null
        }
    }

    async function acceptNegotiation (loanNegotiationId: number) {
        try {
            loader(true)
            const response = await loanCaller.get(`LoanOffer/AcceptNegotiationTerms/${loanNegotiationId}`)
            loader(false)

            console.log({response: response.data})
            if (response.status != 200 && response.status != 201) {
                notifier.show(requestMessage(response.data))
                return null
            }
            else {
                updateNotificationStatus()
                // responder.show(
                //     'Negotiation request accepted', 
                //     "You have accepted this negotiation request. A notification of your acceptance will be sent to the Borrower. ",
                //     'success',
                //     '',
                //     ''
                // )

                return true
            }
        }
        catch (err: any) {
            loader(false)
            notifier.show(erroMessage(catchErrMsg(err)))
        }
    }

    async function rejectNegotiation (loanNegotiationId: number, reason: string) {
        try {
            loader(true)
            const response = await loanCaller.post(`LoanOffer/RejectLoanNegotiation/${loanNegotiationId}`, {reason})
            loader(false)

            console.log({response: response.data})
            if (response.status != 200 && response.status != 201) {
                notifier.show(requestMessage(response.data))
            }
            else {
                updateNotificationStatus()
                responder.show(
                    'Negotiation request rejected', 
                    "You have rejected this negotiation request. A notification of your rejection will be sent to the Borrower. ",
                    'success',
                    '',
                    ''
                )
            }
        }
        catch (err: any) {
            loader(false)
            notifier.show(erroMessage(catchErrMsg(err)))
        }
    }

    async function cancelPortfolio (loanOfferId: number) {
        try {
            loader(true)
            const response = await loanCaller.delete(`LoanOffer/delete-loan-offer/${loanOfferId}`)
            loader(false)

            console.log({response: response.data})
            if (response.status != 200 && response.status != 201) {
                notifier.show(requestMessage(response.data))
                return null
            }
            else {
                notifier.show('Portfolio successfully deleted', 'success')
                return response.data.messageString || response.data.status
            }
        }
        catch (err: any) {
            loader(false)
            notifier.show(erroMessage(catchErrMsg(err)))
            return null
        }
    }

    async function editPortfolio (loanOfferId: number) {
        const payload = {
            ...loan.loanCreation
        }

        try {
            loader(true)
            const response = await loanCaller.put(`LoanOffer/edit-loan-offer/${loanOfferId}`, payload)
            loader(false)

            console.log({response: response.data})
            if (response.status != 200 && response.status != 201) {
                notifier.show('Loan offer could not be created')
                return null
            }
            else {
                await setLoanProperty('currentProcessType', 'lender') // wether loan request or loan offer
                await getCurrentLoans() // To update the dashboard with expected loan card to display
                responder.show(
                    'You have successfully edited the loan offer', 
                    'Nice, You have updated the portfolio.',
                    'success',
                    'Continue',
                    '/dashboard/loan'
                )
                access.getNavigationAccess()('/dashboard/home')
                return true
            }
        }
        catch (err: any) {
            loader(false)
            notifier.show(erroMessage(catchErrMsg(err)))
            return null
        }
    }

    async function getInfoForCharts () {
        try {
            loader(true)
            const response = await loanCaller.get(`Loan/GetLoanStats/${user.userData.customerId}`)
            loader(false)

            console.log({response: response.data})
            if (response.status != 200 && response.status != 201) {
                //notifier.show(requestMessage(response.data))
                return null
            }
            else {
                await setUserProperty('chartsInfo', response.data?.data)
                return response.data.status
            }
        }
        catch (err: any) {
            loader(false)
            return null
        }
    }

    async function getUserOverallScore () {
        try {
            loader(true)
            const response = await decisionCaller.get(`OverallScoring/GetOverallScore-Foruser/${user.userData.customerId}`)
            loader(false)

            console.log({response: response.data})
            if (response.status != 200 && response.status != 201) {
                //notifier.show(requestMessage(response.data))
                return null
            }
            else {
                await setUserProperty('overallScore', response.data?.data)
                return response.data.status
            }
        }
        catch (err: any) {
            loader(false)
            return null
        }
    }

    async function getCharges (amount: number, interest: number, transactionType: string, InterestPayable: number) {
        try {
            loader(true)
            const response = await chargesCaller.get(`GetPlatformCharge`, {
                params: {
                    amount,
                    interest,
                    transactionType,
                    InterestPayable
                }
            })
            loader(false)

            console.log({response: response.data})
            if (response.status != 200 && response.status != 201) {
                notifier.show(requestMessage(response.data))
                return null
            }
            else {
                return response.data.data
            }
        }
        catch (err: any) {
            loader(false)
            //notifier.show(erroMessage(catchErrMsg(err)))
            return null
        }
    }

    async function refreshToken (shouldTriggerRefresh = true) {
        try {
            loader(true)
            const {status, data} = await authCaller.post(`refresh_token`, {
                accessToken: info.token,
                refreshToken: info.refreshToken
            })
            loader(false)

            loglite(data, 'refreshTtoken')
            if ((status != 200 && status != 201) || !data.status) {
                logout()
            }
            else {
                await setInfoProperty('refreshToken', data.data?.refreshToken)
                await setInfoProperty('token', data.data?.accessToken)
                if (shouldTriggerRefresh) await setInfoProperty('refreshTokenTrigger', !info.refreshTokenTrigger)
                return true
            }
        }
        catch (err: any) {
            loader(false)
            logout()
        }
    }

    async function clearUserNotifications () {
        try {
            loader(true)
            const response = await notificationCaller.post(`Notification/ClearAllNotification?UserID=${user.userData?.userId}`)
            loader(false)

            console.log({response: response.data})
            if ((response.status != 200 && response.status != 201) || !response.data.status) {
                notifier.show(requestMessage(response.data || "Notification(s) could not be cleared"))
                return null
            }
            else {
                setUserProperty('notifId', 0)
                await setUserProperty('notifications', [])
                notifier.show('Notification(s) cleared successfully', 'success')
                return true
            }
        }
        catch (err: any) {
            loader(false)
            notifier.show(erroMessage(catchErrMsg(err)))
            return null
        }
    }

    async function setPrimaryBankAccount (account: IBankAccounts) {
        try {
            loader(true)
            const {status, data} = await identityCaller.post('MakeBankAccountPrimaryAccount', {
                userId: user.userData?.userId,
                accountNumber: account.accountNumber,
                bankCode: account.bankCode
            })
            loader(false)

            loglite(data, 'setPrimaryBankAccount')
            if (status != 200 && status != 201) {
                notifier.show(requestMessage(data))
                return null
            }
            else {
                await getUserDetails()
                notifier.show('Bank Account Successfully Set as Primary Account', 'success')
                return true
            }
        }
        catch (err: any) {
            loader(false)
            notifier.show(erroMessage(catchErrMsg(err)))
            return null
        }
    }

    async function deleteBankAccount (account: IBankAccounts) {
        try {
            loader(true)
            const {status, data} = await identityCaller.post('DeleteAccountNumber', {
                userId: user.userData?.userId,
                accountNumber: account.accountNumber,
                bankCode: account.bankCode
            })
            loader(false)

            loglite(data, 'deleteBankAccount')
            if (status != 200 && status != 201) {
                notifier.show(requestMessage(data))
                return null
            }
            else {
                await getUserDetails()
                notifier.show('Bank Account Successfully Deleted', 'success')
                return true
            }
        }
        catch (err: any) {
            loader(false)
            notifier.show(erroMessage(catchErrMsg(err)))
            return null
        }
    }

    async function deleteAccount (reason: string) {
        try {
            loader(true)
            const {status, data} = await authCaller.post('DeleteMyAccount', {reason})
            loader(false)

            loglite(data, 'deleteAccount')
            if (status != 200 && status != 201) {
                notifier.show(requestMessage(data))
                return null
            }
            else {
                notifier.show(data.message || 'Account Successfully Deleted', 'success')
                return true
            }
        }
        catch (err: any) {
            loader(false)
            notifier.show(erroMessage(catchErrMsg(err)))
            return null
        }
    }

    async function deactivateAccount (reason: string) {
        try {
            loader(true)
            const {status, data} = await authCaller.post('DeactivateMyAccount', {reason})
            loader(false)

            loglite(data, 'deactivateAccount')
            if (status != 200 && status != 201) {
                notifier.show(requestMessage(data))
                return null
            }
            else {
                notifier.show(data.message || 'Account Successfully Closed', 'success')
                return true
            }
        }
        catch (err: any) {
            loader(false)
            notifier.show(erroMessage(catchErrMsg(err)))
            return null
        }
    }

    async function runBackgroundService (type: string | Array<string>, waitTime = appSettings.BACKGROUND_JOB_WAIT_TIME) {
        try {
            if (typeof type === 'string') type = [type]

            for (let service of type) {
                await wait(waitTime)
                console.log({currentBackgroundService: service})
                if (service === 'current-loan-update') await getCurrentLoans(false)
                if (service === 'charts-info-update') await getInfoForCharts()
                if (service === 'remove-calls-debounce') access.setCallDebounce(false)
                if (service === 'get-refresh-token') await refreshToken()
            }
        }
        catch (err) {
            console.log({backgroundService: err})
        }
    }

    const wait = (time: number) => new Promise(res => setTimeout(res, time))

    async function createPorfolio () {
        responder.show(
            '', 
            '',
            'negotiation'
        )
        return null
    }

    async function addCard () {
        responder.show(
            'Card Linked Successfully', 
            'Your card was linked succesfully.',
            'card',
            'Go to Home',
            '/dashboard/home'
        )
        return null
    }

    async function ignoreLoanRequest () {
        responder.show(
            'Loan request ignored', 
            'You have ignored this Loan request. A notification of your rejection will be sent to the Borrower.',
            'success'
        )
        return null
    }

    async function retakeLoan () {
        responder.show(
            'Your loan request has been succeessfully submited.', 
            'We will send a notification of your interest in his loan offer. You  ill get notified when he responds.',
            'loan-request',
            'Go to Home',
            '/dashboard/loan'
        )
        return null
    }

    async function deleteBankData () {
        responder.show(
            'Bank deleted successfully', 
            'Your bank account was deleted successfully',
            'delete',
            'continue',
            '/dashboard/payment'
        )
        return null
    }

    async function deleteCardData () {
        responder.show(
            'Card deleted successfully', 
            'Your card was deleted successfully',
            'delete',
            'continue',
            '/dashboard/payment'
        )
        return null
    }

    async function joinWaitlist () {
        responder.show(
            'Thank you for joining the Crendly business waitlist.', 
            'We will keep in touch and let you know thw latest development on Crendly Business.',
            'join'
        )
        return null
    }


    const callActions = {
        exploreResponse,
        signUp,
        updateLastPageVisited,
        sendOtp_Inactivity,
        completeVerification_Inactivity,
        resendOtp,
        resendOtpViaEmail,
        passwordResetResendOtp,
        verifyOtp,
        verifyEmail,
        updateEmail,
        updateMarital_EducationalStatus,
        updateEmploymentDetails,
        getResidenceCategories,
        updateAddress,
        updateIdCard,
        updateSignature,
        getBankList,
        validateAccountNo,
        linkBank,
        createWalletAccount,
        createPasscode,
        createTransactionPin,
        verifyTransactionPin,
        signIn,
        uploadToCloudinary,
        forgetPasswordIdentification,
        resetPassword,
        getLoanTypes,
        calculateLoanRepayment,
        submitLoanRequest,
        topupWallet,
        initializeFunding,
        publishLoanPortfolio,
        filterLoanOffer,
        filterLoanRequest ,
        calculateRepaymentAmount,
        fetchTrustQuestions,
        fetchPersonalityQuestions,
        requestLoan,
        submitTrustAnswers,
        submitPersonalityAnswers,
        saveTrustAnswers,
        savePersonalityAnswers,
        getSavedTrustAnswers,
        getSavedPersonlityAnswers,
        sendNegotiation,
        fetchAllUserOffers,
        fetchUserRunningOffers,
        fetchUserEndedOffers,
        fetchAllUserLoans,
        fetchUserRunningLoans,
        fetchUserRepaidLoans,
        fetchOfferBorrowers,
        fetchAmountToRepay,
        createPorfolio,
        generateStatement,
        linkBankAccount,
        getWalletAccount,
        getUserDetails,
        getNextOfKinDetails,
        updateNextOfKinDetails,
        fetchTransactions,
        fetchTransactionsByDate,
        addCard,
        withdrawFund,
        rejectLoanOffer,
        acceptLoanOffer,
        rejectLoanRequest,
        ignoreLoanRequest,
        acceptLoanRequest,
        acceptNegotiation,
        rejectNegotiation,
        repayLoan,
        fundPortfolio,
        retakeLoan,
        endPortfolio,
        deleteBankData,
        deleteCardData,
        submitFeedback,
        changePasscode,
        changeTransactionPin,
        joinWaitlist,
        getRequestLoanOffers,
        getLoanRequestsOnUserOffers,
        getStatusOfLoanOffersApplied,
        GroupLoanRequestsByLoanType,
        GroupLoanRequestsByTenor,
        GroupLoanRequestsByInterest,
        GroupLoanRequestsByRateType,
        GroupLoanOffersByLoanType,
        GroupLoanOffersByTenor,
        GroupLoanOffersByInterest,
        GroupLoanOffersByRateType,
        getCurrentLoans,
        getLoanRequestInfo,
        cancelOpenLoanRequest,
        createLoanRequestReplica,
        getOfferProspects,
        getOfferLoanRequests,
        submitInterestInLoanRequest,
        handleLoanRejectionNotif,
        fetchNotifications,
        updateNotificationStatus,
        cancelPortfolio,
        editPortfolio,
        getInfoForCharts,
        getCreditDecisionResponse,
        updateLocationHistory,
        updateLoginHistory,
        getUserOverallScore,
        getCharges,
        runBackgroundService,
        refreshToken,
        Marital_EducationalStatusUpdate,
        clearUserNotifications,
        setPrimaryBankAccount,
        deleteBankAccount,
        deleteAccount,
        deactivateAccount
    }

    return (
        <ApiContext.Provider value={{API: callActions, getDeviceInfo}} >
            {props.children}
        </ApiContext.Provider>
    )
}

export default ApiContext