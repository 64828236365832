import { useEffect, useContext } from 'react'
import { useSearchParams, useNavigate } from 'react-router-dom';
import ApiContext from 'src/provider/API/call-service'
import AppInfoContext from 'src/provider/state-manager/appInfoProvider'
import UserContext from 'src/provider/state-manager/userProvider'
import utility from 'src/utils/utility'

const PaymentConfirmation = () => {
    const {API} = useContext(ApiContext)
    const [searchParams] = useSearchParams();
    const navigate = useNavigate()
    const {user: {userData}} = useContext(UserContext)
    const {info: {currentPaymentGateway, token}} = useContext(AppInfoContext)

    const reference = searchParams.get("reference")
    const status = searchParams.get("status")

    useEffect(() => {
        if (userData?.customerId && currentPaymentGateway && token) handleRequests()
    }, [userData?.customerId, currentPaymentGateway])

    async function handleRequests () {
        const response = await API.refreshToken(false)
        if (response) {
            confirmPayment()
        }
    }

    async function confirmPayment () {
        await API.topupWallet({reference, paymentGateway: currentPaymentGateway, customerId: userData?.customerId})
        navigate('/dashboard/home')
    }

    return (
        <></>
    )
}

export default utility.routeData('payment-confirmation/:id', 'PaymentConfirmation', <PaymentConfirmation/>)