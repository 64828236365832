import './style.scss'
import { useState, useEffect, useContext } from 'react'
//import { useNavigate } from 'react-router-dom'
import UserContext from 'src/provider/state-manager/userProvider'
import ApiContext from 'src/provider/API/call-service'
import utility from 'src/utils/utility'
import { MdDelete } from 'react-icons/md'
import { AbsoluteContainer, AppSpan, AppText, Button, Circle, CustomContainer, FlexColumn, FlexRow, GridContainer, Icon, OutlineButton, RoundImageContainer, ScreenContainer } from 'src/styles'
import { images } from 'src/provider/config/constant'
import { AddCard, AddBank, DeletePaymentData, DecisionBox } from 'src/popup'
import { IBankAccounts } from 'src/model'
import { BsThreeDotsVertical } from 'react-icons/bs'


const Payment = () => {
    const {user: {formattedUserData}} = useContext(UserContext)
    const {API} = useContext(ApiContext)
    const [bankAccounts, setBankAccounts] = useState<Array<IBankAccounts> | []>([])
    const [selectedAccount, setSelectedAccount] = useState<IBankAccounts>()
    const [infoType, setInfoType] = useState('bank')
    const [hasCard, setHasCard] = useState(false)
    const [showAddCard, setShowAddCard] = useState(false)
    const [showAddBank, setShowAddBank] = useState(false)
    const [showDeletePaymentData, setShowDeletePaymentData] = useState(false)
    const [shouldSetPryAcct, setShouldSetPryAcct] = useState(false)
    const [view, setView] = useState('')

    useEffect(() => {
        if(formattedUserData?.bankAccounts?.length) {
            setBankAccounts(formattedUserData?.bankAccounts)
        }
    }, [formattedUserData])

    useEffect(() => {
        if(showAddCard || showAddBank || showDeletePaymentData) {
          document.body.style.overflow = 'hidden'
        }
        else document.body.style.overflow = 'auto'
    }, [showAddCard, showAddBank, showDeletePaymentData])

    async function handleAccountOption (e: React.ChangeEvent<HTMLSelectElement>, account: IBankAccounts) {
        setSelectedAccount(account)
        setView(e.target.value) 

        e.target.value = ''
    }

    return (
        <>
            <ScreenContainer>
                <CustomContainer topMargin="2" rightPadding="2" leftPadding="2" className="trans-screen-container">
                    <div className="loan-info-kind-toggle">
                        <span 
                            style={{fontWeight: infoType === 'bank' ? 700 : 400}}
                            onClick={() => setInfoType('bank')}
                        >
                            Banks
                        </span>
                        <span 
                            style={{fontWeight: infoType === 'card' ? 700 : 400}}
                            onClick={() => setInfoType('card')}
                        >
                            Cards
                        </span>
                        <div className="loan-info-kind-shadow" style={{left: infoType === 'bank' ? 0 : '51%'}}></div>
                    </div>
                    { (infoType === 'card' && hasCard) ?
                        <AbsoluteContainer top="0" right="0" className="payment-abs-action-btn">
                            <OutlineButton 
                                color="#6DE7B4"
                                width="17"
                                height="4.5"
                                hoverBgColor="#FED0B7"
                                className="loan-action-outline-btn"
                                disabled={true}
                                onClick={() => setShowAddCard(true)}
                            >
                                Add Card
                            </OutlineButton>
                        </AbsoluteContainer> :
                        (infoType === 'bank' && !!bankAccounts?.length) ?
                        <AbsoluteContainer top="0" right="0" className="payment-abs-action-btn">
                            <OutlineButton 
                                color="#6DE7B4"
                                width="17"
                                height="4.5"
                                hoverBgColor="#FED0B7"
                                onClick={() => setShowAddBank(true)}
                            >
                                Add Bank
                            </OutlineButton>
                        </AbsoluteContainer>
                        : null
                    }

                    { (infoType === 'card' && !hasCard) &&
                        <FlexColumn topMargin="10" >
                            <Icon src={images.CARD_ICON} />
                            <AppText textSize="1.8" width="25" topMargin="3" align="center">No Cards Added</AppText>
                            <AppSpan color="#E2E2E2" textSize="1.3" fontWeight="400" topMargin="1" align="center" width="26">You do not have any cards added yet.</AppSpan>
                            <Button 
                                hoverBgColor="#FED0B7" 
                                width="30" 
                                height="5" 
                                titleSize="1.4"
                                topMargin="5"
                                className="loan-action-btn"
                                disabled={true}
                                onClick={() => setShowAddCard(true)}
                            >
                                Add Card
                            </Button>
                        </FlexColumn>
                    }
                    { (infoType === 'bank' && bankAccounts?.length === 0) &&
                        <FlexColumn topMargin="10" >
                            <Icon src={images.BANK_ICON} />
                            <AppText textSize="1.8" width="25" topMargin="3" align="center">No Bank Account Linked</AppText>
                            <AppSpan color="#E2E2E2" textSize="1.3" fontWeight="400" topMargin="1" align="center" width="26">You do not have any Bank account added yet.</AppSpan>
                            <Button 
                                hoverBgColor="#FED0B7" 
                                width="30" 
                                height="5" 
                                titleSize="1.4"
                                topMargin="5"
                                onClick={() => setShowAddBank(true)}
                            >
                                Add Bank Account
                            </Button>
                        </FlexColumn>
                    }
                    { (infoType === 'bank' && !!bankAccounts?.length) &&
                        <FlexRow 
                            justifyContent="flex-start" wrap="wrap" topMargin="6" 
                            className="center-card-item card-main-container"
                        >
                            {
                                bankAccounts?.map((account: IBankAccounts, index: number) => {
                                    return (
                                        <CustomContainer 
                                            bgColor="#4701E0" width='38' topPadding="2"
                                            leftPadding="1.5" rightPadding="1.5" bottomPadding="1.2"
                                            radius="0.8" rightMargin="1.5" bottomMargin="1.5"
                                            key={index}
                                        >
                                            <FlexRow justifyContent='space-between' bottomMargin='2.5'>
                                                <AppText textSize="1.4">{account.accountName}</AppText>
                                                <CustomContainer width='auto' size=''>
                                                    <select 
                                                        style={{position: 'absolute', top: '0', left: '0', zIndex: '3', opacity: '0'}}
                                                        onChange={(e) => handleAccountOption(e, account)}
                                                    >
                                                        <option value='' selected hidden></option>
                                                        <option 
                                                            value='SET_PRY_ACCT' 
                                                            disabled={account.isPrimaryAccount}
                                                        >
                                                            Set as Primary account
                                                        </option>
                                                        <option 
                                                            value='DELETE_ACCT' 
                                                        >
                                                            Delete Account
                                                        </option>
                                                    </select>
                                                    <BsThreeDotsVertical />
                                                </CustomContainer>
                                                {/* <RoundImageContainer size="6.4" bgColor="rgba(128, 149, 224, 0.2)">
                                                    <MdDelete 
                                                        style={{fontSize: '2rem', color: '#FED0B7'}} 
                                                        //onClick={() => setShowDeletePaymentData(true)} 
                                                    />
                                                </RoundImageContainer> */}
                                            </FlexRow>
                                            <FlexRow 
                                                justifyContent='space-between'
                                                gap='2' alignItems='flex-end'
                                            >
                                                <CustomContainer width='auto' sizeUnit=''>
                                                    <AppText fontWeight="400" textSize="1.2" bottomMargin=".5">{account.bank}</AppText>
                                                    <AppSpan fontWeight="400" textSize="1.2" color="#C4C4C4">{account.accountNumber}</AppSpan>
                                                </CustomContainer>
                                                { account.isPrimaryAccount &&
                                                    <GridContainer
                                                        bgColor='#081952' borderColor='#ffffff'
                                                        width='10' height='3' radius='2'
                                                    >
                                                        <AppSpan 
                                                            color='#ffffff' textSize='0.9'
                                                            fontWeight='400'
                                                        >
                                                            Primary Account
                                                        </AppSpan>
                                                    </GridContainer>
                                                }
                                            </FlexRow>
                                        </CustomContainer>
                                    )
                                })
                            }
                        </FlexRow>
                    }
                    { (infoType === 'card' && hasCard) &&
                        <FlexRow justifyContent="flex-start" wrap="wrap" topMargin="6" className="center-card-item card-main-container">
                            {
                                Array.from(Array(2).keys()).map((item: any, index: number) => {
                                    return (
                                        <CustomContainer 
                                            width='35' height="18" topPadding="1.7"
                                            leftPadding="1.5" rightPadding="1.5" bottomPadding="1.2"
                                            radius="0.8" rightMargin="1.5" bottomMargin="1.5"
                                            key={index}
                                        >
                                            <AbsoluteContainer 
                                                bgColor="#fff" top="0" left="0" 
                                                width="100" height="full" sizeUnit="%"
                                            >
                                                <Icon src={images.TRANSAPRENT_C}></Icon>
                                                <AbsoluteContainer top="1.5" left="1">
                                                    <Icon src={images.MASTERCARD_LOGO} />
                                                </AbsoluteContainer>
                                                <AbsoluteContainer bottom="1.2" left="1.5">
                                                    <AppText color="#080808" textSize="1.3">XXXX XXXX XXXX 7645</AppText>
                                                    <AppSpan color="#080808" textSize="1.3" fontWeight="400">02/23</AppSpan>
                                                    <AppSpan color="#080808" textSize="1.3" fontWeight="400" leftMargin="1.5">112</AppSpan>
                                                </AbsoluteContainer>
                                            </AbsoluteContainer>
                                            <AbsoluteContainer 
                                                    width="9" height="full"
                                                    top="0" right="0"
                                                    bgColor="radial-gradient(123.45% 84.23% at 90.24% 96.92%, #FD5454 0%, #FF9061 29.17%, #FFBA7B 53.33%, #FFCEC4 100%)"
                                                >
                                                <AbsoluteContainer top="1.5" right="1" onClick={() => setShowDeletePaymentData(true)}>
                                                    <MdDelete style={{fontSize: '2.2rem', color: 'rgba(0,0,0, 0.7'}} />
                                                </AbsoluteContainer>
                                                <AbsoluteContainer bottom="1.3" right="0.7">
                                                    <AppText color="#080808" textSize="0.8">Expires in 3 days</AppText>
                                                </AbsoluteContainer>
                                            </AbsoluteContainer>
                                        </CustomContainer>
                                    )
                                })
                            }
                        </FlexRow>
                    }
                </CustomContainer>
            </ScreenContainer>

            { showAddCard &&
                <AddCard close={setShowAddCard} hasCard={false} />
            }
            { showAddBank &&
                <AddBank close={setShowAddBank} hasBank={!!bankAccounts?.length} />
            }
            { showDeletePaymentData &&
                <DeletePaymentData close={() => setShowDeletePaymentData(false)} type={infoType} />
            }
            { view === 'SET_PRY_ACCT' &&
                <DecisionBox
                    close={() => setView('')}
                    title='Set Primary Bank Account'
                    subheading='Are you sure you want to set this account as your primary bank account?'
                    noText="No, Don't Set"
                    yesText="Yes, Set Account"
                    yesMethod={() => API.setPrimaryBankAccount(selectedAccount)}
                />
            }
            { view === 'DELETE_ACCT' &&
                <DecisionBox
                    close={() => setView('')}
                    title='Delete Bank Account'
                    subheading='Are you sure you want to delete this account?'
                    noText="No, Don't Delete"
                    yesText="Yes, Delete Account"
                    yesMethod={() => API.deleteBankAccount(selectedAccount)}
                />
            }
        </>
    )
}

export default utility.routeData('payment', 'Payment', <Payment/>)
