import { useState, useContext, useRef } from 'react'
import utility, { handleTypeNumberControl } from 'src/utils/utility'
import ApiContext from 'src/provider/API/call-service'
import { useNavigate } from 'react-router-dom'
import { Container } from 'src/component'
import { AppText, Button, CustomContainer, FlexRow, ScreenTitle } from 'src/styles'
import { BsChevronLeft, BsFillEyeFill, BsFillEyeSlashFill } from 'react-icons/bs'
import { images } from 'src/provider/config/constant'
import { NoAuthPopup } from 'src/popup'

const Passcode = () => {
    const navigate = useNavigate()
    const {API} = useContext(ApiContext)
    const [password, setPassword] = useState({pass: '', cPass: ''})
    const [showCode, setShowCode] = useState({passcode: false, confirmCode: false})
    const [showResponse, setShowResponse] = useState(false)

    const handlePassword = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.value.length < 7) {
            setPassword({...password, [e.target.name]: e.target.value})
        }
    }

    async function handleNext () {
        const response = await API.createPasscode(password.pass, password.cPass)
        if (response) setShowResponse(true)
    }

    return (
        <>
            <Container type="onboarding" progressBar='92.28'>
                <p className="right-align-text">Already have an account? 
                    <a className="registration-link" onClick={() => navigate('/login')}> Sign in</a>
                </p>
                <div className="onboarding-container">
                    <BsChevronLeft className="loan-back-icon" onClick={() => navigate(-1)} />
                    <ScreenTitle textSize="3" bottomMargin="1.5" topMargin="3" >
                        Finally, create a passcode
                    </ScreenTitle>
                    <AppText color="#D5D5D5" textSize="1.4" fontWeight="400" bottomMargin="2">
                        Create a 6 - digit passcode to login with into Crendly.
                    </AppText>
                    <CustomContainer topMargin="5">
                        <div className="form-group-wrapper">
                            <FlexRow bottomPadding='1' justifyContent='flex-start'>
                                <label className="">Passcode</label>  
                                { !showCode.passcode ?
                                    <BsFillEyeSlashFill 
                                        style={{marginLeft: '1.5rem', marginTop: '-0.5rem'}}
                                        onClick={() => setShowCode({...showCode, passcode: true})} 
                                    />
                                    : <BsFillEyeFill 
                                        style={{marginLeft: '1.5rem', marginTop: '-0.5rem'}}
                                        onClick={() => setShowCode({...showCode, passcode: false})} 
                                    />
                                }
                            </FlexRow>
                            <div className="passcode-input-wrapper">
                                <input 
                                    className='display-hidden' 
                                    id='pass' name="pass" type='number'
                                    value={password.pass} autoFocus={true} 
                                    onChange={handlePassword}
                                    onKeyUp={handleTypeNumberControl}
                                    onKeyDown={handleTypeNumberControl}
                                    autoComplete="off"
                                />
                                <label className={`passcode-input ${password.pass.length === 0 && 'active-pin-box'}`} htmlFor='pass' >{password.pass[0] && showCode.passcode ? password.pass[0] : password.pass[0] ? '*' : ''}</label>
                                <label className={`passcode-input ${password.pass.length === 1 && 'active-pin-box'}`} htmlFor='pass' >{password.pass[1] && showCode.passcode ? password.pass[1] : password.pass[1] ? '*' : ''}</label>
                                <label className={`passcode-input ${password.pass.length === 2 && 'active-pin-box'}`} htmlFor='pass' >{password.pass[2] && showCode.passcode ? password.pass[2] : password.pass[2] ? '*' : ''}</label>
                                <label className={`passcode-input ${password.pass.length === 3 && 'active-pin-box'}`} htmlFor='pass' >{password.pass[3] && showCode.passcode ? password.pass[3] : password.pass[3] ? '*' : ''}</label>
                                <label className={`passcode-input ${password.pass.length === 4 && 'active-pin-box'}`} htmlFor='pass' >{password.pass[4] && showCode.passcode ? password.pass[4] : password.pass[4] ? '*' : ''}</label>
                                <label className={`passcode-input ${password.pass.length === 5 && 'active-pin-box'}`} htmlFor='pass' >{password.pass[5] && showCode.passcode ? password.pass[5] : password.pass[5] ? '*' : ''}</label>
                            </div>
                        </div>
                        <div className="form-group-wrapper">
                            <FlexRow bottomPadding='1' justifyContent='flex-start'>
                                <label className="">Confirm Passcode</label>  
                                { !showCode.confirmCode ?
                                    <BsFillEyeSlashFill 
                                        style={{marginLeft: '1.5rem', marginTop: '-0.5rem'}}
                                        onClick={() => setShowCode({...showCode, confirmCode: true})} 
                                    />
                                    : <BsFillEyeFill 
                                        style={{marginLeft: '1.5rem', marginTop: '-0.5rem'}}
                                        onClick={() => setShowCode({...showCode, confirmCode: false})} 
                                    />
                                }
                            </FlexRow>
                            <div className="passcode-input-wrapper">
                                <input 
                                    className='display-hidden' id='cPass' 
                                    name="cPass" type='number'
                                    value={password.cPass} 
                                    onChange={handlePassword}
                                    onKeyUp={handleTypeNumberControl}
                                    onKeyDown={handleTypeNumberControl}
                                    autoComplete="off"
                                />
                                <label className={`passcode-input ${password.cPass.length === 0 && 'active-pin-box'}`} htmlFor='cPass' >{password.cPass[0] && showCode.confirmCode ? password.cPass[0] : password.cPass[0] ? '*' : ''}</label>
                                <label className={`passcode-input ${password.cPass.length === 1 && 'active-pin-box'}`} htmlFor='cPass' >{password.cPass[1] && showCode.confirmCode ? password.cPass[1] : password.cPass[1] ? '*' : ''}</label>
                                <label className={`passcode-input ${password.cPass.length === 2 && 'active-pin-box'}`} htmlFor='cPass' >{password.cPass[2] && showCode.confirmCode ? password.cPass[2] : password.cPass[2] ? '*' : ''}</label>
                                <label className={`passcode-input ${password.cPass.length === 3 && 'active-pin-box'}`} htmlFor='cPass' >{password.cPass[3] && showCode.confirmCode ? password.cPass[3] : password.cPass[3] ? '*' : ''}</label>
                                <label className={`passcode-input ${password.cPass.length === 4 && 'active-pin-box'}`} htmlFor='cPass' >{password.cPass[4] && showCode.confirmCode ? password.cPass[4] : password.cPass[4] ? '*' : ''}</label>
                                <label className={`passcode-input ${password.cPass.length === 5 && 'active-pin-box'}`} htmlFor='cPass' >{password.cPass[5] && showCode.confirmCode ? password.cPass[5] : password.cPass[5] ? '*' : ''}</label>
                            </div>
                        </div>
                    </CustomContainer>

                    <Button 
                        width="15" 
                        height="4.5" 
                        hoverBgColor="#FFC49D"
                        topMargin="12"
                        className="loan-action-btn"
                        disabled={password.pass.length != 6 || password.cPass.length != 6 ? true : false}
                        onClick={handleNext}
                    >
                        Continue
                    </Button>
                </div>
            </Container>
            { showResponse &&
                <NoAuthPopup 
                    close={() => setShowResponse(false)} 
                    text="Passcode Set"
                    message="Passcode set successfully"
                    route="/onboarding/transaction-pin"
                    responseIcon={images.SUCCESS_RESPONSE}
                />
            }
        </>
    )
}

export default utility.routeData('passcode', 'Passcode', <Passcode/>)