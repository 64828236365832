import utility from 'src/utils/utility'
import { useState, useContext, useEffect, useRef } from 'react'
import ApiContext from 'src/provider/API/call-service'
import LoanContext from 'src/provider/state-manager/loanProvider'
import VisibilityContext from 'src/provider/state-manager/visibilityProvider'
import { 
    SidePopupContainer, 
    SidePopup, 
    Form, 
    Button, 
    Divider,
    FlexRow,
    AppText,
    FormGroupWithIcon,
    SelectButton,
    FormGroup,
    FlexColumn,
    AppSpan,
    CustomContainer,

} from "src/styles"
import { TransPin } from 'src/component'

export const RepayLoan: React.FC<any> = ({close, amountToPay}) => {
    const {API} = useContext(ApiContext)
    const {notifier} = useContext(VisibilityContext)
    const {loan: {currentLoanInfo, currentLoans}} = useContext(LoanContext)
    const [amountLeft, setAmountLeft] = useState(0)
    const [input, setInput] = useState(0)
    const [transPin, setTransPin] = useState('')
    const [repayType, setRepayType] = useState('')

    const amountRef = useRef<HTMLInputElement>(null)

    useEffect(() => {
        if(currentLoanInfo?.amountRemaining || amountToPay) {
            setInput(currentLoanInfo?.amountRemaining || amountToPay)
            setAmountLeft(currentLoanInfo?.amountRemaining || amountToPay)
        }
        else if (!currentLoanInfo?.amountRemaining || !amountToPay) fetchAmountToRepay()
    }, [])

    async function fetchAmountToRepay () {
        const response = await API.fetchAmountToRepay()
        console.log(response)
        if (response) {
            setInput(currentLoanInfo?.amountRemaining || response)
            setAmountLeft(currentLoanInfo?.amountRemaining || response)
        }
    }


    const handleInput = (e: React.ChangeEvent<HTMLInputElement>) => {
        //let val = e.target.value.replace(/,/g, '')
        let val = e.target.value.replace(/[,.]/g, '')
        if (!isNaN(Number(val))) {
            setInput(Number(val))
        }
    }

    const handleRepayType = (type: string) => {
        setRepayType(type)
        setInput(type === 'Full-Repayment' ? (amountLeft || input) : 0)
    }

    function handleAmountCheck() {
        let formattedAmount = amountRef.current?.value?.replace(/[,]/g, '')

        // input
        if (Number(formattedAmount) > amountLeft) {  
            setInput(0)
            notifier.show('Amount entered cannot be greater than the amount to be repaid')
        }
    }

    async function handleSubmit (e: React.FormEvent<HTMLButtonElement|HTMLFormElement>) {
        e.preventDefault()
        let formattedAmount = amountRef.current?.value?.replace(/[,]/g, '')
        const pinVerification = await API.verifyTransactionPin(transPin)
        if (pinVerification) {
            const response = await API.repayLoan(formattedAmount || input) // input
            if (response) {
                API.runBackgroundService(['current-loan-update', 'charts-info-update'], 6000)
                close()
            }
        }
    }

    return (
        <SidePopupContainer >
            <SidePopup data-aos="slide-left">
                <div className="popup-title-wrapper">
                    <h4>Repay Loan</h4>
                    <i className="fa fa-times" onClick={() => close(false)}></i>
                </div>
                <Form onSubmit={handleSubmit}>
                    <FormGroup topMargin="3">
                        <label>How do you want to repay your loan?</label>
                        <div className="loan-tenor-types">
                            <SelectButton width="18" height="4">
                                <input type="radio" name="payment-type" checked={repayType === 'Full-Repayment'} onChange={() => handleRepayType('Full-Repayment')} />
                                <span></span>
                                <label>Full Repayment</label>
                            </SelectButton>
                            <SelectButton width="18" height="4" >
                                <input type="radio" name="payment-type" checked={repayType === 'Part-Repayment'} onChange={() => handleRepayType('Part-Repayment')} />
                                <span></span>
                                <label>Part Repayment</label>
                            </SelectButton>
                        </div>
                    </FormGroup>
                    <FlexRow 
                        bgColor='#4700E0' radius='0.8' 
                        topPadding='1.5' bottomPadding='1.5' 
                        bottomMargin='2'
                    >
                        <FlexColumn 
                            width='50' sizeUnit='%' 
                            alignItems='flex-end' rightPadding='2'
                            style={{borderRight: '1px solid #fff'}}
                        >
                            <CustomContainer width="auto" sizeUnit="">
                                <AppText textSize="1.1" align='right' fontWeight="400">Loan Amount</AppText>
                                <AppText align='right'>₦{utility.moneyFormat(currentLoanInfo?.loanRequest?.loanOffer?.amountPerPerson || currentLoanInfo?.loanRequest?.amount)}</AppText>
                            </CustomContainer>
                            <CustomContainer width="auto" sizeUnit="" topMargin="1">
                                <AppText textSize="1.1" align='right' fontWeight="400">Duration</AppText>
                                <AppText align='right'>{currentLoanInfo?.loanRequest?.loanOffer?.tenor} {currentLoanInfo?.loanRequest?.loanOffer?.tenorType?.toLowerCase()}(s)</AppText>
                            </CustomContainer>
                            <CustomContainer width="auto" sizeUnit="" topMargin="1">
                                <AppText textSize="1.1" align='right' fontWeight="400">Interest Rate</AppText>
                                <AppText align='right'>{currentLoanInfo?.loanRequest?.loanOffer?.interest}%</AppText>
                            </CustomContainer>
                        </FlexColumn>
                        <FlexColumn width='50' sizeUnit='%' alignItems='flex-start' leftPadding='2'>
                            <CustomContainer width="auto" sizeUnit="">
                                <AppText textSize="1.1" fontWeight="400">Repayment Amount</AppText>
                                <AppText>₦{utility.moneyFormat(currentLoanInfo?.loanRequest?.loanOffer?.totalRepaymentBorrower)}</AppText>
                            </CustomContainer>
                            <CustomContainer width="auto" sizeUnit="" topMargin="1">
                                <AppText textSize="1.1" fontWeight="400">Amount Repaid</AppText>
                                <AppText>₦{utility.moneyFormat(currentLoanInfo?.amountPaid)}</AppText>
                            </CustomContainer>
                            <CustomContainer width="auto" sizeUnit="" topMargin="1">
                                <AppText textSize="1.1" fontWeight="400">Remaining payment</AppText>
                                <AppText>₦{currentLoanInfo?.amountRemaining}</AppText>
                            </CustomContainer>
                        </FlexColumn>
                    </FlexRow>
                    { repayType === "Full-Repayment" &&
                        <AppText textSize='1.3' fontWeight="400">
                            You will be making a full repayment of 
                            <AppSpan textSize="1.4"> N{utility.moneyFormat(amountLeft)}</AppSpan>
                        </AppText>
                    }
                    { repayType === 'Part-Repayment' &&
                        <FormGroupWithIcon bottomMargin='1'>
                            <label>Enter loan amount you want to repay.</label>
                            <div className="input-with-icon">
                                <span className='amount-symbol'>&#8358;</span>
                                <input 
                                    type="text"
                                    placeholder="Amount" 
                                    className="amount-input"
                                    ref={amountRef}
                                    value={input ? utility.koboFormat(input) : ''} 
                                    onChange={handleInput}
                                    onBlur={handleAmountCheck}
                                />
                            </div>
                        </FormGroupWithIcon>
                    }
                    { repayType &&
                        <AppSpan textSize='1.1' color='rgba(255, 255, 255, 0.5)' fontWeight='400'>Funds will be deducted from your wallet</AppSpan>
                    }
                    <Divider bgColor="#2E4DBD" margin="3" />
                    <FlexRow justifyContent="space-between" topMargin="3" className="withdraw-passcode-wrapper">
                        <AppText fontWeight="700" topMargin='0.5'>Transaction Pin</AppText>
                        <TransPin onChange={(val: string) => setTransPin(val)} />
                    </FlexRow>
                    <FlexRow justifyContent="flex-end" topMargin="6">
                        <Button
                            width="22"
                            hoverBgColor="#FED0B7" 
                            className="loan-action-btn"
                            disabled={(!input || transPin.length != 4) ? true : false}
                            value="submit"
                        >
                            Make Payment
                        </Button>
                    </FlexRow>
                </Form>
            </SidePopup>
        </SidePopupContainer>
    )
}
