import './style.scss'
import { useState, useEffect, useContext } from 'react'
import ApiContext from 'src/provider/API/call-service'
import LoanContext from 'src/provider/state-manager/loanProvider'
import VisibilityContext from 'src/provider/state-manager/visibilityProvider'
import utility from 'src/utils/utility'
import { useNavigate } from 'react-router-dom'
import { BsChevronLeft } from 'react-icons/bs'
import { FormGroup, FormGroupWithIcon, Button, ScreenContainer, AppSpan, FlexRow } from "src/styles"
import { GoChevronDown } from 'react-icons/go'
import helpers from 'src/utils/helpers'
import { VscWarning } from 'react-icons/vsc'

const LoanType = () => {
    const navigate = useNavigate()
    const {API} = useContext(ApiContext)
    const {notifier} = useContext(VisibilityContext)
    const {loan: {loanCreation}, setLoanProperty} = useContext(LoanContext)
    const [isValidated, setIsValidated] = useState(false)
    const [loanTypes, setLoanTypes] = useState<any>(null)
    const [input, setInput] = useState({loanTypeId: loanCreation?.loanTypeId || '', amount: loanCreation?.amount || '', loanPerPerson: loanCreation?.numberOfPerson || '', about: loanCreation?.about || '', loanTypeName: loanCreation?.loanTypeName || ''})

    useEffect(() => {
        const auth = input.loanTypeId == 9 ? helpers.inputValidator(input, ['about']) : helpers.inputValidator(input, ['loanTypeName', 'about'])
        setIsValidated(auth.isValidated)
    }, [input])

    // useEffect(() => {
    //     if (loanCalcData) setInput({...input, amount: String(loanCalcData?.loanAmount)})
    // }, [loanCalcData])

    useEffect(() => {
        if (!loanTypes?.length) fetchLoanTypes()
        //if (loanCreation) setInput({...input, ...loanCreation})
    }, [])


    function handleInput (e: React.ChangeEvent<HTMLInputElement|HTMLSelectElement|HTMLTextAreaElement>) {
        if (e.target.name === 'amount') {
            let val = e.target.value.replace(/,/g, '')
            if (!isNaN(Number(val))) {
                setInput({...input, [e.target.name]: val})
            }
        }
        else if (e.target.name === 'about') setInput({...input, about: e.target.value.length <= 250 ? e.target.value : input.about})
        else setInput({...input, [e.target.name]: e.target.value})
    }

    async function fetchLoanTypes () {
        const response = await API.getLoanTypes()
        if (response) setLoanTypes(response)
        else navigate('/dashboard/home')
    }

    async function handleNext () {
        if((input.amount/input.loanPerPerson) < 10000) return notifier.show("Minimum amount that can be offered per person is currently ₦10,000") 
        await setLoanProperty(
            'loanCreation', 
            {...loanCreation, ...input, loanTypeId: Number(input.loanTypeId), amount: Number(input.amount), numberOfPerson: Number(input.loanPerPerson)}
        )
        navigate('/dashboard/loan-creation/loan-tenor')
    }

    return (
        <ScreenContainer>
            <div className="loan-type-container">
                <div className="loan-type-title">
                    <div className="loan-type-title-flex">
                        <BsChevronLeft className="loan-back-icon" onClick={() => navigate(-1)} />
                        <div className="loan-type-title-content">
                            <p >{loanCreation?.rateId ? 'Edit' : 'Create'} Loan Portfolio</p>
                            <span>
                                Show how much you want to lend to qualified borrowers. Credit your portfolio with any amount,
                                include more details like loan type and interest rate to help us find the right match for you.
                            </span>
                        </div>
                    </div> 
                </div>
                <div className="loan-type-content">
                    <FormGroupWithIcon>
                        <label>Loan Type</label>
                        <div className="input-with-icon">
                            <select
                                name="loanTypeId" 
                                value={input.loanTypeId} 
                                onChange={handleInput}
                                style={{color: input.loanTypeId ? '#fff' : '#999'}}
                            >
                                <option value="">Select loan type</option>
                                {
                                    loanTypes?.map((item: any, index: number) => {
                                        return(
                                            <option value={item.id} key={index}>{item.loanType}</option>
                                        )
                                    })
                                }
                            </select>
                            <GoChevronDown className="input-with-icon-tag" />
                        </div>
                    </FormGroupWithIcon>
                    { input.loanTypeId == 9 &&
                        <FormGroup>
                            <label>Other Loan Type</label>
                            <input 
                                placeholder="Enter loan type" 
                                name="loanTypeName"
                                value={input.loanTypeName} 
                                onChange={handleInput}
                            />
                        </FormGroup>
                    }
                    <FormGroupWithIcon>
                        <label>How much do you want to drop in this portfolio?</label>
                        <div className="input-with-icon">
                            <span className='amount-symbol'>&#8358;</span>
                            <input 
                                type="text" 
                                value={input.amount ? utility.moneyFormat(input.amount) : ''}
                                name="amount"
                                className="amount-input"
                                autoComplete='off'
                                placeholder="Portfolio amount" 
                                onChange={handleInput} 
                            />
                        </div>
                        <FlexRow justifyContent='flex-start' topMargin='1'>
                            <VscWarning style={{color: '#FFB661', marginRight: '0.7rem'}} />
                            <AppSpan color='#CBCBCB' textSize='1.2' fontWeight='400'>Minimum Loan amount you can give per person is ₦10,000.</AppSpan>
                        </FlexRow>
                    </FormGroupWithIcon>
                    <FormGroupWithIcon>
                        <label>How many people should take this loan</label>
                        <div className="input-with-icon">
                            <input 
                                type="number" 
                                name="loanPerPerson"
                                placeholder="Enter number of people" 
                                autoComplete='off'
                                value={input.loanPerPerson} 
                                onChange={handleInput}
                            />
                            {/* <span>/Per people</span> */}
                        </div>
                    </FormGroupWithIcon>
                    <FormGroup bottomMargin='0'>
                        <label>Loan Description (Optional)</label>
                        <textarea 
                            placeholder="Give this loan portfolio a description" 
                            name="about"
                            value={input.about} 
                            style={{height: '10rem'}}
                            onChange={handleInput}
                        />
                    </FormGroup>
                    <AppSpan textSize='1.2' color='#FF0062' leftMargin='2'>{input.about.length} of 250</AppSpan>
                    <div className="loan-creation-wrapper-plus">
                        <span style={{visibility: 'hidden'}}>Back</span>
                        <Button
                            width="13"
                            hoverBgColor="#FED0B7"
                            className="loan-action-btn"
                            disabled={!isValidated ? true : false}
                            onClick={handleNext}
                        >
                            Next
                        </Button>
                    </div>
                </div>
            </div>
        </ScreenContainer>

    )
}

export default utility.routeData('loan-creation/loan-type', 'Loan Type', <LoanType/>)