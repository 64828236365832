import LandingPage from  'src/app/landingPage'
import SignIn from 'src/app/signin'
import Identification from 'src/app/forgetPassword/identification'
import OtpVerification from 'src/app/forgetPassword/otp'
import NewPasscode from 'src/app/forgetPassword/newPasscode'
import DashboardRoutes from 'src/app/dashboard/dashboard-routing'
import ExploreRoutes from 'src/app/explore/explore-routing'
import OnboardingRoutes from 'src/app/onboarding/onboarding-routing'
import EmailVerificationLanding from 'src/app/emailVerificationLanding'

export default [
    LandingPage,
    SignIn,
    Identification,
    OtpVerification,
    NewPasscode,
    DashboardRoutes,
    ExploreRoutes,
    OnboardingRoutes,
    EmailVerificationLanding
]