import { useContext, useEffect } from "react"
import UserContext from "src/provider/state-manager/userProvider"
import { RoundImageContainer, FixedContainer } from "src/styles"
import { AiOutlineIssuesClose } from "react-icons/ai"
import { BsChatDots } from "react-icons/bs"
import ReactTooltip from 'react-tooltip'
import { credentials } from "src/provider/config/constant"

export const Complaint = () => {
    const {user: {userData}} = useContext(UserContext)

    useEffect(() => {
        ReactTooltip.rebuild();
    }, [])

    function handleIssueLogging () {
        window.handleComplaint({
            name: `${userData?.firstName} ${userData?.lastName}`,
            email: userData?.email,
            userId: userData?.userId,
            customerId: userData?.customerId,
            isBusiness: userData?.profileType != 'individual'
        }, credentials)
    }

    return (
        <>
            {/* <RoundImageContainer 
                size="7.5" 
                bgColor="#6DE7B4" 
                isMaxWidth={false} 
                className="fixed-chat-icon-wrapper"
            >
                <a 
                    href='mailto:support@crendly.com'
                    style={{width: '7.5rem', height: '7.5rem', display: 'grid', placeItems: 'center'}}
                >
                    <BsChatDots style={{fontSize: '4rem', color: '#00071F'}} />
                </a>
            </RoundImageContainer> */}
            <FixedContainer bottom='3' right='4' zIndex="3">
                <RoundImageContainer 
                    size="7.5" 
                    bgColor="#6DE7B4" 
                    isMaxWidth={false}
                    className='hover-scale-up-icon' 
                    data-tip='Log and track complaint or issues'
                    data-class="my-tooltip"
                    onClick={handleIssueLogging}
                >   
                    {/* <AiOutlineIssuesClose style={{fontSize: '4rem', color: '#00071F'}} /> */}
                    <BsChatDots style={{fontSize: '4rem', color: '#00071F'}} />
                </RoundImageContainer>
            </FixedContainer>
        </>
    )
}