import '../style.scss'
import { useContext, useState } from 'react'
import VisibilityContext from 'src/provider/state-manager/visibilityProvider'
import { AppLink, AppSpan, AppText, Button, CustomContainer, FlexColumn, Form, FormGroup, GridContainer, SidePopupContainer } from "src/styles"
import helpers from 'src/utils/helpers'
import {ReactComponent as NINImg} from 'src/assets/svg/nin-img.svg'

export const NIN: React.FC<any> = ({onNext, onNIN, getCodeFromApp, close}) => {
    const {notifier} = useContext(VisibilityContext)
    const [NIN, setNIN] = useState('')

    const handleNINChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.value.length <= 11) setNIN(e.target.value)
    }

    function handleContinue (e: React.FormEvent<HTMLButtonElement | HTMLFormElement>) {
        e.preventDefault()
        const isValidNIN = helpers.validateNIN(NIN)
        if (!isValidNIN) return notifier.show('Kindly provide a valid NIN')
        onNIN(NIN)
        onNext()
    }

    return (
        <>
            <SidePopupContainer zIndex="6" onClick={close} />
            <GridContainer
                className='vnin-popup-container'
            >
                <GridContainer width='10' height='10'>
                    <NINImg className='nin-img'/>
                </GridContainer>
                <GridContainer topMargin='1.5'>
                    <AppSpan fontWeight='600'>To prevent identity theft,</AppSpan>
                    <AppText 
                        align='center' textSize='2' topMargin='1'
                        leftPadding='3' rightPadding='3'
                    >
                        Let’s verify your NIN with a temporary code
                    </AppText>
                </GridContainer>
                <CustomContainer 
                    borderColor='#2E4DBD' borderType='dashed' 
                    topMargin='1' bottomMargin='3'
                />
                <Form onSubmit={handleContinue}>
                    <AppText align='center' fontWeight='500'>First, enter your NIN</AppText>
                    <FormGroup bottomMargin="1.5" topMargin="1">
                        <input  
                            name="nin" 
                            placeholder="Enter NIN" 
                            type='number'
                            value={NIN} 
                            onChange={handleNINChange} 
                            autoComplete='off'
                            required
                        />
                    </FormGroup>
                    {/* <AppText fontWeight='400' textSize='1.2' align='center'>
                        Or generate the code using the NIMC mobile app.
                        <AppLink 
                            fontWeight='400' color='#ffffff' textSize='1.2' 
                            leftPadding='0.5' hoverColor='#FFC49D'
                            onClick={getCodeFromApp}
                        >
                            Learn how
                        </AppLink>
                    </AppText> */}
                    <FlexColumn>
                        <Button 
                            width="17" 
                            height="4.5" 
                            topMargin='5'
                            hoverBgColor="#FFC49D" 
                            bottomMargin="1.5"
                            className="loan-action-btn"
                            disabled={!NIN ? true : false}
                        >
                            Continue
                        </Button>
                    </FlexColumn>
                </Form>
            </GridContainer>
        </>
    )
}