import { useState, useContext, useEffect } from 'react'
import { images } from 'src/provider/config/constant'
import ApiContext from 'src/provider/API/call-service'
import LoanContext from 'src/provider/state-manager/loanProvider'
import { SidePopupContainer, SidePopup, Divider, Button, AbsoluteContainer, Circle, FlexRow, AppLink, BorderlessButton  } from "src/styles"
import utility from 'src/utils/utility'
import { RiCloseFill } from 'react-icons/ri'
import { SelectedOfferDetails } from 'src/popup'


export const SelectedOffers: React.FC<any> = ({close, data, removeSelected}) => {
  const {API} = useContext(ApiContext)
  const {loan: {currentProcessType}} = useContext(LoanContext)
  const [details, setDetails] = useState({})
  const [showDetails, setShowDetails] = useState(false)

  let typeText = currentProcessType === 'borrower' ? 'offer' : 'request'

  function handleDetails (data: any) {
    setDetails(data)
    setShowDetails(true)
  }

  console.log({data})
  async function handleRequestLoan () {
    let ids = []
    if (currentProcessType === 'borrower') {
      ids = [data[0]?.loanOfferId, data[1]?.loanOfferId, data[2]?.loanOfferId]
    }
    else ids = [data[0]?.loanRequestId, data[1]?.loanRequestId, data[2]?.loanRequestId]
    await API.requestLoan(ids)
  }

  return (
    <>
      { !showDetails &&
        <SidePopupContainer >
          <SidePopup data-aos="slide-left">
            <div className="popup-title-wrapper">
              <h4>Loan Offers</h4>
              <i className="fa fa-times" onClick={close}></i>
            </div>
            <span className="popup-title-summary">
                View the 3 loan {typeText}s selected.
            </span>
            <div className="selected-offer-list">
              {
                data?.map((item: any, index: number) => {
                  return (
                    <div className='selected-offer-wrapper' key={index}>
                      <div className="offer-details-summary">
                          <div className="loan-offer-header">
                              <div className="loan-offer-label">
                                  <img src={images.LOAN_OFFER_ICON} className="loan-offer-header-icon" />
                                  <div className="loan-offer-label-text">
                                      {/* <label>LoanType</label> */}
                                      <p className='loan-offer-type-text'>{item?.loanTypeName}</p>
                                  </div>
                              </div>
                              <span className="loan-offer-percentage" style={{color: '#6DE7B4'}}>{item?.interest}%p.m</span>
                          </div>
                          <Divider margin="1.5" />
                          <div className="selected-data-container">
                              <div className="loan-offer-label-text">
                                  <label>Amount</label>
                                  <p>N{utility.moneyFormat(item?.amountPerPerson || item?.amount)}</p>
                              </div>
                              <div className="loan-offer-label-text">
                                  <label>Duration</label>
                                  <p>{item?.tenor} {item?.tenorType?.toLowerCase()}(s)</p>
                              </div>
                              <div className="loan-offer-label-text">
                                  <label>Payment</label>
                                  <p>{item.rateId === 1 ? 'Installment' : 'One-off'}</p>
                              </div>
                              <div className="loan-offer-label-text">
                                  <label>Lender's rep</label>
                                  <p style={{color: '#6DE7B4'}}>{item?.lenderRep || 'nill'}</p>
                              </div>
                          </div>
                          <AbsoluteContainer top='-1' right='-0.8' zIndex='2'>
                              <Circle bgColor='#FF0062' onClick={() => removeSelected(index)}>
                                  <RiCloseFill style={{color: '#fff'}} />
                              </Circle>
                          </AbsoluteContainer>
                      </div>
                      <AppLink 
                        textSize="1.2" 
                        hoverColor="#FED0B7" 
                        decoration='none'
                        hasBottomBorder={true}
                        onClick={() => handleDetails(item)}
                      >
                        View loan {typeText} details
                      </AppLink>
                    </div>
                  )
                })
              }
            </div>
            <FlexRow justifyContent='flex-end' topMargin='4' bottomMargin='2' rightPadding='1.5'>
              { data?.length != 3 &&
                <BorderlessButton hoverColor='#FED0B7' titleSize='1.4' onClick={close}>
                  Add new {typeText}
              </BorderlessButton>
              }
              <Button
                width="17"
                height='4'
                leftMargin='2'
                titleSize='1.4'
                hoverBgColor="#FED0B7"
                className="loan-action-btn"
                disabled={data?.length != 3 ? true : false}
                onClick={handleRequestLoan}
              >
                Show Interest
              </Button>
            </FlexRow>
          </SidePopup>
        </SidePopupContainer>
      }
      { showDetails &&
        <SelectedOfferDetails 
          close={() => setShowDetails(false)}
          data={details}
        />
      }
    </>
  )
}
