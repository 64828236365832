import './style.scss'
import { useContext, useState } from 'react'
import { useNavigate, NavLink } from 'react-router-dom'
import VisibilityContext from 'src/provider/state-manager/visibilityProvider'
import AppInfoContext from 'src/provider/state-manager/appInfoProvider'
import ApiContext from 'src/provider/API/call-service'
import UserContext from 'src/provider/state-manager/userProvider'
import { 
    AbsoluteContainer, AppLink, AppText, CustomContainer, 
    Icon, RoundImageContainer, SideBarContainer, SideBarShadow 
} from 'src/styles'
import { BiHomeAlt } from 'react-icons/bi'
import { useGAEventTracker } from 'src/hooks'
import { MdOutlineEventNote, MdOutlineExplore, MdOutlinePayment } from 'react-icons/md'
import { AiOutlineSetting } from 'react-icons/ai'
import { GiTakeMyMoney } from 'react-icons/gi'
import { HiOutlineLogout } from 'react-icons/hi'
import { images } from 'src/provider/config/constant'
import { AddBank, CompleteProfile } from 'src/popup'


export const SideBar = () => {
    const navigate = useNavigate()
    const {API} = useContext(ApiContext)
    const {visibility, showSideBar} = useContext(VisibilityContext)
    const {info, setInfoProperty, logout} = useContext(AppInfoContext)
    const GAEventTracker = useGAEventTracker('Logout')
    const {user} = useContext(UserContext)
    const [showAddBank, setShowAddBank] = useState(false)
    const [showCompleteProfile, setShowCompleteProfile] = useState(false)

    // function handleNavigation(name: string, path?: string) {
    //     showSideBar(false)
    //     setInfoProperty('selectedMenu', name)
    //     if (path) navigate(path)
    // }

    // function handleCompleteProfile () {
    //     const {screen} = user.onBoardingStatus
    //     if (screen === true) setShowAddBank(true)
    //     else if (screen) navigate(screen)
    // }

    async function handleLogout () {
        GAEventTracker('Logout', `User: ${user.userData?.email}`)
        API.updateLoginHistory()
        await logout()
    }

    return (
        <>
            <SideBarShadow isSideBar={visibility.isSideBar}/>
            <SideBarContainer isSideBar={visibility.isSideBar} onClick={() => showSideBar(false)}>
                <div className="sidebar-menu-wrapper">
                    <NavLink
                        to='/dashboard/home'
                        className={({ isActive }) => `sidebar-menu ${(isActive || info.selectedMenu === 'Home') && 'selected-menu'}`}
                        // className={info.selectedMenu === 'home' ? "sidebar-menu selected-menu" : "sidebar-menu"}
                        // onClick={() => handleNavigation('Home')}
                    >
                        <BiHomeAlt className="sidebar-menu-icon" />
                        <p className="sidebar-menu-title">Home</p>
                    </NavLink>
                    <NavLink
                        to='/dashboard/loan'
                        className={({ isActive }) => `sidebar-menu ${(isActive || info.selectedMenu === 'Loan') && 'selected-menu'}`}
                    >
                        <GiTakeMyMoney className="sidebar-menu-icon" />
                        <p className="sidebar-menu-title">Overview</p>
                    </NavLink>
                    <NavLink 
                        to='/dashboard/transactions'
                        className={({ isActive }) => `sidebar-menu ${(isActive || info.selectedMenu === 'Transactions') && 'selected-menu'}`}
                    >
                        <MdOutlineEventNote className="sidebar-menu-icon" />
                        <p className="sidebar-menu-title">Transactions</p>
                    </NavLink>
                    <NavLink 
                        to='/dashboard/payment'
                        className={({ isActive }) => `sidebar-menu ${(isActive || info.selectedMenu === 'Payment') && 'selected-menu'}`}
                    >
                        <MdOutlinePayment className="sidebar-menu-icon" />
                        <p className="sidebar-menu-title">Payment</p>
                    </NavLink>
                        
                    <NavLink 
                        to='/dashboard/explore'
                        className={({ isActive }) => `sidebar-menu ${(isActive || info.selectedMenu === 'Explore') && 'selected-menu'}`}
                    >
                        <MdOutlineExplore className="sidebar-menu-icon" />
                        <p className="sidebar-menu-title">Explore</p>
                    </NavLink>
                    <NavLink 
                        to='/dashboard/settings/home'
                        className={({ isActive }) => `sidebar-menu ${(isActive || info.selectedMenu === 'Settings') && 'selected-menu'}`}
                    >
                        <AiOutlineSetting className="sidebar-menu-icon" />
                        <p className="sidebar-menu-title">Settings</p>
                    </NavLink>
                </div>
                { user.onBoardingStatus?.percCompleted != 100 &&
                    <CustomContainer leftPadding='2.5' rightPadding='1.5' topMargin='3'>
                        <CustomContainer
                            bgColor='#081952' leftPadding='1' topPadding='1.5' 
                            width='17.2' radius='0.8' rightPadding='1' bottomPadding='1'
                        >
                            <AppText textSize='1.25' width='11' bottomMargin='1'>{user.onBoardingStatus?.infoToProvide} </AppText>
                            <AppText textSize='1.1' width='11' bottomMargin='1' color='rgba(255, 255, 255, 0.8)'>Let’s know more about you.</AppText>
                            <AppLink 
                                textSize="1" 
                                hoverColor="#FED0B7" 
                                decoration='none'
                                hasBottomBorder={true}
                                fontWeight='500'
                                onClick={() => setShowCompleteProfile(true)}
                            >
                                Continue
                            </AppLink>
                        </CustomContainer>
                        <AbsoluteContainer top='1' right='1.8'>
                            <RoundImageContainer size="6.5" bgColor="#fff">
                                <Icon src={images.USER2X} width='6' height='6' radius='50' />
                            </RoundImageContainer>
                        </AbsoluteContainer>
                    </CustomContainer>
                }
                <a className="logout-btn" onClick={handleLogout}>
                    <HiOutlineLogout className="logout-icon"/>
                    <p className="logout-text">Logout</p>
                </a>
            </SideBarContainer>
            { showAddBank &&
                <AddBank 
                    close={() => setShowAddBank(false)} 
                    hasBank={false} 
                />
            }
            { showCompleteProfile &&
                <CompleteProfile
                    close={() => setShowCompleteProfile(false)} 
                    data={user.onBoardingStatus}
                    addBank={() => setShowAddBank(true)}
                />
            }
        </>
  )
}
