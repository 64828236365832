import './style.scss'
import { useState, useContext, useEffect } from 'react'
import utility, { getCharacterToDispay } from 'src/utils/utility'
import ApiContext from 'src/provider/API/call-service'
import LoanContext from 'src/provider/state-manager/loanProvider'
import VisibilityContext from 'src/provider/state-manager/visibilityProvider'
import UserContext from 'src/provider/state-manager/userProvider'
import { useNavigate } from 'react-router-dom'
import { BsChevronLeft } from 'react-icons/bs'
import { FormGroup, SelectButton, Button, ScreenContainer, FormGroupWithIcon, FlexRow, AppSpan } from "src/styles"
import helpers from 'src/utils/helpers'
import { VscWarning } from 'react-icons/vsc'

const TakeLoanType = () => {
    const navigate = useNavigate()
    const {API} = useContext(ApiContext)
    const {notifier} = useContext(VisibilityContext)
    const {user: {overallScore}} = useContext(UserContext)
    const {loan: {loanRequest}, setLoanProperty} = useContext(LoanContext)
    const [isValidated, setIsValidated] = useState(false)
    const [loanTypes, setLoanTypes] = useState<any>(null)
    const [input, setInput] = useState({loanTypeId: loanRequest?.loanTypeId || '', amount: loanRequest?.amount || '', loanType: loanRequest?.loanType || '', loanTypeName: loanRequest?.loanTypeName || ''})

    useEffect(() => {
        fetchLoanTypes()
    }, [])

    useEffect(() => {
        const auth = input.loanTypeId === 9 ? helpers.inputValidator(input) : helpers.inputValidator(input, ['loanTypeName'])
        setIsValidated(auth.isValidated)
    }, [input])

    async function fetchLoanTypes () {
        const response = await API.getLoanTypes()
        if (response) setLoanTypes(response)
        else navigate('/dashboard/home')
    }

    function handleAmount (e: React.ChangeEvent<HTMLInputElement>) {
        let val = e.target.value.replace(/,/g, '')
        if (!isNaN(Number(val))) {
            setInput({...input, amount: val})
        }
    }

    async function handleNext () {
        if (input.amount < 10000) return notifier.show("Minimum loan amount that you can request for is currently ₦10,000") 
        await setLoanProperty('loanRequest', {...loanRequest, ...input, loanTypeId: Number(input.loanTypeId), amount: Number(input.amount)})
        navigate('/dashboard/get-loan/loan-duration')
    }

    return (
        <ScreenContainer>
            <div className="loan-type-container">
                <div className="loan-type-title">
                    <BsChevronLeft className="loan-back-icon" onClick={() => navigate(-1)} />
                    <div className="loan-type-title-content-plus">
                        <p >Get a Loan</p>
                        <span>
                            It takes minutes to match you with a lender. Make sure your loan details are accurate to get matched with the right lenders at great rates.
                        </span>
                    </div>
                </div>
                <div className="loan-type-content">
                    {/* <div className="loan-horizontal-bar"></div> */}
                    <FormGroup topMargin='4'>
                        <label>What type of loan are you applying for?</label>
                        <div className="loan-tenor-types">
                            {
                                loanTypes?.map((item: any, index: number) => {
                                    return (
                                        <SelectButton 
                                            width="13" height="4" size="1.35" 
                                            key={index} 
                                        >
                                            <input type="radio" name="get-loan-type" checked={input.loanTypeId === item.id} onChange={() => setInput({...input, loanTypeId: item.id, loanType: item.loanType})} />
                                            <span></span>
                                            <label>{getCharacterToDispay(item.loanType, 15, 2)}</label>
                                        </SelectButton>
                                    )
                                })
                            }
                        </div>
                    </FormGroup>
                    { input.loanTypeId == 9 &&
                        <FormGroup>
                            <label>Other Loan Type</label>
                            <input 
                                placeholder="Enter loan type" 
                                name="loanTypeName"
                                value={input.loanTypeName} 
                                onChange={(e) => setInput({...input, loanTypeName: e.target.value})}
                            />
                        </FormGroup>
                    }
                    <FormGroupWithIcon>
                        <label>How much are you applying for</label>
                        <div className="input-with-icon">
                            <span className='amount-symbol'>&#8358;</span>
                            <input 
                                name="amount"
                                type="text"
                                placeholder="Enter amount" 
                                className="amount-input"
                                autoComplete='off'
                                value={input.amount ? utility.moneyFormat(input.amount) : ''} 
                                onChange={handleAmount}
                            />
                        </div>
                        <FlexRow justifyContent='flex-start' topMargin='1'>
                            <VscWarning style={{color: '#FFB661', marginRight: '0.7rem'}} />
                            <AppSpan color='#CBCBCB' textSize='1.2' fontWeight='400'>Minimum Loan amount you can take is ₦10,000.</AppSpan>
                        </FlexRow>
                        { (!!overallScore?.loanLimit || overallScore?.loanLimit === 0) &&
                            <FlexRow justifyContent='flex-start'>
                                <VscWarning style={{color: '#FFB661', marginRight: '0.7rem'}} />
                                <AppSpan color='#CBCBCB' textSize='1.2' fontWeight='400'>Your Loan Limit is ₦{utility.moneyFormat(overallScore?.loanLimit)}.</AppSpan>
                            </FlexRow>
                        }
                    </FormGroupWithIcon>
                    <div className="loan-creation-wrapper-plus">
                        <span style={{visibility: 'hidden'}}>Back</span>
                        <Button
                            width="13"
                            hoverBgColor="#FED0B7"
                            className="loan-action-btn"
                            disabled={!isValidated ? true : false}
                            onClick={handleNext}
                        >
                            Next
                        </Button>
                    </div>
                </div>
            </div>
        </ScreenContainer>
    )
}

export default utility.routeData('get-loan/loan-type', 'Take Loan Type', <TakeLoanType/>)