import { useState, useContext, useRef, useCallback } from 'react'
import utility from 'src/utils/utility'
import ApiContext from 'src/provider/API/call-service'
import UserContext from 'src/provider/state-manager/userProvider'
import VisibilityContext from 'src/provider/state-manager/visibilityProvider'
import { useNavigate } from 'react-router-dom'
import { AppText, Button, FlexRow, ScreenContainer, ScreenTitle } from 'src/styles'
import Webcam from "react-webcam";
import { images, cloudinaryData } from 'src/provider/config/constant'

const CompleteFaceScan = () => {
    const navigate = useNavigate()
    const {API} = useContext(ApiContext)
    const {notifier} = useContext(VisibilityContext)
    const {user: {userData}} = useContext(UserContext)
    //const {info, setInfoProperty} = useContext(AppInfoContext)
    const [input, setInput] = useState('')
    // const [showResponse, setShowResponse] = useState(false)

    const webcamRef = useRef<any>(null);

    async function handleFile () {
        const base64 = webcamRef.current.getScreenshot() as string
        if (base64) {
            console.log({base64})
            const files = dataURLtoFile(base64, 'test')

            const data = new FormData()
            data.append('file', files)
            data.append('upload_preset', cloudinaryData.UPLOAD_PRESET)
            data.append('cloud_name', cloudinaryData.CLOUND_NAME)
            data.append('public_id', `CRENDLY_PROFILE_PICS-${userData?.userId}-${Date.now()}`)

            const url = await API.uploadToCloudinary(data, cloudinaryData.CLOUND_NAME)
            console.log({url})
            if (url) setInput(url)
        }
    }

    async function handleSubmit () {
        const response = await API.updateSignature(null, input)
        if (response) {
            await API.getUserDetails()
            notifier.show('Facial scan successfully completed', 'success')
            navigate('/dashboard/home')
        }
    }

    function dataURLtoFile(dataurl: any, filename: string) {
        var arr = dataurl.split(','),
            mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]), 
            n = bstr.length, 
            u8arr = new Uint8Array(n);
            
        while(n--){
            u8arr[n] = bstr.charCodeAt(n);
        }
        
        return new File([u8arr], filename, {type:mime});
    }

    return (
        <>
            <ScreenContainer>
                <div className="onboarding-container">
                    {/* <BsChevronLeft className="loan-back-icon" onClick={() => navigate(-1)} /> */}
                    <ScreenTitle textSize="3" bottomMargin="1.5" >
                        Facial Scan
                    </ScreenTitle>
                    <AppText color="#D5D5D5" textSize="1.4" fontWeight="400" bottomMargin="2">
                        We need a scan of your face to verify your identity. It will only take a few seconds, so give us your best smile!
                    </AppText>
                    <FlexRow justifyContent='flex-start' topMargin="5" className='faceScan-container'>
                        <div className="faceScan-wrapper" style={{border: `12px dashed ${!input ? '#141B34' : '#6DE7B4' }`}}>
                            { !input ?
                                <Webcam
                                    audio={false}
                                    screenshotFormat="image/jpeg"
                                    className='faceScan-camera'
                                    ref={webcamRef}
                                    videoConstraints={{facingMode: 'user'}}
                                    onUserMediaError={() => notifier.show('Camera not supported')}
                                /> :
                                <img src={input} className='faceScan-camera'/>
                            }
                        </div>
                        <Button
                            width="9.5" height="2.5"
                            hoverBgColor="#FED0B7" topMargin="2"
                            titleSize="1.2" radius="0.4"
                            onClick={!input ? handleFile : () => setInput('')}
                        >
                            {!input ? 'Get my face' : 'Scan my face'}
                        </Button>
                    </FlexRow>
                    <div className="option-2-btns">
                        <Button 
                            width="15" 
                            height="4.5" 
                            hoverBgColor="#FFC49D"
                            className="loan-action-btn"
                            disabled={!input? true : false}
                            onClick={handleSubmit}
                        >
                            Submit
                        </Button>
                    </div>
                </div>
            </ScreenContainer>
            {/* { showResponse &&
                <NoAuthPopup 
                    close={() => setShowResponse(false)} 
                    text="Facial Scan Complete."
                    route="/onboarding/signature-upload"
                    responseIcon={images.SIGNATURE_RESPONSE}
                />
            } */}
        </>
    )
}

export default utility.routeData('settings/complete-face-scan', 'Complete Face Scan', <CompleteFaceScan/>)