import './style.scss'
import { images } from 'src/provider/config/constant'
import { AppText, Button, FlexColumn } from 'src/styles'
import  {ReactComponent as Logo} from 'src/assets/img/404.svg'

export const ErrorFallback: React.FC<any> = ({ error, componentStack, resetErrorBoundary }) => {
    console.log({error: error?.message})

    return (
        <FlexColumn bgColor='#00071F' style={{height: '100%'}}>
            <img className="app-logo" src={images.LOGO} />
            <FlexColumn>
                <Logo className='icon-component img-404' />
                <AppText textSize='2' topMargin='3'>Something went wrong</AppText>
            </FlexColumn>
            <Button 
                width="20.2" 
                height="5" 
                hoverBgColor="#FFC49D" 
                topMargin="6"
                bottomMargin="2"
                onClick={resetErrorBoundary}
            >
                Return home
            </Button>
        </FlexColumn>
    )
}
