import utility from 'src/utils/utility'
import { useState, useContext, useRef } from 'react'
import { GoChevronDown } from 'react-icons/go'
import ApiContext from 'src/provider/API/call-service'
import UserContext from 'src/provider/state-manager/userProvider'
import VisibilityContext from 'src/provider/state-manager/visibilityProvider'
import { 
    SidePopupContainer, 
    SidePopup, 
    FormGroup, 
    Form, 
    Button, 
    FlexRow, 
    Divider,
    AppText,
    FormGroupWithIcon,
} from "src/styles"
import { TransPin } from 'src/component'
import { appSettings } from 'src/provider/config/constant'
import { IBankAccounts } from '@src/model'


export const Withdraw: React.FC<any> = ({close}) => {
    const {API} = useContext(ApiContext)
    const {user: {formattedUserData: {bankAccounts}, walletData}} = useContext(UserContext)
    const {notifier} = useContext(VisibilityContext)
    const [input, setInput] = useState({amount: 0})
    const [transPin, setTransPin] = useState('')

    const amountRef = useRef<HTMLInputElement>(null)

    const handleInput = (e: React.ChangeEvent<HTMLInputElement|HTMLSelectElement>) => {
        if (e.target.name === "amount") {
            let val = e.target.value.replace(/[,.]/g, '')
            if (!isNaN(Number(val))) {
                setInput({...input, amount: Number(val)})
            }
        }
        else setInput({...input, [e.target.name]: e.target.value})
    }

    async function handleSubmit (e: React.FormEvent<HTMLButtonElement|HTMLFormElement>) {
        e.preventDefault()
        let formattedAmount = amountRef.current?.value?.replace(/[,]/g, '')

        if (Number(formattedAmount) + appSettings.WITHDRAWAL_CHARGE > walletData?.balance) {
            return notifier.show('Insufficient Fund')
        }

        const pinVerification = await API.verifyTransactionPin(transPin)
        if (pinVerification) {
            // Charge to be added to the API payload (appSettings.WITHDRAWAL_CHARGE)
            const response = await API.withdrawFund(formattedAmount, appSettings.WITHDRAWAL_CHARGE) //input.amount
            if (response) close()
        }
    }

    return (
        <SidePopupContainer >
            <SidePopup data-aos="slide-left">
                <div className="popup-title-wrapper">
                    <h4>Withdraw Funds</h4>
                    <i className="fa fa-times" onClick={() => close(false)}></i>
                </div>
                <Form onSubmit={handleSubmit}>
                    <FormGroup>
                        <label>How much do you want to withdraw</label>
                        <input 
                            type="text" 
                            name="amount"
                            placeholder="Enter amount"
                            autoComplete='off'
                            ref={amountRef}
                            value={input.amount ? utility.koboFormat(input.amount) : ''} 
                            onChange={handleInput} 
                        />
                    </FormGroup>
                    { !!bankAccounts?.length &&
                        <FormGroupWithIcon>
                            <label>Bank Account</label>
                            <div className="input-with-icon">
                                <select  
                                    name="bankAccount"
                                    onChange={handleInput} 
                                    disabled
                                >
                                    {
                                        bankAccounts?.map((account: IBankAccounts, index: number) => {
                                            return (
                                                <option 
                                                    key={index}
                                                    value={account.accountNumber}
                                                    selected={account.isPrimaryAccount}
                                                >
                                                    {account.bank} - {account.accountNumber}
                                                </option>
                                            )
                                        })
                                    }
                                </select>
                                <GoChevronDown className="input-with-icon-tag" />
                            </div>
                        </FormGroupWithIcon>
                    }
                    <AppText textSize='1.3'>You will be charged a sum of &#8358;{appSettings.WITHDRAWAL_CHARGE}</AppText>
                    <Divider bgColor="#2E4DBD" margin="3.5" />
                    <FlexRow justifyContent="space-between" topMargin="3" className="withdraw-passcode-wrapper">
                        <AppText fontWeight="700">Transaction Pin</AppText>
                        <TransPin onChange={(val: string) => setTransPin(val)} />
                    </FlexRow>
                    <Button
                        width="20"
                        hoverBgColor="#FED0B7" 
                        topMargin="6"
                        className="loan-action-btn"
                        disabled={!input.amount || transPin.length != 4 ? true : false}
                        value="submit"
                    >
                        Withdraw Fund
                    </Button>
                </Form>
            </SidePopup>
        </SidePopupContainer>
    )
}
