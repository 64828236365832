import { images } from 'src/provider/config/constant'
import { useState, useContext, useEffect } from 'react'
import ApiContext from 'src/provider/API/call-service'
import LoanContext from 'src/provider/state-manager/loanProvider'
import { CircularProgressbar } from 'react-circular-progressbar'
import { BiReceipt } from 'react-icons/bi'
import UserContext from 'src/provider/state-manager/userProvider'
import { 
    SidePopupContainer, 
    SidePopup, 
    BorderlessButton, 
    AppSpan,  
    FlexRow,
    ScreenTitle,
    CustomContainer,
    AppText,
    OutlineButton,
    FlexColumn
} from "src/styles"
import utility from 'src/utils/utility'
import { LoanDetailsView } from 'src/popup'
import helpers from 'src/utils/helpers'
import { offerLetterBaseUrl } from 'src/provider/config/constant'


export const LoanData: React.FC<any> = ({close, data}) => {
    const {API} = useContext(ApiContext)
    const {user: {userData}} = useContext(UserContext)
    const {loan: {currentLoans, currentLoanInfo}} = useContext(LoanContext)
    const [running_repaidLoan, setRunning_RepaidLoan] = useState<any>(null)
    const [hasMadePayment, setHasMadePayment] = useState(false)
    const [showLoanDetails, setShowLoanDetails] = useState(false)

    useEffect(() => {
       getLoanInfo()
    }, [data])

    // useEffect(() => {
    //     setRunning_RepaidLoan(currentLoanInfo)
    // }, [currentLoanInfo])

    async function getLoanInfo() {
        //if (data?.completed) {
            const response = await API.getLoanRequestInfo(data?.loanRequestId)
            if (response) {
                setRunning_RepaidLoan(response)
                let hasPayment = response?.loanRepaymentResults.filter((item: any) => item?.paid)
                setHasMadePayment(hasPayment?.length > 0)
            }
        //}
    }

    const downloadFile = () => {
        const downloadLink = document.createElement('a');
        document.body.appendChild(downloadLink);
        downloadLink.download = 'Offer-letter';
        //downloadLink.href = `${url.LOAN_BASE_URL}/LoanOffer/DownloadLoanOfferLetter/${data?.loanOffer?.loanOfferId || data?.loanOfferId}`;
        downloadLink.href = `${offerLetterBaseUrl}/LoanOffer/DownloadLoanAgreementLetter/${data?.loanOffer?.loanOfferId || data?.loanOfferId}/${data?.loanRequestId}?customerId=${userData.customerId}`;
        console.log({downloadLink: downloadLink.href})
        downloadLink.click();
    }

    let amountPaidPerc = running_repaidLoan?.loanRequest ? (running_repaidLoan?.amountPaid/((data?.loanOffer?.totalRepaymentBorrower || data?.loanOffer?.totalRepaymentBorrower === 0) ? data?.loanOffer?.totalRepaymentBorrower : data?.totalAmountToBeRepaid)) * 100 : 0
    amountPaidPerc = Number(amountPaidPerc.toFixed(0))

    return (
        <>
            { !showLoanDetails &&
                <SidePopupContainer >
                    <SidePopup data-aos="slide-left">
                        <FlexRow justifyContent="space-between" topMargin="3" bottomMargin="4">
                            <ScreenTitle textSize="2">{data?.loanOffer?.loanTypeName}</ScreenTitle>
                            <FlexRow width="auto" sizeUnit="">
                                    <BorderlessButton 
                                        rightMargin="4" 
                                        hoverColor="#FED0B7"
                                        onClick={downloadFile}
                                    >
                                        <BiReceipt style={{fontSize: '2rem'}} />
                                        <AppSpan color="auto" leftPadding="0.5" textSize="1.3">View Full Contract</AppSpan>
                                    </BorderlessButton>
                                <AppSpan className="fa fa-times" color="#FED0B7" textSize="1.7" onClick={() => close(false)}></AppSpan>
                            </FlexRow>
                        </FlexRow>

                        <CustomContainer
                            topPadding="1.5" 
                            leftPadding="1.5"
                            rightPadding="1.5"
                            bottomPadding="1.5"
                            bgColor="#4700E0"
                            radius="0.8"
                        >
                            <FlexRow justifyContent="flex-start" topMargin="1" wrap="wrap">
                                <CustomContainer width="8" height="8" topMargin="1" rightMargin="1.5">
                                    <CircularProgressbar value={amountPaidPerc} text={`${amountPaidPerc}%`} 
                                        styles={{
                                            root: {},
                                            path: {
                                                stroke: `#6DE7B4`,
                                                strokeLinecap: 'butt',
                                                transition: 'stroke-dashoffset 0.5s ease 0s',
                                                transform: 'rotate(0.25turn)',
                                                transformOrigin: 'center center',
                                            },
                                            trail: {
                                                stroke: '#340697',
                                                strokeLinecap: 'butt',
                                                transform: 'rotate(0.25turn)',
                                                transformOrigin: 'center center',
                                            },
                                            text: {
                                                fill: '#fff',
                                                fontSize: '2rem',
                                                fontWeight: 700
                                            }
                                        }} 
                                    />
                                </CustomContainer>
                                <CustomContainer 
                                    width="auto" sizeUnit="" rightPadding='2'
                                    topMargin="1" rightMargin="2"
                                    style={{borderRight: '1px solid #A380EF'}}
                                >
                                    <AppSpan textSize="1.3" fontWeight="400">Paid</AppSpan>
                                    <AppText textSize="1.9">₦{utility.moneyFormat(running_repaidLoan?.amountPaid || 0)}</AppText>
                                </CustomContainer>
                                <CustomContainer width="auto" sizeUnit="" topMargin="1">
                                    <AppSpan textSize="1.3" fontWeight="400">Remaining payment</AppSpan>
                                    <AppText>₦{utility.moneyFormat(running_repaidLoan?.amountRemaining || 0)}</AppText>
                                </CustomContainer>
                            </FlexRow>
                            <FlexRow justifyContent="space-between" topMargin="3" wrap="wrap" rightPadding='3'>
                                <CustomContainer width="auto" sizeUnit="" rightMargin="2" topMargin="1">
                                    <AppSpan fontWeight="400" textSize="1.3">Total Amount</AppSpan>
                                    <AppText>₦{utility.moneyFormat((data?.loanOffer?.totalRepaymentBorrower || data?.loanOffer?.totalRepaymentBorrower === 0) ? data?.loanOffer?.totalRepaymentBorrower : data?.totalAmountToBeRepaid)}</AppText>
                                </CustomContainer>
                                <CustomContainer width="auto" sizeUnit="" rightMargin="2" topMargin="1">
                                    <AppSpan fontWeight="400" textSize="1.3">Duration</AppSpan>
                                    <AppText>{(data?.loanOffer?.tenor || data?.loanOffer?.tenor === 0) ? data?.loanOffer?.tenor : data?.tenor} {data?.loanOffer?.tenorType.toLowerCase() || data?.tenorType.toLowerCase()}(s)</AppText>
                                </CustomContainer>
                                <CustomContainer width="auto" sizeUnit=""  topMargin="1">
                                    <AppSpan fontWeight="400" textSize="1.3">Interest rate</AppSpan>
                                    <AppText>{(data?.loanOffer?.interest || data?.loanOffer?.interest === 0) ? data?.loanOffer?.interest : data?.interest}%</AppText>
                                </CustomContainer>
                            </FlexRow>
                            <FlexRow justifyContent="flex-start" topMargin="1" wrap="wrap">
                                <CustomContainer 
                                    width="auto" sizeUnit=""  rightPadding='1.8'
                                    topMargin="1" rightMargin="1.8"
                                    style={{borderRight: '1px solid #A380EF'}}
                                >
                                    <AppSpan fontWeight="400" textSize="1.3">Principal</AppSpan>
                                    <AppText textSize="1.3">₦{utility.moneyFormat(data?.loanOffer?.amountPerPerson || data?.amount)}</AppText>
                                </CustomContainer>
                                <CustomContainer width="auto" sizeUnit="" topMargin="1"  rightMargin="4">
                                    <AppSpan textSize="1.3" fontWeight="400">Interest</AppSpan>
                                    <AppText textSize="1.3">₦{utility.moneyFormat((data?.loanOffer?.interestAmount || data?.loanOffer?.interestAmount === 0) ? data?.loanOffer?.interestAmount : data?.interestAmount)}</AppText>
                                </CustomContainer>
                                {/* <div className="loan-data-sum-pay">
                                    <AppSpan textSize="1.3" fontWeight="400">Interest</AppSpan>
                                    <AppText textSize="1.3">₦150,000</AppText>
                                </div> */}
                                <FlexRow width="auto" sizeUnit=""  topMargin="1">
                                    <AppSpan textSize="1.4" rightMargin="1.5">Loan Status</AppSpan>
                                    <OutlineButton 
                                        hoverBgColor="transparent"  
                                        width="7" 
                                        height="2.4" 
                                        titleSize="1.2" 
                                        fontWeight="400" 
                                        color={data?.completed ? '#6DE7B4' : (!data?.completed && data?.confirmed) ? '#F5B070' : '#8095E0'}
                                        hoverColor={data?.completed ? '#6DE7B4' : (!data?.completed && data?.confirmed) ? '#F5B070' : '#8095E0'}
                                        radius="2"
                                    >
                                        {data?.completed ? 'Repaid' : (!data?.completed && data?.confirmed) ? 'Running' : 'Open'}
                                    </OutlineButton>
                                </FlexRow>
                            </FlexRow>
                        </CustomContainer>

                        <CustomContainer topMargin="2">
                            { (running_repaidLoan?.loanRepaymentResults?.length > 0) ?
                                running_repaidLoan?.loanRepaymentResults?.map((item: any, index: number) => {
                                    let type = item?.amountPaid === 0 ? 'Scheduled' : item?.scheduledPayment > item?.amountPaid ? 'Partial payment' : 'Paid'
                                    let typeColor = item?.amountPaid === 0 ? '#FF0062' : item?.scheduledPayment > item?.amountPaid ? '#FED0B7' : '#6DE7B4'
                                    let dateTime = helpers.splitDateTime(item?.paymentMadeDate || item?.paymentDate)
                                    let icon = item?.amountPaid === 0 ? images.LOAN_ICON_3 : item?.scheduledPayment > item?.amountPaid ? images.LOAN_ICON_4 : images.LOAN_ICON_5
                                    return (
                                        <FlexRow 
                                            bgColor="#000926" 
                                            justifyContent="space-between"
                                            topPadding="1" 
                                            leftPadding="1"
                                            rightPadding="1"
                                            bottomPadding="1"
                                            topMargin="0.7"
                                            radius="0.8"
                                            key={index}
                                        >
                                            <FlexRow width="auto" sizeUnit="" alignItems="flex-start">
                                                <img src={icon} style={{width: '2.4rem'}} />
                                                <CustomContainer width="auto" sizeUnit=""  leftMargin="1" topMargin="-0.5">
                                                    <AppSpan textSize="1.3" fontWeight="700">Loan Repayment</AppSpan>
                                                    <AppText textSize="0.9" fontWeight="400" color="#E2E2E2">{dateTime.date}, {dateTime.completeTime}</AppText>
                                                </CustomContainer>
                                            </FlexRow>
                                            <CustomContainer width="auto" sizeUnit=""  leftMargin="1">
                                                <AppSpan fontWeight="700" color="#F2EAE0" align="right">N{utility.moneyFormat(item.paid ? item.amountPaid : item.scheduledPayment)}</AppSpan>
                                                <AppText textSize="0.9" color={typeColor} align="right">{type}</AppText>
                                            </CustomContainer>
                                        </FlexRow>
                                    )
                                })
                                :
                                <>
                                    <FlexColumn topMargin='1'>
                                        <AppText textSize="1.8" width="25" topMargin="3" align="center">No Repayment Made</AppText>
                                        <AppSpan color="#E2E2E2" textSize="1.3" fontWeight="400" topMargin="1" align="center" width="30">No repayment has been made yet.</AppSpan>
                                    </FlexColumn>
                                </>
                            }
                        </CustomContainer>
                        <OutlineButton 
                            hoverBgColor="#FED0B7"  
                            width="100" 
                            height="5" 
                            sizeUnit="%"
                            color="#6DE7B4"
                            topMargin="4"
                            onClick={() => setShowLoanDetails(true)}
                        >
                            View Loan Details
                        </OutlineButton>
                    </SidePopup>
                </SidePopupContainer>
            }
            { showLoanDetails &&
                <LoanDetailsView
                    close={() => setShowLoanDetails(false)}
                    data={data}
                />
            }
        </>
    )
}
