import { appSettings } from "src/provider/config/constant";
import { ValidatorResponse } from "src/model"
import states from 'src/utils/states'

const inputValidator = (data: any, exemptedPropertes?: string[]): ValidatorResponse => {
    var result = <ValidatorResponse>{ isValidated: true }
    for (let key in data) {
        if (!data[key] && !exemptedPropertes?.includes(key)) {
            result.isValidated = false;
            result.message = `Input: Kindly provide ${key}`
            break;
        }
    }
    return result
} 

const generatePadNumbers = () => {
    let numbers: number[] = []
    
    while (numbers.length != 10) {
        let generatedNumber = Math.floor(Math.random() * 10);
        if(!numbers.includes(generatedNumber)) {
            numbers.push(generatedNumber);
        }
    }
    return numbers
}

const validatePhoneNo = (phoneNo: string) => {
    if (phoneNo.startsWith('+234') || phoneNo.startsWith('234')) {
        phoneNo = phoneNo.replace('+', '')
        phoneNo = phoneNo.replace('234', '0')
    }
    if (phoneNo.length === 10) phoneNo = `0${phoneNo}`
    if ((['070', '080', '081', '090', '091'].includes(phoneNo.substring(0, 3))) && phoneNo.length === 11)
        return true;
    else return false
}

const getStateId = (state: string): number => {
    var stateId = NaN;
    for (let i = 0; i < states.length; i++) {
        if (states[i].state === state) {
            stateId = i; 
            break;
        }
    }

    return stateId
}

const convertHMStoSeconds = (time: string): number => {
    var splittedTime = time.split(':')
    let hrTs = Number(splittedTime[0]) * 3600
    let MinTos = Number(splittedTime[1]) * 60
    let seconds = Number(splittedTime[2])

    return Math.floor(Math.abs(seconds) + Math.abs(MinTos) + Math.abs(hrTs))
}

const convertSecondsToHMS = (seconds: number): string => {
    var h = String(Math.floor(seconds / 3600));
    var m = String(Math.floor(seconds % 3600 / 60));
    var s = String(Math.floor(seconds % 3600 % 60));

    h = h.length === 1 ? `0${h}` : h
    m = m.length === 1 ? `0${m}` : m
    s = s.length === 1 ? `0${s}` : s
    return `${h}:${m}:${s}`
}

const formatTimeInfo = (time: string): string => {
    if (time.length > 1) {
        if (time[0] === '0') time = time[1]
    }

    return time
}

const splitDateTime = (data: string) => {
    let [date, time] = data.split('T')
    let [year, month, day] = date.split('-')
    let [hours, minutes] = time.split(':')

    month = (month.length > 1 && month[0] === '0') ? month[1] : month
    const monthShort = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
    const formattedHours = (hours.length > 1 && hours[0] === '0') ? hours[1] : hours

    return {
        date: `${day} ${monthShort[Number(month)-1]} ${year}`,
        slashedDate: `${day}/${month}/${year}`,
        time: `${hours}:${minutes}`,
        timeEnv: `${Number(formattedHours) < 12 ? 'am' : 'pm'}`,
        completeTime: `${hours}:${minutes}${Number(formattedHours) < 12 ? 'am' : 'pm'}`
    }

}

const formatNotification = (notifications: any) => {
    const notifGroup = <any>{}
    const formattedNotifications = []

    for (let notif of notifications) {
        let  notifDate = formatDate(new Date(notif.dateAdded))
        const val = notifGroup[notifDate]
        if(val?.length) notifGroup[notifDate] = sortArrayOfObjects([...val, notif], 'dateAdded')
        else notifGroup[notifDate] = [notif]
    }

    for (let key in notifGroup) {
        formattedNotifications.push({
            date: key,
            info: notifGroup[key],
            groupLatestDate: notifGroup[key][0]?.dateAdded || ''
        })
    }

    console.log({formattedNotifications})

    return sortArrayOfObjects(formattedNotifications, 'groupLatestDate')
}

const formatDate = (dateTime: any) => {
    const fullDays = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']

    let dt = new Date(dateTime),
    date = dt.getDate(),
    diffDays = new Date().getDate() - date,
    diffMonths = new Date().getMonth() - dt.getMonth(),
    diffYears = new Date().getFullYear() - dt.getFullYear()

    if (diffYears === 0 && diffMonths === 0 && diffDays === 0) return 'Today'
    else if (diffYears === 0 && diffMonths === 0 && diffDays === 1) return 'Yesterday'
    else if (diffYears === 0 && diffMonths === 0 && (diffDays > 1 && diffDays < 7)) return fullDays[dateTime.getDay()] 
    else if (diffYears === 0 && diffMonths === 0 && (diffDays >= 7 && diffDays < 14)) return 'Last week'
    else if (diffYears === 0 && diffMonths === 0 && diffDays >= 14) return 'This month'
    else if (diffYears === 0 && diffMonths === 1) return 'Last month'
    else if (diffYears === 0 && diffMonths > 1) return 'Over 1 month'
    else if (diffYears === 1 && diffMonths >= 0) return 'A year ago'
    else if (diffYears === 1 && diffMonths < 0) return 'Last year'
    else return 'Over a year'
}

function sortArrayOfObjects (array: any[], sortBy: string) {
    array.sort((a, b) => {
        let x = a[sortBy].toLowerCase(),
            y = b[sortBy].toLowerCase();
    
        if (x < y) {
            return 1;
        }
        if (x > y) {
            return -1;
        }
        return 0;
    });

    return array
}

const formatSeconds = (seconds: number|string) => {
    seconds = String(seconds)
    return `0:${seconds.length < 2 ? `0${seconds}` : seconds}`
}

const getAge = (dateString: string) => {
    var today = new Date();
    var birthDate = new Date(dateString);
    var age = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--;
    }
    return age;
}

const isValidFileType = (filename: string) => {
    let result = true
    let splittedFileName = filename.split('.')
    let extension = splittedFileName[splittedFileName.length - 1].toLowerCase()
    if (!['jpeg', 'png', 'jpg'].includes(extension)) result = false
        return result
}

const getTimeDifference = (date: string) => {
    let hasNotExceededTime = true
    const difference = (new Date().getTime() - new Date(date).getTime())/1000
    if (difference > 86400) hasNotExceededTime = false
    return {
        hasNotExceededTime,
        seconds: 86400 - difference
    }
}

const validateIdCardDate = (issueDate: string, expiryDate: string) => {
    let isValid = true
    let message = ''
    const today = new Date().toISOString().split('T')[0]
    let todayToSec = new Date(today).getTime()/1000
    let issueDateToSec = new Date(issueDate).getTime()/1000
    let expiryDateToSec = new Date(expiryDate).getTime()/1000

    if (todayToSec - issueDateToSec < 0) {
        isValid = false
        message = 'Card issue date is not valid'
    }

    if (expiryDateToSec - todayToSec <= 0) {
        isValid = false
        message = 'Card expiry date is not valid'
    }
    
    return {
        isValid,
        message
    }
}

const extractChartData = (info: Array<any>, property: string) => {
    const output = []

    for (let i = 1; i <= 12 ; i++) {
        let result = 0
        for (let item of info) {
            if (i === item?.month) {
                result = item[property]
                break;
            }
        }
        output.push(result)
    }

    //console.log({chartData: output})
    return output
}

const filterRequest_Offer = (data: any, filterObject: any) => {
    let output = []
    for (let item of data) {
        if (filterObject.amountFrom) {
            if ((item.amountPerPerson || item.amount) < Number(filterObject.amountFrom)) continue
        }
        if (filterObject.amountTo) {
            if ((item.amountPerPerson || item.amount) > Number(filterObject.amountTo)) continue
        }
        if (filterObject.interestFrom) {
            if (item.interest < Number(filterObject.interestFrom)) continue
        }
        if (filterObject.interestTo) {
            if (item.interest > Number(filterObject.interestTo)) continue
        }
        if (filterObject.durationFrom) {
            if (item.tenor < Number(filterObject.durationFrom)) continue
        }
        if (filterObject.durationTo) {
            if (item.tenor > Number(filterObject.durationTo)) continue
        }

        output.push(item)
    }

    return output
}

const validateEmail = (email: string): boolean => {
    return /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)
}
const validateVNIN = (str: string) => {
    let result = true
    if (str.length != 16) return false
    str = str.toUpperCase()
    const strMidNumbers = str.substring(2, str.length - 2)
    const isValidNumbers =  /^\d+$/.test(strMidNumbers)
    if (!isValidNumbers) result = false
    const firstTwoCharacters = str.substring(0, 2)
    console.log({firstTwoCharacters})
    if (
        (firstTwoCharacters[0].toLowerCase() ===  firstTwoCharacters[0]) 
        || (firstTwoCharacters[1].toLowerCase() ===  firstTwoCharacters[1]) 
    ) result = false
    
    return result
}

const validateNIN = (str: string) => {
    if (str.length != 11) return false
    const isValidNumbers =  /^\d+$/.test(str)
    if (!isValidNumbers) return false

    return true
}

const validateOtherIdCards = (str: string, idType: string) => {
    if (idType === '4') {
       return  /^(?=.*[a-zA-Z]).{9}$/.test(str)
    }
    else if (idType === '2') {
        return str.length === 11
    }
    else {
        return str.length === 9
    }
}

const getInputValidDate = (date?: any) => {
    let dtToday = date ? new Date(date) : new Date() 

    let [month, day, year]: Array<any> =  [
        dtToday.getMonth() + 1,
        dtToday.getDate(),
        dtToday.getFullYear()
    ]

    if (month < 10) month = `0${month}`
    if (day < 10) day = `0${day}`

    return `${year}-${month}-${day}`
}

const addMomentToDate = (duration: number, period: string, type = 'prev'): string => {
    const initiaDate = new Date()

    if (period === 'hours') {
        initiaDate.setHours(initiaDate.getHours() + (type === 'prev' ? -duration : duration))
    }
    else if (period === 'days') {
        initiaDate.setDate(initiaDate.getDate() + (type === 'prev' ? -duration : duration))
    }
    else if (period === 'months') {
        initiaDate.setMonth(initiaDate.getMonth() + (type === 'prev' ? -duration : duration))
    }
    else if (period === 'years') {
        initiaDate.setFullYear(initiaDate.getFullYear() + (type === 'prev' ? -duration : duration))
    }

    return initiaDate.toISOString()
}

const setSessionExpiryTime = (type = 'loggedIn') => {
    const date = new Date()
    //date.setTime( date.getTime() - new Date().getTimezoneOffset() * 60 * 1000 );
    date.setMinutes(date.getMinutes() + appSettings.SESSION_EXPIRATION_LENGTH)
    if (type === 'loggedIn') localStorage.setItem('session-expiry-time', date.toString())
    else localStorage.setItem('onboarding-session-expiry-time', date.toString())
}

const getPrimaryAccountDetails = (userData: any) => {
    let primaryAccount = null

    for (let account of userData?.bankAccounts) {
        if (account.isPrimaryAccount) {
            primaryAccount = account;
            break;
        }
    }

    return primaryAccount || userData?.bankAccounts?.[0] || {}
}

export * from 'src/utils/view'

export default {
    inputValidator,
    generatePadNumbers,
    validatePhoneNo,
    getStateId,
    convertHMStoSeconds,
    convertSecondsToHMS,
    formatTimeInfo,
    splitDateTime,
    formatNotification,
    formatDate,
    formatSeconds,
    sortArrayOfObjects,
    getAge,
    isValidFileType,
    getTimeDifference,
    validateIdCardDate,
    extractChartData,
    filterRequest_Offer,
    validateEmail,
    validateVNIN,
    validateNIN,
    validateOtherIdCards,
    getInputValidDate,
    addMomentToDate,
    setSessionExpiryTime,
    getPrimaryAccountDetails
}