import React, { useState, useContext, useEffect, useRef } from 'react'
import utility from 'src/utils/utility'
import ApiContext from 'src/provider/API/call-service'
import VisibilityContext from 'src/provider/state-manager/visibilityProvider'
import AppInfoContext from 'src/provider/state-manager/appInfoProvider'
import { useNavigate } from 'react-router-dom'
import { Container } from 'src/component'
import helpers from 'src/utils/helpers'
import { AppLink, AppSpan, AppText, Button, CustomContainer, FlexColumn, FlexRow, FormGroup, FormGroupWithIcon, GridContainer, ScreenTitle } from 'src/styles'
import { BsChevronLeft } from 'react-icons/bs'
import { GoChevronDown } from 'react-icons/go'
import Okra from "okra-js"
import { credentials } from 'src/provider/config/constant'
import { images } from 'src/provider/config/constant'
import { NoAuthPopup } from 'src/popup'
import MonoConnect from '@mono.co/connect.js';


const BankDetails = () => {
    const navigate = useNavigate()
    const {API} = useContext(ApiContext)
    const {notifier} = useContext(VisibilityContext)
    const {info: {onBoardingData}, logout} = useContext(AppInfoContext)
    const [showPopup, setShowPopup] = useState(false)

    const monoConnect = React.useMemo(() => {
        const monoInstance = new MonoConnect({
          onClose: () => console.log('Mono widget closed'),
          onLoad: () => console.log('Mono widget loaded successfully'),
          onSuccess: ({ code }: any) => linkAccountWithMono(code),
          key: credentials.MONO_PUBLIC_KEY
        })
    
        monoInstance.setup()
        
        return monoInstance;
      }, [])

    function handleOkraWidget () {
        Okra.buildWithOptions({
            name: credentials.OKRA_NAME,
            env: credentials.OKRA_ENV,
            app_id: credentials.OKRA_APP_ID,
            key: credentials.OKRA_KEY, 
            token: credentials.OKRA_TOKEN,
            currency: 'NGN',
            color:  '#160e2e',
            limit: '24',
            logo: credentials.CRENDLY_LOGO,
            products: ['auth','identity','balance','transactions', 'income'],
            onSuccess: function(data: any){
                console.log('okra success', data)
                linkAccountWithOkra(data)
            },
            onError: function (data: any) {
                console.log('okra error', data)
                notifier.show("Error occured while adding bank account")
            },
            onClose: function(){
                console.log('okra close')
            }
        })
    }

    async function linkAccountWithOkra (data: any) {
        const payload = {
            bank: data.auth.bank_details?.name,
            accountNumber: data.accounts?.length ? String(data.accounts[0]?.nuban) : String(data.accounts?.nuban),
            accountName: data.accounts?.length ? data.accounts[0]?.name : data.accounts?.name,
            okraCustomerId: data.customer_id,
            incomeServiceProvider: 2
        }

        handleAccountLinking(payload)
    }

    async function linkAccountWithMono (code: string) {
        const payload = {
            okraCustomerId: code,
            incomeServiceProvider: 1
        }

        handleAccountLinking(payload)
    }

    async function handleAccountLinking (payload: any) {
        const response = await API.linkBank(payload)
        if (response) setShowPopup(true)
    }

    async function handleLogin () {
        const response = await API.signIn({email: onBoardingData.email, password: onBoardingData.passcode})
        if (response) navigate('/dashboard/home')
        else logout()
    }

    return (
        <>
             <Container type="onboarding" progressBar='84.59'>
                <p className="right-align-text">Already have an account? 
                    <a className="registration-link" onClick={() => navigate('/login')}> Sign in</a>
                </p>
                <div className="onboarding-container">
                    <BsChevronLeft className="loan-back-icon" onClick={() => navigate(-1)} />
                    <ScreenTitle textSize="3" bottomMargin="1.5" topMargin="3" >
                        Add your bank account
                    </ScreenTitle>
                    <AppText color="#D5D5D5" textSize="1.4" fontWeight="400" bottomMargin="2">
                        We’ll use it to generate your account statement and confirm your loan eligibility.
                    </AppText>

                    <div className="option-2-btns">
                        <AppLink className="negotiate-loan-btn" onClick={() => setShowPopup(true)} >Do this later</AppLink>
                        <Button 
                            width="15" 
                            height="4.5" 
                            hoverBgColor="#FFC49D"
                            leftMargin="3" 
                            className="loan-action-btn"
                            onClick={() => monoConnect.open()}
                        >
                            Add Bank
                        </Button>
                    </div>

                    {/* <FlexRow 
                        width='auto' sizeUnit='' wrap='wrap'
                        justifyContent='flex-start' alignItems='flex-start'
                    >
                        <GridContainer
                            width='22'  minHeight='5.5' topPadding='0' bottomPadding='0'
                            radius='0.8' padding='1.5' bgColor='#081952' 
                            rightMargin='2' topMargin='1.5'
                            className='otp-resend-btn'
                            onClick={handleOkraWidget}
                        >
                            <AppLink textSize="1.4">
                                Link Account Via Okra
                            </AppLink>
                        </GridContainer>
                        <GridContainer
                            width='22' minHeight='5.5' topPadding='0' bottomPadding='0'
                            radius='0.8' padding='1.5' bgColor='#081952' topMargin='1.5'
                            className='otp-resend-btn'
                            onClick={() => monoConnect.open()}
                        >
                            <AppLink textSize="1.4">
                                Link Account Via Mono
                            </AppLink>
                        </GridContainer>
                    </FlexRow> */}

                    {/* <FlexColumn topMargin='10' width='90' sizeUnit='%'>
                        <AppText textSize='1.3' color='#E2E2E2' fontWeight='400' align='center'>
                            <AppSpan textSize='1.3' color='#E2E2E2'>NB</AppSpan>
                            : When linking via Okra, Check under "<AppSpan textSize='1.3' color='#E2E2E2'>internet banking</AppSpan>"
                            or "<AppSpan textSize='1.3' color='#E2E2E2'>mobile banking</AppSpan>"
                            if you did not see your bank in either section in the popup widget.
                        </AppText>
                    </FlexColumn> */}
                </div>
            </Container>
            { showPopup &&
                <NoAuthPopup 
                    close={() => setShowPopup(false)} 
                    text="Welcome to Crendly"
                    nextMethod={handleLogin}
                    responseIcon={images.SUCCESS_RESPONSE}
                />
            }
        </>
    )
}

export default utility.routeData('bank-details', 'Bank details', <BankDetails/>)