import './style.scss'
import { useState, useEffect, useContext, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import ApiContext from 'src/provider/API/call-service'
import UserContext from 'src/provider/state-manager/userProvider'
import AppInfoContext from 'src/provider/state-manager/appInfoProvider'
import utility from 'src/utils/utility'
import DataTable from 'react-data-table-component'
import { 
    AbsoluteContainer,
    AppSpan, AppText, 
    CustomContainer, 
    FlexColumn, FlexRow, 
    FormGroup, 
    Icon,
    ScreenContainer, 
    ScreenTitle
} from 'src/styles'
import { images } from 'src/provider/config/constant'
import { TransactionDetails } from 'src/popup'
import { transactionColumns, customStyles } from 'src/utils/tableLayout' 
import { FiSearch } from 'react-icons/fi'
import { BiMenuAltRight } from 'react-icons/bi'
import { BsCheck } from 'react-icons/bs'
import { transFilters } from 'src/provider/config/constant'

const Transactions = () => {
    const navigate = useNavigate()
    const {API} = useContext(ApiContext)
    const {user} = useContext(UserContext)
    const {info:{transactions}} = useContext(AppInfoContext)
    const [hasTransaction, setHasTransaction] = useState(false)
    const [allTransactions, setAllTransactions] = useState<any>(null)
    const [myTransaction, setMyTransaction] = useState<any>(null)
    const [selectedTransaction, setSelectedTransaction] = useState({})
    const [searchText, setSearchText] = useState('')
    const [input, setInput] = useState({startDate: '', endDate: ''})
    const [isDropDown, setIsDropDown] = useState(false)
    const [filterBy, setFilterBy] = useState('')
    const [showDetails, setShowDetails] = useState(false)

    useEffect(() => {
        if(showDetails) {
          document.body.style.overflow = 'hidden'
        }
        else document.body.style.overflow = 'auto'
    }, [showDetails])

    useEffect(() => {
        if (user.userData?.mobileNo || user.userData?.phoneNumber) getAllTransactions()
    }, [])

    async function getAllTransactions () {
        const response = await API.fetchTransactions()
        if (response?.length) {
            setAllTransactions(response) // reversed before
            setMyTransaction(response) // reversed before
            setHasTransaction(true)
        }
    }

    async function handleDate (e: React.ChangeEvent<HTMLInputElement>) {
        setInput({...input, [e.target.name]: e.target.value})
        let payload = {...input, [e.target.name]: e.target.value}

        if (payload.startDate && payload.endDate) {
            const response = await API.fetchTransactionsByDate(payload)
            console.log({response})
            setAllTransactions(response.length ? response : []) // response reversed before
            setMyTransaction(response.length ? response : []) // // response reversed before
            setHasTransaction(response.length ? true : false)
        }
    }

    function handleDropDown (type: string) {
        setIsDropDown(false)
        setFilterBy(type)
        setSearchText('')
        let filtered = allTransactions.filter((item: any) => item.transactionType === type)
        setMyTransaction(filtered.length ? filtered : allTransactions)
    }

    function handleSearch (text: string) {
        let searched = []
        setSearchText(text)

        for (let item of allTransactions) {
            for (let character in item) {
               if (String(item[character]).toLowerCase().includes(text.toLowerCase())) {
                    searched.push(item);
                    break;
               }
            }
        }
        console.log({searched})
        setMyTransaction(searched)
    }

    function handleShowDetails (data: any) {
        setSelectedTransaction(data)
        setShowDetails(true)
    }

    return (
        <>
            <ScreenContainer>
                <CustomContainer topMargin="2" rightPadding="2" leftPadding="2" className="trans-screen-container">
                    <ScreenTitle bottomPadding="3" textSize="2">All Transaction History</ScreenTitle>
                    <FlexRow className='filter-search-transaction-wrapper'>
                        <form className="search-transaction-wrapper">
                            <FiSearch 
                                className="search-icon-center" 
                                style={{color: searchText ? '#6DE7B4' : '#999'}}
                            />
                            <input 
                                className="search-offer-input" 
                                placeholder="Search transaction"
                                value={searchText}
                                onChange={(e) => handleSearch(e.target.value)}
                            />
                        </form>
                        <FlexRow className='filter-transaction-wrapper' width='auto' sizeUnit=''>
                            <FormGroup className='trans-date'>
                                {/* <label>Start date</label> */}
                                <input 
                                    type="text" 
                                    name="startDate" 
                                    placeholder='Start Date'
                                    value={input.startDate} 
                                    onBlur={(e) => (e.target.type = 'text')}
                                    onFocus={(e) => (e.target.type = 'date')}
                                    style={{color: input.startDate ? '#fff' : '#999'}}
                                    onChange={handleDate} 
                                />
                                {/* 📅 */}
                            </FormGroup>
                            <FormGroup className='trans-date'>
                                {/* <label>End date</label> */}
                                <input 
                                    type="text" 
                                    name="endDate" 
                                    placeholder='End Date'
                                    value={input.endDate} 
                                    onBlur={(e) => (e.target.type = 'text')}
                                    onFocus={(e) => (e.target.type = 'date')}
                                    style={{color: input.endDate ? '#fff' : '#999'}}
                                    onChange={handleDate} 
                                />
                            </FormGroup>
                            <AppSpan 
                                className="trans-filter-icon"
                                onClick={() => setIsDropDown(!isDropDown)}
                            >
                                Filter
                                <BiMenuAltRight className="flex-item-icon"/>
                            </AppSpan>

                            { isDropDown &&
                                <AbsoluteContainer top='5' right='0'>
                                    <CustomContainer 
                                        bgColor='#081952' width='18'
                                        radius='0.4'
                                        topPadding='1' bottomPadding='1'
                                        style={{boxShadow: '0px 10px 50px rgba(0, 0, 0, 0.5)'}}
                                    >
                                        {
                                            transFilters.map((item: any, index: number) => {
                                                return (
                                                    <FlexRow className='sort-select-option' onClick={() => handleDropDown(item.value)} key={index}>
                                                        <AppSpan fontWeight="400" textSize="1.3">{item.name}</AppSpan>
                                                        {filterBy === item.value ? <BsCheck style={{color: '#FED0B7', fontSize: '2rem'}} /> : null}
                                                    </FlexRow>
                                                )
                                            })
                                        }
                                    </CustomContainer>
                                </AbsoluteContainer>
                            }
                        </FlexRow>
                    </FlexRow>
                    { !myTransaction?.length &&
                        <FlexColumn topMargin="15" >
                            <Icon src={images.NO_TRANSACTION} />
                            <AppText textSize="1.8" width="25" topMargin="3" align="center">No Transactions yet</AppText>
                            <AppSpan color="#E2E2E2" textSize="1.3" fontWeight="400" topMargin="1" align="center" width="26">
                                You haven’t made any transactions yet.
                            </AppSpan>
                        </FlexColumn>
                    }
                    { myTransaction?.length > 0 &&
                        <CustomContainer className="transaction-data-wrapper">
                            <DataTable 
                                columns={transactionColumns} 
                                data={myTransaction} 
                                customStyles={customStyles}
                                pagination
                                onRowClicked={handleShowDetails}
                                // paginationPerPage={2}
                                // paginationRowsPerPageOptions={[2, 4]}
                            />
                        </CustomContainer>
                    }
                </CustomContainer>
            </ScreenContainer>
            { showDetails &&
                <TransactionDetails 
                    close={() => setShowDetails(false)}
                    data={selectedTransaction}
                />
            }
        </>
    )
}

export default utility.routeData('transactions', 'Transactions', <Transactions/>)