import './style.scss'
import { useState, useEffect, useContext } from 'react'
import utility, { formatNegativeCharacter } from 'src/utils/utility'
import LoanContext from 'src/provider/state-manager/loanProvider'
import { useNavigate } from 'react-router-dom'
import { BsChevronLeft } from 'react-icons/bs'
import { FormGroup, SelectButton, Button, ScreenContainer, CustomContainer, Divider, AbsoluteContainer, AppSpan, LevelBar } from "src/styles"
import helpers from 'src/utils/helpers'
import { tenorData } from 'src/provider/config/constant'

const LoanTenor = () => {
    const navigate = useNavigate()
    const {loan: {loanCreation}, setLoanProperty} = useContext(LoanContext)
    const [isValidated, setIsValidated] = useState(false)
    const [input, setInput] = useState({tenor: loanCreation?.tenor || 0, tenorType: loanCreation?.tenorType || '', rateId: loanCreation?.rateId || 0, repaymentTermId: loanCreation?.repaymentTermId || 0})

    var tenorList = input.repaymentTermId === 2 ? tenorData.days : input.repaymentTermId === 3 ? tenorData.weeks : input.repaymentTermId === 5 ? tenorData.months : tenorData.default

    useEffect(() => {
        const auth = helpers.inputValidator(input)
        setIsValidated(auth.isValidated)
    }, [input])

    // function handleInterest (val: string) {
    //     var interest = input.interest
    //     if (val == 'Yes') interest = ""
    //     else interest = '0'
    //     setInput({...input, isInterest: val, interest: interest})
    // }

    async function handleNext () {
        await setLoanProperty(
            'loanCreation', 
            {...loanCreation, ...input}
        )
        navigate('/dashboard/loan-creation/repayment-option')
    }

    return (
        <ScreenContainer>
            <div className="loan-type-container">
                <div className="loan-type-title">
                    <div className="loan-type-title-flex">
                        <BsChevronLeft className="loan-back-icon" onClick={() => navigate(-1)} />
                        <div className="loan-type-title-content">
                            <p >{loanCreation?.rateId ? 'Edit' : 'Create'} Loan Portfolio</p>
                            <span>
                                Show how much you want to lend to qualified borrowers. Credit your portfolio with any amount,
                                include more details like loan type and interest rate to help us find the right match for you.
                            </span>
                        </div>
                    </div>
                </div>
                <div className="loan-type-content">
                    <LevelBar topMargin='4' strokeWidth="50"><span></span></LevelBar>
                    <FormGroup topMargin="3">
                        <label>Choose a repayment option</label>
                        <div className="loan-tenor-types">
                            <SelectButton width="16" height="4">
                                <input type="radio" name="payment-type" checked={input.rateId === 1} onChange={() => setInput({...input, rateId: 1, repaymentTermId: 0, tenor: 0, tenorType: ''})} />
                                <span></span>
                                <label>Installment</label>
                            </SelectButton>
                            <SelectButton width="16" height="4" >
                                <input type="radio" name="payment-type" checked={input.rateId === 2} onChange={() => setInput({...input, rateId: 2, repaymentTermId: 0, tenor: 0, tenorType: ''})} />
                                <span></span>
                                <label>One-Off</label>
                            </SelectButton>
                        </div>
                    </FormGroup>
                    { input?.rateId != 0 &&
                        <>
                            <Divider bgColor="#1E3177" />
                            <FormGroup topMargin="4">
                                <label>{input?.rateId === 1 ? 'Repayment occurence' : 'Tenor Duration Type'}</label>
                                <div className="loan-tenor-types">
                                    <SelectButton width="10" height="4">
                                        <input type="radio" name="payment-duration" checked={input.repaymentTermId === 2} onChange={() => setInput({...input, repaymentTermId: 2, tenor: 0, tenorType: ''})}/>
                                        <span></span>
                                        <label>{input?.rateId === 1 ? 'Daily' : 'Day'}</label>
                                    </SelectButton>
                                    <SelectButton width="10" height="4">
                                        <input type="radio" name="payment-duration" checked={input.repaymentTermId === 3} onChange={() => setInput({...input, repaymentTermId: 3, tenor: 0, tenorType: ''})} />
                                        <span></span>
                                        <label>{input?.rateId === 1 ? 'Weekly' : 'Week'}</label>
                                    </SelectButton>
                                    {/* <SelectButton width="10" height="4">
                                        <input type="radio" name="payment-duration" checked={input.repaymentTermId === 4} onChange={() => setInput({...input, repaymentTermId: 4, tenor: 0, tenorType: ''})} />
                                        <span></span>
                                        <label>BiWeekly</label>
                                    </SelectButton> */}
                                    <SelectButton width="10" height="4">
                                        <input type="radio" name="payment-duration" checked={input.repaymentTermId === 5} onChange={() => setInput({...input, repaymentTermId: 5, tenor: 0, tenorType: ''})}/>
                                        <span></span>
                                        <label>{input?.rateId === 1 ? 'Monthly' : 'Month'}</label>
                                    </SelectButton>
                                </div>
                            </FormGroup>
                        </>
                    }
                        
                    { input.repaymentTermId != 0 &&
                        <FormGroup topMargin="3">
                            <Divider bgColor="#1E3177" />
                            <label>How long is this loan going to run?</label>
                            <div className="loan-tenor-types">
                                {
                                    tenorList?.data?.map((item: number, index: number) => {
                                        let label = (tenorList.tenorType === 'default' && index === 0) ? 'Days' : (tenorList.tenorType === 'default' && index === 1) ? 'Weeks' : tenorList.tenorType === 'default' ? 'Months' :  tenorList.label
                                        let term = (tenorList.tenorType === 'default' && index === 0) ? 'DAY' : (tenorList.tenorType === 'default' && index === 1) ? 'WEEK' : tenorList.tenorType === 'default' ? 'MONTH' : tenorList.tenorType
                                        return (
                                            <SelectButton width="10" height="4" key={index}>
                                                <input type="radio" name="loan-duration" checked={input.tenor === item} onChange={() => setInput({...input, tenor: item, tenorType: term})} />
                                                <span></span>
                                                <label>{item} {label}</label>
                                            </SelectButton>
                                        )
                                    })
                                }
                            </div>
                        </FormGroup>
                    }
                    { (input.repaymentTermId != 0) &&
                        <>
                            <CustomContainer topMargin='-3'>
                                <Divider bgColor="#1E3177" margin='2.5' />
                                <AbsoluteContainer className='center-absolute'>
                                    <CustomContainer 
                                        borderColor='#0F1B46'
                                        rightPadding='2' leftPadding='2' bgColor='#000926'
                                    >
                                        <AppSpan>or</AppSpan>
                                    </CustomContainer>
                                </AbsoluteContainer>
                            </CustomContainer>
                                <FormGroup>
                                <label>Number of {tenorList.label}</label>
                                <input type="number" value={input.tenor || ''} placeholder={`Enter Number of ${tenorList.label}`} onChange={(e) => setInput({...input, tenor: Number(formatNegativeCharacter(e.target.value)), tenorType: tenorList.tenorType})} />
                            </FormGroup>
                        </>
                    }
                    <div className="loan-creation-wrapper-plus">
                        <span onClick={() => navigate(-1)}>Back</span>
                        <Button
                            width="13"
                            hoverBgColor="#FED0B7"
                            className="loan-action-btn"
                            disabled={!isValidated ? true : false}
                            onClick={handleNext}
                        >
                            Next
                        </Button>
                    </div>
                </div>
            </div>
        </ScreenContainer>
    )
}

export default utility.routeData('loan-creation/loan-tenor', 'Loan Tenor', <LoanTenor/>)