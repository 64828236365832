import './style.scss'
import { useState, useEffect, useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import utility from 'src/utils/utility'
import ApiContext from 'src/provider/API/call-service'
import UserContext from 'src/provider/state-manager/userProvider'
import LoanContext from 'src/provider/state-manager/loanProvider'
import { FaHandsHelping } from 'react-icons/fa'
import { FiPrinter } from 'react-icons/fi'
import { RiSendPlaneFill } from 'react-icons/ri'
import { VscGitPullRequestCreate } from 'react-icons/vsc'
import { AppSpan, AppText, BorderlessButton, Button, Circle, CustomContainer, FlexColumn, FlexRow, Icon, OutlineButton, ScreenContainer } from 'src/styles'
import { images } from 'src/provider/config/constant'
import { 
    RepayLoan, FundPortfolio, 
    Withdraw, LoanData, Portfolio, 
    PortfolioDetails, EndPortfolio, LoanDetailsView
} from 'src/popup'
import { InterestChart, LoanChart, PortfolioChart } from 'src/component'
import "react-responsive-carousel/lib/styles/carousel.min.css"; 
import { Carousel } from 'react-responsive-carousel';

const Loan = () => {
    const navigate = useNavigate()
    const {API} = useContext(ApiContext)
    const {user} = useContext(UserContext)
    const {loan: {currentLoans, allUserPortfolios, userRunningPortfolios}, setLoanProperty} = useContext(LoanContext)
    const [infoType, setInfoType] = useState('loan')
    const [loanType, setLoanType] = useState('all')
    const [portfolioType, setPortfolioType] = useState('all')
    const [hasLoan, setHasLoan] = useState(false)
    const [hasPortfolio, setHasPortfolio] = useState(true)
    const [allLoans, setAllLoans] = useState<any>(null)
    const [runningLoans, setRunningLoans] = useState<any>(null)
    const [repaidLoans, setRepaidLoans] = useState<any>(null)
    const [portfoliosToFund, setPortfoliosToFund] = useState<any>(null)
    const [allPortfolios, setAllPortfolios] = useState<any>(null)
    const [runningPortfolios, setRunningPortfolios] = useState<any>(null)
    const [endedPortfolios, setEndedPortfolios] = useState<any>(null)
    const [showLoanRepay, setShowLoanRepay] = useState(false) 
    const [showFundPortfolio, setShowFundPortfolio] = useState(false)
    const [showWithdraw, setShowWithdraw] = useState(false)
    const [showLoanData, setShowLoanData] = useState(false)
    const [showLoanDetails, setShowLoanDetails] = useState(false)
    const [showPortfolio, setShowPortfolio] = useState(false)
    const [showPortfolioDetails, setShowPortfolioDetails] = useState(false)
    const [showEndPortfolio, setShowEndPortfolio] = useState(false)
    const [selectedLoan, setSelectedLoan] = useState<any>({})
    const [selectedPortfolio, setSelectedPortfolio] = useState({})
    const [availableLoans, setAvailableLoans] = useState<any>(currentLoans)
    const [amountToRepay, setAmountToRepay] = useState(0)

    useEffect(() => {
        if(
            showLoanRepay || showFundPortfolio 
            || showWithdraw || showLoanData || showPortfolio
            || showPortfolioDetails || showEndPortfolio
        ) {
          document.body.style.overflow = 'hidden'
        }
        else document.body.style.overflow = 'auto'
      }, [
          showLoanRepay, showFundPortfolio, 
          showWithdraw, showLoanData, showPortfolio, 
          showPortfolioDetails, showEndPortfolio
        ]
    )

    useEffect(() => {
        if (user.userData.mobileNo || user.userData.phoneNumber) {
            fetchLoans_Offers()
        }
    }, [user.userData])

    useEffect(() => {
        if (allUserPortfolios?.length) fetchUserOffers(allUserPortfolios)
        if (userRunningPortfolios?.length) setRunningPortfolios(userRunningPortfolios)
    }, [allUserPortfolios, userRunningPortfolios])

    useEffect(() => {
        setAvailableLoans(currentLoans)
    }, [currentLoans])

    function fetchLoans_Offers () {
        fetchUserLoans()
        fetchUserOffers()
        fetchAmountToRepay()
    }

    async function fetchUserLoans () {
        const response = await API.fetchAllUserLoans()
        setHasLoan(response?.length > 0)
        setAllLoans(response)
    }

    async function fetchUserOffers (offers?: any) {
        const response = offers ? offers : await API.fetchAllUserOffers()
        const fundPortfolios = response?.filter((item: any) => item.amountLocked < item.amount)
        setHasPortfolio(response?.length > 0)
        setAllPortfolios(response)
        setPortfoliosToFund(fundPortfolios)
    }

    async function fetchAmountToRepay () {
        const response = await API.fetchAmountToRepay()
        if (response) setAmountToRepay(response)
    }

    async function handleLoanType (type: string) {
        setLoanType(type)
        if (type === 'all') {
            if (!allLoans?.length) {
                fetchUserLoans()
            }
        }
        else if (type === 'running') {
            if (!runningLoans?.length) {
                const response = await API.fetchUserRunningLoans()
                setRunningLoans(response)
            }
        }
        else if (type === 'repaid') {
            if (!repaidLoans?.length) {
                const response = await API.fetchUserRepaidLoans()
                setRepaidLoans(response)
            }
        }
    }

    async function handlePortfolioType (type: string) {
        setPortfolioType(type)
        if (type === 'all') {
            if (!allPortfolios?.length) {
                fetchUserOffers()
            }
        }
        else if (type === 'running') {
            if (!runningPortfolios?.length) {
                const response = await API.fetchUserRunningOffers()
                setRunningPortfolios(response)
            }
        }
        else if (type === 'ended') {
            if (!endedPortfolios?.length) {
                const response = await API.fetchUserEndedOffers()
                setEndedPortfolios(response)
            }
        }
    }

    function handleShowLoanData (data: any) {
        setSelectedLoan(data)
        if (!data?.completed && !data?.confirmed) setShowLoanDetails(true)
        else setShowLoanData(true)
    }

    async function handleShowPortfolioData (data: any, status = '') {
        // status = 'running'
       if (status === 'Open') {
            await setLoanProperty('selectedOfferRequest', {...data})
            navigate('/dashboard/loan/borrowers')
       }
       else {
            setSelectedPortfolio(data)
            setShowPortfolio(true)
       }
    }

    return (
        <>
            <ScreenContainer>
                <CustomContainer topMargin="2" rightPadding="2" leftPadding="2" className="trans-screen-container">
                    <div className="loan-info-kind-toggle">
                        <span 
                            style={{fontWeight: infoType === 'loan' ? 700 : 400}}
                            onClick={() => setInfoType('loan')}
                        >
                            Loan
                        </span>
                        <span 
                            style={{fontWeight: infoType === 'portfolio' ? 700 : 400}}
                            onClick={() => setInfoType('portfolio')}
                        >
                            Portfolio
                        </span>
                        <div className="loan-info-kind-shadow" style={{left: infoType === 'loan' ? 0 : '51%'}}></div>
                    </div>
                    { (infoType === 'loan' && hasLoan) &&
                        <FlexRow topMargin="3" justifyContent="flex-end" className="center-flex-item">
                            <OutlineButton 
                                color="#6DE7B4" 
                                width="16" 
                                height="4.5"  
                                hoverBgColor="#FED0B7"
                                rightMargin="1.5"
                                className="loan-action-outline-btn"
                                disabled={(availableLoans?.running || availableLoans?.loanRequest) ? true : false}
                                onClick={() => navigate('/dashboard/get-loan/loan-type')}
                            >
                                <FaHandsHelping style={{fontSize: '2rem'}} />
                                <AppSpan textSize="1.4" leftPadding="1" color="auto">Take Loan</AppSpan>
                            </OutlineButton>
                            <Button 
                                hoverBgColor="#FED0B7" 
                                width="16" 
                                height="4.5" 
                                className="loan-action-btn"
                                disabled={!availableLoans?.running ? true : false}
                                onClick={() => setShowLoanRepay(true)}
                            >
                                <RiSendPlaneFill style={{fontSize: '2rem'}} />
                                <AppSpan textSize="1.4" leftPadding="1" color="auto">Repay Loan</AppSpan>
                            </Button>
                        </FlexRow>
                    }
                    { (infoType === 'portfolio' && hasPortfolio) &&
                        <FlexRow topMargin="3" justifyContent="flex-end" className="center-flex-with-column">
                            <BorderlessButton 
                                rightMargin="4" 
                                hoverColor="#FED0B7" 
                                className="bottom-margin-center" 
                                onClick={() => setShowWithdraw(true)}
                            >
                                <FiPrinter style={{fontSize: '2rem'}} />
                                <AppSpan color="auto" leftPadding="1">Withdraw</AppSpan>
                            </BorderlessButton>
                            <FlexRow width="auto" sizeUnit="" className="center-flex-with-column-350px">
                                <OutlineButton 
                                    color="#6DE7B4" 
                                    width="18" 
                                    height="4.5"  
                                    hoverBgColor="#FED0B7"
                                    rightMargin="1.5"
                                    className="loan-action-outline-btn"
                                    disabled={!!portfoliosToFund?.length ? false : true }
                                    onClick={() => setShowFundPortfolio(true)}
                                >
                                    <FaHandsHelping style={{fontSize: '2rem'}} />
                                    <AppSpan textSize="1.4" leftPadding="1" color="auto">Fund Portfolio</AppSpan>
                                </OutlineButton>
                                <Button 
                                    hoverBgColor="#FED0B7" 
                                    width="18" 
                                    height="4.5" 
                                    onClick={() => navigate('/dashboard/loan-creation/loan-type')}
                                >
                                    <VscGitPullRequestCreate style={{fontSize: '2rem'}}  />
                                    <AppSpan textSize="1.4" leftPadding="1" color="auto">Create Portfolio</AppSpan>
                                </Button>
                            </FlexRow>
                        </FlexRow>
                    }
                    { (infoType === "loan" && hasLoan) &&
                        <FlexColumn 
                            height="46" bgColor="#081952" 
                            topMargin="4" radius="0.8"
                            topPadding="2" bottomPadding="2"
                            leftPadding="2" rightPadding="2"
                        >
                            <LoanChart />
                        </FlexColumn>
                    }
                    { (infoType === "portfolio" && hasPortfolio) &&
                        <Carousel infiniteLoop={false} showArrows={true} showThumbs={false}>
                            <FlexColumn 
                                height="46" bgColor="#081952" 
                                topMargin="4" radius="0.8"
                                topPadding="2" bottomPadding="2"
                                leftPadding="2" rightPadding="2"
                            >
                                <PortfolioChart />               
                            </FlexColumn>
                            <FlexColumn 
                                height="46" bgColor="#081952" 
                                topMargin="4" radius="0.8"
                                topPadding="2" bottomPadding="2"
                                leftPadding="2" rightPadding="2"
                            >
                                <InterestChart />               
                            </FlexColumn>
                        </Carousel>
                       
                    }
                    
                    { (infoType === 'loan' && hasLoan) &&
                        <CustomContainer topMargin="4" bottomMargin="3">
                            <div className="toggle-loan-types">
                                <span 
                                    className={loanType === 'all' ? 'custom-loanType-1' : ''}
                                    onClick={() => handleLoanType('all')}
                                >
                                    All Loans
                                </span>
                                <span
                                    className={loanType === 'running' ? 'custom-loanType-1' : ''}
                                    onClick={() => handleLoanType('running')}
                                >
                                    Running Loans
                                </span>
                                <span
                                    className={loanType === 'repaid' ? 'custom-loanType-1' : ''}
                                    onClick={() => handleLoanType('repaid')}
                                >
                                    Repaid Loans
                                </span>
                                <div 
                                    className={`loan-type-toggler ${loanType === 'all' ? 'custom-loanType-toggler-1' : loanType === 'running' ? 'custom-loanType-toggler-2' : 'custom-loanType-toggler-3'}`}
                                >
                                </div>
                            </div>
                            { loanType === 'all' &&
                                <FlexRow topMargin="5" justifyContent="flex-start" wrap="wrap" className="center-card-item">
                                    { 
                                        allLoans?.map((item: any, index: number) => {
                                            let loanStatus = item.completed ? 'Repaid' : (!item.completed && item.confirmed) ? 'Running' : 'Open'
                                            let statusColor = item.completed ? '#6DE7B4' : (!item.completed && item.confirmed) ? '#F5B070' : '#8095E0'
                                            let isDefaulted = false
                                            return (
                                                <div className="loan-info-card" onClick={() => handleShowLoanData(item)} key={index}>
                                                    {/* <img src={images.CARD_BUBBLE} className="card-bubble" /> */}
                                                    <div className="info-card-wrapper">
                                                        <FlexRow justifyContent="space-between">
                                                            <CustomContainer>
                                                                <AppSpan color="#E2E2E2" textSize="1.1" fontWeight="400">{item?.loanOffer?.loanTypeName || item.loanTypeName} (Amount repayable)</AppSpan>
                                                                <AppText textSize="1.8">
                                                                    ₦{utility.moneyFormat(item?.loanOffer?.totalRepaymentBorrower || item.totalAmountToBeRepaid)}
                                                                    <AppSpan textSize="0.9" fontWeight="400" topPadding="-1" color="#6DE7B4" leftPadding="0.5">{item?.loanOffer?.interest || item.interest}%</AppSpan>
                                                                </AppText>
                                                            </CustomContainer>
                                                            <OutlineButton 
                                                                hoverBgColor="transparent"  
                                                                width="7.2" height="2.1" 
                                                                titleSize="0.9" 
                                                                fontWeight="400" 
                                                                color={statusColor} 
                                                                hoverColor={statusColor} 
                                                                radius="2"
                                                            >
                                                                {loanStatus}
                                                            </OutlineButton>
                                                        </FlexRow>
                                                        <FlexRow justifyContent="flex-start" topMargin="-0.3" style={{visibility: isDefaulted ? 'visible' : 'hidden'}}>
                                                            <Circle 
                                                                bgColor="#FF0062"
                                                                size="0.8"
                                                                rightMargin="0.8"
                                                            />
                                                            <AppSpan textSize="0.9" color="#FF0062" fontWeight="600">
                                                                Loan Defaulted
                                                            </AppSpan>
                                                        </FlexRow>
                                                    
                                                        <FlexRow justifyContent="flex-start" topMargin="4" wrap="wrap">
                                                            <CustomContainer width="auto" sizeUnit="" rightMargin="2">
                                                                <AppSpan fontWeight="400" textSize="0.9" color="#E2E2E2">Loan Amount</AppSpan>
                                                                <AppText textSize="1.4">₦{utility.moneyFormat(item?.loanOffer?.amountPerPerson || item?.amount)}</AppText>
                                                            </CustomContainer>
                                                            <CustomContainer width="auto" sizeUnit="" rightMargin="2">
                                                                <AppSpan fontWeight="400" textSize="0.9" color="#E2E2E2">Loan Duration</AppSpan>
                                                                <AppText textSize="1.4">{item?.loanOffer?.tenor || item.tenor} {item?.loanOffer?.tenorType.toLowerCase() || item.tenorType.toLowerCase()}(s)</AppText>
                                                            </CustomContainer>
                                                            <CustomContainer width="auto" sizeUnit="" rightMargin="2">
                                                                <AppSpan fontWeight="400" textSize="0.9" color="#E2E2E2">Interest</AppSpan>
                                                                <AppText textSize="1.4">₦{utility.moneyFormat(item?.loanOffer?.interestAmount || item.interestAmount)}</AppText>
                                                            </CustomContainer>
                                                        </FlexRow>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </FlexRow>
                            }
                            { loanType === 'running' &&
                                <FlexRow topMargin="5" justifyContent="flex-start" wrap="wrap" className="center-card-item">
                                    { runningLoans?.length > 0 ?
                                        runningLoans?.map((item: any, index: number) => {
                                            let loanStatus = 'Running'
                                            let statusColor = '#F5B070'
                                            let isDefaulted = false
                                            return (
                                                <div className="loan-info-card" onClick={() => handleShowLoanData(item)} key={index}>
                                                    {/* <img src={images.CARD_BUBBLE} className="card-bubble" /> */}
                                                    <div className="info-card-wrapper">
                                                        <FlexRow justifyContent="space-between">
                                                            <CustomContainer>
                                                                <AppSpan color="#E2E2E2" textSize="1.1" fontWeight="400">{item?.loanOffer?.loanTypeName || item.loanTypeName} (Amount repayable)</AppSpan>
                                                                <AppText textSize="1.8">
                                                                    ₦{utility.moneyFormat(item?.loanOffer?.totalRepaymentBorrower || item.totalAmountToBeRepaid)}
                                                                    <AppSpan textSize="0.9" fontWeight="400" topPadding="-1" color="#6DE7B4" leftPadding="0.5">{item?.loanOffer?.interest || item.interest}%</AppSpan>
                                                                </AppText>
                                                            </CustomContainer>
                                                            <OutlineButton 
                                                                hoverBgColor="transparent"  
                                                                width="7.2" height="2.1" 
                                                                titleSize="0.9" 
                                                                fontWeight="400" 
                                                                color={statusColor} 
                                                                hoverColor={statusColor} 
                                                                radius="2"
                                                            >
                                                                {loanStatus}
                                                            </OutlineButton>
                                                        </FlexRow>
                                                        <FlexRow justifyContent="flex-start" topMargin="-0.3" style={{visibility: isDefaulted ? 'visible' : 'hidden'}}>
                                                            <Circle 
                                                                bgColor="#FF0062"
                                                                size="0.8"
                                                                rightMargin="0.8"
                                                            />
                                                            <AppSpan textSize="0.9" color="#FF0062" fontWeight="600">
                                                                Loan Defaulted
                                                            </AppSpan>
                                                        </FlexRow>
                                                    
                                                        <FlexRow justifyContent="flex-start" topMargin="4" wrap="wrap">
                                                            <CustomContainer width="auto" sizeUnit="" rightMargin="2">
                                                                <AppSpan fontWeight="400" textSize="0.9" color="#E2E2E2">Loan Amount</AppSpan>
                                                                <AppText textSize="1.4">₦{utility.moneyFormat(item?.loanOffer?.amountPerPerson || item?.amount)}</AppText>
                                                            </CustomContainer>
                                                            <CustomContainer width="auto" sizeUnit="" rightMargin="2">
                                                                <AppSpan fontWeight="400" textSize="0.9" color="#E2E2E2">Loan Duration</AppSpan>
                                                                <AppText textSize="1.4">{item?.loanOffer?.tenor || item?.tenor} {item?.loanOffer?.tenorType.toLowerCase() || item.tenorType.toLowerCase()}(s)</AppText>
                                                            </CustomContainer>
                                                            <CustomContainer width="auto" sizeUnit="" rightMargin="2">
                                                                <AppSpan fontWeight="400" textSize="0.9" color="#E2E2E2">Interest</AppSpan>
                                                                <AppText textSize="1.4">₦{utility.moneyFormat(item?.loanOffer?.interestAmount || item.interestAmount)}</AppText>
                                                            </CustomContainer>
                                                        </FlexRow>
                                                    </div>
                                                </div>
                                            )
                                        })
                                        :
                                        <FlexColumn >
                                            <Icon src={images.NO_LOAN} style={{maxWidth: '7rem'}} />
                                            <AppText textSize="1.8" width="25" topMargin="3" align="center">No Running Loans</AppText>
                                            <AppSpan color="#E2E2E2" textSize="1.3" fontWeight="400" topMargin="1" align="center" width="30">You currently did not have any running loans.</AppSpan>
                                        </FlexColumn>
                                    }
                                </FlexRow>
                            }
                            { loanType === 'repaid' &&
                                <FlexRow topMargin="5" justifyContent="flex-start" wrap="wrap" className="center-card-item">
                                    { repaidLoans?.length > 0 ?
                                        repaidLoans?.map((item: any, index: number) => {
                                            let loanStatus = 'Repaid'
                                            let statusColor = '#6DE7B4'
                                            let isDefaulted = false
                                            return (
                                                <div className="loan-info-card" onClick={() => handleShowLoanData(item)} key={index}>
                                                    {/* <img src={images.CARD_BUBBLE} className="card-bubble" /> */}
                                                    <div className="info-card-wrapper">
                                                        <FlexRow justifyContent="space-between">
                                                            <CustomContainer>
                                                                <AppSpan color="#E2E2E2" textSize="1.1" fontWeight="400">{item?.loanOffer?.loanTypeName || item.loanTypeName} (Amount repayable)</AppSpan>
                                                                <AppText textSize="1.8">
                                                                    ₦{utility.moneyFormat(item?.loanOffer?.totalRepaymentBorrower || item.totalAmountToBeRepaid)}
                                                                    <AppSpan textSize="0.9" fontWeight="400" topPadding="-1" color="#6DE7B4" leftPadding="0.5">{item?.loanOffer?.interest || item.interest}%</AppSpan>
                                                                </AppText>
                                                            </CustomContainer>
                                                            <OutlineButton 
                                                                hoverBgColor="transparent"  
                                                                width="7.2" height="2.1" 
                                                                titleSize="0.9" 
                                                                fontWeight="400" 
                                                                color={statusColor} 
                                                                hoverColor={statusColor} 
                                                                radius="2"
                                                            >
                                                                {loanStatus}
                                                            </OutlineButton>
                                                        </FlexRow>
                                                        <FlexRow justifyContent="flex-start" topMargin="-0.3" style={{visibility: isDefaulted ? 'visible' : 'hidden'}}>
                                                            <Circle 
                                                                bgColor="#FF0062"
                                                                size="0.8"
                                                                rightMargin="0.8"
                                                            />
                                                            <AppSpan textSize="0.9" color="#FF0062" fontWeight="600">
                                                                Loan Defaulted
                                                            </AppSpan>
                                                        </FlexRow>
                                                    
                                                        <FlexRow justifyContent="flex-start" topMargin="4" wrap="wrap">
                                                            <CustomContainer width="auto" sizeUnit="" rightMargin="2">
                                                                <AppSpan fontWeight="400" textSize="0.9" color="#E2E2E2">Loan Amount</AppSpan>
                                                                <AppText textSize="1.4">₦{utility.moneyFormat(item?.loanOffer?.amountPerPerson || item?.amount)}</AppText>
                                                            </CustomContainer>
                                                            <CustomContainer width="auto" sizeUnit="" rightMargin="2">
                                                                <AppSpan fontWeight="400" textSize="0.9" color="#E2E2E2">Loan Duration</AppSpan>
                                                                <AppText textSize="1.4">{item?.loanOffer?.tenor || item?.tenor} {item?.loanOffer?.tenorType.toLowerCase() || item?.tenorType.toLowerCase()}(s)</AppText>
                                                            </CustomContainer>
                                                            <CustomContainer width="auto" sizeUnit="" rightMargin="2">
                                                                <AppSpan fontWeight="400" textSize="0.9" color="#E2E2E2">Interest</AppSpan>
                                                                <AppText textSize="1.4">₦{utility.moneyFormat(item?.loanOffer?.interestAmount || item.interestAmount)}</AppText>
                                                            </CustomContainer>
                                                        </FlexRow>
                                                    </div>
                                                </div>
                                            )
                                        })
                                        :
                                        <FlexColumn >
                                            <Icon src={images.NO_LOAN} style={{maxWidth: '7rem'}} />
                                            <AppText textSize="1.8" width="25" topMargin="3" align="center">No Repaid Loans</AppText>
                                            <AppSpan color="#E2E2E2" textSize="1.3" fontWeight="400" topMargin="1" align="center" width="30">You currently did not have any repaid loans.</AppSpan>
                                        </FlexColumn>
                                    }
                                </FlexRow>
                            }
                        </CustomContainer>
                    }

                    { (infoType === 'portfolio' && hasPortfolio) &&
                        <CustomContainer topMargin="4" bottomMargin="3">
                            <div className="toggle-loan-types">
                                <span 
                                    className={portfolioType === 'all' ? 'custom-loanType-1' : ''}
                                    onClick={() => handlePortfolioType('all')}
                                >
                                    All Portfolios
                                </span>
                                <span
                                    className={portfolioType === 'running' ? 'custom-loanType-1' : ''}
                                    onClick={() => handlePortfolioType('running')}
                                >
                                    Running Portfolios
                                </span>
                                <span
                                    className={portfolioType === 'ended' ? 'custom-loanType-1' : ''}
                                    onClick={() => handlePortfolioType('ended')}
                                >
                                    Ended Portfolios
                                </span>
                                <div 
                                    className={`loan-type-toggler ${portfolioType === 'all' ? 'custom-loanType-toggler-1' : portfolioType === 'running' ? 'custom-loanType-toggler-2' : 'custom-loanType-toggler-3'}`}
                                >
                                </div>
                            </div>
                            { portfolioType === 'all' &&
                                <FlexRow topMargin="5" justifyContent="flex-start" wrap="wrap" className="center-card-item">
                                    { 
                                        allPortfolios?.map((item: any, index: number) => {
                                            let loanStatus = item.completed ? 'Ended' : (!item.completed && item?.taken) ? 'Running' : 'Open'
                                            let statusColor = item.completed ? '#6DE7B4' : (!item?.completed && item?.taken) ? '#F5B070' : '#8095E0'
                                            let isDefaulted = false
                                            let fundingColor = item.amountLocked >= item.amount ? '#6DE7B4' : item.amountLocked != 0 ? '#FED0B7' : '#FF0062'
                                            let fundingText = item.amountLocked >= item.amount ? 'Funded' : item.amountLocked != 0 ? 'Partially Funded' : 'Not Funded'
                                            return (
                                                <div className="loan-info-card" onClick={() => handleShowPortfolioData(item, loanStatus)} key={index}>
                                                    {/* <img src={images.CARD_BUBBLE} className="card-bubble" /> */}
                                                    <div className="info-card-wrapper">
                                                        <FlexRow justifyContent="space-between">
                                                            <CustomContainer>
                                                                <AppSpan color="#E2E2E2" textSize="1.1" fontWeight="400">Portfolio Amount</AppSpan>
                                                                <AppText textSize="1.8">
                                                                    ₦{utility.moneyFormat(item.amount)}
                                                                    <AppSpan textSize="0.9" fontWeight="400" topPadding="-1" color="#6DE7B4" leftPadding="0.5">{item.interest}%</AppSpan>
                                                                </AppText>
                                                            </CustomContainer>
                                                            <OutlineButton 
                                                                hoverBgColor="transparent"  
                                                                width="7.2" height="2.1" 
                                                                titleSize="0.9" 
                                                                fontWeight="400" 
                                                                color={statusColor} 
                                                                hoverColor={statusColor} 
                                                                radius="2"
                                                            >
                                                                {loanStatus}
                                                            </OutlineButton>
                                                        </FlexRow>
                                                        <FlexRow justifyContent='space-between' topMargin="-0.3">
                                                            <FlexRow 
                                                                width='auto' sizeUnit='' justifyContent="flex-start" 
                                                                style={{visibility: isDefaulted ? 'visible' : 'hidden'}}
                                                            >
                                                                <Circle 
                                                                    bgColor="#FF0062"
                                                                    size="0.8"
                                                                    rightMargin="0.8"
                                                                />
                                                                <AppSpan textSize="0.9" color="#FF0062" fontWeight="600">
                                                                    Loan Defaulted
                                                                </AppSpan>
                                                            </FlexRow>
                                                            <FlexRow width='auto' sizeUnit='' justifyContent="flex-start" >
                                                                <Circle 
                                                                    bgColor={fundingColor}
                                                                    size="0.8"
                                                                    rightMargin="0.8"
                                                                />
                                                                <AppSpan textSize="0.9" color={fundingColor} fontWeight="600">
                                                                    {fundingText}
                                                                </AppSpan>
                                                            </FlexRow>
                                                        </FlexRow>
                                                    
                                                        <FlexRow justifyContent="flex-start" topMargin="4" wrap="wrap">
                                                            <CustomContainer width="auto" sizeUnit="" rightMargin="2">
                                                                <AppSpan fontWeight="400" textSize="0.9" color="#E2E2E2">Loan Type</AppSpan>
                                                                <AppText textSize="1.4">{item.loanTypeName?.split(' ')[0]}</AppText>
                                                            </CustomContainer>
                                                            <CustomContainer width="auto" sizeUnit="" rightMargin="2">
                                                                <AppSpan fontWeight="400" textSize="0.9" color="#E2E2E2">Loan Duration</AppSpan>
                                                                <AppText textSize="1.4">{item.tenor} {item.tenorType.toLowerCase()}(s)</AppText>
                                                            </CustomContainer>
                                                            <CustomContainer width="auto" sizeUnit="" rightMargin="2">
                                                                <AppSpan fontWeight="400" textSize="0.9" color="#E2E2E2">Repayment Method</AppSpan>
                                                                <AppText textSize="1.4">{item.rateName === 'REDUCING_BALANCE' ? 'Installment' : 'One-off'}</AppText>
                                                            </CustomContainer>
                                                        </FlexRow>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </FlexRow>
                            }
                            { portfolioType === 'running' &&
                                <FlexRow topMargin="5" justifyContent="flex-start" wrap="wrap" className="center-card-item">
                                    { runningPortfolios?.length > 0 ?
                                        runningPortfolios?.map((item: any, index: number) => {
                                            let loanStatus = 'Running'
                                            let statusColor = '#F5B070'
                                            let isDefaulted = false
                                            let fundingColor = item.amountLocked >= item.amount ? '#6DE7B4' : item.amountLocked != 0 ? '#FED0B7' : '#FF0062'
                                            let fundingText = item.amountLocked >= item.amount ? 'Funded' : item.amountLocked != 0 ? 'Partially Funded' : 'Not Funded'
                                            return (
                                                <div className="loan-info-card" onClick={() => handleShowPortfolioData(item)} key={index}>
                                                    {/* <img src={images.CARD_BUBBLE} className="card-bubble" /> */}
                                                    <div className="info-card-wrapper">
                                                        <FlexRow justifyContent="space-between">
                                                            <CustomContainer>
                                                                <AppSpan color="#E2E2E2" textSize="1.1" fontWeight="400">Portfolio Amount</AppSpan>
                                                                <AppText textSize="1.8">
                                                                    ₦{utility.moneyFormat(item.amount)}
                                                                    <AppSpan textSize="0.9" fontWeight="400" topPadding="-1" color="#6DE7B4" leftPadding="0.5">{item.interest}%</AppSpan>
                                                                </AppText>
                                                            </CustomContainer>
                                                            <OutlineButton 
                                                                hoverBgColor="transparent"  
                                                                width="7.2" height="2.1" 
                                                                titleSize="0.9" 
                                                                fontWeight="400" 
                                                                color={statusColor} 
                                                                hoverColor={statusColor} 
                                                                radius="2"
                                                            >
                                                                {loanStatus}
                                                            </OutlineButton>
                                                        </FlexRow>
                                                        <FlexRow justifyContent='space-between' topMargin="-0.3">
                                                            <FlexRow 
                                                                width='auto' sizeUnit='' justifyContent="flex-start" 
                                                                style={{visibility: isDefaulted ? 'visible' : 'hidden'}}
                                                            >
                                                                <Circle 
                                                                    bgColor="#FF0062"
                                                                    size="0.8"
                                                                    rightMargin="0.8"
                                                                />
                                                                <AppSpan textSize="0.9" color="#FF0062" fontWeight="600">
                                                                    Loan Defaulted
                                                                </AppSpan>
                                                            </FlexRow>
                                                            <FlexRow width='auto' sizeUnit='' justifyContent="flex-start" >
                                                                <Circle 
                                                                    bgColor={fundingColor}
                                                                    size="0.8"
                                                                    rightMargin="0.8"
                                                                />
                                                                <AppSpan textSize="0.9" color={fundingColor} fontWeight="600">
                                                                    {fundingText}
                                                                </AppSpan>
                                                            </FlexRow>
                                                        </FlexRow>
                                                    
                                                        <FlexRow justifyContent="flex-start" topMargin="4" wrap="wrap">
                                                            <CustomContainer width="auto" sizeUnit="" rightMargin="2">
                                                                <AppSpan fontWeight="400" textSize="0.9" color="#E2E2E2">Loan Type</AppSpan>
                                                                <AppText textSize="1.4">{item.loanTypeName?.split(' ')[0]}</AppText>
                                                            </CustomContainer>
                                                            <CustomContainer width="auto" sizeUnit="" rightMargin="2">
                                                                <AppSpan fontWeight="400" textSize="0.9" color="#E2E2E2">Loan Duration</AppSpan>
                                                                <AppText textSize="1.4">{item.tenor} {item.tenorType.toLowerCase()}(s)</AppText>
                                                            </CustomContainer>
                                                            <CustomContainer width="auto" sizeUnit="" rightMargin="2">
                                                                <AppSpan fontWeight="400" textSize="0.9" color="#E2E2E2">Repayment Method</AppSpan>
                                                                <AppText textSize="1.4">{item.rateName === 'REDUCING_BALANCE' ? 'Installment' : 'One-off'}</AppText>
                                                            </CustomContainer>
                                                        </FlexRow>
                                                    </div>
                                                </div>
                                            )
                                        })
                                        :
                                        <FlexColumn >
                                            <Icon src={images.NO_LOAN} style={{maxWidth: '7rem'}} />
                                            <AppText textSize="1.8" width="25" topMargin="3" align="center">No Running Portfolios</AppText>
                                            <AppSpan color="#E2E2E2" textSize="1.3" fontWeight="400" topMargin="1" align="center" width="30">You currently did not have any running portfolios.</AppSpan>
                                        </FlexColumn>
                                    }
                                </FlexRow>
                            }
                            { portfolioType === 'ended' &&
                                <FlexRow topMargin="5" justifyContent="flex-start" wrap="wrap" className="center-card-item">
                                    { endedPortfolios?.length > 0 ?
                                        endedPortfolios?.map((item: any, index: number) => {
                                            let loanStatus = 'Ended'
                                            let statusColor = '#6DE7B4'
                                            let isDefaulted = false
                                            let fundingColor = item.amountLocked >= item.amount ? '#6DE7B4' : item.amountLocked != 0 ? '#FED0B7' : '#FF0062'
                                            let fundingText = item.amountLocked >= item.amount ? 'Funded' : item.amountLocked != 0 ? 'Partially Funded' : 'Not Funded'
                                            return (
                                                <div className="loan-info-card" onClick={() => handleShowPortfolioData(item)} key={index}>
                                                    {/* <img src={images.CARD_BUBBLE} className="card-bubble" /> */}
                                                    <div className="info-card-wrapper">
                                                        <FlexRow justifyContent="space-between">
                                                            <CustomContainer>
                                                                <AppSpan color="#E2E2E2" textSize="1.1" fontWeight="400">Portfolio Amount</AppSpan>
                                                                <AppText textSize="1.8">
                                                                    ₦{utility.moneyFormat(item.amount)}
                                                                    <AppSpan textSize="0.9" fontWeight="400" topPadding="-1" color="#6DE7B4" leftPadding="0.5">{item.interest}%</AppSpan>
                                                                </AppText>
                                                            </CustomContainer>
                                                            <OutlineButton 
                                                                hoverBgColor="transparent"  
                                                                width="7.2" height="2.1" 
                                                                titleSize="0.9" 
                                                                fontWeight="400" 
                                                                color={statusColor} 
                                                                hoverColor={statusColor} 
                                                                radius="2"
                                                            >
                                                                {loanStatus}
                                                            </OutlineButton>
                                                        </FlexRow>
                                                        <FlexRow justifyContent='space-between' topMargin="-0.3">
                                                            <FlexRow 
                                                                width='auto' sizeUnit='' justifyContent="flex-start" 
                                                                style={{visibility: isDefaulted ? 'visible' : 'hidden'}}
                                                            >
                                                                <Circle 
                                                                    bgColor="#FF0062"
                                                                    size="0.8"
                                                                    rightMargin="0.8"
                                                                />
                                                                <AppSpan textSize="0.9" color="#FF0062" fontWeight="600">
                                                                    Loan Defaulted
                                                                </AppSpan>
                                                            </FlexRow>
                                                            <FlexRow width='auto' sizeUnit='' justifyContent="flex-start" >
                                                                <Circle 
                                                                    bgColor={fundingColor}
                                                                    size="0.8"
                                                                    rightMargin="0.8"
                                                                />
                                                                <AppSpan textSize="0.9" color={fundingColor} fontWeight="600">
                                                                    {fundingText}
                                                                </AppSpan>
                                                            </FlexRow>
                                                        </FlexRow>
                                                    
                                                        <FlexRow justifyContent="flex-start" topMargin="4" wrap="wrap">
                                                            <CustomContainer width="auto" sizeUnit="" rightMargin="2">
                                                                <AppSpan fontWeight="400" textSize="0.9" color="#E2E2E2">Loan Type</AppSpan>
                                                                <AppText textSize="1.4">{item.loanTypeName?.split(' ')[0]}</AppText>
                                                            </CustomContainer>
                                                            <CustomContainer width="auto" sizeUnit="" rightMargin="2">
                                                                <AppSpan fontWeight="400" textSize="0.9" color="#E2E2E2">Loan Duration</AppSpan>
                                                                <AppText textSize="1.4">{item.tenor} {item.tenorType.toLowerCase()}(s)</AppText>
                                                            </CustomContainer>
                                                            <CustomContainer width="auto" sizeUnit="" rightMargin="2">
                                                                <AppSpan fontWeight="400" textSize="0.9" color="#E2E2E2">Repayment Method</AppSpan>
                                                                <AppText textSize="1.4">{item.rateName === 'REDUCING_BALANCE' ? 'Installment' : 'One-off'}</AppText>
                                                            </CustomContainer>
                                                        </FlexRow>
                                                    </div>
                                                </div>
                                            )
                                        })
                                        :
                                        <FlexColumn >
                                            <Icon src={images.NO_LOAN} style={{maxWidth: '7rem'}} />
                                            <AppText textSize="1.8" width="25" topMargin="3" align="center">No Ended Portfolios</AppText>
                                            <AppSpan color="#E2E2E2" textSize="1.3" fontWeight="400" topMargin="1" align="center" width="30">You currently did not have any ended portfolios.</AppSpan>
                                        </FlexColumn>
                                    }
                                </FlexRow>
                            }
                        </CustomContainer>
                    }
                    { (infoType === 'loan' && !hasLoan) &&
                        <FlexColumn topMargin="10" >
                            <Icon src={images.NO_LOAN} />
                            <AppText textSize="1.8" width="25" topMargin="3" align="center">No Loans yet</AppText>
                            <AppSpan color="#E2E2E2" textSize="1.3" fontWeight="400" topMargin="1" align="center" width="26">You haven’t taken any loans yet.</AppSpan>
                            <Button 
                                hoverBgColor="#FED0B7" 
                                width="30" 
                                height="5" 
                                titleSize="1.4"
                                topMargin="5"
                                onClick={() => navigate('/dashboard/get-loan/loan-type')}
                            >
                                Take a Loan
                            </Button>
                        </FlexColumn>
                    }
                    { (infoType === 'portfolio' && !hasPortfolio) &&
                        <FlexColumn topMargin="10" >
                            <Icon src={images.NO_PORTFOLIO} />
                            <AppText textSize="1.8" width="25" topMargin="3" align="center">No Portfolios yet</AppText>
                            <AppSpan color="#E2E2E2" textSize="1.3" fontWeight="400" topMargin="1" align="center" width="26">You haven’t created any Loan portfolios yet.</AppSpan>
                            <Button 
                                hoverBgColor="#FED0B7" 
                                width="30" 
                                height="5" 
                                titleSize="1.4"
                                topMargin="5"
                                onClick={() => navigate('/dashboard/loan-creation/loan-type')}
                            >
                                Create Loan Portfolio
                            </Button>
                        </FlexColumn>
                    }
                </CustomContainer>
            </ScreenContainer>
            { showLoanRepay &&
                <RepayLoan
                    close={() => setShowLoanRepay(false)}
                    amountToPay={amountToRepay}
                />
            }
            { showFundPortfolio &&
                <FundPortfolio
                    close={() => setShowFundPortfolio(false)}
                    data={portfoliosToFund}
                />
            }
            { showWithdraw &&
                <Withdraw
                    close={setShowWithdraw}
                />
            }
            { showLoanData &&
                <LoanData
                    close={() => setShowLoanData(false)}
                    data={selectedLoan}
                />
            }
            { showLoanDetails &&
                <LoanDetailsView
                    close={() => setShowLoanDetails(false)}
                    data={selectedLoan}
                />
            }
            { showPortfolio &&
                <Portfolio
                    close={() => setShowPortfolio(false)}
                    viewDetails={() => setShowPortfolioDetails(true)}
                    data={selectedPortfolio}
                />
            }
            { showPortfolioDetails &&
                <PortfolioDetails
                    close={() => setShowPortfolioDetails(false)}
                    fundPortfolio={() => setShowFundPortfolio(true)}
                    endPortfolio={() => setShowEndPortfolio(true)}
                    data={selectedPortfolio}
                />
            }
            { showEndPortfolio &&
                <EndPortfolio
                    close={() => setShowEndPortfolio(false)}
                    closePortfolio={() => setShowPortfolioDetails(false)}
                    data={selectedPortfolio}
                    refreshMethod={fetchLoans_Offers}
                />
            }
        </>
    )
}

export default utility.routeData('loan', 'Loan', <Loan/>)