import OnboardType from 'src/app/onboarding/onboardType'
import BusinessEmail from 'src/app/onboarding/businessEmail'
import BVNDetails from 'src/app/onboarding/bvnDetails'
import WithoutBVN from 'src/app/onboarding/withoutBvn'
import PhoneNoVerification from 'src/app/onboarding/phoneNoVerification'
import PersonalInfo from 'src/app/onboarding/personalInfo'
import UserEmail from 'src/app/onboarding/userEmail'
import MaritalStatus from 'src/app/onboarding/maritalStatus'
import EducationalBackground from 'src/app/onboarding/eduBackground'
import EmploymentStatus from 'src/app/onboarding/employmentStatus'
import AddressVerification from 'src/app/onboarding/addressVerification'
import MeansOfIdentification from 'src/app/onboarding/idCardUpload'
import SignatureUpload from 'src/app/onboarding/signatureUpload'
import FaceScan from 'src/app/onboarding/faceScan'
import BankDetails from 'src/app/onboarding/addBankDetails'
import Passcode from 'src/app/onboarding/passcode'
import TransactionPin from 'src/app/onboarding/transactionPin'

export default [
    OnboardType,
    BusinessEmail,
    BVNDetails,
    WithoutBVN,
    PhoneNoVerification,
    PersonalInfo,
    UserEmail,
    MaritalStatus,
    EducationalBackground,
    EmploymentStatus,
    AddressVerification,
    MeansOfIdentification,
    FaceScan,
    SignatureUpload,
    BankDetails,
    Passcode,
    TransactionPin,
]