import React, {useReducer, useContext} from 'react'
import { Action, KeyValuePayload } from '../../model'
import UserContext from 'src/provider/state-manager/userProvider'
import LoanContext from 'src/provider/state-manager/loanProvider'
import access from 'src/utils/localAccess'

const initialState = {
    token: '', onBoardingData: {bvn: '', email: '', profileType: ''}, 
    refreshToken: '', selectedMenu: 'home', quickRequest: {}, transactions: [],
    deviceInfo: {}, newNotifCount: '0', currentPaymentGateway: '', 
    refreshTokenTrigger: false
}

const AppInfoContext = React.createContext<any>(null)

const AppInfoReducer = (state: any, action: Action<KeyValuePayload>) => {
    switch(action.type){
        case "set-property": 
            return { ...state, [action.payload.key]: action.payload.value }
        case 'clear-data': 
            return { ...initialState }
    }
}

export const AppInfoProvider = (props: any) => {
    const {clearLoanData, recoverLoanData} = useContext(LoanContext)
    const {clearUserData, recoverUserData} = useContext(UserContext)
    const [state, dispatch] = useReducer(AppInfoReducer, {
        ...initialState
    })

    async function setInfoProperty (key: string, value: any) {
        let isString = typeof(value) === 'string' ? true : false
        let isNumber = typeof(value) === 'number' ? true : false
        localStorage.setItem(key, isString ? value : isNumber ? String(value) : JSON.stringify(value))
        await dispatch({type: "set-property", payload: {key, value }})
    }

    async function recoverAppData () {
        for (let item of Object.keys(initialState)) {
            let retrievedData = await localStorage.getItem(item)!
            retrievedData = (['null', 'undefined', 'NaN'].includes(retrievedData) ? state[item] : ['number', 'string'].includes(typeof state[item]) ? retrievedData : JSON.parse(retrievedData))
            await setInfoProperty(item, typeof state[item] === 'number' ? Number(retrievedData) : retrievedData)
        }
    }
  
    async function clearAppData () {
        await dispatch({type: "clear-data", payload: {key: '', value: ''}})
    }

    async function recoverStateData () {
        await Promise.all([
            recoverAppData(), recoverLoanData(), recoverUserData()
        ])
    }

    async function logout (shouldRedirect = true) {
        await Promise.allSettled([
            localStorage.clear(), clearAppData(), clearLoanData(), clearUserData()
        ])
        if (shouldRedirect) access.getNavigationAccess()('/login')
    }

    const stateActions = {
       setInfoProperty,
       recoverAppData,
       clearAppData,
       recoverStateData,
       logout
    }

    return (
        <AppInfoContext.Provider value={{info: state, ...stateActions}} >
            {props.children}
        </AppInfoContext.Provider>
    )
}

export default AppInfoContext