import { useContext } from 'react'
import ApiContext from 'src/provider/API/call-service'
import { SidePopupContainer, SidePopup, SideBarSummary, Button } from "src/styles"

export const ExploreLoanSummary: React.FC<any> = ({close}) => {
  const {API} = useContext(ApiContext)

  async function handleNext () {
    await API.exploreResponse()
    close()
  }

  return (
    <SidePopupContainer >
      <SidePopup data-aos="slide-left">
        <div className="popup-title-wrapper">
          <h4>Loan Summary</h4>
            <i className="fa fa-times" onClick={close}></i>
        </div>
        <span className="popup-title-summary">
          Fusce lacinia vulputate gravida massa nibh faucibus lorem vel etiam. 
        </span>

        <SideBarSummary borderType="dashed">
          <div className="bar-summary-data add-margin-plus">
            <p className="bar-summary-data-text">Loan type</p>
            <p className="bar-summary-data-text">Payday</p>
          </div>
          <div className="bar-summary-data add-margin-plus">
            <p className="bar-summary-data-text">Loan amount</p>
            <p className="bar-summary-data-text">&#8358;50,000 - &#8358;125,000</p>
          </div>
          <div className="bar-summary-data add-margin-plus">
            <p className="bar-summary-data-text">Interest rate</p>
            <p className="bar-summary-data-text">3%</p>
          </div>
          <div className="bar-summary-data add-margin-plus">
            <p className="bar-summary-data-text">Interest type</p>
            <p className="bar-summary-data-text">Monthly</p>
          </div>
          <div className="bar-summary-data add-margin-plus">
            <p className="bar-summary-data-text">Loan tenor</p>
            <p className="bar-summary-data-text">180 days</p>
          </div>
          <div className="bar-summary-data add-margin-plus">
            <p className="bar-summary-data-text">Repayment amount</p>
            <p className="bar-summary-data-text">&#8358;60,000 - &#8358;105,000</p>
          </div>
          <div className="bar-summary-data add-margin-plus">
            <p className="bar-summary-data-text">Total repayment amount</p>
            <p className="bar-summary-data-text">&#8358;50,000 - &#8358;125,000</p>
          </div>
        </SideBarSummary>
        <Button
          width="100"
          sizeUnit="%"
          topMargin="10"
          hoverBgColor="#FED0B7"
          onClick={handleNext}
        >
          Get this Loan
        </Button>
      </SidePopup>
    </SidePopupContainer>
  )
}
