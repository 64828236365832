import { useContext, useEffect, useState } from 'react'
import { Outlet, useNavigate } from 'react-router-dom'
import { useIdleTimer } from 'react-idle-timer'
import { appSettings, credentials } from 'src/provider/config/constant'
import ReactTooltip from 'react-tooltip'
import { NavBar, SideBar, TransactionPinSetting } from 'src/component'
import AppInfoContext from 'src/provider/state-manager/appInfoProvider'
import ApiContext from 'src/provider/API/call-service'
import { useGAEventTracker } from 'src/hooks'
import UserContext from 'src/provider/state-manager/userProvider'
import access from 'src/utils/localAccess'
import utility from 'src/utils/utility'
import * as signalR from "@microsoft/signalr";
import './dashboard.scss'
import helpers from 'src/utils/helpers'

export const DashboardRoutes = () => {
    const navigate = useNavigate()
    const {info, setInfoProperty, recoverStateData, logout} = useContext(AppInfoContext)
    const {user} = useContext(UserContext)
    const {API} = useContext(ApiContext)
    const [hasCreateTxPin, setHasCreateTxPin] = useState(true)
    const GAEventTracker = useGAEventTracker('Logout')
    const [isAuth, setIsAuth] = useState<any>(null)

    useEffect(() => {
        setHasCreateTxPin(user.userData?.hasSetTransactionPin)
    }, [user.userData])

    useEffect(() => {
        if(info.token) {
            const connection = new signalR.HubConnectionBuilder()
            //.configureLogging(signalR.LogLevel.Debug)
            .withUrl(`${credentials.SIGNALR_URL}?userId=${user?.userData?.userId}`, 
                {
                // skipNegotiation: true,
                // transport: signalR.HttpTransportType.WebSockets
            })
            .withAutomaticReconnect()
            .build();
            
            connection.start()
            .then(() => {
                console.log("SignalR connected")
                //connection.send("NotificationReceived", "foo").then(x => console.log("sent"))
            });
            
            connection.on("RecommendedLoanOffers", data => {
                if (!access.getCallDebounce()) {
                    console.log({signalR: data});
                    const info = {...data?.data, message: data?.message}
                    access.setCallDebounce(true)
                    API.runBackgroundService('remove-calls-debounce', 10000)
                    API.getCreditDecisionResponse(info)
                }
            });
            
            connection.on("NotificationReceived", data => {
                setInfoProperty('newNotifCount', info.newNotifCount ? Number(info.newNotifCount) + 1 : '1')
                console.log({signalR: data});
            });
        }
    }, [user?.userData?.userId])

    useEffect(() => {
        let isAuthenticated = localStorage.getItem('token') && localStorage.getItem('hasLogin') ? true : false
        setIsAuth(isAuthenticated)
        if(!isAuthenticated) navigate('/login')
    }, [])

    useEffect(() => {
        const route = utility.toTitleCase(window.location.href.split('/').pop()!)
        const splittedUrl = window.location.href.split('/')
        if (splittedUrl[splittedUrl.length - 2] === 'settings') 
            return setInfoProperty('selectedMenu', 'Settings')
        if (['Home', 'Loan', 'Transactions', 'Settings', 'Explore', 'Payment'].includes(route)) 
            return setInfoProperty('selectedMenu', route)
    }, [window.location.href])

    useEffect(() => {
        if (!user?.userData?.firstName) {
            recoverStateData()
        }

        return () => {document.body.style.overflow = 'auto'}
    }, [])

    // Set closure access to navigate component method
    useEffect(() => {
        access.setNavigationAccess(navigate)
        access.setLogoutHandler(handleLogout)
        handleAuth()
    }, [])

    // Setup Refresh Token Trigger
    useEffect(() => {
        if (info.token) API.runBackgroundService('get-refresh-token', appSettings.TOKEN_REFRESH_TIME)
     }, [info.refreshTokenTrigger])

    const onIdle = async() => handleLogout()
    const onAction = (e: any) => helpers.setSessionExpiryTime()

    const idleTimer = useIdleTimer({
        timeout: appSettings.ISLOGGED_IN_INACTIVITY_TIME,
        onIdle,
        onAction,
        debounce: 500
    })

    async function handleLogout() {
        GAEventTracker('Inactivity/Session Logout', `User: ${user.userData?.email}`)
        API.updateLoginHistory()
        await logout()
    }

    async function handleAuth() {
        const sessionExpiryTime = await localStorage.getItem('session-expiry-time')

        let isSessionExpire = false
        const currentDate = new Date()

        if (sessionExpiryTime) {
            if (currentDate > new Date(sessionExpiryTime)) isSessionExpire = true
        }

        if (isSessionExpire) handleLogout()
    }

    return (
        <>
            { isAuth &&
                <div className="dashboard-container">
                    <NavBar />
                    <SideBar />
                    { !hasCreateTxPin && <TransactionPinSetting /> }
                    <div className="dashboard-modules">
                        <Outlet/>
                        <ReactTooltip className='tooltip-style' arrowColor='#081952' multiline={true} />
                    </div>
                </div>
            }
        </>
    )
}

export default utility.routeData('dashboard', 'Dashboard', <DashboardRoutes/>)