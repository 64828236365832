import { useContext, useEffect, useState } from 'react'
import ApiContext from 'src/provider/API/call-service'
import { images } from 'src/provider/config/constant'
import { CgCheck } from 'react-icons/cg'
import { IoMdStar } from 'react-icons/io'
import UserContext from 'src/provider/state-manager/userProvider'
import { 
    SidePopupContainer, 
    SidePopup, 
    FlexRow, 
    CustomContainer,
    AppSpan,
    SideBarSummary,
    FlexColumn,
    Divider,
    OutlineButton,
    AppText,
    Circle,
    Icon,
    RoundImageContainer
} from "src/styles"
import utility, { getLoanStatusColor } from 'src/utils/utility'
import helpers from 'src/utils/helpers'
import { offerLetterBaseUrl } from 'src/provider/config/constant'

export const BorrowerLoanRequest: React.FC<any> = ({close, data}) => {
    const {user: {userData}} = useContext(UserContext)
    const {API} = useContext(ApiContext)
    const [running_repaidLoan, setRunning_RepaidLoan] = useState<any>(null)

    useEffect(() => {
        getLoanInfo()
     }, [data])
 
    async function getLoanInfo() {
        const response = await API.getLoanRequestInfo(data?.loanRequestId)
        if (response) {
            setRunning_RepaidLoan(response)
        }
    }

    const dateTime = helpers.splitDateTime(data?.createdAt)

    const downloadFile = () => {
        const downloadLink = document.createElement('a');
        document.body.appendChild(downloadLink);
        downloadLink.download = 'Offer-letter';
        downloadLink.href = `${offerLetterBaseUrl}/LoanOffer/DownloadLoanAgreementLetter/${data?.loanOffer?.loanOfferId || data?.loanOfferId}/${data?.loanRequestId}?customerId=${userData.customerId}`;
        console.log({downloadLink: downloadLink.href})
        downloadLink.click();
    }

    return (
        <SidePopupContainer >
            <SidePopup data-aos="slide-left">
                <div className="popup-title-wrapper">
                    <h4>Loanee Details</h4>
                    <i className="fa fa-times" onClick={close}></i>
                </div>

                <SideBarSummary bgColor="#4700e0" topPadding="2" bottomPadding="2">
                    <FlexColumn>
                        <Circle 
                            size='7.2' rightMargin='1' bgColor='#ffffff'
                        >
                            <Circle
                                size='6.9'
                                bgColor={getLoanStatusColor(data?.userTag)}
                            >
                                <RoundImageContainer 
                                    size="6.3" borderColor='#ffffff'
                                    style={{borderWidth: '1.5px'}}
                                >
                                    <Icon 
                                        src={data.profilePictureUrl || images.USER_IMG}
                                        width='6.1' radius='50' height='6.1' noResize
                                    />
                                </RoundImageContainer>
                            </Circle>
                        </Circle>
                        <AppSpan topMargin="1.5" bottomPadding="0.5">{data?.firstName} {data?.lastName}</AppSpan>
                        <AppSpan fontWeight="400" textSize="1.4">Loan requested on {dateTime.date}, {dateTime.completeTime}</AppSpan>
                        {/* <FlexRow topMargin="0.7">
                            { 
                                Array.from(Array(5).keys()).map((item: any, index: number) => {
                                    let color = index <= 3 ? '#FFBB2E' : '#C4C4C4'
                                    return (
                                        <IoMdStar style={{fontSize: '2rem', color}} key={index}/>
                                    )
                                })
                            }
                        </FlexRow> */}
                    </FlexColumn>
                    <CustomContainer topMargin="3" leftPadding="1.5" rightPadding="1.5" bottomMargin="1">
                        <FlexRow justifyContent="space-between">
                            <AppSpan fontWeight="400" textSize="1.4">Crendly Score</AppSpan>
                            <AppSpan color="#6DE7B4" leftPadding="1" textSize="1.4">{data?.creditScoreInfo?.grandTotal}%</AppSpan>
                        </FlexRow>
                        <Divider margin="0.6" />
                        <FlexRow justifyContent="space-between" topMargin='1.2'>
                            <AppSpan fontWeight="400" textSize="1.4">Amount loaned</AppSpan>
                            <AppSpan fontWeight="400" textSize="1.4">&#8358;{utility.moneyFormat(data?.loanOffer?.amountPerPerson || data?.amount)}</AppSpan>
                        </FlexRow>
                        <FlexRow justifyContent="space-between" topMargin='1'>
                            <AppSpan fontWeight="400" textSize="1.4">Interest rate</AppSpan>
                            <AppSpan fontWeight="400" leftPadding="1" textSize="1.4">{(data?.loanOffer?.interest || data?.loanOffer?.interest === 0) ? data?.loanOffer?.interest : data?.interest}%</AppSpan>
                        </FlexRow>
                        <FlexRow justifyContent="space-between" topMargin='1'>
                            <AppSpan fontWeight="400" textSize="1.4">Interest amount</AppSpan>
                            <AppSpan fontWeight="400" leftPadding="1" textSize="1.4">&#8358;{utility.moneyFormat((data?.loanOffer?.interestAmount || data?.loanOffer?.interestAmount === 0) ? data?.loanOffer?.interestAmount : data?.interestAmount)}</AppSpan>
                        </FlexRow>
                        <FlexRow justifyContent="space-between" topMargin='1'>
                            <AppSpan fontWeight="400" textSize="1.4">Duration</AppSpan>
                            <AppSpan fontWeight="400" leftPadding="1" textSize="1.4">{(data?.loanOffer?.tenor || data?.loanOffer?.tenor === 0) ? data?.loanOffer?.tenor : data?.tenor} {data?.loanOffer?.tenorType.toLowerCase() || data?.tenorType.toLowerCase()}(s)</AppSpan>
                        </FlexRow>
                        <FlexRow justifyContent="space-between" topMargin='1'>
                            <AppSpan fontWeight="400" textSize="1.4">{data?.loanOffer?.repaymentTermName || data?.repaymentTermName} Repayment</AppSpan>
                            <AppSpan fontWeight="400" leftPadding="1" textSize="1.4">
                                &#8358;{utility.moneyFormat((data?.loanOffer?.repaymentPerTenorBorrower || data?.loanOffer?.repaymentPerTenorBorrower === 0) ? data?.loanOffer?.repaymentPerTenorBorrower : data?.amountPerTenorType)}
                            </AppSpan>
                        </FlexRow>
                        <FlexRow justifyContent="space-between" topMargin='1'>
                            <AppSpan fontWeight="400" textSize="1.4">Total Repayment</AppSpan>
                            <AppSpan fontWeight="400" leftPadding="1" textSize="1.4">&#8358;{utility.moneyFormat((data?.loanOffer?.totalRepaymentBorrower || data?.loanOffer?.totalRepaymentBorrower === 0) ? data?.loanOffer?.totalRepaymentBorrower : data?.totalAmountToBeRepaid)}</AppSpan>
                        </FlexRow>
                        <FlexRow justifyContent="space-between" topMargin='1'>
                            <AppSpan fontWeight="400" textSize="1.4">Default</AppSpan>
                            <AppSpan fontWeight="400" leftPadding="1" textSize="1.4">None</AppSpan>
                        </FlexRow>
                        {/* <Divider margin="0.6" />
                        <FlexRow justifyContent="space-between" topMargin='1.2'>
                            <AppSpan fontWeight="400" textSize="1.4">Request date</AppSpan>
                            <AppSpan fontWeight="400" leftPadding="1" textSize="1.4">21-Jan-2020</AppSpan>
                        </FlexRow>
                        <FlexRow justifyContent="space-between" topMargin='1'>
                            <AppSpan fontWeight="400" textSize="1.4">Repayment type</AppSpan>
                            <AppSpan fontWeight="400" leftPadding="1" textSize="1.4">Installments</AppSpan>
                        </FlexRow>
                        <FlexRow justifyContent="space-between" topMargin='1'>
                            <AppSpan fontWeight="400" textSize="1.4">Repayment start date</AppSpan>
                            <AppSpan fontWeight="400" leftPadding="1" textSize="1.4">21-Mar-2020</AppSpan>
                        </FlexRow> */}
                    </CustomContainer>
                </SideBarSummary>

                <CustomContainer topMargin="2">
                    { (running_repaidLoan?.loanRepaymentResults?.length > 0) ?
                        running_repaidLoan?.loanRepaymentResults?.map((item: any, index: number) => {
                            let type = item?.amountPaid === 0 ? 'Scheduled' : item?.scheduledPayment > item?.amountPaid ? 'Partial payment' : 'Paid'
                            let typeColor = item?.amountPaid === 0 ? '#FF0062' : item?.scheduledPayment > item?.amountPaid ? '#FED0B7' : '#6DE7B4'
                            let dateTime = helpers.splitDateTime(item?.paymentMadeDate || item?.paymentDate)
                            let icon = item?.amountPaid === 0 ? images.LOAN_ICON_3 : item?.scheduledPayment > item?.amountPaid ? images.LOAN_ICON_4 : images.LOAN_ICON_5
                            return (
                                <FlexRow 
                                    bgColor="#000926" 
                                    justifyContent="space-between"
                                    topPadding="1" 
                                    leftPadding="1"
                                    rightPadding="1"
                                    bottomPadding="1"
                                    topMargin="0.7"
                                    radius="0.8"
                                    key={index}
                                >
                                    <FlexRow width="auto" sizeUnit="" alignItems="flex-start">
                                        <img src={icon} style={{width: '2.4rem'}} />
                                        <CustomContainer width="auto" sizeUnit=""  leftMargin="1" topMargin="-0.5">
                                            <AppSpan textSize="1.3" fontWeight="700">Loan Repayment</AppSpan>
                                            <AppText textSize="0.9" fontWeight="400" color="#E2E2E2">{dateTime.date}, {dateTime.completeTime}</AppText>
                                        </CustomContainer>
                                    </FlexRow>
                                    <CustomContainer width="auto" sizeUnit=""  leftMargin="1">
                                        <AppSpan fontWeight="700" color="#F2EAE0" align="right">N{utility.moneyFormat(item.paid ? item.amountPaid : item.scheduledPayment)}</AppSpan>
                                        <AppText textSize="0.9" color={typeColor} align="right">{type}</AppText>
                                    </CustomContainer>
                                </FlexRow>
                            )
                        })
                        :
                        <>
                            <FlexColumn topMargin='1'>
                                <AppText textSize="1.8" width="25" topMargin="3" align="center">No Repayment Made</AppText>
                                <AppSpan color="#E2E2E2" textSize="1.3" fontWeight="400" topMargin="1" align="center" width="30">No repayment has been made yet.</AppSpan>
                            </FlexColumn>
                        </>
                    }
                </CustomContainer>
                <OutlineButton 
                    hoverBgColor="#FED0B7"  
                    width="100" 
                    height="5" 
                    sizeUnit="%"
                    color="#6DE7B4"
                    topMargin="4"
                    onClick={downloadFile}
                >
                    View Full Contract
                </OutlineButton>
            </SidePopup>
        </SidePopupContainer>
    )
}
