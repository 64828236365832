import { useState, useContext } from 'react'
import ApiContext from 'src/provider/API/call-service'
import { images } from 'src/provider/config/constant'
import { CgCheck } from 'react-icons/cg'
import { IoMdStar } from 'react-icons/io'
import { 
    SidePopupContainer, 
    SidePopup, 
    FlexRow, 
    AppText,
    CustomContainer,
    Button,
    AppSpan,
    SideBarSummary,
    FlexColumn,
    Divider,
    Circle
} from "src/styles"

export const ExploreLoanDetails: React.FC<any> = ({close}) => {
    const {API} = useContext(ApiContext)

    async function handleAction () {
        await API.exploreResponse()
        close()
    }

    return (
        <SidePopupContainer >
            <SidePopup data-aos="slide-left">
                <div className="popup-title-wrapper">
                    <h4>Loan Request Details</h4>
                    <i className="fa fa-times" onClick={() => close()}></i>
                </div>

                <SideBarSummary bgColor="#4700e0" topPadding="2" bottomPadding="2">
                    <FlexColumn>
                        <div className="img-circle-wrapper">
                            <img src={images.USER_IMG} />
                            <div className="img-circle-icon">
                                <CgCheck className="check-circle-icon" />
                            </div>
                        </div>
                        <AppSpan topMargin="1.5" bottomPadding="0.5">Ridwan Kolawole</AppSpan>
                        <AppSpan fontWeight="400" textSize="1.4">Loan requested on 12th Feb 2022</AppSpan>
                        <FlexRow topMargin="0.7">
                            { 
                                Array.from(Array(5).keys()).map((item: any, index: number) => {
                                    let color = index <= 3 ? '#FFBB2E' : '#C4C4C4'
                                    return (
                                        <IoMdStar style={{fontSize: '2rem', color}} />
                                    )
                                })
                            }
                        </FlexRow>
                    </FlexColumn>
                    <CustomContainer topMargin="3" leftPadding="1.5" rightPadding="1.5" bottomMargin="1">
                        <FlexRow justifyContent="space-between">
                            <AppSpan fontWeight="400" textSize="1.4">Crendly Score</AppSpan>
                            <AppSpan color="#6DE7B4" leftPadding="1" textSize="1.4">75%</AppSpan>
                        </FlexRow>
                        <Divider margin="0.6" />
                        <FlexRow justifyContent="space-between" topMargin='1.2'>
                            <AppSpan fontWeight="400" textSize="1.4">Loan amount</AppSpan>
                            <AppSpan fontWeight="400" textSize="1.4">&#8358;20,000</AppSpan>
                        </FlexRow>
                        <FlexRow justifyContent="space-between" topMargin='1'>
                            <AppSpan fontWeight="400" textSize="1.4">Interest rate</AppSpan>
                            <AppSpan fontWeight="400" leftPadding="1" textSize="1.4">5%</AppSpan>
                        </FlexRow>
                        <FlexRow justifyContent="space-between" topMargin='1'>
                            <AppSpan fontWeight="400" textSize="1.4">Interest type</AppSpan>
                            <AppSpan fontWeight="400" leftPadding="1" textSize="1.4">Monthly</AppSpan>
                        </FlexRow>
                        <FlexRow justifyContent="space-between" topMargin='1'>
                            <AppSpan fontWeight="400" textSize="1.4">Repayment amount</AppSpan>
                            <AppSpan fontWeight="400" leftPadding="1" textSize="1.4">&#8358;35,000</AppSpan>
                        </FlexRow>
                        <Divider margin="0.6" />
                        <FlexRow justifyContent="space-between" topMargin='1.2'>
                            <AppSpan fontWeight="400" textSize="1.4">Request date</AppSpan>
                            <AppSpan fontWeight="400" leftPadding="1" textSize="1.4">21-Jan-2020</AppSpan>
                        </FlexRow>
                        <FlexRow justifyContent="space-between" topMargin='1'>
                            <AppSpan fontWeight="400" textSize="1.4">Repayment type</AppSpan>
                            <AppSpan fontWeight="400" leftPadding="1" textSize="1.4">Installments</AppSpan>
                        </FlexRow>
                        <FlexRow justifyContent="space-between" topMargin='1'>
                            <AppSpan fontWeight="400" textSize="1.4">Duration</AppSpan>
                            <AppSpan fontWeight="400" leftPadding="1" textSize="1.4">90 days</AppSpan>
                        </FlexRow>
                        <FlexRow justifyContent="space-between" topMargin='1'>
                            <AppSpan fontWeight="400" textSize="1.4">Repayment start date</AppSpan>
                            <AppSpan fontWeight="400" leftPadding="1" textSize="1.4">21-Mar-2020</AppSpan>
                        </FlexRow>
                    </CustomContainer>
                </SideBarSummary>
                <AppText topMargin='1.5' textSize="1.7">Loan status</AppText>
                <FlexRow justifyContent="flex-start">
                    <Circle 
                        bgColor="linear-gradient(0deg, #FF3A5A, #FF3A5A), #F65654"
                        size="1.1"
                        rightMargin="1"
                    />
                    <AppSpan textSize="1.4">
                        Request submitted by
                        <AppSpan textSize="1.4" color="#FED0B7" leftPadding="0.5">Ridwan Kolawole</AppSpan>
                    </AppSpan>
                </FlexRow>
                <AppText color="#AAAAAA" fontWeight="400" leftPadding="2.5" topPadding="0.6">21/02/2021 3:45pm</AppText>
                <Button 
                    width="100"
                    sizeUnit="%" 
                    height="4"
                    hoverBgColor="#FED0B7"
                    topMargin="7"
                    titleSize="1.4"
                    onClick={handleAction}
                >
                    Take an Action
                </Button>
            </SidePopup>
        </SidePopupContainer>
    )
}
