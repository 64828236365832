import React, {useReducer} from 'react'
import { Action, KeyValuePayload } from '../../model'

  
const VisibilityContext = React.createContext<any>(null)

const VisibilityReducer = (state: any, action: Action<KeyValuePayload>) => {
    switch(action.type){
        case "set-visibility": 
            return { ...state, [action.payload.key]: action.payload.value }
    }
}

export const VisibilityProvider = (props: any) => {
    const [state, dispatch] = useReducer(VisibilityReducer, {
        isLoading: false,
        isSideBar: false,
        isOnline: true,
        notification: {status: false, message: '', type: 'error'},
        response: {status: false, title: '', message: '', type: 'success', btnText: '', btnPath: ''},
        processingScreen: {status: false, isViewOffers: false}
    })

    async function loader (value: boolean) {
        await dispatch({type: "set-visibility", payload: {key: 'isLoading', value }})
    }

    async function showSideBar (value: boolean) {
        await dispatch({type: "set-visibility", payload: {key: 'isSideBar', value }})
    }

    const notifier = {
        show: async function (message: string, type?: string) {
            const messageType = type ? type.toLowerCase() : 'error'
            await dispatch({type: "set-visibility", payload: {key: 'notification', value: {status: true, message, type: messageType} }})
        },
        hide: async function () {
            await dispatch({type: "set-visibility", payload: {key: 'notification', value: {status: false, message: state.notification.message, type: state.notification.type} }})
        }
    }

    const responder = {
        show: async function (title: string, message: string, type?: string, btnText?: string, btnPath?: string) {
            const messageType = type ? type.toLowerCase() : 'warning'
            btnText = btnText || ''
            btnPath = btnPath || ''
            await dispatch({type: "set-visibility", payload: {key: 'response', value: {status: true, title, message, type: messageType, btnText, btnPath} }})
        },
        hide: async function () {
            await dispatch({type: "set-visibility", payload: {key: 'response', value: {status: false, message: state.response.message, type: state.response.type, title: '', btnPath: '', btnText: ''} }})
        }
    }

    const waitingScreen = {
        show: async function (isViewOffers = true) {
            await dispatch({type: "set-visibility", payload: {key: 'processingScreen', value: {status: true, isViewOffers}}})
        },
        hide: async function () {
            await dispatch({type: "set-visibility", payload: {key: 'processingScreen', value: {status: false, isViewOffers: state.processingScreen.isViewOffers}}})
        }
    }

    async function isConnected (value: boolean) {
        await dispatch({type: "set-visibility", payload: {key: 'isOnline', value }})
    }
  

    const stateActions = {
        loader,
        notifier,
        showSideBar,
        responder,
        isConnected,
        waitingScreen
    }

    return (
        <VisibilityContext.Provider value={{visibility: state, ...stateActions}} >
            {props.children}
        </VisibilityContext.Provider>
    )
}

export default VisibilityContext