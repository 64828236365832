import { useState, useContext, useEffect } from 'react'
import ApiContext from 'src/provider/API/call-service'
import { SidePopupContainer, SidePopup, FormGroup, Button, SelectButton, AbsoluteContainer, AppSpan, CustomContainer, Divider  } from "src/styles"
import helpers from 'src/utils/helpers'
import { tenorData } from 'src/provider/config/constant'
import { formatNegativeCharacter } from 'src/utils/utility'

interface InitialState {
    interest: null | string,
    repaymentTermId: null | number,
    tenor: null | number,
    tenorType: null | string,
    rateId: null | number,
}

export const LoanNegotiation: React.FC<any> = ({data, type, close}) => {
    const {API} = useContext(ApiContext)
    const [isValidated, setIsValidated] = useState(false)
    const [input, setInput] = useState<InitialState>({interest: null, repaymentTermId: null, tenor: null, tenorType: null, rateId: null})

    var tenorList = input.repaymentTermId === 2 ? tenorData.days : input.repaymentTermId === 3 ? tenorData.weeks : input.repaymentTermId === 5 ? tenorData.months : tenorData.default

    useEffect(() => {
        const {interest, repaymentTermId, tenor, tenorType, rateId} = input
        //const auth = input.rateId === 1 ?  helpers.inputValidator(input) : helpers.inputValidator(input, ['repaymentTermId']) 
        const isAuth = (interest || (rateId && repaymentTermId) || tenor || tenorType) ? true : false
        setIsValidated(isAuth)
    }, [input])

    async function handleNegotiation (e: React.FormEvent<HTMLButtonElement | HTMLFormElement>) {
        e.preventDefault()
        //TODO: renegotiation should have its own endpoint
        if (isValidated)  await API.sendNegotiation({
            ...input, interest: Number(input.interest), 
            loanOfferId: data?.loanOfferId,
            repaymentTermId: input.rateId == 2 ? 1 : input.repaymentTermId
        })
    }

    return (
        <SidePopupContainer >
            <SidePopup data-aos="slide-left">
                <div className="popup-title-wrapper">
                    <h4>{type === 'borrower' ? 'Negotiate with this Lender' : 'Renegotiate with this Borrower'}</h4>
                    <i className="fa fa-times" onClick={close}></i>
                </div>
                <span className="popup-title-summary">
                    Negotiate loan terms with this lender you wish to take their offer. 
                </span>
                <form className="loan-negotiation-form" onSubmit={handleNegotiation}>
                    <FormGroup>
                        <label>How much interest is fine by you (monthly)?</label>
                        <input type="number" value={input.interest || ''} placeholder="Enter interest rate" onChange={(e) => setInput({...input, interest: e.target.value})} />
                    </FormGroup>
                    <FormGroup>
                        <label>How do you want to make payment?</label>
                        <div className="loan-tenor-types">
                            <SelectButton width="22" rightMargin="1" height="4.5" onClick={() => setInput({...input, rateId: 1, repaymentTermId: 0, tenor: null, tenorType: null})}>
                                <input type="radio" name="negotiation-payment-type" />
                                <span></span>
                                <label>Installment</label>
                            </SelectButton>
                            <SelectButton width="22" height="4.5" onClick={() => setInput({...input, rateId: 2, repaymentTermId: 0, tenor: null, tenorType: null})}>
                                <input type="radio" name="negotiation-payment-type" />
                                <span></span>
                                <label>One-Off</label>
                            </SelectButton>
                        </div>
                    </FormGroup>
                    { !!input.rateId &&
                        <FormGroup topMargin="3">
                            <label>{input?.rateId === 1 ? 'How often you want to make payment?' : 'Repayment Duration Type'}</label>
                            <div className="loan-tenor-types">
                                <SelectButton width="14" height="4" onClick={() => setInput({...input, repaymentTermId: 2, tenor: null, tenorType: null})}>
                                    <input type="radio" name="payment-frequency" />
                                    <span></span>
                                    <label>{input?.rateId === 1 ? 'Daily' : 'Day'}</label>
                                </SelectButton>
                                <SelectButton width="14" height="4" onClick={() => setInput({...input, repaymentTermId: 3, tenor: null, tenorType: null})}>
                                    <input type="radio" name="payment-frequency" />
                                    <span></span>
                                    <label>{input?.rateId === 1 ? 'Weekly' : 'Week'}</label>
                                </SelectButton>
                                <SelectButton width="14" height="4" onClick={() => setInput({...input, repaymentTermId: 5, tenor: null, tenorType: null})}>
                                    <input type="radio" name="payment-frequency" />
                                    <span></span>
                                    <label>{input?.rateId === 1 ? 'Monthly' : 'Month'}</label>
                                </SelectButton>
                            </div>
                        </FormGroup>
                    }

                    { !!input.repaymentTermId &&
                        <FormGroup topMargin="3">
                            <Divider bgColor="#1E3177" />
                            <label>How long is this loan going to run?</label>
                            <div className="loan-tenor-types">
                                {
                                    tenorList?.data?.map((item: number, index: number) => {
                                        let label = (tenorList.tenorType === 'default' && index === 0) ? 'Days' : (tenorList.tenorType === 'default' && index === 1) ? 'Weeks' : tenorList.tenorType === 'default' ? 'Months' :  tenorList.label
                                        let term = (tenorList.tenorType === 'default' && index === 0) ? 'DAY' : (tenorList.tenorType === 'default' && index === 1) ? 'WEEK' : tenorList.tenorType === 'default' ? 'MONTH' : tenorList.tenorType
                                        return (
                                            <SelectButton width="10" height="4" key={index}>
                                                <input type="radio" name="loan-duration" checked={input.tenor === item} onChange={() => setInput({...input, tenor: item, tenorType: term})} />
                                                <span></span>
                                                <label>{item} {label}</label>
                                            </SelectButton>
                                        )
                                    })
                                }
                            </div>
                        </FormGroup>
                    }
                    {   !!input.repaymentTermId &&
                            <>
                                <CustomContainer topMargin='-3'>
                                    <Divider bgColor="#1E3177" margin='2.5' />
                                    <AbsoluteContainer className='center-absolute'>
                                        <CustomContainer 
                                            borderColor='#0F1B46'
                                            rightPadding='2' leftPadding='2' bgColor='#000926'
                                        >
                                            <AppSpan>or</AppSpan>
                                        </CustomContainer>
                                    </AbsoluteContainer>
                                </CustomContainer>
                                 <FormGroup>
                                    <label>Number of {tenorList.label}</label>
                                    <input 
                                        type="number" 
                                        value={input.tenor || ''} 
                                        placeholder={`Enter Number of ${tenorList.label}`} 
                                        onChange={(e) => setInput({...input, tenor: Number(formatNegativeCharacter(e.target.value)), 
                                        tenorType: tenorList.tenorType})} />
                                </FormGroup>
                            </>
                    }
                    <Button
                        width="100"
                        sizeUnit="%"
                        topMargin="7"
                        hoverBgColor="#FED0B7"
                        className="loan-action-btn"
                        disabled={!isValidated ? true : false}
                        type="submit"
                        onClick={handleNegotiation}
                        >
                        Submit {type === 'borrower' ? 'Negotiation' : 'Renegotiation'} terms
                    </Button>
                </form>
            </SidePopup>
        </SidePopupContainer>
    )
}
