import utility from 'src/utils/utility'
import { useState, useContext } from 'react'
import ApiContext from 'src/provider/API/call-service'
import { SidePopupContainer, SidePopup, Divider, Button, FlexRow  } from "src/styles"

export const PortfolioDetails: React.FC<any> = ({close, fundPortfolio, endPortfolio, data}) => {
  const {API} = useContext(ApiContext)

    function handleFundPorfolio () {
        fundPortfolio()
        close()
    }

    function handleEndPortfolio () {
        endPortfolio()
    }

    return (
        <SidePopupContainer >
        <SidePopup data-aos="slide-left">
            <div className="popup-title-wrapper">
            <h4>Loan Portfolio</h4>
            <div className="side-popup-nav-icons">
                <p>
                <span className="fa fa-pencil"></span>
                <span className="">edit</span>
                </p>
                <i className="fa fa-times" onClick={() => close(false)}></i>
            </div>
            </div>

            <div className="side-bar-summary">
                <div className="bar-summary-data">
                    <p className="bar-summary-data-text">Loan type</p>
                    <p className="bar-summary-data-text">{data?.loanTypeName}</p>
                </div>
                <div className="bar-summary-data">
                    <p className="bar-summary-data-text">Portfolio amount</p>
                    <p className="bar-summary-data-text">&#8358;{utility.moneyFormat(data?.amount)}</p>
                </div>
                <div className="bar-summary-data">
                    <p className="bar-summary-data-text">Interest rate</p>
                    <p className="bar-summary-data-text">{data?.interest}%</p>
                </div>
                <div className="bar-summary-data">
                    <p className="bar-summary-data-text">Amount Per Person</p>
                    <p className="bar-summary-data-text">{utility.moneyFormat(data?.amountPerPerson)}</p>
                </div>
                <div className="bar-summary-data">
                    <p className="bar-summary-data-text">Loan tenor</p>
                    <p className="bar-summary-data-text">{data?.tenor} {data?.tenorType.toLowerCase()}(s)</p>
                </div>
                <div className="bar-summary-data">
                    <p className="bar-summary-data-text">{data?.repaymentTermName} Repayment / Borrower</p>
                    <p className="bar-summary-data-text">&#8358;{utility.moneyFormat(data?.repaymentPerTenorBorrower || data?.amountPerTenorType)}</p>
                </div>
                <div className="bar-summary-data">
                    <p className="bar-summary-data-text">Total repayment amount</p>
                    <p className="bar-summary-data-text">&#8358;{utility.moneyFormat(data?.totalAmountToBeRepaid)}</p>
                </div>
                <Divider margin="1" />
                <div className="bar-summary-data">
                    <p className="bar-summary-data-text">Repayment type</p>
                    <p className="bar-summary-data-text">{data?.rateName === 'REDUCING_BALANCE' ? 'Installment' : 'One-off'}</p>
                </div>
                <div className="bar-summary-data">
                    <p className="bar-summary-data-text">Repayment occurrence</p>
                    <p className="bar-summary-data-text">{data?.repaymentTermName}</p>
                </div>
                {/* <div className="bar-summary-data">
                    <p className="bar-summary-data-text">Repayment start date</p>
                    <p className="bar-summary-data-text">21 Mar 2021</p>
                </div>
                <div className="bar-summary-data">
                    <p className="bar-summary-data-text">Repayment end date</p>
                    <p className="bar-summary-data-text">21 Jun 2021</p>
                </div> */}
                <Divider margin="1" />
                <div className="bar-summary-data">
                    <p className="bar-summary-data-text">Negotiation</p>
                    <p className="bar-summary-data-text" style={{color: '#6DE7B4'}}>{data?.isNegotiable ? 'YES' : 'NO'}</p>
                </div>
                <div className="bar-summary-data">
                    <p className="bar-summary-data-text">Loan extension</p>
                    <p className="bar-summary-data-text" style={{color: '#6DE7B4'}}>{data?.isExtensionAllowed ? 'YES' : 'NO'}</p>
                </div>
                <div className="bar-summary-data">
                    <p className="bar-summary-data-text">Loan extension time</p>
                    <p className="bar-summary-data-text">{data?.canBeExtendedBy}days</p>
                </div>
            </div>
            { (!data?.completed && data?.taken) &&
                <FlexRow justifyContent="flex-end" className="loan-acceptance-btns" topMargin="7">
                    <a className="underlined-link-btn" style={{color: '#FF3A5A'}} onClick={handleEndPortfolio}>End Portfolio</a>
                    <Button 
                        width="17" 
                        height="4"
                        hoverBgColor="#FED0B7"
                        leftMargin="3.5"
                        onClick={handleFundPorfolio}
                    >
                        Fund Portfolio
                    </Button>
                </FlexRow>
            }
        </SidePopup>
        </SidePopupContainer>
    )
}
