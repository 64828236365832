import './style.scss'
import { useState, useContext } from 'react'
import utility from 'src/utils/utility'
import { useNavigate } from 'react-router-dom'
import ApiContext from 'src/provider/API/call-service'
import AppInfoContext from 'src/provider/state-manager/appInfoProvider'
import { Container } from 'src/component'
import { Button } from 'src/styles'

const Identification = () => {
    const navigate = useNavigate()
    const {API} = useContext(ApiContext)
    const {info, setInfoProperty} = useContext(AppInfoContext)
    const [username, setUsername] = useState('')

    async function handleContinue (e: React.FormEvent<HTMLButtonElement|HTMLFormElement>) {
        e.preventDefault()
        await setInfoProperty('onBoardingData', {...info.onBoardingData, email: username})
        const response = await API.forgetPasswordIdentification(username)
        if (response) navigate('/forget-password/otp-verification')
    }

    return (
        <Container>
            <p className="right-align-text">Don't have an account? 
                <a className="registration-link" onClick={() => navigate('/onboarding/type')}> Sign up</a>
            </p>
            <div className="main-content-wrapper">
                <h3>Forgot Passcode</h3>
                <p>Don't worry, we will send you a code to reset your passcode </p>
                <form className="login-form">
                    <div className="form-group-wrapper">
                        <label className="form-label">Username</label>
                        <input 
                            className="form-input-field" 
                            placeholder="Email or Phone number"
                            value={username}
                            onChange={(e) => setUsername(e.target.value)}
                        />
                    </div>
                    <Button 
                        width="18" 
                        height="4.5" 
                        topMargin="10"
                        hoverBgColor="#FFC49D" 
                        className="loan-action-btn"
                        disabled={!username ? true : false}
                        onClick={handleContinue}
                    >
                        Continue
                    </Button>
                </form>
            </div>

        </Container>
    )
}

export default utility.routeData('forget-password/identification', 'Identification', <Identification/>)