import { useContext, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import ApiContext from 'src/provider/API/call-service'
import LoanContext from 'src/provider/state-manager/loanProvider'
import { 
    ResponderContentWrapper, 
    ResponderIconCircle, 
    ResponderIconHolder,
    ResponderTitle, 
} from 'src/component/Responder/style'
import { CenterPopupContainer, CenterPopup, Button, Icon, FlexRow, AppText, BorderlessButton } from 'src/styles'
import { images } from 'src/provider/config/constant'
import { Agreement, FundPortfolio } from 'src/popup'


export const ProposeOffer: React.FC<any> = ({close, data, offerData, portfolioAmount}) => {
    const {API} = useContext(ApiContext)
    const {loan} = useContext(LoanContext)
    const [showFundPortfolio, setShowFundPortfolio] = useState(false)
    const [view, setView] = useState('')

    function handleOfferSigning () {
        data.loanOffer = {...loan?.proposedLoanRequest} // loan?.proposedLoanRequest hold the offer data 
        data.loanOfferId = loan?.proposedLoanRequest?.loanOfferId
        setShowFundPortfolio(true)
    }

    // async function handleProposal () {
    //     await API.submitInterestInLoanRequest(data.loanRequestId, offerData?.loanOfferId || loan?.proposedLoanRequest?.loanOfferId)
    //     close()
    // }

    return (
        <>
            { (!view && !showFundPortfolio) &&
                <CenterPopupContainer zIndex="8">
                    <CenterPopup data-aos="slide-down">
                        <i className="fa fa-times responder-cancel" onClick={close}></i>
                            <ResponderContentWrapper>
                                <ResponderIconCircle>
                                    <Icon src={images.STATEMENT_RESPONSE} />
                                </ResponderIconCircle>
                                <ResponderIconHolder></ResponderIconHolder>
                                <ResponderTitle>You are proposing an offer to this borrower</ResponderTitle>
                                <AppText topMargin="4" fontWeight="400" align="center" textSize='1.4'>
                                    Propose offer to the borrower
                                </AppText>
                                <FlexRow justifyContent="flex-end" className="loan-acceptance-btns" topMargin="5">
                                    <BorderlessButton hoverColor='#FED0B7' titleSize='1.4' onClick={close}>
                                        Not Now
                                    </BorderlessButton>
                                    <Button 
                                        width="17" 
                                        height="4"
                                        hoverBgColor="#FED0B7"
                                        leftMargin="3.5"
                                        onClick={handleOfferSigning}
                                    >
                                        Continue
                                    </Button>
                                </FlexRow>
                            </ResponderContentWrapper>
                    </CenterPopup >
                </CenterPopupContainer>
            }
            { showFundPortfolio &&
                <FundPortfolio 
                    close={() => setShowFundPortfolio(false)}
                    portfolioId={offerData?.loanOfferId || loan?.proposedLoanRequest?.loanOfferId}
                    portfolioAmount={portfolioAmount || loan?.proposedLoanRequest?.amount}
                    onCompleteMethod={() => setView('agreement')}
                />
            }
            { view === 'agreement' &&
                <Agreement
                    close={() => setView('')} 
                    closeDetails={close}
                    data={data}
                    isLenderShowInterest={true}
                />
            }
        </>
    )
}
