import { useContext, useState, useEffect } from 'react'
import { useNavigate } from "react-router-dom"
import utility from 'src/utils/utility'
import { SidePopupContainer, SidePopup, SideBarSummary, Button } from "src/styles"
import ApiContext from 'src/provider/API/call-service'
import UserContext from 'src/provider/state-manager/userProvider'
import LoanContext from 'src/provider/state-manager/loanProvider'
import VisibilityContext from 'src/provider/state-manager/visibilityProvider'
import { loanConfig } from 'src/provider/config/constant'

export const LoanSummary_Get: React.FC<any> = ({close, data}) => {
  const navigate = useNavigate()
  const {API} = useContext(ApiContext)
  const {user} = useContext(UserContext)
  const {notifier} = useContext(VisibilityContext)
  const {loan: {loanRequest}, setLoanProperty} = useContext(LoanContext)
  const [summaryData, setSummaryData] = useState<any>(null)

  useEffect(() => {
    handleRepaymentCalculation()
  },[])
  
  async function handleRepaymentCalculation () {
    const payload = {
      repaymentTerm: loanConfig.repaymentTerm[loanRequest.rateId == 2 ? 1 : loanRequest.repaymentTermId],
      rate: loanConfig.rate[loanRequest.rateId],
      tenor: loanRequest.tenor,
      tenorType: loanRequest.tenorType,
      loanAmount: loanRequest.amount,
      interest: loanRequest.interest
    }

    const response = await API.calculateLoanRepayment(payload)
    if (response) setSummaryData(response)
    else close()
  }

  async function handleNext () {
    const { onBoardingStatus, formattedUserData, overallScore } = user;

    const hasCompletedOnboarding = onBoardingStatus?.percCompleted === 100;
    const isBlacklisted = formattedUserData?.declineLoanRequest;
    const loanLimit = overallScore?.loanLimit || formattedUserData?.overallScore?.loanLimit;
    const isWithinLimit = loanLimit >= loanRequest.amount;

    if (!isWithinLimit && overallScore?.isActualLoanLimit) {
      notifier.show(`You cannot request for a loan above your loan limit which is ₦${utility.moneyFormat(loanLimit)}`);
      close();
      return;
    }

    if (!formattedUserData?.hasTakenPersonalityQuestion || !formattedUserData?.hasTakenTrustQuestion) {
      navigate('/dashboard/questionnaire');
      close();
      return;
    }

    if (!hasCompletedOnboarding || isBlacklisted || user.userData?.isAccountSuspended) {
      const message = (isBlacklisted || user.userData?.isAccountSuspended) ? 'You are currently suspended from accessing this feature' : 'Kindly complete your profile';
      notifier.show(message, 'warning');
      navigate('/dashboard/home');
      close();
      return;
    }

    const response = await API.submitLoanRequest();
    if (response) {
      await setLoanProperty('loanRequest', {});
      close();
      // CREDIT DECISION CHECK PROCESS HERE
      // navigate('/dashboard/loan-board/loan-offers');
    }
  }

  return (
    <SidePopupContainer >
      <SidePopup data-aos="slide-left">
        <div className="popup-title-wrapper">
          <h4>Loan Summary</h4>
            <i className="fa fa-times" onClick={close}></i>
        </div>
        <span className="popup-title-summary">
          Kindly confirm the details of your loan before matching with lenders. 
        </span>

        <SideBarSummary borderType="dashed">
          <div className="bar-summary-data add-margin-plus">
            <p className="bar-summary-data-text">Loan type</p>
            <p className="bar-summary-data-text">{data?.loanTypeName || data?.loanType}</p>
          </div>
          <div className="bar-summary-data add-margin-plus">
            <p className="bar-summary-data-text">Loan Amount</p>
            <p className="bar-summary-data-text">&#8358;{utility.moneyFormat(summaryData?.loanAmount)}</p>
          </div>
          <div className="bar-summary-data add-margin-plus">
            <p className="bar-summary-data-text">Duration</p>
            <p className="bar-summary-data-text">{summaryData?.tenor} {summaryData?.tenorType.toLowerCase()}(s)</p>
          </div>
          <div className="bar-summary-data add-margin-plus">
            <p className="bar-summary-data-text">Interest Rate</p>
            <p className="bar-summary-data-text">{summaryData?.interestRate}%</p>
          </div>
          <div className="bar-summary-data add-margin-plus">
            <p className="bar-summary-data-text">{summaryData?.repaymentTerm} payment</p>
            <p className="bar-summary-data-text">&#8358;{utility.moneyFormat(summaryData?.amountPlusInterestPerTenorType)}</p>
          </div>
          <div className="bar-summary-data add-margin-plus">
            <p className="bar-summary-data-text">Repayment Method</p>
            <p className="bar-summary-data-text">{data?.rateId == 1 ? 'Installment' : 'One-off'}</p>
          </div>
          <div className="bar-summary-data add-margin-plus">
            <p className="bar-summary-data-text">Total Interest Amount</p>
            <p className="bar-summary-data-text">&#8358;{utility.moneyFormat(summaryData?.interestAmount)}</p>
          </div>

          <div className="bar-summary-data add-margin-plus">
            <p className="bar-summary-data-text">Total repayment amount</p>
            <p className="bar-summary-data-text">&#8358;{utility.moneyFormat(summaryData?.totalAmountPlusInterest)}</p>
          </div>
        </SideBarSummary>
        <Button
          width="100"
          sizeUnit="%"
          topMargin="10"
          hoverBgColor="#FED0B7"
          onClick={handleNext}
        >
          Get this Loan
        </Button>
      </SidePopup>
    </SidePopupContainer>
  )
}
