import { useState, useContext, useEffect } from 'react'
import utility, { required } from 'src/utils/utility'
import ApiContext from 'src/provider/API/call-service'
import AppInfoContext from 'src/provider/state-manager/appInfoProvider'
import { useNavigate } from 'react-router-dom'
import { Container } from 'src/component'
import { AppText, Button, Divider, FormGroup, FormGroupWithIcon, ScreenTitle, SelectButton } from 'src/styles'
import { BsChevronLeft } from 'react-icons/bs'
import { GoChevronDown } from 'react-icons/go'
import ReactTooltip from 'react-tooltip'

const MaritalStatus = () => {
    const navigate = useNavigate()
    const {API} = useContext(ApiContext)
    const {setInfoProperty, info} = useContext(AppInfoContext)
    const [isDependent, setIsDependent] = useState<null | boolean>(info.onBoardingData?.isDependent || null)
    const [dependent, setDependent] = useState(info.onBoardingData?.dependents || '')
    const [input, setInput] = useState(info.onBoardingData?.maritalStatus || '')

    // useEffect(() => {
    //     if(!isDependent && isDependent != null) setDependent('None')
    //     else if (!info.onBoardingData?.dependents) setDependent('')
    // }, [isDependent])

    useEffect(() => {
        ReactTooltip.rebuild();
    }, [isDependent])

    async function handleNext () {
        await setInfoProperty('onBoardingData', {...info.onBoardingData, maritalStatus: input, dependents: dependent, isDependent})
        navigate('/onboarding/educational-background')
    }

    function handleInput (type: string) {
        setInput(type)
    }
    console.log({onboarding: info.onBoardingData})
    function handleIsDependent (val: boolean) {
        console.log({val, isDependent})
        setIsDependent(val)
        if(!val) setDependent('None')
        else setDependent('')
    }
    
    function handleDependent (e:React.ChangeEvent<HTMLSelectElement>) {
        setDependent(e.target.value)
    }

    return (
        <Container type="onboarding" progressBar='30.76'>
            <p className="right-align-text">Already have an account? 
                <a className="registration-link" onClick={() => navigate('/login')}> Sign in</a>
            </p>
            <div className="onboarding-container">
                <BsChevronLeft className="loan-back-icon" onClick={() => navigate(-1)} />
                <ScreenTitle textSize="3" bottomMargin="1.5" topMargin="3" >
                    Marital Status
                </ScreenTitle>
                <AppText color="#D5D5D5" textSize="1.4" fontWeight="400" bottomMargin="2">
                    Please provide a valid designation.
                </AppText>
                <FormGroup  topMargin="5">
                    <AppText textSize="1.7" bottomMargin="2">What is your marital status? {required}</AppText>
                    <div className="loan-tenor-types">
                        <SelectButton width="11" height="4" onClick={() => handleInput('Single')}>
                            <input type="radio" name="marital-status" readOnly checked={input === 'Single'} />
                            <span></span>
                            <label>Single</label>
                        </SelectButton>
                        <SelectButton width="11" height="4" onClick={() => handleInput('Married')}>
                            <input type="radio" name="marital-status" readOnly checked={input === 'Married'} />
                            <span></span>
                            <label>Married</label>
                        </SelectButton>
                        <SelectButton width="11" height="4" onClick={() => handleInput('Divorced')}>
                            <input type="radio" name="marital-status" readOnly checked={input === 'Divorced'} />
                            <span></span>
                            <label>Divorced</label>
                        </SelectButton>
                        <SelectButton width="11" height="4" onClick={() => handleInput('Seperated')}>
                            <input type="radio" name="marital-status" readOnly checked={input === 'Seperated'} />
                            <span></span>
                            <label>Seperated</label>
                        </SelectButton>
                        <SelectButton width="11" height="4" onClick={() => handleInput('Widowed')}>
                            <input type="radio" name="marital-status" readOnly checked={input === 'Widowed'} />
                            <span></span>
                            <label>Widowed</label>
                        </SelectButton>
                    </div>
                </FormGroup>

                <FormGroup  topMargin="5">
                    <AppText textSize="1.7" bottomMargin="2">Do you have dependents? {required}</AppText>
                    <div className="loan-tenor-types">
                        <SelectButton width="16" height="4" onClick={() => handleIsDependent(true)}>
                            <input type="radio" name="dependent" readOnly checked={isDependent === true} />
                            <span></span>
                            <label>Yes, I do</label>
                        </SelectButton>
                        <SelectButton width="16" height="4" onClick={() => handleIsDependent(false)}>
                            <input type="radio" name="dependent" readOnly checked={dependent === 'None'} />
                            <span></span>
                            <label>No, I don't</label>
                        </SelectButton>
                    </div>
                </FormGroup>
                <Divider bgColor='#081952'></Divider>
                { isDependent &&
                    <FormGroupWithIcon topMargin="1">
                        <label>How many dependents do you have? {required}</label>
                        <div className="input-with-icon">
                            <select 
                                name="dependent" 
                                value={dependent} 
                                style={{color: dependent ? '#fff' : '#999'}}
                                onChange={handleDependent}
                            >
                                <option value="">Select number of dependents</option>
                                <option value="1 - 2">1 - 2</option>
                                <option value="3 - 4">3 - 4</option>
                                <option value="5 and above">5 and above</option>
                            </select>
                            <GoChevronDown className="input-with-icon-tag" />
                        </div>
                    </FormGroupWithIcon>
                }
                <Button 
                    width="15" 
                    height="4.5" 
                    hoverBgColor="#FFC49D" 
                    topMargin="12"
                    bottomMargin="3"
                    className="loan-action-btn"
                    disabled={(!input || !dependent) ? true : false}
                    onClick={handleNext}
                >
                    Next
                </Button>
            </div>
        </Container>
    )
}

export default utility.routeData('marital-status', 'Marital Status', <MaritalStatus/>)