import { useContext } from "react"
import { SidePopupContainer, SidePopup, CustomContainer, AppText, GridContainer, FlexColumn, AppSpan } from "src/styles"
import UserContext from "src/provider/state-manager/userProvider"

export const FinancialAdvice: React.FC<any> = ({close}) => {
    const {user: {formattedUserData}} = useContext(UserContext)

    return (
        <>
            <SidePopupContainer >
                <SidePopup data-aos="slide-left">
                    <div className="popup-title-wrapper">
                        <h4>Financial Advice</h4>
                        <i className="fa fa-times" onClick={close}></i>
                    </div>
                    <CustomContainer topMargin="2.5">
                        { formattedUserData?.financialAdvice ?
                            <AppText fontWeight="400" textSize="1.4">
                                {formattedUserData?.financialAdvice}
                            </AppText>
                            :
                            <FlexColumn topMargin="10">
                                <AppText textSize="1.8" topMargin="3" align="center">No advice yet</AppText>
                                <AppSpan color="#E2E2E2" textSize="1.3" fontWeight="400" topMargin="1" align="center">The system does not have an advice for you yet.</AppSpan>
                            </FlexColumn>
                        }
                    </CustomContainer>
                </SidePopup>
            </SidePopupContainer>
        </>
    )
}