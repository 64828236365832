import { useState, useContext } from 'react'
import ApiContext from 'src/provider/API/call-service'
import { 
    SidePopupContainer, 
    SidePopup, 
    FlexRow, 
    Button,
    FormGroup,
} from "src/styles"

export const RejectionReason: React.FC<any> = ({close, closeDetails, data, isOfferRejection}) => {
    const {API} = useContext(ApiContext)
    const [reason, setReason] = useState('')

    async function handleRejectRequest () {
        const response = !isOfferRejection ? await API.rejectLoanRequest(data?.loanOfferId, data?.loanRequestId, reason)
            : await API.rejectLoanOffer(data?.loanOfferId, data?.loanRequestId, reason)
        
        API.runBackgroundService(['current-loan-update', 'charts-info-update'])
        close()
        if (closeDetails) closeDetails()
    }

    return (
        <SidePopupContainer >
            <SidePopup data-aos="slide-left">
                <div className="popup-title-wrapper">
                    <h4>Reason for {isOfferRejection ? 'Offer' : 'Request'} Rejection</h4>
                    <i className="fa fa-times" onClick={close}></i>
                </div>
                <span className="popup-title-summary">
                    State your reason for rejecting this Loan {isOfferRejection ? 'offer' : 'request'}.
                </span>
                <FormGroup>
                    <textarea 
                        placeholder="Your reason" 
                        name="about"
                        value={reason} 
                        onChange={(e) => setReason(e.target.value)}
                    >
                    </textarea>
                </FormGroup>
                <FlexRow justifyContent="flex-end" topMargin="4" className="loan-acceptance-btns">
                    <Button 
                        width="15" 
                        height="4"
                        hoverBgColor="#FED0B7"
                        leftMargin="3.5"
                        className='loan-action-btn'
                        disabled={!reason ? true : false}
                        onClick={handleRejectRequest}
                    >
                        Submit
                    </Button>
                </FlexRow>
            </SidePopup>
        </SidePopupContainer>
    )
}
