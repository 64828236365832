import utility from 'src/utils/utility'
import { useState, useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import ApiContext from 'src/provider/API/call-service'
import AppInfoContext from 'src/provider/state-manager/appInfoProvider'
import UserContext from 'src/provider/state-manager/userProvider'
import VisibilityContext from 'src/provider/state-manager/visibilityProvider'
import { SidePopupContainer, SidePopup, Divider, Button  } from "src/styles"
import { Agreement, FundPortfolio, ProposeOffer } from 'src/popup'

export const QuickLoanSummary: React.FC<any> = ({close, data}) => {
    const navigate = useNavigate()
    const {API} = useContext(ApiContext)
    const {info: {quickRequest}} = useContext(AppInfoContext)
    const {user} = useContext(UserContext)
    const {notifier} = useContext(VisibilityContext)
   // const [showProposeOffer, setShowProposeOffer] = useState(false)
    const [showFundPortfolio, setShowFundPortfolio] = useState(false)
    const [view, setView] = useState('')

    async function handleInterest () {
        let isBlacklisted = user.formattedUserData?.declineLoanRequest && quickRequest?.type != 'offer'

        if (user.userData?.isAccountSuspended) {
            notifier.show('You are currently suspended from accessing this feature')
            goToDashboard()
            return false;
        }

        if ((quickRequest?.type === 'offer' && !user.onBoardingStatus?.canGiveLoan) || (quickRequest?.type != 'offer' && user.onBoardingStatus?.percCompleted != 100) || isBlacklisted) {
            notifier.show(isBlacklisted ? 'Unfortunately, you are not eligible for a loan at the moment' : 'Kindly complete your profile', 'warning')
            goToDashboard()
            return false;
        }
        
        let payload = {...data}
        if (quickRequest?.type === 'offer') {
            delete payload?.loanRequestId
            payload.numberOfPerson = 1
            const response = await API.publishLoanPortfolio(payload, true)
            if (response) {
                data.loanOffer = {...response}
                data.loanOfferId = response?.loanOfferId
                setShowFundPortfolio(true)
            }
        }
        else {
            const isWithinLimit = user.overallScore?.loanLimit >= payload.amountPerPerson;

            if (!isWithinLimit && user.overallScore?.isActualLoanLimit) {
                notifier.show(`You cannot request for a loan above your loan limit which is ₦${utility.moneyFormat(user.overallScore?.loanLimit)}`);
                goToDashboard();
                return false;
            }

            delete payload?.loanOfferId
            const response = await API.submitLoanRequest(payload, true)
            if (response) {
                // CREDIT DECISION CHECK PROCESS HERE
                //navigate('/dashboard/loan-board/loan-offer-details')
                close()
            }
        }
    }

    function goToDashboard () {
        navigate('/dashboard/home');
        close();
    }

    // function handleShowInterestInRequest () {
    //     API.submitInterestInLoanRequest(data?.loanRequestId, offerData?.loanOfferId)
    //     close()
    // }

    return (
        <>
            <SidePopupContainer >
                <SidePopup data-aos="slide-left">
                    <div className="popup-title-wrapper">
                    <h4>Loan Details</h4>
                        <i className="fa fa-times" onClick={() => close(false)}></i>
                    </div>

                    <div className="side-bar-summary">
                        <div className="bar-summary-data">
                            <p className="bar-summary-data-text">Loan type</p>
                            <p className="bar-summary-data-text">{data?.loanTypeName}</p>
                        </div>
                        <div className="bar-summary-data">
                            <p className="bar-summary-data-text">Loan amount</p>
                            <p className="bar-summary-data-text">&#8358;{utility.moneyFormat(data?.amountPerPerson || data?.amount)}</p>
                        </div>
                        <div className="bar-summary-data">
                            <p className="bar-summary-data-text">Interest rate</p>
                            <p className="bar-summary-data-text">{data?.interest}%</p>
                        </div>
                        <div className="bar-summary-data">
                            <p className="bar-summary-data-text">Loan tenor</p>
                            <p className="bar-summary-data-text">{data?.tenor} {data?.tenorType?.toLowerCase()}(s)</p>
                        </div>
                        <div className="bar-summary-data">
                            <p className="bar-summary-data-text">{data?.repaymentTermName} Repayment</p>
                            <p className="bar-summary-data-text">&#8358;{utility.moneyFormat(data?.repaymentPerTenorBorrower || data?.amountPerTenorType)}</p>
                        </div>
                        <div className="bar-summary-data">
                            <p className="bar-summary-data-text">Total repayment amount</p>
                            <p className="bar-summary-data-text">&#8358;{utility.moneyFormat(data?.totalRepaymentBorrower || data?.totalAmountToBeRepaid)}</p>
                        </div>
                        <Divider margin="1" />
                        <div className="bar-summary-data">
                            <p className="bar-summary-data-text">Repayment type</p>
                            <p className="bar-summary-data-text">{data?.rateId === 1 ? 'Installment' : 'One-off'}</p>
                        </div>
                        <div className="bar-summary-data">
                            <p className="bar-summary-data-text">Repayment occurrence</p>
                            <p className="bar-summary-data-text">{data?.repaymentTermName}</p>
                        </div>
                        {/* <div className="bar-summary-data">
                            <p className="bar-summary-data-text">Repayment start date</p>
                            <p className="bar-summary-data-text">21 Mar 2021</p>
                        </div>
                        <div className="bar-summary-data">
                            <p className="bar-summary-data-text">Repayment end date</p>
                            <p className="bar-summary-data-text">21 Jun 2021</p>
                        </div> */}
                        <Divider margin="1" />
                        { quickRequest?.type === 'loan' &&
                            <>
                                <div className="bar-summary-data">
                                    <p className="bar-summary-data-text">Negotiation</p>
                                    <p className="bar-summary-data-text" style={{color: '#6DE7B4'}}>{data?.isNegotiable ? 'YES' : 'NO'}</p>
                                </div>
                                <div className="bar-summary-data">
                                    <p className="bar-summary-data-text">Loan extension</p>
                                    <p className="bar-summary-data-text" style={{color: '#6DE7B4'}}>{data?.isExtensionAllowed ? 'YES' : 'NO'}</p>
                                </div>
                                <div className="bar-summary-data">
                                    <p className="bar-summary-data-text">Loan extension time</p>
                                    <p className="bar-summary-data-text">{data?.canBeExtendedBy} days</p>
                                </div>
                            </>
                        }
                    </div>
                    <Button
                        width="100"
                        sizeUnit="%"
                        topMargin="10"
                        hoverBgColor="#FED0B7"
                        onClick={handleInterest}
                    >
                        {quickRequest?.type === 'loan' ? 'Check eligibility' : 'Show interest in this loan request'}
                    </Button>
                </SidePopup>
            </SidePopupContainer>
            { showFundPortfolio &&
                <FundPortfolio
                    close={() => setShowFundPortfolio(false)}
                    portfolioId={data?.loanOffer?.loanOfferId}
                    portfolioAmount={data?.loanOffer?.amount}
                    onCompleteMethod={() => setView('agreement')}
                />
            }
            { view === 'agreement' &&
                <Agreement
                    close={() => setView('')} 
                    closeDetails={close}
                    data={data}
                    isLenderShowInterest={true}
                />
            }
        </>
    )
}
