import './style.scss'
import { Container as Wrapper, InfoSideBar, SubContainer, InfoWrapper, Icon, AppText, Button, CustomContainer, FixedContainer, AbsoluteContainer, AppSpan } from 'src/styles'
import { images } from 'src/provider/config/constant'

export const Container: React.FC<any> = ({children, type, progressBar}) => {
  return (
    <Wrapper>
        <a href='https://crendly.com' target='_blank'>
            <img className="app-logo" src={images.LOGO} />
        </a>
        <InfoSideBar>
            { !type &&
                <InfoWrapper>
                    <div className="info-text-wrapper">
                        <p className="info-text-title">You're in <span>Control</span></p>
                        <p className="info-text">Control your profit on what you lend out</p>
                    </div>
                </InfoWrapper>
            }
            { type === "onboarding" &&
                <>
                    <InfoWrapper>
                        <div className="info-text-wrapper">
                            <p className="info-text-title">A place for <span>social lending</span></p>
                            <p className="info-text-plus">A platform where real people can lend money to real people</p>
                        </div>
                    </InfoWrapper>
                    <div className="sidebar-card">
                        <div className="sidebar-card-wrapper">
                            <img src={images.C_LOGO_TEA} className="sidebar-card-logo" />
                            <AppText textSize="1.4" width="10.7" color="#00071F">Advert placement</AppText>
                            <Button
                                width="11"
                                height="3.5"
                                bgColor="#FFCCB9"
                                titleSize="1.4"
                                topMargin="3"
                                radius="0.5"
                            >
                                Learn more
                            </Button>
                        </div>
                    </div>
                </>
            }
        </InfoSideBar>
        <SubContainer>
            <div className="subcontainer-children">
                { progressBar &&
                    <FixedContainer 
                        width='100' sizeUnit='%' zIndex='3' 
                        leftPadding='35' top='0' left='0'
                        className='progress-bar-wrapper'
                        >
                        <CustomContainer width='100' sizeUnit='%' height='0.6' bgColor='#13412E'>
                            {/* <AppSpan fontWeight="400" textSize="1.4" className='onboarding-progress-text'>{progressBar}</AppSpan> */}
                            <CustomContainer width={progressBar} sizeUnit='%' height='0.6' bgColor='#6DE7B4'></CustomContainer>
                        </CustomContainer>
                    </FixedContainer>
                }
                {children}
            </div>
            <img className="bottom-bubble" src={images.AUTH_BUBBLE} />
        </SubContainer>
    </Wrapper>
  )
}
