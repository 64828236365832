import { useState, useContext, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import ApiContext from 'src/provider/API/call-service'
import LoanContext from 'src/provider/state-manager/loanProvider'
import UserContext from 'src/provider/state-manager/userProvider'
import VisibilityContext from 'src/provider/state-manager/visibilityProvider'
import { loanConfig } from 'src/provider/config/constant'
import { SidePopupContainer, SidePopup, Divider, Button  } from "src/styles"
import utility from 'src/utils/utility'

export const LoanPortfolio: React.FC<any> = ({data, close}) => {
  const navigate = useNavigate()
  const {API} = useContext(ApiContext)
  const {user} = useContext(UserContext)
  const {notifier} = useContext(VisibilityContext)
  const {loan: {loanCreation}, setLoanProperty} = useContext(LoanContext)
  const [summaryData, setSummaryData] = useState<any>(null)

  useEffect(() => {
    handleRepaymentCalculation()
  },[])
  
  async function handleRepaymentCalculation () {
    const payload = {
      repaymentTerm: loanConfig.repaymentTerm[loanCreation.rateId == 2 ? 1 : loanCreation.repaymentTermId],
      rate: loanConfig.rate[loanCreation.rateId],
      tenor: loanCreation.tenor,
      tenorType: loanCreation.tenorType,
      loanAmount: loanCreation.amount,
      interest: loanCreation.interest
    }

    const response = await API.calculateLoanRepayment(payload)
    if (response) setSummaryData(response)
    else close()
  }

  function handleEdit () {
    document.body.style.overflow = 'auto'
    navigate('/dashboard/loan-creation/loan-type')
  }

  async function handleLoanPublish () {
    // user.onBoardingStatus?.percCompleted != 100
    if (user.userData?.isAccountSuspended) {
      notifier.show('You are currently suspended from accessing this feature')
      goToDashboard()
      return false;
    }

    if (!user.onBoardingStatus?.canGiveLoan) {
      notifier.show('Kindly complete your profile', 'warning')
      goToDashboard()
      return false;
    }
    else {
      const response = loanCreation?.editType != 'existing-portfolio' ? await API.publishLoanPortfolio() : await API.editPortfolio(loanCreation?.loanOfferId)
      if (response) {
        await setLoanProperty('loanCreation', {})
        close()
      }
    }
  }

  function goToDashboard () {
    navigate('/dashboard/home');
    close();
  }

  return (
    <SidePopupContainer >
      <SidePopup data-aos="slide-left">
        <div className="popup-title-wrapper">
          <h4>Loan Portfolio</h4>
          <div className="side-popup-nav-icons">
            <p onClick={handleEdit}>
              <span className="fa fa-pencil">
                <span>Edit</span>
              </span>
            </p>
            <i className="fa fa-times" onClick={close}></i>
          </div>
        </div>

        <div className="side-bar-summary">
          <div className="bar-summary-data">
            <p className="bar-summary-data-text">Loan type</p>
            <p className="bar-summary-data-text">{data?.loanTypeName || loanConfig.loanType[data?.loanTypeId]}</p>
          </div>
          <div className="bar-summary-data">
            <p className="bar-summary-data-text">Loan amount</p>
            <p className="bar-summary-data-text">&#8358;{utility.moneyFormat(summaryData?.loanAmount)}</p>
          </div>
          <div className="bar-summary-data">
            <p className="bar-summary-data-text">Interest rate</p>
            <p className="bar-summary-data-text">{summaryData?.interestRate}%</p>
          </div>
          {/* <div className="bar-summary-data">
            <p className="bar-summary-data-text">Interest type</p>
            <p className="bar-summary-data-text">Monthly</p>
          </div> */}
          <div className="bar-summary-data">
            <p className="bar-summary-data-text">Loan tenor</p>
            <p className="bar-summary-data-text">{summaryData?.tenor} {summaryData?.tenorType.toLowerCase()}(s)</p>
          </div>
          <div className="bar-summary-data">
            <p className="bar-summary-data-text">{summaryData?.repaymentTerm} payment</p>
            <p className="bar-summary-data-text">&#8358;{utility.moneyFormat(summaryData?.amountPlusInterestPerTenorType)}</p>
          </div>
          <div className="bar-summary-data">
            <p className="bar-summary-data-text">Total interest amount</p>
            <p className="bar-summary-data-text">&#8358;{utility.moneyFormat(summaryData?.interestAmount)}</p>
          </div>
          <div className="bar-summary-data">
            <p className="bar-summary-data-text">Total repayment amount</p>
            <p className="bar-summary-data-text">&#8358;{utility.moneyFormat(summaryData?.totalAmountPlusInterest)}</p>
          </div>
          <Divider margin="1" />
          <div className="bar-summary-data">
            <p className="bar-summary-data-text">Repayment type</p>
            <p className="bar-summary-data-text">{data?.rateId == 1 ? 'Installment' : 'One-off'}</p>
          </div>
          <div className="bar-summary-data">
            <p className="bar-summary-data-text">Repayment occurrence</p>
            <p className="bar-summary-data-text">{summaryData?.repaymentTerm}</p>
          </div>
          {/* <div className="bar-summary-data">
            <p className="bar-summary-data-text">Repayment start date</p>
            <p className="bar-summary-data-text">21 Mar 2021</p>
          </div>
          <div className="bar-summary-data">
            <p className="bar-summary-data-text">Repayment end date</p>
            <p className="bar-summary-data-text">21 Jun 2021</p>
          </div> */}
          <Divider margin="1" />
          <div className="bar-summary-data">
            <p className="bar-summary-data-text">Negotiation</p>
            <p className="bar-summary-data-text" style={{color: '#6DE7B4'}}>{data?.isNegotiable ? 'YES' : 'NO'}</p>
          </div>
          <div className="bar-summary-data">
            <p className="bar-summary-data-text">Loan extension</p>
            <p className="bar-summary-data-text" style={{color: '#6DE7B4'}}>{data?.isExtensionAllowed ? 'YES' : 'NO'}</p>
          </div>
          <div className="bar-summary-data">
            <p className="bar-summary-data-text">Loan extension time</p>
            <p className="bar-summary-data-text">{data?.canBeExtendedBy} days</p>
          </div>
        </div>
        <Button
          width="100"
          sizeUnit="%"
          topMargin="10"
          hoverBgColor="#FED0B7"
          onClick={handleLoanPublish}
        >
          Publish Loan Offer
        </Button>
      </SidePopup>
    </SidePopupContainer>
  )
}
