import { useState, useContext, useEffect } from 'react'
import ApiContext from 'src/provider/API/call-service'
import AppInfoContext from 'src/provider/state-manager/appInfoProvider'
import UserContext from 'src/provider/state-manager/userProvider'
import { images } from 'src/provider/config/constant'
import { 
    SidePopupContainer, 
    SidePopup, 
    OutlineButton, 
    FlexRow, 
    AppText,
    CustomContainer,
    Icon,
    Button,
    AppSpan
} from "src/styles"
import { LoanAgreement, LoanRequestDetails, NegotiationRequest, SignOffer } from 'src/popup'
import helpers from 'src/utils/helpers'

export const Notifications: React.FC<any> = ({close, walletTopup}) => {
    const {API} = useContext(ApiContext)
    const {setInfoProperty} = useContext(AppInfoContext)
    const {user, setUserProperty} = useContext(UserContext)
    const [notifications, setNotifications] = useState<any>(null)
    const [noOfNotifications, setNoOfNotifications] = useState(0)
    const [selectedData, setSelectedData] = useState<any>(null)
    const [showLoanDetails, setShowLoanDetails] = useState(false)
    const [showNegotiationRequest, setShowNegotiationRequest] = useState(false)
    const [showLoanAgreement, setShowLoanAgreement] = useState(false)
    const [showSignature, setShowSignature] = useState(false)

    useEffect(() => {
        handleFetchNotification()
        setInfoProperty('newNotifCount', 0)
    }, [])

    useEffect(() => {
        if (user.notifications?.length) {
            setNoOfNotifications(user.notifications?.length)
            setNotifications(helpers.formatNotification(user.notifications.reverse()))
        }
    }, [user.notifications])

    async function handleFetchNotification() {
        const response = await API.fetchNotifications()
        // if (response) {
        //     setNoOfNotifications(response.length)
        //     setNotifications(helpers.formatNotification(response.reverse()))
        // }
    }

    async function handleSelectedData (data: any, notifType: string, notifId: number) {
        await setUserProperty('notifId', notifId)
        setSelectedData(data)
        if (['Lender_Show_Interest', 'Lender_Accept_Request'].includes(notifType)) setShowLoanAgreement(true)
        else if (notifType === 'Loan_Negotiation') setShowNegotiationRequest(true)
        else if (notifType === 'TopUp_Wallet') walletTopup()
        else if (notifType === 'Borrower_Show_Interest') setShowLoanDetails(true)
        else if (notifType === 'Rejected_Loan_Request') {
            await API.handleLoanRejectionNotif(data)
            close()
        }
    }

    async function handleClearNotifications () {
        const response = await API.clearUserNotifications()
        if (response) close()
    }
    
    return (
        <>
            { (!showLoanDetails && !showNegotiationRequest) &&
                <SidePopupContainer >
                    <SidePopup data-aos="slide-left">
                        <div className="popup-title-wrapper">
                            <FlexRow justifyContent="flex-start">
                                <h4>Notifications</h4>
                                { notifications?.length > 0 &&
                                    <OutlineButton
                                        width="7"
                                        height="2.6"
                                        radius="2"
                                        color="#6DE7B4"
                                        titleSize="1"
                                        leftMargin="1"
                                    >
                                        {noOfNotifications} New
                                    </OutlineButton>
                                }      
                            </FlexRow>
                            <FlexRow width='auto' sizeUnit=''>
                                <Button
                                    width="7" height="2.6"
                                    hoverBgColor="transparent" hoverColor='#E54B4D'
                                    rightMargin="1.5" bgColor='#E54B4D'
                                    titleSize="1" borderColor='#E54B4D'
                                    radius="2" color='#ffffff'
                                    onClick={handleClearNotifications}
                                >
                                    Clear All
                                </Button>
                                <i className="fa fa-times" onClick={() => close(false)}></i>
                            </FlexRow>
                        </div>
                        { notifications?.length > 0 &&
                            <>
                                {
                                    notifications?.map((item: any, index: number) => {
                                        return (
                                            <CustomContainer key={index}>
                                                <AppText bottomPadding="0.8" textSize="1.3">{item?.date.toUpperCase()}</AppText>
                                                { item.info?.map((data: any, innerIndex: number) => {
                                                    let dateTime = helpers.splitDateTime(data?.dateAdded)
                                                    let buttonText = data?.type.replace(/_/g, ' ')
                                                    buttonText = buttonText === 'Lender Accept Request' ? 'Finish Loan Process' 
                                                        : buttonText === 'Borrower Show Interest' ? 'View Request' 
                                                        : buttonText === 'Lender Show Interest' ? 'View Offer'
                                                        : buttonText === 'Rejected Loan Request' ? 'See more'
                                                        : buttonText
                                                    return (
                                                        <CustomContainer bottomMargin="3.5" key={innerIndex}>
                                                            <FlexRow justifyContent="space-between">
                                                                <FlexRow width="auto" sizeUnit="">
                                                                    <Icon src={images.NOTIFICATION_ICON} rightMargin="0.8" />
                                                                    <AppText color="#FED0B7" textSize="1.3">{data.title}</AppText>
                                                                </FlexRow>
                                                                <AppText color="#D8D8D8" textSize="1">{dateTime.date} {dateTime.completeTime} </AppText>
                                                            </FlexRow>
                                                            <AppText fontWeight="400" leftPadding="3" rightPadding="3" textSize="1.3">
                                                                {data.description}
                                                            </AppText>
                                                            {/* !['Maintenance', 'Rejected_Negotiation', 'Rejected_Loan_Request', 'Rejected_Loan_Offer'] */}
                                                            { ['Lender_Show_Interest', 'Lender_Accept_Request', 'Loan_Negotiation', 'TopUp_Wallet', 'Borrower_Show_Interest', 'Rejected_Loan_Request'].includes(data.type) &&
                                                                <Button
                                                                    width="auto"
                                                                    height="3"
                                                                    sizeUnit=""
                                                                    hoverBgColor="#FED0B7" 
                                                                    topMargin="0.6"
                                                                    padding="1.5"
                                                                    leftMargin="3"
                                                                    titleSize="1.2"
                                                                    radius="0.4"
                                                                    onClick={() => handleSelectedData(data?.data, data?.type, data?.refId)}
                                                                >
                                                                    {buttonText}
                                                                </Button>
                                                            }
                                                        </CustomContainer>
                                                    )
                                                })
                                                }
                                            </CustomContainer>
                                        )
                                    })
                                }
                            </>
                        }
                        { !notifications?.length &&
                            <div className="no-notification">
                                <Icon src={images.NOTIFICATION_ICON_BG} />
                                <AppText textSize="1.8" topMargin="4" >No Notifications yet</AppText>
                                <AppSpan textSize="1.4" color="#E2E2E2" fontWeight="400">
                                    You do not have any notifications yet.
                                </AppSpan>
                            </div>
                        }
                    </SidePopup>
                </SidePopupContainer>
            }
            
            { showLoanDetails &&
                <LoanRequestDetails
                    close={() => setShowLoanDetails (false)}
                    closeNotif={() => close()}
                    data={selectedData}
                />
            }
            { showNegotiationRequest &&
                <NegotiationRequest 
                    close={() => setShowNegotiationRequest (false)}
                    data={selectedData}
                />
            }
            { showLoanAgreement &&
                <LoanAgreement
                    close={() => setShowLoanAgreement(false)}
                    closeNotif={() => close()}
                    signOffer={() => setShowSignature(true)}
                    data={selectedData}
                />
            }
            { showSignature &&
                <SignOffer
                    close={() => setShowSignature(false)}
                    closeNotif={() => close()}
                    data={selectedData}
                />
            }
        </>
    )
}
