import helpers from 'src/utils/helpers'
import { images } from 'src/provider/config/constant'
import { 
    SidePopupContainer, 
    SidePopup, 
    Divider, 
    AppSpan,  
    FlexRow,
    ScreenTitle,
    CustomContainer,
    Icon,
    FlexColumn,
    RoundImageContainer
} from "src/styles"
import utility from 'src/utils/utility'

export const TransactionDetails: React.FC<any> = ({close, data}) => {
    let dateTime = helpers.splitDateTime(data?.dateAdded)
    return (
        <SidePopupContainer >
            <SidePopup data-aos="slide-left">
                <FlexRow justifyContent="space-between" topMargin="3" bottomMargin="4">
                    <ScreenTitle textSize="2">Transaction Details</ScreenTitle>
                    <AppSpan className="fa fa-times" color="#FED0B7" textSize="1.7" onClick={() => close(false)}></AppSpan>
                </FlexRow>

                <FlexColumn>
                    <RoundImageContainer size="6" bgColor="#4700E0" isMaxWidth={false}>
                        <Icon src={utility.getStatementIcon(data?.transactionType)} style={{width: '4rem'}} />
                    </RoundImageContainer>
                    <CustomContainer
                        bgColor="#000926" 
                        topPadding="2" 
                        leftPadding="1.5"
                        rightPadding="1.5"
                        topMargin="2"
                        radius="0.8"
                    >
                        <FlexRow justifyContent="space-between" bottomMargin="2.5">
                            <AppSpan color="#E2E2E2" textSize="1.4" fontWeight="400">Transaction type</AppSpan>
                            <AppSpan textSize="1.4">{utility.toTitleCase(data?.transactionType)}</AppSpan>
                        </FlexRow>
                        { data?.loanType &&
                            <FlexRow justifyContent="space-between" bottomMargin="2.5">
                                <AppSpan color="#E2E2E2" textSize="1.4" fontWeight="400">Loan type</AppSpan>
                                <AppSpan textSize="1.4">{data?.loanType}</AppSpan>
                            </FlexRow>
                        }
                        <FlexRow justifyContent="space-between" bottomMargin="2.5">
                            <AppSpan color="#E2E2E2" textSize="1.4" fontWeight="400">Amount</AppSpan>
                            <AppSpan textSize="1.4">₦{utility.moneyFormat(data?.amount)}</AppSpan>
                        </FlexRow>
                        {/* <FlexRow justifyContent="space-between" bottomMargin="2.5">
                            <AppSpan color="#E2E2E2" textSize="1.4" fontWeight="400">Description</AppSpan>
                            <AppSpan textSize="1.4">{data?.description}</AppSpan>
                        </FlexRow> */}
                        <FlexRow justifyContent="space-between" bottomMargin="2.5">
                            <AppSpan color="#E2E2E2" textSize="1.4" fontWeight="400">Operation</AppSpan>
                            <AppSpan textSize="1.4" color={data?.operation === 'Credit' ? '#6DE7B4' : '#FF0062'}>{data?.operation}</AppSpan>
                        </FlexRow>
                        <FlexRow justifyContent="space-between" bottomMargin="2.5">
                            <AppSpan color="#E2E2E2" textSize="1.4" fontWeight="400">Source Account</AppSpan>
                            <AppSpan textSize="1.4">{data?.sourceAccountNumber}</AppSpan>
                        </FlexRow>
                        <FlexRow justifyContent="space-between" bottomMargin="2.5">
                            <AppSpan color="#E2E2E2" textSize="1.4" fontWeight="400">Source Account Name</AppSpan>
                            <AppSpan textSize="1.4">{data?.sourceName || 'Not Available'}</AppSpan>
                        </FlexRow>
                        <FlexRow justifyContent="space-between" bottomMargin="2.5">
                            <AppSpan color="#E2E2E2" textSize="1.4" fontWeight="400">Destination Account</AppSpan>
                            <AppSpan textSize="1.4">{data?.destinationAccountNumber}</AppSpan>
                        </FlexRow>
                        <FlexRow justifyContent="space-between" bottomMargin="2.5">
                            <AppSpan color="#E2E2E2" textSize="1.4" fontWeight="400">Destination Account Name</AppSpan>
                            <AppSpan textSize="1.4">{data?.destinationName || 'Not Available'}</AppSpan>
                        </FlexRow>
                        <FlexRow justifyContent="space-between" bottomMargin="2.5">
                            <AppSpan color="#E2E2E2" textSize="1.4" fontWeight="400">Date</AppSpan>
                            <AppSpan textSize="1.4">{dateTime?.date}</AppSpan>
                        </FlexRow>
                        <FlexRow justifyContent="space-between" bottomMargin="2.5">
                            <AppSpan color="#E2E2E2" textSize="1.4" fontWeight="400">Time</AppSpan>
                            <AppSpan textSize="1.4">{dateTime?.time}{dateTime?.timeEnv}</AppSpan>
                        </FlexRow>
                        <FlexRow justifyContent="space-between" bottomMargin="2.5">
                            <AppSpan color="#E2E2E2" textSize="1.4" fontWeight="400">Status</AppSpan>
                            <AppSpan textSize="1.4" color={data?.status === 'success' ? '#6DE7B4' : data?.status === 'Pending' ? '#F2994A' : '#FF0062'}>{data?.status}</AppSpan>
                        </FlexRow>
                        <Divider margin="6" bgColor="#13276F" />
                    </CustomContainer>
                </FlexColumn>
            </SidePopup>
        </SidePopupContainer>
    )
}
