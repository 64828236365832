import { Style } from 'react-loader-spinner/dist/type'
import { StyleProps } from 'src/model'
import styled from 'styled-components'

export const SideBarContainer = styled.div<StyleProps>`
    width: 23rem;
    height: 100%;
    padding-top: 8rem;
    background-color: #00071F;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 5;
    transition: all .2s;
    overflow-y: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
        display: none
    }

    @media (max-width: 770px) {
        left: ${({isSideBar}) => isSideBar ? 0 : -23}rem;
    }
`
export const SideBarShadow = styled.div<StyleProps>`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: none;
    background-color: rgba(0,0,0, 0.4);
    z-index: 4;

    @media (max-width: 770px) {
        display: ${({isSideBar}) => isSideBar ? 'block' : 'none'}
    }
`