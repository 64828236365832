import { useState, useContext, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { images } from 'src/provider/config/constant'
import ApiContext from 'src/provider/API/call-service'
import LoanContext from 'src/provider/state-manager/loanProvider'
import { SidePopupContainer, SidePopup, Divider, Button, FlexRow, AppLink  } from "src/styles"
import utility from 'src/utils/utility'
import { loanRequests } from 'src/utils/mockData'
import { SelectedOfferDetails, DecisionBox } from 'src/popup'


export const OpenLoanRequests: React.FC<any> = ({close}) => {
  const navigate = useNavigate()
  const {API} = useContext(ApiContext)
  const {loan: {currentLoans, loanIdB4Cancel}} = useContext(LoanContext)
  const [openRequests, setOpenRequests] = useState<Array<object>>([])
  const [details, setDetails] = useState({})
  const [showDetails, setShowDetails] = useState(false)
  const [showDecisionBox, setShowDecisionBox] = useState(false)
  const [showRequestLoan, setShowRequestLoan] = useState(false)

  useEffect(() => { 
    setOpenRequests(currentLoans?.loanOffers)
  }, [currentLoans])

  function handleDetails (data: any) {
    setDetails(data)
    setShowDetails(true)
  }

  async function handleCancelLoan () {
    const response = await API.cancelOpenLoanRequest(currentLoans?.loanRequest?.loanRequestId)
    if (response) setShowRequestLoan(true)
  }

  async function handleNewLoanRequest () {
    const response = await API.createLoanRequestReplica(currentLoans?.loanRequest?.loanRequestId || Number(loanIdB4Cancel))
    if (response) {
      // CREDIT DECISION CHECK PROCESS HERE
      //navigate('/dashboard/loan-board/loan-offers')
      close()
    }
  }

  return (
    <>
      { !showDetails &&
        <SidePopupContainer >
          <SidePopup data-aos="slide-left">
            <div className="popup-title-wrapper">
              <h4>Loan Offers</h4>
              <i className="fa fa-times" onClick={close}></i>
            </div>
            <span className="popup-title-summary">
                {openRequests?.length} Loan requests you have placed
            </span>
            <div className="selected-offer-list">
              {
                openRequests?.map((item: any, index: number) => {
                  return (
                    <div className='selected-offer-wrapper' key={index}>
                      <div className="offer-details-summary">
                          <div className="loan-offer-header">
                              <div className="loan-offer-label">
                                  <img src={images.LOAN_OFFER_ICON} className="loan-offer-header-icon" />
                                  <div className="loan-offer-label-text">
                                      {/* <label>LoanType</label> */}
                                      <p className='loan-offer-type-text'>{item?.loanTypeName}</p>
                                  </div>
                              </div>
                              <span className="loan-offer-percentage" style={{color: '#6DE7B4'}}>{item?.interest}%p.m</span>
                          </div>
                          <Divider margin="1.5" />
                          <div className="selected-data-container">
                              <div className="loan-offer-label-text">
                                  <label>Amount</label>
                                  <p>N{utility.moneyFormat(item?.amountPerPerson)}</p>
                              </div>
                              <div className="loan-offer-label-text">
                                  <label>Duration</label>
                                  <p>{item?.tenor} {item?.tenorType.toLowerCase()}(s)</p>
                              </div>
                              <div className="loan-offer-label-text">
                                  <label>Payment</label>
                                  <p>{item.rateId === 1 ? 'Installment' : 'One-off'}</p>
                              </div>
                              <div className="loan-offer-label-text">
                                  <label>Submitted</label>
                                  <p>{index === 0 ? `${currentLoans?.submittedToFirst}` : index === 1 ? `${currentLoans?.submittedToSecond}` : `${currentLoans?.submittedToThird}`}</p>
                              </div>
                          </div>
                      </div>
                      <AppLink 
                        textSize="1.2" 
                        hoverColor="#FED0B7" 
                        decoration='none'
                        hasBottomBorder={true}
                        onClick={() => handleDetails(item)}
                      >
                        View loan request details
                      </AppLink>
                    </div>
                  )
                })
              }
            </div>
            <FlexRow justifyContent='flex-end' topMargin='4' bottomMargin='2' rightPadding='1.5'>
              <Button
                width="17"
                height='4'
                leftMargin='2'
                titleSize='1.4'
                hoverBgColor="#FED0B7"
                onClick={() => setShowDecisionBox(true)}
              >
                Cancel all requests
              </Button>
            </FlexRow>
          </SidePopup>
        </SidePopupContainer>
      }
      { showDetails &&
        <SelectedOfferDetails 
          close={() => setShowDetails(false)}
          data={details}
        />
      }
      { showDecisionBox &&
        <DecisionBox
          close={() => setShowDecisionBox(false)}
          title='Cancel all loan request'
          subheading='Are you sure you want to cancel all your loan requests?'
          noText="No, Don't cancel"
          yesText="Yes, Cancel"
          yesMethod={handleCancelLoan}
        />
      }
      { showRequestLoan &&
        <DecisionBox
          close={() => setShowRequestLoan(false)}
          title='All loan requests cancelled'
          subheading='You have cancelled all loan requests.'
          noText="No, not now"
          yesText="Yes, Take a new loan"
          icon={images.SUCCESS_RESPONSE}
          noMethod={close}
          yesMethod={handleNewLoanRequest}
        />
      }
    </>
  )
}
