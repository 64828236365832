import { handleTypeNumberControl } from "src/utils/utility"
import { useContext, useState } from "react"
import ApiContext from "src/provider/API/call-service"
import UserContext from "src/provider/state-manager/userProvider"
import VisibilityContext from "src/provider/state-manager/visibilityProvider"
import { CenterPopupContainer, ScreenTitle, AppText, CustomContainer, Button } from "src/styles"


export const TransactionPinSetting: React.FC<any> = () => {
    const {API} = useContext(ApiContext)
    const {notifier} = useContext(VisibilityContext)
    const {user, setUserProperty} = useContext(UserContext)
    const [transPin, setTransPin] = useState({pin: '', cPin: ''})

    const handlePin = (e: React.ChangeEvent<HTMLInputElement|HTMLSelectElement>) => {
        if (e.target.value.length < 5) {
            setTransPin({...transPin, [e.target.name]: e.target.value})
        }
    }

    async function handleNext () {
        const response = await API.createTransactionPin(transPin.pin, transPin.cPin)
        if (response) {
            notifier.show('Transaction Pin Created Successfully', 'success')
            await setUserProperty('userData', {...user.userData, hasSetTransactionPin: true})
        }
    }
    
    return (
        <CenterPopupContainer zIndex="6">
            <div className="transaction-pin-container">
                <ScreenTitle color="#FED0B7" textSize="3.2" topMargin="4" fontWeight="bold" align="center">
                    Create Transaction PIN
                </ScreenTitle>
                <AppText textSize="1.6" topMargin="0.7" align="center">Create your own transaction PIN</AppText>

                <CustomContainer topMargin="6" width="auto" sizeUnit="">
                    <AppText textSize="1.8" fontWeight="400" align="center" color="#fff" bottomMargin="1">
                        Enter New PIN
                    </AppText>
                    <div className="passcode-input-wrapper">
                        <input 
                            className='display-hidden' id='pin'
                            name="pin" value={transPin.pin} 
                            onChange={handlePin}
                            onKeyUp={handleTypeNumberControl}
                            onKeyDown={handleTypeNumberControl}  
                            autoFocus={true} autoComplete="off"
                        />
                        <label className={`transaction-pin-input ${transPin.pin.length === 0 && 'active-pin-box'}`} htmlFor='pin' >{transPin.pin[0] ? '*' : ''}</label>
                        <label className={`transaction-pin-input ${transPin.pin.length === 1 && 'active-pin-box'}`} htmlFor='pin' >{transPin.pin[1] ? '*' : ''}</label>
                        <label className={`transaction-pin-input ${transPin.pin.length === 2 && 'active-pin-box'}`} htmlFor='pin' >{transPin.pin[2] ? '*' : ''}</label>
                        <label className={`transaction-pin-input ${transPin.pin.length === 3 && 'active-pin-box'}`} htmlFor='pin' >{transPin.pin[3] ? '*' : ''}</label>
                    </div>
                </CustomContainer>
                <CustomContainer topMargin="6" width="auto" sizeUnit="">
                    <AppText textSize="1.8" fontWeight="400" align="center" color="#fff" bottomMargin="1">
                        Confirm New PIN
                    </AppText>
                    <div className="passcode-input-wrapper">
                        <input 
                            className='display-hidden' id='cPin' 
                            name="cPin" value={transPin.cPin}
                            onKeyUp={handleTypeNumberControl}
                            onKeyDown={handleTypeNumberControl}   
                            onChange={handlePin} autoComplete="off"
                        />
                        <label className={`transaction-pin-input ${transPin.cPin.length === 0 && 'active-pin-box'}`} htmlFor='cPin' >{transPin.cPin[0] ? '*' : ''}</label>
                        <label className={`transaction-pin-input ${transPin.cPin.length === 1 && 'active-pin-box'}`} htmlFor='cPin' >{transPin.cPin[1] ? '*' : ''}</label>
                        <label className={`transaction-pin-input ${transPin.cPin.length === 2 && 'active-pin-box'}`} htmlFor='cPin' >{transPin.cPin[2] ? '*' : ''}</label>
                        <label className={`transaction-pin-input ${transPin.cPin.length === 3 && 'active-pin-box'}`} htmlFor='cPin' >{transPin.cPin[3] ? '*' : ''}</label>
                    </div>
                </CustomContainer>
                <Button 
                    width="20" 
                    height="5" 
                    bottomMargin="3"
                    hoverBgColor="#FFC49D"
                    topMargin="10"
                    className="loan-action-btn"
                    disabled={transPin.pin.length != 4 || transPin.cPin.length != 4 ? true : false}
                    onClick={handleNext}
                >
                    Set PIN
                </Button>
            </div>
        </CenterPopupContainer>
    )
}