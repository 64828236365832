import './style.scss'
import { useState, useEffect, useContext } from 'react'
import ApiContext from 'src/provider/API/call-service'
import VisibilityContext from 'src/provider/state-manager/visibilityProvider'
import UserContext from 'src/provider/state-manager/userProvider'
import LoanContext from 'src/provider/state-manager/loanProvider'
import { useNavigate } from 'react-router-dom'
import utility from 'src/utils/utility'
import { BsChevronLeft } from 'react-icons/bs'
import { BiCheck } from 'react-icons/bi'
//import { trustQuestions as firstSectionQuestion, personalityQuestions as secondSectionQuestions } from 'src/utils/questionaire'
import { 
    AppLink,
    AppSpan, 
    AppText, 
    BorderlessButton, 
    Button, 
    CustomContainer, 
    FlexRow,  
    RoundImageContainer,  
    ScreenContainer, 
} from 'src/styles'
import { PostponeQuestionaire } from 'src/popup'


const Questionaire = () => {
    const navigate = useNavigate()
    const {API} = useContext(ApiContext)
    const {user} = useContext(UserContext)
    const {loan: {loanCreation, loanRequest}} = useContext(LoanContext)
    const {responder} = useContext(VisibilityContext)
    const [selectedTab, setSelectedTab] = useState('trust')
    const [showPostponeQuestion, setShowPostponeQuestion] = useState(false)
    const [trustQuestions, setTrustQuestions] = useState<any>([])
    const [personalityQuestions, setPersonalityQuestions] = useState<any>([])
    const [trustAnswers, setTrustAnswers] = useState<any>([])
    const [personalityAnswers, setPersonalityAnswers] = useState<any>([])
    const [questionIndex, setQuestionIndex] = useState(0)
    const [isTrustCompleted, setIsTrustCompleted] = useState(false)
    const [isPersonalityCompleted, setIsPersonalityCompleted] = useState(false)
    const [subPersonalityQuestionNo, setSubPersonalityQuestionNo] = useState(1)
    const [personalityQuestionNo, setPersonalityQuestionNo] = useState(1)

    useEffect(() => {
        if(user.userData?.mobileNo || user.userData?.phoneNumber) getQuestions()
        document.body.style.overflow = 'auto'
    }, [user?.userData])

    useEffect(() => {
        if (user.formattedUserData?.hasTakenPersonalityQuestion && user.formattedUserData?.hasTakenTrustQuestion) {
            if (loanRequest?.tenor) navigate('/dashboard/get-loan/loan-duration')
            else navigate('/dashboard/home')
        }
    }, [user?.formattedUserData])

    async function getQuestions () {
        if (!trustQuestions.length || !personalityQuestions.length) {
            const response1 = await API.fetchTrustQuestions()
            if (response1) {
                setTrustQuestions(response1)
                const response2 = await API.fetchPersonalityQuestions()
                if (response2) {
                    setPersonalityQuestions(response2)
                    getSavedAnswers(response1, response2)
                }
                else navigate('/dashboard/home')
            }
            else navigate('/dashboard/home')
        }
    }

    async function getSavedAnswers (trust: any, personality: any) {
        const response = await API.getSavedTrustAnswers()
        const response2 = await API.getSavedPersonlityAnswers()

        if (response?.length > 0) {
            let newTrustQuestions = [...trust]
            let newTrustAnswers = []
            let index = 0
            
            for (let question of trust) {
                for (let answer of response) {
                    if (question.id === answer.questionId) {
                        newTrustQuestions[index] = {
                            ...newTrustQuestions[index],
                            answer: answer.optionId === 1 ? 'a' : answer.optionId === 2 ? 'b' : answer.optionId === 3 ? 'c' : answer.optionId === 4 ? 'd' : 'e'
                        }

                        newTrustAnswers[index] = {
                            questionId: answer.questionId,
                            optionId: answer.optionId
                        }

                        break;
                    }
                }
                index++
            }
            setQuestionIndex(response?.length - 1)
            setTrustQuestions(newTrustQuestions)
            setTrustAnswers(newTrustAnswers)
        }

        if (response2?.length > 0) {
            let newPersonalityQuestions = [...personality]
            let newPersonalityAnswers = []
            let index = 0
            
            for (let question of personality) {
                for (let answer of response2) {
                    if (question.id === answer.questionId) {
                        newPersonalityQuestions[index] = {
                            ...newPersonalityQuestions[index],
                            answer: answer.optionId === question.options[0].id ? 'a' : 'b'
                        }

                        newPersonalityAnswers[index] = {
                            questionId: answer.questionId,
                            optionId: answer.optionId
                        }
                    }
                }
                index++
            }
            setPersonalityQuestions(newPersonalityQuestions)
            setPersonalityAnswers(newPersonalityAnswers)
        }
    }

    async function handleQuestion (val: number) {
        if (selectedTab === 'trust') {
            if (questionIndex + val > 11) {
                console.log({trustAnswers})
                const response = await API.submitTrustAnswers({
                    userPhoneNumber: user.userData.mobileNo || user.userData.phoneNumber,
                    customerId: user.userData.customerId,
                    answers: trustAnswers
                })
                if (response) {
                    trustQuestionCompleted()
                    setIsTrustCompleted(true)
                    setSelectedTab('personality')
                    setQuestionIndex(0)
                }
            }
            else if (questionIndex + val >= 0) {
                setQuestionIndex(questionIndex + val)
            }
        }
        else {
            let newQuestion = questionIndex + val
            if (newQuestion === 5 || newQuestion === 10 || newQuestion === 15) {
                setSubPersonalityQuestionNo(1)
                setPersonalityQuestionNo(personalityQuestionNo + val)
                if (!personalityAnswers[newQuestion]) personalityQuestionCompleted()
            }
            if (newQuestion === 20) {
                console.log({personalityAnswers})
                const response = await API.submitPersonalityAnswers({
                    userPhoneNumber: user.userData.mobileNo || user.userData.phoneNumber,
                    customerId: user.userData.customerId,
                    answers: personalityAnswers
                })
                if (response) {
                    personalityQuestionCompleted()
                    setIsPersonalityCompleted(true)
                    handleTabSelection('trust')
                }
            }
            if ((newQuestion >= 0) && (newQuestion <= 19)) {
                if (subPersonalityQuestionNo === 1 && val === -1) {
                    setSubPersonalityQuestionNo(5)
                }
                else if ((subPersonalityQuestionNo + val >= 1) && (subPersonalityQuestionNo + val <= 5)) setSubPersonalityQuestionNo(subPersonalityQuestionNo + val)
                setQuestionIndex(newQuestion)
            }
        }
    }

    function handleSelectedOption (option: any) {
        let newQuestions;
        let newAnswers;
        if (selectedTab === 'trust') {
            newQuestions = [...trustQuestions]
            newQuestions[questionIndex] = {
                ...newQuestions[questionIndex],
                answer: option.value
            }

            newAnswers = [...trustAnswers]
            newAnswers[questionIndex] = {
                questionId: newQuestions[questionIndex].id,
                optionId: option.id
            }
            setTrustQuestions(newQuestions)
            setTrustAnswers(newAnswers)

            if ((questionIndex < 11)) handleQuestion(1) // take user to next trust question after answering a question
        }
        else {
            newQuestions = [...personalityQuestions]
            newQuestions[questionIndex] = {
                ...newQuestions[questionIndex],
                answer: option.value
            }

            newAnswers = [...personalityAnswers]
            newAnswers[questionIndex] = {
                questionId: newQuestions[questionIndex].id,
                optionId: option.id
            }
            setPersonalityQuestions(newQuestions)
            setPersonalityAnswers(newAnswers)

            if (questionIndex < 19)  handleQuestion(1) // take user to next personality question after answering a question
        }
    }

    function handleTabSelection (type: string) {
        setSelectedTab(type)
        setQuestionIndex(0)
        setSubPersonalityQuestionNo(1)
        setPersonalityQuestionNo(1)
    }

    function trustQuestionCompleted () {
        responder.show(
            'Trust questions completed successfully', 
            '',
            'user-trust',
            'Continue',
            '/dashboard/questionaire'
        )
    }

    function personalityQuestionCompleted () {
        responder.show(
            personalityQuestionNo != 4 ? `Wow.  ${personalityQuestionNo} of 4 Personality question completed. Let’s go` : 'Personality questions has been completed successfully', 
            personalityQuestionNo != 4 ? `${personalityQuestionNo} of 4 personality questions completed. ${4 - personalityQuestionNo} more to go.` : `${personalityQuestionNo} of 4 personality questions completed.`,
            'user-trust',
            'Continue',
            '/dashboard/questionaire'
        )
    }

    return (
        <>
            <ScreenContainer>
                <CustomContainer topMargin="3" leftPadding="2.5" rightPadding="2.5" className="reduce-container-padding">
                    <FlexRow width="auto" sizeUnit="" justifyContent="flex-start" alignItems="flex-start">
                        <BsChevronLeft className="loan-back-icon" onClick={() => navigate(-1)} />
                        <CustomContainer width="40">
                            <AppText textSize="2" bottomMargin="1" >Complete your profile details</AppText>
                            <AppSpan textSize="1.3" fontWeight="400">
                                Kindly update your details and any other information required to proceed with your loan application.
                            </AppSpan>
                        </CustomContainer>
                    </FlexRow>
                    <div className="questionaire-container">
                        <div className="question-tabs">
                            <div className="question-type-wrapper">
                                <div className="question-type-wrapper-inner">
                                    <FlexRow 
                                        width="auto" sizeUnit="" 
                                        justifyContent="flex-start" 
                                        className="question-tab-type"
                                        onClick={() => handleTabSelection('trust')}
                                    >
                                        <RoundImageContainer 
                                            borderColor={selectedTab === 'trust' ? '#fff' : "#0930B6"} 
                                            size="2.5" bgColor={isTrustCompleted ? '#6DE7B4' : 'transparent'}
                                        >
                                            <BiCheck style={{color: selectedTab === 'trust' ? '#fff' : "#0930B6" }} />
                                        </RoundImageContainer>
                                        <CustomContainer width="auto" sizeUnit="" leftMargin="2.5">
                                            <AppText textSize="1.3" color={selectedTab === 'trust' ? '#fff' : "#808493"}>Trust Question</AppText>
                                            <AppSpan color={selectedTab === 'trust' ? '#E2E2E2' : "#808493"} textSize="1.3" fontWeight="400">Kindly answer the questions. </AppSpan>
                                        </CustomContainer>
                                    </FlexRow>
                                    <FlexRow 
                                        width="auto" sizeUnit="" 
                                        justifyContent="flex-start" topMargin="5" 
                                        className="question-tab-type"
                                        onClick={() => handleTabSelection('personality')}
                                    >
                                        <RoundImageContainer 
                                            borderColor={selectedTab === 'personality' ? '#fff' : "#0930B6"} 
                                            size="2.5" bgColor={isPersonalityCompleted ? '#6DE7B4' : 'transparent'}
                                        >
                                            <BiCheck style={{color: selectedTab === 'personality' ? '#fff' : "#0930B6"}} />
                                        </RoundImageContainer>
                                        <CustomContainer width="auto" sizeUnit="" leftMargin="2.5">
                                            <AppText textSize="1.3" color={selectedTab === 'personality' ? '#fff' : "#808493"}>Personality Question</AppText>
                                            <AppSpan color={selectedTab === 'personality' ? '#E2E2E2' : "#808493"} textSize="1.3" fontWeight="400">Kindly answer the questions. </AppSpan>
                                        </CustomContainer>
                                    </FlexRow>
                                    <p className="tab-link-line"></p>
                                </div>
                            </div>
                        </div>
                        <div className="question-data">
                            <div className="question-title-wrap">
                                <AppText textSize="1.8" rightMargin="1.5">
                                    {selectedTab === 'trust' ? 'Trust' : 'Personality'} Question
                                    { selectedTab === 'personality' &&
                                        <AppSpan leftMargin="1.5" textSize="1.6" fontWeight="600"><AppSpan color="#6DE7B4">{personalityQuestionNo}</AppSpan> of 4</AppSpan>
                                    }
                                </AppText>
                                <AppText color="#FED0B7" textSize="1.4">
                                    Question
                                    <AppSpan textSize="2.4" fontWeight="600" color="#FED0B7" leftMargin="1">{selectedTab === 'trust' ? (questionIndex + 1) : subPersonalityQuestionNo}</AppSpan>
                                    <AppSpan textSize="2.4" fontWeight="600">/</AppSpan>
                                    <AppSpan textSize="1.6" fontWeight="600">{selectedTab === 'trust' ? '12' : '5'}</AppSpan>
                                </AppText>
                            </div>
                            <div className="question-container">
                                { selectedTab === 'trust' &&
                                    <>
                                        <AppText textSize="1.4">{trustQuestions[questionIndex]?.question}</AppText>
                                        <div className="question-options-wrapper">
                                            {
                                                trustQuestions[questionIndex]?.options.map((item: any, index: number) => {
                                                    let option = index === 0 ? 'a' : index === 1 ? 'b' : index === 2 ? 'c' : index === 3 ? 'd' : 'e'
                                                    return (
                                                        <div 
                                                            className={ trustQuestions[questionIndex].answer === option ? "question-option-plus": "question-option"} 
                                                            onClick={() => handleSelectedOption({id: item.id, value: option})}
                                                            key={index}
                                                        >
                                                            <FlexRow justifyContent="flex-start">
                                                                <RoundImageContainer 
                                                                    size="2.5" 
                                                                    borderColor={trustQuestions[questionIndex].answer === option ? "#fff" : "#8095E0"} 
                                                                    rightMargin="1.5" 
                                                                />
                                                                <AppText textSize="1.3">{item.option}</AppText>
                                                            </FlexRow>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                    </>
                                }
                                { selectedTab === 'personality' &&
                                    <>
                                        <AppText textSize="1.4">{personalityQuestions[questionIndex]?.question}</AppText>
                                        <div className="question-options-wrapper">
                                            {
                                                personalityQuestions[questionIndex]?.options.map((item: any, index: number) => {
                                                    let option = index === 0 ? 'a' : 'b'
                                                    return (
                                                        <div 
                                                            className={ personalityQuestions[questionIndex]?.answer === option ? "question-option-plus": "question-option"} 
                                                            onClick={() => handleSelectedOption({id: item.id, value: option})}
                                                            key={index}
                                                        >
                                                            <FlexRow justifyContent="flex-start">
                                                                <RoundImageContainer 
                                                                    size="2.5" 
                                                                    borderColor={personalityQuestions[questionIndex]?.answer === option ? "#fff" : "#8095E0"}  
                                                                    rightMargin="1.5" 
                                                                />
                                                                <AppText textSize="1.3" width='85' sizeUnit='%' >{item.option}</AppText>
                                                            </FlexRow>
                                                        </div> 
                                                    )
                                                })
                                            }
                                        </div>
                                    </>
                                }
                                <FlexRow justifyContent="space-between" topMargin="8" className="questionaire-btns">
                                    { questionIndex > 0 &&
                                        <AppLink textSize="1.3" hoverColor="#FED0B7" topMargin="3" onClick={() => setShowPostponeQuestion(true)}>Save & Continue Later</AppLink>
                                    }
                                    <FlexRow justifyContent="flex-start" width="auto" sizeUnit="" topMargin="3">
                                        { questionIndex > 0 &&
                                            <BorderlessButton color="#fff" hoverColor="#FED0B7" onClick={() => handleQuestion(-1)}>Back</BorderlessButton>
                                        }
                                        <Button
                                            width="15"
                                            height="4.5"
                                            hoverBgColor="#FED0B7"
                                            leftMargin="4"
                                            className="loan-action-btn"
                                            disabled={selectedTab === 'trust' && trustQuestions[questionIndex]?.answer ? false : selectedTab === 'personality' && personalityQuestions[questionIndex]?.answer ? false : true}
                                            onClick={() => handleQuestion(1)}
                                        >
                                            Next
                                        </Button>
                                    </FlexRow>
                                </FlexRow>
                            </div>
                        </div>
                    </div>
                </CustomContainer>
            </ScreenContainer>

            { showPostponeQuestion &&
                <PostponeQuestionaire 
                    close={() => setShowPostponeQuestion(false)} 
                    tabType={selectedTab}
                    data={selectedTab === 'trust' ? trustAnswers : personalityAnswers}
                />
            }
        </>
    )
}

export default utility.routeData('questionaire', 'Questionaire', <Questionaire/>)