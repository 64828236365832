import { useState, useEffect } from 'react'
import utility from 'src/utils/utility'
import { useNavigate } from 'react-router-dom'
import { AppSpan, AppText, CustomContainer, FlexRow, ScreenTitle } from 'src/styles'
import { BsChevronLeft, } from 'react-icons/bs'
import { FiSearch } from 'react-icons/fi'
import { ExploreLoanDetails } from 'src/popup'


const LoanRequest = () => {
    const navigate = useNavigate()
    const [showLoanDetails, setShowLoanDetails] = useState(false)

    useEffect(() => {
        if(showLoanDetails) {
          document.body.style.overflow = 'hidden'
        }
        else document.body.style.overflow = 'auto'
    }, [showLoanDetails])

    return (
        <>
            <div style={{marginTop: '7rem', zIndex: '2', paddingRight: '1rem', position: 'relative'}}>
                <div className="loan-category-title">
                    <BsChevronLeft 
                        className="loan-back-icon" 
                        onClick={() => navigate(-1)} 
                    />
                    <CustomContainer leftMargin="2">
                        <ScreenTitle textSize="2.2" bottomMargin="1" >
                            ₦0 - ₦50,000 Loan Requests
                        </ScreenTitle>
                        <AppText color="#E2E2E2" textSize="1.3" fontWeight="400">
                            Quis blandit tempus, risus non vivamus tortor natoque. Urna pellentesque tellus.
                        </AppText>
                    </CustomContainer>
                </div>

                <div className="loan-request-search">
                    <div className="search-input-wrapper loan-request-search">
                        <FiSearch className="search-icon-center" />
                        <input className="search-offer-input" placeholder="Search by loan amount"/>
                    </div>
                </div>

                <div className="loan-list-card-wrapper">
                    {
                        Array.from(Array(6).keys()).map((item: any, index: number) => {
                            return (
                                <div className="loan-category-card loan-request-card" key={index} onClick={() => setShowLoanDetails(true)}>
                                    <FlexRow justifyContent="space-between" alignItems="flex-start">
                                        <AppSpan textSize="1.8" fontWeight="400" topMargin="1">Loan request</AppSpan>
                                        <AppText textSize="1.8" topMargin="1" leftMargin="1.5">N20,000</AppText>
                                    </FlexRow>
                                    <AppSpan color="#E2E2E2" textSize="1" fontWeight="400">Requests 12th Feburary, 2022</AppSpan>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
            { showLoanDetails &&
                <ExploreLoanDetails close={() => setShowLoanDetails(false)} />
            }
        </>
    )
}

export default utility.routeData('loan-request', 'Loan Request', <LoanRequest/>)