import { useState, useContext, useEffect } from 'react'
import utility from 'src/utils/utility'
import ApiContext from 'src/provider/API/call-service'
import { useNavigate } from 'react-router-dom'
import { Container } from 'src/component'
import { AppSpan, AppText, Button, CustomContainer, FlexRow, FormGroup, ScreenTitle } from 'src/styles'
import { BsChevronLeft } from 'react-icons/bs'

const BusinessEmail = () => {
    const navigate = useNavigate()
    const {API} = useContext(ApiContext)
    const [email, setEmail] = useState('')
    const [isValidated, setIsValidated] = useState(false)

    useEffect(() => {
        setIsValidated(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email))
    }, [email])

    async function handleJoinWaitlist () {
        await API.joinWaitlist()
        navigate('/login')
    }

    return (
        <Container type="onboarding">
            <p className="right-align-text">Already have an account? 
                <a className="registration-link" onClick={() => navigate('/login')}> Sign in</a>
            </p>
            <div className="onboarding-container">
                <BsChevronLeft className="loan-back-icon" onClick={() => navigate(-1)} />
                <ScreenTitle textSize="3" bottomMargin="1.5" topMargin="3" >
                    Crendly for Business
                </ScreenTitle>
                <AppText color="#D5D5D5" textSize="1.4" fontWeight="400" bottomMargin="2">
                    For business that want to be part of these great idea, we have you in mind. 
                    Crendly for business is coming soon.
                </AppText>
                <AppText color="#D5D5D5" textSize="1.4" fontWeight="400">
                    You can join our waitlist to know to be the first to know wen we are live.
                </AppText>
                <FormGroup  topMargin="5">
                    <label>Business email address</label>
                    <input 
                        type="email" 
                        placeholder="example@gmail.com" 
                        name="email" 
                        onChange={(e) => setEmail(e.target.value)}
                    />
                </FormGroup>
                <Button 
                    width="18" 
                    height="4.5" 
                    hoverBgColor="#FFC49D" 
                    topMargin="5"
                    bottomMargin="3"
                    className="loan-action-btn"
                    disabled={!isValidated ? true : false}
                    onClick={handleJoinWaitlist}
                >
                    Join Waitlist
                </Button>
            </div>
        </Container>
    )
}

export default utility.routeData('business', 'Business Onboarding', <BusinessEmail/>)