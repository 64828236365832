import { useState, useRef, useContext } from 'react'
import utility from 'src/utils/utility'
import ApiContext from 'src/provider/API/call-service'
import UserContext from 'src/provider/state-manager/userProvider'
import AppInfoContext from 'src/provider/state-manager/appInfoProvider'
import {usePaystackPayment} from 'react-paystack'
import { appSettings, appBaseUrl } from 'src/provider/config/constant'
import { SidePopupContainer, SidePopup, Button, FormGroupWithIcon } from "src/styles"
import { useBudPayPayment, closeBudPayPaymentModal } from 'budpay-react-v2';
import { BudPayResponse } from 'src/model'

export const WalletTopup: React.FC<any> = ({close}) => {
  const {API} = useContext(ApiContext)
  const {user} = useContext(UserContext)
  const {setInfoProperty} = useContext(AppInfoContext)
  const [isCardType, setIsCardType] = useState(true)
  const [input, setInput] = useState({amount: 0})

  const verifiedTopupRef = useRef<boolean>(false)
  const amountRef = useRef<number|null>(null)

  function handleToggle (e: React.ChangeEvent<HTMLInputElement>) {
    if (!e.target.checked) setIsCardType(true)
    else setIsCardType(false)
  }

  const handleInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.name === "amount") {
        let val = e.target.value.replace(/,/g, '')
        if (!isNaN(Number(val))) {
          setInput({...input, amount: Number(val)})
          amountRef.current = Number(val)
        }
    }
    else setInput({...input, [e.target.name]: Number(e.target.value)})
  }

  // initialize paystack payment
  //const initializeFunding = usePaystackPayment(utility.getPaymentConfig(input.amount, user.userData?.email));

  // const onSuccess = (response: any) => {
  //   console.log(response, "payment successful");
  //   const payload = {
  //     customerId: user?.userData.customerId,
  //     transactionRef: response.trxref,
  //     charge: appSettings.DEFAULT_CHARGE
  //   }

  //   API.topupWallet(payload).then((resp: any) => {
  //     if (resp) close()
  //   })
  // };
  
  // const onClose = () => {
  //   console.log('payment closed')
  //   close()
  // }

  // Initialize Budpay redirect flow
  async function initializeFunding () {
    const response = await API.initializeFunding({
      amount: input.amount,
      email: user.userData?.email,
      customerId: user.userData?.customerId,
      callback: `${appBaseUrl}/dashboard/payment-confirmation/gateway`
    })

    console.log({response})
    if (response) {
      await setInfoProperty('currentPaymentGateway', response.payment_gateway)
      const paymentLink = document.createElement('a');
      document.body.appendChild(paymentLink);
      //paymentLink.target = 'blank'
      paymentLink.href = response.authorization_url
      paymentLink.click();
    }
  }

  // const budPayConfig = {
  //   ...utility.getPaymentConfig(input.amount, user.userData),
  //   text: 'Pay with BudPay',
  //   //callback_url: `${appBaseUrl}/dashboard/payment-confirmation/gateway`,
  //   callback: function (response: BudPayResponse) {
  //     closeBudPayPaymentModal();
  //     if (!verifiedTopupRef.current && (input.amount === amountRef.current) && response?.reference) {
  //       verifiedTopupRef.current = true
  //       handleTopupVerificationProcess(response)
  //     }
  //   },
  //   onClose: function (txDetails: BudPayResponse) {
  //     console.log('payment closed')
  //     handleTopupVerificationProcess(txDetails)
  //   },
  // };

  // function handleTopupVerificationProcess (txDetails: BudPayResponse) {
  //   API.topupWallet({
  //     reference: txDetails?.reference, 
  //     paymentGateway: 'BUDPAY', 
  //     customerId: user.userData?.customerId,
  //     amount: input.amount == 0 ? 1 : input.amount
  //   }).then((resp: any) => {
  //     if (resp) close()
  //   })
  // }

  // initialize BudPay payment
  //const handleBudPayPayment = useBudPayPayment(budPayConfig);

  return (
    <SidePopupContainer > 
      <SidePopup data-aos="slide-left">
        <div className="popup-title-wrapper">
          <h4>Top up</h4>
          <i className="fa fa-times" onClick={() => close(false)}></i>
        </div>
        <FormGroupWithIcon>
          <label>How much do you want to fund?</label>
          <div className="input-with-icon">
              <span className='amount-symbol'>&#8358;</span>
              <input 
                  name="amount"
                  type="text"
                  placeholder="Enter amount" 
                  className="amount-input"
                  value={input.amount ? utility.moneyFormat(input.amount) : ''} 
                  onChange={handleInput}
              />
          </div>
        </FormGroupWithIcon>
        <div className="divider-line"></div>
        <p className="payment-notice">You will be charged a sum of &#8358;{appSettings.DEFAULT_CHARGE}.</p>
        <Button 
          className="payment-btn loan-action-btn" 
          width="24"
          height="5"
          bgColor="#6DE7B4"
          hoverBgColor="#FED0B7"
          topMargin="4"
          disabled={!input.amount ? true : false}
          onClick={initializeFunding}
          //onClick={() => initializeFunding(onSuccess, onClose)} // Paystack
          //onClick={handleBudPayPayment} // Budpay widget
        >
          Add &#8358;{utility.moneyFormat(input.amount + appSettings.DEFAULT_CHARGE || 0)}
        </Button>
      </SidePopup>
    </SidePopupContainer>
  )
}
