import './style.scss'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import utility from 'src/utils/utility'

const LandingPage = () => {
  const navigate = useNavigate()

  useEffect(() => {
    navigate('/login')
  }, [])
    return (
      <div>LandingPage</div>
    )
}

export default utility.routeData('/', 'Landing Page', <LandingPage/>)
