import { useState, useContext } from 'react'
import utility from 'src/utils/utility'
import ApiContext from 'src/provider/API/call-service'
import { useNavigate } from 'react-router-dom'
import { AppSpan, AppText, CustomContainer, FlexRow, ScreenTitle } from 'src/styles'
import { BsChevronLeft, BsChevronRight } from 'react-icons/bs'


const LoanCategory = () => {
    const navigate = useNavigate()

    return (
        <div style={{marginTop: '7rem', zIndex: '2', paddingRight: '1rem'}}>
            <div className="loan-category-title">
                <BsChevronLeft 
                    className="loan-back-icon" 
                    onClick={() => navigate(-1)} 
                />
                <CustomContainer leftMargin="2">
                    <ScreenTitle textSize="2.2" bottomMargin="1" >
                        Payday Loans
                    </ScreenTitle>
                    <AppText color="#E2E2E2" textSize="1.3" fontWeight="400">
                        Quis blandit tempus, risus non vivamus tortor natoque. Urna pellentesque tellus.
                    </AppText>
                </CustomContainer>
            </div>

            <div className="loan-list-card-wrapper">
                {
                    Array.from(Array(6).keys()).map((item: any, index: number) => {
                        return (
                            <div className="loan-category-card" key={index} onClick={() => navigate('/explore/loan-request')}>
                                <FlexRow justifyContent="space-between" alignItems="flex-start" topMargin="1">
                                    <CustomContainer topMargin="-0.6">
                                        <AppSpan color="#E2E2E2" textSize="1.3" fontWeight="400">Amount Category</AppSpan>
                                        <AppText textSize="1.8">
                                            ₦800,001 - ₦1,000,000
                                        </AppText>
                                    </CustomContainer>
                                    <BsChevronRight
                                        
                                    />
                                </FlexRow>
                                <CustomContainer topMargin="2">
                                    <AppSpan color="#E2E2E2" textSize="1.3" fontWeight="400">Requests available</AppSpan>
                                    <AppText textSize="1.8">
                                        100
                                    </AppText>
                                </CustomContainer>
                            </div>
                        )
                    })
                }
            </div>
        </div>
    )
}

export default utility.routeData('loan-category', 'Loan Category', <LoanCategory/>)