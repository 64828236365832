import { useState, useContext, useEffect } from 'react'
import LoanContext from 'src/provider/state-manager/loanProvider'
import { images } from 'src/provider/config/constant'
import { SidePopupContainer, SidePopup, Divider, AppSpan, AppText, FlexRow, RoundImageContainer, Icon, Circle, CustomContainer, OutlineButton, AbsoluteContainer  } from "src/styles"
import { interestedBorrowers } from 'src/utils/mockData'
import helpers from 'src/utils/helpers'
import { BiGitCompare } from 'react-icons/bi'
import { RiCloseFill } from 'react-icons/ri'
import { CompareBorrowers } from 'src/popup'


export const BorrowersToCompare: React.FC<any> = ({close}) => {
    const {loan: {offerBorrowers}} = useContext(LoanContext)
    const [borrowerList, setBorrowerList] = useState<any>(null)
    const [selectedBorrowers, setSelectedBorrowers] = useState<any>([])
    const [showComparison, setShowComparison] = useState(false)

    useEffect(() => {
        setBorrowerList(offerBorrowers)
    }, [offerBorrowers])

    function handleSelectedBorrower(data: any, dataIndex: number) {
        if (selectedBorrowers.length < 2) {
            setSelectedBorrowers([...selectedBorrowers, data])

            const allBorrowers = borrowerList.filter((item: any, index: number) => dataIndex != index)
            setBorrowerList(allBorrowers)
        }
    }

    async function handleRemoveSelectedBorrower (dataIndex: number) {
        let allSelectedBorrowers = []
        for (let i = 0; i < selectedBorrowers.length; i++) { 
            if (i === dataIndex) {
                setBorrowerList([...borrowerList, selectedBorrowers[i]])
            }
            else allSelectedBorrowers.push(selectedBorrowers[i])
        }
        setSelectedBorrowers(allSelectedBorrowers)
    }

    return (
        <>
            <SidePopupContainer >
                <SidePopup width="80" data-aos="slide-left" className='compare-borrowers-popup'>
                    <i className="fa fa-times comparison-cancel-icon" onClick={close}></i>
                    <div className="popup-title-wrapper compare-borrowers-title-wrapper">
                        <CustomContainer width='30' className='compare-borrowers-title'>
                            <AppText textSize='2'>Compare Borrowers</AppText>
                            <AppSpan textSize='1.2' fontWeight='400'>Select and compare borrowers who are interested in your loan offer.</AppSpan>
                        </CustomContainer>
                    
                        <OutlineButton 
                            color="#6DE7B4" 
                            width="auto" 
                            hoverBgColor="#FED0B7" 
                            titleSize='1.3'
                            leftPadding='1'
                            rightPadding='1'
                            sizeUnit=''
                            className="loan-action-outline-btn start-borrowers-comparison"
                            disabled={(!selectedBorrowers || selectedBorrowers?.length < 2) ? true : false}
                            onClick={() => setShowComparison(true)}
                        >
                            <BiGitCompare style={{fontSize: '2rem', marginRight: '1rem'}} />
                            <a className="compare-link-text">Start Comparison</a>
                        </OutlineButton>
                    </div>
                    <FlexRow className='borrowers-wrapper selected-borrowers-wrapper' >
                        {
                            Array.from(Array(2).keys()).map((item: any, index: number) => {
                                let borrower = selectedBorrowers[index];
                                let dateTime = borrower&& helpers.splitDateTime(selectedBorrowers[index]?.createdAt) 
                                return (
                                    selectedBorrowers[index] ?
                                    <div className='selected-borrower' key={index}>
                                        <FlexRow justifyContent='space-between' alignItems='flex-start'>
                                            <AppText textSize='1.7' rightMargin='1'>{borrower.firstName} {borrower.lastName}</AppText>
                                            <RoundImageContainer bgColor='#fff' size='5' rightMargin='1'>
                                                <Icon src={images.USER2X} width='5' height='5' radius='50' />
                                            </RoundImageContainer>
                                        </FlexRow>
                                        <FlexRow justifyContent="flex-start">
                                            <AppSpan textSize="1.4" fontWeight='400' rightMargin='0.5'>Request submitted</AppSpan>
                                            <Circle 
                                                bgColor="#FED0B7"
                                                size="1" borderColor='#572104'
                                            />
                                        </FlexRow>
                                        <AppText color='#AAAAAA' textSize='1.2'>{dateTime?.slashedDate} {dateTime?.completeTime}</AppText>
                                        
                                        <AbsoluteContainer top='-1' right='-0.8'>
                                            <Circle bgColor='#FF0062' onClick={() => handleRemoveSelectedBorrower(index)}>
                                                <RiCloseFill style={{color: '#fff'}} />
                                            </Circle>
                                        </AbsoluteContainer>
                                    </div>
                                    :
                                    <div className="loan-offer-info borrower-selected-none" key={index}>
                                        <AppText fontWeight='400' textSize='1.3' color="rgba(255,255,255,0.5)">Borrower {index+1}</AppText>
                                    </div>
                                )
                            })
                        }
                    </FlexRow>
                    <Divider margin='0.5'/>
                    <FlexRow className='borrowers-wrapper' >
                        {
                            borrowerList?.map((item: any, index: number) => {
                                let dateTime = helpers.splitDateTime(item?.dateSubmitted)
                                return (
                                    <div className='interested-borrowers interested-borrowers-plus' onClick={() => handleSelectedBorrower(item?.loanRequest, index)}   key={index}>
                                        <FlexRow justifyContent='space-between' alignItems='flex-start'>
                                            <AppText textSize='1.4' fontWeight='600' rightMargin='1'>{item.firstName} {item.lastName}</AppText>
                                            <RoundImageContainer bgColor='#fff' size='4' rightMargin='1'>
                                                <Icon src={images.USER2X} width='4' height='4' radius='50' />
                                            </RoundImageContainer>
                                        </FlexRow>
                                        <FlexRow justifyContent="flex-start">
                                            <AppSpan textSize="1.15" fontWeight='400' rightMargin='0.5'>Request submitted</AppSpan>
                                            <Circle 
                                                bgColor="#FED0B7"
                                                size="0.9" borderColor='#572104'
                                            />
                                        </FlexRow>
                                        <AppText color='#AAAAAA' textSize='1' fontWeight='400' topPadding='-2'>{dateTime.slashedDate} {dateTime.completeTime}</AppText>
                                    </div>
                                )
                            })
                        }
                    </FlexRow>
                </SidePopup>
            </SidePopupContainer>
            { showComparison &&
                <CompareBorrowers 
                    close={() => setShowComparison(false)}
                    data={selectedBorrowers}
                />
            }
        </>
    )
}
