import { useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import ApiContext from 'src/provider/API/call-service'
import { 
    ResponderContentWrapper, 
    ResponderIconCircle, 
    ResponderIconHolder,
    ResponderTitle, 
} from 'src/component/Responder/style'
import { CenterPopupContainer, CenterPopup, Icon, OutlineButton, AppText, AbsoluteContainer, AppSpan, FlexRow, CustomContainer, Button } from 'src/styles'
import { MdClose } from 'react-icons/md'


export const NoAuthPopup: React.FC<any> = ({
    close, responseIcon, text, route, message, btnText, 
    hasManualClose, nextMethod, questionText, yesMethod, 
    noMethod, yesBtnText, noBtnText
}) => {
    const navigate = useNavigate()
    const {API} = useContext(ApiContext)

    async function handleNext () {
        close()
        if (route) navigate(route)
        if (nextMethod) nextMethod()
    }

    function handleDecision (type: string) {
        if (type === 'yes' && yesMethod) yesMethod()
        else if (type === 'no' && noMethod) noMethod()
    }

    return (
        <CenterPopupContainer zIndex="7">
            <CenterPopup data-aos="slide-down">
                { hasManualClose &&
                    <AbsoluteContainer top='2' right='3'>
                        <AppSpan textSize='2' color='#FED0B7' onClick={close}>
                            <MdClose />
                        </AppSpan>
                    </AbsoluteContainer>
                }
                <ResponderContentWrapper>
                    <ResponderIconCircle>
                        <Icon src={responseIcon} />
                    </ResponderIconCircle>
                    <ResponderIconHolder></ResponderIconHolder>
                    <ResponderTitle>{text}</ResponderTitle>
                    { message &&
                        <AppText bottomMargin="2">{message}</AppText>
                    }
                    <ResponderIconHolder></ResponderIconHolder>
                    { !questionText &&
                        <OutlineButton
                            width="12"
                            height="5"
                            topMargin="5.5"
                            bottomMargin="1.5"
                            hoverBgColor="#FED0B7"
                            color="#6DE7B4"
                            onClick={handleNext}
                        >
                            {btnText || 'Continue'}
                        </OutlineButton>
                    }
                    { questionText &&
                        <CustomContainer topMargin='3' bottomMargin='1.5'>
                            <AppText align='center' textSize='1.8'>{questionText}</AppText>
                            <FlexRow topMargin='2' wrap='wrap'>
                                <Button 
                                    width="8" 
                                    height="4" 
                                    hoverBgColor="#FFC49D"
                                    rightMargin='1.5'
                                    onClick={() => handleDecision('yes')}
                                >
                                    {yesBtnText || 'Yes'}
                                </Button>
                                <OutlineButton
                                    width="8"
                                    height="4"
                                    hoverBgColor="#FED0B7"
                                    color="#6DE7B4"
                                    onClick={() => handleDecision('no')}
                                >
                                    {noBtnText || 'No'}
                                </OutlineButton>
                            </FlexRow>
                        </CustomContainer>
                    }
                </ResponderContentWrapper>
            </CenterPopup >
        </CenterPopupContainer>
    )
}
