import helpers from 'src/utils/helpers'
import { useState, useEffect, useContext } from 'react'
import { BiCheck } from 'react-icons/bi'
import { GoChevronDown } from 'react-icons/go'
import ApiContext from 'src/provider/API/call-service'
import { SidePopupContainer, SidePopup, FormGroup, Form, Button, FlexEndWrapper, FormGroupWithIcon, FlexRow, Circle, AppSpan } from "src/styles"

export const Statement: React.FC<any> = ({close}) => {
    const {API} = useContext(ApiContext)
    const [isValidated, setIsValidated] = useState(false)
    const [input, setInput] = useState({statementType: '', startDate: '', endDate: ''})
    const [format, setFormat] = useState({isPdf: false, isExcel: false})

    useEffect(() => {
        const auth = helpers.inputValidator(input)
        setIsValidated(auth.isValidated)
    }, [input])
    
    const handleInput = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => setInput({...input, [e.target.name]: e.target.value})

    async function handleGenerateStatement (e: React.FormEvent<HTMLButtonElement | HTMLFormElement>) {
        e.preventDefault()
        const payload = {
            startDate: input.startDate,
            endDate: input.endDate,
            format: format.isExcel ? 'EXCEL' : 'PDF',
            statementType: input.statementType
        }
        const response = await API.generateStatement(payload)
        if (response) close()
    }

    return (
        <SidePopupContainer >
            <SidePopup data-aos="slide-left">
                <div className="popup-title-wrapper">
                    <h4>Generate Statement</h4>
                    <i className="fa fa-times" onClick={close}></i>
                </div>
                <Form onSubmit={handleGenerateStatement}>
                    <FormGroupWithIcon>
                        <label>Statement type</label>
                        <div className="input-with-icon">
                            <select 
                                value={input.statementType} 
                                name="statementType" 
                                onChange={handleInput}
                                style={{color: input.statementType ? '#fff' : '#999'}}
                            >
                                <option value="">Select statement type</option>
                                <option value="ACCOUNT_STATEMENT">Account Statement</option>
                                <option value="LOAN_STATEMENT">Loan Statement</option>
                            </select>
                            <GoChevronDown className="input-with-icon-tag" />
                        </div>
                    </FormGroupWithIcon>
                    <FormGroup>
                        <label>Start Date</label>
                        <input 
                            type="date"
                            name="startDate" 
                            value={input.startDate} 
                            style={{color: input.startDate ? '#fff' : '#999'}}
                            onChange={handleInput} 
                        />
                    </FormGroup>
                    <FormGroup>
                        <label>End Date</label>
                        <input 
                            type="date" 
                            name="endDate" 
                            value={input.endDate} 
                            style={{color: input.endDate ? '#fff' : '#999'}}
                            onChange={handleInput} 
                        />
                    </FormGroup>
                    <FlexRow justifyContent='flex-start' wrap='wrap'>
                        <FlexRow width='10' sizeUnit='rem' bottomMargin='1.5'>
                            <Circle 
                                borderColor='#6DE7B4' size='3' 
                                bgColor={format.isPdf ? '#6DE7B4' : 'transparent'}
                                onClick={() => setFormat({...format, isPdf: true, isExcel: false})}
                            >
                                { format.isPdf && <BiCheck/> }
                            </Circle>
                            <AppSpan size='1.4' leftMargin='1'>PDF</AppSpan>
                        </FlexRow>
                        <FlexRow width='10' sizeUnit='rem' leftMargin='2' bottomMargin='1.5'>
                            <Circle 
                                borderColor='#6DE7B4' size='3' 
                                bgColor={format.isExcel ? '#6DE7B4' : 'transparent'}
                                onClick={() => setFormat({...format, isExcel: true, isPdf: false})}
                            >
                                { format.isExcel && <BiCheck/> }
                            </Circle>
                            <AppSpan size='1.4' leftMargin='1'>Excel</AppSpan>
                        </FlexRow>
                    </FlexRow>

                    <FlexEndWrapper topMargin="5">
                        <Button
                            width="23"
                            hoverBgColor="#FED0B7" 
                            value="submit"
                            className="loan-action-btn"
                            disabled={!isValidated || (!format.isExcel && !format.isPdf) ? true : false}
                        >
                            Continue
                        </Button>
                    </FlexEndWrapper>
                </Form>
            </SidePopup>
        </SidePopupContainer>
    )
}
