import helpers from 'src/utils/helpers'
import { useState } from 'react'
import { LoanAgreement, SignOffer } from 'src/popup'
import { loanConfig } from 'src/provider/config/constant'
import { SidePopupContainer, SidePopup, Divider, Button, FlexRow  } from "src/styles"
import utility from 'src/utils/utility'

export const OfferSummary: React.FC<any> = ({data, close, closeNotif}) => {
  const [showLoanAgreement, setShowLoanAgreement] = useState(false)
  const [showSignature, setShowSignature] = useState(false)

  let startDateTime = data?.startDate ? helpers.splitDateTime(data?.startDate) : null
  let endDateTime = data?.endDate ? helpers.splitDateTime(data?.endDate) : null

  return (
    <>
      <SidePopupContainer >
        <SidePopup data-aos="slide-left">
          <div className="popup-title-wrapper">
              <h4>Loan Details</h4>
              <i className="fa fa-times" onClick={close}></i>
          </div>

          <div className="side-bar-summary">
            <div className="bar-summary-data">
              <p className="bar-summary-data-text">Loan type</p>
              <p className="bar-summary-data-text">{data?.loanTypeName}</p>
            </div>
            <div className="bar-summary-data">
              <p className="bar-summary-data-text">Loan amount</p>
              <p className="bar-summary-data-text">&#8358;{utility.moneyFormat(data?.amountPerPerson ||data?.amount)}</p>
            </div>
            <div className="bar-summary-data">
              <p className="bar-summary-data-text">Interest rate</p>
              <p className="bar-summary-data-text">{data?.interest}%</p>
            </div>
            {/* <div className="bar-summary-data">
              <p className="bar-summary-data-text">Interest type</p>
              <p className="bar-summary-data-text">Monthly</p>
            </div> */}
            <div className="bar-summary-data">
              <p className="bar-summary-data-text">Loan tenor</p>
              <p className="bar-summary-data-text">{data?.tenor} {data?.tenorType.toLowerCase()}(s)</p>
            </div>
            <div className="bar-summary-data">
              <p className="bar-summary-data-text">{data?.repaymentTermName} payment</p>
              <p className="bar-summary-data-text">&#8358;{utility.moneyFormat(data?.repaymentPerTenorBorrower || data?.amountPerTenorType)}</p>
            </div>
            <div className="bar-summary-data">
              <p className="bar-summary-data-text">Total interest amount</p>
              <p className="bar-summary-data-text">&#8358;{utility.moneyFormat(data?.interestAmount)}</p>
            </div>
            <div className="bar-summary-data">
              <p className="bar-summary-data-text">Total repayment amount</p>
              <p className="bar-summary-data-text">&#8358;{utility.moneyFormat(data?.totalRepaymentBorrower || data?.totalAmountToBeRepaid)}</p>
            </div>
            <Divider margin="1" />
            <div className="bar-summary-data">
              <p className="bar-summary-data-text">Repayment type</p>
              <p className="bar-summary-data-text">{data?.rateId == 1 ? 'Installment' : 'One-off'}</p>
            </div>
            <div className="bar-summary-data">
              <p className="bar-summary-data-text">Repayment occurrence</p>
              <p className="bar-summary-data-text">{data?.repaymentTermName}</p>
            </div>
            {/* <div className="bar-summary-data">
              <p className="bar-summary-data-text">Repayment start date</p>
              <p className="bar-summary-data-text">21 Mar 2021</p>
            </div>
            <div className="bar-summary-data">
              <p className="bar-summary-data-text">Repayment end date</p>
              <p className="bar-summary-data-text">21 Jun 2021</p>
            </div> */}
            <Divider margin="1" />
            <div className="bar-summary-data">
              <p className="bar-summary-data-text">Negotiation</p>
              <p className="bar-summary-data-text" style={{color: '#6DE7B4'}}>{data?.isNegotiable ? 'YES' : 'NO'}</p>
            </div>
            <div className="bar-summary-data">
              <p className="bar-summary-data-text">Loan extension</p>
              <p className="bar-summary-data-text" style={{color: '#6DE7B4'}}>{data?.isExtensionAllowed ? 'YES' : 'NO'}</p>
            </div>
            <div className="bar-summary-data">
              <p className="bar-summary-data-text">Loan extension time</p>
              <p className="bar-summary-data-text">{data?.canBeExtendedBy} days</p>
            </div>
            { (data?.startDate || data?.endDate) &&
              <>
                <Divider margin="1" />
                <div className="bar-summary-data">
                  <p className="bar-summary-data-text">Start Date</p>
                  <p className="bar-summary-data-text">{startDateTime?.date} {startDateTime?.completeTime}</p>
                </div>
                <div className="bar-summary-data">
                  <p className="bar-summary-data-text">End Date</p>
                  <p className="bar-summary-data-text">{endDateTime?.date} {endDateTime?.completeTime}</p>
                </div>
              </>
            }
            <Divider margin="1" />
            <div className="bar-summary-data">
              <p className="bar-summary-data-text">Status</p>
              <Button
                width="15" height="3.2"
                bgColor='#525252' radius="0.4"
                titleSize="1.2" color='#FED0B7'
              >
                {data.rejectedLender ? 'Request Rejected' : data?.agreedLender ? 'Request Accepted' : 'Pending Acceptance'}
              </Button>
            </div>
          </div>
          { data?.agreedLender &&
            <>
              <FlexRow>
                <Button
                  width="100"
                  sizeUnit="%"
                  topMargin="4"
                  hoverBgColor="#FED0B7"
                  onClick={() => setShowLoanAgreement(true)}
                >
                  Action Lender's Offer
                </Button>
              </FlexRow>
            </>
          }
        </SidePopup>
      </SidePopupContainer>
      { showLoanAgreement &&
          <LoanAgreement
              close={setShowLoanAgreement}
              closeNotif={() => closeNotif()}
              signOffer={() => setShowSignature(true)}
              data={data}
          />
      }
      { showSignature &&
          <SignOffer
              close={() => setShowSignature(false)}
              closeNotif={() => closeNotif()}
              data={data}
          />
      }
    </>
  )
}
