import { useEffect, useState, useContext } from 'react';
import UserContext from 'src/provider/state-manager/userProvider';
import { Chart as ChartJS, registerables } from 'chart.js';
import { Bar } from 'react-chartjs-2'
import helpers from 'src/utils/helpers';
ChartJS.register(...registerables);

export const PortfolioChart = () => {
    const {user: {chartsInfo}} = useContext(UserContext)
    const [loansCollected, setLoansCollected] = useState<any>(null)
    const [loansDisbursed, setLoansDisbursed] = useState<any>(null)

    useEffect(() => {
        if (chartsInfo?.loanOfferDetails) {
            setLoansCollected(helpers.extractChartData(chartsInfo?.loanOfferDetails, 'totalLoanCollected'))
            setLoansDisbursed(helpers.extractChartData(chartsInfo?.loanOfferDetails, 'totalLoanDisbursed'))
        }
    }, [chartsInfo])

    return (
        <Bar 
            data={{
                labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'],
                datasets: [
                    {
                        label: 'Total Loans Disbursed',
                        data: loansDisbursed || [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
                        //[23, 35, 50, 38, 35, 37, 70, 62, 45, 52, 24, 33]
                        backgroundColor: '#FED0B7',
                        barThickness: 15,
                        borderColor: "rgba(0,0,0,0)",
                        borderWidth: 3,
                        borderRadius: 5,
                        
                    },
                    {
                        label: 'Total Loans Collected',
                        data: loansCollected || [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
                        //[30, 30, 50, 58, 42, 55, 34, 65, 55, 28, 64, 39]
                        backgroundColor: '#6DE7B4',
                        barThickness: 15,
                        borderColor: "rgba(0,0,0,0)",
                        borderWidth: 3,
                        borderRadius: 5
                    }
                ]
            }}
            height="90%"
            width="100%"
            options={{
                maintainAspectRatio: false, 
                plugins: {
                    legend: {
                        align: 'end',
                        labels: {
                            boxWidth: 10,
                            boxHeight: 10,
                            color: '#fff'
                        }
                    }
                },
                scales: {
                    y: {  
                        display: false,
                        ticks: {
                            color: "#fff",
                        }
                    },
                    x: {  
                        ticks: {
                            color: "#fff", 
                        }
                    }
                }
            }}
        />
    )
}
