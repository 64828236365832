import { useState, useContext, useEffect } from 'react'
import utility, { required } from 'src/utils/utility'
import ApiContext from 'src/provider/API/call-service'
import AppInfoContext from 'src/provider/state-manager/appInfoProvider'
import VisibilityContext from 'src/provider/state-manager/visibilityProvider'
import { useNavigate } from 'react-router-dom'
import { AppText, Button, Divider, FormGroup, FormGroupWithIcon, ScreenContainer, ScreenTitle, SelectButton } from 'src/styles'
import { GoChevronDown } from 'react-icons/go'
import ReactTooltip from 'react-tooltip'
import { BsChevronLeft } from 'react-icons/bs'

const MaritalStatus_EducationBgUpdate = () => {
    const navigate = useNavigate()
    const {API} = useContext(ApiContext)
    const {notifier} = useContext(VisibilityContext)
    const {setInfoProperty, info} = useContext(AppInfoContext)
    const [isDependent, setIsDependent] = useState<null | boolean>(false)
    const [dependents, setDependent] = useState('')
    const [input, setInput] = useState({maritalStatus: '', educationalQualification: ''})
    const [showEducationBg, setShowEducationBg] = useState(false)

    useEffect(() => {
        ReactTooltip.rebuild();
    }, [isDependent])

    async function handleNext () {
        const response = await API.Marital_EducationalStatusUpdate({...input, dependents})
        if (response) {
            await API.getUserDetails()
            notifier.show('Marital status and educational qualification successfully updated', 'success')
            navigate('/dashboard/home')
        }
    }

    function handleIsDependent (val: boolean) {
        console.log({val, isDependent})
        setIsDependent(val)
        if(!val) setDependent('None')
        else setDependent('')
    }
    
    function handleDependent (e:React.ChangeEvent<HTMLSelectElement>) {
        setDependent(e.target.value)
    }

    return (
        <ScreenContainer>
            { !showEducationBg &&
                <div className="onboarding-container">
                    <ScreenTitle textSize="3" bottomMargin="1.5" topMargin="3" >
                        Marital Status
                    </ScreenTitle>
                    <AppText color="#D5D5D5" textSize="1.4" fontWeight="400" bottomMargin="2">
                        Please provide a valid designation.
                    </AppText>
                    <FormGroup  topMargin="5">
                        <AppText textSize="1.7" bottomMargin="2">What is your marital status? {required}</AppText>
                        <div className="loan-tenor-types">
                            <SelectButton width="11" height="4" onClick={() => setInput({...input, maritalStatus: 'Single'})}>
                                <input type="radio" name="marital-status" readOnly checked={input.maritalStatus === 'Single'} />
                                <span></span>
                                <label>Single</label>
                            </SelectButton>
                            <SelectButton width="11" height="4" onClick={() => setInput({...input, maritalStatus: 'Married'})}>
                                <input type="radio" name="marital-status" readOnly checked={input.maritalStatus === 'Married'} />
                                <span></span>
                                <label>Married</label>
                            </SelectButton>
                            <SelectButton width="11" height="4" onClick={() => setInput({...input, maritalStatus: 'Divorced'})}>
                                <input type="radio" name="marital-status" readOnly checked={input.maritalStatus === 'Divorced'} />
                                <span></span>
                                <label>Divorced</label>
                            </SelectButton>
                            <SelectButton width="11" height="4" onClick={() => setInput({...input, maritalStatus: 'Seperated'})}>
                                <input type="radio" name="marital-status" readOnly checked={input.maritalStatus === 'Seperated'} />
                                <span></span>
                                <label>Seperated</label>
                            </SelectButton>
                            <SelectButton width="11" height="4" onClick={() => setInput({...input, maritalStatus: 'Widowed'})}>
                                <input type="radio" name="marital-status" readOnly checked={input.maritalStatus === 'Widowed'} />
                                <span></span>
                                <label>Widowed</label>
                            </SelectButton>
                        </div>
                    </FormGroup>

                    <FormGroup  topMargin="5">
                        <AppText textSize="1.7" bottomMargin="2">Do you have dependents? {required}</AppText>
                        <div className="loan-tenor-types">
                            <SelectButton width="16" height="4" onClick={() => handleIsDependent(true)}>
                                <input type="radio" name="dependent" readOnly checked={isDependent === true} />
                                <span></span>
                                <label>Yes, I do</label>
                            </SelectButton>
                            <SelectButton width="16" height="4" onClick={() => handleIsDependent(false)}>
                                <input type="radio" name="dependent" readOnly checked={dependents === 'None'} />
                                <span></span>
                                <label>No, I don't</label>
                            </SelectButton>
                        </div>
                    </FormGroup>
                    <Divider bgColor='#081952'></Divider>
                    { isDependent &&
                        <FormGroupWithIcon topMargin="1">
                            <label>How many dependents do you have? {required}</label>
                            <div className="input-with-icon">
                                <select 
                                    name="dependent" 
                                    value={dependents} 
                                    style={{color: dependents ? '#fff' : '#999'}}
                                    onChange={handleDependent}
                                >
                                    <option value="">Select number of dependents</option>
                                    <option value="1 - 2">1 - 2</option>
                                    <option value="3 - 4">3 - 4</option>
                                    <option value="5 and above">5 and above</option>
                                </select>
                                <GoChevronDown className="input-with-icon-tag" />
                            </div>
                        </FormGroupWithIcon>
                    }
                    <Button 
                        width="15" 
                        height="4.5" 
                        hoverBgColor="#FFC49D" 
                        topMargin="12"
                        bottomMargin="3"
                        className="loan-action-btn"
                        disabled={(!input.maritalStatus || !dependents) ? true : false}
                        onClick={() => setShowEducationBg(true)}
                    >
                        Next
                    </Button>
                </div>
            }
            { showEducationBg &&
                <div className="onboarding-container">
                    <BsChevronLeft className="loan-back-icon" onClick={() => setShowEducationBg(false)} />
                    <ScreenTitle textSize="3" bottomMargin="1.5" topMargin="3" >
                        Educational Background
                    </ScreenTitle>
                    <AppText color="#D5D5D5" textSize="1.4" fontWeight="400" bottomMargin="2">
                        Please provide a valid educational qualification.
                    </AppText>
                    <FormGroupWithIcon topMargin="5" >
                        <AppText textSize="1.7" bottomMargin="1.5">What is your highest educational qualification? {required}</AppText>
                        <div className="input-with-icon">
                            <select 
                                name="qualification" 
                                value={input.educationalQualification} 
                                onChange={(e) => setInput({...input, educationalQualification: e.target.value})} 
                                style={{color: input ? '#fff' : '#999'}}
                            >
                                <option value="">Your Qualification</option>
                                <option value="None">None</option>
                                <option value="Vocational Training">Vocational Training</option>
                                <option value="Primary Education">Primary Education</option>
                                <option value="Secondary Education">Secondary Education</option>
                                <option value="Bachelors Degree/HND">Bachelors Degree/HND</option>
                                <option value="Bachelors Degree + Professional Qualification">Bachelors Degree + Professional Qualification</option>
                                <option value="Masters Degree/PHD">Masters Degree/PHD</option>
                            </select>
                            <GoChevronDown className="input-with-icon-tag" />
                        </div>
                    </FormGroupWithIcon>
                    <Button 
                        width="15" 
                        height="4.5" 
                        hoverBgColor="#FFC49D" 
                        topMargin="12"
                        bottomMargin="3"
                        className="loan-action-btn"
                        disabled={!input.educationalQualification ? true : false}
                        onClick={handleNext}
                    >
                        Submit
                    </Button>
                </div>
            }
        </ScreenContainer>
    )
}

export default utility.routeData('settings/marital-status_education-bg', 'Marital Status And Education Background Update', <MaritalStatus_EducationBgUpdate/>)