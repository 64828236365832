import { useState, useContext, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import ApiContext from 'src/provider/API/call-service'
import UserContext from 'src/provider/state-manager/userProvider'
import VisibilityContext from 'src/provider/state-manager/visibilityProvider'
import { images, appSettings } from 'src/provider/config/constant'
import { 
    CustomContainer,
    Button,
    CenterPopupContainer,
    ScreenTitle,
    SignatureContainer,
    Icon
} from "src/styles"

export const SignOffer: React.FC<any> = ({data, close, closeNotif, isLender, actionMethod}) => {
    const navigate = useNavigate()
    const {API} = useContext(ApiContext)
    const {user: {formattedUserData}} = useContext(UserContext)
    const {notifier} = useContext(VisibilityContext)

    useEffect(() => {
        if (!formattedUserData?.bioDetail?.signature) {
            notifier.show('Kindly upload your signature for you to proceed', 'warning')
            navigate('/dashboard/settings/account')
        }
    }, [])

    async function handleAcceptance () {
        if (!isLender){
            await API.acceptLoanOffer(data?.loanOfferId, data?.loanRequestId) // TODO: remove getCurrenLoans method called inside acceptLoanOffer function
            API.runBackgroundService(['current-loan-update', 'charts-info-update'])
            if (closeNotif) closeNotif()
            close()
        }
        else actionMethod()
        
    }

    return (
        <CenterPopupContainer zIndex="8">
            <SignatureContainer>
                <CustomContainer bottomMargin="3.5">
                    <ScreenTitle align="center" textSize="2">
                        Sign Offer Letter
                    </ScreenTitle>
                    <i className="fa fa-times abs-right-center" onClick={() => close(false)}></i>
                </CustomContainer>
                <div className="signature-box">
                    <div className="signature-shadow-y"></div>
                    <div className="signature-shadow-x">
                        <Icon src={formattedUserData?.bioDetail?.signature} className="signature-image" />
                    </div>
                </div>
                <Button 
                    width="20" 
                    height="4.5"
                    topMargin="10"
                    hoverBgColor="#FED0B7"
                    onClick={handleAcceptance}
                >
                    Continue
                </Button>
            </SignatureContainer>
        </CenterPopupContainer>
    )
}
