import utility from 'src/utils/utility'
import { useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import ApiContext from 'src/provider/API/call-service'
import { SidePopupContainer, SidePopup, Divider, Button  } from "src/styles"
import helpers from 'src/utils/helpers'

export const LoanDetailsView: React.FC<any> = ({close, data}) => {
    const navigate = useNavigate()
    const {API} = useContext(ApiContext)

    async function handleNewLoanRequest () {
        const response = await API.createLoanRequestReplica(data?.loanRequestId)
        if (response) navigate('/dashboard/loan-board/loan-offers')
        close()
    }

    // async function handleRetakeLoan () {
    //     await API.retakeLoan()
    //     close()
    // }

    let startDateTime = data?.loanOffer?.startDate ? 
        helpers.splitDateTime(data?.loanOffer?.startDate)
        : data?.startDate ? helpers.splitDateTime(data?.startDate)
        : null

    let endDateTime = data?.loanOffer?.endDate ? 
    helpers.splitDateTime(data?.loanOffer?.endDate)
    : data?.endDate ? helpers.splitDateTime(data?.endDate)
    : null

    return (
        <SidePopupContainer >
            <SidePopup data-aos="slide-left">
                <div className="popup-title-wrapper">
                <h4>Loan Details</h4>
                    <i className="fa fa-times" onClick={() => close(false)}></i>
                </div>

                <div className="side-bar-summary">
                    <div className="bar-summary-data">
                        <p className="bar-summary-data-text">Loan type</p>
                        <p className="bar-summary-data-text">{data?.loanOffer?.loanTypeName || data?.loanTypeName}</p>
                    </div>
                    <div className="bar-summary-data">
                        <p className="bar-summary-data-text">Loan amount</p>
                        <p className="bar-summary-data-text">&#8358;{utility.moneyFormat(data?.loanOffer?.amountPerPerson || data?.amount)}</p>
                    </div>
                    <div className="bar-summary-data">
                        <p className="bar-summary-data-text">Interest rate</p>
                        <p className="bar-summary-data-text">{(data?.loanOffer?.interest || data?.loanOffer?.interest === 0) ? data?.loanOffer?.interest : data?.interest}%</p>
                    </div>
                    <div className="bar-summary-data">
                        <p className="bar-summary-data-text">Interest type</p>
                        <p className="bar-summary-data-text">{data?.loanOffer?.repaymentTermName || data?.repaymentTermName}</p>
                    </div>
                    <div className="bar-summary-data">
                        <p className="bar-summary-data-text">Interest amount</p>
                        <p className="bar-summary-data-text">&#8358;{utility.moneyFormat((data?.loanOffer?.interestAmount || data?.loanOffer?.interestAmount === 0) ? data?.loanOffer?.interestAmount : data?.interestAmount)}</p>
                    </div>
                    <div className="bar-summary-data">
                        <p className="bar-summary-data-text">Loan tenor</p>
                        <p className="bar-summary-data-text">{(data?.loanOffer?.tenor || data?.loanOffer?.tenor === 0) ? data?.loanOffer?.tenor : data?.tenor} {data?.loanOffer?.tenorType.toLowerCase() || data?.tenorType.toLowerCase()}(s)</p>
                    </div>
                    <div className="bar-summary-data">
                        <p className="bar-summary-data-text">{data?.loanOffer?.repaymentTermName || data?.repaymentTermName} Repayment</p>
                        <p className="bar-summary-data-text">&#8358;{utility.moneyFormat((data?.loanOffer?.repaymentPerTenorBorrower || data?.loanOffer?.repaymentPerTenorBorrower === 0) ? data?.loanOffer?.repaymentPerTenorBorrower : data?.amountPerTenorType)}</p>
                    </div>
                    <div className="bar-summary-data">
                        <p className="bar-summary-data-text">Total repayment amount</p>
                        <p className="bar-summary-data-text">&#8358;{utility.moneyFormat((data?.loanOffer?.totalRepaymentBorrower || data?.loanOffer?.totalRepaymentBorrower === 0) ? data?.loanOffer?.totalRepaymentBorrower : data?.totalAmountToBeRepaid)}</p>
                    </div>
                    <Divider margin="1" />
                    <div className="bar-summary-data">
                        <p className="bar-summary-data-text">Repayment type</p>
                        <p className="bar-summary-data-text">{(data?.loanOffer?.rateName || data?.rateName) === 'REDUCING_BALANCE' ? 'Installment' : 'One-off'}</p>
                    </div>
                    <div className="bar-summary-data">
                        <p className="bar-summary-data-text">Repayment occurrence</p>
                        <p className="bar-summary-data-text">{data?.loanOffer?.repaymentTermName || data?.repaymentTermName}</p>
                    </div>
                    {/* <div className="bar-summary-data">
                        <p className="bar-summary-data-text">Repayment start date</p>
                        <p className="bar-summary-data-text">21 Mar 2021</p>
                    </div>
                    <div className="bar-summary-data">
                        <p className="bar-summary-data-text">Repayment end date</p>
                        <p className="bar-summary-data-text">21 Jun 2021</p>
                    </div> */}
                    <Divider margin="1" />
                    { data?.loanOffer?.loanOfferId &&
                        <>
                            <div className="bar-summary-data">
                                <p className="bar-summary-data-text">Negotiation</p>
                                <p className="bar-summary-data-text" style={{color: '#6DE7B4'}}>{data?.loanOffer?.isNegotiable ? 'YES': 'NO'}</p>
                            </div>
                            <div className="bar-summary-data">
                                <p className="bar-summary-data-text">Loan extension</p>
                                <p className="bar-summary-data-text" style={{color: '#6DE7B4'}}>{data?.loanOffer?.isExtensionAllowed ? 'YES' : 'NO'}</p>
                            </div>
                            <div className="bar-summary-data">
                                <p className="bar-summary-data-text">Loan extension time</p>
                                <p className="bar-summary-data-text">{data?.loanOffer?.canBeExtendedBy} days</p>
                            </div>
                        </>
                    }

                    { (data?.loanOffer?.startDate || data?.startDate) &&
                        <div className="bar-summary-data">
                            <p className="bar-summary-data-text">Start Date</p>
                            <p className="bar-summary-data-text">
                                {startDateTime?.date} {startDateTime?.completeTime}
                            </p>
                        </div>
                    }
                    { (data?.loanOffer?.endDate || data?.endDate) &&
                        <div className="bar-summary-data">
                            <p className="bar-summary-data-text">End Date</p>
                            <p className="bar-summary-data-text">
                                {endDateTime?.date} {endDateTime?.completeTime}
                            </p>
                        </div>
                    }
                </div>
                { data?.completed &&
                     <Button
                        width="100"
                        sizeUnit="%"
                        topMargin="10"
                        hoverBgColor="#FED0B7"
                        onClick={handleNewLoanRequest}
                    >
                        Retake Loan
                    </Button>
                }
               
            </SidePopup>
        </SidePopupContainer>
    )
}
