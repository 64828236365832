import '../style.scss'
import { useState, useEffect, useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import ApiContext from 'src/provider/API/call-service'
import LoanContext from 'src/provider/state-manager/loanProvider'
import utility, { getLoanStatusColor } from 'src/utils/utility'
import { 
    AppLabel,
    AppLink,
    AppSpan, AppText, 
    Button, 
    Circle, 
    CustomContainer, 
    FlexColumn, FlexRow,  
    Icon,
    OutlineButton,
    RoundImageContainer,
    ScreenContainer, 
} from 'src/styles'
import { BsChevronLeft } from 'react-icons/bs'
import { BiGitCompare } from 'react-icons/bi'
import { images } from 'src/provider/config/constant'
import { LoanRequestDetails, BorrowersToCompare, PortfolioSummary } from 'src/popup'
import { interestedBorrowers } from 'src/utils/mockData'
import helpers from 'src/utils/helpers'


const AllBorrowers = () => {
    const navigate = useNavigate()
    const {API} = useContext(ApiContext)
    const {loan: {selectedOfferRequest}} = useContext(LoanContext)
    const [offerDetails, setOfferDetails] = useState<any>({})
    const [borrowers, setBorrowers] = useState<any>(null)
    const [borrowerRequest, setBorrowerRequest] = useState<object>({})
    const [showCompareBorrowers, setShowCompareBorrowers] = useState(false)
    const [showRequestDetails, setShowRequestDetails] = useState(false)
    const [showPortfolioSummary,setShowPortfolioSummary] = useState(false)
    
    useEffect(() => {
        if(selectedOfferRequest?.loanOfferId) handleFetchBorrowers()
        setOfferDetails(selectedOfferRequest)
    }, [selectedOfferRequest])

    useEffect(() => {
        if(showCompareBorrowers) {
          document.body.style.overflow = 'hidden'
        }
        else document.body.style.overflow = 'auto'
    }, [showCompareBorrowers])

    async function handleFetchBorrowers () {
        const response = await API.getOfferProspects(selectedOfferRequest?.loanOfferId)
        if (response) {
            setBorrowers(response)
        }
    }

    function handleBorrower (request: object) {
        setBorrowerRequest(request)
        setShowRequestDetails(true)
    }


    return (
        <>
            <ScreenContainer> 
                <div className="loan-type-title borrower-title-wrapper">
                    <div className="loan-type-title-flex">
                        <BsChevronLeft className="loan-back-icon" style={{fontSize: '2.5rem', marginTop: '0.5rem'}} onClick={() => navigate(-1)} />
                        <div className="loan-type-title-content">
                            <p >Portfolio Interests</p>
                            <span>
                                A list of borrowers interested in this loan portfolio
                            </span>
                        </div>
                    </div>
                </div>
                <div className="compare-btn-wrapper compare-borrowers">
                    <AppLink 
                        textSize='1.4' fontWeight='400' 
                        rightMargin='2' hoverColor="#FED0B7"
                        onClick={() => setShowPortfolioSummary(true)}
                    >
                        View Portfolio Details
                    </AppLink>
                    <OutlineButton 
                        color="#6DE7B4" 
                        width="auto" 
                        hoverBgColor="#FED0B7" 
                        titleSize='1.3'
                        leftPadding='1'
                        rightPadding='1'
                        sizeUnit=''
                        className="loan-action-outline-btn"
                        disabled={(!borrowers || borrowers?.length < 2) ? true : false}
                        onClick={() => setShowCompareBorrowers(true)}
                    >
                        <BiGitCompare style={{fontSize: '2rem', marginRight: '1rem'}} />
                        <a className="compare-link-text">Compare Borrowers</a>
                    </OutlineButton>
                </div>
                <FlexRow className='portfolio-details-horizontal'>
                    <FlexColumn className="portfolio-borrower-count">
                        <Icon src={images.FRUIT_HEADS} style={{width: '8rem'}}/>
                        <AppText fontWeight='400' textSize='1.2' width='14' align='center' topMargin='1.7'>{borrowers?.length || offerDetails?.loanOffer?.numberOfInterestedBorrowers} person(s) are interested in this Offer</AppText>
                    </FlexColumn>
                    <CustomContainer className='portfolio-info-horizontal'>
                        <FlexRow justifyContent='space-between' wrap='wrap' topPadding='1.5'>
                            <CustomContainer width='auto' sizeUnit='' rightMargin='2' >
                                <AppText textSize='1.1' color='#E2E2E2' fontWeight='400'>Portfolio Amount</AppText>
                                <AppSpan textSize='1.7'>N{utility.moneyFormat(offerDetails?.loanOffer?.amount || offerDetails?.amount)}</AppSpan>
                            </CustomContainer>
                            <FlexRow width='auto' sizeUnit='' justifyContent="flex-start" rightMargin='2' topPadding='1.5' >
                                <Circle 
                                    bgColor={offerDetails?.amountLocked >= offerDetails?.amount ? '#6DE7B4' : offerDetails?.amountLocked != 0 ? '#FED0B7' : '#FF0062'}
                                    size="0.9"
                                    rightMargin="0.8"
                                />
                                <AppSpan 
                                    textSize="1.2" 
                                    color={offerDetails?.amountLocked >= offerDetails?.amount ? '#6DE7B4' : offerDetails?.amountLocked != 0 ? '#FED0B7' : '#FF0062'} 
                                    fontWeight="600"
                                >
                                    {offerDetails?.amountLocked >= offerDetails?.amount ? 'Funded' : offerDetails?.amountLocked != 0 ? 'Partially Funded' : 'Not Funded'}
                                </AppSpan>
                            </FlexRow>
                            <FlexRow width='auto' sizeUnit='' topMargin='1.5'>
                                <AppText textSize='1.1' color='#E2E2E2' fontWeight='400'>Portfolio Status</AppText>
                                <Button 
                                    width='auto' sizeUnit='' height='2.2'
                                    leftPadding='1.5' rightPadding='1.5'
                                    borderColor='#C1CDF6' bgColor='transparent'
                                    color='#C1CDF6' titleSize='1' fontWeight='600'
                                    leftMargin='1' radius='2'
                                >
                                    Open
                                </Button>
                            </FlexRow>
                        </FlexRow>
                        <div className="portfolio-details-container">
                            <div className="loan-offer-label-text">
                                <label>Loan Type</label>
                                <p>{offerDetails?.loanTypeName}</p>
                            </div>
                            <div className="loan-offer-label-text">
                                <label>Loan Per/Person</label>
                                <p>N{utility.moneyFormat(offerDetails?.loanOffer?.amountPerPerson || offerDetails?.amountPerPerson || offerDetails?.amount)}</p>
                            </div>
                            <div className="loan-offer-label-text">
                                <label>Payment</label>
                                <p>{(offerDetails?.loanOffer?.rateId === 1 || offerDetails?.rateId === 1) ? 'Installment' : 'One-off'}</p>
                            </div>
                            <div className="loan-offer-label-text">
                                <label>Duration</label>
                                <p>{offerDetails?.loanOffer?.tenor || offerDetails?.tenor} {offerDetails?.loanOffer?.tenorType?.toLowerCase() || offerDetails?.tenorType?.toLowerCase()}(s)</p>
                            </div>
                            <div className="loan-offer-label-text">
                                <label>Interest on loan</label>
                                {/* <p>{offerDetails?.loanOffer?.interest || offerDetails?.interest}% ({offerDetails?.loanOffer?.repaymentTermName || offerDetails?.repaymentTermName})</p> */}
                                <p>{offerDetails?.loanOffer?.interest || offerDetails?.interest}% (monthly)</p>
                            </div>
                            <div className="loan-offer-label-text">
                                <label>{offerDetails?.loanOffer?.repaymentTermName || offerDetails?.repaymentTermName} Repayment / borrower</label>
                                <p>N{utility.moneyFormat(offerDetails?.loanOffer?.repaymentPerTenorBorrower || offerDetails?.repaymentPerTenorBorrower)}</p>
                            </div>
                        </div>
                    </CustomContainer>
                </FlexRow>

                <CustomContainer topMargin='3'>
                    <AppLabel textSize='1.6' color='#fff'>Interested Borrowers</AppLabel>
                    { borrowers?.length > 0 &&
                        <FlexRow className='borrowers-wrapper' >
                            {
                                borrowers?.map((item: any, index: number) => {
                                    let dateTime = helpers.splitDateTime(item?.createdAt || item?.dateSubmitted)
                                    return (
                                        <div className='interested-borrowers' onClick={() => handleBorrower(item)} key={index}>
                                            <FlexRow justifyContent='space-between' alignItems='flex-start'>
                                                <AppText textSize='1.4' fontWeight='600' rightMargin='1'>{item.firstName} {item.lastName}</AppText>
                                                {/* <RoundImageContainer bgColor='#fff' size='4' rightMargin='1'>
                                                    <Icon src={images.USER2X} width='4' radius='50' height='4' />
                                                </RoundImageContainer> */}
                                                <Circle 
                                                    size='4.2' rightMargin='1' bgColor='#ffffff'
                                                >
                                                    <Circle
                                                        size='3.9'
                                                        bgColor={getLoanStatusColor(item?.userTag)}
                                                    >
                                                        <RoundImageContainer 
                                                            size="3.3" borderColor='#ffffff'
                                                            style={{borderWidth: '1.5px'}}
                                                        >
                                                            <Icon 
                                                                src={item.profilePictureUrl || images.USER2X}
                                                                width='3.1' radius='50' height='3.1' noResize
                                                            />
                                                        </RoundImageContainer>
                                                    </Circle>
                                                </Circle>
                                            </FlexRow>
                                            <FlexRow justifyContent="flex-start">
                                                <AppSpan textSize="1.15" fontWeight='400' rightMargin='0.5'>Request submitted</AppSpan>
                                                <Circle 
                                                    bgColor="#FED0B7"
                                                    size="0.9" borderColor='#572104'
                                                />
                                            </FlexRow>
                                            <AppText color='#AAAAAA' textSize='1' fontWeight='400' topPadding='-2'>{dateTime?.slashedDate} {dateTime?.completeTime}</AppText>
                                        </div>
                                    )
                                })
                            }
                        </FlexRow>
                    }
                    { (!borrowers || borrowers.length <= 0) &&
                        <FlexColumn topMargin="5" >
                            <Icon src={images.NOTIFICATION_ICON_BG} width='6' />
                            <AppText textSize="1.8" width="25" topMargin="3" align="center">No Interested Borrowers yet</AppText>
                            <AppSpan color="#E2E2E2" textSize="1.3" fontWeight="400" topMargin="1" align="center" width="26">
                                No borrower has shown interest yet.
                            </AppSpan>
                        </FlexColumn>
                    }
                </CustomContainer>
            </ScreenContainer>
            { showRequestDetails &&
                <LoanRequestDetails 
                    close={() => setShowRequestDetails(false)}
                    data={borrowerRequest}
                />
            }
            { showCompareBorrowers &&
                <BorrowersToCompare
                    close={() => setShowCompareBorrowers(false)}
                />
            }
            { showPortfolioSummary &&
                <PortfolioSummary 
                    data={offerDetails}
                    close={() => setShowPortfolioSummary(false)}
                />
            }
        </>
    )
}

export default utility.routeData('loan/borrowers', 'Borrowers', <AllBorrowers/>)