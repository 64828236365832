import { useState } from 'react'
import { images } from 'src/provider/config/constant'
import { SidePopupContainer, SidePopup, Divider, Button, AppText, AppLabel, CustomContainer, FlexColumn, AppSpan, RoundImageContainer, Icon  } from "src/styles"
import { BiCheck } from 'react-icons/bi'
import { Agreement } from 'src/popup'

export const CompareBorrowers: React.FC<any> = ({close, data}) => {
    const [showAgreement, setShowAgreement] = useState(false)
    const [selectedBorrower, setSelectedBorrower] = useState<any>(null)

    async function handleChosenOffer (offer: any) {
        setSelectedBorrower(offer)
        setShowAgreement(true)
    }

    return (
        <>
            <SidePopupContainer >
                <SidePopup width="90" data-aos="slide-left">
                <i className="fa fa-times comparison-cancel-icon" onClick={close}></i>
                    <div className="popup-title-wrapper">
                        <CustomContainer width='30' className='compare-borrowers-title'>
                            <AppText textSize='2'>Compare Borrowers</AppText>
                            <AppSpan textSize='1.2' fontWeight='400'>Select and compare borrowers who are interested in your loan offer.</AppSpan>
                        </CustomContainer>
                        {/* <i className="fa fa-times" onClick={close}></i> */}
                    </div>
                    <div className="loan-compare-header">
                        <div className="loan-peck-container">
                            <AppLabel textSize='1.9' color='#fff' topMargin='2.5'>Borrowers</AppLabel>
                            <AppText textSize='1.55' fontWeight='400' topPadding='4.8'>Crendly Score</AppText>
                            <AppText textSize='1.55' fontWeight='400' topPadding='3.5'>Rating</AppText>
                            <AppText textSize='1.55' fontWeight='400' topPadding='2.5'>Default</AppText>
                        </div>
                        <div className="loan-compare-info" >
                            <div className="loan-offer-header">
                                <div className="loan-offer-label">
                                    <RoundImageContainer bgColor='#fff' size='4' rightMargin='1.5'>
                                        <Icon src={images.USER2X} width='4' height='4' radius='50' />
                                    </RoundImageContainer>
                                    <AppText textSize='1.4'>{data[0].firstName} {data[0].lastName}</AppText>
                                </div>
                            </div>
                            <Divider margin="1.5" />
                            <CustomContainer topMargin='2.5'>
                                <Button 
                                    width="100" sizeUnit='%' bgColor='#000B2F'
                                    disabled borderColor='#01175F' radius='0.4'
                                    color='#6DE7B4'titleSize='1.8'
                                    leftPadding='3'
                                >
                                    75%
                                    <BiCheck style={{marginLeft: '1rem'}} />
                                </Button>
                            </CustomContainer>
                            <CustomContainer topMargin='1.2'>
                                <Button 
                                    width="100" sizeUnit='%' bgColor='#000B2F'
                                    disabled borderColor='#01175F' radius='0.4'
                                    color='#6DE7B4'titleSize='1.8'
                                    leftPadding='3'
                                >
                                    4.7
                                    <BiCheck style={{marginLeft: '1rem'}} />
                                </Button>
                            </CustomContainer>
                            <AppText textSize='1.6' align='center' topPadding='1.7'>None</AppText>
                            <Divider margin="3" />
                            <FlexColumn>
                                <Button width="22" hoverBgColor="#FED0B7" onClick={()=> handleChosenOffer(data[0])}>
                                    Accept Request
                                </Button>
                            </FlexColumn>
                        </div>
                        <div className="loan-compare-info" >
                            <div className="loan-offer-header">
                                <div className="loan-offer-label">
                                    <RoundImageContainer bgColor='#fff' size='4' rightMargin='1.5'>
                                        <Icon src={images.USER2X} width='4' height='5' radius='50' />
                                    </RoundImageContainer>
                                    <AppText textSize='1.4'>{data[1].firstName} {data[1].lastName}</AppText>
                                </div>
                            </div>
                            <Divider margin="1.5" />
                            <AppText textSize='1.6' align='center' topPadding='2.8'>72%</AppText>
                            <AppText textSize='1.6' align='center' topPadding='3'>4.5</AppText>
                            <AppText textSize='1.6' align='center' topPadding='2.5' bottomPadding='0.7'>1 default</AppText>
                            <Divider margin="2.2" />
                            <FlexColumn>
                                <Button 
                                    width="22" topMargin="0.7" hoverBgColor="#FED0B7" 
                                    onClick={()=> handleChosenOffer(data[1])}
                                >
                                    Accept Request
                                </Button>
                            </FlexColumn>
                        </div>
                    </div>
                </SidePopup>
            </SidePopupContainer>
            { showAgreement &&
                <Agreement
                    close={() => setShowAgreement(false)}
                    closeDetails={close}
                    data={selectedBorrower}
                />
            }
        </>
    )
}
