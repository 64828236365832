import { useState, useContext, useEffect } from 'react'
import LoanContext from 'src/provider/state-manager/loanProvider'
import { images } from 'src/provider/config/constant'
import { SidePopupContainer, SidePopup, Divider, Button, AppSpan, AppText  } from "src/styles"
import utility from 'src/utils/utility'


export const LoansToCompare: React.FC<any> = ({close, showCompareLoan, setCompareWithLoan}) => {
  const {loan: {requestLoanOffers, selectedLoanOffer, currentProcessType}} = useContext(LoanContext)
  const [compareOfferList, setCompareOfferList] = useState<any>(null)
  const [compareWith, setCompareWith] = useState<any>(null)
  let isBorrower = currentProcessType === 'borrower' ? true : false

  useEffect(() => {
    let offers = []

    for (let item of requestLoanOffers) {
      if (currentProcessType === 'borrower') {
        if(item?.loanOfferId != selectedLoanOffer?.loanOfferId) offers.push(item)
      }
      else {
        if(item?.loanRequestId != selectedLoanOffer?.loanRequestId) offers.push(item)
      }
      
    }
    setCompareOfferList(offers)
  }, [])

  function handleLoanCompareSelect(data: any) {
    setCompareWithLoan(data)
    setCompareWith(data)
  }

  function handleLoanCompare () {
    showCompareLoan(true)
    close(false)
  }

  return (
    <>
      <SidePopupContainer >
        <SidePopup width="60" data-aos="slide-left">
          <div className="popup-title-wrapper">
            <h4>Select a loan to compare</h4>
            <i className="fa fa-times" onClick={() => close(false)}></i>
          </div>
          <div className="loans-to-compare">
            {
              compareOfferList?.map((item: any, index: number) => {
                return (
                  <div 
                    className="loans-to-compare-info" 
                    key={index}  
                    onClick={() => handleLoanCompareSelect(item)}
                    style={{background: (isBorrower ? compareWith?.loanOfferId : compareWith?.loanRequestId) === (isBorrower ? item?.loanOfferId : item?.loanRequestId) ? '#2E4DBD' : '#00071F'}}
                  >
                    <div className="loan-offer-header">
                        <div className="loan-offer-label">
                            <img src={images.LOAN_OFFER_ICON} className="loan-offer-header-icon" />
                            <div className="loan-offer-label-text">
                                <p>{item.loanTypeName}</p>
                            </div>
                        </div>
                        <span className="loan-offer-percentage" style={{color: '#6DE7B4'}}>{item.interest}%p.m</span>
                    </div>
                    <Divider margin="1.5" />
                    <div className="flex-row-space-between margin-vertical-1-5">
                        <div className="loan-offer-label-text">
                            <label>Amount</label>
                            <p>N{utility.moneyFormat(item.amountPerPerson || item.amount)}</p>
                        </div>
                        <div className="loan-offer-label-text width-10-rem">
                            <label>Duration</label>
                            <p>{item.tenor} {item?.tenorType?.toLowerCase()}(s)</p>
                        </div>
                    </div>
                    <div className="flex-row-space-between margin-vertical-1-5">
                        <div className="loan-offer-label-text">
                            <label>Payment</label>
                            <p>{item.rateId === 1 ? 'Installment' : 'One-off'}</p>
                        </div>
                        { currentProcessType === 'borrower' ?
                          // <div className="loan-offer-label-text width-10-rem">
                          //   <label>Lender's rep</label>
                          //   <p style={{color: '#6DE7B4'}}>{item?.lenderRep || 0}%</p>
                          // </div>
                          null
                          :
                          <div className="loan-offer-label-text width-10-rem">
                            <label>Crendly score</label>
                            <p style={{color: '#6DE7B4'}}>{item?.creditScoreInfo?.grandTotal || 0}%</p>
                          </div>
                        }
                    </div>
                  </div>
                )
              })
            }
          </div>
          { (!compareOfferList || compareOfferList?.length === 0) &&
              <div className="no-offer-container">
                  <AppText textSize='2' bottomMargin='1'>No Loan {currentProcessType === 'borrower' ? 'offers' : 'requests'} to compare with</AppText>
              </div>
          }
          { compareOfferList?.length > 0 &&
              <Button
                width="19"
                topMargin="4"
                hoverBgColor="#FED0B7"
                className="loan-action-btn"
                disabled={!compareWith}
                onClick={handleLoanCompare}
              >
                Compare Loans
              </Button>
          }
        </SidePopup>
      </SidePopupContainer>
    </>
  )
}
