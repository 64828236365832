import { useContext, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import ApiContext from 'src/provider/API/call-service'
import { 
    ResponderContentWrapper, 
    ResponderIconCircle, 
    ResponderIconHolder,
    ResponderMessage,
    ResponderTitle, 
} from 'src/component/Responder/style'
import { CenterPopupContainer, CenterPopup, Icon, AppText, FlexRow, BorderlessButton, Button } from 'src/styles'
import { images } from 'src/provider/config/constant'
import { VerifyOtp } from 'src/popup'


export const ExistNotif: React.FC<any> = ({close}) => {
    const navigate = useNavigate()
    const {API} = useContext(ApiContext)
    const [showVerifyOtp, setShowVerifyOtp] = useState(false)

    async function handleSendOtp () {
        const response = await API.sendOtp_Inactivity('Phone')
        if (response) setShowVerifyOtp(true)
    }

    return (
        <>
            { !showVerifyOtp &&
                <CenterPopupContainer zIndex="7">
                    <CenterPopup data-aos="slide-down">
                        <i className="fa fa-times responder-cancel" onClick={close}></i>
                        <ResponderContentWrapper>
                            <ResponderIconCircle>
                                <Icon src={images.HELLO} />
                            </ResponderIconCircle>
                            <ResponderIconHolder></ResponderIconHolder>
                            <ResponderTitle>Hi there</ResponderTitle>
                            <ResponderMessage>
                                We noticed you already started your sign up process but did not complete it.
                            </ResponderMessage>
                            <AppText textSize='1.4' topMargin='3'>Do you wish to continue where you left off?</AppText>
                            {/* <AppLink hoverColor="#FED0B7" textSize="1.4" topMargin="10" onClick={handleNext}>Click proceed if you have a BVN</AppLink> */}
                            <FlexRow className="popup-sm-side-btn" topMargin="5">
                                    <BorderlessButton hoverColor='#FED0B7' titleSize='1.4' onClick={close}>
                                        No, Start over
                                    </BorderlessButton>
                                    <Button 
                                        width="15" 
                                        height="3.8"
                                        hoverBgColor="#FED0B7"
                                        leftMargin="4"
                                        onClick={handleSendOtp}
                                    >
                                        Yes, Continue
                                    </Button>
                                </FlexRow>
                        </ResponderContentWrapper>
                    </CenterPopup >
                </CenterPopupContainer>
            }
            
            { showVerifyOtp &&
                <VerifyOtp 
                    close={() => setShowVerifyOtp(false)}
                />
            }
        </>
    )
}
