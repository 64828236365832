import { useNavigate } from 'react-router-dom';
import { CircularProgressbar } from 'react-circular-progressbar';
import { BiCheck } from 'react-icons/bi'
import { 
    CenterPopupContainer, FlexRow, AppSpan, CustomContainer, AppText, AbsoluteContainer 
} from 'src/styles'


export const CompleteProfile: React.FC<any> = ({data, addBank, close}) => {
    const navigate = useNavigate()

    function handleCompleteProfile (screen: any) {
        document.body.style.overflow = 'auto'
        if (screen === true) addBank()
        else if (screen) navigate(screen)
        close()
    }

    return (
        <CenterPopupContainer zIndex="7">
            <div className="complete-profile-popup-container" data-aos="slide-down">
                <AbsoluteContainer top='1' right='2'>
                    <i className="fa fa-times" style={{color: '#FED0B7', fontSize: '1.7rem'}} onClick={close}></i>
                </AbsoluteContainer>
                <FlexRow justifyContent='space-between' topMargin='1'>
                    <CustomContainer width='auto' sizeUnit=' ' rightMargin='2'>
                        <AppText textSize='2.2'>Complete your profile.</AppText>
                        <AppSpan textSize='1.2' fontWeight='400'>Complete your profile and start giving or take loans.</AppSpan>
                    </CustomContainer>
                    <div style={{width: '5.5rem', height: '5.5rem'}}>
                        <CircularProgressbar value={data?.percCompleted || 0} text={`${data?.percCompleted || 0}%`} 
                            styles={{
                                root: {},
                                path: {
                                    stroke: `#6DE7B4`,
                                    strokeLinecap: 'round',
                                    transition: 'stroke-dashoffset 0.5s ease 0s',
                                    transform: 'rotate(0.25turn)',
                                    transformOrigin: 'center center',
                                },
                                trail: {
                                    stroke: '#00071F',
                                    strokeLinecap: 'butt',
                                    transform: 'rotate(0.25turn)',
                                    transformOrigin: 'center center',
                                },
                                text: {
                                    fill: '#fff',
                                    fontSize: '2.5rem',
                                    fontWeight: 700
                                }
                            }} 
                        />
                    </div>
                </FlexRow>

                <FlexRow wrap='wrap' topMargin='3' justifyContent='flex-start'>
                    {
                        data?.uncompletedInfo?.map((item: any, index: number) => {
                            return (
                                <FlexRow 
                                    onClick={() => handleCompleteProfile(item.screen)}
                                    bgColor='#00071F' topPadding='2.5' bottomPadding='2.5'
                                    radius='0.4' leftPadding='2' rightPadding='2'
                                    justifyContent='space-between' bottomMargin='1.5'
                                    className='profile-to-complete-card' key={index}
                                >
                                    <AppText textSize='1.2'>{item.name}</AppText>
                                    <BiCheck style={{fontSize: '1.5rem', color: '#0930B6'}} className='profile-complete-icon' />
                                </FlexRow>
                            )
                        })
                    }
                </FlexRow>
            </div >
        </CenterPopupContainer>
    )
}
