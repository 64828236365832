import './style.scss'
import { useEffect, useState, useContext } from 'react'
import utility from 'src/utils/utility'
import ApiContext from 'src/provider/API/call-service'
import { useNavigate } from 'react-router-dom'
import { FiChevronRight } from 'react-icons/fi'
import { AbsoluteContainer, AppSpan, AppText, Button, CustomContainer, FlexColumn, FlexRow, Icon } from 'src/styles'
import { images } from 'src/provider/config/constant'
import { ExploreLoanCalculator } from 'src/popup'

const ExploreHome = () => {
    const navigate = (useNavigate())
    const {API} = useContext(ApiContext)
    const [showLoanCalc, setShowLoanCalc] = useState(false)

    useEffect(() => {
        if (showLoanCalc) {
            document.body.style.overflow = 'hidden'
        }
        else document.body.style.overflow = 'auto'
    }, [showLoanCalc])

    function handleGiveLoan () {
        API.exploreResponse('Sign up to create a Loan Portfolio to help you give Loans on Crendly', 'explore-portfolio')
    }

    return (
        <>
            <div style={{marginTop: '6rem', zIndex: '2'}}>
                <div className="explore-signup-container">
                    <FlexRow justifyContent="flex-start"width="auto" sizeUnit="" rightMargin="1.5">
                        <Icon src={images.SM_LOGO_TEA} />
                        <AppText textSize="1.4" leftMargin="2">Sign up new to enjoy full Crendly features.</AppText>
                    </FlexRow>
                    <Button 
                        height="4"
                        width="9"
                        hoverBgColor="#FED0B7"
                        radius="0.5"
                        fontWeight="500"
                        onClick={() => navigate('/onboarding/type')}
                    >
                        Sign up
                        <FiChevronRight className="icon-chevron-right" />
                    </Button>
                </div>

                <FlexRow justifyContent="flex-start" wrap="wrap">
                    <div className="explore-balance">
                        <span>Account Balance</span>
                        <p>&#8358;0</p>
                    </div>
                    <FlexRow className="loan-action-kind text-cursor">
                        <div className="explore-loan-option-card-1" onClick={handleGiveLoan}>
                            <img className="loan-avatar" src={images.CREATE_LOAN_AVATAR} />
                            <p>Give Loan</p>
                            <div className="loan-option-card-text-wrapper">
                                <p>Invest in the needs of real people and earn interests monthly.</p>
                                <FiChevronRight className="icon-chevron-right" />
                            </div>
                        </div>
                        <div className="explore-loan-option-card-2" onClick={ () => navigate('/explore/get-loan/loan-type')}>
                            <img className="loan-avatar" src={images.GET_LOAN_AVATAR} />
                            <p>Collect Loan</p>
                            <div className="loan-option-card-text-wrapper">
                                <p>Get money for what you need at an incredible speed. </p>
                                <FiChevronRight className="icon-chevron-right" />
                            </div>
                        </div>
                    </FlexRow>
                </FlexRow>

                <FlexRow 
                    topMargin="4"
                    justifyContent="space-between"
                    topPadding="1.5" bottomPadding="1.5"
                    leftPadding="1.8" rightPadding="1.8"
                    bgColor="#fff" radius="0.4"
                >
                    <FlexRow justifyContent="flex-start" rightMargin="2"> 
                        <Icon src={images.C_POINTER} />
                        <AppText textSize="1.6" color="#000" fontWeight="500" leftMargin="2">Make use of our calculator.</AppText>
                    </FlexRow>
                    <Button 
                        height="4"
                        width="15"
                        titleSize="1.3"
                        hoverBgColor="#FED0B7"
                        radius="0.5"
                        fontWeight="500"
                        onClick={() => setShowLoanCalc(true)}
                    >
                        Check it out
                        <FiChevronRight className="icon-chevron-right" />
                    </Button>
                </FlexRow>

                <CustomContainer topMargin="6">
                    <AppText textSize="1.7" bottomMargin="1.5">Browse Our Loan Category</AppText>
                    <div className="explore-app-card">
                        <div className="explore-app-img-card explore-community7">
                            <AbsoluteContainer bottom="3" left="1.5">
                                <div style={{whiteSpace: 'normal'}}>
                                    <AppText textSize="1.4" bottomMargin="2.5" width="20">
                                        Looking for ways to cut your monthly spend? 
                                    </AppText>
                                </div>
                                <Button
                                    width="8"
                                    height="3.5"
                                    titleSize='1.1'
                                    color="#00071F"
                                    bgColor="#fff"
                                    hoverBgColor='#6DE7B4'
                                    hoverColor="#fff"
                                    onClick={() => navigate('/explore/loan-type-list')}
                                >
                                    View all
                                </Button>
                            </AbsoluteContainer>
                        </div>
                        <div className="explore-app-img-card explore-community8">
                            <AbsoluteContainer bottom="3" left="1.5">
                                <div style={{whiteSpace: 'normal'}}>
                                    <AppText textSize="1.4" bottomMargin="2.5" width="20">
                                        Looking for ways to cut your monthly spend? 
                                    </AppText>
                                </div>
                                <Button
                                    width="8"
                                    height="3.5"
                                    titleSize='1.1'
                                    color="#00071F"
                                    bgColor="#fff"
                                    hoverBgColor='#6DE7B4'
                                    hoverColor="#fff"
                                    onClick={() => navigate('/explore/loan-type-list')}
                                >
                                    View all
                                </Button>
                            </AbsoluteContainer>
                        </div>
                        <div className="explore-app-img-card explore-community6">
                            <AbsoluteContainer bottom="3" left="1.5">
                                <div style={{whiteSpace: 'normal'}}>
                                    <AppText textSize="1.4" bottomMargin="2.5" width="20">
                                        Looking for ways to cut your monthly spend? 
                                    </AppText>
                                </div>
                                <Button
                                    width="8"
                                    height="3.5"
                                    titleSize='1.1'
                                    color="#00071F"
                                    bgColor="#fff"
                                    hoverBgColor='#6DE7B4'
                                    hoverColor="#fff"
                                    onClick={() => navigate('/explore/loan-type-list')}
                                >
                                    View all
                                </Button>
                            </AbsoluteContainer>
                        </div>
                        <div className="explore-app-img-card explore-community9">
                            <AbsoluteContainer bottom="3" left="1.5">
                                <div style={{whiteSpace: 'normal'}}>
                                    <AppText textSize="1.4" bottomMargin="2.5" width="20">
                                        Looking for ways to cut your monthly spend? 
                                    </AppText>
                                </div>
                                <Button
                                    width="8"
                                    height="3.5"
                                    titleSize='1.1'
                                    color="#00071F"
                                    bgColor="#fff"
                                    hoverBgColor='#6DE7B4'
                                    hoverColor="#fff"
                                    onClick={() => navigate('/explore/loan-type-list')}
                                >
                                    View all
                                </Button>
                            </AbsoluteContainer>
                        </div>
                        <div className="explore-scroll-hack"></div>
                    </div>
                    <div className="app-statistics">
                        <AppText textSize="1.7" bottomMargin="1.5">What we have achieved so far</AppText>
                        <FlexRow>
                            <FlexColumn bgColor="#4700E0" radius="0.8" width="49" rightMargin="1.2" size="%" style={{maxHeight: '14rem'}} height="14">
                                <AppText textSize="2.6">32,000</AppText>
                                <AppSpan textSize="1.3" fontWeight="400">Users</AppSpan>
                            </FlexColumn>
                            <FlexColumn bgColor="#4700E0" radius="0.8" width="49" size="%" height="14" style={{maxHeight: '14rem'}}>
                                <AppText textSize="2.6">32,000</AppText>
                                <AppSpan textSize="1.3" fontWeight="400">Loans</AppSpan>
                            </FlexColumn>
                        </FlexRow>
                        <FlexRow alignItems="space-between" topMargin="1.5">
                            <FlexColumn bgColor="#4700E0" radius="0.8" width="49" size="%" rightMargin="1.2" height="14" style={{maxHeight: '14rem'}}>
                                <AppText textSize="2.6">₦78M</AppText>
                                <AppSpan textSize="1.3" fontWeight="400">In Loans</AppSpan>
                            </FlexColumn>
                            <FlexColumn bgColor="#4700E0" radius="0.8" width="49" size="%" height="14" style={{maxHeight: '14rem'}}>
                                <AppText textSize="2.6">99%</AppText>
                                <AppSpan textSize="1.3" fontWeight="400">Repayment rate</AppSpan>
                            </FlexColumn>
                        </FlexRow>
                    </div>
                </CustomContainer>

                <CustomContainer topMargin="5">
                    <AppText textSize="1.7" bottomMargin="1.5">Inside Crendly's Community</AppText>
                    <div className="explore-app-card">
                        <div className="explore-app-img-card-plus explore-community2">
                            <AbsoluteContainer top="2" left="1.5">
                                <div style={{whiteSpace: 'normal'}}>
                                    <AppText textSize="1.4" bottomMargin="2.5" width="20">
                                        Looking for ways to cut your monthly spend? 
                                    </AppText>
                                </div>
                                <Button
                                    width="14"
                                    height="4"
                                    titleSize='1.4'
                                    color="#00071F"
                                    bgColor="#fff"
                                    hoverBgColor='#6DE7B4'
                                    hoverColor="#fff"
                                >
                                    Stories
                                </Button>
                            </AbsoluteContainer>
                        </div>
                        <div className="explore-app-img-card-plus explore-community1">
                            <AbsoluteContainer top="2" left="1.5">
                                <div style={{whiteSpace: 'normal'}}>
                                    <AppText textSize="1.4" bottomMargin="2.5" width="20">
                                        Not sure how to travel for summer?
                                    </AppText>
                                </div>
                                <Button
                                    width="14"
                                    height="4"
                                    titleSize='1.4'
                                    color="#00071F"
                                    bgColor="#fff"
                                    hoverBgColor='#6DE7B4'
                                    hoverColor="#fff"
                                >
                                    Guide
                                </Button>
                            </AbsoluteContainer>
                        </div>
                        <div className="explore-app-img-card-plus explore-community3">
                            <AbsoluteContainer top="2" left="1.5">
                                <div style={{whiteSpace: 'normal'}}>
                                    <AppText textSize="1.4" bottomMargin="2.5" width="20">
                                        Proofing her business idea with Crendly 
                                    </AppText>
                                </div>
                                <Button
                                    width="14"
                                    height="4"
                                    titleSize='1.4'
                                    color="#00071F"
                                    bgColor="#fff"
                                    hoverBgColor='#6DE7B4'
                                    hoverColor="#fff"
                                >
                                    Interview
                                </Button>
                            </AbsoluteContainer>
                        </div>
                    </div>
                </CustomContainer>
            </div>
            { showLoanCalc &&
                <ExploreLoanCalculator close={() => setShowLoanCalc(false)} />
            }
        </>
    )
}

export default utility.routeData('home', 'Explore', <ExploreHome/>)