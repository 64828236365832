import { useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import ApiContext from 'src/provider/API/call-service'
import { 
    ResponderContentWrapper, 
    ResponderIconCircle, 
    ResponderIconHolder,
    ResponderMessage,
    ResponderTitle, 
} from 'src/component/Responder/style'
import { CenterPopupContainer, CenterPopup, Icon, OutlineButton, AppLink } from 'src/styles'
import { images } from 'src/provider/config/constant'


export const NoBVNPopup: React.FC<any> = ({close}) => {
    const navigate = useNavigate()
    const {API} = useContext(ApiContext)

    async function handleNext () {
        close()
        navigate('/onboarding/bvn-details')
    }

    return (
        <CenterPopupContainer zIndex="7">
            <CenterPopup data-aos="slide-down">
                <i className="fa fa-times responder-cancel" onClick={close}></i>
                <ResponderContentWrapper>
                    <ResponderIconCircle>
                        <Icon src={images.WARNING_RESPONSE} />
                    </ResponderIconCircle>
                    <ResponderIconHolder></ResponderIconHolder>
                    <ResponderMessage style={{marginTop: '2rem'}}>
                        Thank you for your interest in Crendly. we are working hard to create 
                        opportunities for everyone. however, you will require a BVN to use our platform 
                        at this time.  We will keep you notified as soon as we develop features for you.  
                    </ResponderMessage>
                    <AppLink hoverColor="#FED0B7" textSize="1.4" topMargin="10" onClick={handleNext}>Click proceed if you have a BVN</AppLink>
                </ResponderContentWrapper>
            </CenterPopup >
        </CenterPopupContainer>
    )
}
