import { useState, useContext, useEffect } from 'react'
import utility, { explain, required } from 'src/utils/utility'
import ApiContext from 'src/provider/API/call-service'
import AppInfoContext from 'src/provider/state-manager/appInfoProvider'
import { useNavigate } from 'react-router-dom'
import { Container } from 'src/component'
import helpers from 'src/utils/helpers'
import { AppText, Button, CustomContainer, Divider, FormGroup, FormGroupsWrapper, FormGroupWithIcon, ScreenTitle, SelectButton } from 'src/styles'
import { BsChevronLeft } from 'react-icons/bs'
import { GoChevronDown } from 'react-icons/go'
import ReactTooltip from 'react-tooltip'

const EmploymentStatus = () => {
    const navigate = useNavigate()
    const {API} = useContext(ApiContext)
    const {info} = useContext(AppInfoContext)
    const [employmentStatus, setEmploymentStatus] = useState(info.onBoardingData?.employmentStatus || '')
    const [isValidated, setIsValidated] = useState(false)
    const [input, setInput] = useState({employer: info.onBoardingData?.employer || '', occupation: info.onBoardingData?.occupation || '', position: info.onBoardingData?.position || '', employmentType: info.onBoardingData?.employmentType || '', salaryOrIncomeRange: info.onBoardingData?.salaryOrIncomeRange || '', politicallyExposed: info.onBoardingData?.politicallyExposed ? 'Yes' : info.onBoardingData?.politicallyExposed === false ? 'No' : ''})

    useEffect(() => {
        ReactTooltip.rebuild();
    }, [employmentStatus])

    useEffect(() => {
        const auth = employmentStatus === 'Employed' ? helpers.inputValidator(input) : employmentStatus === 'Self-Employed' ? helpers.inputValidator(input, ['position', 'employmentType', 'employer']) : employmentStatus === 'Unemployed' ? helpers.inputValidator(input, ['position', 'employmentType', 'employer', 'salaryOrIncomeRange', 'occupation']) : {isValidated: false}
        setIsValidated(auth.isValidated)
    }, [input, employmentStatus])

    function handleInput (e: React.ChangeEvent<HTMLInputElement|HTMLSelectElement>) {
        setInput({...input, [e.target.name]: e.target.value})
    }

    async function handleNext () {
        let payload = {
            ...input,
            politicallyExposed: input.politicallyExposed === 'Yes' ? true : false,
            employmentStatus
        }
        const response = await API.updateEmploymentDetails(payload)
        if (response) navigate('/onboarding/address-verification')
    }

    return (
        <>
            <Container type="onboarding" progressBar='46.14'>
                <p className="right-align-text">Already have an account? 
                    <a className="registration-link" onClick={() => navigate('/login')}> Sign in</a>
                </p>
                <div className="onboarding-container">
                    <BsChevronLeft className="loan-back-icon" onClick={() => navigate(-1)} />
                    <ScreenTitle textSize="3" bottomMargin="1.5" topMargin="3" >
                        Employment Status
                    </ScreenTitle>
                    <AppText color="#D5D5D5" textSize="1.4" fontWeight="400" bottomMargin="2">
                        Please provide your employment information.
                    </AppText>
                    <FormGroup  topMargin="5">
                        <AppText textSize="1.7" bottomMargin="2">What is your employment status? {required}</AppText>
                        <div className="loan-tenor-types">
                            <SelectButton width="16" height="4" onClick={() => setEmploymentStatus('Employed')}>
                                <input type="radio" name="employment-status" readOnly checked={employmentStatus === 'Employed'} />
                                <span></span>
                                <label>Employed</label>
                            </SelectButton>
                            <SelectButton width="16" height="4" onClick={() => setEmploymentStatus('Self-Employed')}>
                                <input type="radio" name="employment-status" readOnly checked={employmentStatus === 'Self-Employed'} />
                                <span></span>
                                <label>Self-Employed</label>
                            </SelectButton>
                            <SelectButton width="16" height="4" onClick={() => setEmploymentStatus('Unemployed')}>
                                <input type="radio" name="employment-status" readOnly checked={employmentStatus === 'Unemployed'}  />
                                <span></span>
                                <label>Unemployed</label>
                            </SelectButton>
                        </div>
                    </FormGroup>
                    { employmentStatus &&
                        <Divider margin="2" bgColor="#243673" />
                    }
                    { employmentStatus === 'Employed' &&
                        <CustomContainer>
                            <FormGroupsWrapper className="full-column-flex">
                                <FormGroup  width="48.5" rightMargin="3" bottomMargin="1.5" topMargin="1" className="full-width-flex">
                                    <label>Employer’s name? {required}</label>
                                    <input 
                                        type="text" 
                                        name="employer" 
                                        placeholder="Company name" 
                                        value={input.employer}
                                        onChange={handleInput}
                                    />
                                </FormGroup>
                                <FormGroup bottomMargin="1.5" width="48.5" topMargin="1" className="full-width-flex">
                                    <label>What is your Occupation? {required}</label>
                                    <input 
                                        type="text" 
                                        name="occupation" 
                                        placeholder="Your occupation"
                                        value={input.occupation}
                                        onChange={handleInput}
                                    />
                                </FormGroup>
                            </FormGroupsWrapper>
                            <FormGroupsWrapper className="full-column-flex">
                                {/* <FormGroup  width="48.5" rightMargin="3" bottomMargin="1.5" topMargin="1" className="full-width-flex">
                                    <label>Position at the company</label>
                                    <input 
                                        type="text" 
                                        name="position" 
                                        placeholder="Your position" 
                                        value={input.position}
                                        onChange={handleInput}
                                    />
                                </FormGroup> */}
                                <FormGroupWithIcon bottomMargin="1.5" width="48.5" rightMargin="3" topMargin="1" className="full-width-flex">
                                    <label>Position at the company {required}</label>
                                    <div className="input-with-icon">
                                        <select 
                                            name="position" 
                                            value={input.position}
                                            onChange={handleInput}
                                            style={{color: input.position ? '#fff' : '#999'}}
                                        >
                                            <option value="">Your Position</option>
                                            <option value="Intern">Intern</option>
                                            <option value="NYSC">NYSC</option>
                                            <option value="Entry Level">Entry Level</option>
                                            <option value="Junior Management">Junior Management</option>
                                            <option value="Middle Management">Middle Management</option>
                                            <option value="Senior Management">Senior Management</option>
                                            <option value="Executive Director">Executive Director</option>
                                            <option value="MD/CEO">MD/CEO</option>
                                        </select>
                                        <GoChevronDown className="input-with-icon-tag" />
                                    </div>
                                </FormGroupWithIcon>
                                <FormGroupWithIcon bottomMargin="1.5" width="48.5" topMargin="1" className="full-width-flex">
                                    <label>Type of employment {required}</label>
                                    <div className="input-with-icon">
                                        <select 
                                            name="employmentType" 
                                            value={input.employmentType} 
                                            onChange={handleInput}
                                            style={{color: input.employmentType ? '#fff' : '#999'}}
                                        >
                                            <option value="">Employment type</option>
                                            <option value="Full-Time">Full-Time</option>
                                            <option value="Part-Time">Part-Time</option>
                                            <option value="Contract">Contract</option>
                                        </select>
                                        <GoChevronDown className="input-with-icon-tag" />
                                    </div>
                                </FormGroupWithIcon>
                            </FormGroupsWrapper>
                            <FormGroupWithIcon  bottomMargin="1.5" topMargin="1">
                                <label>What is your monthly salary range? {required}</label>
                                <div className="input-with-icon">
                                    <select 
                                        name="salaryOrIncomeRange" 
                                        value={input.salaryOrIncomeRange} 
                                        onChange={handleInput}
                                        style={{color: input.salaryOrIncomeRange ? '#fff' : '#999'}}
                                    >
                                        <option value="">Salary range</option>
                                        <option value="Below ₦50,000">Below ₦50,000</option>
                                        <option value="₦50,001 - ₦100,000">₦50,001 - ₦100,000</option>
                                        <option value="₦100,001 - ₦200,000">₦100,001 - ₦200,000</option>
                                        <option value="₦200,001 - ₦300,000">₦200,001 - ₦300,000</option>
                                        <option value="₦300,001 - ₦450,000">₦300,001 - ₦450,000</option>
                                        <option value="₦450,001 - ₦600,000">₦450,001 - ₦600,000</option>
                                        <option value="₦600,001 - ₦750,000">₦600,001 - ₦750,000</option>
                                        <option value="₦750,001 - ₦900,000">₦750,001 - ₦900,000</option>
                                        <option value="₦900,001 - ₦1,000,000">₦900,001 - ₦1,000,000</option>
                                        <option value="Above ₦1,000,000">Above ₦1,000,000</option>
                                    </select>
                                    <GoChevronDown className="input-with-icon-tag" />
                                </div>
                            </FormGroupWithIcon>
                        </CustomContainer>
                    }
                    { employmentStatus === 'Self-Employed' && 
                        <CustomContainer>
                            <FormGroup  bottomMargin="1.5" topMargin="1">
                                <label>What is your occupation? {required}</label>
                                <input 
                                    type="text" 
                                    name="occupation" 
                                    placeholder="Your occupation" 
                                    value={input.occupation}
                                    onChange={handleInput}
                                />
                            </FormGroup>
                            <FormGroupWithIcon bottomMargin="1.5" topMargin="1">
                                <label>What is your monthly salary range? {required}</label>
                                <div className="input-with-icon">
                                    <select 
                                        name="salaryOrIncomeRange" 
                                        value={input.salaryOrIncomeRange} 
                                        onChange={handleInput}
                                        style={{color: input.salaryOrIncomeRange ? '#fff' : '#999'}}
                                    >
                                        <option>Salary range</option>
                                        <option value="Below ₦50,000">Below &#8358;50,000</option>
                                        <option value="₦50,000 - ₦200,000">&#8358;50,000 - &#8358;200,000</option>
                                        <option value="₦200,001 - ₦500,000">&#8358;200,001 - &#8358;500,000</option>
                                        <option value="₦500,001 - ₦1,000,000">&#8358;500,001 - &#8358;1,000,000</option>
                                        <option value="₦1,000,001 and above">&#8358;1,000,001 and above</option>
                                    </select>
                                    <GoChevronDown className="input-with-icon-tag" />
                                </div>
                            </FormGroupWithIcon>
                        </CustomContainer>
                    }
                    { employmentStatus &&
                        <FormGroup  topMargin="2">
                            <label>
                                Are you a politically exposed person? {required} {
                                    explain(`You are a Politically Exposed Person (PEP) if you have been or have previously been entrusted with a political role or prominent public function in government or political party offices
                                        You are a family member of a PEP, either directly or indirectly through marriage or other similar (civil) forms of partnership. 
                                        You are a close associate who is socially or professionally connected to a PEP. `
                                    )
                                }
                            </label>
                            <div className="loan-tenor-types">
                                <SelectButton width="16" height="4" onClick={() => setInput({...input, politicallyExposed: 'Yes'})}>
                                    <input type="radio" name="politics" readOnly checked={input.politicallyExposed === 'Yes'}/>
                                    <span></span>
                                    <label>Yes, I am</label>
                                </SelectButton>
                                <SelectButton width="16" height="4" onClick={() => setInput({...input, politicallyExposed: 'No'})}>
                                    <input type="radio" name="politics" readOnly checked={input.politicallyExposed === 'No'} />
                                    <span></span>
                                    <label>No, I am not</label>
                                </SelectButton>
                            </div>
                        </FormGroup>
                    }
                    <Button 
                        width="15" 
                        height="4.5" 
                        hoverBgColor="#FFC49D" 
                        topMargin="5"
                        bottomMargin="3"
                        className="loan-action-btn"
                        disabled={!isValidated ? true : false}
                        onClick={handleNext}
                    >
                        Next
                    </Button>
                </div>
            </Container>
        </>
    )
}

export default utility.routeData('employment-status', 'Employment Status', <EmploymentStatus/>)