import utility from 'src/utils/utility'
import { useState, useContext, useEffect } from 'react'
import ApiContext from 'src/provider/API/call-service'
import { 
    SidePopupContainer, 
    SidePopup, 
    Form, 
    Button, 
    FlexRow, 
    Divider,
    AppText,
    OutlineButton,
    Circle,
    AppSpan,
    CustomContainer,
    AppLabel,
    FormGroupWithIcon,
} from "src/styles"
import { TransPin } from 'src/component'
import helpers from 'src/utils/helpers'
import Okra from "okra-js"
import { credentials } from 'src/provider/config/constant'
import { WalletTopup } from 'src/popup'


export const FundPortfolio: React.FC<any> = ({close, data, portfolioId, portfolioAmount, onCompleteMethod}) => {
    const {API} = useContext(ApiContext)
    const [isValidated, setIsValidated] = useState(false)
    const [input, setInput] = useState({amount: portfolioAmount || '', portfolio: 0})
    const [transPin, setTransPin] = useState('')
    const [allPortfolios, setAllPortfolios] = useState<any>(null)
    const [view, setView] = useState('')

    useEffect(() => {
        const auth = helpers.inputValidator(input)
        setIsValidated(auth.isValidated)
    }, [input])

    useEffect(() => {
        //console.log({portfolioId})
        //console.log({data})
        if (!data && portfolioId) {
            fetchUserOffers() 
        }
        else setAllPortfolios(data)
    }, [])

    async function fetchUserOffers() {
        let response = await API.fetchAllUserOffers()
        response = response.filter((item: any) => item.amountLocked < item.amount)
        if (portfolioId) {
            let selectedPortfolio = response.find((item: any) => item.loanOfferId === portfolioId)
            response.unshift(selectedPortfolio)
            response = [...new Set(response)]
        }
        setAllPortfolios(response)
        setInput({...input, portfolio: portfolioId || 0})
    }

    const handleInput = (e: React.ChangeEvent<HTMLInputElement>) => {
        let val = e.target.value.replace(/,/g, '')
        if (!isNaN(Number(val))) {
            setInput({...input, amount: val})
        }
    }

    async function handleSubmit (e: React.FormEvent<HTMLButtonElement|HTMLFormElement>) {
        e.preventDefault()
        const pinVerification = await API.verifyTransactionPin(transPin)
        if (pinVerification) {
            const response = await API.fundPortfolio(input.portfolio, input.amount)
            if (response === 'fund-wallet') setView('wallet-topup')
            else {
                if (onCompleteMethod) onCompleteMethod()
                close()
            }
        } 
    }

    return (
        <>
            { !view &&
                <SidePopupContainer >
                    <SidePopup data-aos="slide-left">
                        <div className="popup-title-wrapper">
                            <h4>Fund portfolio</h4>
                            <i className="fa fa-times" onClick={() => close(false)}></i>
                        </div>
                        <Form onSubmit={handleSubmit}>
                            <AppLabel color='#fff' fontWeight='500'>Select Portfolio</AppLabel>
                            <div className='portfolios-header' style={{marginTop: '0'}}>
                                { 
                                    allPortfolios?.map((item: any, index: number) => {
                                        let loanStatus = item.completed ? 'Ended' : (!item.completed && item?.taken) ? 'Running' : 'Open'
                                        let statusColor = item.completed ? '#6DE7B4' : (!item.completed && item?.taken) ? '#F5B070' : '#8095E0'
                                        let isDefaulted = false
                                        let fundingColor = item.amountLocked >= item.amount ? '#6DE7B4' : item.amountLocked != 0 ? '#FED0B7' : '#FF0062'
                                        let fundingText = item.amountLocked >= item.amount ? 'Funded' : item.amountLocked != 0 ? 'Partially Funded' : 'Not Funded'
                                        return (
                                            !item.completed &&
                                                <div 
                                                    className={input.portfolio == item.loanOfferId ? 'fund-portfolio-card-plus' : 'fund-portfolio-cards'} 
                                                    key={index}
                                                    onClick={() => setInput({...input, portfolio: item.loanOfferId})}
                                                >
                                                    <div className="info-card-wrapper">
                                                        <FlexRow justifyContent="space-between">
                                                            <CustomContainer>
                                                                <AppSpan color="#E2E2E2" textSize="1.1" fontWeight="400">Portfolio Amount</AppSpan>
                                                                <AppText textSize="1.8">
                                                                    ₦{utility.moneyFormat(item.amount)}
                                                                    <AppSpan textSize="0.9" fontWeight="400" topPadding="-1" color="#6DE7B4" leftPadding="0.5">{item.interest}%</AppSpan>
                                                                </AppText>
                                                            </CustomContainer>
                                                            <OutlineButton 
                                                                hoverBgColor="transparent"  
                                                                width="7.2" height="2.1" 
                                                                titleSize="0.9" 
                                                                fontWeight="400" 
                                                                color={statusColor} 
                                                                hoverColor={statusColor} 
                                                                radius="2"
                                                            >
                                                                {loanStatus}
                                                            </OutlineButton>
                                                        </FlexRow>
                                                        <FlexRow justifyContent='space-between' topMargin="-0.3">
                                                            <FlexRow 
                                                                width='auto' sizeUnit='' justifyContent="flex-start" 
                                                                style={{visibility: isDefaulted ? 'visible' : 'hidden'}}
                                                            >
                                                                <Circle 
                                                                    bgColor="#FF0062"
                                                                    size="0.8"
                                                                    rightMargin="0.8"
                                                                />
                                                                <AppSpan textSize="0.9" color="#FF0062" fontWeight="600">
                                                                    Loan Defaulted
                                                                </AppSpan>
                                                            </FlexRow>
                                                            <FlexRow width='auto' sizeUnit='' justifyContent="flex-start" >
                                                                <Circle 
                                                                    bgColor={fundingColor}
                                                                    size="0.8"
                                                                    rightMargin="0.8"
                                                                />
                                                                <AppSpan textSize="0.9" color={fundingColor} fontWeight="600">
                                                                    {fundingText}
                                                                </AppSpan>
                                                            </FlexRow>
                                                        </FlexRow>
                                                    
                                                        <FlexRow justifyContent="flex-start" topMargin="4" wrap="wrap">
                                                            <CustomContainer width="auto" sizeUnit="" rightMargin="2">
                                                                <AppSpan fontWeight="400" textSize="0.9" color="#E2E2E2">Loan Type</AppSpan>
                                                                <AppText textSize="1.4">{item.loanTypeName?.split(' ')[0]}</AppText>
                                                            </CustomContainer>
                                                            <CustomContainer width="auto" sizeUnit="" rightMargin="2">
                                                                <AppSpan fontWeight="400" textSize="0.9" color="#E2E2E2">Loan Duration</AppSpan>
                                                                <AppText textSize="1.4">{item.tenor} {item.tenorType.toLowerCase()}(s)</AppText>
                                                            </CustomContainer>
                                                            <CustomContainer width="auto" sizeUnit="" rightMargin="2">
                                                                <AppSpan fontWeight="400" textSize="0.9" color="#E2E2E2">Repayment Method</AppSpan>
                                                                <AppText textSize="1.4">{item.rateName === 'REDUCING_BALANCE' ? 'Installment' : 'One-off'}</AppText>
                                                            </CustomContainer>
                                                        </FlexRow>
                                                    </div>
                                                </div>
                                        )
                                    })
                                }
                            </div>
                            <FormGroupWithIcon topMargin='3'>
                                <label>Portlio Amount</label>
                                <div className="input-with-icon">
                                    <span className='amount-symbol'>&#8358;</span>
                                    <input 
                                        name="amount"
                                        type="text"
                                        placeholder="Enter amount" 
                                        className="amount-input"
                                        autoComplete='off'
                                        value={input.amount ? utility.moneyFormat(input.amount) : ''} 
                                        onChange={handleInput}
                                        disabled={portfolioAmount}
                                    />
                                </div>
                            </FormGroupWithIcon>
                            <Divider bgColor="#2E4DBD" margin="3.5" />
                            <FlexRow justifyContent="space-between" topMargin="3" className="withdraw-passcode-wrapper">
                                <AppText fontWeight="700">Transaction Pin</AppText>
                                <TransPin onChange={(val: string) => setTransPin(val)} />
                            </FlexRow>
                            <FlexRow justifyContent="flex-end" topMargin="6">
                                <Button
                                    width="22"
                                    hoverBgColor="#FED0B7" 
                                    className="loan-action-btn"
                                    disabled={!isValidated || transPin.length != 4 ? true : false}
                                    value="submit"
                                >
                                    Fund Portfolio
                                </Button>
                            </FlexRow>
                        </Form>
                    </SidePopup>
                </SidePopupContainer>
            }
            { view === 'wallet-topup' &&
                <WalletTopup close={() => setView('')} />
            }
        </>
    )
}
