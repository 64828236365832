import { useState, useContext, useEffect } from 'react'
import utility, {required} from 'src/utils/utility'
import ApiContext from 'src/provider/API/call-service'
import AppInfoContext from 'src/provider/state-manager/appInfoProvider'
import VisibilityContext from 'src/provider/state-manager/visibilityProvider'
import { useNavigate } from 'react-router-dom'
import { AppText, Button, CustomContainer, Divider, FormGroup, FormGroupsWrapper, FormGroupWithIcon, ScreenTitle, SelectButton, ReactSelect, ScreenContainer } from 'src/styles'
import helpers from 'src/utils/helpers'
import { GoChevronDown } from 'react-icons/go'
import states from 'src/utils/states'
import ReactTooltip from 'react-tooltip'

const AddressVerificationUpdate = () => {
    const navigate = useNavigate()
    const {API} = useContext(ApiContext)
    const {notifier} = useContext(VisibilityContext)
    const {info} = useContext(AppInfoContext)
    const [isValidated, setIsValidated] = useState(false)
    const [residenceCategories, setResidenceCategories] = useState<any>([])
    const [lgaList, setLgaList] = useState<any>([])
    const [input, setInput] = useState<any>({
        houseNumber: '', apartmentNumber: '', street: '', 
        area: '', lga: '', state: '', residenceHolding: ''
    })
    const [rentedFor, setRentedFor] = useState('')
    const [otherArea, setOtherArea] = useState('')

    useEffect(() => {
        ReactTooltip.rebuild();
    }, [input.residenceHolding])
    
    useEffect(() => {
        fetchResidenceCategories()

        if (info.onBoardingData?.stateId) {
            const stateData = states[info.onBoardingData?.stateId]
            setLgaList(stateData?.lgas)
        }
    }, [])

    useEffect(() => {
        const auth = helpers.inputValidator(input, ['apartmentNumber'])
        //const isOthers = input.area === 'others' ? otherArea != '' : true
        setIsValidated(auth.isValidated)
    }, [input, otherArea])

    async function fetchResidenceCategories () {
        const response = await API.getResidenceCategories()
        if (response) setResidenceCategories(response)
    }

    function handleInput (e: React.ChangeEvent<HTMLInputElement|HTMLSelectElement>) {
        if (e.target.name === 'state') {
            if (e.target.value) {
                const stateData = states[Number(e.target.value)]
                setInput({...input, state: e.target.value, lga: ''})
                setLgaList(stateData?.lgas)
            }
        }
        else setInput({...input, [e.target.name]: e.target.value})
    }

    async function handleNext () {
        console.log({input})
        const response = await API.updateAddress({...input, state: states[input.state].state, rentedFor}, true)
        if (response) {
            await API.getUserDetails()
            notifier.show('Address information successfully updated', 'success')
            navigate('/dashboard/home')
        }
    }

    return (
        <ScreenContainer>
            <div className="onboarding-container">
                <ScreenTitle textSize="3" bottomMargin="1.5" topMargin="3" >
                    Let’s verify your address.
                </ScreenTitle>
                <AppText color="#D5D5D5" textSize="1.4" fontWeight="400" bottomMargin="2">
                    This would help us with more verification.
                </AppText>
                <CustomContainer topMargin="5">
                    <CustomContainer>
                        <AppText textSize="1.4" fontWeight="500">Residence {required}</AppText>
                        <FormGroupsWrapper className="full-column-flex">
                            <FormGroup  width="48.5" rightMargin="3" bottomMargin="1.5" topMargin="1" className="full-width-flex">
                                <input 
                                    type="text" 
                                    name="houseNumber" 
                                    placeholder="House number" 
                                    value={input.houseNumber}
                                    onChange={handleInput}
                                />
                            </FormGroup>
                            <FormGroup bottomMargin="1.5" width="48.5" topMargin="1" className="full-width-flex">
                                <input 
                                    type="text" 
                                    name="apartmentNumber" 
                                    placeholder="Apartment number"
                                    value={input.apartmentNumber}
                                    onChange={handleInput}
                                />
                            </FormGroup>
                        </FormGroupsWrapper>
                    </CustomContainer>
                    <CustomContainer>
                        <AppText textSize="1.4" fontWeight="500">Area or Road Name {required}</AppText>
                        <FormGroup bottomMargin="1.5" topMargin="1">
                            <input 
                                type="text" 
                                name="street" 
                                placeholder="Street name" 
                                value={input.street}
                                onChange={handleInput}
                            />
                        </FormGroup>
                        <FormGroupWithIcon bottomMargin="1.5" topMargin="1">
                            <div className="input-with-icon">
                                <select 
                                    name="area" 
                                    value={input.area} 
                                    style={{color: input.area ? '#fff' : '#999'}}
                                    onChange={handleInput}
                                >
                                    <option value="">Nearest bus stop/landmark</option>
                                    {
                                        residenceCategories?.map((item: any, index: number) => {
                                            return (
                                                <option value={item.area} key={index}>{item.area}</option>
                                            )
                                        })
                                    }
                                </select>
                                <GoChevronDown className="input-with-icon-tag" />
                            </div>
                        </FormGroupWithIcon>
                        {/* { input.area === 'others' &&
                            <FormGroup >
                                <input 
                                    type="text" 
                                    placeholder="Enter Area" 
                                    name="otherArea" 
                                    value={otherArea}
                                    onChange={(e) => setOtherArea(e.target.value)}
                                />
                            </FormGroup>
                        } */}
                        {/* <ReactSelect 
                            bottomMargin="1.5" topMargin="1"
                            styles={utility.reactSelectStyles()} 
                            options={
                                residenceCategories?.map((item: any, index: number) => {
                                    return {
                                       label: item.area,
                                       value: item.area,
                                       key: index
                                    }
                               })
                            } 
                            isClearable={true} 
                            isSearchable={true} 
                            name="area" 
                            placeholder="Nearest bus stop/landmark" 
                            menuPortalTarget={document.body} 
                            value={input.area}
                            onChange={(val) => handleSelect(val, 'area')}
                        /> */}
                    </CustomContainer>
                    <FormGroupsWrapper className="full-column-flex">
                        <FormGroupWithIcon width="48.5" rightMargin="3" bottomMargin="1.5" topMargin="1" className="full-width-flex">
                            <label>State {required}</label>
                            <div className="input-with-icon">
                                <select name="state" value={input.state} onChange={handleInput}>
                                    <option value="">---</option>
                                    {
                                        states.map((item: any, index: number) => {
                                            return (
                                                <option value={index} key={index}>{item.state}</option>
                                            )
                                        })
                                    }
                                </select>
                                <GoChevronDown className="input-with-icon-tag" />
                            </div>
                            {/* <ReactSelect 
                                styles={utility.reactSelectStyles()} 
                                options={states.map((item: any, index: number) => {
                                    return {
                                        label: item.state,
                                        value: index,
                                        key: index
                                    }
                                })} 
                                isClearable={true} 
                                isSearchable={true} 
                                placeholder="state" 
                                value={input.state}
                                menuPortalTarget={document.body} 
                                onChange={(val) => handleSelect(val, 'state')}
                            /> */}
                        </FormGroupWithIcon>
                        <FormGroupWithIcon width="48.5" bottomMargin="1.5" topMargin="1" className="full-width-flex">
                            <label>L.G.A {required}</label>
                            <div className="input-with-icon">
                                <select name="lga" value={input.lga} onChange={handleInput}>
                                    <option value="">---</option>
                                    {
                                        lgaList?.map((item: any, index: number) => {
                                            return (
                                                <option value={item} key={index}>{item}</option>
                                            )
                                        })
                                    }
                                </select>
                                <GoChevronDown className="input-with-icon-tag" />
                            </div>
                        </FormGroupWithIcon>
                    </FormGroupsWrapper>
                    <FormGroupWithIcon bottomMargin="1.5" topMargin="1">
                        <label>Type of residence holding at your address. {required}</label>
                        <div className="input-with-icon">
                            <select name="residenceHolding" value={input.residenceHolding} onChange={handleInput}>
                                <option value="">---</option>
                                <option value="Owned">Owned</option>
                                <option value="Rented">Rented</option>
                                <option value="Shared Rental">Shared Rental</option>
                                <option value="Living with family or friend">Living with family or friend</option>
                            </select>
                            <GoChevronDown className="input-with-icon-tag" />
                        </div>
                    </FormGroupWithIcon>
                    { input.residenceHolding === 'Rented' &&
                        <>
                            <Divider bgColor='#081952'></Divider>
                            <FormGroup bottomMargin="1.5">
                                <AppText textSize="1.5" bottomMargin="2">How long have you lived in your current residence? {required}</AppText>
                                <div className="loan-tenor-types">
                                    <SelectButton width="16" height="4" onClick={() => setRentedFor('Less than 2 years')}>
                                        <input type="radio" name="rented-for" readOnly checked={rentedFor === 'Less than 2 years'} />
                                        <span></span>
                                        <label>Less than 2 years</label>
                                    </SelectButton>
                                    <SelectButton width="16" height="4" onClick={() => setRentedFor('2 - 4 years')}>
                                        <input type="radio" name="rented-for" readOnly checked={rentedFor === '2 - 4 years'} />
                                        <span></span>
                                        <label>2 - 4 years</label>
                                    </SelectButton>
                                    <SelectButton width="16" height="4" onClick={() => setRentedFor('More than 4 years')}>
                                        <input type="radio" name="rented-for" readOnly checked={rentedFor === 'More than 4 years'} />
                                        <span></span>
                                        <label>More than 4 years</label>
                                    </SelectButton>
                                </div>
                            </FormGroup>
                        </>
                    }
                </CustomContainer>
                <Button 
                    width="15" 
                    height="4.5" 
                    hoverBgColor="#FFC49D" 
                    topMargin="5"
                    bottomMargin="3"
                    className="loan-action-btn"
                    disabled={(!isValidated || (input.residenceHolding === 'Rented' && !rentedFor)) ? true : false}
                    onClick={handleNext}
                >
                    Submit
                </Button>
            </div>
        </ScreenContainer>
    )
}

export default utility.routeData('settings/address-info', 'Address Verification Update', <AddressVerificationUpdate/>)