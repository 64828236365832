import { useState, useContext, useEffect } from 'react'
import { GoChevronDown } from 'react-icons/go'
import ApiContext from 'src/provider/API/call-service'
import UserContext from 'src/provider/state-manager/userProvider'
import { BiReceipt } from 'react-icons/bi'
import { 
    FlexRow, 
    AppText,
    CustomContainer,
    Button,
    CenterPopupContainer,
    CenterAlignedPopup,
    ScreenTitle,
    AppSpan,
    SideBarSummary,
    DataWithLabel,
    Divider
} from "src/styles"
import utility from 'src/utils/utility'
import { RejectionReason } from 'src/popup'
import helpers from 'src/utils/helpers'
import { offerLetterBaseUrl } from 'src/provider/config/constant'


export const LoanAgreement: React.FC<any> = ({data, close, closeNotif, signOffer}) => {
    const {API} = useContext(ApiContext)
    const { user: {formattedUserData, userData} } = useContext(UserContext)
    const [charge, setCharge] = useState(0)
    const [showRejectionReason, setShowRejectionReason] = useState(false)

    useEffect(() => {
        handleGetCharge()
    }, [])

    let startDateTime = data?.loanOffer?.startDate ? 
        helpers.splitDateTime(data?.loanOffer?.startDate)
        : data?.startDate ? helpers.splitDateTime(data?.startDate)
        : null

    let endDateTime = data?.loanOffer?.endDate ? 
    helpers.splitDateTime(data?.loanOffer?.endDate)
    : data?.endDate ? helpers.splitDateTime(data?.endDate)
    : null

    async function handleGetCharge () {
        if (data) {
            const response = await API.getCharges(
                data?.loanOffer?.amountPerPerson || data?.amountPerPerson || data?.amount,
                (data?.loanOffer?.interest || data?.loanOffer?.interest === 0) ? data?.loanOffer?.interest : data?.interest,
                'DISBURSEMENT',
                (data?.loanOffer?.interestAmount || data?.loanOffer?.interestAmount === 0) ? data?.loanOffer?.interestAmount : data?.interestAmount
            )
            if (response) setCharge(response)
        }
    }

    const downloadFile = () => {
        const downloadLink = document.createElement('a');
        document.body.appendChild(downloadLink);
        downloadLink.download = 'Offer-letter';
        //downloadLink.target = '_blank'
        downloadLink.href = `${offerLetterBaseUrl}/LoanOffer/DownloadLoanAgreementLetter/${data?.loanOffer?.loanOfferId || data?.loanOfferId}/${data?.loanRequestId}?customerId=${userData.customerId}`;
        console.log({downloadLink: downloadLink.href})
        downloadLink.click();
    }

    function handleCloseAll () {
        if (closeNotif) closeNotif()
        else close()
    }

    function handleNext () {
        signOffer()
        close()
    }

    return (
        <>
            { !showRejectionReason &&
                <CenterPopupContainer zIndex="8">
                    <CenterAlignedPopup data-aos="slide-right">
                        <CustomContainer bottomMargin="3.5">
                            <ScreenTitle align="center" textSize="2" className='agreement-title'>
                                Loan offer letter and agreements
                            </ScreenTitle>
                            <i className="fa fa-times abs-right-center" onClick={() => close(false)}></i>
                        </CustomContainer>
                        <FlexRow justifyContent="space-between" alignItems="flex-start" className="agreement-address-wrapper"> 
                            <AppText width="50" sizeUnit="%" color="#E2E2E2" textSize="1.4" fontWeight="400">
                                Borrower's name: 
                                <AppSpan color="#fff" textSize="1.4" fontWeight="700" leftPadding="1"> {formattedUserData?.bioDetail?.firstName} {formattedUserData?.bioDetail?.lastName}</AppSpan>
                            </AppText>
                            {/* <FlexRow width="50" sizeUnit="%" alignItems="flex-start">
                                <AppSpan style={{wordWrap: 'normal'}} width="30"  color="#E2E2E2" textSize="1.4" fontWeight="400">Borrower's Address:</AppSpan>
                                <AppText leftMargin="1" color="#fff" textSize="1.4" fontWeight="700">
                                    {formattedUserData?.contactAddress?.residenceNo}, {formattedUserData?.contactAddress?.streetName}, {formattedUserData?.contactAddress?.landMark || ''}, {formattedUserData?.contactAddress?.state}
                                </AppText>
                            </FlexRow> */}
                        </FlexRow>
                        <CustomContainer className='agreement-info-wrapper'>
                            <CustomContainer topMargin="2" bottomMargin="2">
                                <AppText textSize="1.4" fontWeight="700">Hi {formattedUserData?.bioDetail?.firstName},</AppText>
                                <AppText fontWeight="400" textSize="1.4" color="#E2E2E2">
                                    I refer to our prior correspondence on the subject and I am pleased
                                    to offer the below-mentioned loan offer to you under the terms 
                                    and conditions stated below.
                                </AppText>
                            </CustomContainer>
                            <SideBarSummary bgColor="#4700e0" topPadding="2" bottomPadding="2">
                                <FlexRow justifyContent="space-between" alignItems="flex-start" bottomMargin="2"> 
                                    <DataWithLabel 
                                        titleSize="1.25" 
                                    textSize="1.5" 
                                        fontWeight="700"
                                        align="left"
                                    >
                                        <label>Total repayment</label>
                                        <span>&#8358;{utility.moneyFormat((data?.loanOffer?.totalRepaymentBorrower || data?.loanOffer?.totalRepaymentBorrower === 0) ? data?.loanOffer?.totalRepaymentBorrower : (data?.totalRepaymentBorrower || data?.totalAmountToBeRepaid))}</span>
                                    </DataWithLabel>
                                    <DataWithLabel 
                                        titleSize="1.25" 
                                        textSize="1.5" 
                                        fontWeight="700"
                                        align="right"
                                    >
                                        <label>Tenor</label>
                                        <span>{(data?.loanOffer?.tenor || data?.loanOffer?.tenor === 0) ? data?.loanOffer?.tenor : data?.tenor} {data?.loanOffer?.tenorType?.toLowerCase() || data?.tenorType?.toLowerCase()}(s)</span>
                                    </DataWithLabel>
                                </FlexRow>
                                <FlexRow justifyContent="space-between" alignItems="flex-start" bottomMargin="2"> 
                                    <DataWithLabel 
                                        titleSize="1.25" 
                                        textSize="1.5" 
                                        className="data-with-label"
                                        fontWeight="700"
                                        align="left"
                                    >
                                        <label>{data?.loanOffer?.repaymentTermName || data?.repaymentTermName} repayment</label>
                                        <span>&#8358;{utility.moneyFormat((data?.loanOffer?.repaymentPerTenorBorrower || data?.loanOffer?.repaymentPerTenorBorrower === 0) ? data?.loanOffer?.repaymentPerTenorBorrower : (data?.repaymentPerTenorBorrower || data?.totalAmountPerTenorType))}</span>
                                    </DataWithLabel>
                                    <DataWithLabel 
                                        titleSize="1.25" 
                                        textSize="1.5" 
                                        className="data-with-label"
                                        fontWeight="700"
                                        align="right"
                                    >
                                        <label>Interest rate</label>
                                        <span>{(data?.loanOffer?.interest || data?.loanOffer?.interest === 0) ? data?.loanOffer?.interest : data?.interest}% {data?.loanOffer?.repaymentTermName || data?.repaymentTermName}</span>
                                    </DataWithLabel>
                                </FlexRow>
                                <FlexRow justifyContent="space-between" alignItems="flex-start"> 
                                    <DataWithLabel 
                                        titleSize="1.25" 
                                        textSize="1.5" 
                                        titleColor="#E2E2E2"
                                        className="data-with-label"
                                        fontWeight="700"
                                        align="left"
                                    >
                                        <label>Loan Amount</label>
                                        <span>{utility.moneyFormat(data?.loanOffer?.amountPerPerson || data?.amountPerPerson || data?.amount)}</span>
                                    </DataWithLabel>
                                    <DataWithLabel 
                                        titleSize="1.25" 
                                        textSize="1.5" 
                                        color="#fff"
                                        className="data-with-label"
                                        fontWeight="700"
                                        align="right"
                                    >
                                        <label>Repayment</label>
                                        <span>{(data?.loanOffer?.rateId || data?.rateId) == 1 ? 'Installment' : 'One-off'} ({data?.loanOffer?.repaymentTermName || data?.repaymentTermName})</span>
                                    </DataWithLabel>
                                </FlexRow>
                                { (data?.loanOffer?.startDate || data?.startDate || data?.loanOffer?.endDate || data?.endDate) &&
                                    <FlexRow justifyContent="space-between" alignItems="flex-start"> 
                                        <DataWithLabel 
                                            titleSize="1.25" 
                                            textSize="1.5" 
                                            titleColor="#E2E2E2"
                                            className="data-with-label"
                                            fontWeight="700"
                                            align="left"
                                        >
                                            <label>Start Date</label>
                                            <span>{startDateTime?.date} {startDateTime?.completeTime}</span>
                                        </DataWithLabel>
                                        <DataWithLabel 
                                            titleSize="1.25" 
                                            textSize="1.5" 
                                            color="#fff"
                                            className="data-with-label"
                                            fontWeight="700"
                                            align="right"
                                        >
                                            <label>End Date</label>
                                            <span>{endDateTime?.date} {endDateTime?.completeTime}</span>
                                        </DataWithLabel>
                                    </FlexRow>
                                }
                                <Divider margin='1' bgColor='rgba(255,255,255,0.2)' />
                                <AppText color='#E2E2E2' textSize='1.25' fontWeight='400'>
                                    Crendly Charges:
                                    <AppSpan textSize='1.5'> &#8358;{charge}</AppSpan>
                                    {/* charges should not be hardcoded */}
                                </AppText>
                            </SideBarSummary>

                            {/* <CustomContainer>
                                <AppText textSize="1.4" fontWeight="700" topMargin="2">
                                    Conditions Precedent to DISBURSEMENT
                                </AppText>
                                <ol className="condition-list-wrapper">
                                    <li className="condition-list">
                                        <AppText color="#E2E2E2" leftMargin="1.2" textSize="1.4" fontWeight="400">
                                            Acceptance of loan offer from the Lender
                                        </AppText>
                                    </li>
                                    <li className="condition-list">
                                        <AppText color="#E2E2E2" leftMargin="1.2" textSize="1.4" fontWeight="400">
                                            Receipt of the Offer Letter duly signed by the Borrower
                                        </AppText>
                                    </li>
                                    <li className="condition-list">
                                        <AppText color="#E2E2E2" leftMargin="1.2" textSize="1.4" fontWeight="400">
                                            Receipt of the Loan Agreement duly signed by the Borrower
                                        </AppText>
                                    </li>
                                </ol>
                                <FlexRow topMargin="3" justifyContent="flex-start">
                                    <AppSpan color="#E2E2E2" rightMargin="3" textSize="1.4" fontWeight="400">
                                        Payment of the upfront fee and any other applicable fee
                                    </AppSpan>
                                    <div className="terms-acceptance-slider-container">
                                        <input type="checkbox" className="terms-acceptance-checkbox" />
                                        <div className="terms-acceptance-slider-shadow"></div>
                                        <span className="terms-acceptance-slider"></span>
                                    </div>
                                </FlexRow>
                            </CustomContainer> */}
                            <CustomContainer>
                                <AppText textSize="1.4" fontWeight="700" topMargin="2">
                                    Conditions Precedent to Drawdown
                                </AppText>
                                <ol className="condition-list-wrapper">
                                    <li className="condition-list">
                                        <AppText color="#E2E2E2" leftMargin="1.2" textSize="1.4" fontWeight="400">
                                            Acceptance of loan offer from the Lender
                                        </AppText>
                                    </li>
                                    <li className="condition-list">
                                        <AppText color="#E2E2E2" leftMargin="1.2" textSize="1.4" fontWeight="400">
                                            Receipt of the Offer Letter duly signed by the Borrower
                                        </AppText>
                                    </li>
                                    <li className="condition-list">
                                        <AppText color="#E2E2E2" leftMargin="1.2" textSize="1.4" fontWeight="400">
                                            Receipt of the Loan Agreement duly signed by the Borrower
                                        </AppText>
                                    </li>
                                    <li className="condition-list">
                                        <AppText color="#E2E2E2" leftMargin="1.2" textSize="1.4" fontWeight="400">
                                            Payment of the upfront fee and any other applicable fee
                                        </AppText>
                                    </li>
                                </ol>
                            </CustomContainer>
                            <AppText fontWeight="400" textSize="1.4" color="#E2E2E2">
                                The above summary terms and conditions are preliminary and are subject to change
                                and do not purport to summarize all of the terms and conditions contained in definitive legal documentation
                                for this loan facility contemplated hereby. <br/>
                                Please indicate your acceptance of the above facility and the terms and conditions by signing this letter
                                within 3 (three) days from the date of this letter, failing which the offer shall lapse
                                without any liability or commitment on the part of the Lender.
                            </AppText>
                        </CustomContainer>
                        <FlexRow topMargin="4" justifyContent="space-between" className="loan-acceptance-action">
                            <button className="borderless-button" onClick={downloadFile}>
                                <BiReceipt className="borderless-button-icon" />
                                <span>View Full Contract</span>
                            </button>
                            <FlexRow justifyContent="flex-end" width="auto" sizeUnit="" className="loan-acceptance-btns">
                                <a className="underlined-link-btn" onClick={() => setShowRejectionReason(true)}>Reject loan offer</a>
                                <Button 
                                    width="17" 
                                    height="4"
                                    hoverBgColor="#FED0B7"
                                    leftMargin="3.5"
                                    onClick={handleNext}
                                >
                                    I accept loan offer
                                </Button>
                            </FlexRow>
                        </FlexRow>
                    </CenterAlignedPopup>
                </CenterPopupContainer>
            }
            
            { showRejectionReason &&
                <RejectionReason
                    closeDetails={handleCloseAll}
                    close={() => setShowRejectionReason(false)}
                    data={data}
                    isOfferRejection={true}
                />
            }
        </>
    )
}
