import '../style.scss'
import { useContext, useState } from 'react'
import VisibilityContext from 'src/provider/state-manager/visibilityProvider'
import { AppLabel, AppSpan, AppText, Button, CustomContainer, FlexColumn, FlexRow, Form, FormGroup, GridContainer, SidePopupContainer } from "src/styles"
import helpers from 'src/utils/helpers'
import { BsChevronLeft } from 'react-icons/bs'

export const VNINViaApp: React.FC<any> = ({onNext, onVNIN, onBack, close}) => {
    const {notifier} = useContext(VisibilityContext)
    const [vNIN, setVNIN] = useState('')

    const handleVNINChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.value.length <= 16) setVNIN(e.target.value.replace(' ', ''))
    }

    function handleContinue (e: React.FormEvent<HTMLButtonElement | HTMLFormElement>) {
        e.preventDefault()
        const isValidVNIN = helpers.validateVNIN(vNIN)
        if (!isValidVNIN) return notifier.show('Kindly provide a valid VNIN')
        onVNIN(vNIN)
        onNext()
    }

    return (
        <>
            <SidePopupContainer zIndex="6" onClick={close} />
            <GridContainer
                className='vnin-popup-container'
            >
                <FlexRow 
                    justifyContent='flex-start' onClick={close}
                >
                    <BsChevronLeft style={{fontSize: '1.5rem', marginRight: '1.5', color: '#B4DDC5'}}/>
                    <AppSpan textSize='1.5' fontWeight='400' cursor='pointer' hoverColor='#FFC49D'>Back</AppSpan>
                </FlexRow>
                <CustomContainer topMargin='2'>
                    <AppLabel textSize='2' color='#ffffff'>How to generate vNIN through the NIMC mobile app.</AppLabel>
                    <AppText textSize='1.2' fontWeight='400' topMargin='1'>
                        1.  Download the NIMC App from the Apple App Store or Google Play Store & sign up/sign in.
                    </AppText>
                    <AppText textSize='1.2' fontWeight='400' topMargin='1.2'>
                        2. On the home screen, click on <AppSpan textSize='1.2'>"Get Virtual NIN"</AppSpan> and follow prompts.
                    </AppText>
                    <AppText textSize='1.2' fontWeight='400' topMargin='1.2'>
                        3. Select <AppSpan textSize='1.2'>"Input Enterprise short-code"</AppSpan> and type <AppSpan textSize='1.2'>“471335”</AppSpan>
                    </AppText>
                    <AppText textSize='1.2' fontWeight='400' topMargin='1.2'>
                        4. Click <AppSpan textSize='1.2'>"Submit"</AppSpan> and your virtual NIN will be generated.
                    </AppText>
                </CustomContainer>
                <CustomContainer borderColor='#2E4DBD' borderType='dashed' topMargin='2' bottomMargin='2'/>
                <Form onSubmit={handleContinue}>
                    <FormGroup bottomMargin="1.5" topMargin="1">
                        <label>Enter the vNIN you generated </label>
                        <input  
                            name="vNIN" 
                            placeholder="Enter VNIN" 
                            value={vNIN} 
                            onChange={handleVNINChange} 
                            autoComplete='off'
                            required
                        />
                    </FormGroup>
                    <FlexColumn>
                        <Button 
                            width="17" 
                            height="4.5" 
                            topMargin='3'
                            hoverBgColor="#FFC49D" 
                            bottomMargin="1.5"
                            className="loan-action-btn"
                            disabled={!vNIN ? true : false}
                        >
                            Continue
                        </Button>
                    </FlexColumn>
                </Form>
            </GridContainer>
        </>
    )
}