import React, {useReducer} from 'react'
import { Action, KeyValuePayload } from '../../model'

const initialState = {
    loanRequest: {}, loanCreation: {}, requestLoanOffers: [], requestLoanOffersCreditDecision: {}, selectedLoanOffer: {},
    loanTypes: [], proposedLoanRequest: {}, loanCalcData: {}, selectedOfferRequest: {},
    currentLoans: null, currentLoanInfo: {}, offerBorrowers: [], loanIdB4Cancel: 0,
    currentProcessType: '', allUserPortfolios: [], userRunningPortfolios: []
}
const LoanContext = React.createContext<any>(null)

const LoanReducer = (state: any, action: Action<KeyValuePayload>) => {
    switch(action.type){
        case "set-property": 
            return { ...state, [action.payload.key]: action.payload.value }
        case 'clear-data': 
            return { 
                ...initialState
            }
    }
}

export const LoanProvider = (props: any) => {
    const [state, dispatch] = useReducer(LoanReducer, {
        ...initialState
    })

    async function setLoanProperty (key: string, value: any) {
        localStorage.setItem(key, JSON.stringify(value))
        let isString = typeof(value) === 'string' ? true : false
        let isNumber = typeof(value) === 'number' ? true : false
        localStorage.setItem(key, isString ? value : isNumber ? String(value) : JSON.stringify(value))
        await dispatch({type: "set-property", payload: {key, value}})
    }

    async function recoverLoanData () {
        // const loanRequest = await JSON.parse(localStorage.getItem('loanRequest')!)
        // const loanCreation = await JSON.parse(localStorage.getItem('loanCreation')!)
        // const requestLoanOffers = await JSON.parse(localStorage.getItem('requestLoanOffers')!)
        // const selectedLoanOffer = await JSON.parse(localStorage.getItem('selectedLoanOffer')!)
        // const loanTypes = await JSON.parse(localStorage.getItem('loanTypes')!)
        // const proposedLoanRequest = await JSON.parse(localStorage.getItem('proposedLoanRequest')!)
        // const loanCalcData = await JSON.parse(localStorage.getItem('loanCalcData')!)
        // const selectedOfferRequest = await JSON.parse(localStorage.getItem('selectedOfferRequest')!)
        // const currentLoans = await JSON.parse(localStorage.getItem('currentLoans')!)
        // const currentLoanInfo = await JSON.parse(localStorage.getItem('currentLoanInfo')!)
        // const offerBorrowers = await JSON.parse(localStorage.getItem('offerBorrowers')!)
        // const loanIdB4Cancel = await localStorage.getItem('loanIdB4Cancel')!
        // const currentProcessType = await localStorage.getItem('currentProcessType')!
        // const requestLoanOffersCreditDecision = await localStorage.getItem('requestLoanOffersCreditDecision')!
        // setLoanProperty('loanRequest', loanRequest)
        // setLoanProperty('loanCreation', loanCreation)
        // setLoanProperty('requestLoanOffers', requestLoanOffers)
        // setLoanProperty('selectedLoanOffer', selectedLoanOffer)
        // setLoanProperty('loanTypes', loanTypes)
        // setLoanProperty('proposedLoanRequest', proposedLoanRequest)
        // setLoanProperty('loanCalcData', loanCalcData)
        // setLoanProperty('selectedOfferRequest', selectedOfferRequest)
        // setLoanProperty('currentLoans', currentLoans)
        // setLoanProperty('currentLoanInfo', currentLoanInfo)
        // setLoanProperty('offerBorrowers', offerBorrowers)
        // setLoanProperty('loanIdB4Cancel', loanIdB4Cancel)
        // setLoanProperty('currentProcessType', currentProcessType)
        // setLoanProperty('requestLoanOffersCreditDecision', requestLoanOffersCreditDecision)

        for (let item of Object.keys(initialState)) {
            let retrievedData = await localStorage.getItem(item)!
            retrievedData = (['null', 'undefined', 'NaN'].includes(retrievedData) ? state[item] : ['number', 'string'].includes(typeof state[item]) ? retrievedData : JSON.parse(retrievedData))
            await setLoanProperty(item, typeof state[item] === 'number' ? Number(retrievedData) : retrievedData)
        }
    }

    async function clearLoanData () {
        await dispatch({type: "clear-data", payload: {key: '', value: ''}})
    }
  

    const stateActions = {
       setLoanProperty,
       recoverLoanData,
       clearLoanData
    }

    return (
        <LoanContext.Provider value={{loan: state, ...stateActions}} >
            {props.children}
        </LoanContext.Provider>
    )
}

export default LoanContext