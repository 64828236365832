const access = (function () {
    let navigation: any = null;
    let closePopupMethod: any = null;
    let isOnline: boolean = true;
    let debounceCall = false
    let proposedLoan = <any>{}
    let logout: any = null

    const setNavigationAccess = function(val: any) {
        navigation = val
    }
    const getNavigationAccess = function() {
        return navigation;
    }

    const setClosePopupMethod = function(val: any) {
        closePopupMethod = val
    }
    const getClosePopupMethod = function() {
        return closePopupMethod;
    }

    const setInternetStatus = function(val: boolean) {
        isOnline = val
    }
    const getInternetStatus = function() {
        return isOnline;
    }

    const setCallDebounce = function(val: boolean) {
        debounceCall = val
    }
    const getCallDebounce = function() {
        return debounceCall;
    }
    
    const setProposedLoan = function(val: any) {
        proposedLoan = val
    }
    const getProposedLoan = function() {
        return proposedLoan;
    }

    const setLogoutHandler = function(val: any) {
        logout = val
    }
    const getLogoutHandler = function() {
        return logout;
    }


    return {
        setNavigationAccess,
        getNavigationAccess,
        setClosePopupMethod,
        getClosePopupMethod,
        setInternetStatus,
        getInternetStatus,
        setCallDebounce,
        getCallDebounce,
        setProposedLoan,
        getProposedLoan,
        setLogoutHandler,
        getLogoutHandler
    }
})()

export default access