import { useContext, useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import utility from 'src/utils/utility'
import ApiContext from 'src/provider/API/call-service'
import UserContext from 'src/provider/state-manager/userProvider'
import { 
    ResponderContentWrapper, 
    ResponderMessage,
    ResponderTitle, 
} from 'src/component/Responder/style'
import { CenterPopupContainer, CenterPopup, AppLink, AppText, Button, AppSpan, FlexRow, GridContainer } from 'src/styles'
import helpers from 'src/utils/helpers'
import { HiOutlineMail } from 'react-icons/hi'
import { LiaSmsSolid } from 'react-icons/lia'


export const VerifyOtp: React.FC<any> = ({close}) => {
    const navigate = useNavigate()
    const {API} = useContext(ApiContext)
    const {user: {bvnData}} = useContext(UserContext)
    const [otp, setOtp] = useState('')
    const [resendType, setResendType] = useState('phoneNo')
    var [time, setTime] = useState<any>(null)
    var [count, setCount] = useState<any>(null)

    useEffect(() => {
        if (count === 30) handleResendOtp()
    }, [count])

    useEffect(() => {
        return () => { clearInterval(time) }
    }, [])

    function handleResendCounter (resendType: string) {
        if (!count) {
            setResendType(resendType)
            time =  setInterval(async () => {
                setCount((count: number) => {
                    if (count === 30) {
                        clearInterval(time)
                        return 0
                    }
                    else return count + 1
                });
            }, 1000)
        }
    }

    const handleOtp = (e: React.ChangeEvent<HTMLInputElement|HTMLSelectElement>) => {
        if (e.target.value.length < 7) {
            setOtp(e.target.value)
        }
    }

    async function handleResendOtp () {
        await API.sendOtp_Inactivity(resendType === 'phoneNo' ? 'Phone': 'Email')
    }

    async function handleContinue () {
        const response = await API.completeVerification_Inactivity(otp)
        if (response) {
            let lastRouteVisited = utility.getLastPageVisited(response)
            navigate(`/onboarding/${lastRouteVisited}`)
            close()
        }
    }

    return (
        <CenterPopupContainer zIndex="7">
            <CenterPopup data-aos="slide-down" style={{paddingTop: '3rem'}}>
                <i className="fa fa-times responder-cancel" onClick={close}></i>
                <ResponderContentWrapper>
                    <ResponderTitle>OTP Verification</ResponderTitle>
                    <ResponderMessage style={{marginBottom: '6rem'}}>
                        We sent a verification code to the phone number linked to your BVN. Kindly enter the code and continue with your sign up.
                    </ResponderMessage>
                    <div className="form-group-wrapper">
                        <div className="passcode-input-wrapper">
                            <input 
                                className='display-hidden' 
                                id='pass' name="pass" 
                                value={otp} autoFocus={true} 
                                onChange={handleOtp} autoComplete="off"
                            />
                            <label className={`passcode-input ${otp.length === 0 && 'active-pin-box'}`} htmlFor='pass' >{otp[0] || ''}</label>
                            <label className={`passcode-input ${otp.length === 1 && 'active-pin-box'}`} htmlFor='pass' >{otp[1] || ''}</label>
                            <label className={`passcode-input ${otp.length === 2 && 'active-pin-box'}`} htmlFor='pass' >{otp[2] || ''}</label>
                            <label className={`passcode-input ${otp.length === 3 && 'active-pin-box'}`} htmlFor='pass' >{otp[3] || ''}</label>
                            <label className={`passcode-input ${otp.length === 4 && 'active-pin-box'}`} htmlFor='pass' >{otp[4] || ''}</label>
                            <label className={`passcode-input ${otp.length === 5 && 'active-pin-box'}`} htmlFor='pass' >{otp[5] || ''}</label>
                        </div>
                    </div>
                    <AppText textSize='1.4' align='center' width='25' topMargin='-1.5' fontWeight='400'>
                        Didn't receive the code? Please check messages for the code.
                    </AppText>
                    <AppText textSize="1.4" color="#D5D5D5" fontWeight="400" topMargin="4">
                        Code will resend in 
                        <AppSpan color="#FED0B7" leftPadding="0.5">{helpers.formatSeconds(30-count)}</AppSpan>
                        {/* <AppLink textSize="1.4" hoverColor="#FED0B7" leftMargin="8" onClick={handleResendCounter}>
                            Resend Code
                        </AppLink> */}
                    </AppText>
                    <FlexRow 
                        width='auto' sizeUnit='' topMargin="3" wrap='wrap'
                        justifyContent='center' alignItems='flex-start' gap='1.5'
                    >
                        <GridContainer
                            width='20'  minHeight='4' topPadding='0' bottomPadding='0'
                            radius='0.8' padding='1.5' bgColor='#00071F' 
                            className='otp-resend-btn'
                            onClick={() => handleResendCounter('phoneNo')}
                        >
                            <FlexRow>
                                <AppSpan color='#6DE7B4' textSize='1.5' rightMargin='0.6'>
                                    <LiaSmsSolid />
                                </AppSpan>
                                <AppLink textSize="1.2">
                                    Resend Code via SMS
                                </AppLink>
                            </FlexRow>
                        </GridContainer>
                        <GridContainer
                            width='20' minHeight='4' topPadding='0' bottomPadding='0'
                            radius='0.8' padding='1.5' bgColor='#000926'
                            className='otp-resend-btn'
                            onClick={() => handleResendCounter('email')}
                        >
                            <FlexRow>
                                <AppSpan color='#6DE7B4' textSize='1.4' rightMargin='0.6'>
                                    <HiOutlineMail />
                                </AppSpan>
                                <AppLink textSize="1.2">
                                    Resend Code via Email
                                </AppLink>
                            </FlexRow>
                        </GridContainer>
                    </FlexRow>
                    <Button 
                        topMargin='4'
                        bottomMargin='2'
                        width="18" 
                        height="4"
                        hoverBgColor="#FED0B7"
                        className="loan-action-btn"
                        disabled={otp.length != 6 ? true : false}
                        onClick={handleContinue}
                    >
                        Continue
                    </Button>
                </ResponderContentWrapper>
            </CenterPopup >
        </CenterPopupContainer>
    )
}
