import { useState, useContext, useEffect } from 'react'
import utility, {required} from 'src/utils/utility'
import ApiContext from 'src/provider/API/call-service'
import AppInfoContext from 'src/provider/state-manager/appInfoProvider'
import UserContext from 'src/provider/state-manager/userProvider'
import { useNavigate } from 'react-router-dom'
import { Container } from 'src/component'
import { AppSpan, AppText, Button, Circle, CustomContainer, FlexRow, FormGroup, ScreenTitle } from 'src/styles'
import { BsChevronLeft } from 'react-icons/bs'

const UserEmail = () => {
    const navigate = useNavigate()
    const {API} = useContext(ApiContext)
    const {info} = useContext(AppInfoContext)
    const {user} = useContext(UserContext)
    const [email, setEmail] = useState(info.onBoardingData?.email || '')
    const [isValidated, setIsValidated] = useState(false)

    useEffect(() => {
        setIsValidated(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email))
    }, [email])

    async function handleNext () {
        console.log({user})
        const response = await API.updateEmail({userId: user.userData?.id, email})
        if (response) navigate('/onboarding/marital-status')
    }

    return (
        <Container type="onboarding" progressBar='23.07'>
            <p className="right-align-text">Already have an account? 
                <a className="registration-link" onClick={() => navigate('/login')}> Sign in</a>
            </p>
            <div className="onboarding-container">
                <BsChevronLeft className="loan-back-icon" onClick={() => navigate(-1)} />
                <ScreenTitle textSize="3" bottomMargin="1.5" topMargin="3" >
                    Email Address
                </ScreenTitle>
                <AppText color="#D5D5D5" textSize="1.4" fontWeight="400" bottomMargin="2">
                    Please provide a valid email address for verification.
                </AppText>
                <CustomContainer topMargin="5">
                    <FormGroup >
                        <label>Email address {required}</label>
                        <input 
                            type="email" 
                            placeholder="example@gmail.com" 
                            name="email" 
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                    </FormGroup>
                    <FlexRow justifyContent="flex-start" alignItems="flex-start" topMargin="-1">
                        <Circle 
                            bgColor="#FED0B7"
                            size="0.8"
                            rightMargin="0.8"
                        />
                        <AppSpan textSize="1.1" fontWeight="400" topMargin="-0.4">Please provide a valid email address</AppSpan>
                    </FlexRow>
                </CustomContainer>
                <Button 
                    width="15" 
                    height="4.5" 
                    hoverBgColor="#FFC49D" 
                    topMargin="12"
                    bottomMargin="3"
                    className="loan-action-btn"
                    disabled={!isValidated ? true : false}
                    onClick={handleNext}
                >
                    Next
                </Button>
            </div>
        </Container>
    )
}

export default utility.routeData('email', 'User Email', <UserEmail/>)