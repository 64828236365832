import './style.scss'
import { useState, useEffect, useContext } from 'react'
import utility, { formatNegativeCharacter } from 'src/utils/utility'
import LoanContext from 'src/provider/state-manager/loanProvider'
import { useNavigate } from 'react-router-dom'
import { BsChevronLeft } from 'react-icons/bs'
import { FormGroup, SelectButton, Button, ScreenContainer, LevelBar, Divider, FormGroupWithIcon, CustomContainer, AbsoluteContainer, AppSpan } from "src/styles"
import { LoanSummary_Get } from 'src/popup'
import helpers from 'src/utils/helpers'
import { tenorData } from 'src/provider/config/constant'

const LoanDuration = () => {
    const navigate = useNavigate()
    const {loan: {loanRequest}, setLoanProperty} = useContext(LoanContext)
    // const [interest, setInterest] = useState(15)
    const [isValidated, setIsValidated] = useState(false)
    const [input, setInput] = useState({paymentDate: loanRequest?.paymentDate || '', tenor: loanRequest?.tenor || 0, tenorType: loanRequest?.tenorType || '', repaymentTermId: loanRequest?.repaymentTermId || 0, rateId: loanRequest?.rateId || 0, interest: loanRequest?.interest || 15})
    const [loanSummaryShow, setLoanSummaryShow] = useState(false)

    useEffect(() => {
        if(loanSummaryShow) {
          document.body.style.overflow = 'hidden'
        }
        else document.body.style.overflow = 'auto'
    }, [loanSummaryShow])

    useEffect(() => {
        const auth = input.interest == 0 ? helpers.inputValidator({...input}, ['paymentDate', 'interest']) :  helpers.inputValidator({...input}, ['paymentDate'])
        setIsValidated(auth.isValidated)
    }, [input])

    var tenorList = input.repaymentTermId === 2 ? tenorData.days : input.repaymentTermId === 3 ? tenorData.weeks : input.repaymentTermId === 5 ? tenorData.months : tenorData.default
    // useEffect(() => {
    //     const {loanCalcData} = loan
    //     if (loanCalcData) {
    //         setInput({...input, tenor: loanCalcData.tenor, tenorType: loanCalcData.tenorType, interest: loanCalcData.interestRate})
    //     }
    // }, [loan?.loanCalcData])

    const handleSummaryShow = async () => {
        await setLoanProperty('loanRequest', {...loanRequest, ...input})
        setLoanSummaryShow(true)
    }


    return (
        <>
            <ScreenContainer>
                <div className="loan-type-container">
                    <div className="loan-type-title">
                        <BsChevronLeft className="loan-back-icon" onClick={() => navigate(-1)} />
                        <div className="loan-type-title-content-plus">
                            <p >Get a Loan</p>
                            <span>
                                It takes minutes to match you with a lender. Make sure 
                                your loan details are accurate to get matched with the right lenders at great rates.
                            </span>
                        </div>
                    </div>
                    <div className="loan-type-content">
                        {/* <div className="loan-horizontal-bar"></div> */}
                        <LevelBar topMargin='4' strokeWidth="50"><span></span></LevelBar>
                        <FormGroup topMargin="3">
                            <label>How do you want to make payment?</label>
                            <div className="loan-tenor-types">
                                <SelectButton width="16" height="4">
                                    <input type="radio" name="payment-type" checked={input.rateId === 1} onChange={() => setInput({...input, rateId: 1, repaymentTermId: 0, tenor: 0, tenorType: ''})} />
                                    <span></span>
                                    <label>Installment</label>
                                </SelectButton>
                                <SelectButton width="16" height="4" >
                                    <input type="radio" name="payment-type" checked={input.rateId === 2} onChange={() => setInput({...input, rateId: 2, repaymentTermId: 0, paymentDate: '', tenor: 0, tenorType: ''})} />
                                    <span></span>
                                    <label>One-Off</label>
                                </SelectButton>
                            </div>
                        </FormGroup>
                        { input?.rateId != 0 &&
                            <>
                                <Divider bgColor="#1E3177" />
                                <FormGroup topMargin="4">
                                    <label>{input?.rateId === 1 ? 'How often you want to make payment?' : 'Repayment Duration Type'}</label>
                                    <div className="loan-tenor-types">
                                        <SelectButton width="10" height="4">
                                            <input type="radio" name="payment-duration" checked={input.repaymentTermId === 2} onChange={() => setInput({...input, repaymentTermId: 2, tenor: 0, tenorType: ''})}/>
                                            <span></span>
                                            <label>{input?.rateId === 1 ? 'Daily' : 'Day'}</label>
                                        </SelectButton>
                                        <SelectButton width="10" height="4">
                                            <input type="radio" name="payment-duration" checked={input.repaymentTermId === 3} onChange={() => setInput({...input, repaymentTermId: 3, tenor: 0, tenorType: ''})} />
                                            <span></span>
                                            <label>{input?.rateId === 1 ? 'Weekly' : 'Week'}</label>
                                        </SelectButton>
                                        {/* <SelectButton width="10" height="4">
                                            <input type="radio" name="payment-duration" checked={input.repaymentTermId === 4} onChange={() => setInput({...input, repaymentTermId: 4, tenor: 0, tenorType: ''})} />
                                            <span></span>
                                            <label>BiWeekly</label>
                                        </SelectButton> */}
                                        <SelectButton width="10" height="4">
                                            <input type="radio" name="payment-duration" checked={input.repaymentTermId === 5} onChange={() => setInput({...input, repaymentTermId: 5, tenor: 0, tenorType: ''})}/>
                                            <span></span>
                                            <label>{input?.rateId === 1 ? 'Monthly' : 'Month'}</label>
                                        </SelectButton>
                                    </div>
                                </FormGroup>
                            </>
                        }
                        {/* { input.rateId === 2 &&
                            <>
                                <Divider bgColor="#1E3177" />
                                <FormGroup bottomMargin="1.5">
                                    <label>Date to make payment</label>
                                    <input 
                                        type="date" 
                                        name="paymentDate" 
                                        value={input.paymentDate} 
                                        style={{color: input.paymentDate ? '#fff' : '#999'}}
                                        onChange={(e) => setInput({...input, paymentDate: e.target.value})} 
                                    />
                                </FormGroup>
                            </>
                        } */}
                        { input.repaymentTermId != 0 &&
                            <FormGroup topMargin="3">
                                <Divider bgColor="#1E3177" />
                                <label>How long is this loan going to run?</label>
                                <div className="loan-tenor-types">
                                    {
                                        tenorList?.data?.map((item: number, index: number) => {
                                            let label = (tenorList.tenorType === 'default' && index === 0) ? 'Days' : (tenorList.tenorType === 'default' && index === 1) ? 'Weeks' : tenorList.tenorType === 'default' ? 'Months' :  tenorList.label
                                            let term = (tenorList.tenorType === 'default' && index === 0) ? 'DAY' : (tenorList.tenorType === 'default' && index === 1) ? 'WEEK' : tenorList.tenorType === 'default' ? 'MONTH' : tenorList.tenorType
                                            return (
                                                <SelectButton width="10" height="4" key={index}>
                                                    <input type="radio" name="loan-duration" checked={input.tenor === item} onChange={() => setInput({...input, tenor: item, tenorType: term})} />
                                                    <span></span>
                                                    <label>{item} {label}</label>
                                                </SelectButton>
                                            )
                                        })
                                    }
                                </div>
                            </FormGroup>
                        }
                        { (input.repaymentTermId != 0) &&
                            <>
                                <CustomContainer topMargin='-3'>
                                    <Divider bgColor="#1E3177" margin='2.5' />
                                    <AbsoluteContainer className='center-absolute'>
                                        <CustomContainer 
                                            borderColor='#0F1B46'
                                            rightPadding='2' leftPadding='2' bgColor='#000926'
                                        >
                                            <AppSpan>or</AppSpan>
                                        </CustomContainer>
                                    </AbsoluteContainer>
                                </CustomContainer>
                                 <FormGroup>
                                    <label>Number of {tenorList.label}</label>
                                    <input type="number" value={input.tenor || ''} placeholder={`Enter Number of ${tenorList.label}`} onChange={(e) => setInput({...input, tenor: Number(formatNegativeCharacter(e.target.value)), tenorType: tenorList.tenorType})} />
                                </FormGroup>
                            </>
                        }
                        <FormGroupWithIcon>
                            <label>Interest Rate (monthly)</label>
                            <p className="selected-interest-rate">{input.interest}%</p>
                            <div className="range-input-container">
                                <input type="range" className="range-input" defaultValue={input.interest} onChange={(e) => setInput({...input, interest: Number(e.target.value)})} />
                                <span className="range-progress-shadow" style={{width: `${(input.interest * 48.2) /50}%`}}></span>
                            </div>
                        </FormGroupWithIcon>
                        <div className="loan-creation-wrapper-plus">
                            <span onClick={() => navigate(-1)}>Back</span>
                            <Button
                                width="13"
                                hoverBgColor="#FED0B7"
                                className="loan-action-btn"
                                disabled={!isValidated ? true : false}
                                onClick={handleSummaryShow}
                            >
                                Next
                            </Button>
                        </div>
                    </div>
                </div>
            </ScreenContainer>
            { loanSummaryShow &&
                <LoanSummary_Get
                    close={() => setLoanSummaryShow(false)}
                    data={{...loanRequest, ...input}}
                />
            }
        </>
    )
}

export default utility.routeData('get-loan/loan-duration', 'Take Loan Duration', <LoanDuration/>)