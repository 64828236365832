import { useState, useContext, useEffect } from 'react'
import { GoChevronDown } from 'react-icons/go'
import ApiContext from 'src/provider/API/call-service'
import AppInfoContext from 'src/provider/state-manager/appInfoProvider'
import { DecisionBox } from 'src/popup'
import { 
    SidePopupContainer, 
    SidePopup, 
    FlexRow, 
    Button,
    FormGroup,
    OutlineButton,
    CustomContainer,
    FormGroupWithIcon,
} from "src/styles"

export const AccountDeletion: React.FC<any> = ({close}) => {
    const {API} = useContext(ApiContext)
    const {logout} = useContext(AppInfoContext)
    const [reason, setReason] = useState({selectedReason: '', otherReason: ''})
    const [disableBtn, setDisableBtn] = useState(true)
    const [dialogueType, setDialogueType] = useState('')

    useEffect(() => {
        let shouldDisableBtn = true
        if (reason.selectedReason) {
            if (reason.selectedReason === 'Others' && !reason.otherReason) shouldDisableBtn = true
            else shouldDisableBtn = false
        }

        setDisableBtn(shouldDisableBtn)
    }, [reason])

    async function handleAccountDeletion (type: string) {
        const actionReason = reason.selectedReason === 'Others' ? reason.otherReason : reason.selectedReason
        const response = type === 'delete' ? 
            await API.deleteAccount(actionReason)
            : await API.deactivateAccount(actionReason)

        if (response) handleLogout()
    }

    async function handleLogout () {
        API.updateLoginHistory()
        await logout()
    }


    return (
        <>
            <SidePopupContainer >
                <SidePopup data-aos="slide-left">
                    <div className="popup-title-wrapper">
                        <h4>Account Deletion</h4>
                        <i className="fa fa-times" onClick={close}></i>
                    </div>
                    <CustomContainer>
                        <FormGroupWithIcon>
                            <label>Why do you want to delete your account?</label>
                            <div className="input-with-icon">
                                <select
                                    name="reason" 
                                    value={reason.selectedReason} 
                                    onChange={(e) => setReason({...reason, selectedReason: e.target.value})}
                                    style={{color: reason.selectedReason ? '#fff' : '#999'}}
                                >
                                    <option value="">Select a reason</option>
                                    <option value="I have issues with the platform">I have issues with the platform</option>
                                    <option value="Loans are hard to get">Loans are hard to get</option>
                                    <option value="I want to erase my data">I want to erase my data</option>
                                    <option value="I am not using it">I am not using it</option>
                                    <option value="Others">Others</option>
                                </select>
                                <GoChevronDown className="input-with-icon-tag" />
                            </div>
                        </FormGroupWithIcon>
                        { reason.selectedReason === 'Others' &&
                            <FormGroup>
                                <textarea 
                                    placeholder="Enter your reason" 
                                    name="about"
                                    value={reason.otherReason} 
                                    onChange={(e) => setReason({...reason, otherReason: e.target.value})}
                                >
                                </textarea>
                            </FormGroup>
                        }
                    </CustomContainer>
                    <FlexRow 
                        justifyContent="flex-end" 
                        topMargin="2" gap='2' wrap='wrap'
                    >
                        <Button 
                            width="100" sizeUnit='%' 
                            bgColor='#E54B4D'
                            color='#ffffff'
                            className='acct-action-btn'
                            disabled={disableBtn}
                            onClick={() => setDialogueType('delete')}
                        >
                            Delete Account
                        </Button>
                        <Button 
                            width="100" sizeUnit='%' 
                            hoverBorderColor='#E54B4D'
                            bgColor='transparent'
                            borderColor='#E54B4D'
                            color='#E54B4D'
                            className='loan-action-outline-btn'
                            disabled={disableBtn}
                            onClick={() => setDialogueType('deactivate')}
                        >
                            Deactivate Account
                        </Button>
                    </FlexRow>
                </SidePopup>
            </SidePopupContainer>
            { dialogueType &&
                <DecisionBox
                    close={() => setDialogueType('')}
                    title={`${dialogueType === 'delete' ? 'Delete' : 'Deactivate'} My Account`}
                    subheading={`Are you sure you want to ${dialogueType} this account? Kindly note that this action cannot be reversed.`}
                    noText="No, not now"
                    yesText={`Yes, ${dialogueType}`}
                    yesMethod={() => handleAccountDeletion(dialogueType)}
                />
            }
        </>
    )
}
