import { useState, useContext } from 'react'
import ApiContext from 'src/provider/API/call-service'
import { SidePopupContainer, SidePopup, FormGroup, Button, DataWithLabel, FormGroupWithIcon, SideBarSummary, FlexEndWrapper } from "src/styles"

export const ExploreLoanCalculator: React.FC<any> = ({close}) => {
    const {API} = useContext(ApiContext)
    const [isSummarySection, setIsSummarySection] = useState(false)
    const [interestRate, setInterestRate] = useState(50)
    const [isLoanCalc, setIsLoanCalc] = useState(true)

    const handleSlider = (e: React.ChangeEvent<HTMLInputElement>) => setInterestRate(Number(e.target.value))

    function handleToggle (e: React.ChangeEvent<HTMLInputElement>) {
        if (!e.target.checked) setIsLoanCalc(true)
        else setIsLoanCalc(false)
    }

    async function handleCreatePortfolio () {
        await API.exploreResponse()
        close()
      }

    return (
        <SidePopupContainer >
            <SidePopup padding="0" data-aos="slide-left">
                { !isSummarySection &&
                    <>
                        <div className="popup-grey-container">
                            <div className="popup-title-wrapper">
                                <h4>Calculator</h4>
                                <i className="fa fa-times" onClick={close}></i>
                            </div>

                            <div className="calc-type-toggler">
                                <input type="checkbox" className="calc-toggler-checkbox" defaultChecked={!isLoanCalc} onChange={handleToggle} />
                                <div className="calc-type-slider"></div>
                                <div className="calc-type-text">
                                    <span className="calc-type-1">Loan Calculator</span>
                                    <span className="calc-type-2">Income Calculator</span>
                                </div>
                            </div>
                            <div className="calc-type-amount">
                                { isLoanCalc ?
                                    <DataWithLabel titleSize="1.5" textSize="3" fontWeight="700">
                                        <label>Monthly Payment</label>
                                        <span>&#8358;70,000</span>
                                    </DataWithLabel>
                                    :
                                    <DataWithLabel titleSize="1.5" textSize="3" fontWeight="700">
                                        <label>Monthly Earnings</label>
                                        <span>&#8358;70,000</span>
                                    </DataWithLabel>
                                }
                            </div>
                            <div className="calc-loan-data">
                                { isLoanCalc ?
                                    <DataWithLabel titleSize="1.3" textSize="1.9" fontWeight="700" className="data-with-label">
                                        <label>Amount</label>
                                        <span>&#8358;125000</span>
                                    </DataWithLabel>
                                    :
                                    <DataWithLabel titleSize="1.3" textSize="1.9" fontWeight="700" className="data-with-label">
                                        <label>Portfolio Amount</label>
                                        <span>&#8358;125000</span>
                                    </DataWithLabel>
                                }
                                <DataWithLabel titleSize="1.3" textSize="1.9" fontWeight="700" className="data-with-label">
                                    <label>Duration</label>
                                    <span>12 Months</span>
                                </DataWithLabel>
                                <DataWithLabel titleSize="1.3" textSize="1.9" fontWeight="700" className="data-with-label">
                                    <label>Interest</label>
                                    <span>&#8358;22,500</span>
                                </DataWithLabel>
                            </div>
                        </div>
                        <div className="loan-calc-form">
                            <FormGroup>
                                <label>Amount</label>
                                <input type="number" placeholder="Enter amount" />
                            </FormGroup>
                            <FormGroup>
                                <label>Duration</label>
                                <input type="number" placeholder="Enter duration" />
                            </FormGroup>
                            <FormGroupWithIcon>
                                <label>Interest Rate</label>
                                <p className="selected-interest-rate">{interestRate}%</p>
                                <div className="range-input-container">
                                    <input type="range" className="range-input" value={interestRate} onChange={handleSlider} />
                                    <span className="range-progress-shadow" style={{width: `${(interestRate * 48.2) /50}%`}}></span>
                                </div>
                            </FormGroupWithIcon>
                            <div className="flex-end-container">
                                <Button
                                    width="20"
                                    topMargin="3"
                                    hoverBgColor="#FED0B7"
                                    onClick={() => setIsSummarySection(true)}
                                >
                                    Continue
                                </Button>
                            </div>
                        </div>
                    </>
                }
                { isSummarySection &&
                    <div className="popup-main-container" data-aos="slide-up">
                        <div className="popup-title-wrapper">
                            <h4>{isLoanCalc ? 'Loan Summary' : 'Income Summary'}</h4>
                            <i className="fa fa-times" onClick={() => close(false)}></i>
                        </div>
                        <span className="popup-title-summary">
                            Fusce lacinia vulputate gravida massa nibh faucibus lorem vel etiam. 
                        </span>

                        <SideBarSummary borderType="dashed" topPadding="0">
                            <div className="calc-loan-data">
                                { isLoanCalc ?
                                    <DataWithLabel titleSize="1.3" textSize="1.9" fontWeight="700" className="data-with-label">
                                        <label>Amount</label>
                                        <span>&#8358;125,000</span>
                                    </DataWithLabel>
                                    :
                                    <DataWithLabel titleSize="1.3" textSize="1.9" fontWeight="700" className="data-with-label">
                                        <label>Portfolio Amount</label>
                                        <span>&#8358;75000</span>
                                    </DataWithLabel>
                                }
                                <DataWithLabel titleSize="1.3" textSize="1.9" fontWeight="700" className="data-with-label">
                                    <label>Duration</label>
                                    <span>12 Months</span>
                                </DataWithLabel>
                            </div>
                            <div className="calc-loan-data">
                                <DataWithLabel titleSize="1.3" textSize="1.9" fontWeight="700" className="data-with-label">
                                    <label>Total Payment</label>
                                    <span>&#8358;125,000</span>
                                </DataWithLabel>
                                <DataWithLabel titleSize="1.3" textSize="1.9" fontWeight="700" className="data-with-label">
                                    <label>Monthly Payment</label>
                                    <span>12 Months</span>
                                </DataWithLabel>
                            </div>
                            <div className="calc-loan-data">
                                <DataWithLabel titleSize="1.3" textSize="1.9" fontWeight="700" className="data-with-label">
                                    <label>Interest</label>
                                    <span>15%</span>
                                </DataWithLabel>
                                <DataWithLabel titleSize="1.3" textSize="1.9" fontWeight="700" className="data-with-label">
                                    <label>Interest Amount</label>
                                    <span>&#8358;50,000</span>
                                </DataWithLabel>
                            </div>
                        </SideBarSummary>
                        <FlexEndWrapper topMargin="5">
                            <span onClick={() => setIsSummarySection(false)}>Back</span>
                            <Button
                                width="23"
                                hoverBgColor="#FED0B7" 
                                leftMargin="4"
                                onClick={handleCreatePortfolio}
                            >
                                {isLoanCalc ? 'Get this Loan' : 'Create Loan Portfolio'}
                            </Button>
                        </FlexEndWrapper>
                    </div>
                }
            </SidePopup>
        </SidePopupContainer>
    )
}
