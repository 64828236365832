import { useState, useContext, useEffect} from 'react'
import utility, {required} from 'src/utils/utility'
import ApiContext from 'src/provider/API/call-service'
import AppInfoContext from 'src/provider/state-manager/appInfoProvider'
import UserContext from 'src/provider/state-manager/userProvider'
import VisibilityContext from 'src/provider/state-manager/visibilityProvider'
import { useNavigate } from 'react-router-dom'
import { AppLabel, AppText, Button, CustomContainer, Divider, FormGroup, FormGroupsWrapper, FormGroupWithIcon, ScreenContainer, ScreenTitle } from 'src/styles'
import { GoChevronDown } from 'react-icons/go'
import { images, cloudinaryData, idNumbercharLength } from 'src/provider/config/constant'
import helpers from 'src/utils/helpers'
import { NIN, NoAuthPopup, USSDCode, VNINViaApp, VNINCode } from 'src/popup'
import ReactTooltip from 'react-tooltip'

const MeansOfIdentificationUpdate = () => {
    const navigate = useNavigate()
    const {API} = useContext(ApiContext)
    const {notifier} = useContext(VisibilityContext)
    const {info} = useContext(AppInfoContext)
    const {user: {userData}} = useContext(UserContext)
    const [isValidated, setIsValidated] = useState(false)
    const [input, setInput] = useState({
        issuanceDate: info.onBoardingData?.issuanceDate || '', 
        expiryDate: info.onBoardingData?.expiryDate || '', 
        imagePath: info.onBoardingData?.imagePath || '', 
        type: info.onBoardingData?.type || '', 
        idNumber: info.onBoardingData?.idNumber || '', nin: ''
    })
    const [showResponse, setShowResponse] = useState(false)
    const [view, setView] = useState('')

    useEffect(() => {
        ReactTooltip.rebuild();
    }, [input.imagePath])
    
    useEffect(() => {
        const auth = input.type === '1' ? 
            helpers.inputValidator(input, ['expiryDate', 'issuanceDate', 'idNumber', 'nin']) :
            input.type === '3' ? helpers.inputValidator(input, ['expiryDate', 'nin'])
            : helpers.inputValidator(input, ['nin'])
        setIsValidated(auth.isValidated)
    }, [input])

    const handleInput = (e: React.ChangeEvent<HTMLInputElement|HTMLSelectElement>) => {
        if (e.target.name === 'type') {
            setInput({...input, [e.target.name]: e.target.value, idNumber: '', imagePath: '', issuanceDate: '', expiryDate: ''})
            if ( e.target.value === '1') setView('nin')
        }
        else if (e.target.name === 'idNumber') {
            if (e.target.value?.length <= idNumbercharLength[input.type as keyof typeof idNumbercharLength]) {
                setInput({...input, [e.target.name]: e.target.value.replace(' ', '')})
            }
        } 
        else setInput({...input, [e.target.name]: e.target.value})
    }

    async function handleFile (e: React.ChangeEvent<HTMLInputElement>) {
        if (e.target.files instanceof FileList) {
            //setInput({...input, imagePath: URL.createObjectURL(e.target.files[0])})
            if(!helpers.isValidFileType(e.target.value)) return notifier.show('Only JPG, PNG, and JPEG files are allowed')

            const data = new FormData()
            data.append('file', e.target.files[0])
            data.append('upload_preset', cloudinaryData.UPLOAD_PRESET)
            data.append('cloud_name', cloudinaryData.CLOUND_NAME)
            data.append('public_id', `CRENDLY_IDCARD-${userData?.userId}-${Date.now()}`)

            const url = await API.uploadToCloudinary(data, cloudinaryData.CLOUND_NAME)
            if (url) setInput({...input, imagePath: url})
        }
    }

    function handlePopClose() {
        setInput({...input, type: '', imagePath: '', nin: '', idNumber: ''})
        setView('')
    }

    async function handleNext () {
        if (input.type === '1' && (!input.idNumber && !input.nin)) {
            setView('nin')
            setInput({...input, idNumber: '', nin: ''})
        }
        else {
            if (['2', '3', '4'].includes(input.type)) {
                const isValid = helpers.validateOtherIdCards(input.idNumber, input.type)
                if (!isValid) return notifier.show('Kindly provide a valid IdCard number')
            }

            const payload = {...input, type: Number(input.type)}
            if (payload.idNumber) payload.nin = ''

            const response = await API.updateIdCard(payload, true)
            if (response && typeof(response) != 'object') setShowResponse(true)
            else {
                if (input.type === '1') {
                    if (response?.useVnin) return setView('vnin-via-app')
                    setInput({...input, type: '', idNumber: '', issuanceDate: '', expiryDate: '', imagePath: '', nin: ''})
                }
            }
        }
    }

    async function handleUserUpdate () {
        await API.getUserDetails()
        navigate('/dashboard/home')
    }

    return (
        <>
             <ScreenContainer>
                <div className="onboarding-container">
                    <ScreenTitle textSize="3" bottomMargin="1.5" topMargin="3" >
                        Means Of Identification
                    </ScreenTitle>
                    <AppText color="#D5D5D5" textSize="1.4" fontWeight="400" bottomMargin="2">
                        Let’s be sure you’re human.
                    </AppText>
                    <CustomContainer topMargin="5">
                        {
                            <>
                                <FormGroupWithIcon bottomMargin="1.5" topMargin="1">
                                    <label>ID card type {required}</label>
                                    <div className="input-with-icon">
                                        <select 
                                            name="type" 
                                            value={input.type} 
                                            onChange={handleInput}
                                            style={{color: input.type ? '#fff' : '#999'}}
                                        >
                                            <option value="">ID card type</option>
                                            <option value="4">International Passport</option>
                                            <option value="2">Driver's Licence</option>
                                            <option value="1">National Identity Card (NIN)</option>
                                            <option value="3">Permanent Voter's Card</option>
                                        </select>
                                        <GoChevronDown className="input-with-icon-tag" />
                                    </div>
                                </FormGroupWithIcon>
                                <Divider bgColor="#243673" margin="3" />
                            </>
                        }
                        
                        <div className="idcard-wrapper">
                            <div className="idCard-upload-block">
                            <AppText textSize='1' align="center" color='#E2E2E2' fontWeight='400'>Valid formats are JPG, PNG, and JPEG</AppText>
                                <div className="idCard-container">
                                    <img className="idcard-photo" src={input.imagePath || images.DEFAULT_IDCARD} />
                                </div>
                                <input type="file" onChange={handleFile} className="idcard-input" id="card" />
                                <AppLabel 
                                    hoverColor="#FED0B7" topMargin="2" htmlFor="card"
                                    align="center" decoration="underline" 
                                    style={{display: 'block'}}
                                >
                                    { !input.imagePath ? 'Upload ID card' : 'Upload another ID card'}
                                </AppLabel>
                            </div>
                        </div>
                        
                        { input.imagePath &&
                            <CustomContainer topMargin="4">
                                <FormGroup bottomMargin="1.5" topMargin="1">
                                    <label>{input.type === '1' ? (input.idNumber ? 'VNIN' : 'NIN') : 'ID Number'} {required}</label>
                                    <input 
                                        type="text" 
                                        name="idNumber" 
                                        placeholder= {input.type === '1' ? (input.idNumber ? 'vNIN number' : 'NIN number') : 'Id card number'}
                                        value={input.idNumber || (input.type === '1' ? input.nin : '')}
                                        disabled={input.type === '1' ? true : false} 
                                        onChange={handleInput} 
                                    />
                                </FormGroup>
                                <FormGroupsWrapper className="full-column-flex">
                                    { input.type != '1' &&
                                        <FormGroup bottomMargin="1.5" width="48.5" rightMargin="3" topMargin="1" className="full-width-flex">
                                            <label>Issue Date {required}</label>
                                            <input 
                                                type="date" 
                                                name="issuanceDate" 
                                                value={input.issuanceDate} 
                                                style={{color: input.issuanceDate ? '#fff' : '#999'}}
                                                onChange={handleInput} 
                                            />
                                        </FormGroup>
                                    }
                                    { !['1', '3'].includes(input.type) &&
                                        <FormGroup bottomMargin="1.5" width="48.5" topMargin="1" className="full-width-flex">
                                            <label>Expiry Date {required}</label>
                                            <input 
                                                type="date" 
                                                name="expiryDate" 
                                                value={input.expiryDate} 
                                                style={{color: input.expiryDate ? '#fff' : '#999'}}
                                                onChange={handleInput} 
                                            />
                                        </FormGroup>
                                    }
                                </FormGroupsWrapper>
                            </CustomContainer>
                        }
                    </CustomContainer>
                    <Button 
                        width="15" 
                        height="4.5" 
                        hoverBgColor="#FFC49D" 
                        topMargin="6"
                        bottomMargin="3"
                        className="loan-action-btn"
                        disabled={!isValidated ? true : false}
                        onClick={handleNext}
                    >
                        Submit
                    </Button>
                </div>
            </ScreenContainer>
            { showResponse &&
                <NoAuthPopup 
                    close={() => setShowResponse(false)} 
                    text="We’ll have our AI system verify your ID in a bit"
                    nextMethod={handleUserUpdate}
                    responseIcon={images.USER_RESPONSE}
                />
            }
            { view === 'nin' &&
                <NIN
                    onNIN={(val: string) => setInput({...input, nin: val})}
                    close={handlePopClose}
                    getCodeFromApp={() => setView('vnin-via-app')}
                    //onNext={() => setView('ussd-code')}
                    onNext={() => setView('')}
                />
            }
            {/* { view === 'ussd-code' &&
                <USSDCode
                    onBack={() => setView('nin')}
                    close={handlePopClose}
                    NIN={input.nin}
                    getCodeFromApp={() => setView('vnin-via-app')}
                    onNext={() => setView('vnin-code')}
                />
            }
            { view === 'vnin-code' &&
                <VNINCode
                    onBack={() => setView('ussd-code')}
                    close={handlePopClose}
                    onVNIN={(val: string|number) => setInput({...input, idNumber: val})}
                    onNext={() => setView('')}
                />
            } */}
            { view === 'vnin-via-app' &&
                <VNINViaApp
                    //onBack={() => setView('ussd-code')}
                    close={handlePopClose}
                    onVNIN={(val: string|number) => setInput({...input, idNumber: val})}
                    onNext={() => setView('')}
                />
            }
        </>
    )
}

export default utility.routeData('settings/means-of-identification', 'Means of identification Update', <MeansOfIdentificationUpdate/>)