import './style.scss'
import { useState, useEffect, useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import utility, { explain } from 'src/utils/utility'
import { 
    ScreenContainer, Button, OutlineButton, AppSpan, AbsoluteContainer, Icon, 
    FlexRow, AppLink, CustomContainer, AppText, ChartToggleTypes, 
    ChartToggleText, FlexColumn, Divider, Circle
} from 'src/styles'
import { images, loanTypes } from 'src/provider/config/constant'
import { ProgressCircleBar, LoanChart, PortfolioChart, InterestChart } from 'src/component'
import ApiContext from 'src/provider/API/call-service'
import UserContext from 'src/provider/state-manager/userProvider'
import LoanContext from 'src/provider/state-manager/loanProvider'
import AppInfoContext from 'src/provider/state-manager/appInfoProvider'
import VisibilityContext from 'src/provider/state-manager/visibilityProvider'
import { FiChevronRight, FiPrinter } from 'react-icons/fi'
import { FaPlus } from 'react-icons/fa'
import { BsThreeDotsVertical } from 'react-icons/bs'
import { CircularProgressbar } from 'react-circular-progressbar';
import { 
    WalletTopup, LoanCalculatorNew, 
    Statement, AddBank, AddCard, Withdraw, RequestList, 
    RepayLoan, LoanData, OpenLoanRequests, DecisionBox, CompleteProfile
} from 'src/popup'
import 'react-circular-progressbar/dist/styles.css';
import helpers from 'src/utils/helpers'
import { IoMdRefresh } from 'react-icons/io'


const Home: React.FC = () => {
    const navigate = useNavigate()
    const {API} = useContext(ApiContext)
    const {user} = useContext(UserContext)
    const {visibility: {isOnline}, notifier} = useContext(VisibilityContext)
    const {
        loan: {
            loanCreation, loanRequest, selectedLoanOffer, 
            currentLoans, currentLoanInfo, loanIdB4Cancel
        }, 
        setLoanProperty
    } = useContext(LoanContext)
    const {info: {transactions}, setInfoProperty, quickRequest} = useContext(AppInfoContext)
    const [isWalletTopup, setIsWalletTopup] = useState(false)
    const [hasOnboardingStatus, setHasOnboardingStatus] = useState(false)
    const [chartType, setChartType] = useState('loan')
    const [showLoanCalculation, setShowLoanCalculation] = useState(false)
    const [showStatementForm, setShowStatementForm] = useState(false)
    const [showAddBank, setShowAddBank] = useState(false)
    const [showAddCard, setShowAddCard] = useState(false)
    const [showWithdraw, setShowWithdraw] = useState(false)
    const [showRepayLoan, setShowRepayLoan] = useState(false)
    const [showLoanDetails, setShowLoanDetails] = useState(false)
    const [showOpenLoans, setShowOpenLoans] = useState(false)
    const [showDecisionBox, setShowDecisionBox] = useState(false)
    const [showCancelLoan, setShowCancelLoan] = useState(false)
    const [showRequestLoan, setShowRequestLoan] = useState(false)
    const [showRequestList, setShowRequestList] = useState(false)
    const [showCompleteProfile, setShowCompleteProfile] = useState(false)
    const [dropDownType, setDropDownType] = useState('')
    const [browseType, setBrowseType] = useState('lender')

    useEffect(() => {
        if (loanCreation?.tenor) setLoanProperty('loanCreation', {})
        if (loanRequest?.tenor) setLoanProperty('loanRequest', {})
        if (selectedLoanOffer?.tenor) setLoanProperty('selectedLoanOffer', {})
        if (quickRequest?.category) setInfoProperty('quickRequest', {})
    }, [])
    
    useEffect(() => {
        if(
            isWalletTopup || showLoanCalculation || showStatementForm || showAddBank 
            || showAddCard || showWithdraw || showRequestList || showRepayLoan 
            || showLoanDetails || showOpenLoans || showDecisionBox || showCompleteProfile
        ) {
          document.body.style.overflow = 'hidden'
        }
        else document.body.style.overflow = 'auto'
    }, [
        isWalletTopup, showLoanCalculation, 
        showStatementForm, showAddBank, 
        showAddCard, showWithdraw, showRequestList, 
        showRepayLoan, showLoanDetails, showOpenLoans, 
        showDecisionBox, showCompleteProfile
    ])

    useEffect(() => {
        if (user.userData?.customerId && !user.walletData?.phoneNumber) API.getWalletAccount()
        if (user.userData?.userId && !user?.formattedUserData?.bioDetail) API.getUserDetails()
        if (user.userData?.customerId && !user.chartsInfo?.loanRequestDetails) API.getInfoForCharts()
    }, [user?.userData])


    useEffect(() => {
        if (user.userData?.customerId && !currentLoans?.loanRequest?.loanRequestId) handleFetchCurrentLoans()
    }, [currentLoans?.loanRequest])

    useEffect(() => {
        if (user.userData?.customerId && !transactions?.length) API.fetchTransactions()
        if ((user.userData?.mobileNo || user.userData?.phoneNumber) && !user.formattedUserData?.bioDetail) API.updateLocationHistory()
        if ((user.userData?.mobileNo || user.userData?.phoneNumber) && !user.overallScore?.probabilityOfDefault) API.getUserOverallScore()
    }, [])

    useEffect(() => {
        setHasOnboardingStatus(!!user.onBoardingStatus?.percCompleted)
    }, [user.onBoardingStatus])

    async function handleFetchCurrentLoans () {
        const response = await API.getCurrentLoans()
        //if (response?.running) API.getLoanRequestInfo(response?.loanRequest?.loanRequestId) 
    }

    const showWalletTopup = (val: boolean) => setIsWalletTopup(val)

    async function handleQuickAccess (id: number, category: string) {
        const type = browseType === 'lender' ? 'offer' : 'loan'
        await setLoanProperty('currentProcessType', browseType === 'lender' ? 'lender' : 'borrower')
        await setInfoProperty('quickRequest', {type, id, category})
        navigate('/dashboard/quick-access-loans_offers')
    }

    async function handleDropDown (option: string) {
        setDropDownType('')
        if (option === 'repay-loan') setShowRepayLoan(true)
        else if (option === 'loan-details') setShowLoanDetails(true)
        else if (option === 'loan-requests') setShowOpenLoans(true)
        else if (option === 'submit-request') setShowDecisionBox(true)
        else if (option === 'cancel-request') setShowCancelLoan(true)
        else if (option === 'finish-loan-request') handleFinishLoanRequest()
    }

    async function handleCancelLoan () {
        const response = await API.cancelOpenLoanRequest(currentLoans?.loanRequest?.loanRequestId)
        if (response) setShowRequestLoan(true)
    }
    
    async function handleNewLoanRequest () {
        await API.createLoanRequestReplica(currentLoans?.loanRequest?.loanRequestId || Number(loanIdB4Cancel))
        // CREDIT DECISION CHECK PROCESS
        //if (response) navigate('/dashboard/loan-board/loan-offers')
    }

    async function handleSubmitLoanRequest () {
        const response = await API.cancelOpenLoanRequest(currentLoans?.loanRequest?.loanRequestId)
        if (response) {
            handleNewLoanRequest()
        }
    }

    async function handleFinishLoanRequest () {
        await setLoanProperty('currentProcessType', 'borrower')
        // await setLoanProperty('proposedLoanRequest', currentLoans?.loanRequest)
        // await API.getRequestLoanOffers(currentLoans?.loanRequest?.customerId)
        // navigate('/dashboard/loan-board/loan-offers') 
        
        handleSubmitLoanRequest()
    }

    function handleOffer_loanRequestCreation (type: string) {
        const { onBoardingStatus, formattedUserData, userData } = user;

        if (userData?.isAccountSuspended) {
            notifier.show('You are currently suspended from accessing this feature');
            return;
        }

        if (type === 'give') {
            if (!onBoardingStatus?.canGiveLoan) {
                notifier.show('Kindly provide your Signature, Facial Scan, Address, IdCard information and Bank details to access this feature', 'warning');
                setShowCompleteProfile(true);
                return;
            }

            navigate('/dashboard/loan-creation/loan-type');
            return;
        }

        if (type === 'take') {
            if (onBoardingStatus?.percCompleted !== 100) {
                notifier.show('Kindly complete your profile to access this feature', 'warning');
                setShowCompleteProfile(true);
                return;
            }

            if (formattedUserData?.declineLoanRequest) {
                notifier.show('Unfortunately, you are not eligible for a loan at the moment');
                return;
            }

            navigate('/dashboard/get-loan/loan-type');
            return;
        }
    }

    function handleTopup_withdrawal (type: string) {
        if (user.userData?.isAccountSuspended) {
            notifier.show('You are currently suspended from accessing this feature');
        }
        else if (!user.onBoardingStatus?.canGiveLoan) {
            notifier.show('Kindly provide your Signature, Facial Scan, Address, IdCard information and Bank details to access this feature', 'warning')
            setShowCompleteProfile(true)
        }
        else {
            if (type === 'topup') showWalletTopup(true)
            else if (type === 'withdrawal') setShowWithdraw(true)
        }
    }

    async function handleRefresh() {
        await API.getWalletAccount()
        API.getCurrentLoans()
    }
    
    return (
        <>
            <ScreenContainer>
                { (hasOnboardingStatus && user.onBoardingStatus?.percCompleted != 100) &&
                    <div className="complete-profile-wrapper">
                        <div className="complete-profile-container">
                            <FlexRow 
                                justifyContent="flex-start"
                                width="auto" sizeUnit="" 
                                rightMargin="1.5" 
                                className="complete-level-container"
                            >
                                <Icon src={images.SM_LOGO_TEA} />
                                <CustomContainer leftMargin="1.5">
                                    <AppText textSize="1.3" bottomMargin="1">Your profile is {user.onBoardingStatus?.percCompleted || 0}% completed</AppText>
                                    <CustomContainer 
                                        width="34" height="0.9" 
                                        bgColor="#040F35" radius="0.2" 
                                        className="complete-level"
                                    >
                                        <CustomContainer width={`${user.onBoardingStatus?.percCompleted || 0}`} sizeUnit="%" height="0.9" bgColor="#6DE7B4"/>
                                    </CustomContainer>
                                </CustomContainer>
                            </FlexRow>
                            <AppLink 
                                textSize="1.3" 
                                hoverColor="#FED0B7" 
                                className="complete-level-text"
                                onClick={() => setShowCompleteProfile(true)}
                            >
                                Complete your profile
                            </AppLink>
                        </div>
                        {/* <FlexRow justifyContent="flex-start" leftMargin='5' topMargin='0.5'>
                            <Circle 
                                bgColor="#F86C44"
                                size="0.7"
                                rightMargin="0.5"
                            />
                            <AppSpan textSize="1.2" color="#F86C44" fontWeight="400">
                                {user.onBoardingStatus?.infoToProvide}
                            </AppSpan>
                        </FlexRow> */}
                    </div>
                }
                
                <FlexColumn className='notif-wrapper'>
                    <FlexRow width='auto' sizeUnit='' alignItems='center'>
                        {explain(
                            `See all Loan requests you get as a Lender and Loan offers 
                            you have requested for. Please try to attend to Loan requests
                            you get as a Lender before the timer attached to the 
                            request runs out.`, false, '0'
                        )}
                        <Icon src={images.NOTI_FLASH} leftMargin='1.2' />
                        <AppSpan textSize='1.5' leftMargin='1'>My Requests</AppSpan>
                        <AppLink 
                            textSize="1.4" 
                            hoverColor="#FED0B7" 
                            decoration='none'
                            hasBottomBorder={true}
                            fontWeight='500'
                            leftMargin='2'
                            onClick={() => setShowRequestList(true)}
                        >
                            View all
                        </AppLink>
                    </FlexRow>
                </FlexColumn>
                <div className="home-content-row-container">
                    <div className="home-content-row-1">
                        <div className="wallet-balance-wrapper">
                            <label>My Funds</label>
                            <div className="wallet-balance">
                                <AbsoluteContainer bottom="-6" right="0" className="wallet-c_logo">
                                    <Icon className="wallet-crendly-logo" src={images.C_LOGO_GREEN} />
                                </AbsoluteContainer>
                                <FlexRow justifyContent='flex-start' wrap='wrap' className='balance-type-wrapper'>
                                    <CustomContainer width='auto' size=' ' rightPadding='1.5'>
                                        <AppSpan fontWeight='400' textSize='1.4'>Available Balance</AppSpan>
                                        {explain(
                                            `This is your balance which you can actively carry out activities 
                                            with such as withdrawals, repayments e.t.c`, true
                                        )}
                                        <AppText textSize='1.4' style={{marginTop: '-0.3rem'}}>&#8358;{utility.moneyFormat(user.walletData?.balance || '0', 2)}</AppText>
                                    </CustomContainer>
                                    <CustomContainer className='total-balance-container' width='auto' size=' ' rightPadding='1.5'>
                                        <AppSpan fontWeight='400' textSize='1.4' color='rgba(255, 255, 255, 0.5)'>Total Balance</AppSpan>
                                        {explain(
                                            `This is your balance which shows combination of accessible balance 
                                            and the balance set aside to finance your portfolio.`, true
                                        )}
                                        <AppText textSize='1.4' style={{marginTop: '-0.3rem'}} color='rgba(255, 255, 255, 0.5)'>&#8358;{utility.moneyFormat(user.walletData?.totalBalance || '0', 2)}</AppText>
                                    </CustomContainer>
                                </FlexRow>
                                <AbsoluteContainer top='1' right='1'>
                                    <AppSpan 
                                        hoverColor='#FED0B7' color='#6DE7B4' cursor='pointer'
                                        onClick={handleRefresh}
                                    >
                                        <IoMdRefresh 
                                            style={{fontSize: '2.5rem'}}
                                            data-tip={'Click to get updated balances and loan request status'}
                                            data-class=""
                                        />
                                    </AppSpan>
                                </AbsoluteContainer>
                                
                                <FlexRow justifyContent="flex-start" topMargin='5' className='wallet-home-btns'>
                                    <Button 
                                        hoverBgColor="#FED0B7" 
                                        width="16" 
                                        height="4.2" 
                                        rightMargin="1.5"
                                        className="loan-action-btn"
                                        disabled={!isOnline ? true : false}
                                        onClick={() => handleTopup_withdrawal('topup')}
                                    >
                                        <FaPlus style={{fontSize: '2rem'}} />
                                        <AppSpan textSize="1.4" leftPadding="1" color="auto">Top up</AppSpan>
                                    </Button>
                                    <OutlineButton 
                                        color="#6DE7B4" 
                                        width="16" 
                                        height="4.2"  
                                        hoverBgColor="#FED0B7"
                                        onClick={() => handleTopup_withdrawal('withdrawal')}
                                    >
                                        <FiPrinter style={{fontSize: '2rem'}} />
                                        <AppSpan textSize="1.4" leftPadding="1" color="auto">Withdraw</AppSpan>
                                    </OutlineButton>
                                </FlexRow>
                            </div>
                        </div>
                        <div className="loan-option-card-wrapper text-cursor">
                            <div 
                                className="loan-option-card-1" 
                                onClick={() => handleOffer_loanRequestCreation('give')}
                            >
                                {/* <img className="loan-avatar" src={images.CREATE_LOAN_AVATAR} /> */}
                                <Circle size='4' bottomMargin='2.5'>
                                    <Icon src={images.CREATE_LOAN_AVATAR} />
                                </Circle>
                                <p>Give Loan</p>
                                <div className="loan-option-card-text-wrapper">
                                    <p>Lend to credible people and earn interest on your terms.</p>
                                    <FiChevronRight className="icon-chevron-right" />
                                </div>
                            </div>
                            { ((!currentLoans?.running && !currentLoans?.loanRequest) || !currentLoans?.loanRequest?.loanRequestId) &&
                                <div 
                                    className="loan-option-card-2" 
                                    onClick={() => handleOffer_loanRequestCreation('take')}
                                >
                                    {/* <img className="loan-avatar" src={images.GET_LOAN_AVATAR} /> */}
                                    <Circle size='4.5' bottomMargin='2'>
                                        <Icon  src={images.GET_LOAN_AVATAR} />
                                    </Circle>
                                    <p>Take Loan</p>
                                    <div className="loan-option-card-text-wrapper">
                                        <p>Borrow what you need at incredible speed </p>
                                        <FiChevronRight className="icon-chevron-right" />
                                    </div>
                                </div>
                            }
                            
                            {/*====================== OPEN LOAN CARD ===========================*/}
                            { (!currentLoans?.running && currentLoans?.loanRequest?.loanRequestId) &&
                                <div className="loan-option-card-open">
                                    <AbsoluteContainer top="1.8" right="1">
                                        <FlexRow width='auto' sizeUnit=''>
                                            <Button 
                                                width='auto' sizeUnit='' height='2.2'
                                                leftPadding='1.5' rightPadding='1.5'
                                                borderColor='#C1CDF6' bgColor='#081952'
                                                color='#C1CDF6' titleSize='1' fontWeight='600'
                                                rightMargin='0.3'
                                            >
                                                Open Loan
                                            </Button>
                                            <BsThreeDotsVertical onClick={() => setDropDownType(dropDownType ? '' :'open')} />
                                        </FlexRow>
                                    </AbsoluteContainer>
                                    <CustomContainer width='auto' sizeUnit=''>
                                        <AppText textSize='1.4'>{currentLoans?.loanRequest?.loanOffer?.loanTypeName || currentLoans?.loanRequest?.loanTypeName}</AppText>
                                        <AppSpan color='#6DE7B4' textSize='1.7'>{currentLoans?.loanRequest?.loanOffer?.interest || currentLoans?.loanRequest?.interest}% p.m</AppSpan>
                                    </CustomContainer>
                                    <Divider margin="1" />
                                    <CustomContainer topMargin='-1'>
                                        <div className="flex-row-space-between margin-vertical-1-5">
                                            <div className="loan-offer-label-text">
                                                <label>Amount</label>
                                                <p>N{utility.moneyFormat(currentLoans?.loanRequest?.loanOffer?.amountPerPerson || currentLoans?.loanRequest?.amount)}</p>
                                            </div>
                                            <div className="loan-offer-label-text width-10-rem">
                                                <label>Duration</label>
                                                <p>{(currentLoans?.loanRequest?.loanOffer?.tenor || currentLoans?.loanRequest?.loanOffer?.tenor === 0) ? currentLoans?.loanRequest?.loanOffer?.tenor : currentLoans?.loanRequest?.tenor} {currentLoans?.loanRequest?.loanOffer?.tenorType?.toLowerCase() || currentLoans?.loanRequest?.tenorType?.toLowerCase()}(s)</p>
                                            </div>
                                        </div>
                                        <div className="flex-row-space-between margin-vertical-1-5">
                                            <div className="loan-offer-label-text">
                                                <label>Payment</label>
                                                <p>{(currentLoans?.loanRequest?.loanOffer?.rateId || currentLoans?.loanRequest?.rateId) === 1 ? 'Installment' : 'One-off'}</p>
                                            </div>
                                            {/* <div className="loan-offer-label-text width-10-rem">
                                                <label>Lender's rep</label>
                                                <p style={{color: '#6DE7B4'}}>{89.2}%</p>
                                            </div> */}
                                        </div>
                                    </CustomContainer>

                                    { dropDownType === 'open' &&
                                        <AbsoluteContainer top='-10' right='1'>
                                            <CustomContainer 
                                                bgColor='#081952' width='18'
                                                radius='0.4'
                                                topPadding='1' bottomPadding='1'
                                                style={{boxShadow: '0px 10px 50px rgba(0, 0, 0, 0.5)'}}
                                            >
                                                { currentLoans?.loanOffers?.length > 0 &&
                                                    <>
                                                        <FlexRow className='sort-select-option' onClick={() => handleDropDown('loan-requests')}>
                                                            <AppSpan fontWeight="400" textSize="1.3" cursor='pointer'>View All Loan Requests</AppSpan>
                                                        </FlexRow>
                                                        <FlexRow className='sort-select-option' onClick={() => handleDropDown('submit-request')}>
                                                            <AppSpan fontWeight="400" textSize="1.3" cursor='pointer'>Submit Loan Request</AppSpan>
                                                        </FlexRow>
                                                    </>
                                                }
                                                
                                                { (!currentLoans?.loanOffers || !currentLoans?.loanOffers?.length) &&
                                                    <FlexRow className='sort-select-option' onClick={() => handleDropDown('finish-loan-request')}>
                                                        <AppSpan fontWeight="400" textSize="1.3" color='#6DE7B4' cursor='pointer'>Finish Loan Request</AppSpan>
                                                    </FlexRow>
                                                }
                                                <FlexRow className='sort-select-option' onClick={() => handleDropDown('cancel-request')}>
                                                    <AppSpan fontWeight="400" textSize="1.3" color='#FF0062' cursor='pointer'>Cancel Loan Request</AppSpan>
                                                </FlexRow>
                                            </CustomContainer>
                                        </AbsoluteContainer>
                                    }
                                </div>
                            }

                            {/*====================== RUNNING LOAN CARD ===========================*/}
                            { (currentLoans?.running && !!currentLoans?.loanRequest?.loanRequestId) &&
                                <div className="loan-option-card-open">
                                    <AbsoluteContainer top="1.8" right="1">
                                        <FlexRow width='auto' sizeUnit=''>
                                            <Button 
                                                width='auto' sizeUnit='' height='2.2'
                                                leftPadding='1.5' rightPadding='1.5'
                                                borderColor='#FED0B7' bgColor='#081952'
                                                color='#FED0B7' titleSize='1' fontWeight='600'
                                                rightMargin='0.3'
                                            >
                                                Running
                                            </Button>
                                            <BsThreeDotsVertical onClick={() => setDropDownType(dropDownType ? '' :'running')} />
                                        </FlexRow>
                                    </AbsoluteContainer>
                                    <CustomContainer width='auto' sizeUnit=''>
                                        <AppText textSize='1.1' color='#E2E2E2' fontWeight='400'>{currentLoans?.loanRequest?.loanOffer?.loanTypeName || currentLoans?.loanRequest?.loanTypeName}</AppText>
                                        <AppSpan textSize='1.8'>
                                            N{utility.moneyFormat(currentLoans?.loanRequest?.loanOffer?.amountPerPerson || currentLoans?.loanRequest?.amount)}
                                            <AppSpan color='#6DE7B4' textSize='0.9' leftPadding='1' fontWeight='400' >{(currentLoans?.loanRequest?.loanOffer?.interest || currentLoans?.loanRequest?.loanOffer?.interest === 0) ? currentLoans?.loanRequest?.loanOffer?.interest : currentLoans?.loanRequest?.interest}%</AppSpan>
                                        </AppSpan>
                                    </CustomContainer>
                                    <FlexRow justifyContent='flex-start' topMargin='1'>
                                        <CustomContainer width='auto' sizeUnit='' rightMargin='2' >
                                            <AppText textSize='1.1' color='#E2E2E2' fontWeight='400'>Interest</AppText>
                                            <AppSpan textSize='1.5'>N{utility.moneyFormat((currentLoans?.loanRequest?.loanOffer?.interestAmount || currentLoans?.loanRequest?.loanOffer?.interestAmount === 0) ? currentLoans?.loanRequest?.loanOffer?.interestAmount : currentLoans?.loanRequest?.interestAmount)}</AppSpan>
                                        </CustomContainer>
                                        <CustomContainer width='auto' sizeUnit='' >
                                            <AppText textSize='1.1' color='#E2E2E2' fontWeight='400'>Duration</AppText>
                                            <AppSpan textSize='1.5'>
                                                {(currentLoans?.loanRequest?.loanOffer?.tenor || currentLoans?.loanRequest?.loanOffer?.tenor === 0) ? currentLoans?.loanRequest?.loanOffer?.tenor : currentLoans?.loanRequest?.tenor} {currentLoans?.loanRequest?.loanOffer?.tenorType?.toLowerCase() || currentLoans?.loanRequest?.tenorType?.toLowerCase()}(s)
                                            </AppSpan>
                                        </CustomContainer>
                                    </FlexRow>
                                    <Divider margin="1" />
                                    <FlexRow justifyContent='flex-start' topMargin='1'>
                                        <CustomContainer width='auto' sizeUnit='' rightMargin='3' >
                                            <AppText textSize='1.1' color='#E2E2E2' fontWeight='400'> Total Amount Repayable</AppText>
                                            <AppSpan textSize='1.8'>N{utility.moneyFormat((currentLoans?.loanRequest?.loanOffer?.totalRepaymentBorrower || currentLoans?.loanRequest?.loanOffer?.totalRepaymentBorrower === 0) ? currentLoans?.loanRequest?.loanOffer?.totalRepaymentBorrower : currentLoans?.loanRequest?.totalAmountToBeRepaid)}</AppSpan>
                                        </CustomContainer>
                                        <CustomContainer width='auto' sizeUnit='' >
                                            <AppText textSize='1.1' color='#E2E2E2' fontWeight='400'> Amount Remaining </AppText>
                                            <AppSpan textSize='1.8'>N{utility.moneyFormat((currentLoans?.amountRemaining || currentLoans?.amountRemaining === 0) ? currentLoans?.amountRemaining : currentLoanInfo?.amountRemaining)}</AppSpan>
                                        </CustomContainer>
                                    </FlexRow>
                                    { dropDownType === 'running' &&
                                        <AbsoluteContainer top='-10' right='1'>
                                            <CustomContainer 
                                                bgColor='#081952' width='15'
                                                radius='0.4'
                                                topPadding='1' bottomPadding='1'
                                                style={{boxShadow: '0px 10px 50px rgba(0, 0, 0, 0.5)'}}
                                            >
                                                <FlexRow className='sort-select-option' onClick={() => handleDropDown('loan-details')}>
                                                    <AppSpan fontWeight="400" textSize="1.3" cursor='pointer'>View Loan Details</AppSpan>
                                                </FlexRow>
                                                <FlexRow className='sort-select-option' onClick={() => handleDropDown('repay-loan')}>
                                                    <AppSpan fontWeight="400" textSize="1.3" cursor='pointer'>Repay Loan</AppSpan>
                                                </FlexRow>
                                            </CustomContainer>
                                        </AbsoluteContainer>
                                    }
                                </div>
                            }
                            
                        </div>
                        <div className="trends-wrapper">
                            <label>Trends</label>
                            <div className="trends">
                                <div className="trend-chart-toggle">
                                    <ChartToggleTypes type={chartType}>
                                        <ChartToggleText
                                            isPicked={chartType === 'loan' ? true : false}
                                            onClick={() => setChartType('loan')}
                                        >
                                            Loan
                                        </ChartToggleText>
                                        <ChartToggleText
                                            isPicked={chartType === 'portfolio' ? true : false}
                                            onClick={() => setChartType('portfolio')}
                                        >
                                            Portfolio
                                        </ChartToggleText>
                                        <ChartToggleText
                                            isPicked={chartType === 'interest' ? true : false}
                                            onClick={() => setChartType('interest')}
                                        >
                                            Interest
                                        </ChartToggleText>
                                        <div className="chart-toggler"></div>
                                    </ChartToggleTypes>
                                </div>
                                { chartType === "portfolio" &&
                                    <PortfolioChart/>
                                }

                                { chartType === 'loan' &&
                                    <LoanChart/>
                                }

                                { chartType === 'interest' &&
                                    <InterestChart />
                                }

                            </div>
                        </div>
                    </div>
                    <div className="home-content-row-2">
                        <div className="score-wrapper">
                            <div className="score-content-wrapper">
                                {/* <ProgressCircleBar
                                    size="6"
                                    strokeWidth="0.4"
                                    barFontSize="1.2"
                                    bgColor="#081952"
                                    barBgColor="#F88544"
                                    percentage="45"
                                /> */}
                                <div style={{width: '6rem', height: '6rem'}}>
                                    <CircularProgressbar 
                                        value={user.overallScore?.grandTotal || user.formattedUserData?.overallScore?.grandTotal || 0} 
                                        text={`${Number(user.overallScore?.grandTotal || user.formattedUserData?.overallScore?.grandTotal || 0).toFixed(1)}%`} 
                                        styles={{
                                            root: {},
                                            path: {
                                                stroke: `#F88544`,
                                                strokeLinecap: 'butt',
                                                transition: 'stroke-dashoffset 0.5s ease 0s',
                                                transform: 'rotate(0.25turn)',
                                                transformOrigin: 'center center',
                                            },
                                            trail: {
                                                stroke: '#081952',
                                                strokeLinecap: 'butt',
                                                transform: 'rotate(0.25turn)',
                                                transformOrigin: 'center center',
                                            },
                                            text: {
                                                fill: '#fff',
                                                fontSize: '2rem',
                                                fontWeight: 700
                                            }
                                        }} 
                                    />
                                </div>
                                
                                <div className="score-data-container">
                                    <span>Crendly Score</span>
                                    <div className="score-data-wrapper">
                                        <div className="score-data">
                                            <span className="score-data-point">{currentLoans?.userLoanInformation?.numberOfLoanOffersAccepted}</span>
                                            <span className="score-data-label">Loans Taken</span>
                                        </div>
                                        <div className="score-data">
                                            <span className="score-data-point purple-border">{currentLoans?.userLoanInformation?.numberOfLoanRequestsCompleted}</span>
                                            <span className="score-data-label">Loans Repaid</span>
                                        </div>
                                    </div>
                                </div>
                                <AbsoluteContainer top="1" right="1.1" >
                                {explain(
                                    `Your crendly score depicts your credit worthiness. 
                                    The higher your score, the better you look to potential lenders.`
                                )}
                                </AbsoluteContainer>
                            </div>
                            <div className="score-content-wrapper">
                                <div style={{width: '6rem', height: '6rem'}}>
                                    <CircularProgressbar value={0} text={`${Number(user.reputationScore?.grandTotal || 0).toFixed(1)}%`} 
                                        styles={{
                                            root: {},
                                            path: {
                                                stroke: `#6DE7B4`,
                                                strokeLinecap: 'butt',
                                                transition: 'stroke-dashoffset 0.5s ease 0s',
                                                transform: 'rotate(0.25turn)',
                                                transformOrigin: 'center center',
                                            },
                                            trail: {
                                                stroke: '#081952',
                                                strokeLinecap: 'butt',
                                                transform: 'rotate(0.25turn)',
                                                transformOrigin: 'center center',
                                            },
                                            text: {
                                                fill: '#fff',
                                                fontSize: '2rem',
                                                fontWeight: 700,
                                            }
                                        }} 
                                    />
                                </div>
                                <div className="score-data-container">
                                    <span>Reputation Score</span>
                                    <div className="score-data-wrapper">
                                        <div className="score-data">
                                            <span className="score-data-point pink-border">{currentLoans?.userLoanInformation?.numberOfLoanRequestsGranted}</span>
                                            <span className="score-data-label">Loans Disbursed</span>
                                        </div>
                                        <div className="score-data">
                                            <span className="score-data-point purple-border">{currentLoans?.userLoanInformation?.numberOfLoanOffersCompleted}</span>
                                            <span className="score-data-label">Loans Collected</span>
                                        </div>
                                    </div>
                                </div>
                                <AbsoluteContainer top="1" right="1.1" >
                                {explain(
                                    `Your reputation score depicts your reputation compare to 
                                    other lenders. The higher your score, the better you look 
                                    to potential borrowers.`
                                )}
                                </AbsoluteContainer>
                            </div>
                        </div>
                        <div className="quick-box-container">
                            <label className="quick-box-title">Quick Box</label>
                            <div className="quick-box-wrapper">
                                <div className="quick-box box-style-plus" onClick={ () => setShowLoanCalculation(true)}>
                                    <div className="box-icon-wrapper">
                                        <img src={images.CALCULATOR} />
                                    </div>
                                    <span>Calculator</span>
                                </div>
                                <div className="quick-box" onClick={ () => setShowStatementForm(true)}>
                                    <div className="box-icon-wrapper">
                                        <img src={images.STATEMENT} />
                                    </div>
                                    <span>Statement</span>
                                </div>
                                <div className="quick-box box-style-plus" onClick={() => setShowAddBank(true)}>
                                    <div className="box-icon-wrapper">
                                        <img src={images.BANK} />
                                    </div>
                                    <span>Bank</span>
                                </div>
                                <div className="quick-box" onClick={() => setShowAddCard(true)}>
                                    <div className="box-icon-wrapper">
                                        <img src={images.CARD} />
                                    </div>
                                    <span>Cards</span>
                                </div>
                            </div>
                        </div>
                        <div className="trans-activity">
                            <div className="trans-label-wrapper">
                                <label>Transaction Activities</label>
                                { transactions?.length > 0 ?
                                    <AppLink 
                                        textSize="1.6" 
                                        hoverColor="#FED0B7" 
                                        decoration='none'
                                        hasBottomBorder={true}
                                        fontWeight='700'
                                        bottomMargin='1.2'
                                        leftMargin='2'
                                        onClick={() => navigate('/dashboard/transactions')}
                                    >
                                        View All
                                    </AppLink>
                                    : <label></label> 
                                }
                            </div>
                            <div className="trans-content-wrapper">
                                { transactions?.length > 0 ?
                                    transactions?.map((item: any, index: number) => {
                                        let typeColor = item.operation === 'Credit' ? '#6DE7B4' : '#FF0062'
                                        let dateTime = helpers.splitDateTime(item?.dateAdded)
                                        return (
                                            index <=5 &&
                                            <div className="trans-content" key={index}>
                                                <div className="content-label-wrapper">
                                                    <img src={utility.getStatementIcon(item?.transactionType)} />
                                                    <div className="trans-content-label">
                                                        <label>{utility.toTitleCase(item?.transactionType)}</label>
                                                        <span>{dateTime?.date}, {dateTime?.completeTime}</span>
                                                    </div>
                                                </div>
                                                <div className="content-amt-wrapper">
                                                    <label>&#8358;{utility.moneyFormat(item?.amount)}</label>
                                                    <span style={{color: typeColor}}>{item?.operation}</span>
                                                </div>
                                            </div>
                                        )
                                    })
                                    :
                                    <FlexColumn topMargin="10" bottomMargin="10" >
                                        <Icon src={images.NO_TRANSACTION} width='6' />
                                        <AppText textSize="1.8" width="25" topMargin="3" align="center">No Transactions yet</AppText>
                                        <AppSpan color="#E2E2E2" textSize="1.3" fontWeight="400" topMargin="1" align="center" width="26">
                                            You haven’t made any transactions yet.
                                        </AppSpan>
                                    </FlexColumn>
                                   
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <div className="loan-category-wrapper">
                    <div style={{position: 'relative'}}>
                        <div className='toggle-browse-type-wrapper'>
                            <CustomContainer className='browse-type-text-container'>
                                <AppText color='#fff' textSize='2.3'>Browse Loans as a {browseType === 'lender' ? 'Lender' : 'Borrower'}</AppText>
                                { browseType === 'lender' ? 
                                    <AppText textSize='1.4' fontWeight='400'>As a Lender, easily see borrowers loan request and give out loans.</AppText>
                                    : <AppText textSize='1.4' fontWeight='400'>As a Borrower, easily see loan offers by lender and find one that suit your needs.</AppText>
                                }
                                
                            </CustomContainer>
                            <div className='browse-type-divider'></div>
                        </div>
                        <div className='browse-toggle-abs'>
                            <div className="browse-type-toggle">
                                <span 
                                    style={{fontWeight: browseType === 'lender' ? 700 : 400}}
                                    onClick={() => setBrowseType('lender')}
                                >
                                    Lender
                                </span>
                                <span 
                                    style={{fontWeight: browseType === 'borrower' ? 700 : 400}}
                                    onClick={() => setBrowseType('borrower')}
                                >
                                    Borrower
                                </span>
                                <div className="browse-type-toggle-shadow" style={{left: browseType === 'lender' ? 0 : '51%'}}></div>
                            </div>
                        </div>
                    </div>
                    
                    <div className="loan-category">
                        {
                            loanTypes?.map((item: any, index: number) => {
                                return (
                                    <div 
                                        className={`loan-category-content ${browseType === 'lender' ? 'offer' : 'loan'}-category${index+1}`} key={index}
                                        //style={{width: item === 'others' ? '100%' : '27rem'}}
                                    >
                                        <div className="loan-category_text-wrapper">
                                            <p>{utility.toTitleCase(item)}</p>
                                            <button 
                                                onClick={() => handleQuickAccess(index+1, item)}
                                            >
                                                View all
                                            </button>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
                <div className="community-wrapper">
                    <AppText color='#fff' textSize='2.3' bottomMargin='1'>Inside Crendly's Community</AppText>
                    <div className="community">
                        <div className="community-content community1">
                            <div className="community_text-wrapper">
                                <p>Looking for ways to cut your monthly spend? </p>
                                <button>Stories</button>
                            </div>
                        </div>
                        <div className="community-content community2">
                            <div className="community_text-wrapper">
                                <p>Not sure how to travel for summer?</p>
                                <button>Guide</button>
                            </div>
                        </div>
                        <div className="community-content community3">
                            <div className="community_text-wrapper">
                                <p>Proofing your business idea with Crendly</p>
                                <button>Interview</button>
                            </div>
                        </div>
                    </div>
                </div>
            </ScreenContainer>
            { isWalletTopup &&
                <WalletTopup close={() => showWalletTopup(false)} />
            }
            { showLoanCalculation &&
                <LoanCalculatorNew close={setShowLoanCalculation} />
            }
            { showStatementForm &&
                <Statement close={() => setShowStatementForm(false)} />
            }
            { showAddBank &&
                <AddBank close={() => setShowAddBank(false)} hasBank={user?.formattedUserData?.primaryBankAccount?.accountNumber} />
            }
            { showAddCard &&
                <AddCard close={setShowAddCard} hasCard={false} />
            }
            { showWithdraw &&
                <Withdraw close={setShowWithdraw} />
            }
            { showRequestList &&
                <RequestList close={() => setShowRequestList(false)} />
            }
            { showRepayLoan &&
                <RepayLoan 
                    close={() => setShowRepayLoan(false)}
                    amountToPay={null}
                />
            }
            { showLoanDetails &&
                <LoanData 
                    close={() => setShowLoanDetails(false)}
                    data={currentLoans?.loanRequest}
                />
            }
            { showOpenLoans &&
                <OpenLoanRequests 
                    close={() => setShowOpenLoans(false)}
                />
            }
            { showDecisionBox &&
                <DecisionBox 
                    close={() => setShowDecisionBox(false)}
                    title='Take new similar loans'
                    subheading='You are trying to take a new loan while you still have an open loan request(s). If you choose to continue, we will cancel your current loan request(s) so you can choose a new one. '
                    noText="No, not now"
                    yesText="Yes, go ahead"
                    questionText='Do you still wish to go ahead?'
                    yesMethod={handleSubmitLoanRequest}
                />
            }
            { showCancelLoan &&
                <DecisionBox
                    close={() => setShowCancelLoan(false)}
                    title='Cancel all loan request'
                    subheading='Are you sure you want to cancel all your loan requests?'
                    noText="No, Don't cancel"
                    yesText="Yes, Cancel"
                    yesMethod={handleCancelLoan}
                />
            }
            { showRequestLoan &&
                <DecisionBox
                    close={() => setShowRequestLoan(false)}
                    title='All loan requests cancelled'
                    subheading='You have cancelled all loan requests.'
                    noText="No, not now"
                    yesText="Yes, Take a new loan"
                    icon={images.SUCCESS_RESPONSE}
                    yesMethod={handleNewLoanRequest}
                />
            }
            { showCompleteProfile &&
                <CompleteProfile
                    close={() => setShowCompleteProfile(false)} 
                    data={user.onBoardingStatus}
                    addBank={() => setShowAddBank(true)}
                />
            }
        </>
    );
}

export default utility.routeData('home', 'Home', <Home/>)
