import { useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import ApiContext from 'src/provider/API/call-service'
import { 
    ResponderContentWrapper, 
    ResponderIconCircle, 
    ResponderIconHolder,
    ResponderTitle, 
} from 'src/component/Responder/style'
import { CenterPopupContainer, CenterPopup, Button, Icon, FlexRow, AppText, AppSpan, BorderlessButton } from 'src/styles'
import { images } from 'src/provider/config/constant'


export const DecisionBox: React.FC<any> = ({close, icon, title, subheading, questionText, yesText, noText, noMethod, yesMethod, noColor}) => {
    async function handleMethod (type: string) {
        type === 'Yes' ?  yesMethod && await yesMethod() : noMethod && await noMethod()
        close()
    }

    return (
        <CenterPopupContainer zIndex="8">
            <CenterPopup data-aos="slide-down">
                <i className="fa fa-times responder-cancel" onClick={close}></i>
                    <ResponderContentWrapper>
                        <ResponderIconCircle>
                            <Icon src={icon || images.WARNING_RESPONSE} />
                        </ResponderIconCircle>
                        <ResponderIconHolder></ResponderIconHolder>
                        <ResponderTitle style={{marginTop: '1rem'}}>{title}</ResponderTitle>
                        <AppText topMargin="0" fontWeight="400" align="center" textSize='1.4'>
                            {subheading}
                        </AppText>
                        <AppText topMargin='7' align="center" textSize='1.4'>{questionText}</AppText>
                        <FlexRow justifyContent="flex-end" className="loan-acceptance-btns" topMargin="5">
                            <BorderlessButton hoverColor='#FED0B7' color={noColor || ''} titleSize='1.4' onClick={() => handleMethod('No')}>
                                {noText}
                            </BorderlessButton>
                            <Button 
                                width="17" 
                                height="4"
                                hoverBgColor="#FED0B7"
                                leftMargin="3.5"
                                onClick={() => handleMethod('Yes')}
                            >
                                {yesText}
                            </Button>
                        </FlexRow>
                    </ResponderContentWrapper>
            </CenterPopup >
        </CenterPopupContainer>
    )
}
