import { useContext } from 'react'
import LoanContext from 'src/provider/state-manager/loanProvider'
import { images } from 'src/provider/config/constant'
import { SidePopupContainer, SidePopup, Divider  } from "src/styles"
import utility from 'src/utils/utility'


export const SelectedOfferDetails: React.FC<any> = ({close, data}) => {
  const {loan: {currentProcessType}} = useContext(LoanContext)
  return (
    <>
      <SidePopupContainer >
        <SidePopup data-aos="slide-left">
          <div className="popup-title-wrapper">
            <h4>Loan Offer Details</h4>
            <i className="fa fa-times" onClick={close}></i>
          </div>
          <span className="popup-title-summary">
              Here is the details of the selected offer.
          </span>
          <div className="offer-details-summary" style={{backgroundColor: '#4700E0', border: 'none'}}>
            <div className="loan-offer-header">
              <div className="loan-offer-label">
                  <img src={images.LOAN_OFFER_ICON} className="loan-offer-header-icon" />
                  <div className="loan-offer-label-text">
                      <label>LoanType</label>
                      <p className='loan-offer-type-text'>{data?.loanTypeName}</p>
                  </div>
              </div>
              <span className="loan-offer-percentage" style={{color: '#6DE7B4'}}>{data?.interest}%p.m</span>
            </div>
            <Divider margin="1.5" />
            <div className="selected-data-container">
              <div className="loan-offer-label-text">
                  <label>Amount</label>
                  <p>N{utility.moneyFormat(data?.amountPerPerson || data?.amount)}</p>
              </div>
              <div className="loan-offer-label-text">
                  <label>Duration</label>
                  <p>{data?.tenor} {data?.tenorType?.toLowerCase()}</p>
              </div>
              <div className="loan-offer-label-text">
                  <label>{data?.repaymentTermName} Repayment</label>
                  <p>{data?.amountPerTenorType}</p>
              </div>
              <div className="loan-offer-label-text">
                  <label>Interest Amount</label>
                  <p>N{utility.moneyFormat(data?.interestAmount)}</p>
              </div>
              <div className="loan-offer-label-text">
                  <label>Payment</label>
                  <p>{data.rateId === 1 ? 'Installment' : 'One-off'}</p>
              </div>
              <div className="loan-offer-label-text">
                  <label>Total Repayment</label>
                  <p>N{utility.moneyFormat(data?.totalAmountToBeRepaid)}</p>
              </div>
              <div className="loan-offer-label-text">
                  <label>Lender's rep</label>
                  <p style={{color: '#6DE7B4'}}>{data?.lenderRep || 'nill'}</p>
              </div>
            </div>
          </div>

          { currentProcessType === 'borrower' &&
            <div className="about-loan">
              <h4 className="about-loan-title">About this Loan</h4>
              <span className="about-loan-text">
                {data?.about || 'None'}
              </span>
            </div>
          }
        </SidePopup>
      </SidePopupContainer>
    </>
  )
}
