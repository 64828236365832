import { useState, useContext, useEffect } from 'react'
import utility from 'src/utils/utility'
import { useNavigate } from 'react-router-dom'
import ApiContext from 'src/provider/API/call-service'
import AppInfoContext from 'src/provider/state-manager/appInfoProvider'
import LoanContext from 'src/provider/state-manager/loanProvider'
import { AbsoluteContainer, AppSpan, AppText, Button, CustomContainer, Divider, FlexRow, Icon, ScreenContainer, ScreenTitle } from 'src/styles'
import { BsCheck, BsChevronLeft } from 'react-icons/bs'
import { images, sortingCategory as category, sortingOrder as order } from 'src/provider/config/constant'
import { AiOutlineSortAscending } from 'react-icons/ai'
import { BiMenuAltRight } from 'react-icons/bi'
import {FilterOffersNew, ProposeOffer, QuickLoanSummary} from 'src/popup'

const LoanTypeList = () => {
    const navigate = useNavigate()
    const {API} = useContext(ApiContext)
    const {info: {quickRequest}, setInfoProperty} = useContext(AppInfoContext)
    const {loan: {requestLoanOffers}, setLoanProperty} = useContext(LoanContext)
    const [requests_offers, setRequests_Offers] = useState<any>(null)
    const [quickAccess, setQuickAccess] = useState<any>(null)
    const [sortCategory, setSortCategory] = useState('amountPerPerson')
    const [sortOrder, setSortOrder] = useState('asc')
    const [sortType, setSortType] = useState('')
    const [isSortDropdown, setIsSortDropdown] = useState(false)
    const [showFilterBy, setShowFilterBy] = useState(false)
    const [showSummary, setShowSummary] = useState(false)
    const [selectedLoan, setSelectedLoan] = useState<any>(null)

    let typeText = quickRequest?.type === 'offer' ? 'request' : 'offer'

    useEffect(() => {
        setRequests_Offers(requestLoanOffers)
    }, [requestLoanOffers])

    useEffect(() => {
        setQuickAccess(quickRequest)
        if (quickRequest?.type) getCategoryData()
        console.log({type: quickRequest?.type})
    }, [quickRequest])
    

    async function getCategoryData () {
        let response;
        if (quickRequest?.type === 'offer') {
            response = await API.filterLoanRequest({searchText: quickRequest?.category})
        }
        else {
            response = await API.filterLoanOffer({searchText: quickRequest?.category})
        }

        if (!response) navigate('/dashboard/home')
    }

    async function handleSorting (val: string) {
        sortType === 'category' ? setSortCategory(val) : setSortOrder(val)

        let data = requestLoanOffers;
        if ((sortType === 'order' && val === 'asc') || (sortType === 'category' && sortOrder === 'asc')) {
            data = data.sort((a: any, b: any) => normalizeTenor(a, val, sortCategory) - normalizeTenor(b, val, sortCategory));
        }
        else {
            data = data.sort((a: any, b: any) => normalizeTenor(b, val, sortCategory) - normalizeTenor(a, val, sortCategory));
        }

        setLoanProperty('requestLoanOffers', data)
        setIsSortDropdown(false)
    }

    function normalizeTenor (obj: any, sortType1: string, sortType2: string) {
        let val = Number(obj[sortType1] || obj[sortType2])
        if (sortType1 === 'tenor' || sortType2 === 'tenor') {
            const tenorType = obj.tenorType == 1 ? 1 : obj.tenorType == 2 ? 30 : obj.tenorType === 3 ? 7 : 52
            val = val * tenorType
        }
        if (sortType1 === 'amountPerPerson' || sortType2 === 'amountPerPerson') {
            val = !val ? Number(obj?.amount) : val
        }
        return val
    }

    function handleSortType (type: string) {
        setSortType(type)
        setIsSortDropdown(true)
    }

    async function handleSelectedOffer_Request (data: any) {
        setSelectedLoan(data)
        await setLoanProperty('selectedLoanOffer', data) // selected request/offer
        setShowSummary(true)
    }


    return (
        <>
            <ScreenContainer>
                <CustomContainer topMargin="2" rightPadding="1" leftPadding="1" className="trans-screen-container">
                    <div 
                        //style={{marginTop: '4rem', zIndex: '2', paddingRight: '1rem'}}
                    >
                        <FlexRow justifyContent='space-between'>
                            <BsChevronLeft 
                                className="loan-back-icon" 
                                onClick={() => navigate(-1)} 
                                style={{zIndex: 3}}
                            />
                            <FlexRow justifyContent='flex-end'>
                                <CustomContainer 
                                    width='auto' size='' borderColor='#4B5D99' 
                                    radius='0.4' leftMargin='1'
                                    style={{overflow: 'visible'}}
                                >
                                    <AppSpan className='sort-by-text' onClick={() => handleSortType('category')}>
                                        Sort By
                                    </AppSpan>
                                    <AiOutlineSortAscending className="sort-icon" onClick={() => handleSortType('order')} />
                                    {
                                        isSortDropdown &&
                                        <AbsoluteContainer top='5'>
                                            <CustomContainer 
                                                bgColor='#081952' width='13'
                                                radius='0.4'
                                                topPadding='1' bottomPadding='1'
                                                style={{boxShadow: '0px 10px 50px rgba(0, 0, 0, 0.5)'}}
                                            >
                                                {
                                                    sortType === 'category' ?
                                                    category.map((item: any, index: number) => {
                                                        return (
                                                            <FlexRow className='sort-select-option' onClick={() => handleSorting(item.value)} key={index}>
                                                                <AppSpan fontWeight="400" textSize="1.3">{item.name}</AppSpan>
                                                                {sortCategory === item.value ? <BsCheck style={{color: '#FED0B7', fontSize: '2rem'}} /> : null}
                                                            </FlexRow>
                                                        )
                                                    })
                                                    : 
                                                    order.map((item: any, index: number) => {
                                                        return (
                                                            <FlexRow className='sort-select-option' onClick={() => handleSorting(item.value)} key={index}>
                                                                <AppSpan fontWeight="400" textSize="1.3">{item.name}</AppSpan>
                                                                {sortOrder === item.value ? <BsCheck style={{color: '#FED0B7', fontSize: '2rem'}} /> : null}
                                                            </FlexRow>
                                                        )
                                                    })
                                                }
                                            </CustomContainer>
                                        </AbsoluteContainer>
                                    }
                                </CustomContainer>
                                <AppSpan 
                                    onClick={() => setShowFilterBy(true)}
                                    className="filter-text-icon filter-quick-access"
                                >
                                    Filter
                                    <BiMenuAltRight className="flex-item-icon"/>
                                </AppSpan>
                            </FlexRow>
                        </FlexRow>

                        <div className={`loan-list-banner-plus ${quickAccess?.type}-b${quickAccess?.id}-img`}>
                            <AppText textSize="2.7">
                                {utility.toTitleCase(String(quickAccess?.category))} Loans
                            </AppText>
                            { quickAccess?.type === 'loan' ?
                                <AppSpan textSize="1.4" color="#E2E2E2" fontWeight="400" topMargin="1">
                                    See lenders offering {quickAccess?.category} loans on crendly
                                </AppSpan>
                                :
                                <AppSpan textSize="1.4" color="#E2E2E2" fontWeight="400" topMargin="1">
                                    See borrowers requesting for {quickAccess?.category} loans on crendly
                                </AppSpan>
                            }
                        </div>

                        <div className="loan-offers-content" style={{marginTop: '-0.7rem'}}>
                            {
                                requests_offers?.map((item: any, index: number) => {
                                    //let color = index % 5 === 0 ?  "#FF3A5A" : index % 3 === 0 ? '#FFBC3A' : "#6DE7B4"
                                    return (
                                        <div className="loan-offer-info" key={index} onClick={() => handleSelectedOffer_Request(item)}>
                                            <div className="loan-offer-header">
                                                <div className="loan-offer-label">
                                                    <img src={images.LOAN_OFFER_ICON} className="loan-offer-header-icon" />
                                                    <div className="loan-offer-label-text">
                                                        {/* <label>LoanType</label> */}
                                                        <p className='loan-offer-type-text'>{item.loanTypeName === 'Others' ? item.customLoanType : item.loanTypeName}</p>
                                                    </div>
                                                </div>
                                                <span className="loan-offer-percentage" style={{color: '#6DE7B4'}}>{item.interest}%p.m</span>
                                            </div>
                                            <Divider margin="1" />
                                            <div className="flex-row-space-between margin-vertical-1-5">
                                                <div className="loan-offer-label-text">
                                                    <label>Amount</label>
                                                    <p>N{utility.moneyFormat(item.amountPerPerson || item.amount)}</p>
                                                </div>
                                                <div className="loan-offer-label-text width-10-rem">
                                                    <label>Duration</label>
                                                    <p>{item.tenor} {item?.tenorType?.toLowerCase()}(s)</p>
                                                </div>
                                            </div>
                                            <div className="flex-row-space-between margin-vertical-1-5">
                                                <div className="loan-offer-label-text">
                                                    <label>Payment</label>
                                                    <p>{item.rateId === 1 ? 'Installment' : 'One-off'}</p>
                                                </div>
                                                { quickRequest?.type === 'offer' ?
                                                    <div className="loan-offer-label-text width-10-rem">
                                                        <label>Crendly score</label>
                                                        <p style={{color: '#6DE7B4'}}>{item?.creditScoreInfo?.grandTotal || 0}%</p>
                                                    </div>
                                                    : null
                                                    // <div className="loan-offer-label-text width-10-rem">
                                                    //     <label>Lender's rep</label>
                                                    //     <p style={{color: '#6DE7B4'}}>{item?.lenderRep || 89.2}%</p>
                                                    // </div>
                                                }
                                                
                                            </div>
                                            { quickAccess?.type === 'loan' &&
                                                <FlexRow justifyContent="flex-start" wrap="wrap">
                                                    <Icon src={images.FRUIT_HEADS2X} style={{marginLeft: '-0.5rem'}} width='6'/>
                                                    <AppText textSize="1.2" leftMargin="1">
                                                        {item?.numberOfInterestedBorrowers || 0} person(s) made request
                                                    </AppText>
                                                </FlexRow>
                                            }
                                        </div>
                                    )
                                })
                            }
                        </div>
                        { (!requests_offers || requests_offers?.length === 0) &&
                            <div className="no-offer-container">
                                <AppText textSize='2.5' bottomMargin='1'>No loan {typeText}(s) available for your {typeText}</AppText>
                                <AppText fontWeight='400'>Consider searching or filtering to get {typeText}(s) that best matches what you want</AppText>
                                {/* <Button
                                    width="20"
                                    hoverBgColor="#FED0B7" 
                                    topMargin='3'
                                    onClick={() => API.filterLoanOffer(null)}
                                >
                                    See other {typeText}(s)
                                </Button> */}
                            </div>
                        }
                    </div>
                </CustomContainer>
            </ScreenContainer>
            { showFilterBy &&
                <FilterOffersNew 
                    close={() => setShowFilterBy(false)}
                />
            }
            { showSummary &&
                <QuickLoanSummary 
                    close={() => setShowSummary(false)}
                    data={selectedLoan}
                />
            }
        </>
    )
}

export default utility.routeData('quick-access-loans_offers', 'Loan Requests/Offers', <LoanTypeList/>)