import { useState, useContext } from 'react'
import utility from 'src/utils/utility'
import ApiContext from 'src/provider/API/call-service'
import AppInfoContext from 'src/provider/state-manager/appInfoProvider'
import UserContext from 'src/provider/state-manager/userProvider'
import VisibilityContext from 'src/provider/state-manager/visibilityProvider'
import { useNavigate } from 'react-router-dom'
import { Container } from 'src/component'
import { AppLink, AppSpan, AppText, Button, CheckBox, CustomContainer, FlexRow, ScreenTitle } from 'src/styles'
import { BsChevronLeft } from 'react-icons/bs'
import { images, cloudinaryData } from 'src/provider/config/constant'
import { NoAuthPopup } from 'src/popup'
import SignatureCanvas from 'react-signature-canvas'
import { BiCheck } from 'react-icons/bi'

const SignatureUpload = () => {
    const navigate = useNavigate()
    const {API} = useContext(ApiContext)
    const {notifier} = useContext(VisibilityContext)
    const {info} = useContext(AppInfoContext)
    const {user: {userData}} = useContext(UserContext)
    const [input, setInput] = useState(info.onBoardingData?.signaturePath || '')
    const [signature, setSignature] = useState<any>(null)
    const [showResponse, setShowResponse] = useState(false)
    const [hasAgreedTerms, setHasAgreedTerms] = useState(false)

    async function handleSignature() {
        if (signature.isEmpty()) return notifier.show("Kindly sign inside the white box")
        
        const signatureToDataUrl = signature.getTrimmedCanvas().toDataURL('image/png')
        const url = await API.uploadToCloudinary(
            {
                file: signatureToDataUrl,
                upload_preset: cloudinaryData.UPLOAD_PRESET,
                public_id: `CRENDLY_SIGNATURE-${userData?.userId}-${Date.now()}`

            }, 
            cloudinaryData.CLOUND_NAME
        )
        if (url) {
            setInput(url)
            handleNext(url)
        }
    }

    async function handleNext (url: string) {
        const response = await API.updateSignature(url)
        if (response)  setShowResponse(true)
    }

    return (
        <>
            <Container type="onboarding" progressBar='76.9'>
                <p className="right-align-text">Already have an account? 
                    <a className="registration-link" onClick={() => navigate('/login')}> Sign in</a>
                </p>
                <div className="onboarding-container">
                    <BsChevronLeft className="loan-back-icon" onClick={() => navigate(-1)} />
                    <ScreenTitle textSize="3" bottomMargin="1.5" topMargin="3" >
                        Signature
                    </ScreenTitle>
                    <AppText color="#D5D5D5" textSize="1.4" fontWeight="400" bottomMargin="2">
                        Your signature for document authentication. Kindly sign below
                    </AppText>
                    <CustomContainer topMargin="5">
                        <div className="idcard-wrapper">
                            <div className="idCard-upload-block">
                                <div className="idCard-container signature-container">
                                    {/* <img className="signature-photo" src={input || images.DEFAULT_SIGNATURE} /> */}
                                    <CustomContainer bgColor='white' radius='0.4' bottomMargin='1'>
                                        <SignatureCanvas
                                            canvasProps={{width: 500, height: 200, className: 'sigCanvas'}} 
                                            ref={(data: any) => setSignature(data)}
                                        />
                                    </CustomContainer>
                                    <FlexRow gap='1' alignItems='flex-start' justifyContent='flex-start'>
                                        <CheckBox 
                                            hoverColor='#6DE7B4'
                                            borderColor={hasAgreedTerms ? '#6DE7B4' : '#D0D5DD'}
                                            onClick={() => setHasAgreedTerms(!hasAgreedTerms)}
                                        >
                                            { hasAgreedTerms &&
                                                <AppSpan color='#6DE7B4' textSize='1.7' topMargin='-0.3'>
                                                    <BiCheck />
                                                </AppSpan>
                                            }
                                        </CheckBox>
                                        <AppSpan 
                                            textSize='1.2' fontWeight='400'
                                            width='100' sizeUnit='%'
                                        >
                                            I understand this is a legal representation of my signature.
                                            <AppLink 
                                                textSize="1.1" hoverColor="#FED0B7" leftMargin='0.5'
                                                href='https://www.crendly.com/terms-and-conditions' 
                                                target='_blank' 
                                            >
                                                Terms & Conditions
                                            </AppLink>
                                        </AppSpan>
                                    </FlexRow>
                                </div>
                                {/* <input type="file" onChange={handleFile} className="idcard-input" id="card" />
                                <AppLabel 
                                    hoverColor="#FED0B7" topMargin="2" htmlFor="card"
                                    align="center" decoration="underline" 
                                    style={{display: 'block'}}
                                >
                                    { !input ? 'Upload My Signature' : 'Provide another Signature'}
                                </AppLabel> */}
                                <FlexRow gap='2'>
                                    { 
                                        <Button
                                            width='8' height='3.5' titleSize='1.2'
                                            bgColor='transparent' borderColor='#6DE7B4'
                                            color='#6DE7B4' hoverColor='#FFC49D'
                                            hoverBorderColor='#FFC49D'
                                            onClick={() => signature.clear()}
                                        >
                                            Clear
                                        </Button>
                                    }
                                    <Button 
                                        width="13" 
                                        height="3.5" titleSize='1.2'
                                        hoverBgColor="#FFC49D"
                                        onClick={handleSignature}
                                        className="loan-action-btn"
                                        disabled={!hasAgreedTerms}
                                    >
                                        Submit Signature
                                    </Button>
                                </FlexRow>
                            </div>
                        </div>
                    </CustomContainer>
                    
                    <div className="option-2-btns">
                        <AppLink className="negotiate-loan-btn" onClick={() => navigate('/onboarding/bank-details')}>Do this later</AppLink>
                        {/* <Button 
                            width="15" 
                            height="4.5" 
                            hoverBgColor="#FFC49D"
                            leftMargin="3" 
                            className="loan-action-btn"
                            disabled={!input ? true : false}
                            onClick={handleNext}
                        >
                            Continue
                        </Button> */}
                    </div>
                </div>
            </Container>
            { showResponse &&
                <NoAuthPopup 
                    close={() => setShowResponse(false)} 
                    text="Your signature has been secured in our database."
                    route="/onboarding/bank-details"
                    responseIcon={images.SIGNATURE_RESPONSE}
                />
            }
        </>
    )
}

export default utility.routeData('signature-upload', 'Signature Upload', <SignatureUpload/>)