import { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import utility from 'src/utils/utility'
import { ImUser } from 'react-icons/im'
import { AiFillInstagram, AiFillTwitterCircle } from 'react-icons/ai'
import { BsHeadset, BsChevronLeft, BsFacebook } from 'react-icons/bs'
import { MdAttachEmail } from 'react-icons/md'
import { FaGlobeAsia, FaTiktok } from 'react-icons/fa'
import { 
    AppLink,
    AppSpan, 
    AppText, 
    CustomContainer, 
    FlexRow,  
    ScreenContainer, 
} from 'src/styles'


const Support = () => {
    const navigate = useNavigate()
    
    return (
        <ScreenContainer>
            <CustomContainer topMargin="3" leftPadding="2.5" rightPadding="2.5" className="reduce-container-padding">
                <FlexRow width="auto" sizeUnit="" justifyContent="flex-start" alignItems="flex-start">
                    <BsChevronLeft className="loan-back-icon" onClick={() => navigate(-1)} />
                    <CustomContainer width="40">
                        <AppText textSize="2" bottomMargin="1" >Support</AppText>
                        <AppSpan textSize="1.3" fontWeight="400">
                            Check out our 
                            <AppLink 
                                textSize="1.3" href='https://www.crendly.com/faq' 
                                hoverColor='#FED0B7' decoration='none' leftPadding='0.5'
                                target="_blank"
                            >
                                FAQ section
                            </AppLink> for answers to common questions. If you still need assistance, 
                            please reach out to us through our support channels and we'll get back to you as soon as possible.
                            Thank you for choosing Crendly. <AppSpan textSize="1.3">Build wealth, support people</AppSpan>.
                        </AppSpan>
                    </CustomContainer>
                </FlexRow>
                <FlexRow justifyContent="flex-start" alignItems="flex-start" wrap="wrap" topMargin="6" leftPadding="5">
                    <CustomContainer width="auto" sizeUnit="" rightMargin="8" topMargin="2">
                        <AppText textSize="1.5" bottomMargin="2">Call Us</AppText>
                        <FlexRow bottomMargin="2.5">
                            <BsHeadset className="settings-icon" />
                            <AppLink href='tel:07080639537' decoration='none'>
                                <AppSpan 
                                    textSize="1.5" leftPadding="1"
                                    hoverColor='#FED0B7' cursor='pointer'
                                >
                                    07080639537
                                </AppSpan>
                            </AppLink>
                        </FlexRow>
                    </CustomContainer>
                    <CustomContainer width="auto" sizeUnit="" rightMargin="7" topMargin="2" >
                        <AppText textSize="1.5" bottomMargin="2">Social Media</AppText>
                        <FlexRow bottomMargin="2.5" justifyContent="flex-start">
                            <AiFillInstagram className="settings-icon" style={{fontSize: '2.5rem'}} />
                            <AppLink 
                                href='https://instagram.com/becrendly?igshid=MzRlODBiNWFlZA==' 
                                decoration='none' target='_blank'
                            >
                                <AppSpan textSize="1.5" leftPadding="1" hoverColor='#FED0B7' cursor='pointer'>
                                    @becrendly
                                </AppSpan>
                            </AppLink>
                        </FlexRow>
                        <FlexRow bottomMargin="2.5" justifyContent="flex-start">
                            <BsFacebook className="settings-icon" />
                            <AppLink 
                                href='https://www.facebook.com/Crendly?mibextid=LQQJ4d' 
                                decoration='none' target='_blank'
                            >
                                <AppSpan textSize="1.5" leftPadding="1" hoverColor='#FED0B7' cursor='pointer'>
                                    Crendly
                                </AppSpan>
                            </AppLink>
                        </FlexRow>
                        <FlexRow bottomMargin="2.5" justifyContent="flex-start">
                            <AiFillTwitterCircle className="settings-icon" style={{fontSize: '2.5rem'}} />
                            <AppLink 
                                href='https://x.com/becrendly?s=21&t=KLxbkqSg0d8lgslfplGgRg' 
                                decoration='none' target='_blank'
                            >
                                <AppSpan textSize="1.5" leftPadding="1" hoverColor='#FED0B7' cursor='pointer'>
                                    @becrendly
                                </AppSpan>
                            </AppLink>
                        </FlexRow>
                        <FlexRow bottomMargin="2.5" justifyContent="flex-start">
                            <FaTiktok className="settings-icon" style={{fontSize: '2rem'}} />
                            <AppLink 
                                href='https://www.tiktok.com/@becrendly?_t=8f7M95cGmur&_r=1' 
                                decoration='none' target='_blank'
                            >
                                <AppSpan textSize="1.5" leftPadding="1" hoverColor='#FED0B7' cursor='pointer'>
                                    @becrendly
                                </AppSpan>
                            </AppLink>
                        </FlexRow>
                        <FlexRow bottomMargin="2.5" justifyContent="flex-start">
                            <FaGlobeAsia className="settings-icon" />
                            <AppLink href='https://www.crendly.com/' decoration='none' target='_blank'>
                                <AppSpan textSize="1.5" leftPadding="1" hoverColor='#FED0B7' cursor='pointer'>
                                    www.crendly.com
                                </AppSpan>
                            </AppLink>
                        </FlexRow>
                    </CustomContainer>
                    <CustomContainer width="auto" sizeUnit="" topMargin="2" >
                        <AppText textSize="1.5" bottomMargin="2">Email Us</AppText>
                        <FlexRow bottomMargin="2.5" justifyContent="flex-start">
                            <MdAttachEmail className="settings-icon" />
                            <AppLink href='mailto:support@crendly.com' decoration='none'>
                                <AppSpan 
                                    textSize="1.5" leftPadding="1.5" 
                                    hoverColor='#FED0B7' cursor='pointer'
                                >
                                    support@crendly.com
                                </AppSpan>
                            </AppLink>
                        </FlexRow>
                    </CustomContainer>
                </FlexRow>
            </CustomContainer>
        </ScreenContainer>
    )
}

export default utility.routeData('settings/support', 'Settings Support', <Support/>)