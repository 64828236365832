import './style.scss'
import { useEffect, useState, useContext } from 'react'
import AppInfoContext from 'src/provider/state-manager/appInfoProvider'
import utility from 'src/utils/utility'
import { useNavigate, useLocation } from 'react-router-dom'
import { Container } from 'src/component'
import { AppSpan, AppText, Circle, Icon } from 'src/styles'
import { images } from 'src/provider/config/constant'

const OnboardType = () => {
    const navigate = useNavigate()
    const location = useLocation();
    const referralCode = new URLSearchParams(location.search).get('referralCode');
    const {info, setInfoProperty} = useContext(AppInfoContext)

    async function handleContinue (type: string) {
        await setInfoProperty('onBoardingData', {...info.onBoardingData, profileType: type, referralCode})
        if (type === 'individual') navigate('/onboarding/bvn-details')
        else navigate('/onboarding/business')
    }

    return (
        <Container type="onboarding">
            <p className="right-align-text">Already have an account? 
                <a className="registration-link" onClick={() => navigate('/login')}> Sign in</a>
            </p>
            <div className="onboarding-type-container">
                <AppText color="#D5D5D5" textSize="1.7" fontWeight="500" align="center" bottomMargin='3.5'>
                    We are about the credibility of our platform. Hence, you will be asked a series of questions to onboard Crendly. Please be patient and get all your details handy. 
                </AppText>
                <h3 className="title-width-auto" >
                    Which of these options best describes you?
                </h3>
                <div className="onboarding-type">
                    <div className="onboard-type-wrap" onClick={() => handleContinue('individual')}>
                        <Circle size='5'>
                            <Icon  src={images.CREATE_LOAN_AVATAR} />
                        </Circle>
                        <AppText textSize="2.3" color="#131176" topMargin="3">Individual</AppText>
                        <AppSpan textSize="1.2" color="#070647" fontWeight="400">
                            Invest in the needs of real people and earn interests monthly.
                        </AppSpan>
                    </div>
                    <div className="onboard-type-wrap" onClick={() => handleContinue('business')}>
                        <Circle size='5'>
                            <Icon  src={images.GET_LOAN_AVATAR} />
                        </Circle>
                        <AppText textSize="2.3" color="#131176" topMargin="3">Business</AppText>
                        <AppSpan textSize="1.2" color="#070647" fontWeight="400">
                            Get money for what you need at an incredible speed. 
                        </AppSpan>
                    </div>
                </div>
                <AppSpan textSize="1.4" color="#F8FBF4" fontWeight="400" bottomMargin="9">
                    Either way, we’ll be having a good time together.
                </AppSpan>
                <a className="registration-link" href='https://crendly.com' >Don't want to sign up now</a>
            </div>
        </Container>
    )
}

export default utility.routeData('type', 'Onboarding Type', <OnboardType/>)