import { ReactElement } from "react"
import { BudPayUser, LoanStatusColor, RouteData } from "src/model"
import { images, credentials } from 'src/provider/config/constant'
import access from "./localAccess"

const routeData = (path: string, name: string, component: ReactElement<any, any>): RouteData => {
    return { 
        routeProps: {
            path,
            element: component
        },
        name
    }
}

const getResponderIcon = (type: string) => {
    type = type.toLowerCase()
    if (type === 'success') return images.SUCCESS_RESPONSE
    else if (type === 'withdraw') return images.WITHDRAW_RESPONSE
    else if (type === 'statement') return images.STATEMENT_RESPONSE
    else if (type === 'bank') return images.BANK_RESPONSE
    else if (type === 'card') return images.CARD_RESPONSE
    else if (type === 'topup') return images.TOPUP_RESPONSE
    else if (type === 'loan-request') return images.LOAN_REQUEST_RESPONSE
    else if (type === 'negotiation') return images.NEGOTIATION_RESPONSE
    else if (type === 'cancel') return images.CANCEL_RESPONSE
    else if (type === 'delete') return images.DELETE_RESPONSE
    else if (type === 'join') return images.JOIN_RESPONSE
    else if (type === 'identity') return images.USER_RESPONSE
    else if (type === 'user-trust') return images.USER_TRUST_RESPONSE
    else if (type === 'explore') return images.EXPLORE_RESPONSE
    else if (type === 'explore-plus') return images.EXPLORE_RESPONSE_PLUS
    else if (type === 'explore-portfolio') return images.EXPLORE_RESPONSE_PORTFOLIO
    else return images.SUCCESS_RESPONSE
}

const getTenorTypeText = (val: number) => {
    if (val === 1) return 'Day(s)'
    else if (val === 3) return 'Week(s)'
    else if (val == 2) return 'Month(s)'
}

const moneyFormat = (val: number|string, decimalPlace?: number) => {
    var formatter = new Intl.NumberFormat('en-NG', {
        currency: 'NGN',
        minimumFractionDigits: decimalPlace ||  0,
        maximumFractionDigits: 2 
    })
    return formatter.format(Number(val))
}

// const getPaymentConfig = (amount: number, email: string) => {
//     return {
//         //reference: (new Date()).getTime().toString(),
//         email: email || credentials.PAYSTACK_EMAIL,
//         amount: amount * 100,
//         publicKey: credentials.PAYSTACK_KEY,
//     };
// }

const getPaymentConfig = (amount: number, user: BudPayUser) => {
    return {
        email: user?.email || credentials.BUDPAY_EMAIL,
        amount: String(amount),
        api_key: credentials.BUDPAY_KEY,
        first_name: user?.firstName,
        last_name: user?.lastName,
        currency: 'NGN',
        reference: `BUD_${new Date().getTime().toString()}`,
    };
}

const reactSelectStyles = () => {
    return {
        input: (style: any) => ({
            ...style,
            color: '#fff'
        }),
        control: (base: any, state: any) => ({
          ...base,
          background: "transparent",
          height: '5rem',
          border: 0,
          color: '#fff',
          borderColor: 'transparent',
          boxShadow: 'none',
          "&:hover": {
            background: "transparent",
            borderColor: 'transparent'
          }
        }),
        option: (style: any, state: any) => {
            let color = '#000'
            if (state.isSelected) {
                color = "#fff";
            }

            return {
                ...style,
                color,
                paddingTop: '0.5rem'
            }
        },
        singleValue: (style: any) => ({
            ...style,
            color: '#fff'
        }),
        menu: (base: any) => ({
          ...base,
          borderRadius: 0,
          marginTop: 0,
          margin: 0,
          padding: 0
        }),
        menuList: (base: any) => ({
          ...base,
          padding: 0
        }),
        menuPortal: (base: any) => ({ ...base, zIndex: 9999 })
    };
}

const getLastPageVisited = (data: any) => {
    if (['phoneNo-verification', 'bvn-details'].includes(data?.lastRouteVisited)) return 'passcode'
    else if (data?.lastRouteVisited) return data.lastRouteVisited
    else if (!data?.hasCreatedPassword) return 'passcode'
    else if (!data.maritalStatus) return 'marital-status'
    else if (!data.educationalQualification) return 'educational-background'
    else if (!data.employmentStatus) return 'employment-status'
    else if (!data.residenceNo) return 'address-verification'
    else if (!data.isRegulatoryIdProvided) return 'means-of-identification'
    else if (!data.hasDoneFaceScan) return 'face-scan'
    else if (!data.hasUploadedSignature) return 'signature-upload'
    else return 'bank-details'
}

const toTitleCase = (text: string) => {
    text = text?.toLowerCase()
    return text[0]?.toUpperCase() + text?.substring(1, text?.length)
}

export const erroMessage = (text: any) => {
    const isOnline = access.getInternetStatus()
    return `Message: ${text || (!isOnline ? 'No Internet Connection' : 'An error occurred')}`
}

export const requestMessage = (resp: any, text = '') => {
    return resp.message || resp.messageString || text || 'Something went wrong'
}

export const catchErrMsg = (err: any) => {
    let message = err?.response?.data?.message || err?.response?.data?.messageString

    if (err.response?.data?.errors && !message) message = retrievApiValidationErrorMsg(err.response.data.errors)
   
    if (err.response?.status === 500) message = "We're experiencing a hiccup on our end, please try again."
    if (err.response?.status === 401) {
        message = 'Session has expired'
        access.getLogoutHandler()()
    }
    return message
}

export const getStatementIcon = (transactionType: string) => {
    transactionType = transactionType?.toUpperCase()
    if (transactionType === 'FUNDING') return images.LOAN_ICON_2
    else if (transactionType === 'DISBURSEMENT') return images.LOAN_ICON_3
    else if (transactionType === 'REPAYMENT') return images.LOAN_ICON_5
    else if (transactionType === 'WITHDRAWAL') return images.LOAN_ICON_4
    else return images.LOAN_ICON_4
}

export const getPercCompleted = (data: any) => {
    let percCompleted = 15
    let screen: any = ''
    let infoToProvide = ''
    let uncompletedInfo = []
    let canGiveLoan = false

    if (data?.primaryBankAccount?.accountNumber) percCompleted += 5
    if (data?.hasDoneFaceScan) percCompleted += 5
    if (data?.hasUploadedSignature) percCompleted += 5
    if (data?.hasTakenPersonalityQuestion) percCompleted += 10
    if (data?.hasTakenTrustQuestion) percCompleted += 10
    if (data?.maritalStatus) percCompleted += 10
    if (data?.educationalQualification) percCompleted += 10
    if (data?.employmentStatus) percCompleted += 10
    if (data?.residenceNo || data.contactAddress?.residenceNo) percCompleted += 10
    if (data?.isRegulatoryIdProvided || data.identification?.number) percCompleted += 10
    
    if (!data?.primaryBankAccount?.accountNumber){
        screen = true
        infoToProvide = 'Add Bank Details'
        uncompletedInfo.push({
            name: 'Add Bank Account',
            screen: true
        })
    } 
    if (!data?.hasDoneFaceScan) {
        screen = '/dashboard/settings/complete-face-scan'
        infoToProvide = 'Complete Facial Scan'
        uncompletedInfo.push({
            name: 'Facial Scan',
            screen
        })
    }
    if (!data?.hasUploadedSignature) {
        screen = '/dashboard/settings/signature-upload'
        infoToProvide = 'Upload Signature'
        uncompletedInfo.push({
            name: 'Signature',
            screen
        })
    }
    if (!data?.hasTakenPersonalityQuestion || !data?.hasTakenTrustQuestion) {
        screen = '/dashboard/questionaire'
        infoToProvide = 'Personality & Trust questions'
        uncompletedInfo.push({
            name: 'Personality & Trust Questions',
            screen
        })
    }
    if (!data?.maritalStatus) {
        screen = '/dashboard/settings/marital-status_education-bg'
        infoToProvide = 'Provide Marital Status'
        uncompletedInfo.push({
            name: 'Marital Status',
            screen
        })
    }
    if (!data?.educationalQualification) {
        screen = '/dashboard/settings/marital-status_education-bg'
        infoToProvide = 'Provide Education Qualification'
        uncompletedInfo.push({
            name: 'Education Qualification',
            screen
        })
    }
    if (!data?.employmentStatus) {
        screen = '/dashboard/settings/employment-status'
        infoToProvide = 'Provide Employment Status'
        uncompletedInfo.push({
            name: 'Employment Status',
            screen
        })
    }
    if (!data?.residenceNo && !data.contactAddress?.residenceNo) {
        screen = '/dashboard/settings/address-info'
        infoToProvide = 'Provide Address Details'
        uncompletedInfo.push({
            name: 'Address Details',
            screen
        })
    }
    if (!data?.isRegulatoryIdProvided && !data.identification?.number) {
        screen = '/dashboard/settings/means-of-identification'
        infoToProvide = 'Provide Means Of Identification'
        uncompletedInfo.push({
            name: 'Means Of Identification',
            screen
        })
    }

    if (data?.hasDoneFaceScan && data?.hasUploadedSignature && (data?.residenceNo || data?.contactAddress?.residenceNo) && data?.primaryBankAccount?.accountNumber && (data?.isRegulatoryIdProvided || data.identification?.number)) {
        canGiveLoan = true
    }

    return {
        percCompleted,
        screen, 
        infoToProvide,
        uncompletedInfo,
        canGiveLoan
    }
}

const koboFormat = (val: number|string) => {
    let formattedValue = (Number(val) / 100)
    return Number(formattedValue).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
}

const getPhoneModel = (type: string) => {
    let width = window.screen.availWidth
    let height = window.screen.availHeight

    if (type === 'iPhone') {
        // if (width === 390 && height === 844) {
        //     return 'iPhone 12 Pro'
        // }
        // else if (width === 428 && height === 926) {
        //     return 'iPhone 12 Pro max'   
        // }
        // else if (width === 360 && height === 780) {
        //     return 'iPhone 12 Mini'   
        // }
        // else if (width === 214 && height === 379) {
        //     return 'iPhone SE'   
        // }
        // else if (width === 414 && height === 896) {
        //     return 'iPhone 11 Pro max'   
        // }
        // else if (width === 375 && height === 812) {
        //     return 'iPhone 11 Pro'   
        // }
        // else if (width === 414 && height === 736) {
        //     return 'iPhone 8 Plus'   
        // }
        // else if (width === 375 && height === 667) {
        //     return 'iPhone 8'   
        // }
        // else if (width === 320 && height === 568) {
        //     return 'iPhone 5'   
        // }
        // else return 'iPhone'

        if ((width >= 430 && width < 440) && (height >= 932 && height < 940)) {
            return 'iPhone 14'
        }
        else if ((width >= 428 && width < 430) && (height >= 910 && height < 932)) {
            return 'iPhone 12 Pro max'   
        }
        else if ((width >= 414 && width < 428) && (height >= 896 && height < 926)) {
            return 'iPhone 11 Pro max'   
        }
        else if ((width >= 414 && width < 428) && height === 736) { 
            return 'iPhone 8 Plus'   
        }
        else if ((width >= 390 && width < 428) && (height >= 840 && height < 896)) {
            return 'iPhone 12 Pro'
        }
        else if ((width >= 375 && width < 390) && (height >= 812 && height < 844)) {
            return 'iPhone 11 Pro'   
        }
        else if ((width >= 375 && width < 390) && (height >= 667 && height< 812)) {
            return 'iPhone 8'   
        }
        else if ((width >= 360 && width < 375) && height <= 780) {
            return 'iPhone 12 Mini'   
        }
        else if ((width >= 320 && width < 360) && (height >= 568 && height < 667)) {
            return 'iPhone 5'   
        }
        else if ((width >= 214 && width < 320) && (height >= 360 && height < 568)) {
            return 'iPhone SE'   
        }
        else return `Unknown iPhone with width ${width} and height ${height}`
    }
    else if (type === 'iPad') {
        if (width === 1024 && height === 1366) {
            return 'iPad Pro'
        }
        else if (width === 820 && height === 1180) {
            return 'iPad Air (2020)'
        }
        else if (width === 834 && height === 1112) {
            return 'iPad Air (2020)'
        }
        else if (width === 810 && height === 1080) {
            return 'iPad 10.2'
        }
        else if (width === 768 && height === 1024) {
            return 'iPad Mini'
        }
        else return 'iPad'
    }
    else return null 
}

export const formatNegativeCharacter = (val: string) => {
    val = val.startsWith('-') ? val.replace('-', '') : val
    return val
}

export const loglite = (data: any, description?: string) => {
    if (description) console.log(`====== ${description} =======`)
    console.log(data)
}

export const handleTypeNumberControl = (e: React.KeyboardEvent<HTMLInputElement>, type = 'pin') => {
    let regex = new RegExp(/(^\d*$)|(Backspace|Tab|Delete)/)
    if (type === 'field') regex = new RegExp(/(^\d*$)|(Backspace|Tab|Delete|ArrowLeft|ArrowRight)/)
    if (!e.key.match(regex)) {
        e.preventDefault();
    }
}

const maskContactInfo = (info: string, type = 'phone'): string => {
    if (info) {
        if (type === 'email') {
            const [str1, str2] = info.split('@')
            return `${str1[0]}****${str1[str1.length-1]}@${str2}`
        }
        else {
            return `${info.substring(0, 4)}*****${info.substring(info.length - 2)}`
        }
    }
    else return ''
}

const retrievApiValidationErrorMsg = (errorObj: any) => {
    const errorProperties = Object.keys(errorObj)
    const message = errorObj[errorProperties[0]][0]

    return message
}

export const getLoanStatusColor = (stage: string, defaultColor = 'transparent') => {
    let stagesColorMap = <LoanStatusColor>{
        stage0: '#FFFFFF',
        stage1: '#38761D',
        stage2: '#0000FF',
        stage3: '#FFFF04',
        stage4: '#FF6D00',
        stage5: '#CC0100'
    }

    return stagesColorMap[(stage?.toLowerCase()) as keyof LoanStatusColor] || defaultColor
    //return stagesColorMap[stage as keyof typeof stagesColorMap] || 'transparent'
}

export const getCharacterToDispay = (character = '', acceptedCharacterLength = 20, characterLengthToremove = 0) => {
    let result = character

    if (character.length > acceptedCharacterLength) {
        result = `${result.substring(0, (acceptedCharacterLength - characterLengthToremove))}...`
    }

    return result
}

export * from 'src/utils/view'
export default {
    routeData,
    getResponderIcon,
    getTenorTypeText,
    moneyFormat,
    getPaymentConfig,
    reactSelectStyles,
    getLastPageVisited,
    toTitleCase,
    getStatementIcon,
    getPercCompleted,
    koboFormat,
    getPhoneModel,
    formatNegativeCharacter,
    maskContactInfo
}
