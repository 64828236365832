import { useState, useContext, useRef } from 'react'
import utility, { handleTypeNumberControl } from 'src/utils/utility'
import ApiContext from 'src/provider/API/call-service'
import AppInfoContext from 'src/provider/state-manager/appInfoProvider'
import { useNavigate } from 'react-router-dom'
import { AppText, Button, CenterPopupContainer, CustomContainer, ScreenContainer, ScreenTitle } from 'src/styles'
import { images } from 'src/provider/config/constant'
import { NoAuthPopup } from 'src/popup'

const TransactionPin = () => {
    const navigate = useNavigate()
    const {API} = useContext(ApiContext)
    const {info: {onBoardingData}, logout} = useContext(AppInfoContext)
    const [view, setView] = useState('')
    const [transPin, setTransPin] = useState({pin: '', cPin: ''})

    const handlePin = (e: React.ChangeEvent<HTMLInputElement|HTMLSelectElement>) => {
        if (e.target.value.length < 5) {
            setTransPin({...transPin, [e.target.name]: e.target.value})
        }
    }

    async function handleNext () {
        const response = await API.createTransactionPin(transPin.pin, transPin.cPin)
        if (response) {
            setView('tx-pin-message')
        }
    }

    async function handleLogin () {
        const response = await API.signIn({email: onBoardingData.email, password: onBoardingData.passcode})
        if (response) navigate('/dashboard/home')
        else logout()
    }

    return (
        <ScreenContainer padding="0">
            { view != 'tx-pin-message' &&
                <CenterPopupContainer zIndex="6">
                    <div className="transaction-pin-container">
                        <ScreenTitle color="#FED0B7" textSize="3.2" topMargin="4" fontWeight="bold" align="center">
                            Create Transaction PIN
                        </ScreenTitle>
                        <AppText textSize="1.6" topMargin="0.7" align="center">Create your own transaction PIN</AppText>

                        <CustomContainer topMargin="6" width="auto" sizeUnit="">
                            <AppText textSize="1.8" fontWeight="400" align="center" color="#fff" bottomMargin="1">
                                Enter New PIN
                            </AppText>
                            <div className="passcode-input-wrapper">
                                <input 
                                    className='display-hidden' id='pin'
                                    name="pin" type='number' 
                                    value={transPin.pin} 
                                    onChange={handlePin}
                                    onKeyUp={handleTypeNumberControl}
                                    onKeyDown={handleTypeNumberControl} 
                                    autoFocus={true} 
                                    autoComplete="off"
                                />
                                <label className={`transaction-pin-input ${transPin.pin.length === 0 && 'active-pin-box'}`} htmlFor='pin' >{transPin.pin[0] ? '*' : ''}</label>
                                <label className={`transaction-pin-input ${transPin.pin.length === 1 && 'active-pin-box'}`} htmlFor='pin' >{transPin.pin[1] ? '*' : ''}</label>
                                <label className={`transaction-pin-input ${transPin.pin.length === 2 && 'active-pin-box'}`} htmlFor='pin' >{transPin.pin[2] ? '*' : ''}</label>
                                <label className={`transaction-pin-input ${transPin.pin.length === 3 && 'active-pin-box'}`} htmlFor='pin' >{transPin.pin[3] ? '*' : ''}</label>
                            </div>
                        </CustomContainer>
                        <CustomContainer topMargin="6" width="auto" sizeUnit="">
                            <AppText textSize="1.8" fontWeight="400" align="center" color="#fff" bottomMargin="1">
                                Confirm New PIN
                            </AppText>
                            <div className="passcode-input-wrapper">
                                <input 
                                    className='display-hidden' id='cPin' 
                                    name="cPin" type='number' 
                                    value={transPin.cPin} 
                                    onKeyUp={handleTypeNumberControl}
                                    onKeyDown={handleTypeNumberControl} 
                                    onChange={handlePin} 
                                    autoComplete="off"
                                />
                                <label className={`transaction-pin-input ${transPin.cPin.length === 0 && 'active-pin-box'}`} htmlFor='cPin' >{transPin.cPin[0] ? '*' : ''}</label>
                                <label className={`transaction-pin-input ${transPin.cPin.length === 1 && 'active-pin-box'}`} htmlFor='cPin' >{transPin.cPin[1] ? '*' : ''}</label>
                                <label className={`transaction-pin-input ${transPin.cPin.length === 2 && 'active-pin-box'}`} htmlFor='cPin' >{transPin.cPin[2] ? '*' : ''}</label>
                                <label className={`transaction-pin-input ${transPin.cPin.length === 3 && 'active-pin-box'}`} htmlFor='cPin' >{transPin.cPin[3] ? '*' : ''}</label>
                            </div>
                        </CustomContainer>
                        <Button 
                            width="20" 
                            height="5" 
                            bottomMargin="3"
                            hoverBgColor="#FFC49D"
                            topMargin="10"
                            className="loan-action-btn"
                            disabled={transPin.pin.length != 4 || transPin.cPin.length != 4 ? true : false}
                            onClick={handleNext}
                        >
                            Set PIN
                        </Button>
                    </div>
                </CenterPopupContainer>
            }
            
            { view === 'tx-pin-message' &&
                <NoAuthPopup 
                    close={() => setView('')} 
                    text="Transaction PIN Set"
                    questionText='Do you want to proceed to dashboard and skip onboarding?'
                    yesMethod={() => setView('welcome-popup')}
                    noMethod={() => navigate('/onboarding/marital-status')}
                    responseIcon={images.SUCCESS_RESPONSE}
                />
            }
            { view === 'welcome-popup' &&
                <NoAuthPopup 
                    close={() => setView('')} 
                    text="Welcome to Crendly"
                    nextMethod={handleLogin}
                    responseIcon={images.SUCCESS_RESPONSE}
                />
            }
        </ScreenContainer>
    )
}

export default utility.routeData('transaction-pin', 'Transaction Pin', <TransactionPin/>)