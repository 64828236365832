import { useState, useContext, useEffect } from 'react'
import utility from 'src/utils/utility'
import ApiContext from 'src/provider/API/call-service'
import AppInfoContext from 'src/provider/state-manager/appInfoProvider'
import VisibilityContext from 'src/provider/state-manager/visibilityProvider'
import { useNavigate } from 'react-router-dom'
import { Container } from 'src/component'
import helpers from 'src/utils/helpers'
import { required } from 'src/utils/view'
import { GoChevronDown } from 'react-icons/go'
import ReCaptchaV2 from "react-google-recaptcha"
import { credentials } from 'src/provider/config/constant'
import { ExistNotif } from 'src/popup'
import { AppLink, AppSpan, AppText, Button, Circle, CustomContainer, FlexRow, FormGroup, FormGroupsWrapper, FormGroupWithIcon, ScreenTitle } from 'src/styles'

const BVNDetails = () => {
    const navigate = useNavigate()
    const {API} = useContext(ApiContext)
    const {notifier} = useContext(VisibilityContext)
    const {info} = useContext(AppInfoContext)
    const [isValidated, setIsValidated] = useState(false)
    const [input, setInput] = useState({
        dateOfBirth: info.onBoardingData?.dateOfBirth || '', 
        bvn: info.onBoardingData?.bvn || '', gender: info.onBoardingData?.gender || '', 
        phoneNumber: info.onBoardingData?.phoneNumber || '', email: info.onBoardingData?.email || '', 
        profileType: info.onBoardingData?.profileType || 'individual',
        referralCode: info.onBoardingData?.referralCode || ''
    })
    const [captchaToken, setCaptchaToken] = useState('')
    const [showExisting, setShowExisting] = useState(false)

    useEffect(() => {
        if (info.onBoardingData?.referralCode) setInput({...input, referralCode: info.onBoardingData.referralCode})
    }, [info.onBoardingData])

    useEffect(() => {
        const auth = helpers.inputValidator(input, ['referralCode']) 
        setIsValidated(auth.isValidated && input.bvn.length === 11 && helpers.validatePhoneNo(input.phoneNumber) && helpers.validateEmail(input.email) && helpers.getAge(input.dateOfBirth) >= 18)
    }, [input])

    const handleInput = (e: React.ChangeEvent<HTMLInputElement|HTMLSelectElement>) => {
        if (e.target.name === 'bvn') {
            if (e.target.value.length > 11) return false
        }
        if (e.target.name === 'phoneNumber') {
            let length = e.target.value.startsWith('0') ? 11 : 10
            if (e.target.value.length > length) return false
        }
        setInput({...input, [e.target.name]: e.target.value})
    }

    function handleCaptchaVerification (token: any) {
        setCaptchaToken(token)
    }

    function handleInputValidation (type: string) {
        if (type === 'dateOfBirth' && input.dateOfBirth) {
            if (helpers.getAge(input.dateOfBirth)  < 18) 
                notifier.show('You must be at least 18 years old')
        }
        else if (type === 'phoneNumber' && input.phoneNumber) {
            if (!helpers.validatePhoneNo(input.phoneNumber)) 
                notifier.show('Kindly provide a valid phone number')
        }
        else if (type === 'bvn' && input.bvn) {
            if (input.bvn.length !== 11) 
                notifier.show('Kindly provide a valid bvn')
        }
        else if (type === 'email' && input.email) {
            if (!helpers.validateEmail(input.email)) 
                notifier.show('Kindly provide a valid email address')
        }
    }

    async function handleNext () {
        const payload = {...input}
        if (!input.phoneNumber.startsWith('0')) payload.phoneNumber = `0${input.phoneNumber}`
        const response = await API.signUp(payload)
        if (response) {
            if (response?.exist) setShowExisting(true)
            else navigate('/onboarding/phoneNo-verification')
        }
    }

    return (
        <>
            <Container type="onboarding" progressBar='0'>
                <p className="right-align-text">Already have an account? 
                    <a className="registration-link" onClick={() => navigate('/login')}> Sign in</a>
                </p>
                <div className="onboarding-container">
                    <ScreenTitle textSize="3" bottomMargin="1.5" topMargin="3" >
                        Let's Start Off Easily
                    </ScreenTitle>
                    <AppText color="#D5D5D5" textSize="1.4" fontWeight="400" bottomMargin="2">
                        Kindly input your personal details as written on your BVN.
                    </AppText>

                    <FormGroupsWrapper className="full-column-flex" style={{marginTop: '3rem'}}>
                        <CustomContainer width="48.5" rightMargin="3" bottomMargin="1" topMargin="2" className="full-width-flex">
                            <FormGroup >
                                <label>BVN {required}</label>
                                <input 
                                    type="number" 
                                    placeholder="Enter valid BVN" 
                                    name="bvn" 
                                    value={input.bvn}
                                    onChange={handleInput}
                                    onBlur={() => handleInputValidation('bvn')}
                                />
                            </FormGroup>
                            <AppText textSize="1.3" topMargin="-1.5" fontWeight="400">Can't get your BVN? Dial *565*0#</AppText>
                        </CustomContainer>
                        <CustomContainer width="48.5" bottomMargin="1" topMargin="2" className="full-width-flex">
                            <FormGroupWithIcon bottomMargin="1.5">
                                <label>Phone Number {required}</label>
                                <div className="input-with-icon">
                                    <span 
                                        className='amount-symbol' 
                                        style={{fontSize: '1.5rem'}}
                                    >
                                        +234
                                    </span>
                                    <input 
                                        type="number" 
                                        name="phoneNumber" 
                                        placeholder="Phone number" 
                                        value={input.phoneNumber}
                                        onChange={handleInput}
                                        style={{paddingLeft: '5.5rem'}}
                                        autoComplete='off'
                                        onBlur={() => handleInputValidation('phoneNumber')}
                                    />
                                </div>
                            </FormGroupWithIcon>
                            <FlexRow justifyContent="flex-start" alignItems="flex-start">
                                <Circle 
                                    bgColor="#FED0B7"
                                    size="0.8"
                                    rightMargin="0.8"
                                />
                                <AppSpan textSize="1.1" fontWeight="400" topMargin="-0.4">It’s adviced that you  input your phone number as it is on your BVN.</AppSpan>
                            </FlexRow>
                        </CustomContainer>
                    </FormGroupsWrapper>
                    
                    <FormGroupsWrapper className="full-column-flex">
                        <CustomContainer width="48.5" rightMargin="3" bottomMargin="1" topMargin="2" className="full-width-flex">
                            <FormGroup bottomMargin="1.5">
                                <label>Date of Birth {required}</label>
                                <input 
                                    type="date" 
                                    name="dateOfBirth" 
                                    value={input.dateOfBirth} 
                                    style={{color: input.dateOfBirth ? '#fff' : '#999'}}
                                    onChange={handleInput} 
                                    onBlur={() => handleInputValidation('dateOfBirth')}
                                    max={helpers.getInputValidDate(helpers.addMomentToDate(18, 'years'))}
                                />
                            </FormGroup>
                            <FlexRow justifyContent="flex-start" alignItems="flex-start">
                                <Circle 
                                    bgColor="#FED0B7"
                                    size="0.8"
                                    rightMargin="0.8"
                                />
                                <AppSpan textSize="1.1" topMargin="-0.4" fontWeight="400">It’s adviced that you  input your date of birth as it is on your BVN.</AppSpan>
                            </FlexRow>
                        </CustomContainer>
                        <CustomContainer width="48.5" topMargin="2" className="full-width-flex">
                            <FormGroup >
                                <label>Email address {required}</label>
                                <input 
                                    type="email" 
                                    placeholder="example@gmail.com" 
                                    name="email" 
                                    value={input.email}
                                    onChange={handleInput}
                                    onBlur={() => handleInputValidation('email')}
                                />
                            </FormGroup>
                        </CustomContainer>
                    </FormGroupsWrapper>

                    <FormGroupsWrapper className="full-column-flex">
                        <CustomContainer width="48.5" rightMargin="3" topMargin="1.6" className="full-width-flex">
                            <FormGroupWithIcon>
                                <label>Gender {required}</label>
                                <div className="input-with-icon">
                                    <select 
                                        name="gender" 
                                        value={input.gender} 
                                        onChange={handleInput}
                                        style={{color: input.gender ? '#fff' : '#999'}}
                                    >
                                        <option value="">Select Gender</option>
                                        <option value="Male">Male</option>
                                        <option value="Female">Female</option>
                                    </select>
                                    <GoChevronDown className="input-with-icon-tag" />
                                </div>
                            </FormGroupWithIcon>
                        </CustomContainer>
                        <CustomContainer width="48.5" topMargin="1.6" className="full-width-flex">
                            <FormGroup >
                                <label>Referral Code</label>
                                <input 
                                    placeholder="Referral code" 
                                    name="referralCode" 
                                    value={input.referralCode}
                                    onChange={handleInput}
                                />
                            </FormGroup>
                        </CustomContainer>
                    </FormGroupsWrapper>
                    
                    <AppText textSize="1.4" topMargin="1.5" bottomMargin='2.5'>
                        Don’t have a BVN at the moment? 
                        <AppLink textSize="1.4" hoverColor="#FED0B7" leftMargin="1" onClick={() => navigate('/onboarding/without-bvn')}>
                            Continue without BVN
                        </AppLink>
                    </AppText>
                    <CustomContainer 
                        width="31.7" bgColor='#fff' radius="0.2" 
                        leftPadding='0.7' rightPadding='0.7'
                        height={isValidated ? 'auto' : '0'}
                        className='captcha-wrapper'
                        style={{visibility: isValidated ? 'visible' : 'hidden'}}
                    >
                        <AppText textSize="1.2" fontWeight='400' color='#000' bottomMargin='1'>
                            Please check the box below to proceed
                        </AppText>
                        <ReCaptchaV2
                            sitekey={credentials.RECAPTCHA_SITE_KEY}
                            onChange={handleCaptchaVerification}
                            onError={() => notifier.show('Captcha verification failed')}
                        />
                    </CustomContainer>
                    <AppText 
                        textSize="1.2" fontWeight='400' bottomMargin='2.5'
                        topMargin={isValidated ? "3.5" : '1.5'}
                    >
                        By clicking on Next, I agree to the
                        <AppSpan textSize="1.2" rightMargin="0.5" leftMargin="0.5">
                            <AppLink textSize="1.2" hoverColor="#FED0B7" href='https://www.crendly.com/terms-and-conditions' target='_blank' >Terms & Conditions</AppLink>, 
                            <AppLink textSize="1.2" hoverColor="#FED0B7" leftMargin='0.5' href='https://www.crendly.com/privacy-policy' target='_blank'>Privacy Policy & Data Consent</AppLink>
                        </AppSpan>
                        of Crendly and commit to fulfilling my obligations as a user on Crendly.
                    </AppText>
                    <Button 
                        width="15" 
                        height="4.5" 
                        hoverBgColor="#FFC49D" 
                        topMargin="2"
                        bottomMargin="3"
                        className="loan-action-btn"
                        disabled={!captchaToken ? true : false}
                        onClick={handleNext}
                    >
                        Next
                    </Button>
                </div>
            </Container>
            { showExisting &&
                <ExistNotif 
                    close={() => setShowExisting(false)}
                />
            }
        </>
    )
}

export default utility.routeData('bvn-details', 'BVN', <BVNDetails/>)