import { ImNotification } from 'react-icons/im'
import { AppSpan } from 'src/styles'

export const required = <AppSpan data-tip="This field is required" color='#FF0062' style={{cursor: 'pointer'}}>*</AppSpan>

export const explain = (text: string, mobileHide = false, topMargin = '-0.6', size = '2') => 
<ImNotification 
    style={{fontSize: `${size}rem`, marginLeft: '0.5rem', marginTop: `${topMargin}rem`}} 
    data-tip={text}
    className={mobileHide ? 'hide-tooltip' : ''}
    //data-place="left"
    //data-multiline={true}
    data-class="my-tooltip"
/> 