import ExploreHome from './home'
import GetLoanType from './getLoan/loanType'
import LoanDuration from './getLoan/loanDuration'
import LoanTypeList from './loanTypeList'
import LoanCategory from './loanCategory'
import LoanRequest from './loanRequest'

export default [
    ExploreHome,
    GetLoanType,
    LoanDuration,
    LoanTypeList,
    LoanCategory,
    LoanRequest
]