import { useState, useContext } from 'react'
import ApiContext from 'src/provider/API/call-service'
import { images } from 'src/provider/config/constant'
import { 
    SidePopupContainer, 
    SidePopup, 
    FormGroup, 
    Form, 
    Button, 
    CustomContainer, 
    FlexColumn, 
    DataWithLabel, 
    FormGroupWithIcon,
    Divider,
    FormGroupsWrapper
} from "src/styles"


export const AddCard: React.FC<any> = ({close, hasCard}) => {
    const {API} = useContext(ApiContext)
    const [isCard, setIsCard] = useState(hasCard)
    const [input, setInput] = useState({cardNumber: '', expiryDate: '', CVV: ''})

    const handleInput = (e: React.ChangeEvent<HTMLInputElement>) => setInput({...input, [e.target.name]: e.target.value})

    async function handleAddCard () {
        const response = await API.addCard()
    }

    return (
        <SidePopupContainer >
            <SidePopup data-aos="slide-left">
                { !isCard &&
                    <CustomContainer>
                        <div className="popup-title-wrapper">
                            <h4>Card</h4>
                            <i className="fa fa-times" onClick={() => close(false)}></i>
                        </div>
                        <FlexColumn topMargin="12">
                            <img src={images.CARD_ICON} className="card-center-icon" />
                            <DataWithLabel 
                                titleSize="1.8" 
                                textSize="1.4" 
                                titleWeight="700" 
                                color="#E2E2E2"
                                titleColor="#fff"
                                className="data-with-label"
                                fontWeight="400"
                            >
                                <label style={{marginBottom: '0.5rem'}} >No Card Added</label>
                                <span>You do not have any Card added yet.</span>
                            </DataWithLabel>

                            <Button
                                width="25"
                                height="4"
                                hoverBgColor="#FED0B7" 
                                topMargin="10"
                                onClick={() => setIsCard(true)}
                            >
                                Add Card
                            </Button>
                        </FlexColumn>
                    </CustomContainer>
                }
                
                { isCard &&
                    <CustomContainer data-aos="slide-up">
                        <div className="popup-title-wrapper">
                            <h4>Add Card</h4>
                            <i className="fa fa-times" onClick={() => close(false)}></i>
                        </div>
                        <FormGroup>
                            <label>Enter Card Number</label>
                            <input 
                                type="number" 
                                name="cardNumber" 
                                value={input.cardNumber} 
                                placeholder="Card number" 
                                onChange={handleInput} 
                            />
                        </FormGroup>
                        <FormGroupsWrapper>
                            <FormGroup width="49" rightMargin="2">
                                <label>Expiry Date</label>
                                <input 
                                    type="number" 
                                    name="expiryDate" 
                                    value={input.expiryDate} 
                                    onChange={handleInput} 
                                    placeholder="MM/YY" 
                                />
                            </FormGroup>
                            <FormGroup width="49">
                                <label>CVV</label>
                                <input 
                                    type="number" 
                                    placeholder="123" 
                                    name="CVV" 
                                    value={input.CVV}
                                    maxLength={3}
                                    onChange={handleInput} 
                                />
                            </FormGroup>
                        </FormGroupsWrapper>
                        <p className="payment-notice">You will be charged a sum of N100 to add your card.</p>
                        <Button
                            width="23"
                            hoverBgColor="#FED0B7" 
                            topMargin="6"
                            onClick={handleAddCard}
                        >
                            Add card
                        </Button>
                    </CustomContainer>
                }
            </SidePopup>
        </SidePopupContainer>
    )
}
