import '../loanOffers/style.scss'
import { useEffect, useState, useContext } from 'react'
import utility from 'src/utils/utility'
import ApiContext from 'src/provider/API/call-service'
import LoanContext from 'src/provider/state-manager/loanProvider'
import { useNavigate } from 'react-router-dom'
import { BsCheck, BsChevronLeft } from 'react-icons/bs'
import { BiMenuAltRight } from 'react-icons/bi'
import { FiSearch } from 'react-icons/fi'
import { RiCloseFill } from 'react-icons/ri'
import { ScreenContainer, Divider, AppSpan, AppText, Button, Circle, AbsoluteContainer, FlexRow, FixedContainer, CustomContainer, Icon } from "src/styles"
import { images, sortingCategory as category, sortingOrder as order } from 'src/provider/config/constant'
import { FilterOffers, FilterOffersNew, SelectedOffers } from 'src/popup'
import { AiOutlineSortAscending } from 'react-icons/ai'
import { type } from 'os'

const LoanOfferSelection = () => {
    const navigate = useNavigate()
    const {API} = useContext(ApiContext)
    const {loan: {requestLoanOffers, selectedLoanOffer, currentProcessType}, setLoanProperty} = useContext(LoanContext)
    const [loanOffers, setLoanOffers] = useState<any>(null)
    const [currentProcessUser, setCurrentProcessUser] = useState('')
    const [searchText, setSearchText] = useState('')
    const [sortCategory, setSortCategory] = useState('amountPerPerson')
    const [sortOrder, setSortOrder] = useState('asc')
    const [sortType, setSortType] = useState('')
    const [isSortDropdown, setIsSortDropdown] = useState(false)
    const [showFilterBy, setShowFilterBy] = useState(false)
    const [selectedOffers, setSelectedOffers] = useState<any>([])
    const [showSelectedOffers, setShowSelectedOffers] = useState(false)

    let typeText = currentProcessUser === 'borrower' ? 'offer' : 'request' 

    useEffect(() => {
        //setLoanOffers(requestLoanOffers)
        setSelectedOffers([selectedLoanOffer])
        setCurrentProcessUser(currentProcessType)

        let offers_requests = []
        
        for (let item of requestLoanOffers) {
            if (currentProcessType === 'borrower') {
                if(item.loanOfferId != selectedLoanOffer?.loanOfferId) offers_requests.push(item)
            }
            else {
                if(item.loanRequestId != selectedLoanOffer?.loanRequestId) offers_requests.push(item)
            }
        }
        setLoanOffers(offers_requests)
    }, [requestLoanOffers, selectedLoanOffer, currentProcessType])

    useEffect(() => {
        document.body.style.overflow = 'auto'
    }, [])

    useEffect(() => {
        if(showFilterBy || showSelectedOffers) {
          document.body.style.overflow = 'hidden'
        }
        else document.body.style.overflow = 'auto'
    }, [showFilterBy, showSelectedOffers])

    async function handleSelectedOffer (data: any, dataIndex: number) {
        if (selectedOffers.length < 3) {
            setSelectedOffers([...selectedOffers, data])

            const allOffers = loanOffers.filter((item: any, index: number) => dataIndex != index)
            setLoanOffers(allOffers)
        }
        // else {
        //     let offers = [...selectedOffers]
        //     offers.pop()
        //     setSelectedOffers([...offers, data])
        // }
    }

    async function handleSorting (val: string) {
        sortType === 'category' ? setSortCategory(val) : setSortOrder(val)

        let data = requestLoanOffers;
        if ((sortType === 'order' && val === 'asc') || (sortType === 'category' && sortOrder === 'asc')) {
            data = data.sort((a: any, b: any) => Number(a[val] || a[sortCategory]) - Number(b[val] || b[sortCategory]));
        }
        else {
            data = data.sort((a: any, b: any) => Number(b[val] || b[sortCategory]) - Number(a[val] || a[sortCategory]));
        }

        setLoanProperty('requestLoanOffers', data)
        setIsSortDropdown(false)
    }

    function handleSortType (type: string) {
        setSortType(type)
        setIsSortDropdown(true)
    }

    async function handleRemoveSelectedOffer (dataIndex: number) {
        // add offer_request back to the offers list
        setLoanOffers([...loanOffers, selectedOffers[dataIndex]])
        
        // remove offer_request from the selected offers_requests list
        const allSelectedOffers = selectedOffers.filter((item: any, index: number) => dataIndex != index)
        setSelectedOffers(allSelectedOffers)
    }

    async function handleSearch (e: React.FormEvent<HTMLButtonElement | HTMLFormElement>) {
        e.preventDefault()
        handleOfferSearch()
    }

    async function handleOfferSearch () {
        if (!searchText) return false
        await API.filterLoanOffer({searchText})
    }

    return (
        <>
            <ScreenContainer>
                <div className="loan-offers-container">
                    <div className="loan-offer-title-wrapper">
                        <div className="loan-offers-title">
                            <BsChevronLeft className="loan-back-icon" onClick={() => navigate(-1)} />
                            <div className="loan-type-title-content">
                                <p >Loan {typeText}s</p>
                                { selectedOffers.length < 3 ?
                                    <span>Select {3 - selectedOffers.length} more loan {typeText}s</span>
                                    :
                                    <span>3 loan {typeText}s selected</span>
                                }
                            </div>
                        </div>
                        <div className="search-for-offers">
                            <form className="search-input-wrapper" onSubmit={handleSearch}>
                                <FiSearch 
                                    className="search-icon-center" 
                                    style={{color: searchText ? '#6DE7B4' : '#999'}}
                                    onClick={handleOfferSearch}
                                />
                                <input 
                                    className="search-offer-input" 
                                    placeholder="Search by loanType, amount e.t.c"
                                    onChange={(e) => setSearchText(e.target.value)}
                                />
                            </form>
                            <CustomContainer 
                                width='auto' size='' borderColor='#4B5D99' 
                                radius='0.4' leftMargin='1'
                                style={{overflow: 'visible'}}
                            >
                                <AppSpan className='sort-by-text' onClick={() => handleSortType('category')}>
                                    Sort By
                                </AppSpan>
                                <AiOutlineSortAscending className="sort-icon" onClick={() => handleSortType('order')} />
                                {
                                    isSortDropdown &&
                                    <AbsoluteContainer top='5'>
                                        <CustomContainer 
                                            bgColor='#081952' width='13'
                                            radius='0.4'
                                            topPadding='1' bottomPadding='1'
                                            style={{boxShadow: '0px 10px 50px rgba(0, 0, 0, 0.5)'}}
                                        >
                                            {
                                                sortType === 'category' ?
                                                category.map((item: any, index: number) => {
                                                    return (
                                                        <FlexRow className='sort-select-option' onClick={() => handleSorting(item.value)} key={index}>
                                                            <AppSpan fontWeight="400" textSize="1.3">{item.name}</AppSpan>
                                                            {sortCategory === item.value ? <BsCheck style={{color: '#FED0B7', fontSize: '2rem'}} /> : null}
                                                        </FlexRow>
                                                    )
                                                })
                                                : 
                                                order.map((item: any, index: number) => {
                                                    return (
                                                        <FlexRow className='sort-select-option' onClick={() => handleSorting(item.value)} key={index}>
                                                            <AppSpan fontWeight="400" textSize="1.3">{item.name}</AppSpan>
                                                            {sortOrder === item.value ? <BsCheck style={{color: '#FED0B7', fontSize: '2rem'}} /> : null}
                                                        </FlexRow>
                                                    )
                                                })
                                            }
                                        </CustomContainer>
                                    </AbsoluteContainer>
                                }
                            </CustomContainer>
                            <AppSpan 
                                onClick={() => setShowFilterBy(true)}
                                className="filter-text-icon"
                            >
                                Filter
                                <BiMenuAltRight className="flex-item-icon"/>
                            </AppSpan>
                        </div>
                    </div>

                    <div className="loan-offers-content">
                        {
                            Array.from(Array(3).keys()).map((item: any, index: number) => {
                                return (
                                    selectedOffers[index] ? 
                                    <div className="loan-offer-info loan-offer-selected" key={index}>
                                        <div className="loan-offer-header">
                                            <div className="loan-offer-label">
                                                <img src={images.LOAN_OFFER_ICON} className="loan-offer-header-icon" />
                                                <div className="loan-offer-label-text">
                                                    {/* <label>LoanType</label> */}
                                                    <p className='loan-offer-type-text'>{selectedOffers[index].loanTypeName}</p>
                                                </div>
                                            </div>
                                            <span className="loan-offer-percentage" style={{color: '#6DE7B4'}}>{selectedOffers[index].interest}%p.m</span>
                                        </div>
                                        <Divider margin="1" />
                                        <div className="flex-row-space-between margin-vertical-1-5">
                                            <div className="loan-offer-label-text">
                                                <label>Amount</label>
                                                <p>N{utility.moneyFormat(selectedOffers[index].amountPerPerson || selectedOffers[index].amount)}</p>
                                            </div>
                                            <div className="loan-offer-label-text width-10-rem">
                                                <label>Duration</label>
                                                <p>{selectedOffers[index].tenor} {selectedOffers[index]?.tenorType?.toLowerCase()}(s)</p>
                                            </div>
                                        </div>
                                        <div className="flex-row-space-between margin-vertical-1-5">
                                            <div className="loan-offer-label-text">
                                                <label>Payment</label>
                                                <p>{selectedOffers[index].rateId === 1 ? 'Installment' : 'One-off'}</p>
                                            </div>
                                            {   currentProcessType === 'borrower' ?
                                                // <div className="loan-offer-label-text width-10-rem">
                                                //     <label>Lender's rep</label>
                                                //     <p style={{color: '#6DE7B4'}}>{selectedOffers[index].lenderRep || 89.2}%</p>
                                                // </div>
                                                null
                                                :
                                                <div className="loan-offer-label-text width-10-rem">
                                                    <label>Crendly score</label>
                                                    <p style={{color: '#6DE7B4'}}>{selectedOffers[index].creditScoreInfo?.grandTotal || 0}%</p>
                                                </div>
                                            }
                                            
                                        </div>
                                        <AbsoluteContainer top='-1' right='-0.8'>
                                            <Circle bgColor='#FF0062' onClick={() => handleRemoveSelectedOffer(index)}>
                                                <RiCloseFill style={{color: '#fff'}} />
                                            </Circle>
                                        </AbsoluteContainer>
                                        <AbsoluteContainer bottom='-0.8' right='-0.8'>
                                            <Circle bgColor='#122C85' borderColor='#fff'>
                                                <AppSpan textSize='1.3' topMargin='-0.1'>{index+1}</AppSpan>
                                            </Circle>
                                        </AbsoluteContainer>
                                    </div>
                                : 
                                    <div className="loan-offer-info loan-offer-selected-none" key={index}>
                                        <AppText fontWeight='400' textSize='1.3' color="rgba(255,255,255,0.5)">Loan {utility.toTitleCase(typeText)} {index+1}</AppText>
                                    </div>
                               )
                            })
                        }
                    </div>
                    <Divider margin='0.5'/>
                    <div className="loan-offers-content loan-offers-to-select">
                        {
                            loanOffers?.map((item: any, index: number) => {
                                //let color = index % 5 === 0 ?  "#FF3A5A" : index % 3 === 0 ? '#FFBC3A' : "#6DE7B4"
                                return (
                                    <div className="loan-offer-info" key={index} onClick={() => handleSelectedOffer(item, index)}>
                                        <div className="loan-offer-header">
                                            <div className="loan-offer-label">
                                                <img src={images.LOAN_OFFER_ICON} className="loan-offer-header-icon" />
                                                <div className="loan-offer-label-text">
                                                    {/* <label>LoanType</label> */}
                                                    <p className='loan-offer-type-text'>{item.loanTypeName}</p>
                                                </div>
                                            </div>
                                            <span className="loan-offer-percentage" style={{color: '#6DE7B4'}}>{item.interest}%p.m</span>
                                        </div>
                                        <Divider margin="1" />
                                        <div className="flex-row-space-between margin-vertical-1-5">
                                            <div className="loan-offer-label-text">
                                                <label>Amount</label>
                                                <p>N{utility.moneyFormat(item.amountPerPerson || item.amount)}</p>
                                            </div>
                                            <div className="loan-offer-label-text width-10-rem">
                                                <label>Duration</label>
                                                <p>{item.tenor} {item?.tenorType?.toLowerCase()}(s)</p>
                                            </div>
                                        </div>
                                        <div className="flex-row-space-between margin-vertical-1-5">
                                            <div className="loan-offer-label-text">
                                                <label>Payment</label>
                                                <p>{item.rateId === 1 ? 'Installment' : 'One-off'}</p>
                                            </div>
                                            {   currentProcessType === 'borrower' ?
                                                // <div className="loan-offer-label-text width-10-rem">
                                                //     <label>Lender's rep</label>
                                                //     <p style={{color: '#6DE7B4'}}>{item?.lendersRep || 0}%</p>
                                                // </div>
                                                null
                                                :
                                                <div className="loan-offer-label-text width-10-rem">
                                                    <label>Crendly score</label>
                                                    <p style={{color: '#6DE7B4'}}>{item?.creditScoreInfo?.grandTotal || 0}%</p>
                                                </div>
                                            }
                                        </div>
                                        <FlexRow justifyContent="flex-start" wrap="wrap">
                                            <Icon src={images.CARD_ICON_STACK} style={{marginLeft: '-0.5rem'}}/>
                                            <AppText textSize="1.2" leftMargin="1">
                                                {item?.numberOfInterestedBorrowers || item?.numberOfPerson - item?.numberOfSlotLeft || 0} person(s) made request
                                            </AppText>
                                        </FlexRow>
                                    </div>
                                )
                            })
                            
                        }
                        <FixedContainer bottom='2' right='1'>
                            <FlexRow 
                                className='loan-selected-notifier-box'
                                justifyContent='space-between'
                            >
                                <CustomContainer rightMargin='1.5' topMargin='1'>
                                    <AppText textSize='2'>{selectedOffers.length} loan {typeText}(s) selected</AppText>
                                    { selectedOffers.length < 3 &&
                                        <AppSpan textSize='1.3' fontWeight='400'>Select {3 - selectedOffers.length} more loan {typeText}(s)</AppSpan>
                                    }
                                </CustomContainer>
                                <Button
                                    width="23"
                                    hoverBgColor="#FED0B7" 
                                    height='3.8'
                                    titleSize='1.4'
                                    topMargin='1'
                                    className="loan-action-btn"
                                    disabled={selectedOffers.length != 3 ? true : false}
                                    onClick={() => setShowSelectedOffers(true)}
                                >
                                    Continue
                                </Button>
                            </FlexRow>
                        </FixedContainer>
                    </div>
                </div>
            </ScreenContainer>
            { showFilterBy &&
                <FilterOffersNew 
                    close={() => setShowFilterBy(false)}
                />
            }
            { showSelectedOffers &&
                <SelectedOffers 
                    close={() => setShowSelectedOffers(false)}
                    data={selectedOffers}
                    removeSelected={handleRemoveSelectedOffer}
                />
            }
        </>
    )
}

export default utility.routeData('loan-board/loan-offer-selection', 'Loan Offer Selection', <LoanOfferSelection/>)