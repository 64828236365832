import './style.scss'
import { useState, useEffect, useContext } from 'react'
import utility, { formatNegativeCharacter } from 'src/utils/utility'
import LoanContext from 'src/provider/state-manager/loanProvider'
import { useNavigate } from 'react-router-dom'
import { BsChevronLeft } from 'react-icons/bs'
import { FormGroup, SelectButton, Button, ScreenContainer } from "src/styles"
import { LoanPortfolio } from 'src/popup'
import helpers from 'src/utils/helpers'


const RepaymentOption = () => {
    const navigate = useNavigate()
    const {loan: {loanCreation}, setLoanProperty} = useContext(LoanContext)
    const [isValidated, setIsValidated] = useState(false)
    const [showLoanPortfolio, setShowLoanPortfolio] = useState(false)
    const [input, setInput] = useState({interest: loanCreation?.interest || '', isInterest: loanCreation?.isInterest || "", isExtensionAllowed: loanCreation?.isExtensionAllowed || false, isNegotiable: loanCreation?.isNegotiable || false, canBeExtendedBy: loanCreation?.canBeExtendedBy || 0})

    useEffect(() => {
        if(showLoanPortfolio) {
          document.body.style.overflow = 'hidden'
        }
        else document.body.style.overflow = 'auto'
    }, [showLoanPortfolio])

      useEffect(() => {
        const auth = input.isExtensionAllowed ? helpers.inputValidator(input, ['isNegotiable']) : helpers.inputValidator(input, ['canBeExtendedBy', 'isNegotiable', 'isExtensionAllowed'])
        setIsValidated(auth.isValidated)
    }, [input])

    function handleInterest (val: string) {
        var interest = input.interest
        if (val == 'Yes') interest = ""
        else interest = '0'
        setInput({...input, isInterest: val, interest: interest})
    }

    const handlePortfolioShow = async () => {
        await setLoanProperty(
            'loanCreation', 
            {...loanCreation, ...input, interest: input.interest}
        )
        setShowLoanPortfolio(true)
    }

    

    function handlePermission (e: React.ChangeEvent<HTMLInputElement>) {
        setInput({...input, [e.target.name]: e.target.checked})
    }

    return (
        <>
            <ScreenContainer>
                <div className="loan-type-container">
                    <div className="loan-type-title">
                        <div className="loan-type-title-flex">
                            <BsChevronLeft className="loan-back-icon" onClick={() => navigate(-1)} />
                            <div className="loan-type-title-content">
                                <p >{loanCreation?.rateId ? 'Edit' : 'Create'} Loan Portfolio</p>
                                <span>
                                    Show how much you want to lend to qualified borrowers. Credit your portfolio with any amount,
                                    include more details like loan type and interest rate to help us find the right match for you.
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className="loan-type-content">
                        {/* <FormGroup>
                            <label>Choose a repayment option</label>
                            <div className="loan-tenor-types">
                                <SelectButton width="14" height="4">
                                    <input type="radio" name="repayment-type" checked={input.rateId === 1} onChange={() => setInput({...input, rateId: 1, repaymentTermId: 0})} />
                                    <span></span>
                                    <label>Installment</label>
                                </SelectButton>
                                <SelectButton width="14" height="4">
                                    <input type="radio" name="repayment-type" checked={input.rateId === 2} onChange={() => setInput({...input, rateId: 2, repaymentTermId: 1})} />
                                    <span></span>
                                    <label>One-Off</label>
                                </SelectButton>
                            </div>
                        </FormGroup>
                        { input.rateId === 1 &&
                            <FormGroup>
                                <label>Repayment occurence</label>
                                <div className="loan-tenor-types">
                                    <SelectButton width="10" height="4" >
                                        <input type="radio" name="repayment-occurence" checked={input.repaymentTermId === 2} onChange={() => setInput({...input, repaymentTermId: 2})}/>
                                        <span></span>
                                        <label>Daily</label>
                                    </SelectButton>
                                    <SelectButton width="10" height="4" >
                                        <input type="radio" name="repayment-occurence" checked={input.repaymentTermId === 3} onChange={() => setInput({...input, repaymentTermId: 3})}/>
                                        <span></span>
                                        <label>Weekly</label>
                                    </SelectButton>
                                    <SelectButton width="10" height="4" >
                                        <input type="radio" name="repayment-occurence" checked={input.repaymentTermId === 4} onChange={() => setInput({...input, repaymentTermId: 4})}/>
                                        <span></span>
                                        <label>BiWeekly</label>
                                    </SelectButton>
                                    <SelectButton width="10" height="4" >
                                        <input type="radio" name="repayment-occurence" checked={input.repaymentTermId === 5} onChange={() => setInput({...input, repaymentTermId: 5})}/>
                                        <span></span>
                                        <label>Monthly</label>
                                    </SelectButton>
                                </div>
                            </FormGroup>
                        } */}
                        <FormGroup>
                            <label>Would you want interest in this loan offer?</label>
                            <div className="loan-tenor-types">
                                <SelectButton width="16" height="4">
                                    <input type="radio" name="loan-interest" checked={input.isInterest === 'Yes'} onChange={() => handleInterest('Yes')}/>
                                    <span></span>
                                    <label>Yes</label>
                                </SelectButton>
                                <SelectButton width="16" height="4">
                                    <input type="radio" name="loan-interest" checked={input.isInterest === 'No'} onChange={() => handleInterest('No')} />
                                    <span></span>
                                    <label>No</label>
                                </SelectButton>
                            </div>
                        </FormGroup>
                        { input.isInterest == 'Yes' &&
                            <>
                                <div className="loan-rate-divider"></div>
                                <FormGroup>
                                    <label>Interest Rate (monthly)</label>
                                    <input 
                                        type="number" 
                                        value={input.interest}
                                        name="interest"
                                        placeholder="Enter Interest" 
                                        onChange={(e) => setInput({...input, interest: formatNegativeCharacter(e.target.value)})} 
                                    />
                                </FormGroup>
                            </>
                        }
                        <div className="loan-rate-divider"></div>
                        <div className="loan-permission-container">
                            <div className="loan-permission-content">
                                <span className="loan-permission-text">Allow loan negotiation</span>
                                <div className="permission-slider-container">
                                    <input type="checkbox" className="permission-checkbox" checked={input.isNegotiable} name="isNegotiable" onChange={handlePermission} />
                                    <div className="permission-slider-shadow"></div>
                                    <span className="permission-slider"></span>
                                </div>
                            </div>
                            <div className="loan-permission-content">
                                <span className="loan-permission-text">Allow loan extension</span>
                                <div className="permission-slider-container">
                                    <input type="checkbox" className="permission-checkbox" checked={input.isExtensionAllowed} name="isExtensionAllowed" onChange={handlePermission} />
                                    <div className="permission-slider-shadow"></div>
                                    <span className="permission-slider"></span>
                                </div>
                            </div>
                        </div>
                        { input.isExtensionAllowed &&
                            <FormGroup topMargin="7">
                                <label>Extend By</label>
                                <div className="loan-tenor-types">
                                    <SelectButton width="10" height="4">
                                        <input type="radio" name="extension-days" checked={input.canBeExtendedBy === 7} onChange={() => setInput({...input, canBeExtendedBy: 7})} />
                                        <span></span>
                                        <label>7 days</label>
                                    </SelectButton>
                                    <SelectButton width="10" height="4">
                                        <input type="radio" name="extension-days" checked={input.canBeExtendedBy === 15} onChange={() => setInput({...input, canBeExtendedBy: 15})} />
                                        <span></span>
                                        <label>15 days</label>
                                    </SelectButton>
                                    <SelectButton width="10" height="4">
                                        <input type="radio" name="extension-days" checked={input.canBeExtendedBy === 30} onChange={() => setInput({...input, canBeExtendedBy: 30})}/>
                                        <span></span>
                                        <label>30 days</label>
                                    </SelectButton>
                                </div>
                            </FormGroup>
                        }
                        <div className="loan-creation-wrapper-plus">
                            <span onClick={() => navigate(-1)}>Back</span>
                            <Button
                                width="13"
                                hoverBgColor="#FED0B7"
                                className="loan-action-btn"
                                disabled={!isValidated ? true : false}
                                onClick={handlePortfolioShow}
                            >
                                Next
                            </Button>
                        </div>
                    </div>
                </div>
            </ScreenContainer>
            { showLoanPortfolio &&
                <LoanPortfolio
                    data={{...loanCreation, ...input}}
                    close={() => setShowLoanPortfolio(false)}
                />
            }
        </>
    )
}

export default utility.routeData('loan-creation/repayment-option', 'Repayment Option', <RepaymentOption/>)