import { useEffect, useState, useContext } from 'react';
import UserContext from 'src/provider/state-manager/userProvider';
import { Chart as ChartJS, registerables } from 'chart.js';
import { Line } from 'react-chartjs-2'
import helpers from 'src/utils/helpers';
ChartJS.register(...registerables);

export const LoanChart = () => {
    const {user: {chartsInfo}} = useContext(UserContext)
    const [loansTaken, setLoansTaken] = useState<any>(null)
    const [loansRepaid, setLoansRepaid] = useState<any>(null)

    useEffect(() => {
        if (chartsInfo?.loanRequestDetails) {
            setLoansTaken(helpers.extractChartData(chartsInfo?.loanRequestDetails, 'totalLoanTaken'))
            setLoansRepaid(helpers.extractChartData(chartsInfo?.loanRequestDetails, 'totalLoanRepaid'))
        }
    }, [chartsInfo])

    return (
        <Line 
            data={{
                labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'],
                datasets: [
                    {
                        label: 'Total Loans Taken',
                        data: loansTaken || [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
                        backgroundColor: 'rgba(109, 231, 180, 0.1)',
                        borderColor: '#FED0B7',
                        pointRadius: 0,
                        fill: true
                        
                    },
                    {
                        label: 'Total Loan Repaid',
                        data: loansRepaid || [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
                        backgroundColor: 'rgba(109, 231, 180, 0.1)',
                        borderColor: '#6DE7B4',
                        pointRadius: 0,
                        fill: true
                    }
                ]
            }}
            height="90%"
            width="100%"
            options={{
                maintainAspectRatio: false, 
                plugins: {
                    legend: {
                        align: 'end',
                        labels: {
                            boxWidth: 10,
                            boxHeight: 10,
                            color: '#fff'
                        }
                    }
                },
                elements: {
                    line: {
                        tension: 0.5
                    }
                },
                scales: {
                    y: {  
                        display: false,
                        ticks: {
                            color: "#fff",
                        }
                    },
                    x: {  
                        ticks: {
                            color: "#fff", 
                        }
                    }
                }
            }}
        />
    )
}
