import './style.scss'
import { useState, useEffect, useContext } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { images } from 'src/provider/config/constant'
import ApiContext from 'src/provider/API/call-service'
import VisibilityContext from 'src/provider/state-manager/visibilityProvider'
import { AppSpan, AppText, Button, FlexColumn } from 'src/styles'
import utility from 'src/utils/utility'
import  {ReactComponent as EmailIcon} from 'src/assets/img/emailVerification.svg'

const EmailVerificationLanding: React.FC = () => {
    const navigate = useNavigate()
    const { id } = useParams()
    const {API} = useContext(ApiContext)
    const {visibility: {isLoading}} = useContext(VisibilityContext)
    const [isVerified, setIsVerified] = useState(false)

    useEffect(() => {
       handleEmailVerification()
    }, [])

    async function handleEmailVerification () {
        const response = await API.verifyEmail(id)
        if (response) setIsVerified(true)
    }

    return (
        <FlexColumn bgColor='#00071F' style={{height: '100%'}}>
            <img className="app-logo" src={images.LOGO} />
            <FlexColumn 
                bgColor='#000926'  
                className='email-verification-wrapper'
            >
                <EmailIcon className='email-verification-icon'/>
                <AppText 
                    align='center' textSize='2' bottomMargin='2'
                    leftMargin='2' rightMargin='2' topMargin='2'
                >
                    {
                        isLoading ? 'Verifying...' :
                        isVerified ? 'Your email has been confirmed':
                        'Email verification failed'
                    }
                </AppText>
                <AppSpan fontWeight='400' textSize='1.3' width='25' align='center'>
                    Thank you for your interest in Crendly,
                </AppSpan>
                <AppSpan fontWeight='400' textSize='1.3' align='center'>
                    {isVerified ? "Let's get you started" : 'Kindly try again later'}
                </AppSpan>
                { !isLoading &&
                    <Button 
                        width="17" 
                        height="4" 
                        hoverBgColor="#FFC49D" 
                        topMargin="8"
                        bottomMargin="3"
                        onClick={() => navigate('/login')}
                    >
                        Continue
                    </Button>
                }
            </FlexColumn>
        </FlexColumn>
    )
}

export default utility.routeData('email-verification/:id', 'Email Verification Landing', <EmailVerificationLanding/>)