import { useState, useContext } from 'react'
import utility from 'src/utils/utility'
import ApiContext from 'src/provider/API/call-service'
import AppInfoContext from 'src/provider/state-manager/appInfoProvider'
import UserContext from 'src/provider/state-manager/userProvider'
import { useNavigate } from 'react-router-dom'
import { Container } from 'src/component'
import { AppLink, AppText, Button, FormGroup, FormGroupsWrapper, ScreenTitle } from 'src/styles'
import { GoChevronDown } from 'react-icons/go'
import { BsChevronLeft } from 'react-icons/bs'

const PersonalInfo = () => {
    const navigate = useNavigate()
    const {API} = useContext(ApiContext)
    const {user} = useContext(UserContext)
    const {info, setInfoProperty} = useContext(AppInfoContext)
    const [isData, setIsData] = useState(true)
    const [input, setInput] = useState({dateOfBirth: '', bvn: '', gender: '', email: ''})

    const handleInput = (e: React.ChangeEvent<HTMLInputElement|HTMLSelectElement>) => setInput({...input, [e.target.name]: e.target.value})

    async function handleNext () {
        // await setInfoProperty('OnboardingData', {...info.OnboardingData, email: input.email})
        // if(input.email) navigate('/onboarding/marital-status')
        // else navigate('/onboarding/email')
        navigate('/onboarding/passcode') 
    }

    return (
        <Container type="onboarding" progressBar='15.38'>
            <p className="right-align-text">Already have an account? 
                <a className="registration-link" onClick={() => navigate('/login')}> Sign in</a>
            </p>
            <div className="onboarding-container">
                <BsChevronLeft className="loan-back-icon" onClick={() => navigate(-1)} />
                <ScreenTitle textSize="3" bottomMargin="1.5" topMargin="3" >
                    Your Personal Information
                </ScreenTitle>
                <AppText color="#D5D5D5" textSize="1.4" fontWeight="400" bottomMargin="5">
                    { isData ?
                        'Please confirm the personal information below is correct.'
                        : 'Please enter your correct personal information'
                    }
                </AppText>
                
                <FormGroupsWrapper className="full-column-flex">
                    <FormGroup  width="48.5" rightMargin="3" bottomMargin="1.5" topMargin="1" className="full-width-flex">
                        <label>First Name</label>
                        <input 
                            disabled
                            type="text" 
                            name="firstName" 
                            placeholder="First name" 
                            value={user.bvnData?.firstName || user.userData?.firstName || ''}
                        />
                    </FormGroup>
                    <FormGroup bottomMargin="1.5" width="48.5" topMargin="1" className="full-width-flex">
                        <label>Last Name</label>
                        <input 
                            disabled
                            type="text" 
                            name="lastName" 
                            placeholder=" Last name"
                            value={user.bvnData?.lastName || user.userData?.lastName || ''}
                        />
                    </FormGroup>
                </FormGroupsWrapper>
                <FormGroupsWrapper className="full-column-flex">
                    <FormGroup  width="48.5" rightMargin="3" bottomMargin="1.5" topMargin="1" className="full-width-flex">
                        <label>Gender</label>
                        <input 
                            disabled
                            type="text" 
                            name="gender" 
                            placeholder="First name" 
                            value={info.onBoardingData?.gender || user.userData?.gender || ''}
                        />
                    </FormGroup>
                    <FormGroup width="48.5" bottomMargin="1.5" topMargin="1" className="full-width-flex">
                        <label>Date of Birth</label>
                        <input 
                            disabled
                            type="date" 
                            name="dateOfBirth" 
                            value={user.bvnData?.dateOfBirth || user.userData?.dateOfBirth || ''} 
                            //style={{color: input.dateOfBirth ? '#fff' : '#999'}}
                        />
                    </FormGroup>
                </FormGroupsWrapper>
                {/* { !isData &&
                    <FormGroupsWrapper className="full-column-flex">
                        <FormGroup width="48.5" bottomMargin="1.5" topMargin="1" className="full-width-flex">
                            <label>Email</label>
                            <input 
                                type="email" 
                                name="email" 
                                value={input.email}
                                placeholder="email address"
                                onChange={handleInput} 
                            />
                        </FormGroup>
                    </FormGroupsWrapper>
                } */}
                {/* <div className="loan-decision-btns" style={{marginTop: '4rem', paddingBottom: '3rem'}}>
                    <AppLink className="negotiate-loan-btn personal-info-link"
                        style={{marginTop: '2rem', marginRight: '2rem'}} 
                        onClick={() => setIsData(true)} 
                    >
                        My info is not correct
                    </AppLink>
                    <Button 
                        width="20" 
                        height="4.5" 
                        leftMargin='2'
                        topMargin='2'
                        hoverBgColor="#FED0B7"
                        className='personal-info-link'
                        onClick={handleNext}
                    >
                        My info is correct
                    </Button>
                </div> */}
                <Button 
                    width="18" 
                    height="4.5" 
                    topMargin='6'
                    hoverBgColor="#FED0B7"
                    onClick={handleNext}
                >
                    My info is correct
                </Button>
            </div>
        </Container>
    )
}

export default utility.routeData('personal-info', 'Personal Information', <PersonalInfo/>)