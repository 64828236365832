import { useState, useContext } from 'react'
import { BiCheck } from 'react-icons/bi'
import { filterAmountRange, filterInterestRange, filterDurationRange } from 'src/provider/config/constant'
import ApiContext from 'src/provider/API/call-service'
import LoanContext from 'src/provider/state-manager/loanProvider'
import { SidePopupContainer, SidePopup, Button, FlexEndWrapper, FlexRow, Circle, AppSpan, CustomContainer, AppText, ChartToggleTypes, ChartToggleText, ToggleTypes, ToggleText } from "src/styles"
import utility from 'src/utils/utility'
import helpers from 'src/utils/helpers'

export const FilterOffersNew: React.FC<any> = ({isLocalFilter, updateData, close}) => {
    const {API} = useContext(ApiContext)
    const {loan: {currentProcessType, requestLoanOffers}} = useContext(LoanContext)
    const [durationType, setDurationType] = useState('Days')
    const [input, setInput] = useState({
        amountFrom: null, amountTo: null,
        durationFrom: null, durationTo: null,
        interestFrom: null, interestTo: null
    })
    
    async function handleFilter () {
        if (!isLocalFilter) {
            if (currentProcessType === 'lender') await API.filterLoanRequest(input) 
            else await API.filterLoanOffer(input)
        }
        else {
            const filtered = helpers.filterRequest_Offer(requestLoanOffers, input)
            if (updateData) updateData(filtered)
        }
        
        close()
    }

    return (
        <SidePopupContainer >
            <SidePopup data-aos="slide-left">
                <div className="popup-title-wrapper">
                    <h4>Filter By</h4>
                    <i className="fa fa-times" onClick={close}></i>
                </div>
                <CustomContainer>
                    <CustomContainer 
                        borderColor='rgba(128, 149, 224, 0.2)'
                        topPadding='1.5'
                        leftPadding='1.5' rightPadding='1.5'
                        radius='0.4' bottomMargin='2'
                    >
                        <AppText textSize='1.3' bottomMargin='2'>Amount</AppText>
                        <FlexRow wrap='wrap' justifyContent='flex-start'>
                            {
                                filterAmountRange?.map((item: any, index: number) => {
                                    let amountFrom = item.split('-')[0]
                                    let amountTo = item.split('-')[1]
                                    let isChecked = `${input.amountFrom}-${input.amountTo}` === `${amountFrom}-${amountTo}`
                                    return (
                                        <FlexRow 
                                            bottomMargin='2.5' width='auto' size='' 
                                            className='filter-entry' key={index}
                                            justifyContent='flex-start' rightMargin='4'
                                        >
                                            <Circle 
                                                borderColor='#6DE7B4' size='2' 
                                                bgColor={isChecked ? '#6DE7B4' : 'transparent'}
                                                onClick={() => setInput({...input, amountFrom, amountTo})}
                                            >
                                                { isChecked && <BiCheck/> }
                                            </Circle>
                                            <AppSpan textSize='1.2' leftMargin='1' fontWeight='400'> ₦{utility.moneyFormat(amountFrom)} - ₦{utility.moneyFormat(amountTo)}</AppSpan>
                                        </FlexRow>
                                    )
                                })
                            }
                        </FlexRow>
                    </CustomContainer>

                    <CustomContainer 
                        borderColor='rgba(128, 149, 224, 0.2)'
                        topPadding='1.5'
                        leftPadding='1.5' rightPadding='1.5'
                        radius='0.4' bottomMargin='2'
                    >
                        <AppText textSize='1.3' bottomMargin='2'>Interest</AppText>
                        <FlexRow wrap='wrap' justifyContent='flex-start'>
                            {
                                filterInterestRange?.map((item: any, index: number) => {
                                    let interestFrom = item.split('-')[0]
                                    let interestTo = item.split('-')[1]
                                    let isChecked = `${input.interestFrom}-${input.interestTo}` === `${interestFrom}-${interestTo}`
                                    return (
                                        <FlexRow 
                                            bottomMargin='2.5' width='auto' size='' 
                                            className='filter-entry' key={index}
                                            justifyContent='flex-start' rightMargin='4'
                                        >
                                            <Circle 
                                                borderColor='#6DE7B4' size='2' 
                                                bgColor={isChecked ? '#6DE7B4' : 'transparent'}
                                                onClick={() => setInput({...input, interestFrom, interestTo})}
                                            >
                                                { isChecked && <BiCheck/> }
                                            </Circle>
                                            <AppSpan textSize='1.2' leftMargin='1' fontWeight='400'> {interestFrom} - {interestTo}%</AppSpan>
                                        </FlexRow>
                                    )
                                })
                            }
                        </FlexRow>
                    </CustomContainer>

                    <CustomContainer 
                        borderColor='rgba(128, 149, 224, 0.2)'
                        topPadding='1.5'
                        leftPadding='1.5' rightPadding='1.5'
                        radius='0.4' bottomMargin='2'
                    >
                        <FlexRow justifyContent='space-between' bottomMargin='2'>
                            <AppText textSize='1.3'>Duration</AppText>
                            <ToggleTypes type={durationType}>
                                <ToggleText
                                    isPicked={durationType === 'Days' ? true : false}
                                    onClick={() => setDurationType('Days')}
                                >
                                    Days
                                </ToggleText>
                                <ToggleText
                                    isPicked={durationType === 'Weeks' ? true : false}
                                    onClick={() => setDurationType('Weeks')}
                                >
                                    Weeks
                                </ToggleText>
                                <ToggleText
                                    isPicked={durationType === 'Months' ? true : false}
                                    onClick={() => setDurationType('Months')}
                                >
                                    Months
                                </ToggleText>
                                <div className="chart-toggler"></div>
                            </ToggleTypes>
                        </FlexRow>
                        
                        <FlexRow wrap='wrap' justifyContent='flex-start'>
                            {
                                filterDurationRange?.map((item: any, index: number) => {
                                    let durationFrom = item.split('-')[0]
                                    let durationTo = item.split('-')[1]
                                    let isChecked = `${input.durationFrom}-${input.durationTo}` === `${durationFrom}-${durationTo}`
                                    return (
                                        <FlexRow 
                                            bottomMargin='2.5' width='auto' size='' 
                                            className='filter-entry' key={index}
                                            justifyContent='flex-start' rightMargin='4'
                                        >
                                            <Circle 
                                                borderColor='#6DE7B4' size='2' 
                                                bgColor={isChecked ? '#6DE7B4' : 'transparent'}
                                                onClick={() => setInput({...input, durationFrom, durationTo})}
                                            >
                                                { isChecked && <BiCheck/> }
                                            </Circle>
                                            <AppSpan textSize='1.2' leftMargin='1' fontWeight='400'> {durationFrom} - {durationTo} {durationType}</AppSpan>
                                        </FlexRow>
                                    )
                                })
                            }
                        </FlexRow>
                    </CustomContainer>

                    <FlexEndWrapper topMargin="5">
                        <Button
                            width="18"
                            hoverBgColor="#FED0B7" 
                            value="submit"
                            className="loan-action-btn"
                            onClick={handleFilter}
                        >
                            Continue
                        </Button>
                    </FlexEndWrapper>
                </CustomContainer>
            </SidePopup>
        </SidePopupContainer>
    )
}
