import utility from 'src/utils/utility'
import { useContext, useState } from 'react'
import LoanContext from 'src/provider/state-manager/loanProvider'
import { images } from 'src/provider/config/constant'
import { SidePopupContainer, SidePopup, Divider, Button, AppText, AppLabel, CustomContainer, FlexColumn  } from "src/styles"
import { BiCheck } from 'react-icons/bi'
import { ProposeOffer } from 'src/popup'

export const CompareLoan: React.FC<any> = ({selectedLoan, CompareWithLoan, setChosenOffer, close}) => {
    const {loan: {currentProcessType}} = useContext(LoanContext)
    const [showProposeOffer, setShowProposeOffer] = useState(false)
    const [chosenRequest, setChosenRequest] = useState<any>(null)

    let isBorrower = currentProcessType === 'borrower' ? true : false

    async function handleChosenOffer_Request (offer_request: any) {
        if (isBorrower) {
            setChosenOffer(offer_request)
            close()
        }
        else {
            setChosenRequest(offer_request)
            setShowProposeOffer(true)
        }
    }

    return (
        <>
            { !showProposeOffer &&
                <SidePopupContainer >
                    <SidePopup width="90" data-aos="slide-left">
                        <div className="popup-title-wrapper" style={{marginBottom: '0rem'}}>
                            <h4>Compare Loan</h4>
                            <i className="fa fa-times" onClick={close}></i>
                        </div>
                        <Divider margin="2.5" bgColor='#2E4DBD' />
                        <div className="loan-compare-header">
                            <div className="loan-peck-container">
                                <AppLabel textSize='1.9' color='#fff' topMargin='2.5'>{isBorrower ? 'Loan Pecks' : 'Requested by'}</AppLabel>
                                <AppText textSize='1.55' fontWeight='400' topPadding='4.8'>Amount</AppText>
                                <AppText textSize='1.55' fontWeight='400' topPadding='4'>Interest Rate</AppText>
                                <AppText textSize='1.55' fontWeight='400' topPadding='3'>Duration</AppText>
                                <AppText textSize='1.55' fontWeight='400' topPadding='3.7'>Payment</AppText>
                                <AppText textSize='1.55' fontWeight='400' topPadding='3.5'>{isBorrower ? 'Reputation' : 'Crendly Score'}</AppText>
                            </div>
                            <div className="loan-compare-info" >
                                <div className="loan-offer-header">
                                    <div className="loan-offer-label">
                                        <img src={images.LOAN_OFFER_ICON} className="loan-offer-header-icon" alt='' />
                                        <div className="loan-offer-label-text">
                                            { isBorrower ?
                                                <>
                                                    <label>LoanType</label>
                                                    <p>{selectedLoan?.loanTypeName}</p>
                                                </>
                                                :
                                                <p>{selectedLoan?.firstName} {selectedLoan?.lastName}</p>
                                            }
                                        </div>
                                    </div>
                                </div>
                                <Divider margin="1.5" />
                                <AppText textSize='2' align='center' topPadding='1.5'>N{utility.moneyFormat(selectedLoan?.amountPerPerson || selectedLoan?.amount)}</AppText>
                                <Button 
                                    width="100" sizeUnit='%' bgColor='#000B2F'
                                    disabled borderColor='#01175F' radius='0.4'
                                    color='#6DE7B4'titleSize='1.8'
                                    topMargin='2.5'
                                >
                                    {selectedLoan?.interest}% per annum
                                </Button>
                                <AppText textSize='2' align='center' topPadding='1.7'>{selectedLoan?.tenor} {selectedLoan?.tenorType?.toLowerCase()}(s)</AppText>
                                <AppText textSize='2' align='center' topPadding='3'>
                                    {selectedLoan?.rateId === 1 ? 'Installmental' : 'One-off'} 
                                    {selectedLoan?.rateId === 1 ? `(${selectedLoan?.repaymentTermName})` : null}
                                
                                </AppText>
                                <AppText textSize='2' align='center' topPadding='2.5' color='#FFBC3A'>{(isBorrower ? selectedLoan?.lendersRep : selectedLoan?.creditScoreInfo?.grandTotal) || 0}%</AppText>
                                <Divider margin="3" />
                                <FlexColumn>
                                    <Button width="22" hoverBgColor="#FED0B7" onClick={()=> handleChosenOffer_Request(selectedLoan)}>
                                        {isBorrower ? 'Get Selected Loan' : 'Propose Offer'}
                                    </Button>
                                </FlexColumn>
                            </div>
                            <div className="loan-compare-info" >
                                <div className="loan-offer-header">
                                    <div className="loan-offer-label">
                                        <img src={images.LOAN_OFFER_ICON} className="loan-offer-header-icon" alt='' />
                                        <div className="loan-offer-label-text">
                                            { isBorrower ?
                                                <>
                                                    <label>LoanType</label>
                                                    <p>{CompareWithLoan?.loanTypeName}</p>
                                                </>
                                                :
                                                <p>{CompareWithLoan?.firstName} {CompareWithLoan?.lastName}</p>
                                            }
                                        </div>
                                    </div>
                                </div>
                                <Divider margin="1.5" />
                                <AppText textSize='2' align='center' topPadding='1.5'>N{utility.moneyFormat(CompareWithLoan?.amountPerPerson || CompareWithLoan?.amount)}</AppText>
                                <AppText textSize='2' align='center' topPadding='3.5'>{CompareWithLoan?.interest}% per annum</AppText>
                                <AppText textSize='2' align='center' topPadding='2.5'>{CompareWithLoan?.tenor} {CompareWithLoan?.tenorType?.toLowerCase()}(s)</AppText>
                                <Button 
                                    width="100" sizeUnit='%' bgColor='#000B2F'
                                    disabled borderColor='#01175F' radius='0.4'
                                    color='#6DE7B4'titleSize='1.8'
                                    topMargin='2' leftPadding='3'
                                >
                                    {CompareWithLoan?.rateId === 1 ? 'Installmental' : 'One-off'} 
                                    {
                                        CompareWithLoan?.rateId === 1 ? `(${CompareWithLoan?.repaymentTermName})` : 
                                        <BiCheck style={{marginLeft: '1rem'}} />
                                    }
                                </Button>
                                <CustomContainer topMargin='1.2'>
                                    <Button 
                                        width="100" sizeUnit='%' bgColor='#000B2F'
                                        disabled borderColor='#01175F' radius='0.4'
                                        color='#6DE7B4'titleSize='1.8'
                                        leftPadding='3'
                                    >
                                        {isBorrower ? CompareWithLoan?.lendersRep : CompareWithLoan?.creditScoreInfo?.grandTotal || 0}%
                                        <BiCheck style={{marginLeft: '1rem'}} />
                                    </Button>
                                </CustomContainer>
                                <Divider margin="2.2" />
                                <FlexColumn>
                                    <Button 
                                        width="22" topMargin="0.7" hoverBgColor="#FED0B7" 
                                        onClick={()=> handleChosenOffer_Request(CompareWithLoan)}
                                    >
                                        {isBorrower ? 'Get this Loan' : 'Propose Offer'}
                                    </Button>
                                </FlexColumn>
                            </div>
                        </div>
                    </SidePopup>
                </SidePopupContainer>
            }
            { showProposeOffer &&
                <ProposeOffer
                    close={() => setShowProposeOffer(false)}
                    data={chosenRequest}
                />
            }
        </>
    )
}
