import { Document, Page, Text, View, StyleSheet, PDFDownloadLink } from '@react-pdf/renderer';


export const MyDocument = () => {
    return (
        <Document>
            <Page size="A4" style={styles.page}>
            <View style={styles.section}>
                <Text>Section #1</Text>
            </View>
            <View style={styles.section}>
                <Text>Section #2</Text>
            </View>
            </Page>
        </Document>
    )
}

{/* <PDFDownloadLink 
    document={<MyDocument />} 
    fileName='statement-test.pdf'
>
    Download Statement
</PDFDownloadLink> */}

const styles = StyleSheet.create({
    page: {
      flexDirection: 'row',
      backgroundColor: '#E4E4E4'
    },
    section: {
      margin: 10,
      padding: 10,
      flexGrow: 1
    }
  });
  