import './style.scss'
import { useState, useContext, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import VisibilityContext from 'src/provider/state-manager/visibilityProvider'
import UserContext from 'src/provider/state-manager/userProvider'
import AppInfoContext from 'src/provider/state-manager/appInfoProvider'
import { GoGift } from 'react-icons/go';
import { FaRegBell } from 'react-icons/fa'
import { BsEnvelope } from "react-icons/bs";
import { images } from 'src/provider/config/constant'
import { NavBarContainer, MenuIcon, Icon, Circle, RoundImageContainer, CustomContainer, AppSpan, AbsoluteContainer, GridContainer, FlexColumn } from 'src/styles'
import { Notifications, WalletTopup, LoanAgreement, SignOffer, FinancialAdvice } from 'src/popup'
import {getLoanStatusColor} from 'src/utils/utility'

export const NavBar = () => {
  const navigate = useNavigate()
  const {user} = useContext(UserContext)
  const {info} = useContext(AppInfoContext)
  const {visibility, showSideBar} = useContext(VisibilityContext)
  const [showNotifications, setShowNotifications] = useState(false)
  const [showWalletTopup, setShowWalletTopup] = useState(false)
  const [showLoanAgreement, setShowLoanAgreement] = useState(false)
  const [showSignature, setShowSignature] = useState(false)
  const [showLoanDetails, setShowLoanDetails] = useState(false)
  const [newNotifCount, setNewNotifCount] = useState(info.newNotifCount)
  const [view, setView] = useState('')

  useEffect(() => {
    if(showNotifications || showWalletTopup || showLoanAgreement || showSignature || showLoanDetails || view) {
      document.body.style.overflow = 'hidden'
    }
    else document.body.style.overflow = 'auto'
  }, [showNotifications, showWalletTopup, showLoanAgreement, showSignature, showLoanDetails, view])

  useEffect(() => {
    setNewNotifCount(info.newNotifCount)
  }, [info.newNotifCount])

  // function handleNavigation (path: string) {
  //   if(path === '/') localStorage.clear()
  //   navigate(path)
  // }

  function handleSidebar () {
    if (visibility.isSideBar) showSideBar(false)
    else showSideBar(true)
  }

  return (
    <>
      <NavBarContainer >
        <a href='https://crendly.com' target='_blank'>
          <img src={images.CRENDLY_LOGO} className="navbar-logo" />
        </a>
        <a href='https://crendly.com' target='_blank'>
          <img src={images.LOGO} className="navbar-logo navbar-logo-sm" />
        </a>
        <p className="page-title">{info.selectedMenu}</p>
        <div className="menu-wrapper">
          <CustomContainer 
            width='auto' sizeUnit='' rightMargin='3'
            className="fin-advice-container"
            data-tip="This is the financial advice the system have for you"
            data-class="my-tooltip"
            onClick={() => setView('fin-advice')}
          >
            <AppSpan textSize='2' >
              {/* <GoGift/> */}
              <BsEnvelope />
            </AppSpan>
            { user.formattedUserData?.financialAdvice &&
              <AbsoluteContainer top='0.3' right='0'>
                <GridContainer 
                  width='1.3' height='1.3' radius='10' bgColor='#ff0062'
                >
                  <AppSpan textSize='0.7'>1</AppSpan>
                </GridContainer>
              </AbsoluteContainer>
            }
          </CustomContainer>
          <div className="bell-wrapper" onClick={() => setShowNotifications(true)}>
            { !!Number(newNotifCount) && <span>{newNotifCount}</span> }
            <FaRegBell className="menu-icon bell-icon"/>
          </div>
          <div className="user-block" onClick={() => navigate('/dashboard/settings/home')}>
            <Circle 
              size='4.9' bgColor='#ffffff' rightMargin='1'
            >
              <Circle 
                size='4.7'
                bgColor={getLoanStatusColor(user.formattedUserData?.loanStatusColorCode)}
              >
                <RoundImageContainer 
                  size="4.2" borderColor='#ffffff'
                  style={{borderWidth: '1.5px'}}
                >
                  <Icon 
                    src={user.formattedUserData?.bioDetail?.profilePicture || images.USER2X} 
                    width='4' radius='50' height='4' noResize
                  />
                </RoundImageContainer>
              </Circle>
            </Circle>
            <span style={{cursor: 'pointer'}}>Hi, {user?.userData?.firstName || 'User'}</span>
          </div>
        </div>
        <MenuIcon onClick={handleSidebar} />
      </NavBarContainer>
      { showNotifications &&
        <Notifications
          close={setShowNotifications}
          walletTopup={() => setShowWalletTopup(true)}
          loanAgreement={() => setShowLoanAgreement(true)}
          loanDetails={() => setShowLoanDetails(true)}
        />
      }
      { showWalletTopup &&
        <WalletTopup
          close={setShowWalletTopup}
        />
      }
      { showLoanAgreement &&
        <LoanAgreement
          close={setShowLoanAgreement}
          signOffer={() => setShowSignature(true)}
        />
      }
      { showSignature &&
        <SignOffer
          close={setShowSignature}
        />
      }
      { view === 'fin-advice' &&
        <FinancialAdvice
          close={() => setView('')} 
        />
      }
      {/* { showLoanDetails &&
        <LoanRequestDetails
          close={() => setShowLoanDetails (false)}
        />
      } */}
    </>
  )
}
