import { useEffect, useContext } from 'react'
import { Outlet, useNavigate } from 'react-router-dom'
import AppInfoContext from 'src/provider/state-manager/appInfoProvider'
import utility from 'src/utils/utility'
import { Container } from 'src/component'
import access from 'src/utils/localAccess'

export const ExploreRoutes = () => {
    const navigate = useNavigate()
    const {logout} = useContext(AppInfoContext)

    useEffect(() => {
        logout(false)
    }, [])
    
    // Set closure access to navigate component method
    useEffect(() => {
        access.setNavigationAccess(navigate)
    }, [])

    return (
        <Container>
            <Outlet/>
        </Container>
    )
}

export default utility.routeData('explore', 'Explore', <ExploreRoutes/>)