import helpers from 'src/utils/helpers'
import {useNavigate} from 'react-router-dom'
import { useState, useEffect, useContext } from 'react'
import ApiContext from 'src/provider/API/call-service'
import LoanContext from 'src/provider/state-manager/loanProvider'
import { SidePopupContainer, SidePopup, FormGroup, Button, DataWithLabel, FormGroupWithIcon, SideBarSummary, FlexEndWrapper, SelectButton, Divider, CustomContainer, FlexRow, AppText } from "src/styles"
import utility from 'src/utils/utility'
import { loanConfig } from 'src/provider/config/constant'

const initialState = {amount: 0, tenor: 3, tenorType: '', interestRate: 15, rateId: 0, repaymentTermId: 1}

export const LoanCalculatorNew: React.FC<any> = ({close}) => {
    const navigate = useNavigate()
    const {API} = useContext(ApiContext)
    const {setLoanProperty, loan: {loanCreation, loanRequest}} = useContext(LoanContext)
    const [isSummarySection, setIsSummarySection] = useState(false)
    const [isValidated, setIsValidated] = useState(false)
    const [isLoanCalc, setIsLoanCalc] = useState(true)
    const [repaymentDetails, setRepaymentDetails] = useState<any>()
    const [input, setInput] = useState({...initialState})

    useEffect(() => {
        const auth = helpers.inputValidator(input)
        setIsValidated(auth.isValidated)

        //if (auth.isValidated) handleRepaymentCalculation()
    }, [input])

    function handleToggle (e: React.ChangeEvent<HTMLInputElement>) {
        if (!e.target.checked) setIsLoanCalc(true)
        else setIsLoanCalc(false)
    }

    const handleInput = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.name === "amount") {
            let val = e.target.value.replace(/,/g, '')
            if (!isNaN(Number(val))) {
                setInput({...input, amount: Number(val)})
            }
        }
        else setInput({...input, [e.target.name]: Number(e.target.value)})
    }

    async function handleRepaymentCalculation () {
        //const response = await API.calculateRepaymentAmount(input)
        const payload = {
            repaymentTerm: loanConfig.repaymentTerm[input.repaymentTermId],
            rate: loanConfig.rate[input.rateId],
            tenor: input.tenor,
            tenorType: input.tenorType,
            loanAmount: input.amount,
            interest: input.interestRate
        }

        const response = await API.calculateLoanRepayment(payload)
        if (response) setRepaymentDetails(response)
    }

    async function handleAction (type: string) {
        const {tenor, tenorType, interestRate, loanAmount} = repaymentDetails
        
        if (type === 'get-loan') {
            await setLoanProperty(
                'loanRequest', 
                {tenor, tenorType, interest: interestRate, amount: loanAmount, repaymentTermId: input.repaymentTermId, rateId: input.rateId}
            )
            navigate('/dashboard/get-loan/loan-type')
        }
        else {
            await setLoanProperty(
                'loanCreation', 
                {tenor, tenorType, interest: interestRate, amount: loanAmount, repaymentTermId: input.repaymentTermId, rateId: input.rateId}
            )
            navigate('/dashboard/loan-creation/loan-type')
        }
    }

    return (
        <SidePopupContainer >
            <SidePopup padding="0" data-aos="slide-left">
                { !isSummarySection &&
                    <>
                        <div className="popup-grey-container-new">
                            <div className="popup-title-wrapper">
                                <h4>Calculator</h4>
                                <i className="fa fa-times" onClick={() => close(false)}></i>
                            </div>

                            <div className="calc-type-toggler">
                                <input type="checkbox" className="calc-toggler-checkbox" defaultChecked={!isLoanCalc} onChange={handleToggle} />
                                <div className="calc-type-slider"></div>
                                <div className="calc-type-text">
                                    <span className="calc-type-1">Loan Calculator</span>
                                    <span className="calc-type-2">Income Calculator</span>
                                </div>
                            </div>
                        </div>
                        <div className="loan-calc-form-new">
                            <FormGroupWithIcon>
                                <label>Amount</label>
                                <div className="input-with-icon">
                                    <span className='amount-symbol'>&#8358;</span>
                                    <input 
                                        name="amount"
                                        type="text"
                                        placeholder="Enter amount" 
                                        className="amount-input"
                                        value={input.amount ? utility.moneyFormat(input.amount) : ''} 
                                        onChange={handleInput}
                                    />
                                </div>
                            </FormGroupWithIcon>
                            <FormGroup>
                                <label>How do you want to make payment?</label>
                                <div className="loan-tenor-types">
                                    <SelectButton width="16" height="4">
                                        <input type="radio" name="payment-type" checked={input.rateId === 1} onChange={() => setInput({...input, rateId: 1, repaymentTermId: 0})} />
                                        <span></span>
                                        <label>Installment</label>
                                    </SelectButton>
                                    <SelectButton width="16" height="4" >
                                        <input type="radio" name="payment-type"checked={input.rateId === 2} onChange={() => setInput({...input, rateId: 2, repaymentTermId: 1, tenorType: 'DAY'})} />
                                        <span></span>
                                        <label>One-Off</label>
                                    </SelectButton>
                                </div>
                            </FormGroup>
                            { input?.rateId === 1 &&
                                <>
                                    <Divider bgColor="#1E3177" />
                                    <FormGroup topMargin="4">
                                        <label>How often you want to make payment?</label>
                                        <div className="loan-tenor-types">
                                            <SelectButton width="13.7" height="4">
                                                <input type="radio" name="payment-duration" checked={input.repaymentTermId === 2} onChange={() => setInput({...input, repaymentTermId: 2, tenorType: 'DAY'})}/>
                                                <span></span>
                                                <label>Daily</label>
                                            </SelectButton>
                                            <SelectButton width="13.7" height="4">
                                                <input type="radio" name="payment-duration" checked={input.repaymentTermId === 3} onChange={() => setInput({...input, repaymentTermId: 3, tenorType: 'WEEK'})} />
                                                <span></span>
                                                <label>Weekly</label>
                                            </SelectButton>
                                            {/* <SelectButton width="10" height="4">
                                                <input type="radio" name="payment-duration" checked={input.repaymentTermId === 4} onChange={() => setInput({...input, repaymentTermId: 4})} />
                                                <span></span>
                                                <label>BiWeekly</label>
                                            </SelectButton> */}
                                            <SelectButton width="13.7" height="4">
                                                <input type="radio" name="payment-duration" checked={input.repaymentTermId === 5} onChange={() => setInput({...input, repaymentTermId: 5, tenorType: 'MONTH'})}/>
                                                <span></span>
                                                <label>Monthly</label>
                                            </SelectButton>
                                        </div>
                                    </FormGroup>
                                </>
                            }
                            <FormGroupWithIcon>
                                <label>Duration</label>
                                <p className="selected-interest-rate" style={{fontSize: '2rem'}}>{input.tenor} {loanConfig.tenorType[input.repaymentTermId]}(s)</p>
                                <div className="range-input-container">
                                    <input type="range" className="range-input" name="tenor" defaultValue={input.tenor} onChange={handleInput} />
                                    <span className="range-progress-shadow" style={{width: `${(input.tenor * 48.2) /50}%`}}></span>
                                </div>
                            </FormGroupWithIcon>
                            <FormGroupWithIcon>
                                <label>Interest Rate ({loanConfig.repaymentTerm[input.repaymentTermId]})</label>
                                <p className="selected-interest-rate" style={{fontSize: '2rem'}}>{input.interestRate}%</p>
                                <div className="range-input-container">
                                    <input type="range" className="range-input" name="interestRate" defaultValue={input.interestRate} onChange={handleInput} />
                                    <span className="range-progress-shadow" style={{width: `${(input.interestRate * 48.2) /50}%`}}></span>
                                </div>
                            </FormGroupWithIcon>
                            <CustomContainer 
                                borderColor='#2C3D73' bgColor='rgba(44, 61, 118, 0.2)'
                                leftPadding='1.5' rightPadding='1.5' radius='0.8'
                            >
                                <FlexRow justifyContent='space-between' topPadding='1' bottomPadding='1'>
                                    <AppText fontWeight='400' textSize='1.6'>Loan Principal</AppText>
                                    <AppText fontWeight='400' textSize='1.6'>&#8358;{utility.moneyFormat(Number(input?.amount))}</AppText>
                                </FlexRow>
                                <FlexRow justifyContent='space-between' topPadding='1' bottomPadding='1'>
                                    <AppText fontWeight='400' textSize='1.6'>Interest Payable</AppText>
                                    <AppText fontWeight='400' textSize='1.6'>&#8358;{utility.moneyFormat(repaymentDetails?.interestAmount || 0)}</AppText>
                                </FlexRow>
                                <FlexRow justifyContent='space-between' topPadding='1' bottomPadding='1'>
                                    <AppText fontWeight='400' textSize='1.6'>{loanConfig.repaymentTerm[input.repaymentTermId]} Repayment</AppText>
                                    <AppText fontWeight='400' textSize='1.6'>&#8358;{utility.moneyFormat(repaymentDetails?.amountPlusInterestPerTenorType || 0)}</AppText>
                                </FlexRow>
                                <FlexRow justifyContent='space-between' topPadding='1' bottomPadding='1'>
                                    <AppText fontWeight='400' textSize='1.6'>Total Repayment</AppText>
                                    <AppText fontWeight='400' textSize='1.6'>&#8358;{utility.moneyFormat(repaymentDetails?.totalAmountPlusInterest || 0)}</AppText>
                                </FlexRow>
                            </CustomContainer>
                            <FlexRow justifyContent='space-between' topMargin='4'>
                                <Button
                                    width="18"
                                    rightMargin="3"
                                    hoverBgColor="#FED0B7"
                                    className="loan-action-btn"
                                    disabled={!isValidated ? true : false}
                                    onClick={handleRepaymentCalculation}
                                >
                                    Calculate
                                </Button>
                                <Button
                                    width="18"
                                    hoverBgColor="#FED0B7"
                                    className="loan-action-btn"
                                    disabled={!repaymentDetails?.interestAmount ? true : false}
                                    onClick={() => setIsSummarySection(true)}
                                >
                                    Continue
                                </Button>
                            </FlexRow>
                        </div>
                    </>
                }
                { isSummarySection &&
                    <div className="popup-main-container" data-aos="slide-up">
                        <div className="popup-title-wrapper">
                            <h4>{isLoanCalc ? 'Loan Summary' : 'Income Summary'}</h4>
                            <i className="fa fa-times" onClick={() => close(false)}></i>
                        </div>
                        <span className="popup-title-summary">
                            Kindly confirm the details of your {isLoanCalc ? 'loan': 'portfolio'} before creating a {isLoanCalc ? 'loan': 'portfolio'}.
                        </span>

                        <SideBarSummary borderType="dashed" topPadding="0">
                            <div className="calc-loan-data">
                                { isLoanCalc ?
                                    <DataWithLabel titleSize="1.3" textSize="1.9" fontWeight="700" className="data-with-label" align='left'>
                                        <label>Amount</label>
                                        <span>&#8358;{utility.moneyFormat(repaymentDetails?.loanAmount)}</span>
                                    </DataWithLabel>
                                    :
                                    <DataWithLabel titleSize="1.3" textSize="1.9" fontWeight="700" className="data-with-label" align='left'>
                                        <label>Portfolio Amount</label>
                                        <span>&#8358;{utility.moneyFormat(repaymentDetails?.loanAmount)}</span>
                                    </DataWithLabel>
                                }
                                <DataWithLabel titleSize="1.3" textSize="1.9" fontWeight="700" className="data-with-label" align='right'>
                                    <label>Duration</label>
                                    <span>{repaymentDetails?.tenor} {repaymentDetails?.tenorType.toLowerCase()}(s)</span>
                                </DataWithLabel>
                            </div>
                            <div className="calc-loan-data">
                                <DataWithLabel titleSize="1.3" textSize="1.9" fontWeight="700" className="data-with-label" align='left'>
                                    <label>{repaymentDetails?.repaymentTerm} {isLoanCalc ? 'Repayment': 'Earnings'}</label>
                                    <span>&#8358;{utility.moneyFormat(repaymentDetails?.amountPlusInterestPerTenorType)}</span>
                                </DataWithLabel>
                                <DataWithLabel titleSize="1.3" textSize="1.9" fontWeight="700" className="data-with-label" align='right'>
                                    <label>Total {isLoanCalc ? 'Repayment': 'Earnings'}</label>
                                    <span>&#8358;{utility.moneyFormat(repaymentDetails?.totalAmountPlusInterest)}</span>
                                </DataWithLabel>
                            </div>
                            <div className="calc-loan-data">
                                <DataWithLabel titleSize="1.3" textSize="1.9" fontWeight="700" className="data-with-label" align='left'>
                                    <label>Interest</label>
                                    <span>{repaymentDetails?.interestRate}%</span>
                                </DataWithLabel>
                                <DataWithLabel titleSize="1.3" textSize="1.9" fontWeight="700" className="data-with-label" align='right'>
                                    <label>Interest Amount</label>
                                    <span>&#8358;{utility.moneyFormat(repaymentDetails?.interestAmount)}</span>
                                </DataWithLabel>
                            </div>
                        </SideBarSummary>
                        <AppText fontWeight='400' textSize='1.3' color='#E2E2E2' topMargin='1.5'>You will be charged a fee of ₦100 +1.5% of the total amount.</AppText>
                        <FlexEndWrapper topMargin="5">
                            <span onClick={() => setIsSummarySection(false)}>Back</span>
                            <Button
                                width="23"
                                hoverBgColor="#FED0B7" 
                                leftMargin="4"
                                onClick={() => handleAction(isLoanCalc ? 'get-loan' : 'create-loan')}
                            >
                                {isLoanCalc ? 'Get this Loan' : 'Create Loan Portfolio'}
                            </Button>
                        </FlexEndWrapper>
                    </div>
                }
            </SidePopup>
        </SidePopupContainer>
    )
}
