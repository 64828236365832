import { useState, useContext } from 'react'
import utility, { required } from 'src/utils/utility'
import ApiContext from 'src/provider/API/call-service'
import AppInfoContext from 'src/provider/state-manager/appInfoProvider'
import { useNavigate } from 'react-router-dom'
import { Container } from 'src/component'
import { AppText, Button, FormGroupWithIcon, ScreenTitle } from 'src/styles'
import { BsChevronLeft } from 'react-icons/bs'
import { GoChevronDown } from 'react-icons/go'

const EducationalBackground = () => {
    const navigate = useNavigate()
    const {API} = useContext(ApiContext)
    const {info} = useContext(AppInfoContext)
    const [input, setInput] = useState(info.onBoardingData?.educationalQualification || '')

   async function handleNext () {
        const response = await API.updateMarital_EducationalStatus(input)
        if (response) navigate('/onboarding/employment-status')
    }

    return (
        <Container type="onboarding" progressBar='38.45'>
            <p className="right-align-text">Already have an account? 
                <a className="registration-link" onClick={() => navigate('/login')}> Sign in</a>
            </p>
            <div className="onboarding-container">
                <BsChevronLeft className="loan-back-icon" onClick={() => navigate(-1)} />
                <ScreenTitle textSize="3" bottomMargin="1.5" topMargin="3" >
                    Educational Background
                </ScreenTitle>
                <AppText color="#D5D5D5" textSize="1.4" fontWeight="400" bottomMargin="2">
                    Please provide a valid educational qualification.
                </AppText>
                <FormGroupWithIcon topMargin="5" >
                    <AppText textSize="1.7" bottomMargin="1.5">What is your highest educational qualification? {required}</AppText>
                    <div className="input-with-icon">
                        <select 
                            name="qualification" 
                            value={input} 
                            onChange={(e) => setInput(e.target.value)} 
                            style={{color: input ? '#fff' : '#999'}}
                        >
                            <option value="">Your Qualification</option>
                            <option value="None">None</option>
                            <option value="Vocational Training">Vocational Training</option>
                            <option value="Primary Education">Primary Education</option>
                            <option value="Secondary Education">Secondary Education</option>
                            <option value="Bachelors Degree/HND">Bachelors Degree/HND</option>
                            <option value="Bachelors Degree + Professional Qualification">Bachelors Degree + Professional Qualification</option>
                            <option value="Masters Degree/PHD">Masters Degree/PHD</option>
                        </select>
                        <GoChevronDown className="input-with-icon-tag" />
                    </div>
                </FormGroupWithIcon>
                <Button 
                    width="15" 
                    height="4.5" 
                    hoverBgColor="#FFC49D" 
                    topMargin="12"
                    bottomMargin="3"
                    className="loan-action-btn"
                    disabled={!input ? true : false}
                    onClick={handleNext}
                >
                    Next
                </Button>
            </div>
        </Container>
    )
}

export default utility.routeData('educational-background', 'Educational Background', <EducationalBackground/>)