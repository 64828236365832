//import { useState, useContext } from 'react'
import utility from 'src/utils/utility'
import { useNavigate } from 'react-router-dom'
import { AppSpan, AppText, CustomContainer, FlexRow, Icon, ScreenTitle } from 'src/styles'
import { BsChevronLeft } from 'react-icons/bs'
import { images } from 'src/provider/config/constant'

const LoanTypeList = () => {
    const navigate = useNavigate()

    return (
        <div style={{marginTop: '4rem', zIndex: '2', paddingRight: '1rem'}}>
            <BsChevronLeft 
                className="loan-back-icon" 
                onClick={() => navigate(-1)} 
            />
            <div className="loan-list-banner">
                <AppText textSize="2.7">
                    Quick Loans to take care of your needs.
                </AppText>
                <AppSpan textSize="1.4" color="#E2E2E2" fontWeight="400" topMargin="1">
                    Quis blandit tempus, risus non vivamus tortor natoque. Urna pellentesque tellus.
                </AppSpan>
            </div>

            <div className="loan-list-card-wrapper">
                {
                    Array.from(Array(6).keys()).map((item: any, index: number) => {
                        return (
                            <div className="loan-list-card" key={index} onClick={() => navigate('/explore/loan-category')}>
                                <Icon src={images.BUBBLE_PLUS} className="loan-list-card-bubble" />
                                <CustomContainer>
                                    <AppSpan color="#E2E2E2" textSize="1.8">Payday Loans</AppSpan>
                                    <AppText textSize="1.2" width="21" fontWeight="400">
                                        Nibh cursus eget laoreet in. In justo, cras blandit etiam amet in.
                                    </AppText>
                                </CustomContainer>
                                <FlexRow justifyContent="flex-start" wrap="wrap" topMargin="6">
                                    <Icon src={images.CARD_ICON_STACK} style={{marginTop: '1rem'}} />
                                    <AppText textSize="1.2" leftMargin="1">
                                        231 people made requests
                                    </AppText>
                                </FlexRow>
                            </div>
                        )
                    })
                }
            </div>
        </div>
    )
}

export default utility.routeData('loan-type-list', 'Loan Type List', <LoanTypeList/>)