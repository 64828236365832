import { images } from 'src/provider/config/constant';
import { AppText, Button, FlexRow } from 'src/styles';
import helpers from '../helpers';
import utility, { getStatementIcon } from '../utility';
import './style.scss';

// ========================= COLUMNS =========================
export const transactionColumns = [
    {
        name: <p>Transaction</p>,
        width: '20rem',
        cell: (row: any) => 
            <>
                { 
                    <FlexRow width='auto' sizeUnit='' justifyContent='flex-start'>
                        <img 
                            src={getStatementIcon(row.transactionType)} 
                            style={{width: '2.7rem', marginRight: '1rem'}} />
                        <AppText fontWeight='400' textSize='1.3'>{row.transactionType}</AppText>
                    </FlexRow>
                }
            </>,     
        ignoreRowClick: false,
        allowOverflow: true
    },
    {
        name: <p>Date</p>,
        width: '15rem',
        selector: (row: any) => helpers.splitDateTime(row.dateAdded)?.date,
    },
    {
        name: <p>Time</p>,
        selector: (row: any) => `${helpers.splitDateTime(row.dateAdded)?.time}${helpers.splitDateTime(row.dateAdded)?.timeEnv}`,
    },
    {
        name: <p>Description</p>,
        width: '25rem',
        selector: (row: any) => row.description
    },
    {
        name: <p>Amount</p>,
        width: '15rem',
        cell: (row: any) => <AppText>₦{utility.moneyFormat(row.amount)}</AppText>,
        ignoreRowClick: false,
        allowOverflow: true
    },
    {
        name: <p>Type</p>,
        cell: (row: any) => <p style={{color: row.operation === 'Credit' ? '#6DE7B4' : '#FF0062'}}>{row.operation}</p>,
        ignoreRowClick: false,
        allowOverflow: true
    },
    {
        name: <p>Status</p>,
        width: '14rem',
        cell: (row: any) => 
            <Button 
                radius='2'
                titleSize='1.2'
                color={row.status === 'success' ? '#6DE7B4' : row.status === 'Pending' ? '#F2994A' : '#FF0062'}
                bgColor={row.status === 'success' ? '#014D2D' : row.status === 'Pending' ? '#5A3600' : '#3F0119'}
                height='2.8'
                leftPadding='1.5'
                rightPadding='1.5'
                width='auto'
                sizeUnit=''
            >
                {row.status}
            </Button>,
        ignoreRowClick: false,
        allowOverflow: true
    }
]


// ========================= MOCK DATA =========================

export const transactionData = [
    {
        transactionType: 'Wallet Topup',
        dateAdded: '2022-07-23T19:41:54.958738+00:00',
        description: 'Wallet topup using debit card',
        amount: `₦50,000`,
        operation: 'Credit',
        status: 'success'
    },
    {
        transactionType: 'Loan Disbursed',
        dateAdded: '2022-07-23T19:41:54.958738+00:00',
        description: 'Loan disbursed from portfolio balance',
        amount: `₦50,000`,
        operation: 'Debit',
        status: 'Pending'
    }
]


// ========================== CUSTOM TABLE STYLES ============================

export const customStyles = {
    table: {
		style: {
			backgroundColor: '#000926'
		},
	},
    rows: {
        style: {
            backgroundColor: '#081952',
            marginBottom: '0.6rem',
            color: '#FFFFFF',
            borderRadius: '0.8rem',
            padding: '1.5rem 0',
            cursor: 'pointer'
        }
    },
    headRow: {
        style: {
            //paddingRight: '1rem',
            backgroundColor: '#000926',
            color: '#FFFFFF',
            fontSize: '1.3rem',
            fontWeight: '400'
        }
    },
    pagination: {
        style: {
            backgroundColor: '#000926',
            color: '#FFFFFF'
        },
        pageButtonsStyle: {
            backgroundColor: 'rgba(255, 255, 255, 0.7)',
            color: '#FFFFFF',
            transition: '0.4s',
            '&:not(:disabled)': {
				//fill: '#fff',
			},
            '&:hover:not(:disabled)': {
				backgroundColor: '#fff',
			},
        }
    }
}