import './style.scss'
import { useEffect, useState, useContext } from 'react'
import utility from 'src/utils/utility'
import ApiContext from 'src/provider/API/call-service'
import LoanContext from 'src/provider/state-manager/loanProvider'
import { useNavigate } from 'react-router-dom'
import { BsCheck, BsChevronLeft } from 'react-icons/bs'
import { BiMenuAltRight } from 'react-icons/bi'
import { FiSearch } from 'react-icons/fi'
import { ScreenContainer, Divider, AppSpan, AppText, Button, FlexRow, Icon, CustomContainer, AbsoluteContainer } from "src/styles"
import { images, sortingCategory as category, sortingOrder as order } from 'src/provider/config/constant'
import { FilterOffers, FilterOffersNew } from 'src/popup'
import { AiOutlineSortAscending } from 'react-icons/ai'

const LoanOffers = () => {
    const navigate = useNavigate()
    const {API} = useContext(ApiContext)
    const {loan: {requestLoanOffers, currentProcessType}, setLoanProperty} = useContext(LoanContext)
    const [loanOffers, setLoanOffers] = useState<any>(null)
    const [currentProcessUser, setCurrentProcessUser] = useState('')
    const [searchText, setSearchText] = useState('')
    const [sortCategory, setSortCategory] = useState('amountPerPerson')
    const [sortOrder, setSortOrder] = useState('asc')
    const [sortType, setSortType] = useState('')
    const [isSortDropdown, setIsSortDropdown] = useState(false)
    const [showFilterBy, setShowFilterBy] = useState(false)

    let typeText = currentProcessUser === 'borrower' ? 'offers' : 'requests'

    useEffect(() => {
        // responsible for getting the data from state manager and setting loanOffers state
        // We are watching changes on requestLoanOffers since it takes little time to
        // retrieve the data from state manager.
        // incase a refresh is made, loanOffers state get set everytime requestLoanOffers changes
        setLoanOffers(requestLoanOffers) // Requests/Offers
        setCurrentProcessUser(currentProcessType)
    }, [requestLoanOffers, currentProcessType])

    useEffect(() => {
        document.body.style.overflow = 'auto'
    }, [])

    useEffect(() => {
        if(showFilterBy) {
          document.body.style.overflow = 'hidden'
        }
        else document.body.style.overflow = 'auto'
    }, [showFilterBy])

    async function handleSorting (val: string) {
        sortType === 'category' ? setSortCategory(val) : setSortOrder(val)

        let data = requestLoanOffers;
        if ((sortType === 'order' && val === 'asc') || (sortType === 'category' && sortOrder === 'asc')) {
            data = data.sort((a: any, b: any) => normalizeTenor(a, val, sortCategory) - normalizeTenor(b, val, sortCategory));
        }
        else {
            data = data.sort((a: any, b: any) => normalizeTenor(b, val, sortCategory) - normalizeTenor(a, val, sortCategory));
        }

        setLoanProperty('requestLoanOffers', data)
        setIsSortDropdown(false)
    }

    function normalizeTenor (obj: any, sortType1: string, sortType2: string) {
        let val = Number(obj[sortType1] || obj[sortType2])
        if (sortType1 === 'tenor' || sortType2 === 'tenor') {
            const tenorType = obj.tenorType == 1 ? 1 : obj.tenorType == 2 ? 30 : obj.tenorType === 3 ? 7 : 52
            val = val * tenorType
        }
        if (sortType1 === 'amountPerPerson' || sortType2 === 'amountPerPerson') {
            val = !val ? Number(obj?.amount) : val
        }
        return val
    }

    function handleSortType (type: string) {
        setSortType(type)
        setIsSortDropdown(true)
    }

    async function handleSelectedOffer_Request (data: any) {
        await setLoanProperty('selectedLoanOffer', data) // selected request/offer
        navigate('/dashboard/loan-board/loan-offer-details')
    }

    async function handleSearch (e: React.FormEvent<HTMLButtonElement | HTMLFormElement>) {
        e.preventDefault()
        handleOfferSearch()
    }

    async function handleOfferSearch () {
        if (!searchText) return false
        if (currentProcessType === 'borrower') await API.filterLoanOffer({searchText}) 
        else await API.filterLoanRequest({searchText})
    }

    return (
        <>
            <ScreenContainer>
                <div className="loan-offers-container">
                    <div className="loan-offer-title-wrapper">
                        <div className="loan-offers-title">
                            <BsChevronLeft className="loan-back-icon" onClick={() => navigate(-1)} />
                            <div className="loan-type-title-content">
                                <p >Loan {typeText}</p>
                                <span>We have arranged the best loan {typeText} just for you</span>
                            </div>
                        </div>
                        <div className="search-for-offers">
                            <form className="search-input-wrapper" onSubmit={handleSearch}>
                                <FiSearch 
                                    className="search-icon-center" 
                                    style={{color: searchText ? '#6DE7B4' : '#999'}}
                                    onClick={handleOfferSearch}
                                />
                                <input 
                                    className="search-offer-input" 
                                    placeholder="Search by loanType, amount e.t.c"
                                    onChange={(e) => setSearchText(e.target.value)}
                                />
                            </form>
                            <CustomContainer 
                                width='auto' size='' borderColor='#4B5D99' 
                                radius='0.4' leftMargin='1'
                                style={{overflow: 'visible'}}
                            >
                                <AppSpan className='sort-by-text' onClick={() => handleSortType('category')}>
                                    Sort By
                                </AppSpan>
                                <AiOutlineSortAscending className="sort-icon" onClick={() => handleSortType('order')} />
                                {
                                    isSortDropdown &&
                                    <AbsoluteContainer top='5'>
                                        <CustomContainer 
                                            bgColor='#081952' width='13'
                                            radius='0.4'
                                            topPadding='1' bottomPadding='1'
                                            style={{boxShadow: '0px 10px 50px rgba(0, 0, 0, 0.5)'}}
                                        >
                                            {
                                                sortType === 'category' ?
                                                category.map((item: any, index: number) => {
                                                    return (
                                                        <FlexRow className='sort-select-option' onClick={() => handleSorting(item.value)} key={index}>
                                                            <AppSpan fontWeight="400" textSize="1.3">{item.name}</AppSpan>
                                                            {sortCategory === item.value ? <BsCheck style={{color: '#FED0B7', fontSize: '2rem'}} /> : null}
                                                        </FlexRow>
                                                    )
                                                })
                                                : 
                                                order.map((item: any, index: number) => {
                                                    return (
                                                        <FlexRow className='sort-select-option' onClick={() => handleSorting(item.value)} key={index}>
                                                            <AppSpan fontWeight="400" textSize="1.3">{item.name}</AppSpan>
                                                            {sortOrder === item.value ? <BsCheck style={{color: '#FED0B7', fontSize: '2rem'}} /> : null}
                                                        </FlexRow>
                                                    )
                                                })
                                            }
                                        </CustomContainer>
                                    </AbsoluteContainer>
                                }
                            </CustomContainer>
                            <AppSpan 
                                onClick={() => setShowFilterBy(true)}
                                className="filter-text-icon"
                            >
                                Filter
                                <BiMenuAltRight className="flex-item-icon"/>
                            </AppSpan>
                        </div>
                    </div>

                    <div className="loan-offers-content">
                        {
                            loanOffers?.map((item: any, index: number) => {
                                //let color = index % 5 === 0 ?  "#FF3A5A" : index % 3 === 0 ? '#FFBC3A' : "#6DE7B4"
                                return (
                                    <div className="loan-offer-info" key={index} onClick={() => handleSelectedOffer_Request(item)}>
                                        <div className="loan-offer-header">
                                            <div className="loan-offer-label">
                                                <img src={images.LOAN_OFFER_ICON} className="loan-offer-header-icon" />
                                                <div className="loan-offer-label-text">
                                                    {/* <label>LoanType</label> */}
                                                    <p className='loan-offer-type-text'>{item.loanTypeName}</p>
                                                </div>
                                            </div>
                                            <span className="loan-offer-percentage" style={{color: '#6DE7B4'}}>{item.interest}%p.m</span>
                                        </div>
                                        <Divider margin="1" />
                                        <div className="flex-row-space-between margin-vertical-1-5">
                                            <div className="loan-offer-label-text">
                                                <label>Amount</label>
                                                <p>N{utility.moneyFormat(item.amountPerPerson || item.amount)}</p>
                                            </div>
                                            <div className="loan-offer-label-text width-10-rem">
                                                <label>Duration</label>
                                                <p>{item.tenor} {item?.tenorType?.toLowerCase()}(s)</p>
                                            </div>
                                        </div>
                                        <div className="flex-row-space-between margin-vertical-1-5">
                                            <div className="loan-offer-label-text">
                                                <label>Payment</label>
                                                <p>{item.rateId === 1 ? 'Installment' : 'One-off'}</p>
                                            </div>
                                            { currentProcessUser === 'borrower' ?
                                                null
                                                // <div className="loan-offer-label-text width-10-rem">
                                                //     <label>Lender's rep</label>
                                                //     <p style={{color: '#6DE7B4'}}>{item?.lenderRep || 89.2}%</p>
                                                // </div>
                                                :
                                                <div className="loan-offer-label-text width-10-rem">
                                                    <label>Crendly score</label>
                                                    <p style={{color: '#6DE7B4'}}>{item?.creditScoreInfo?.grandTotal || 0}%</p>
                                                </div>
                                            }
                                            
                                        </div>
                                        <FlexRow justifyContent="flex-start" wrap="wrap">
                                            <Icon src={images.FRUIT_HEADS2X} style={{marginLeft: '-0.5rem'}} width='6'/>
                                            <AppText textSize="1.2" leftMargin="1">
                                                {item?.numberOfInterestedBorrowers || item?.numberOfPerson - item?.numberOfSlotLeft || 0} person(s) made request
                                            </AppText>
                                        </FlexRow>
                                    </div>
                                )
                            })
                        }
                    </div>
                    { (!loanOffers || loanOffers?.length === 0) &&
                        <div className="no-offer-container">
                            <AppText textSize='2.5' bottomMargin='1'>No loan {typeText} available for your {currentProcessUser === 'borrower' ? 'request' : 'offer'}</AppText>
                            <AppText fontWeight='400'>Consider searching or filtering to get {typeText} that best matches what you want</AppText>
                            <Button
                                width="20"
                                hoverBgColor="#FED0B7" 
                                topMargin='3'
                                onClick={() => currentProcessUser === 'borrower' ? API.filterLoanOffer(null) : API.filterLoanRequest(null)}
                            >
                                See other {typeText}
                            </Button>
                        </div>
                    }
                </div>
            </ScreenContainer>
            { showFilterBy &&
                <FilterOffersNew 
                    close={() => setShowFilterBy(false)}
                    isLocalFilter={true}
                    updateData={setLoanOffers}
                />
            }
        </>
    )
}

export default utility.routeData('loan-board/loan-offers', 'Loan Offers', <LoanOffers/>)